import React, { useMemo } from "react";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  useMaterialReactTable,
} from "material-react-table";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import CustomTooltip from "./CustomTooltip";
import FeatherIcon from "feather-icons-react";
import "./RequestTable.css";
import { Link } from "react-router-dom";

export const RequestTable = ({
  columns,
  data,
  handlePreviewRequest,
  handleLoadRequest,
  handleDuplicateRequest,
  handleDeleteRequest,
  rpdRequestEnabled,
}) => {
  // Custom theme for the table.
  const tableTheme = useMemo(() =>
    createTheme({
      palette: {
        background: {
          default: "#101010",
        },
        secondary: {
          main: "#101010",
          contrastText: "#FFFFFF",
        },
        primary: {
          main: "#101010",
          light: "#101010",
          dark: "#101010",
          contrastText: "#FFFFFF",
        },
      },
      typography: {
        allVariants: {
          color: "#FFF",
        },
      },
      components: {
        MuiTableCell: {
          defaultProps: {
            sx: {
              color: "#FFF",
            },
          },
          styleOverrides: {
            root: {
              color: "#FFF",
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              color: "#FFF",
              fill: "#FFF",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              background: "rgb(25,25,25) !important",
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              background: "rgb(25,25,25) !important",
            },
          },
        },

        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#101010 !important",
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              background: "rgb(25,25,25)",
            },
          },
        },
      },
    })
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnOrdering: false,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    initialState: {
      showColumnFilters: false,
      pagination: { pageSize: 5, pageIndex: 0 },
      showGlobalFilter: true,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10],
    },
    enableHiding: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: "none",
    muiSearchTextFieldProps: {
      sx: {
        border: "1px solid #949494",
        borderRadius: "5px",
        color: "#FFF",
        marginTop: "10px",
        marginBottom: "10px",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        "::placeholder": "color: #949494;",
        ":focus": "border: 2px solid #4A90E2 !important;",
        ":hover": "border: 2px solid #AFD4FF !important;",
      },
      variant: "outlined",
    },
    muiFilterTextFieldProps: {
      sx: {
        border: "1px solid #949494",
        borderRadius: "5px",
        color: "#FFF",
        marginTop: "10px",
        marginBottom: "10px",
        "::placeholder": "color: #949494;",
        ":focus": "border: 2px solid #4A90E2 !important;",
        ":hover": "border: 2px solid #AFD4FF !important;",
      },
      variant: "outlined",
    },
    renderTopToolbar: ({ table }) => {
      const isRowSelected = () => {
        return table.getSelectedRowModel().flatRows.length > 0;
      };

      const isAbleToRequest = () => {
        if (isRowSelected()) {
          if (rpdRequestEnabled) {
            return true;
          }
          return false;
        }
        return false;
      };

      const isAbleToLoadRequest = () => {
        if (isRowSelected()) {
          const row = table.getSelectedRowModel().flatRows[0].original;
          if (row.requestStatusFormatted === "Complete") {
            return true;
          }
          return false;
        }
        return false;
      };

      const handlePreview = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          handlePreviewRequest(row.original);
        });
      };

      const handleLoad = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          handleLoadRequest(row.original);
        });
      };

      const handleDuplicate = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          handleDuplicateRequest(row.original, "copied");
        });
      };

      const handleDelete = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          handleDeleteRequest(row.original, "deleted");
        });
      };

      const getRowData = () => {
        if (isRowSelected()) {
          return table.getSelectedRowModel().flatRows[0].original;
        }
        return null;
      };

      return (
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: "0.5rem",
            justifyContent: "space-between",
            paddingLeft: "10px",
            paddingRight: "10px",
          })}
        >
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: "0.5rem", marginTop: "10px" }}>
              {isRowSelected() && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  <p
                    style={{
                      color: "#FFF",
                      fontWeight: 600,
                    }}
                  >
                    {getRowData().templateName}
                  </p>
                </Box>
              )}

              <CustomTooltip
                content={"Preview Request"}
                tooltipType={"general"}
                placement={"top"}
              >
                <div
                  className={!isRowSelected() ? "disabled-button" : ""}
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "5px",
                    backgroundColor: "#303030",
                    padding: "6px",
                    cursor: "pointer",
                  }}
                  onClick={handlePreview}
                >
                  <FeatherIcon
                    icon="eye"
                    width={28}
                    height={28}
                    color={"#FFF"}
                  />
                </div>
              </CustomTooltip>
              {isAbleToLoadRequest() ? (
                <Link
                  to={{
                    pathname: "/ahmsview",
                    state: getRowData()?.ahmsRpdInfo,
                  }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <CustomTooltip
                    content={"Load Request"}
                    tooltipType={"general"}
                    placement={"top"}
                  >
                    <div
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "5px",
                        backgroundColor: "#3F51B5",
                        padding: "6px",
                        cursor: "pointer",
                      }}
                      onClick={handleLoad}
                    >
                      <FeatherIcon
                        icon="arrow-right"
                        width={28}
                        height={28}
                        color={"#FFF"}
                      />
                    </div>
                  </CustomTooltip>
                </Link>
              ) : (
                <CustomTooltip
                  content={"Load Request"}
                  tooltipType={"general"}
                  placement={"top"}
                >
                  <div
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "5px",
                      backgroundColor: "#3F51B5",
                      padding: "6px",
                      cursor: "not-allowed",
                    }}
                    onClick={handleLoad}
                    className={"disabled-button"}
                  >
                    <FeatherIcon
                      icon="arrow-right"
                      width={28}
                      height={28}
                      color={"#FFF"}
                    />
                  </div>
                </CustomTooltip>
              )}

              <CustomTooltip
                content={"Duplicate Request"}
                tooltipType={"general"}
                placement={"top"}
              >
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "5px",
                    backgroundColor: "#9C27B0",
                    padding: "6px",
                    cursor: "pointer",
                  }}
                  onClick={handleDuplicate}
                  className={isAbleToRequest() ? "" : "disabled-button"}
                >
                  <FeatherIcon
                    icon="copy"
                    width={28}
                    height={28}
                    color={"#FFF"}
                  />
                </div>
              </CustomTooltip>

              <div></div>
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
};

export default RequestTable;
