import TagManager from "react-gtm-module";
/**
 * Google Analytics and Google Tag Manager event creating helper
 * @param DataLayerArgs Additional arguments to send through the event.
 */
export function sendGA4Event(DataLayerArgs) {
  const userOrganizationId = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).orgId
    : "";

  const userId = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).userId
    : "";

  let userType = "unknown";

  /*
    Identify what type of user is using the application by its Bombardier user ID.
    If the user ID starts with a "B" it is an internal user.
    If the user ID starts with a "C" it is an customer user.
    If the user ID starts with a "K" it is an - user.
  */
  if (
    process.env.REACT_APP_ENVIRONMENT === "DEMO" ||
    process.env.REACT_APP_ENVIRONMENT === "PREDICTIVE-DEV"
  ) {
    userType = process.env.REACT_APP_ENVIRONMENT.toLowerCase();
  } else {
    if (userId.substring(0, 1).toLowerCase() === "b") {
      userType = "internal";
    } else if (userId.substring(0, 1).toLowerCase() === "c") {
      userType = "customer";
    }
  }

  // Default events sent through the event
  DataLayerArgs.dataLayer.userId = userId;
  DataLayerArgs.dataLayer.orgId = userOrganizationId;
  DataLayerArgs.dataLayer.crcId = userId;
  DataLayerArgs.dataLayer.userType = userType;
  DataLayerArgs.dataLayer.environment = process.env.REACT_APP_ENVIRONMENT;
  TagManager.dataLayer(DataLayerArgs);
}

export function sendCustomGA4Event(eventName, eventCategory, eventLabel, eventInteraction, moreInfo = null) {
  const userOrganizationId = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")).orgId
      : "";

  const userId = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")).userId
      : "";
  
  sendInternalUserEvent(userOrganizationId, userId);

  const tagManagerArgs = {
    dataLayer: {
      user_id: userId,
      org_id: userOrganizationId,
      environment: process.env.REACT_APP_ENVIRONMENT,
      event: eventName,
      event_category: eventCategory,
      event_label: eventLabel,
      event_interaction: eventInteraction,
    }
  };

  if(moreInfo) {
    tagManagerArgs.dataLayer.more_info = moreInfo;
  }

  TagManager.dataLayer(tagManagerArgs);
}

function sendInternalUserEvent(userOrganizationId, userId) {
  const isInternalUser = userId.substring(0, 1).toLowerCase() === "b"

  if (isInternalUser) {
    const internalUserTagManagerArgs = {
      dataLayer: {
        user_id: userId,
        org_id: userOrganizationId,
        environment: process.env.REACT_APP_ENVIRONMENT,
        event: "traffic_type",
        traffic_type: "internal",
      }
    };
    TagManager.dataLayer(internalUserTagManagerArgs);
  }
}