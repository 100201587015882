import React, { Component } from "react";
import FdeCasList from "./FdeCasList";
import OmsList from "./OmsList";
import CmsList from "./CmsList";
import MdcList from "./MdcList";
import EIFRView from "./EIFRView";
import FeatherIcon from "feather-icons-react";
import CustomTooltip from "../Global Components/CustomTooltip";
import moment from "moment";
import "./FaultMessageBox.css";
import FeatureToggleWrapper from "../Feature Toggles/FeatureToggleWrapper";
import * as featureToggleTools from "../Feature Toggles/FeatureToggleTools";

export default class FaultMessageBox extends Component {
  constructor(props) {
    super(props);

    this.handleFdecsTable = this.handleFdecasTable.bind(this);
    this.handleOmsTable = this.handleOmsTable.bind(this);
    this.handleCmsTable = this.handleCmsTable.bind(this);
    this.hasOMSButton = this.hasOMSButton.bind(this);
    this.hasCMSButton = this.hasCMSButton.bind(this);
    this.uiConfig = this.props.aircraftUIConfig.aircraftViewPage;
    this.handleMDCwithFMH = this.handleMDCwithFMH.bind(this);
  }

  hasOMSButton() {
    if (
      (this.props.viewType === "IFR" && this.uiConfig.hideMdcColumIfActive) ||
      !this.uiConfig.hasOmsMdcMessages
    ) {
      return <div></div>;
    } else {
      return (
        <button
          className={
            this.props.selectedTab === "OMS" || this.props.selectedTab === "MDC"
              ? "faultMessageBox__tabPanel active"
              : "faultMessageBox__tabPanel"
          }
          onClick={() => {
            this.uiConfig.hasOmsMdcMessages &&
            this.uiConfig.mdcTabLabel === "MDC"
              ? this.handleTabSelection("MDC")
              : this.handleTabSelection("OMS");
          }}
        >
          <div className="faultMessageBox__tabLeft">
            {this.uiConfig.mdcTabLabel}
          </div>
          <div className="faultMessageBox__tabRight">
            {this.props.selectedFdeFault ? (
              this.props.selectedFdeFault.omsRelatedCount > 0 ? (
                <div className="faultMessageBox__relateCount">
                  {this.props.selectedFdeFault.omsRelatedCount}
                </div>
              ) : (
                <div></div>
              )
            ) : (
              <div></div>
            )}
          </div>
        </button>
      );
    }
  }

  hasCMSButton() {
    // if this has cms support
    if (this.props.aircraftCmsSupport) {
      // If this is an inflight session we shoe the cms IF msessage tooltip
      if (this.props.viewType === "IFR") {
        return (
          <CustomTooltip
            tooltipType={"general"}
            content={
              this.props.latestCmsIfMessageDate
                ? "Last CMS message received on " +
                  moment
                    .utc(this.props.latestCmsIfMessageDate)
                    .format("MMM DD, YYYY")
                    .toString() +
                  " at " +
                  moment
                    .utc(this.props.latestCmsIfMessageDate)
                    .format("HH:mm:ss UTC")
                    .toString() +
                  "."
                : "No CMS data yet."
            }
            placement={"top-end"}
          >
              <button
                  id="cms-tab-aircraft-page"
                  className={
                      this.props.selectedTab === "CMS"
                          ? "faultMessageBox__tabPanel active"
                          : "faultMessageBox__tabPanel"
                  }
                  onClick={() => {
                      this.handleTabSelection("CMS");
                  }}
              >
                  <div className="faultMessageBox__tabLeft">
                      <span style={{ marginRight: "5px" }}>CMS</span>
                  </div>
                  <div className="faultMessageBox__tabRight">
                      <FeatherIcon icon={"info"} height={18} />
                  </div>
              </button>
          </CustomTooltip>
        );
      }

      // If this is not an inflight session we do not show the cms IF msessage tooltip
      return (
        <button
          id="cms-tab-aircraft-page"
          className={
            this.props.selectedTab === "CMS"
              ? "faultMessageBox__tabPanel active"
              : "faultMessageBox__tabPanel"
          }
          onClick={() => {
            this.handleTabSelection("CMS");
          }}
        >
          <div className="faultMessageBox__tabLeft">
            <span>CMS</span>
          </div>
          <div className="faultMessageBox__tabRight"></div>
        </button>
      );
    } else if (this.uiConfig.hasEIFRTab) {
      return (
        <button
          className={
            this.props.selectedTab === "EIFR"
              ? "faultMessageBox__tabPanel active"
              : "faultMessageBox__tabPanel"
          }
          onClick={() => {
            this.handleTabSelection("EIFR");
          }}
        >
          <div className="faultMessageBox__tabLeft">
            <span> EIFR </span>
          </div>
          <div className="faultMessageBox__tabRight"></div>
        </button>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    return (
      <div className="faultMessageBox" id="faultMessageBox">
        <div className="faultMessageBox__tabContainer">
          <button
            className={
              this.props.selectedTab === "FDECAS"
                ? "faultMessageBox__tabPanel active"
                : "faultMessageBox__tabPanel"
            }
            onClick={() => {
              this.handleTabSelection("FDECAS");
            }}
          >
            <div className="faultMessageBox__tabLeft">FDE / CAS</div>
            <div className="faultMessageBox__tabRight">
              {this.props.selectedOmsFault ? (
                this.props.selectedOmsFault.fdeRelatedCount > 0 ? (
                  <div className="faultMessageBox__relateCount">
                    {this.props.selectedOmsFault.fdeRelatedCount}
                  </div>
                ) : (
                  <div></div>
                )
              ) : (
                <div></div>
              )}
            </div>
          </button>
          {this.hasOMSButton()}
          {this.hasCMSButton()}
        </div>
        <div
          className={
            this.props.selectedTab === "FDECAS"
              ? "faultMessageBox__content active"
              : "faultMessageBox__content"
          }
          id="fdecasTable"
        >
          {this.handleFdecasTable()}
        </div>

        <div
          className={
            this.props.selectedTab === "OMS" || this.props.selectedTab === "MDC"
              ? "faultMessageBox__content active"
              : "faultMessageBox__content"
          }
          id="omsTable"
        >
          {this.uiConfig.hasOmsMdcMessages &&
          this.uiConfig.mdcTabLabel === "MDC"
            ? featureToggleTools.isFeatureToggleActive("ft-mdc-fmh-smh")
              ? this.handleMDCwithFMH()
              : this.handleOmsTable()
            : this.handleOmsTable()}
        </div>
        <div
          className={
            this.props.selectedTab === "CMS"
              ? "faultMessageBox__content active"
              : "faultMessageBox__content"
          }
          id="cmsTable"
        >
          {this.handleCmsTable()}
        </div>

        <div
          className={
            this.props.selectedTab === "EIFR"
              ? "faultMessageBox__content activeEifr"
              : "faultMessageBox__content"
          }
          id="eifrView"
        >
          {this.props.selectedTab === "EIFR" ? this.handleEIFRView() : ""}
        </div>
      </div>
    );
  }

  handleTabSelection(tab) {
    this.props.handleFaultMessageTabChange(tab);
  }

  // Handles the loading and fdecas table component rendering
  handleFdecasTable() {
    return (
      <FdeCasList
        changeFlightSelection={this.props.changeFlightSelection}
        viewType={this.props.viewType}
        aircraftUIConfig={this.uiConfig}
        aircraftFamily={this.props.aircraftFamily}
        aircraftModel={this.props.aircraftModel}
        fdecasList={this.props.fdecasList}
        selectedFdeFault={this.props.selectedFdeFault}
        selectedOmsFault={this.props.selectedOmsFault}
        infoSeriesToggle={this.props.infoSeriesToggle}
        statusSeriesToggle={this.props.statusSeriesToggle}
        reduceCommonFaultsSeriesToggle={
          this.props.reduceCommonFaultsSeriesToggle
        }
        faultCorrelationClickEvent={this.props.faultCorrelationClickEvent}
        fdeFilterText={this.props.fdeFilterText}
        handleFdeFilterChange={this.props.handleFdeFilterChange}
        faultMessageBoxRef={this.props.faultMessageBoxRef}
      />
    );
  }

  // Handles the loading and fdecas table component rendering
  handleOmsTable() {
    return (
      <OmsList
        aircraftUIConfig={this.uiConfig}
        omsList={this.props.omsList}
        selectedOmsFault={this.props.selectedOmsFault}
        selectedFdeFault={this.props.selectedFdeFault}
        omsFilterText={this.props.omsFilterText}
        omsRowClickEvent={this.props.omsRowClickEvent}
        handleOmsFilterChange={this.props.handleOmsFilterChange}
        showMdcInformativeBanner={this.uiConfig.showMdcInformativeBanner}
      />
    );
  }

  handleCmsTable() {
    return (
      <CmsList
        changeFlightSelection={this.props.changeFlightSelection}
        cmsList={this.props.cmsList}
        selectedCmsFault={this.props.selectedCmsFault}
        cmsFilterText={this.props.cmsFilterText}
        cmsRowClickEvent={this.props.cmsRowClickEvent}
        handleCmsFilterChange={this.props.handleCmsFilterChange}
      />
    );
  }

  handleEIFRView() {
    return (
      <EIFRView
        eifrList={this.props.eifrList}
        flightList={this.props.flightList}
      />
    );
  }

  handleMDCwithFMH() {
    return (
      <FeatureToggleWrapper name="ft-mdc-fmh-smh">
        <MdcList
          aircraftFamily={this.props.aircraftFamily}
          aircraftModel={this.props.aircraftModel}
          tailNumber={this.props.tailNumber}
          setMdcRowData={this.props.setMdcRowData}
          omsRowClickEvent={this.props.omsRowClickEvent}
          fdeInfoFault={this.props.fdeInfoFault}
          sessionNumber={this.props.sessionNumber}
        />
      </FeatureToggleWrapper>
    );
  }
}
