import axios from "axios";
/* istanbul ignore file */
export const onSubmit = (submitData, success, error) => {
  const loginData = {
    username: process.env.REACT_APP_OPO_USER,
    password: process.env.REACT_APP_OPO_PW,
  };

  const header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "",
    },
  };

  axios
    .post(
      "https://py28p2hu02.execute-api.ca-central-1.amazonaws.com/dev/login",
      loginData,
      header
    )

    .then(function(response) {
      // feedback got saved in the database
      const { data } = response;

      if (data.authentication) {
        const sendData = {
          ...submitData,
          source: "DOM",
        };

        const feedBackHeader = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.user.token}`,
          },
        };

        axios
          .post(
            "https://py28p2hu02.execute-api.ca-central-1.amazonaws.com/dev/addNewFeedback",
            sendData,
            feedBackHeader
          )

          .then(function(response) {
            // feedback got saved in the database
            const { data } = response;
            if (data.success) {
              success();
            } else {
              error();
            }
          })
          .catch((e) => {
            error();
          });
      } else {
        error();
      }
    })
    .catch((e) => {
      error();
    });
};
