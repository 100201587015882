import React, { useEffect, useState, useContext } from 'react'
import _ from 'lodash'
import moment from 'moment'
import FeatherIcon from 'feather-icons-react'
import CircularProgress from '@mui/material/CircularProgress'
import { Modal, TextField, TextareaAutosize } from '@mui/material'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { styled } from '@mui/material/styles'
import CustomSelectAutocomplete from '../../Global Components/CustomSelectAutocomplete'
import CustomSelect from '../../Global Components/CustomSelect'
import CustomRadioGroup from './CustomRadioGroup'
import { dropdownFields } from '../../constants'
import ReportContext from '../Context Components/ReportContext'
import PredictiveTooltip from '../../Global Components/PredictiveTooltip.jsx'
import { getRolesPermissions } from '../../../Auth Components/RbacValidation.js'

import './Review.css'

const CustomTextField = styled(TextField)({
    width: '100%',
    minWidth: '100%',
    margin: '10px 0 0 0',
    '& label.Mui-focused': {
        color: '#D2D2D2'
    },
    '& label.Mui-disabled': {
        color: '#D2D2D2'
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#D2D2D2'
    },
    '& .MuiOutlinedInput-root': {
        height: '50px',
        width: '80%',
        '& input': {
            fontWeight: 700
        },
        '& fieldset': {
            borderColor: '#D2D2D2 !important',
            borderWidth: '1px !important',
            borderRadius: '10px !important'
        },
        '&:hover fieldset': {
            borderColor: '#D2D2D2'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#D2D2D2'
        }
    }
})

const CustomTextareaAutosize = styled(TextareaAutosize)({
    width: '100%',
    minWidth: '100%',
    margin: '10px 0 0 0',
    '& .MuiOutlinedInput-root': {
        height: '55px',
        width: '50%',
        '& fieldset': {
            borderWidth: '1px'
        }
    }
})

const AccordionRoot = styled(Accordion)({
    background: 'transparent !important',
    margin: '0 0 25px 0 !important',
    '& .MuiButtonBase-root': {
        minHeight: 'auto !important'
    }
})

const AccordionEditSummary = styled(AccordionSummary)({
    backgroundColor: '#505050 !important',
    padding: '0 !important',
    cursor: 'pointer !important',
    borderTopLeftRadius: '10px !important',
    borderTopRightRadius: '10px !important',
    overflow: 'visible',
    '& .MuiAccordionSummary-content': {
        margin: '0 !important',
        display: 'flex !important',
        justifyContent: 'space-between !important',
        alignItems: 'center !important'
    }
})

const AccordionViewSummary = styled(AccordionSummary)({
    backgroundColor: '#808080 !important',
    padding: '0 !important',
    cursor: 'pointer !important',
    borderTopLeftRadius: '10px !important',
    borderTopRightRadius: '10px !important',
    overflow: 'visible',
    '& .MuiAccordionSummary-content': {
        height: 50,
        margin: '0 !important',
        display: 'flex !important',
        justifyContent: 'space-between !important',
        alignItems: 'center !important'
    }
})

const AccordionPersonalInfoDetail = styled(AccordionDetails)({
    backgroundColor: '#202020',
    padding: '25px !important',
    color: '#FFFFFF',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '50% 1fr'
})

const AccordionQuestionsDetail = styled(AccordionDetails)({
    backgroundColor: '#202020',
    padding: '25px !important',
    color: '#FFFFFF',
    width: '100%',
    display: 'flex',
    flexFlow: 'column'
})

const AccordionViewDetail = styled(AccordionDetails)({
    backgroundColor: '#202020',
    padding: '25px 30px!important',
    color: '#FFFFFF',
    width: '100%',
    display: 'flex',
    flexFlow: 'column'
})

// This can be added in each component by using the sx prop if needed.
// root: {
//   display: "flex",
//   flexWrap: "wrap",
// },

const Review = props => {
    const {
        templateForReview,
        reviewQuestions,
        reviewForm,
        reviewed,
        answeredDate,
        handleReviewFormData,
        handleSubmitReview,
        handleWidthdrawReview,

        handleQuestionsChange,
        personalSection,
        questionsSection,
        optionalQuestions,
        originalAnswers,
        handleOriginalAnswers,
        containsDiagnostics,
        containsPrognostics,

        editMode,
        handleReviewEditModeChange,
        allReviews
    } = props
    const rolesPermissions = getRolesPermissions('global')
    const { loadingValue } = useContext(ReportContext)
    let questionCount = 0

    const [, setLoading] = loadingValue
    const [submitDisabled, setSubmitDisabled] = useState(reviewed ? false : true)
    const [textError, setTextError] = useState({
        error: false,
        message: ''
    })
    const [currModal, setCurrModal] = useState({
        show: false,
        icon: 'check-circle',
        iconColor: '#FFFFFF',
        headerText: 'Review submitted successfully.',
        bodyText: '',
        containsCancelButton: false,
        containsOKButton: true,
        containsWidthdrawButton: false,
        refreshPage: false
    })
    const [reviewTabSelected, setReviewTabSelected] = useState(true)
    const [viewingOthersReview, setViewingOthersReview] = useState(false)
    const [selectedReview, setSelectedReview] = useState(null)

    // All modals
    // Successful modal
    const showSuccessModal = () => {
        currModal.show = true
        currModal.icon = 'check-circle'
        currModal.headerText = reviewed
            ? 'Changes Saved successfully!'
            : 'Review submitted successfully.'
        currModal.bodyText = (
            <React.Fragment>
                You can still "<span style={{ fontWeight: 700 }}>Edit</span>" your responses by
                clicking the Edit button on Review page
            </React.Fragment>
        )
        currModal.containsCancelButton = false
        currModal.containsOKButton = true
        currModal.refreshPage = true
        currModal.containsWidthdrawButton = false
        setCurrModal({ ...currModal })
    }

    const showMissingAnswersModal = () => {
        currModal.show = true
        currModal.icon = 'alert-circle'
        currModal.headerText = 'Error submitting review'
        currModal.bodyText = 'Please answer all questions to submit.'
        currModal.containsCancelButton = false
        currModal.containsOKButton = true
        currModal.containsWidthdrawButton = false
        setCurrModal({ ...currModal })
    }

    // Widthdraw modal
    const showWithdrawModal = () => {
        currModal.show = true
        currModal.icon = 'alert-circle'
        currModal.headerText = 'Do you want to withdraw your submission?'
        currModal.bodyText = (
            <React.Fragment>
                Click on "<span style={{ fontWeight: 700 }}>Withdraw</span>" if you want to withdraw
                your submission.
            </React.Fragment>
        )
        currModal.containsOKButton = false
        currModal.containsCancelButton = true
        currModal.containsWidthdrawButton = true
        setCurrModal({ ...currModal })
    }

    // Save changes warning modal
    const showSaveChangesModal = () => {
        currModal.show = true
        currModal.icon = 'alert-circle'
        currModal.headerText = 'Changes not saved!'
        currModal.bodyText = (
            <React.Fragment>
                Click on "<span style={{ fontWeight: 700 }}>Resubmit</span>" if you want to save
                your changes.
            </React.Fragment>
        )
        currModal.containsCancelButton = true
        currModal.containsOKButton = false
        currModal.containsWidthdrawButton = false
        setCurrModal({ ...currModal })
    }

    const handleModalCancel = () => {
        // Reset visibility of questions depending on orginal answers
        for (let i = 0; i < questionsSection.questions.length; i++) {
            const q = questionsSection.questions[i]

            let isVisible = false
            let questionForm = _.find(originalAnswers, {
                question_id: q.question_id
            })

            // Check if the question should be visible
            // If it contains an answer, make it visible
            if (typeof questionForm !== 'undefined') {
                if (q.question_type === 'multiple') {
                    if (questionForm.answer_text.length > 0) isVisible = true
                } else {
                    if (questionForm.answer_text !== '') isVisible = true
                }
            }

            if (q.question_id !== '1') {
                q.is_visible = isVisible
            }
        }
        optionalQuestions.is_visible = false

        handleQuestionsChange('Review Questions', 'questions', questionsSection.questions)
        handleQuestionsChange('Optional', null, optionalQuestions)
        // Set review to original answers
        handleReviewFormData(_.cloneDeep(originalAnswers), reviewed, answeredDate)

        // Go back to View mode
        handleReviewEditModeChange(false)
        closeModal()
    }

    const handleModalWithdraw = async () => {
        const response = await handleWidthdrawReview()

        if (response.status === 200) {
            handleReviewEditModeChange(true)

            // Delete all answers
            handleResetClick(true)

            handleOriginalAnswers(_.cloneDeep(templateForReview))

            // Make all unanswered questions not visible
            for (let i = 0; i < questionsSection.questions.length; i++) {
                const q = questionsSection.questions[i]
                if (q.question_id !== '1') {
                    q.is_visible = false
                }
            }
            handleQuestionsChange('Review Questions', 'questions', questionsSection.questions)
            handleQuestionsChange('Review Questions', 'isComplete', false)

            // Close modal
            closeModal()
        }
    }

    const closeModal = () => {
        currModal.show = false
        setCurrModal({ ...currModal })
    }

    const handleSubmitClick = async () => {
        if (submitDisabled) {
            showMissingAnswersModal()
        } else {
            const response = await handleSubmitReview(reviewForm)
            if (response.status === 200) {
                // Show success modal
                showSuccessModal()
                handleReviewEditModeChange(false)

                const currentTime = new Date()
                handleReviewFormData(
                    [...reviewForm],
                    true,
                    moment.utc(moment(currentTime)).format()
                )
                // Set orginal answers to new ones
                handleOriginalAnswers(_.cloneDeep(reviewForm))
            }
        }
    }

    const handleEditClick = () => {
        // Find last question answered and display the next one if theres one
        let nextQuestionId
        let questionObj = questionsSection.questions[0]
        let questionForm = _.find(reviewForm, {
            question_id: questionObj.question_id
        })

        // While loop ends when last question answered found
        while (questionForm.answer_text !== '') {
            if (questionObj.next_question.length === 1) {
                nextQuestionId = questionObj.next_question[0].next_question_id
            } else {
                nextQuestionId = _.find(questionObj.next_question, {
                    option_id: questionForm.answer_ids.toString()
                }).next_question_id
            }

            if (nextQuestionId === 'E' || nextQuestionId === optionalQuestions.question_id) {
                optionalQuestions.is_visible = true
                handleQuestionsChange('Optional', null, optionalQuestions)
                break
            } else {
                questionObj = _.find(questionsSection.questions, {
                    question_id: nextQuestionId
                })
                questionForm = _.find(reviewForm, { question_id: nextQuestionId })
            }
        }

        if (typeof questionObj === 'undefined') {
            optionalQuestions.is_visible = true
            handleQuestionsChange('Optional', null, optionalQuestions)
        } else {
            questionObj.is_visible = true
            handleQuestionsChange('Review Questions', 'questions', questionsSection.questions)
        }

        handleReviewEditModeChange(true)
    }

    const handleResetClick = isWithdraw => {
        let containReview = reviewed
        if (isWithdraw) containReview = false
        let newForm = []

        reviewForm.forEach(questionObj => {
            questionObj.answer_ids = ''
            questionObj.answer_text = ''

            newForm.push(questionObj)
        })

        if (isWithdraw) optionalQuestions.is_visible = false

        handleReviewFormData([...newForm], containReview, answeredDate)
    }

    const handleChange = (id, value, field, fieldId) => {
        let reviewFormObj = _.find(reviewForm, { question_id: id })
        let questionObj = _.find(reviewQuestions, { question_id: id })

        if (typeof questionObj !== 'undefined') {
            if (questionObj.question_type === 'text') {
                // Text field question can only contain letters
                if (!/^[A-Za-z\s]*$/.test(value)) {
                    if (!textError.error) {
                        textError.error = true
                        textError.message = 'Invalid characters. Enter alphabets only.'
                        setTextError({ ...textError })
                    }
                } else {
                    if (textError.error) {
                        textError.error = false
                        setTextError({ ...textError })
                    }
                }
                reviewFormObj.answer_text = value
            } else {
                if (Array.isArray(value)) {
                    reviewFormObj.answer_text = field
                    reviewFormObj.answer_ids = fieldId
                } else {
                    reviewFormObj.answer_text = value[field]
                    reviewFormObj.answer_ids = value[fieldId]
                }
            }

            // Un/unhide questions depending on the answer
            if (questionObj.question_category !== 'General') {
                let questionIndex = _.findIndex(questionsSection.questions, {
                    question_id: id
                })

                // Start by hiding all next questions
                for (let i = questionIndex + 1; i < questionsSection.questions.length; i++) {
                    let q = questionsSection.questions[i]
                    q.is_visible = false
                }

                // Start by hiding comment question
                optionalQuestions.is_visible = false

                let nextQuestions = [...questionObj.next_question]
                nextQuestions.length > 1 &&
                    nextQuestions.sort(function (objA, objB) {
                        if (parseInt(objA.option_id) === parseInt(value.option_id)) return 1
                        else return -1
                    })

                nextQuestions.forEach(nextQuestion => {
                    let nextQuestionId = nextQuestion.next_question_id

                    let isVisible = false

                    if (Array.isArray(value)) {
                        if (value.length > 0 || questionObj.question.includes('failure')) {
                            isVisible = true
                        }
                    } else {
                        if (
                            questionObj.next_question.length === 1 ||
                            parseInt(nextQuestion.option_id) === parseInt(value.option_id)
                        ) {
                            isVisible = true
                        }
                    }

                    if (
                        nextQuestionId === 'E' ||
                        (nextQuestionId === optionalQuestions.question_id && isVisible)
                    ) {
                        optionalQuestions.is_visible = true
                    } else {
                        let nextQuestionObj = _.find(questionsSection.questions, {
                            question_id: nextQuestionId
                        })
                        let nextQuestionForm = _.find(reviewForm, {
                            question_id: nextQuestionId
                        })

                        if (
                            (typeof nextQuestionObj === 'undefined' &&
                                !containsDiagnostics &&
                                !containsPrognostics) ||
                            (typeof nextQuestionObj === 'undefined' && !containsPrognostics)
                        ) {
                            optionalQuestions.is_visible = true
                        } else {
                            // If nextQuestionObj is undefined, it might be because there is no diagnostics
                            if (
                                typeof nextQuestionObj === 'undefined' &&
                                containsPrognostics &&
                                isVisible
                            ) {
                                nextQuestionObj = _.find(questionsSection.questions, function (o) {
                                    return o.question_category.toLowerCase().includes('prognostics')
                                })
                                nextQuestionId = nextQuestionObj.question_id
                                nextQuestionForm = _.find(reviewForm, {
                                    question_id: nextQuestionId
                                })
                            }

                            if (typeof nextQuestionObj !== 'undefined') {
                                nextQuestionObj.is_visible = isVisible

                                // If user answered the rest of the questions already, display them
                                while (
                                    isVisible &&
                                    typeof nextQuestionObj !== 'undefined' &&
                                    ((Array.isArray(nextQuestionForm.answer_ids) &&
                                        nextQuestionForm.answer_ids.length > 0) ||
                                        questionObj.question.includes('failure') ||
                                        nextQuestionForm.answer_text !== '')
                                ) {
                                    if (nextQuestionObj.next_question.length === 1) {
                                        nextQuestionId =
                                            nextQuestionObj.next_question[0].next_question_id
                                    } else {
                                        nextQuestionId = _.find(nextQuestionObj.next_question, {
                                            option_id: nextQuestionForm.answer_ids.toString()
                                        }).next_question_id
                                    }

                                    if (
                                        nextQuestionId === 'E' ||
                                        (nextQuestionId === optionalQuestions.question_id &&
                                            isVisible)
                                    ) {
                                        optionalQuestions.is_visible = true
                                        break
                                    } else {
                                        nextQuestionObj = _.find(questionsSection.questions, {
                                            question_id: nextQuestionId
                                        })
                                        nextQuestionForm = _.find(reviewForm, {
                                            question_id: nextQuestionId
                                        })

                                        if (
                                            typeof nextQuestionObj === 'undefined' &&
                                            containsPrognostics &&
                                            !containsDiagnostics
                                        ) {
                                            nextQuestionObj = _.find(
                                                questionsSection.questions,
                                                function (o) {
                                                    return o.question_category
                                                        .toLowerCase()
                                                        .includes('prognostics')
                                                }
                                            )
                                            nextQuestionId = nextQuestionObj.question_id
                                            nextQuestionForm = _.find(reviewForm, {
                                                question_id: nextQuestionId
                                            })
                                        }
                                        if (
                                            typeof nextQuestionObj === 'undefined' &&
                                            !containsPrognostics
                                        ) {
                                            optionalQuestions.is_visible = true
                                            break
                                        } else if (typeof nextQuestionObj !== 'undefined') {
                                            nextQuestionObj.is_visible = isVisible
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
            }

            handleQuestionsChange('Optional', null, optionalQuestions)
            handleQuestionsChange('Review Questions', 'questions', questionsSection.questions)

            handleReviewFormData([...reviewForm], reviewed, answeredDate)
        }
    }

    const handleReviewsTabClick = () => {
        setSelectedReview(null)
        setViewingOthersReview(false)
        setReviewTabSelected(true)
    }

    const handleSubmittedReviewsTabClick = () => {
        setSelectedReview(null)
        setViewingOthersReview(false)
        setReviewTabSelected(false)
    }

    const handleReviewClick = review => {
        setSelectedReview(review)
        setViewingOthersReview(true)
    }

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(() => {
        if (typeof reviewed === 'boolean') {
            setSubmitDisabled(!reviewed)
        }
    }, [reviewed])

    useEffect(() => {
        if (
            reviewForm &&
            personalSection.questions.length > 0 &&
            questionsSection.questions.length > 0
        ) {
            let personalSectionCompleted = true
            personalSection.questions.forEach(question => {
                let reviewFormIndex = _.findIndex(reviewForm, function (q) {
                    return q.question_id === question.question_id
                })

                if (reviewFormIndex !== -1) {
                    let reviewFormQuestion = reviewForm[reviewFormIndex]
                    if (
                        (reviewFormQuestion.answer_text === '' && !reviewFormQuestion.answer_ids) ||
                        (!reviewFormQuestion.answer_text && !reviewFormQuestion.answer_ids)
                    ) {
                        personalSectionCompleted = false
                    }
                }
            })

            handleQuestionsChange('Personal Information', 'isComplete', personalSectionCompleted)

            // To check if questions are done, excluding optional questions
            // Check if all questions until Severity question is answered (the rest is optional)
            let questionsCompleted = true
            let anomalyAnswerId = _.find(reviewForm, { question_id: '1' }).answer_ids

            if (anomalyAnswerId == '') questionsCompleted = false
            else if (anomalyAnswerId == 2) {
                if (!optionalQuestions?.is_visible) {
                    questionsCompleted = false
                }
            } else {
                if (!optionalQuestions?.is_visible) {
                    let nextQuestionId
                    let questionObj = questionsSection.questions[0]
                    let questionForm = _.find(reviewForm, {
                        question_id: questionObj.question_id
                    })

                    while (questionForm.answer_text !== '') {
                        if (questionObj.next_question.length === 1) {
                            nextQuestionId = questionObj.next_question[0].next_question_id
                        } else {
                            nextQuestionId = _.find(questionObj.next_question, {
                                option_id: questionForm.answer_ids.toString()
                            }).next_question_id
                        }

                        questionObj = _.find(questionsSection.questions, {
                            question_id: nextQuestionId
                        })
                        questionForm = _.find(reviewForm, { question_id: nextQuestionId })

                        if (questionObj.question.toLowerCase().includes('confident')) break
                    }

                    if (!questionObj.question.toLowerCase().includes('confident')) {
                        questionsCompleted = false
                    }
                }
            }

            handleQuestionsChange('Review Questions', 'isComplete', questionsCompleted)
        }
    }, [reviewForm, personalSection.questions, questionsSection.questions, editMode])

    useEffect(() => {
        if (reviewForm) {
            // Check if Submit button still need to disabled
            if (personalSection.isComplete && questionsSection.isComplete) {
                setSubmitDisabled(false)
            } else {
                setSubmitDisabled(true)
            }
        }
    }, [personalSection.isComplete, questionsSection.isComplete])

    const ReviewModal = ({
        show,
        closeModal,
        icon,
        iconColor,
        headerText,
        bodyText,
        containsOKButton,
        containsCancelButton,
        containsWidthdrawButton,
        refreshPage
    }) => {
        return (
            <Modal open={show} onClose={closeModal} className='review__modalStyle'>
                <div className='review__modalContainer'>
                    <div className='review__modalBody'>
                        <div className='review__modalBody_leftSide'>
                            <FeatherIcon
                                width={40}
                                height={40}
                                color={iconColor}
                                icon={icon}
                                style={{ marginRight: 20, marginLeft: 2 }}
                            />
                        </div>
                        <div className='review__modalBody__rightSide'>
                            <div className='review__modalBody_top'>{headerText}</div>
                            <div className='review__modalBody_middle'>{bodyText}</div>
                            <div className='review__modalBody_bottom'>
                                {containsOKButton ? (
                                    <button
                                        className={`predictive__button whiteButton`}
                                        onClick={() => {
                                            closeModal()
                                        }}
                                    >
                                        OK
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            className={`predictive__button darkButton`}
                                            style={{ marginRight: 25 }}
                                            onClick={() => {
                                                if (containsWidthdrawButton) handleModalWithdraw()
                                                else handleSubmitClick()
                                            }}
                                        >
                                            {containsWidthdrawButton ? 'WITHDRAW' : 'RESUBMIT'}
                                        </button>
                                        <button
                                            className={`predictive__button whiteButton`}
                                            onClick={() => {
                                                if (
                                                    !containsWidthdrawButton &&
                                                    containsCancelButton
                                                )
                                                    handleModalCancel()
                                                else closeModal()
                                            }}
                                        >
                                            {containsCancelButton ? 'CANCEL' : 'OK'}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

    const SingleTypeQuestion = ({ data, index }) => {
        const questionId = data.question_id

        const isDropdown = data.question_type.toLowerCase() === 'multiple' ? true : false
        let dropdownListNumber = 0
        let selectedValues = []
        if (isDropdown) {
            dropdownListNumber = _.findIndex(dropdownFields, {
                answerFamily: data.answer_family
            })

            if (
                Array.isArray(
                    reviewForm &&
                        reviewForm.length > 0 &&
                        _.find(reviewForm, { question_id: questionId }).answer_text
                )
            ) {
                let reviewObj = _.find(reviewForm, { question_id: questionId })
                let combinedAnswers = []

                for (let i = 0; i < reviewObj.answer_text.length > 0; i++) {
                    let texts = reviewObj.answer_text[i]
                    let ids = reviewObj.answer_ids[i]

                    combinedAnswers.push({
                        [dropdownFields[dropdownListNumber].field]: texts,
                        [dropdownFields[dropdownListNumber].fieldId]: ids
                    })
                }

                selectedValues = combinedAnswers
            }
        }

        const containsAnomalyToolTip = questionId === '1'
        const containsSeverityTooltip = data.question.toLowerCase().includes('severity')

        return (
            <React.Fragment>
                <div className='review__questionContainer'>
                    <div className='review__questionText'>
                        <span style={{ marginRight: 5 }}>{index}.</span> {data.question}
                        {containsAnomalyToolTip && (
                            <PredictiveTooltip
                                element={
                                    <FeatherIcon
                                        icon='info'
                                        width={20}
                                        height={20}
                                        strokeWidth={1}
                                    />
                                }
                                text={
                                    <React.Fragment>
                                        A large{' '}
                                        <b>
                                            <i>and</i>
                                        </b>{' '}
                                        rare deviation from normal operation, modeled by AI using
                                        the last six months of AHMS data on the entire Global 7500
                                        fleet.
                                    </React.Fragment>
                                }
                                wide={true}
                                icon={true}
                            />
                        )}
                        {containsSeverityTooltip && (
                            <PredictiveTooltip
                                element={
                                    <FeatherIcon
                                        icon='info'
                                        width={20}
                                        height={20}
                                        strokeWidth={1}
                                    />
                                }
                                text={
                                    <React.Fragment>
                                        <ul style={{ paddingLeft: 25 }}>
                                            <li style={{ marginBottom: 10 }}>
                                                <b>
                                                    <i>High</i>
                                                </b>
                                                : the anomaly could ultimately compromise the safety
                                                of the passengers or lead to an AOG.
                                            </li>
                                            <li style={{ marginBottom: 10 }}>
                                                <b>
                                                    <i>Medium</i>
                                                </b>
                                                : the anomaly could cause delays or cancellations.
                                            </li>
                                            <li style={{ marginBottom: 10 }}>
                                                <b>
                                                    <i>Low</i>
                                                </b>
                                                : the anomaly will likely not impact operations.
                                            </li>
                                        </ul>
                                    </React.Fragment>
                                }
                                wide={true}
                                icon={true}
                            />
                        )}
                    </div>
                    {data.options && (
                        <>
                            {isDropdown ? (
                                <CustomSelectAutocomplete
                                    id={questionId}
                                    handleChange={handleChange}
                                    options={data.options}
                                    placeholder={dropdownFields[dropdownListNumber].placeholder}
                                    fieldId={dropdownFields[dropdownListNumber].fieldId}
                                    field={dropdownFields[dropdownListNumber].field}
                                    selectedValues={selectedValues}
                                />
                            ) : (
                                <CustomRadioGroup
                                    id={questionId}
                                    reviewForm={reviewForm}
                                    handleChange={handleChange}
                                    options={data.options}
                                />
                            )}
                        </>
                    )}
                </div>
            </React.Fragment>
        )
    }

    const ViewMode = ({}) => {
        const [reviewCollapse, setReviewCollapse] = useState(false)

        let name
        let role
        let optionalAnswer = null

        if (!viewingOthersReview) {
            personalSection.questions.forEach(q => {
                let answer = _.find(reviewForm, { question_id: q.question_id })
                if (q.question.toLowerCase().includes('name')) name = answer.answer_text
                else role = answer.answer_text
            })
            if (optionalQuestions.is_visible) {
                let answerObj = _.find(!viewingOthersReview ? reviewForm : selectedReview, {
                    question_id: optionalQuestions.question_id
                })
                optionalAnswer = answerObj.answer_text !== '' ? answerObj.answer_text : null
            }
        } else {
            name = selectedReview.answers[0].answer_text
            role = selectedReview.answers[1].answer_text
            let answerObj = _.find(selectedReview.answers, {
                question_id: optionalQuestions.question_id
            })
            if (answerObj === undefined || answerObj?.answer_text === '') {
                optionalAnswer = null
            } else {
                optionalAnswer = answerObj.answer_text
            }
        }

        return (
            <React.Fragment>
                <div className='review__content'>
                    <AccordionRoot defaultExpanded={true}>
                        <AccordionViewSummary
                            onClick={() => {
                                if (reviewCollapse) questionCount = 0
                                setReviewCollapse(!reviewCollapse)
                            }}
                        >
                            <div className='review__accordionSummary__leftSide'>
                                <FeatherIcon
                                    icon={reviewCollapse ? 'chevron-down' : 'chevron-up'}
                                    width={30}
                                    height={30}
                                    color={'#D2D2D2'}
                                    style={{ margin: '0 15px 0 25px' }}
                                />
                                <div className='review__accordionSummary__leftSide__title'>
                                    <span style={{ fontWeight: 500, fontSize: 20 }}>
                                        Review Submitted by:{' '}
                                    </span>
                                    <span style={{ fontWeight: 700, fontSize: 20 }}>
                                        {name}-{role}
                                    </span>
                                </div>
                            </div>
                            <div
                                className='review__accordionSummary__rightSide'
                                style={{
                                    height: 'fit-content',
                                    width: 'fit-content',
                                    marginRight: 25,
                                    color: '#FFFFFF'
                                }}
                            >
                                Review last updated on:
                                <span style={{ fontWeight: 700, color: '#FFFFFF' }}>
                                    &nbsp;
                                    {moment
                                        .utc(
                                            !viewingOthersReview
                                                ? answeredDate
                                                : new Date(selectedReview.answered_date)
                                        )
                                        .format('MMM DD, YYYY')}
                                </span>
                            </div>
                        </AccordionViewSummary>
                        <AccordionViewDetail>
                            {questionsSection.questions.map((question, index) => {
                                let answerObj = {}
                                let isVisible = !viewingOthersReview ? question.is_visible : true
                                if (isVisible) {
                                    answerObj = _.find(
                                        !viewingOthersReview ? reviewForm : selectedReview.answers,
                                        {
                                            question_id: question.question_id
                                        }
                                    )

                                    if (answerObj === undefined || answerObj.answer_text === '')
                                        isVisible = false
                                    else questionCount++
                                }

                                return (
                                    <React.Fragment
                                        key={`${question.question}-${question.question_id}`}
                                    >
                                        {isVisible && (
                                            <div style={{ fontSize: 16, padding: '15px 0' }}>
                                                <span>
                                                    <span style={{ marginRight: 5 }}>
                                                        {questionCount}.
                                                    </span>{' '}
                                                    {question.question}
                                                </span>
                                                {Array.isArray(answerObj.answer_text) ? (
                                                    <div style={{ marginLeft: 25, marginTop: 10 }}>
                                                        {answerObj.answer_text.map((v, i) => (
                                                            <span
                                                                key={v}
                                                                style={{ marginRight: 5 }}
                                                            >
                                                                <span style={{ fontWeight: 700 }}>
                                                                    {v}
                                                                </span>
                                                                {i !==
                                                                    answerObj.answer_text.length -
                                                                        1 && <>{', '}</>}
                                                            </span>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <span
                                                        style={{
                                                            marginLeft: 10,
                                                            fontWeight: 700,
                                                            wordBreak: 'break-word'
                                                        }}
                                                    >
                                                        {answerObj.answer_text}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </React.Fragment>
                                )
                            })}
                            {optionalAnswer && (
                                <div style={{ fontSize: 16, padding: '15px 0' }}>
                                    <div>
                                        <span style={{ marginRight: 5 }}>{questionCount + 1}.</span>{' '}
                                        {optionalQuestions.question}
                                    </div>
                                    <div style={{ marginLeft: 10, marginTop: 10, fontWeight: 700 }}>
                                        {optionalAnswer}
                                    </div>
                                </div>
                            )}
                        </AccordionViewDetail>
                    </AccordionRoot>
                </div>
                <div className='review__footer'>
                    {!viewingOthersReview ? (
                        rolesPermissions.predictiveMaintenanceFeedbackAccess && (
                            <div className='review__buttonContainer'>
                                <button
                                    className='predictive__button blackButton'
                                    style={{ marginRight: 25 }}
                                    onClick={() => showWithdrawModal()}
                                >
                                    <PredictiveTooltip
                                        element='WITHDRAW'
                                        text='Withdraw submission'
                                        fitted='200px'
                                        centered={true}
                                    />
                                </button>
                                <button
                                    className={`predictive__button whiteButton`}
                                    style={{ margin: 0 }}
                                    onClick={() => handleEditClick()}
                                >
                                    <PredictiveTooltip
                                        element='EDIT'
                                        text='Edit responses'
                                        fitted='150px'
                                        centered={true}
                                    />
                                </button>
                            </div>
                        )
                    ) : (
                        <div className='review__footer'>
                            <div className='review__buttonContainer'>
                                <button
                                    className={`predictive__button whiteButton`}
                                    style={{ margin: 0 }}
                                    onClick={() => handleSubmittedReviewsTabClick()}
                                >
                                    <PredictiveTooltip
                                        element='ALL REVIEWS'
                                        text='View all reviews'
                                        fitted='180px'
                                        centered={true}
                                    />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className='infoBox__header infoBox__tabContainer'>
                <button
                    className={'infoBox__tabPanel ' + (reviewTabSelected ? 'active' : '')}
                    style={
                        !reviewTabSelected
                            ? { backgroundColor: '#404040', flex: '50% 1' }
                            : { flex: '50% 1' }
                    }
                    onClick={() => handleReviewsTabClick()}
                >
                    {reviewed ? 'My Review' : 'Review Questionaire'}
                </button>
                <button
                    className={'infoBox__tabPanel ' + (!reviewTabSelected ? 'active' : '')}
                    style={
                        reviewTabSelected
                            ? { backgroundColor: '#404040', flex: '50% 1' }
                            : { flex: '50% 1' }
                    }
                    onClick={() => handleSubmittedReviewsTabClick()}
                >
                    Submitted Reviews
                </button>
            </div>
            {reviewTabSelected ? (
                reviewQuestions?.length === 0 ||
                !rolesPermissions.predictiveMaintenanceFeedbackAccess ? (
                    <div className='review__container'>
                        <div className='container-no-content'>No review questions</div>
                    </div>
                ) : (
                    <>
                        {personalSection.questions.length > 0 &&
                        questionsSection.questions.length > 0 &&
                        optionalQuestions &&
                        typeof reviewed === 'boolean' ? (
                            <div className='review__container'>
                                <div className='review__header'>
                                    {rolesPermissions.predictiveMaintenanceFeedbackAccess
                                        ? reviewed
                                            ? 'Click on Edit button if you want to make changes to your responses.'
                                            : 'Submit your review for this anomaly report by completing this responsive questionnaire. You can Edit  or delete your responses after submission.'
                                        : !reviewed && (
                                              <div className='container-no-content'>
                                                  This anomaly has not been reviewed yet
                                              </div>
                                          )}
                                </div>
                                {editMode &&
                                rolesPermissions.predictiveMaintenanceFeedbackAccess ? (
                                    <React.Fragment>
                                        <div className='review__content'>
                                            <AccordionRoot defaultExpanded={true}>
                                                <AccordionEditSummary
                                                    onClick={() => {
                                                        handleQuestionsChange(
                                                            'Personal Information',
                                                            'isCollapse',
                                                            !personalSection.isCollapse
                                                        )
                                                    }}
                                                >
                                                    <div className='review__accordionSummary__leftSide'>
                                                        <FeatherIcon
                                                            icon={
                                                                personalSection.isCollapse
                                                                    ? 'chevron-down'
                                                                    : 'chevron-up'
                                                            }
                                                            width={30}
                                                            height={30}
                                                            color={'#D2D2D2'}
                                                            style={{ margin: '0 15px 0 25px' }}
                                                        />
                                                        <div className='review__accordionSummary__leftSide__title'>
                                                            {personalSection.title}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className='review__accordionSummary__rightSide'
                                                        style={{
                                                            background: personalSection.isComplete
                                                                ? '#FFFFFF'
                                                                : '#909090'
                                                        }}
                                                    >
                                                        <PredictiveTooltip
                                                            element={
                                                                <div className='section-complete-check-icon'>
                                                                    <FeatherIcon
                                                                        icon={'check-circle'}
                                                                        width={30}
                                                                        height={30}
                                                                        color={'#303030'}
                                                                    />
                                                                </div>
                                                            }
                                                            text={
                                                                personalSection.isComplete
                                                                    ? 'Section completed'
                                                                    : 'Section not completed'
                                                            }
                                                            fitted='200px'
                                                            centered={true}
                                                            icon={true}
                                                        />
                                                    </div>
                                                </AccordionEditSummary>
                                                <AccordionPersonalInfoDetail>
                                                    <div className='review__questionContainer_textField'>
                                                        <div className='review__questionText'>
                                                            {personalSection.questions[0].question}
                                                        </div>
                                                        <CustomTextField
                                                            key='full-name'
                                                            placeholder={`Enter your ${personalSection.questions[0].question.toLowerCase()}`}
                                                            name={personalSection.questions[0].question_id.toString()}
                                                            value={
                                                                (reviewForm &&
                                                                    reviewForm.length > 0 &&
                                                                    _.find(reviewForm, {
                                                                        question_id: '-1'
                                                                    }).answer_text) ||
                                                                ''
                                                            }
                                                            type='text'
                                                            margin='none'
                                                            onChange={e => {
                                                                const { name, value } = e.target
                                                                handleChange(name, value)
                                                            }}
                                                            variant='outlined'
                                                        />
                                                        {textError.error && (
                                                            <div
                                                                style={{
                                                                    color: 'red',
                                                                    marginTop: 10
                                                                }}
                                                            >
                                                                {textError.message}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='review__questionContainer_textField'>
                                                        <div className='review__questionText'>
                                                            {personalSection.questions[1].question}
                                                        </div>
                                                        <CustomSelect
                                                            placeholder={`Select your ${personalSection.questions[1].question.toLowerCase()}`}
                                                            id={'0'}
                                                            reviewForm={reviewForm}
                                                            handleChange={handleChange}
                                                            options={
                                                                personalSection.questions[1].options
                                                            }
                                                            currValue={
                                                                reviewForm &&
                                                                reviewForm.length > 0 &&
                                                                _.find(reviewForm, {
                                                                    question_id: '0'
                                                                }).answer_ids
                                                            }
                                                            label={'option'}
                                                            value={'option_id'}
                                                        />
                                                    </div>
                                                </AccordionPersonalInfoDetail>
                                            </AccordionRoot>

                                            <AccordionRoot defaultExpanded={true}>
                                                <AccordionEditSummary
                                                    onClick={() => {
                                                        handleQuestionsChange(
                                                            'Review Questions',
                                                            'isCollapse',
                                                            !questionsSection.isCollapse
                                                        )
                                                    }}
                                                >
                                                    <div className='review__accordionSummary__leftSide'>
                                                        <FeatherIcon
                                                            icon={
                                                                questionsSection.isCollapse
                                                                    ? 'chevron-down'
                                                                    : 'chevron-up'
                                                            }
                                                            width={30}
                                                            height={30}
                                                            color={'#D2D2D2'}
                                                            style={{ margin: '0 15px 0 25px' }}
                                                        />
                                                        <div className='review__accordionSummary__leftSide__title'>
                                                            <PredictiveTooltip
                                                                element={questionsSection.title}
                                                                text={questionsSection.title}
                                                                fitted='170px'
                                                                centered={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className='review__accordionSummary__rightSide'
                                                        style={{
                                                            background: questionsSection.isComplete
                                                                ? '#FFFFFF'
                                                                : '#909090'
                                                        }}
                                                    >
                                                        <PredictiveTooltip
                                                            element={
                                                                <div className='section-complete-check-icon'>
                                                                    <FeatherIcon
                                                                        icon={'check-circle'}
                                                                        width={30}
                                                                        height={30}
                                                                        color={'#303030'}
                                                                    />
                                                                </div>
                                                            }
                                                            text={
                                                                questionsSection.isComplete
                                                                    ? 'Section completed'
                                                                    : 'Section not completed'
                                                            }
                                                            fitted='200px'
                                                            centered={true}
                                                            icon={true}
                                                        />
                                                    </div>
                                                </AccordionEditSummary>
                                                <AccordionQuestionsDetail>
                                                    {questionsSection?.questions?.length > 0 &&
                                                        questionsSection.questions.map(
                                                            (question, index) => {
                                                                if (question.is_visible)
                                                                    questionCount++
                                                                return (
                                                                    <React.Fragment
                                                                        key={`${question.question}-${question.question_id}`}
                                                                    >
                                                                        {question.is_visible && (
                                                                            <SingleTypeQuestion
                                                                                data={question}
                                                                                index={
                                                                                    questionCount
                                                                                }
                                                                            />
                                                                        )}
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        )}
                                                    {optionalQuestions?.is_visible && (
                                                        <div className='review__questionContainer__comment'>
                                                            <div className='review__questionText'>
                                                                {questionCount + 1}.{' '}
                                                                {optionalQuestions.question}
                                                            </div>
                                                            <CustomTextareaAutosize
                                                                key='optional'
                                                                placeholder='Add information here...'
                                                                name={optionalQuestions.question_id}
                                                                value={
                                                                    (reviewForm &&
                                                                        reviewForm.length > 0 &&
                                                                        _.find(reviewForm, {
                                                                            question_id:
                                                                                optionalQuestions.question_id
                                                                        })?.answer_text) ||
                                                                    ''
                                                                }
                                                                onChange={e => {
                                                                    const { name, value } = e.target
                                                                    handleChange(name, value)
                                                                }}
                                                                variant='outlined'
                                                                maxRows={4}
                                                            />
                                                        </div>
                                                    )}
                                                </AccordionQuestionsDetail>
                                            </AccordionRoot>
                                        </div>
                                        <div className='review__footer'>
                                            <div className='review__buttonContainer'>
                                                <button
                                                    className='predictive__button blackButton'
                                                    style={{ marginRight: 25 }}
                                                    onClick={() => {
                                                        if (reviewed) showSaveChangesModal()
                                                        else handleResetClick(false)
                                                    }}
                                                >
                                                    <PredictiveTooltip
                                                        text={reviewed ? 'Cancel' : 'Reset form'}
                                                        element={reviewed ? 'CANCEL' : 'RESET'}
                                                        fitted='110px'
                                                        centered={true}
                                                        lifted={true}
                                                    />
                                                </button>
                                                {submitDisabled ? (
                                                    <button
                                                        className='predictive__button disabledButton'
                                                        style={{ margin: 0 }}
                                                    >
                                                        <PredictiveTooltip
                                                            text='Answer all questions to enable submit button'
                                                            element={
                                                                reviewed ? 'RESUBMIT' : 'SUBMIT'
                                                            }
                                                            fitted='220px'
                                                            centered={true}
                                                            lifted={true}
                                                        />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className='predictive__button whiteButton'
                                                        style={{ margin: 0 }}
                                                        onClick={() => {
                                                            handleSubmitClick()
                                                        }}
                                                    >
                                                        <PredictiveTooltip
                                                            text='Submit Review'
                                                            element={
                                                                reviewed ? 'RESUBMIT' : 'SUBMIT'
                                                            }
                                                            fitted='130px'
                                                            centered={true}
                                                            lifted={true}
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    reviewed && <ViewMode />
                                )}
                                <ReviewModal
                                    show={currModal.show}
                                    closeModal={closeModal}
                                    icon={currModal.icon}
                                    iconColor={currModal.iconColor}
                                    headerText={currModal.headerText}
                                    bodyText={currModal.bodyText}
                                    containsOKButton={currModal.containsOKButton}
                                    containsCancelButton={currModal.containsCancelButton}
                                    containsWidthdrawButton={currModal.containsWidthdrawButton}
                                    refreshPage={currModal.refreshPage}
                                />
                            </div>
                        ) : (
                            <div className='review__circularProgress__container'>
                                <CircularProgress style={{ color: '#979797' }} />
                                <div
                                    style={{
                                        fontWeight: 700,
                                        fontSize: 20,
                                        color: '#FFFFFF',
                                        marginTop: 10
                                    }}
                                >
                                    Loading questions...
                                </div>
                            </div>
                        )}
                    </>
                )
            ) : // Submitted Reviews tab
            allReviews && allReviews.length > 0 ? (
                !viewingOthersReview ? (
                    <div className='review__container'>
                        <div className='review__header'>
                            You may view Reviews for this anomaly report submitted by other users.
                        </div>
                        <div className='review__content' style={{ paddingBottom: '25px' }}>
                            <div className='infoBox'>
                                <div className='infoBox__header infoBox__header--with-columns'>
                                    <span>Name of User</span>
                                    <span>Title</span>
                                    <span>Date of last sumbission</span>
                                </div>
                                <div className='infoBox__body'>
                                    {allReviews.map(review => {
                                        return (
                                            <React.Fragment key={review.user_id}>
                                                <div
                                                    className='infoBox__body__row review__allReviews_row'
                                                    onClick={() => {
                                                        handleReviewClick(review)
                                                    }}
                                                >
                                                    <span>{review.answers[0].answer_text}</span>
                                                    <span>{review.answers[1].answer_text}</span>
                                                    <span>
                                                        {moment
                                                            .utc(new Date(review.answered_date))
                                                            .format('MMM DD, YYYY')}
                                                    </span>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='review__container'>
                        <div className='review__header'>
                            You can only View this report. No Edit option available for reviews
                            submitted by other users.
                        </div>
                        <ViewMode />
                    </div>
                )
            ) : (
                <div className='review__container'>
                    <div className='review__header'>
                        <div className='container-no-content'>
                            This anomaly has not been reviewed by other users yet
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default Review
