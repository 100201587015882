import React, { Component } from "react";
import "./Puck.css";
import CustomTooltip from "../Global Components/CustomTooltip";

/**
 * The individual puck component.
 */
export default class Puck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pFlagTopClass: "flag-5-info",
      pFlagBottomClass: "flag-5-info",
      pFlagTopCount: 0,
      pFlagBottomCount: 0,
      pFooterClass: "footer-landed",
      puckDisable: true,
      hover: false,
    };
  }

  componentDidMount() {
    this.togglePuckColors();
  }

  /**
   * Sets the puck color depending on its flight status.
   */
  togglePuckColors() {
    let pFooter;

    if (this.props.status === "IN-FLIGHT") {
      pFooter = "puck__footer inFlight";
    } else if (this.props.status === "ON GROUND") {
      pFooter = "puck__footer landed";
    } else {
      pFooter = "puck__footer landed";
    }

    this.setState({
      pFooterClass: pFooter,
      pFlagTopClass: this.props.flagSeverityTop,
      pFlagBottomClass: this.props.flagSeverityBottom,
      pFlagTopCount: this.props.flagCountTop,
      pFlagBottomCount: this.props.flagCountBottom,
    });
  }

  render() {
    return (
      <div id={this.props.tail} className="puck">
        <div className="puck__body">
          <div className="puck__body__flags">
            <CustomTooltip
              content={this.state.pFlagTopCount}
              tooltipType={"general"}
              placement={"top"}
            >
              <div className={this.state.pFlagTopClass}></div>
            </CustomTooltip>
            <CustomTooltip
              content={this.state.pFlagBottomCount}
              tooltipType={"general"}
              placement={"bottom"}
            >
              <div className={this.state.pFlagBottomClass}></div>
            </CustomTooltip>
          </div>
          <div className="puck__body__content">
            <div className="puck__body__content__tail">{this.props.tail}</div>
            <div className="puck__body__content__family__modal">
              {this.props.aircraftFamily} {this.props.aircraftModel}
            </div>
          </div>
        </div>
        <div className={this.state.pFooterClass}>
          <div className="tail">{this.props.serial}</div>
          <div className="status">{this.props.status}</div>
        </div>
      </div>
    );
  }
}
