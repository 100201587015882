import React from 'react';
import Card from '../Card.jsx';
import * as C from '../Colors';
import { AlertCircle, Mail } from "react-feather";
import { startCase } from 'lodash';

const overview = (
  <div>
    Lastly, settings can be changed on the <b>CONFIGURATION</b> page. 
  </div>
)

const access = (
  <div>
    Access the <b>CONFIGURATION page</b> through the sidebar.
  </div>
)

const category = (
  <div>
    Choose a <b>CATEGORY</b> to make edits. You can make changes at any time.
  </div>
)

// const fdeSideBarDescr = (
//   <div>
//     Use the <b>Filtered FDE</b> tab to personalize the type of CAS messages 
//     you do not want to see by default for any aircraft family and model. 
//   </div>
// ); 

// const notifSideBarDescr = (
//   <div>
//     The <b>Notifications</b> tab allows you to configure the In-App <AlertCircle/> and 
//     Email <Mail/> settings for setting key aircraft events such as 
//     Takeoff / Landing and CAS messages.
//   </div>
// ); 

// const aircraftSideBarDescr = (
//   <div>
//     Use Aircraft Settings to toggle the <b>RPD request</b> functionality.
//     <div style={{fontStyle: "italic"}}>*Available to certain A/C platforms only</div>
//   </div>
// )

// const configSideBarDescr = (
//   <div>
//     Use the <b>Configure Hours</b> settings page to modify Airframe or Engine Hours &#38; Cycles. 
//     Click “Save” to make updates.
//     <br/> 
//     The Log tab shows previous modifications.
//   </div>
// )

const stepContent = [
  {
    steps: [
      overview
    ],
    placement: "center",
  },
  {
    steps: [
      access
    ],
    target: "#Configuration",
    placement: "right",
  },
  {
    steps: [
      category
    ],
    target: "#configOptionsTable__rows",
    placement: 'right-start',
  }
  // {
  //   steps: [
  //     fdeSideBarDescr
  //   ],
  //   target: "#FilteredFDE",
  //   placement: 'right-start',
  //   floaterProps: {
  //     placement: 'right-start',
  //   },
  //   spotlightClicks: true,
  // },
  // {
  //   steps: [
  //     notifSideBarDescr
  //   ],
  //   target: "#Notifications",
  //   placement: 'right-start',
  //   floaterProps: {
  //     placement: 'right-start',
  //   },
  //   spotlightClicks: true,
  // },
  // {
  //   steps: [
  //     aircraftSideBarDescr
  //   ],
  //   target: "#AircraftSettings",
  //   placement: 'right-start',
  //   floaterProps: {
  //     placement: 'right-start',
  //   },
  //   spotlightClicks: true,
  // },
  // {
  //   steps: [
  //     configSideBarDescr
  //   ],
  //   target: "#ConfigureHours",
  //   placement: 'right-start',
  //   floaterProps: {
  //     placement: 'right-start',
  //   },
  //   spotlightClicks: true,
  // },
];

export default function ConfigViewOnboarding () {

  // defaults for this set
  const defaultStyles = {
    options: C.styleReset,
    buttonNext: {
      ...C.buttonStyles,
      color: C.blue,
    },
    buttonBack: {
      ...C.buttonStyles,
      background: "transparent",
      border: "1px solid " + C.white,
      color: C.white,
    },
  };

  let trainings = [];

  stepContent.map( (x, index) => {
    trainings.push({
      target: x.target || 'body',
      disableBeacon: x.disableBeacon || true,
      content: 
        <Card paragraphs={x.steps} 
          index={index}
          stepCount={stepContent.length-1}
          pageName={"Configuration"}
        />, 
      placement: x.placement || 'center',
      styles: x.styles || defaultStyles, 
      spotlightClicks: x.spotlightClicks || false,
    });
  });

  return trainings;
}