import moment from "moment";

const pLimit = require("p-limit");

export function formatCodeVisually(txt) {
  if (txt.length >= 7) {
    const tokens = txt.split("");
    const fhalf = tokens.slice(0, 7);
    const shalf = tokens.slice(7);
    const rest = [...fhalf, ` `, ...shalf];
    return rest.join("");
  }
  return txt;
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatDate(rawDate) {
  return moment
    .utc(rawDate)
    .format("MMM DD, YYYY HH:mm:ss")
    .toString();
}

export function parseMessage(txt) {
  let cleanedText = txt;
  const regExp = /\\r\\n/;
  const postProcessExp = /\s*ATA:.+/;
  let value = cleanedText.search(regExp);
  while (value !== -1) {
    cleanedText = cleanedText.replace(regExp, "|");
    value = cleanedText.search(regExp);
  }
  const linesArray = cleanedText.split("|");
  let result;
  const hasMiddleDash = linesArray[0].search("-");
  if (hasMiddleDash !== -1) {
    result = linesArray[3].replace(postProcessExp, "");
  } else {
    result = linesArray[1].replace(postProcessExp, "");
  }
  return result;
}

export function openInTab(url) {
  window.open(url, "_blank");
}

export async function parallelProcessing(promiseArray, concurrentRequests) {
  const concurrentLimit = pLimit(concurrentRequests);
  const requests = promiseArray.map((req) => concurrentLimit(() => req));
  const result = await Promise.all(requests);
  return result;
}

export function tokenHasExpired(timestamp, minutes) {
  const now = moment(new Date());
  const end = moment.unix(timestamp);
  const duration = moment.duration(now.diff(end));
  const durationMinutes = duration.asMinutes();
  return durationMinutes > minutes;
}

export function createUnixTimestamp(dateString) {
  return moment(dateString)
    .unix()
    .toString();
}

export const decodeJWTToken = (token) => {
  return JSON.parse(window.atob(token.split(".")[1]));
};

export function cleanAuthLocalStorage() {
  try {
    // Important for PMx
    let reportIseNum = localStorage.getItem("lastReportIseNum");
    let reportSessionKey = localStorage.getItem("lastReportSessionKey");
    localStorage.clear();

    if (reportIseNum && reportSessionKey) {
      localStorage.setItem("lastReportIseNum", reportIseNum);
      localStorage.setItem("lastReportSessionKey", reportSessionKey);
    }

    return true;
  } catch (error) {
    return false;
  }
}

export function cleanAircrfatLocalStorage() {
  try {
    localStorage.removeItem("tmpTail");
    localStorage.removeItem("tmpAircraftFamily");
    localStorage.removeItem("tmpAircraftModel");
    localStorage.removeItem("tmpSerial");
    localStorage.removeItem("tmpMonitorStatus");
    localStorage.removeItem("tmpFlightHistory");
    localStorage.removeItem("tmpRecentSessionHistory");
    localStorage.removeItem("tmpACSession");
    localStorage.removeItem("tmpACSessionStartDate");
    localStorage.removeItem("tmpACSessionEndDate");
    localStorage.removeItem("tmpACHistorySessionType");
    localStorage.removeItem("tmpACSitaSessionOrder");

    return true;
  } catch (error) {
    return false;
  }
}

export function cleanAhmsLocalStorage() {
  try {
    localStorage.removeItem("chartTailNumber");
    localStorage.removeItem("globalChartIndex");
    localStorage.removeItem("eventParameters");
    localStorage.removeItem("disceteCharts");
    localStorage.removeItem("charts");
    localStorage.removeItem("chartFaultCode");

    return true;
  } catch (error) {
    return false;
  }
}

export function formatCycles(cycleCount) {
  if (!cycleCount) {
    return 0;
  }
  return cycleCount.toFixed();
}

export function formatCyclesText(cycleCount) {
  if (!cycleCount) {
    return "-";
  }
  return formatCycles(cycleCount);
}

export function convertMinutesToHoursMinutes(timeInMinutes) {
  if (!timeInMinutes) {
    return [0, 0];
  }
  const timeInMinutesRound = timeInMinutes.toFixed();
  const minutes = (timeInMinutesRound % 60).toFixed();
  const hours = ((timeInMinutesRound - minutes) / 60).toFixed();
  return [hours, minutes];
}

export function convertMinutesToHoursMinutesText(timeInMinutes) {
  if (!timeInMinutes) {
    return "-";
  }
  const timeValues = convertMinutesToHoursMinutes(timeInMinutes);
  const hours = timeValues[0];
  const minutes = timeValues[1];

  return hours + " hrs. " + minutes + " min.";
}

export function swapArrayLocations(array, index1, index2) {
  const temp = array[index1];
  array[index1] = array[index2];
  array[index2] = temp;

  return array;
}

export const reloadOrRedirectToPage = (path, history, window) => {
  const alreadyThere = window.location.href.includes(path);
  if (alreadyThere) window.location.reload();
  history.push(path);
};

export function sendEmail(email, subject, body) {
  document.location="mailto:"+email+"?subject="+subject+"&body="+body;
}

export function getFormattedTimeDiff(dateOne, dateTwo, showMinutes = true) {
  let formattedTime = "-";

  if (dateOne || dateTwo) {
    dateOne = dateOne
      ? new Date(moment.utc(dateOne).format())
      : new Date(moment.utc().format());
    dateTwo = dateTwo
      ? new Date(moment.utc(dateTwo).format())
      : new Date(moment.utc().format());

    let dateEarlier, dateLater;
    if (dateOne > dateTwo) {
      dateEarlier = dateTwo;
      dateLater = dateOne;
    } else {
      dateEarlier = dateOne;
      dateLater = dateTwo;
    }

    let secondsDiff = (dateLater.getTime() - dateEarlier.getTime()) / 1000;
    const hours = Math.floor(secondsDiff / 3600);
    secondsDiff %= 3600;
    const minutes = Math.floor(secondsDiff / 60);
    formattedTime =
      hours === 0 ? "" : hours === 1 ? hours + " hour " : hours + " hours ";

    if (showMinutes
      || hours === 0) {
      formattedTime +=
        minutes === 0
          ? ""
          : minutes === 1
            ? minutes + " minute "
            : minutes + " minutes ";
    }
    formattedTime = formattedTime === "" ? "0 minutes" : formattedTime;
  }
  return formattedTime;
}

export const getFormattedTimeLapsed = (timestamp) => {
  let tempTimestamp = new Date(moment.utc(timestamp).format());
  let currentDate = new Date(moment.utc().format());
  let secondsDiff = (currentDate.getTime() - tempTimestamp.getTime()) / 1000;

  return secondsDiff;
};

/**
 * @param seconds
 * @returns {{unit: string, value: number}|{unit: string, value}}
 */
export const calculateHumanTimeUnits = (seconds) => {
  let result;
  switch (true) {
    case seconds < 3600 && seconds >= 60:
      result = { value: seconds / 60, unit: "min" };
      break;
    case seconds < 86400 && seconds >= 3600:
      result = { value: seconds / 3600, unit: "hr" };
      break;
    case seconds >= 86400:
      result = { value: seconds / 3600 / 24, unit: "days" };
      break;
    default:
      result = { value: seconds, unit: "sec" };
      break;
  }
  result.value = Math.ceil(result.value);

  return result;
};

// Family Models that are supported in the application
export function allowedAircraftFamilyModels() {
  return [
    // Global 7500
    { aircraftFamily: "Global", aircraftModel: "7500" },
    // Global Vision
    { aircraftFamily: "Global", aircraftModel: "6000" },
    { aircraftFamily: "Global", aircraftModel: "5000 Vision" },
    // Global Classic
    { aircraftFamily: "Global", aircraftModel: "5000" },
    { aircraftFamily: "Global", aircraftModel: "Express" },
    { aircraftFamily: "Global", aircraftModel: "Express XRS" },
    // Challenger
    { aircraftFamily: "Challenger", aircraftModel: "300" },
    { aircraftFamily: "Challenger", aircraftModel: "350" },
    { aircraftFamily: "Challenger", aircraftModel: "650" },
    { aircraftFamily: "Challenger", aircraftModel: "605" },
  ];
}

// Family Models that are supported in the event count feature
export function allowedAircraftFamilyModelsEventHistory() {
  return [
    // Global 7500
    { aircraftFamily: "Global", aircraftModel: "7500" },
    // Global Vision
    { aircraftFamily: "Global", aircraftModel: "6000" },
    // Challenger
    { aircraftFamily: "Challenger", aircraftModel: "300" },
    { aircraftFamily: "Challenger", aircraftModel: "350" },
  ];
}

// Family Models that are supported in the application
export function notificationEnabledAircraftFamilyModels() {
  return [
    { aircraftFamily: "All", aircraftModel: "" },
    { aircraftFamily: "Global", aircraftModel: "6000" },
    { aircraftFamily: "Global", aircraftModel: "5000 Vision" },
    { aircraftFamily: "Global", aircraftModel: "5000" },
    { aircraftFamily: "Global", aircraftModel: "Express" },
    { aircraftFamily: "Global", aircraftModel: "Express XRS" },
    { aircraftFamily: "Challenger", aircraftModel: "300" },
    { aircraftFamily: "Challenger", aircraftModel: "350" },
    { aircraftFamily: "Challenger", aircraftModel: "650" },
    { aircraftFamily: "Challenger", aircraftModel: "605" },
  ];
}

// Family Models that are supported in the application
export function convertAircraftFlightStatus(statusId) {
  const statusString = statusId.toString();
  if (statusString === "1") {
    return "IN-FLIGHT";
  }
  if (statusString === "0") {
    return "ON GROUND";
  }
  return "-";
}

// Family Models that are supported in the application
export function getFaultColor(category) {
  let color = "#808080";

  switch (category) {
    //CMS
    case "Cabin System": //In Flight Entertainment
      color = "#00FFA3";
      break;
    case "Indicating/Recording System": // Camera System
      color = "#5087FF";
      break;
    case "Communications": // Communications
      color = "#8500ED";
      break;
    case "Lights": // Cabin Lighting
      color = "#FFFB02";
      break;
    case "Electrical Power": // Cabin Power Outlet System
      color = "#005C79";
      break;
    case "Water/Waste System": // Water & Waste
      color = "#1C18D6";
      break;
    case "Windows": // Window Shades
      color = "#B05EBE";
      break;
    case "Equipment/Furnishing": // Galley Appliances
      color = "#F37619";
      break;
    // FDE
    case "W":
      color = "#FF5050";
      break;
    case "C":
      color = "#FFC72C";
      break;
    case "A":
      color = "#709DFF";
      break;
    case "N":
      color = "#808080";
      break;
    case "S":
      color = "#FFFFFF";
      break;
    case "OMS":
      color = "#FFC72C";
      break;
    case "MDC":
      color = "#FFC72C";
      break;
    default:
      color = "#303030";
      break;
  }

  return color;
}

export function addAlphaToHexColor(hexColor, alpha) {
  return `${hexColor}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, 0)}`;
}

export const parseQueryParamsFromUrl = (query) => {
  // ?abc=xyz&jkl=opy
  const paramsMap = new Map();
  if (!query || query === "") return paramsMap;
  const params = query.replace("?", "").split("&");
  params.forEach((param) => {
    const tmp = param.split("=");
    paramsMap.set(tmp[0], tmp[1]);
  });

  return paramsMap;
};

export function convertBytesToReadableBytes(bytes) {
  if (!bytes) {
    return "-";
  }

  if (bytes < 1000) {
    return bytes.toString() + " bytes";
  }
  if (bytes < 1000000) {
    return (bytes / 1000).toFixed(2).toString() + " KB";
  }

  return (bytes / 1000000).toFixed(2).toString() + " MB";
}

export const consoleLogErrorOutput = (
  customErrorMessage = "",
  errorCatchMessage = ""
) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "PROD") {
    console.error(customErrorMessage);
  } else {
    console.error(customErrorMessage + " :: " + errorCatchMessage);
  }
};
