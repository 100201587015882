import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import FeatherIcon from "feather-icons-react";
import "./GlobalDropzone.css";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 1,
  borderRadius: 2,
  borderColor: "#979797",
  borderStyle: "dashed",
  backgroundColor: "rgba(255,255,255,0.12)",
  color: "#FFFFFF",
  outline: "none",
  transition: "border .24s ease-in-out",
  height: "140px",
  cursor: "pointer"
};

const activeStyle = {
  borderColor: "#4A90E2",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const disabledStyle = {
  backgroundColor: "#3C3C3C",
  color: "#9C9C9C",
  borderColor: "#686969",
  cursor: "not-allowed"
}
/**
 * The dropzone reusable component.
 * @param handleUploadedFile Handle for when the user uploads files.
 * @param label The dropzone label.
 * @param description The description.
 * @param iconName The icon that will be displayed in the dropzone.
 * @param iconColor The color of the icon.
 * @param required Flag to say if it is a required field.
 * @param isDisabled Flag to disable the dropzone.
 * @param errors Error list to be displayed.
 * @return {JSX.Element}
 * @constructor
 */
export default function GlobalDropzone({
  handleUploadedFile,
  label,
  description,
  iconName,
  iconColor,
  required,
  isDisabled,
  errors
}) {
  const [isDropzoneDisabled, setIsDropzoneDisabled] = useState(true);

  useEffect(() => {
    setIsDropzoneDisabled(isDisabled);
  }, [isDisabled]);

  /**
   * Callback for when the user drops files into the dropzone.
   * @type {(function(*): void)|*}
   */
  const onDrop = useCallback((acceptedFiles) => {
    handleUploadedFile(acceptedFiles);
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone(
    { disabled: isDropzoneDisabled, onDrop }
  );

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(isDropzoneDisabled ? disabledStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept, isDropzoneDisabled]
  );

  return (
    <>
      {label && label !== "" && <p className={"global__dropzone__label"}>{label}</p>}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <FeatherIcon
          icon={iconName}
          height={"35px"}
          width={"35px"}
          color={isDisabled ? "#9C9C9C" : iconColor}
          className={"global__dropzone__icon"}
        />
        <p className={"global__dropzone__description"}>{description}</p>
        {required && <p className={"global__dropzone__requiredText"}>(Required)</p>}
      </div>
      {errors.map((e) => (
        <div key={"dropzone-error-" + e.code}>
          <p className="global__dropzone__error__message">
            <FeatherIcon
              icon={"alert-circle"}
              height={"18px"}
              width={"18px"}
              color={"#FFC72C"}
              style={{
                position: "relative",
                float: "left",
                marginRight: "8px",
              }}
            />{e.message}
          </p>
        </div>
      ))}
    </>
  )
}