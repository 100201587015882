import React, { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ToastNotification.css";

const ToastNotification = (props) => {
    const {
        message,
        item
    } = props;
    useEffect(() => {
        if (message !== "" && message !== null) toast(message);
    }, [message, item]);

    return (
        <ToastContainer
            data-testid={"toast-container"}
            position="top-right"
            autoClose={4000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            pauseOnHover
        />
    )
};

export default ToastNotification;
