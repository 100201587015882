import React, { useEffect, useState } from 'react'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import * as tools from '../utils/CommonTools'
import { getCredentials } from '../clients/LoginClient'
import './GetCredentials.css'
import Spinner from '../Global Components/Spinner.js'
import * as Tools from '../utils/CommonTools'
import { sendLoginSuccessfulEvent } from '../GA4/EventsCatalog.js'

function GetCredentials() {
    const location = useLocation()
    const paramsMap = Tools.parseQueryParamsFromUrl(location.search)
    const { userDetails, originalURL, parameter1, parameter2, parameter3 } = useParams()
    const [accessOrigin] = useState(paramsMap.get('o'))
    const [willRedirect, setWillRedirect] = useState(null)

    function handleError(error) {
        console.error('ERROR: ', error)
        let errorType = 'generic'

        if (error.message === 'Network Error') {
            errorType = 'network'
        } else {
            if (error.response.status && error.response.status === 401) {
                errorType = 'unauthorized'
            }

            if (error.response.status && error.response.status === 500) {
                errorType = 'server'
            }
        }
        localStorage.setItem('credentialsError', errorType)
        setWillRedirect('/unauthorized')
    }

    function handleSuccessfulCredentialRetreival(
        token,
        tokenExpiration,
        refreshToken,
        refreshExpiration,
        userType
    ) {
        const decodedToken = tools.decodeJWTToken(token)

        const userInfo = {
            orgId: decodedToken.identity.organizationId,
            name: decodedToken.identity.user_full_name,
            type: decodedToken.identity.user_type,
            group: decodedToken.identity.usergroup,
            userId: decodedToken.identity.userId,
            userEmail: decodedToken.identity.userEmail
        }

        let userT = 'internal'
        if (userInfo.userId.substring(0, 1).toLowerCase() === 'b') {
            userT = 'internal'
        } else if (userInfo.userId.substring(0, 1).toLowerCase() === 'c') {
            userT = 'customer'
        }

        localStorage.setItem('userInfo', JSON.stringify(userInfo))
        localStorage.setItem('token', token)
        localStorage.setItem('tokenExpiration', tokenExpiration)
        localStorage.setItem('tokenRefresh', refreshToken)
        localStorage.setItem('tokenRefreshExpiration', refreshExpiration)
        localStorage.setItem('userType', userType)

        sendLoginSuccessfulEvent()

        if (originalURL === 'aircraftview' && parameter1 && parameter2 && parameter3) {
            setWillRedirect(`/${originalURL}/${parameter1}/${parameter2}/${parameter3}`)
        } else if (originalURL === 'predictivereports' && parameter1 && parameter2) {
            setWillRedirect(`/${originalURL}/${parameter1}/${parameter2}`)
        } else {
            setWillRedirect('/' + originalURL)
        }
    }

    useEffect(() => {
        getCredentials(userDetails)
            .then(res => {
                handleSuccessfulCredentialRetreival(
                    res.data.access_token,
                    res.data.exp,
                    res.data.refresh_token,
                    res.data.refresh_exp,
                    res.data.user_type
                )
            })
            .catch(error => {
                handleError(error)
            })
    }, [userDetails]) // Dont forget the [] to make it run just once on unmount.

    if (willRedirect !== null) {
        return <Redirect to={willRedirect} />
    } else {
        return (
            <div className='getCredentials__container'>
                <div
                    style={{
                        position: 'fixed',
                        width: '100%',
                        marginLeft: 'calc(-7% + 60px)',
                        marginRight: '-7%',
                        marginTop: '-50px',
                        height: '100%',
                        backgroundColor: 'rgb(0,0,0,.5)',
                        zIndex: '2000'
                    }}
                >
                    <div style={{ width: '100px', paddingTop: '20%', margin: 'auto' }}>
                        <Spinner />
                    </div>
                </div>
            </div>
        )
    }
}

export default GetCredentials
