import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

const GET_USERHELP_DOC_LIST = Api.GET_USERHELP_DOC_LIST;
const GET_USERHELP_DOC = Api.GET_USERHELP_DOC;

async function getDocLink(docHelpId) {
  let result;
  const data = {
    params: {
      docHelpId: docHelpId,
    },
  };

  try {
    result = await AxiosService(
      GET_USERHELP_DOC.url,
      GET_USERHELP_DOC.method,
      data
    );
  } catch (error) {
    console.error(error);
  }
  return result;
}

async function getUserManuals(axiosCancelSource) {
  let result;
  const data = {
    params: {
      docHelpType: "MANUAL",
      statuses: "published",
    },
  };

  try {
    result = await AxiosService(
      GET_USERHELP_DOC_LIST.url,
      GET_USERHELP_DOC_LIST.method,
      data,
      axiosCancelSource
    );
    // const data=[{
    //   "docHelpId": "8C0D479C-D9DB-4B64-9EF5-6055F06A9D22",
    //   "docHelpTitle": "User Link 1",
    //   "docHelpType": "LINK",
    //   "docHelpDescription": "User Link 1 ...wivbaybay cawyuofawuybc awebcawie babecy aowevca uwecay wbecya uebcauywbe",
    //   "docHelpContentFileKey": null,
    //   'docHelpThumbnailFileKey': 'LINK/8C0D479C-D9DB-4B64-9EF5-6055F06A9D22-Thumbnail.png',
    //   "docHelpContentLink": "https://www.annabrandberg.com/_files/ugd/9ad083_cb8ea68862404451b52fed6929103b67.pdf",
    //   "createdByUser": "b8897675",
    //   "lastModifiedByUser": "b8897675",
    //   "createdDate": "2022-09-30T00:00:00.000Z",
    //   "lastModifiedDate": "2022-09-30T00:00:00.000Z",
    //   "status": "created"
    // }]
    // result = {data};
  } catch (error) {
    console.error(error);
  }
  return result;
}

async function getLinks(axiosCancelSource) {
  let result;
  const data = {
    params: {
      docHelpType: "LINK",
    },
  };

  try {
    result = await AxiosService(
      GET_USERHELP_DOC_LIST.url,
      GET_USERHELP_DOC_LIST.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

async function getVideoTutorials(axiosCancelSource) {
  let result;
  const data = {
    params: {
      docHelpType: "VIDEO",
    },
  };

  try {
    result = await AxiosService(
      GET_USERHELP_DOC_LIST.url,
      GET_USERHELP_DOC_LIST.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

async function getAllUserGuides(axiosCancelSource) {
  let result;
  const data = {
    params: {
      docHelpType: "ALL",
    },
  };

  try {
    result = await AxiosService(
      GET_USERHELP_DOC_LIST.url,
      GET_USERHELP_DOC_LIST.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

export {
  getDocLink,
  getUserManuals,
  getLinks,
  getVideoTutorials,
  getAllUserGuides,
};
