import * as Api from "../Constants/ApiCatalog";
import AxiosService from "./AxiosService";
import axios from "axios";

const GROUND_LOADING_LIST_REQUEST = Api.GROUND_LOADING_LIST;
const NEW_GROUND_LOADING_SESSION = Api.NEW_GROUND_LOADING_SESSION;
const UPLOAD_GROUND_LOADING_FILE = Api.UPLOAD_GROUND_LOADING_FILE;
const DELETE_GROUND_LOADING_FILE = Api.DELETE_GROUND_LOADING_FILE;
const SUBMIT_GROUND_LOADING_SESSION = Api.SUBMIT_GROUND_LOADING_SESSION;

/**
 * Gets the ground loading history for a user.
 * @param userId The user id.
 * @param axiosCancelSource The axios cancel source.
 * @return {Promise<AxiosResponse<any>>}
 */
async function getGroundLoadingHistory (
  userId,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      userId: userId
    }
  };

  try {
    result = await AxiosService(
      GROUND_LOADING_LIST_REQUEST.url,
      GROUND_LOADING_LIST_REQUEST.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

/**
 * Creates a new ground loading session.
 * @param userId The user id.
 * @param registrationId The tail registration #.
 * @param axiosCancelSource The axios cancel source.
 * @return {Promise<AxiosResponse<any>>}
 */
async function newGroundLoadingSession (
  userId,
  registrationId,
  axiosCancelSource
) {
  let result;
  const data = {
    userId: userId,
    registrationId: registrationId
  };

  try {
    result = await AxiosService(
      NEW_GROUND_LOADING_SESSION.url,
      NEW_GROUND_LOADING_SESSION.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    throw error;
  }

  return result;
}

/**
 * Sends the file data to the API.
 * @param userId The user id.
 * @param registrationId The tail registration #.
 * @param compressedFileName The compressed file name.
 * @param uncompressedFileName The uncompressed file name.
 * @param compressedFileSize The compressed file size.
 * @param uncompressedFileSize The uncompressed file size.
 * @param groundLoadingId The ground loading id.
 * @param axiosCancelSource The axios cancel source.
 * @return {Promise<AxiosResponse<any>>}
 */
async function uploadGroundLoadingFile (
  userId,
  registrationId,
  compressedFileName,
  uncompressedFileName,
  compressedFileSize,
  uncompressedFileSize,
  groundLoadingId,
  axiosCancelSource
) {
  let result;
  const data = {
    userId: userId,
    registrationId: registrationId,
    compressedFileName: compressedFileName,
    uncompressedFileName: uncompressedFileName,
    compressedFileSize: compressedFileSize,
    uncompressedFileSize: uncompressedFileSize,
    groundLoadingId: groundLoadingId
  };

  try {
    result = await AxiosService(
      UPLOAD_GROUND_LOADING_FILE.url,
      UPLOAD_GROUND_LOADING_FILE.method,
      data,
      axiosCancelSource
    )
  } catch (error) {
    throw error;
  }

  return result;
}

/**
 * Submits a ground loading session.
 * @param userId The user id.
 * @param groundLoadingSessionId The ground loading session id.
 * @param registrationId The tail registration #.
 * @param notes The notes for the ground loading session.
 * @param axiosCancelSource The axios cancel source.
 * @return {Promise<AxiosResponse<any>>}
 */
async function submitGroundLoadingSession (
  userId,
  groundLoadingSessionId,
  registrationId,
  notes,
  axiosCancelSource
) {
  let result;
  const data = {
    userId: userId,
    id: groundLoadingSessionId,
    registrationId: registrationId,
    notes: notes
  };

  try {
    result = await AxiosService(
      SUBMIT_GROUND_LOADING_SESSION.url,
      SUBMIT_GROUND_LOADING_SESSION.method,
      data,
      axiosCancelSource
    )
  } catch (err) {
    throw err;
  }
  return result;
}

/**
 * Removes a file from a ground loading session.
 * @param fileId The file id.
 * @param userId The user id.
 * @param registrationId The tail registration #.
 * @param groundLoadingId The ground loading id.
 * @param axiosCancelSource The axios cancel source.
 * @return {Promise<AxiosResponse<any>>}
 */
async function deleteGroundLoadingFile (
  fileId,
  userId,
  registrationId,
  groundLoadingId,
  axiosCancelSource
) {
  let result;
  const data = {
    userId: userId,
    id: fileId,
    registrationId: registrationId,
    groundLoadingId: groundLoadingId
  };

  try {
    result = await AxiosService(
      DELETE_GROUND_LOADING_FILE.url,
      DELETE_GROUND_LOADING_FILE.method,
      data,
      axiosCancelSource
    )
  } catch (err) {
    throw err;
  }

  return result;
}

export {
  getGroundLoadingHistory,
  newGroundLoadingSession,
  uploadGroundLoadingFile,
  deleteGroundLoadingFile,
  submitGroundLoadingSession
};