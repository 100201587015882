import _ from "lodash";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Droppable } from "react-beautiful-dnd";

import "./Archives.css";
import FleetList from "../FleetList";
import SearchBox from "../../Global Components/SearchBox";
import AnomalyCard from "../../Global Components/AnomalyCard";
import Months from "../../resources/Months.json";
import { handleReportsSearch, handleFleetSearch } from "../../usefulFunctions";
import { reloadOrRedirectToPage } from "../../../utils/CommonTools.js";
import { useHistory } from "react-router-dom";

const Archives = (props) => {
  const {
    data,
    fleet,
    fleetList,
    filteredReports,
    filteredFleetList,
    updatePageInfo,
    count,

    reportSearch,
    handleReportSearchChange,
    fleetSearch,
    handleFleetSearchChange,
    fleetListLength
  } = props;
  const history = useHistory();

  //for pass search parameter, and full archive list
  // we then filter it based on search param, then format it to create a new obj with month and year
  const formatArchiveData = (searchParam = "", fullList) => {
    const map = {};

    const filteredList = handleReportsSearch(searchParam, fullList);

    filteredList.forEach((item) => {
      // get the month
      const month = Months[new Date(item.first_timestamp).getMonth()];
      const year = new Date(item.first_timestamp).getFullYear();
      const key = `${month} ${year}`;

      // check if month year combination exists, if it does then push that item to that array
      // else, create a new key (month year), and put the new item there
      if (Object.hasOwn(map, key)) {
        map[key].push(item);
      } else {
        map[key] = [item];
      }
    });

    updatePageInfo("filteredReports", { ...map });

    return map;
  };

  const handleFleetPress = (fleet) => {
    const currentFleet = JSON.parse(
      localStorage.getItem("currentArchivesFleet")
    );

    // if we click on the same fleet that is selected again, unselect it and show
    // all the archives
    if (currentFleet?.tailNumber === fleet?.tailNumber) {
      updatePageInfo("fleet", "");
      localStorage.removeItem("currentArchivesFleet");

      formatArchiveData("", data);
    } else {
      updatePageInfo("fleet", fleet);

      // store the fleet we are currently looking at in local storage
      localStorage.setItem("currentArchivesFleet", JSON.stringify(fleet));

      if (reportSearch !== null && reportSearch !== "")
        formatArchiveData(`${reportSearch} ${fleet.shortSerialNumber}`, data);
      else
        formatArchiveData(fleet.shortSerialNumber, data);
    }
  };

  const handleFiltering = (text) => {
    if (text === "") {
      updatePageInfo("fleet", "");
      handleReportSearchChange(null)
    } else {
      handleReportSearchChange(text);
    }

    formatArchiveData(text, data);
  };

  useEffect(() => {
    if (fleet !== "") {
      let fleetIndex = _.findIndex(
        data,
        function (o) {
          return o.serial_number === fleet.shortSerialNumber
        }
      )
      if (fleetIndex !== -1)
        formatArchiveData(fleet.tailNumber, data);
      else {
        updatePageInfo("fleet", "");
        formatArchiveData("", data);
      }
    }
    else
      formatArchiveData("", data);
  }, [data]);

  useEffect(() => {
    let filteredFleets = [];

    data.forEach((report) => {
      if (filteredFleets.length <= fleetListLength) {
        const serialNumber = report.serial_number.toString();

        const fleetListIndex = _.findIndex(
          fleetList,
          function (fleet) {
            return fleet.shortSerialNumber === serialNumber
          }
        );

        const currFleetListIndex = _.findIndex(
          filteredFleets,
          function (fleet) {
            return fleet.shortSerialNumber === serialNumber
          }
        );

        if (fleetListIndex !== -1 && currFleetListIndex === -1) {
          filteredFleets.push(fleetList[fleetListIndex]);
        }
      }
    });

    if (fleetSearch !== "")
      filteredFleets = handleFleetSearch(fleetSearch, filteredFleets);

    updatePageInfo("filteredFleetList", [...filteredFleets], "Archives");
  }, [filteredReports]);

  return (
    <div className="predictiveView__tabContent">
      {/* LEFT SIDE */}
      <FleetList
        data={data}
        selectedFleet={fleet}
        fleetPressed={(fleet) => handleFleetPress(fleet)}
        fleetList={fleetList}
        updatePageInfo={updatePageInfo}
        currTab={"Archives"}
        filteredReports={filteredReports}
        filteredFleetList={filteredFleetList}

        reportSearch={reportSearch}
        fleetSearch={fleetSearch}
        handleFleetSearchChange={handleFleetSearchChange}
      />

      {/* RIGHT SIDE */}
      <div className="archives__rightSide__container">
        <div className="infoBox">
          <div className="infoBox__header">
            Archived In-Service Event Reports
          </div>

          <div className="infoBox__body"
            style={{
              padding: "0 10px 15px",
              overflowX: "hidden",
            }}
          >
            <div className="archives__searchContainer">
              <div className="archives__searchContainer__searchResult">
                {reportSearch != null &&
                  <p>{count} Reports found for "{reportSearch}"</p>
                }
              </div>
              <SearchBox
                width={"450px"}
                handleSearch={handleFiltering}
                placeholder={"Search in the archives for a specific report..."}
              />
            </div>

            <div className="archives__itemsContainer">
              <Droppable
                direction={"horizontal"}
                key={'5'}
                droppableId={'5'}
              >
                {(provided, snapshot) => {
                  return (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <span style={{ display: "none" }}>
                        {provided.placeholder}
                      </span>

                      {filteredReports &&
                        Object?.keys(filteredReports)?.map((date, index) => (
                          <div key={`${date}-${index}`}>
                            <Accordion
                              style={{
                                backgroundColor: "#303030",
                                boxShadow: "none",
                                margin: "0 10px 20px 10px"
                              }}
                              defaultExpanded={true}
                            >
                              <AccordionSummary
                                style={{
                                  display: "flex",
                                  flexFlow: "row"
                                }}
                              >
                                <ExpandMoreIcon />
                                <div className="archives__anomaliesDate">
                                  {date}
                                </div>
                              </AccordionSummary>
                              <AccordionDetails className="archives__listofAnomaliesContainer" >
                                {filteredReports[date].map((item, index) => (
                                  <React.Fragment key={`${item.session_key}-${item.ise_num}`}>
                                    <AnomalyCard
                                      data={item}
                                      index={index}
                                      fleet={fleet}
                                    />
                                  </React.Fragment>
                                ))}
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ))
                      }
                    </div>
                  );
                }}
              </Droppable>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Archives;