import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import CustomCheckbox from "../Global Components/CustomCheckbox.jsx";
import CustomButton from "../Global Components/CustomButton";
import { v4 as uuidv4 } from "uuid";
import * as featureToggleTools from "../Feature Toggles/FeatureToggleTools";

import "./ConfigNotifications.css";

const ConfigNotifications = ({
  notificationsConfigV10, // Response from the get notifications API v10.
  savedSuccessfully, // Flag to control save status.
  updateNotificationsConfigV10, // Method to update the notifications.
  selectedTail,
}) => {
  const [
    currentNotificationsSetting,
    setCurrentNotificationsSetting,
  ] = useState(notificationsConfigV10);
  const [isConfigReady, setConfigIsReady] = useState(true);
  const [allInAppSelected, setAllInAppSelected] = useState(false);
  const [allEmailSelected, setAllEmailSelected] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    setCurrentNotificationsSetting(notificationsConfigV10);
    updateAllSelected();
  }, [notificationsConfigV10]);

  // Checks/unchecks select all boxes when user interacts with the table
  useEffect(() => {
    updateAllSelected();
  }, [currentNotificationsSetting]);

  // Function to see if the select all checkboxes should be checked
  const updateAllSelected = () => {
    let allInAppNotifsSelected = true;
    currentNotificationsSetting
      .filter((notif) => notif.dispatch_type === "INAPP")
      .map((notification) => (
        allInAppNotifsSelected = allInAppNotifsSelected && notification.setting_value
      ));
    setAllInAppSelected(allInAppNotifsSelected);

    let allEmailNotifsSelected = true;
    currentNotificationsSetting
      .filter((notif) => notif.dispatch_type === "EMAIL")
      .map((notification) => (
        allEmailNotifsSelected = allEmailNotifsSelected && notification.setting_value
      ));
    setAllEmailSelected(allEmailNotifsSelected);
  };

  // This transforms the notification events settings titles.
  const transformEventName = (eventName) => {
    switch (eventName) {
      case "EVENT_RPD_REQUEST":
        return "RPD Request";
      case "EVENT_RPD_RESPONSE":
        return "RPD Response";
      case "EVENT_TAKEOFF":
        return "Takeoff Event";
      case "EVENT_LANDING":
        return "Landing Event";
      case "EVENT_WARNING":
        return "Warning Event";
      case "EVENT_CAUTION":
        return "Caution Event";
      case "EVENT_ADVISORY":
        return "Advisory Event";
      case "SYSTEM_ANNOUNCE":
        return "Announce System";
      case "SYSTEM_WELCOME":
        return "Welcome System";
      case "EVENT_STATUS":
        return "Status";
      case "EVENT_INFO":
        return "Info";
      default:
        return " ";
    }
  };

  // Function to check if 2 objects are equal.
  const objectsAreEqual = (ob1, ob2) => {
    return (
      Object.keys(ob1).length === Object.keys(ob2).length &&
      Object.keys(ob1).every((p) => ob1[p] === ob2[p])
    );
  };

  // Handles the checkboxes event, finding the selected notification setting in the API response array and updates its setting_value.
  const handleSelection = (selectedNotification) => {
    const found =
      currentNotificationsSetting.length &&
      currentNotificationsSetting.find((currentNotif) =>
        objectsAreEqual(currentNotif, selectedNotification)
      );
    const updatedCurrentNotificationsSetting =
      currentNotificationsSetting.length &&
      currentNotificationsSetting.map((currentNotif) => {
        return found && objectsAreEqual(found, currentNotif)
          ? { ...currentNotif, setting_value: !currentNotif.setting_value }
          : { ...currentNotif };
      });

    setCurrentNotificationsSetting(updatedCurrentNotificationsSetting);
    setConfigIsReady(false);
  };

  // Handles the select all checkboxes event, finding all notification settings in the API response array and update all setting_values.
  const handleSelectAll = (notificationType) => {
    let allChecked = notificationType === "INAPP" ? !allInAppSelected : !allEmailSelected;
    const updatedCurrentNotificationsSetting =
      currentNotificationsSetting.length &&
      currentNotificationsSetting.map((notif) => {
        if (notif.dispatch_type === notificationType) {
          return { ...notif, setting_value: allChecked }
        } 
        return { ...notif }
      });
    if (notificationType === "INAPP") {
      setAllInAppSelected(allChecked);
    } else {
      setAllEmailSelected(allChecked);
    }
    setCurrentNotificationsSetting(updatedCurrentNotificationsSetting);
    setConfigIsReady(false);
  };

  // Creates the new config data to be saved.
  const handleSaveNewConfig = () => {
    if (selectedTail) {
      let updatedData = {
        serial_number: selectedTail.serialNumber,
        user_id: currentNotificationsSetting.find((ele) => ele)?.user_id,
        aircraft_id: selectedTail.serialNumber,
        user_settings: currentNotificationsSetting.map((event) => {
          return {
            id: event.id,
            user_id: event.user_id,
            notification_type: event.notification_type,
            dispatch_type: event.dispatch_type,
            setting_value: event.setting_value,
          };
        }),
      };

      updateNotificationsConfigV10(updatedData);
      setConfigIsReady(true);
    }
  };

  return (
    <div className="ConfigNotifications__container">
      {userInfo?.userEmail ? (
        <p className="emailBanner">
          Emails will be sent to:{" "}
          <strong id="userEmail">{userInfo?.userEmail}</strong>
        </p>
      ) : (
        <p className="emailBanner">
          The email address provided is not correct, please{" "}
          <strong>contact Bombardier customer support team.</strong>
        </p>
      )}

      <div className="notif-mgmt-ctnr">
        <div className="notif-header-container">
          <div className="notif-header-row-container notif-header-row-container--left">
            <div className="notif-header-row notif-header-row--left">
              <div className="notif-name"></div>
                <div className="notif-state notif-state--left">
                  <CustomCheckbox
                    isChecked={allInAppSelected}
                    onChangeAction={() => {
                      handleSelectAll("INAPP")
                    }}
                  />
                    In-App
                </div>
            </div>
          </div>
          <div className="notif-header-row-container notif-header-row-container--right">
            <div className="notif-header-row notif-header-row--right">
              <div className="notif-state">
                  <CustomCheckbox
                    isChecked={allEmailSelected}
                    onChangeAction={() => {
                      handleSelectAll("EMAIL")
                    }}
                  />
                    Email
                </div>
            </div>
          </div>
        </div>
        <div className="notif-body-wrapper">
          <div className="notif-body-rows-left">
            {currentNotificationsSetting
              .filter((notif) => notif.dispatch_type === "INAPP")
              .map((notification) => (
                // TODO: remove check when PMX has users
                notification.notification_type !== "EVENT_PMX_IN_SERVICE" &&
                // Check if RPD is enabled
                (
                  !featureToggleTools.isFeatureToggleActive("ft-rpd-notifications")
                    ?
                      notification.notification_type !== "EVENT_RPD_REQUEST" &&
                      notification.notification_type !== "EVENT_RPD_RESPONSE"
                    : true
                ) &&
                // Check if info is enabled
                (
                  !featureToggleTools.isFeatureToggleActive("ft-info-notifications")
                    ? notification.notification_type !== "EVENT_INFO"
                    : true
                ) &&
                // Check if status is enabled
                (
                  !featureToggleTools.isFeatureToggleActive("ft-status-notifications")
                    ? notification.notification_type !== "EVENT_STATUS"
                    : true
                ) &&
                <React.Fragment key={uuidv4()}>
                  <div className="notif-value-row">
                    <div className="notif-name">
                      {transformEventName(notification.notification_type)}
                    </div>

                    <div className="notif-state notif-state--left">
                      <CustomCheckbox
                        isChecked={notification.setting_value}
                        onChangeAction={() => {
                          handleSelection(notification);
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
          <div className="notif-body-rows-right">
            {currentNotificationsSetting
              .filter((notif) => notif.dispatch_type === "EMAIL")
              .map((notification) => (
                // TODO: remove check when PMX has users
                notification.notification_type !== "EVENT_PMX_IN_SERVICE" &&
                // Check if RPD is enabled
                (
                  !featureToggleTools.isFeatureToggleActive("ft-rpd-notifications") 
                    ?
                      notification.notification_type !== "EVENT_RPD_REQUEST" &&
                      notification.notification_type !== "EVENT_RPD_RESPONSE"
                    : true
                ) &&
                // Check if info is enabled
                (
                  !featureToggleTools.isFeatureToggleActive("ft-info-notifications")
                    ? notification.notification_type !== "EVENT_INFO"
                    : true
                ) &&
                // Check if status is enabled
                (
                  !featureToggleTools.isFeatureToggleActive("ft-status-notifications")
                    ? notification.notification_type !== "EVENT_STATUS"
                    : true
                ) &&
                <React.Fragment key={uuidv4()}>
                  <div className="notif-value-row-left">
                    <div className="notif-state">
                      <CustomCheckbox
                        isChecked={notification.setting_value}
                        onChangeAction={() => {
                          handleSelection(notification);
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
      <div className="notif-mngmt-submit">
        {savedSuccessfully && isConfigReady ? (
          <CustomButton
            id={"id-notificationManagement-save"}
            text={"SAVED"}
            buttonStyle={"pill"}
            isDisabled={false}
            colorStyle={"custom"}
            customButtonColor={"#99FF65"}
            customButtonTextColor={"#000"}
            customHeight={"46px"}
            icon={<FeatherIcon icon="check" height={20} />}
            iconPosition={"start"}
            onClickAction={() => {
              handleSaveNewConfig();
            }}
          />
        ) : (
          <CustomButton
            id={"id-notificationManagement-save"}
            text={"SAVE"}
            isDisabled={false}
            onClickAction={() => {
              handleSaveNewConfig();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ConfigNotifications;
