import React, { useState, useEffect, useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  alpha,
} from "@mui/material/styles";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import _ from "lodash";

import "./MdcTable.css";

// Adds black and grey colors to the table.
const darkTheme = createTheme({
  palette: {
    background: {
      default: "#1f1e1e",
    },
  },
  typography: {
    fontFamily: "DM Sans",
    allVariants: {
      color: "#FFF",
    },
  },
  components: {
    MuiTableCell: {
      defaultProps: {
        sx: {
          color: "#FFF",
        },
      },
      styleOverrides: {
        root: {
          color: "#FFF",
          padding: "0.06em !important",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#FFF",
          fill: "#FFF",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "rgb(25,25,25) !important",
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#101010 !important",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: "rgb(25,25,25)",
        },
      },
    },
  },
});

const MdcTable = ({
  mdcData,
  setMdcRowData,
  omsRowClickEvent,
  fdeInfoFault,
}) => {
  const [currentRow, setCurrentRow] = useState({});
  const [changeBackgroundOpacity, setChangeBackgroundOpacity] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const lowResolution = useMediaQuery("(max-height:750px)");

  // Columns configuration by rowData[property] or "field" within each object in array.
  const columns = useMemo(
    () => [
      {
        header: "Code",
        accessorKey: "mdcCode",
        size: 50,
        Cell: ({ cell }) => {
          return (
            <div className="MdcTable__countCell__content">
              <span
                className="MdcTable__countCell__color"
                style={
                  cell.row?.original?.mdcMessageType === "Service"
                    ? { backgroundColor: "#FFFFFF" }
                    : { backgroundColor: "#ADA6A6" }
                }
              ></span>
              <span>{cell.row?.original?.mdcCode}</span>
            </div>
          );
        },
      },
      {
        header: "Reporting LRU",
        accessorKey: "mdcReportingLru",
        size: 50,
      },
      {
        header: "Name",
        accessorKey: "mdcMessageName",
        size: 50,
      },
      {
        header: "Timestamp",
        accessorKey: "mdcTimestamp",
        size: 50,
        Cell: ({ cell }) =>
          moment(cell.getValue()).format("MMM D, YYYY - HH:mm:ss"),
      },
    ],
    []
  );

  /**
   * This is used to track which row is selected and get the necessary information for each one.
   * @param {Object} rowData - Row clicked.
   * @returns Set current row and update css styles.
   **/
  const handleRowClick = (rowData) => {
    let newRow = Object.entries(rowData).sort().toString();
    let stateRow = Object.entries(currentRow).sort().toString();

    // Compare the selected row to the new one to either highlight it or not.
    if (newRow === stateRow) {
      omsRowClickEvent(
        rowData.mdcCode,
        moment.utc(rowData.mdcTimestamp).toISOString()
      );

      setCurrentRow({});
      setMdcRowData({});

      return;
    }

    setMdcRowData(rowData);
    setCurrentRow(rowData);
    omsRowClickEvent(
      rowData.mdcCode,
      moment.utc(rowData.mdcTimestamp).toISOString()
    );
  };

  useEffect(() => {
    if (fdeInfoFault !== null) {
      setCurrentRow({});
      setChangeBackgroundOpacity(true);
    } else {
      setChangeBackgroundOpacity(false);
    }
  }, [fdeInfoFault]);

  return (
    <div style={changeBackgroundOpacity ? { opacity: 0.3 } : { opacity: 1 }}>
      <div
        style={
          lowResolution
            ? { maxHeight: "230px", overflowY: "scroll" }
            : { maxHeight: "286px", overflowY: "scroll" }
        }
      >
        {mdcData.length && (
          <>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={darkTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={mdcData}
                  enableHiding={false}
                  enableFullScreenToggle={false}
                  enableSelectAll={false}
                  positionToolbarAlertBanner={"none"}
                  enablePagination={false}
                  enableBottomToolbar={false}
                  enableTopToolbar={false}
                  enableColumnActions={false}
                  enableMultiRowSelection={false}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      setRowSelection((prev) => ({
                        [row?.original?.id]: !prev[row?.original?.id],
                      }));
                      handleRowClick(row?.original);
                    },

                    selected: rowSelection[row?.original?.id],
                    sx: {
                      cursor: "pointer",
                      bgcolor: currentRow
                        ? alpha("rgba(32, 32, 32)", 1)
                        : alpha("rgb(0, 255, 0)", 1),
                    },
                  })}
                  onRowSelectionChange={setRowSelection}
                  state={{
                    rowSelection,
                  }}
                />
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        )}
      </div>
    </div>
  );
};

export default MdcTable;
