import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export function getSeries(response, dataFocus, unit, discreteTranslation, data, parameter) {
    if (!data) {
        data = null;
        if (
            dataFocus === "FAULT" ||
            dataFocus === "RPD" ||
            dataFocus === "IN-FLIGHT"
        ) {
            data = response.data.data || response.data[0].data;
        } else if (dataFocus === "FLIGHT" || dataFocus === "CMS") {
            data = response.data.data || response.data[0].data;

            if (data.length === 0 || isNaN(data[0][1])) {
                data = [];
            }
        }
    }

    const sortedData = data.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b[0]) - new Date(a[0]);
    });

    let lru = response.config.params.lru ? response.config.params.lru : parameter.LRU;

    let series = {
        toggleState: "eye",
        ata: response.config.params.ata,
        englishName: response.config.params.englishName,
        unit: unit,
        name: response.config.params.parameterName,
        type: unit === "Discrete" ? "area" : "line",
        data: sortedData,
        lru: lru,
        isDiscrete: response.config.params.unit === "Discrete" ? true : false,
        discreteTranslation:
            unit === "Discrete"
                ? discreteTranslation(response.config.params.parameterName)
                : null,
    };

    return series;
}

export function getDiscreteNonBinarySeries(s, config, discreteTranslation, parameter) {
    const sortedDate = s.valueStatus.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b[0]) - new Date(a[0]);
    });

    let lru = config.lru ? config.lru : parameter.LRU;

    let series = {
        toggleState: "eye",
        ata: config.ata,
        englishName: s.value,
        unit: "Discrete Non-binary",
        name: s.value,
        type: "area",
        data: sortedDate,
        lru: lru,
        isDiscrete: true,
        discreteTranslation: discreteTranslation,
    };

    return series;
}

export function getDiscreteNonBinaryChartObject(
    index,
    options,
    faultTimeStamp,
    severityColor,
    series
) {
    // Object needed to create a chart
    let chart = {
        chartName: "Discrete Non-Binary_chart_" + index,
        chartIndex: index,
        unit: "Discrete Non-Binary",
        lru: "",
        series: [series],
        options: options,
        type: "line",
        size: "99%",
        id: "discreteNonBinary-" + index,
        index: index,
    };

    const annotations = {
        xaxis: [
            {
                x: new Date(faultTimeStamp).getTime(),
                strokeDashArray: 0,
                borderWidth: 4,
                offsetX: 0,
                offsetY: 0,
                borderColor: severityColor,
                label: {
                    borderColor: severityColor,
                    style: {
                        color: severityColor,
                        background: severityColor,
                    },
                },
            },
        ],
    };

    if (!chart.options.stroke) {
        chart.options.stroke = {}; // Initialize stroke if it's not defined
    }
    if (!chart.options.yaxis) {
        chart.options.yaxis = {}; // Initialize yaxis if it's not defined
    }

    chart.options.annotations = annotations;

    // Modifications for binary discrete data

    chart.options.stroke.curve = "stepline";
    chart.options.stroke.width = 2;
    chart.options.yaxis.tickAmount = 1;
    chart.height = 80;
    return chart;
}

export function getChartObject(
    series,
    index,
    options,
    faultTimeStamp,
    severityColor,
    id
) {
    // Object needed to create a chart
    let chart = {
        chartTitle: series.chartTitle ? series.chartTitle : null,
        chartName: series.unit + "_chart_" + index,
        chartIndex: index,
        englishName: series.name,
        unit: series.unit,
        height: 350,
        lru: series.lru,
        series: [series],
        options: options,
        type: "line",
        size: "99%",
        id: id,
        index: index,
    };

    const annotations = {
        xaxis: [
            {
                x: new Date(faultTimeStamp).getTime(),
                strokeDashArray: 0,
                borderWidth: 2,
                offsetX: 0,
                offsetY: 0,
                borderColor: severityColor,
                label: {
                    borderColor: severityColor,
                    style: {
                        color: severityColor,
                        background: severityColor,
                    },
                },
            },
        ],
    };

    chart.options.annotations = annotations;

    // Modifications for binary discrete data

    if (chart.unit === "Discrete" || chart.unit === "Discrete Non-binary") {
        chart.options.stroke.curve = "stepline";
        chart.options.stroke.width = 2;
        chart.options.yaxis.tickAmount = 1;
        chart.height = 80;
    }
    return chart;
}

export function getFaultSeverityColor(severity) {
    let color = "#FFFFFF";
    switch (severity) {
        case "C":
            color = "#FFC72C";
            break;
        case "W":
            color = "#FF5050";
            break;
        case "A":
            color = "#709DFF";
            break;
        case "S":
            color = "#FFFFFF";
            break;
        default:
            color = "#FFFFFF";
            break;
    }
    return color;
}

export function getUnit(unit) {
    if (unit) {
        if (unit.lastIndexOf(")") !== -1) {
            unit = unit.substring(unit.lastIndexOf("(") + 1, unit.lastIndexOf(")"));
        }
        if (
            unit === "As per Group Definition" ||
            unit === "As per Group definition"
        ) {
            unit = "APGP";
        }
        return unit;
    } else {
        return "n/a";
    }
}

export function getCanvasChartOption(
    unit,
    dataSeries,
    groupedAnomalousPoints,
    colorSet,
    chartType,
    initialZoom,
    stripLineColor
) {
    let stripLines = [];

    groupedAnomalousPoints.forEach((group) => {
        if (group.length > 1) {
            const start = group[0];
            const end = group[group.length - 1];

            stripLines.push({
                startValue: new Date(start),
                endValue: new Date(end),
                color: stripLineColor,
                opacity: 0.3,
                showOnTop: false,
            })
        } else {
            stripLines.push({
                value: new Date(group[0]),
                color: stripLineColor,
                opacity: 0.3,
                thickness: 5,
                showOnTop: false,
            })
        }
    });

    let chartName = "New Chart Unit - " + unit.toString();
    dataSeries.forEach(series => {
        if (series.chartTitle)
            chartName = series.chartTitle
    });

    return {
        id: chartType + "-" + unit + uuidv4(),
        chartName: chartName,
        unit: unit,
        isCollapsed: false,
        chartType: "discrete-line",
        options: {
            height: 60,
            theme: "dark2",
            colorSet: colorSet,
            backgroundColor: "rgb(16, 16, 16)",
            zoomEnabled: true,
            exportEnabled: false,
            animationEnabled: true,
            axisY: {
                gridThickness: 0,
                lineThickness: 1,
                labelAutoFit: true,
                labelMaxWidth: 40,
                labelFontSize: 13,
                minimum: 0,
                maximum: 1,
                interval: 2,
                tickLength: 2,
                valueFormatString: "###,###.####",
                labelFormatter: function (e) {
                    if (e.value.toString().length === 1) {
                        return e.value.toFixed(4);
                    }
                    if (e.value.toString().length === 2) {
                        return e.value.toFixed(3);
                    }
                    if (e.value.toString().length === 3) {
                        return e.value.toFixed(2);
                    }
                    if (e.value.toString().length === 4) {
                        return e.value.toFixed(1);
                    }
                    return e.value;
                },
            },
            axisX: {
                gridThickness: 0,
                tickLength: 3,
                margin: 3,
                lineThickness: 1,
                valueFormatString: "HH:mm:ss",
                labelFontSize: 13,
                labelFormatter: function (e) {
                    return moment.utc(e.value).format("HH:mm:ss");
                },
                stripLines: stripLines,
                viewportMinimum: initialZoom.min,
                viewportMaximum: initialZoom.max
            },
            data: dataSeries,
        },
    };
}

export function getCanvasLineChartOptions(
    unit,
    dataSeries,
    groupedAnomalousPoints,
    colorSet,
    chartType,
    initialZoom,
    stripLineColor,
    featureType = "CONTINUOUS"
) {
    let stripLines = [];

    groupedAnomalousPoints.forEach((group) => {
        if (group.length > 1) {
            const start = group[0];
            const end = group[group.length - 1];

            stripLines.push({
                startValue: new Date(start),
                endValue: new Date(end),
                color: stripLineColor,
                opacity: 0.3,
                showOnTop: false,
            })
        } else {
            stripLines.push({
                value: new Date(group[0]),
                color: stripLineColor,
                opacity: 0.3,
                thickness: 5,
                showOnTop: false,
            })
        }
    });

    let chartName = "New Chart Unit - " + unit.toString();
    dataSeries.forEach(series => {
        if (series.chartTitle)
            chartName = series.chartTitle
    });

    return {
        id: chartType + "-" + unit + uuidv4(),
        featureType: featureType,
        chartName: chartName,
        unit: unit,
        isCollapsed: false,
        chartType: "continuous-line",
        options: {
            height: 350,
            theme: "dark2",
            colorSet: colorSet,
            backgroundColor: "rgb(16, 16, 16)",
            zoomEnabled: true,
            exportEnabled: false,
            animationEnabled: true,
            toolbar: {
                itemBackgroundColor: "transparent",
                itemBackgroundColorOnHover: "#2d2d2d",
                buttonBorderColor: "transparent"
            },
            axisY: {
                gridThickness: 0,
                lineThickness: 1,
                labelAutoFit: true,
                labelMaxWidth: 40,
                labelFontSize: 13,
                tickLength: 2,
                valueFormatString: "###,###.####",
                labelFormatter: function (e) {
                    if (e.value.toString().length === 1) {
                        return e.value.toFixed(4);
                    }
                    if (e.value.toString().length === 2) {
                        return e.value.toFixed(3);
                    }
                    if (e.value.toString().length === 3) {
                        return e.value.toFixed(2);
                    }
                    if (e.value.toString().length === 4) {
                        return e.value.toFixed(1);
                    }
                    return e.value;
                },
            },
            axisX: {
                gridThickness: 0,
                tickLength: 3,
                margin: 3,
                lineThickness: 1,
                valueFormatString: "HH:mm:ss",
                labelFontSize: 13,
                labelFormatter: function (e) {
                    return moment.utc(e.value).format("HH:mm:ss");
                },
                stripLines: stripLines,
                viewportMinimum: initialZoom.min,
                viewportMaximum: initialZoom.max
            },
            data: dataSeries,
        },
    };
}

export function getCanvasParametersOverviewOptions(data, axisYRange, colorSet) {
    return {
        options: {
            theme: "dark2",
            colorSet: colorSet,
            backgroundColor: "#202020",
            // zoomEnabled: true,
            // zoomType: "y",
            exportEnabled: false,
            animationEnabled: true,
            toolTip: {
                shared: true,
                contentFormatter: function (e) {
                    let content = "";
                    let parametersLength = data[0]?.dataPoints?.length;

                    // if shared tooltip
                    let isNegativeValue = e.entries[0]?.dataPoint?.actualValue < 0;
                    if (isNegativeValue) {
                        for (let i = e.entries.length - 1; i >= 0; i--) {
                            const point = e.entries[i].dataPoint;
                            if (point.title === "Actual") {
                                content += `<div class="observed-chart-tooltip minus ${point.x === 0 ? "first" : ""} ${point.x === (parametersLength - 1) ? "last" : ""}">${point.title}<div class="tooltip-text">${point.value}<br/>${point.label}</div></div>`;
                            } else if (point.title === "Expected") {
                                content += `<div class="expected-chart-tooltip minus ${point.x === 0 ? "first" : ""} ${point.x === (parametersLength - 1) ? "last" : ""}">${point.title}<div class='tooltip-text'>${point.value}<br/>${point.label}</div></div>`
                            }
                        }
                    } else {
                        for (let i = 0; i < e.entries.length; i++) {
                            const point = e.entries[i].dataPoint;
                            if (point.title === "Actual") {
                                content += `<div class="observed-chart-tooltip plus ${point.x === 0 ? "first" : ""} ${point.x === (parametersLength - 1) ? "last" : ""}">${point.title}<div class="tooltip-text">${point.value}<br/>${point.label}</div></div>`;
                            } else if (point.title === "Expected") {
                                content += `<div class="expected-chart-tooltip plus ${point.x === 0 ? "first" : ""} ${point.x === (parametersLength - 1) ? "last" : ""}">${point.title}<div class='tooltip-text'>${point.value}<br/>${point.label}</div></div>`
                            }
                        }
                    }

                    return content;
                }
            },
            axisX: {
                labelAngle: -90,
                gridThickness: 1,
                gridColor: "rgba(216, 216, 216, 0.15)",
                tickLength: 20,
                tickColor: "none",
                lineThickness: 1,
                lineColor: "rgba(216, 216, 216, 0.15)",
                labelFontSize: 16,

            },
            axisY: {
                gridThickness: 1,
                gridColor: "rgba(216, 216, 216, 0.15)",
                tickLength: 0,
                lineThickness: 0,
                labelFormatter: function () {
                    return " ";
                },
                stripLines: [{
                    value: 0,
                    color: "#8F96EF",
                    showOnTop: true
                }],
                minimum: axisYRange.min,
                maximum: axisYRange.max
            },
            data: data
        }
    }
};

export function getCanvasDegradationOptions(dataSeries, designFailureLimit, unschedFailLimit, partTotalHours, suggestedRemovalHours, lifeLimit, xMax) {
    const suggestedRemovalHoursObj = {
        name: "Suggested Removal",
        value: suggestedRemovalHours,
        color: "#92d197"
    };

    const lifeLimitObj = {
        name: "Design Removal",
        value: lifeLimit,
        color: "#6cdceb"
    };

    const designFailLimit = {
        name: "Design Failure Limit",
        value: designFailureLimit,
        color: "#c46dc7"
    };

    const unschedFailLimitObj = {
        name: "In service Failure Limit",
        value: unschedFailLimit,
        color: "#e6c647"
    };

    const partTotalHoursObj = {
        name: "Part Total Hours",
        value: partTotalHours,
        color: "#578a64"
    };

    return {
        options: {
            height: 325,
            theme: "dark2",
            backgroundColor: "rgb(16, 16, 16)",
            zoomEnabled: true,
            exportEnabled: false,
            animationEnabled: true,
            toolTip: {
                shared: true,
                enabled: true,
                contentFormatter: function (e) {
                    let content = "<div>";

                    for (let i = 0; i < e.entries.length; i++) {
                        const dataPoint = e.entries[i].dataPoint;
                        const dataSeries = e.entries[i].dataSeries;

                        if (i === 0) {
                            // Adding x axis value
                            content += `<div><span style="font-weight: 700">${dataPoint.x}</span> flight hours</div>`;
                        }

                        content += `<div><span style="color: ${dataSeries.color}; font-weight: 700">${dataSeries.name}:</span> ${(dataPoint.y).toFixed(0)}%</div>`;
                    };

                    content += "</div>";

                    return content;
                }
            },
            axisY: {
                valueFormatString: "0'%'",
                minimum: 0,
                maximum: 100,
                gridThickness: 0.1,
                interval: 20,
                tickLength: 9,
                tickColor: "#7d7d7d",
                lineThickness: 1,
                lineColor: "#7d7d7d",
                labelFontSize: 12,
                margin: 15,
                stripLines: [
                    {
                        value: designFailLimit.value,
                        color: designFailLimit.color,
                        thickness: 1.5,
                        lineDashType: "dash"
                    }, {
                        value: unschedFailLimitObj.value,
                        lineDashType: "solid",
                        thickness: 1.5,
                        color: unschedFailLimitObj.color
                    }
                ]
            },
            axisX: {
                minimum: 0,
                maximum: xMax,
                gridThickness: 0.1,
                tickLength: 9,
                tickColor: "#7d7d7d",
                lineThickness: 1,
                lineColor: "#7d7d7d",
                labelFontSize: 12,
                margin: 15,
                stripLines: [
                    {
                        value: partTotalHoursObj.value,
                        color: partTotalHoursObj.color,
                        thickness: 1.5,
                        lineDashType: "dash",
                    }, {
                        value: suggestedRemovalHoursObj.value,
                        color: suggestedRemovalHoursObj.color,
                        thickness: 1.5,
                        lineDashType: "solid",
                    }, {
                        value: lifeLimitObj.value,
                        color: lifeLimitObj.color,
                        thickness: 1.5,
                        lineDashType: "dash",
                    }
                ]
            },
            data: dataSeries,
        }
    }
};