import * as featureToggleTools from "./FeatureToggleTools";

/**
 * Wrapper to hide React components that should be hidden based on a release feature toggle.
 * If the feature toggle validation is true it will return that cild compoenent
 * @param name
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const FeatureToggleWrapper = ({ name, children }) => {
  if (featureToggleTools.isFeatureToggleActive(name)) {
    return children;
  }

  return null;
};

export default FeatureToggleWrapper;
