import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import "./FilterBox.css";

const textStyle = {
  color: "#D2D2D2",
  fontSize: 16,
  fontFamily: "DM Sans",
  fontWeight: 700,
  cursor: "pointer",
};

const FilterBox = (props) => {
  const {
    width,
    filterOptions,
    currFilters,
    filterClicked,
    handleFilterCategoryCollapse,
    handleFilterApplyClick,
    dateFilter,
    handleDateChange,
    handleFilerResetClick,
  } = props;
  //ref for detecting clicking outside filter dropdown
  const filterBoxRef = useRef(null);
  //ref for detecting clicking inside material ui datepicker
  const dateRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [containsFilters, setContainsFilters] = useState(false);
  const datePickerTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const compareDates = () => {
    if (
      dateFilter.startDate &&
      dateFilter.endDate &&
      new Date(dateFilter.startDate) - new Date(dateFilter.endDate) > 0
    )
      return "The end date entered occurs before the start date.";
  };

  const localToUTC = (dateTime, name) => {
    if (dateTime) {
      const newDate = new Date(dateTime);
      const year = newDate.getUTCFullYear();
      let month = newDate.getMonth() + 1;
      if (month < 10) month = `0${month}`;

      let day = newDate.getDate();
      if (day < 10) day = `0${day}`;

      const utcFromLocal = `${year}-${month}-${day}T00:00:00Z`;

      handleDateChange(name, utcFromLocal);
    } else {
      handleDateChange(name, null);
    }
  };

  const makeLocalAppearUTC = (value) => {
    return new Date(new Date(value).getTime() + 86400000);
  };

  const closeAllCollapsableLists = () => {
    // Collapse all category lists
    filterOptions.forEach((item) => {
      handleFilterCategoryCollapse(item.title, "isCollapsed", true);
    });

    // Collapse date list
    handleDateChange("isCollapsed", true);

    // Collapse whole filter list
    setIsCollapsed(true);
  };

  //THIS IS THE PART THAT DOESN'T WORK
  // apparently the ref encompasses the entire date dropdown as opposed to just the modal
  // so when the date dropdown is open, even if no date is being selected, clicking outside does not close the entire filter dropdown
  const handleClickOutside = (event) => {
    // check if we are clicking inside the filter dropdown
    if (filterBoxRef.current && !filterBoxRef.current.contains(event.target)) {
      // if the datepicker modal is open
      // then we check if we are clicking inside the datepicker modal
      // if we are, then we keep the dropdown open
      // otherwise, we close the dropdown
      if (dateRef.current) {
        if (!dateRef.current && !dateRef.current.contains(event.target)) {
          setIsCollapsed(true);
        }
      } else {
        // if the datepicker modal is not open then we collapse the filter dropdown
        setIsCollapsed(true);
      }
    }
  };

  const handleApplyClick = () => {
    handleFilterApplyClick();

    closeAllCollapsableLists();
  };

  const handleResetClick = () => {
    // Reset filters
    Object.keys(currFilters).forEach((key) => {
      currFilters[key] = [];
    });

    handleFilerResetClick(currFilters);

    // Reset dates
    handleDateChange("startDate", null);
    handleDateChange("endDate", null);

    handleApplyClick();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    let containsAFilter = false;

    // Check if contains filters
    for (const [key, array] of Object.entries(currFilters)) {
      if (array.length > 0) containsAFilter = true;
    }

    // Check if contains date range
    if (dateFilter.startDate || dateFilter.endDate) containsAFilter = true;

    setContainsFilters(containsAFilter);
  }, [currFilters, dateFilter, filterOptions]);

  const DateItem = ({ dateFilter, handleDateChange }) => {
    const startDateInput = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: "7px !important",
      height: 50,
      "& input": {
        color: dateFilter.startDate ? "#D2D2D2" : "rgb(144, 144, 144)",
        fontSize: 16,
        fontFamily: "DM Sans",
        fontWeight: 700,
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiIconButton-label": {
        color: "#D2D2D2",
      },
    };

    const endDateInput = {
      display: "flex",
      paddingRight: "7px !important",
      height: 50,
      "& input": {
        color: dateFilter.endDate ? "#D2D2D2" : "rgb(144, 144, 144)",
        fontSize: 16,
        fontFamily: "DM Sans",
        fontWeight: 700,
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiIconButton-label": {
        color: "#D2D2D2",
      },
    };

    return (
      <React.Fragment>
        <List
          sx={{
            border: "2px solid #D2D2D2",
            margin: "10px 0 0 0",
            padding: 0,
            backgroundColor: "#202020",
          }}
        >
          <ListItemButton
            onClick={() =>
              handleDateChange("isCollapsed", !dateFilter.isCollapsed)
            }
          >
            <ListItemText
              primaryTypographyProps={{
                style: textStyle,
              }}
              primary={dateFilter.title}
            />
            <FeatherIcon
              width={25}
              height={25}
              icon={dateFilter.isCollapsed ? "chevron-down" : "chevron-up"}
              style={{ color: "#D2D2D2" }}
            />
          </ListItemButton>
          <Collapse in={!dateFilter.isCollapsed} timeout="auto" unmountOnExit>
            <List disablePadding ref={dateRef}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={datePickerTheme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableFuture
                      slotProps={{
                        textField: {
                          placeholder: !dateFilter.startDate ? "Start Date" : ""
                        },
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      value={
                        !dateFilter.startDate
                          ? null
                          : makeLocalAppearUTC(dateFilter.startDate)
                      }
                      onChange={(dateTime) => localToUTC(dateTime, "startDate")}
                      className="reports__dateTimePicker"
                      sx={startDateInput}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </StyledEngineProvider>

              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={datePickerTheme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableFuture
                      slotProps={{
                        textField: {
                          placeholder: !dateFilter.endDate ? "End Date" : ""
                        },
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      value={
                        !dateFilter.endDate
                          ? null
                          : makeLocalAppearUTC(dateFilter.endDate)
                      }
                      onChange={(dateTime) => localToUTC(dateTime, "endDate")}
                      className="reports__dateTimePicker"
                      sx={endDateInput}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </StyledEngineProvider>
              <div style={{ color: "red", margin: "0 15px" }}>
                {compareDates()}
              </div>
            </List>
          </Collapse>
        </List>
      </React.Fragment>
    );
  };

  const OptionItem = ({ item, filterClicked }) => {
    return (
      <List
        sx={{
          border: "2px solid #D2D2D2",
          margin: "10px 0 0 0",
          padding: 0,
          backgroundColor: "#202020",
        }}
      >
        <ListItemButton
          onClick={() =>
            handleFilterCategoryCollapse(
              item.title,
              "isCollapsed",
              !item.isCollapsed
            )
          }
        >
          <ListItemText
            primaryTypographyProps={{
              style: textStyle,
            }}
            primary={item.title}
          />
          <FeatherIcon
            width={25}
            height={25}
            icon={item.isCollapsed ? "chevron-down" : "chevron-up"}
            style={{ color: "#D2D2D2" }}
          />
        </ListItemButton>
        <Collapse in={!item.isCollapsed} timeout="auto" unmountOnExit>
          <List disablePadding>
            {item.options.map((subOption) => {
              return (
                <React.Fragment key={subOption.id}>
                  <ListItemButton
                    onClick={() => filterClicked(item.id, subOption.id)}
                  >
                    <FeatherIcon
                      width={25}
                      height={25}
                      icon={
                        currFilters[item.id]?.includes(subOption.id)
                          ? "check-square"
                          : "square"
                      }
                      style={{ color: "#D2D2D2" }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        style: textStyle,
                      }}
                      sx={{ marginLeft: 2 }}
                      primary={subOption.title}
                    />
                  </ListItemButton>
                </React.Fragment>
              );
            })}
          </List>
        </Collapse>
      </List>
    );
  };

  return (
    <List
      sx={{
        width: width,
        position: "relative",
        margin: "10px 15px 20px 0",
        padding: 0,
      }}
      className="filterContainer"
      ref={filterBoxRef}
    >
      <ListItemButton
        sx={{
          border: "2px solid #D2D2D2",
        }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <ListItemText
          primaryTypographyProps={{
            style: {
              color: "#D2D2D2",
              fontSize: 16,
              fontFamily: "DM Sans",
              fontWeight: 600,
              cursor: "pointer",
            },
          }}
          primary="Filter By"
        />
        <FeatherIcon
          width={25}
          height={25}
          icon={isCollapsed ? "chevron-down" : "chevron-up"}
          style={{ color: "#D2D2D2" }}
        />
      </ListItemButton>
      <Collapse
        sx={{
          position: "absolute",
          top: 45,
          zIndex: 3,
          maxHeight: "65vh",
          overflowX: "hidden",
          overflowY: "auto",
          width: width,
          backgroundColor: "#202020",
          padding: "0 15px 15px 15px",
          borderLeft: "2px solid #D2D2D2",
          borderRight: "2px solid #D2D2D2",
          borderBottom: "2px solid #D2D2D2",
        }}
        in={!isCollapsed}
        timeout="auto"
        unmountOnExit
      >
        <List
          sx={{
            padding: 0,
            width: "100%",
          }}
        >
          {filterOptions.map((option) => (
            <React.Fragment key={option.title}>
              <OptionItem item={option} filterClicked={filterClicked} />
            </React.Fragment>
          ))}
          <DateItem
            dateFilter={dateFilter}
            handleDateChange={handleDateChange}
          />
          <div
            className="filterContainer__applyButton"
            onClick={() => handleApplyClick()}
          >
            Apply
          </div>
          {containsFilters && (
            <div
              className="filterContainer__applyButton"
              onClick={() => handleResetClick()}
            >
              Reset
            </div>
          )}
        </List>
      </Collapse>
    </List>
  );
};

export default FilterBox;
