import React, { Component } from "react";
import "../styles/Graph Components/CircleToggle.css";

export default class CircleToggle extends Component {
  render() {
    return (
      // The circleColor will use the CSS Class in CircleToggle.css corresponding to the color name
      // Make your own circle color CSS rule in CircleToggle.css to use more colors
      <div
        className={
          "lollipopChart__circle__toggle " +
          this.props.circleColor +
          `${this.props.opacityToggler === "on" ? " high-opacity " : " low-opacity "}` +
          `${this.props.toggle === "true" ? "no-space" : ""}`
        }
      ></div>
    );
  }
}
