import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import "../styles/Global Components/InformativeBanner.css";

function InformativeBanner({
  bannerMessage,
  linkText,
  linkHandleShowBanner = false,
  handleLinkOnClick,
}) {
  const [showBanner, setShowBanner] = useState(true);
  const handleLinkClick = () => {
    if (linkHandleShowBanner) {
      setShowBanner(!showBanner);
    }
    if (handleLinkOnClick) {
      handleLinkOnClick();
    }
  };

  return (
    <div
      id="template-exists-alert"
      className={
        "informativeBanner informativeBanner__show-" +
        (showBanner ? "true" : "false")
      }
    >
      <div className="informativeBanner__iconContainer">
        <FeatherIcon
          icon="alert-circle"
          width={36}
          height={36}
          className="informativeBanner__icon"
        />
      </div>
      <div className="informativeBanner__messageContainer">
        <div className="informativeBanner__messageType">
          <span>Informative: </span>
        </div>
        <div className="informativeBanner__message">
          <span>{bannerMessage}</span>
        </div>
      </div>
      <div className="informativeBanner__footer">
        <div className="informativeBanner__footer__linkContainer">
          {linkText && (
            <span
              className="informativeBanner__footer__link"
              onClick={handleLinkClick}
            >
              {linkText}
            </span>
          )}
        </div>
        <div className="informativeBanner__footer__indicator"></div>
      </div>
    </div>
  );
}

export default InformativeBanner;
