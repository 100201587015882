import AWS from "aws-sdk";
import jwt from "jsonwebtoken";

// const {
//   REACT_APP_AWS_REGION,
//   REACT_APP_AWS_SECRET_ID,
//   REACT_APP_AWS_ACCESS_KEY_ID,
//   REACT_APP_AWS_SECRET_ACCESS_KEY,
// } = process.env;

// const getAwsCredentials = () => {
//   AWS.config.update({
//     accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
//     secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
//   });

//   AWS.config.getCredentials(function(err) {
//     if (err) {
//       // Credentials Not Loaded
//       throw err;
//     }
//   });

//   return AWS.config.credentials;
// };

// export const decodeSecret = (data) => {
//   let secret;
//   let decodedBinarySecret;
//   if ("SecretString" in data) {
//     secret = data.SecretString;
//   } else {
//     const buff = new Buffer(data.SecretBinary, "base64");
//     decodedBinarySecret = buff.toString("ascii");
//   }
//   return secret || decodedBinarySecret;
// };

// export const getSecrets = async () => {
//   let data;

//   try {
//     const client = new AWS.SecretsManager({
//       credentials: getAwsCredentials(),
//       region: REACT_APP_AWS_REGION,
//     });
//     data = await client
//       .getSecretValue({ SecretId: REACT_APP_AWS_SECRET_ID })
//       .promise();
//     return data;
//   } catch (error) {
//     console.error("Development Access Error 1", error);
//     return data;
//   }
// };

export const createDevelopmentJWT = (userId, secret) => {
  const token = jwt.sign(
    {
      issuer: "Development MyDOM",
      userId,
    },
    secret,
    { expiresIn: "30000" } // 30 seconds => its in ms
  );
  return token;
};
