import _ from 'lodash'
import moment from 'moment'
//import MaterialTable, { MTablePagination } from "material-table";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { getMaterialTableIcons } from '../../../Graph Components/Chart Options/MaterialTableConfig.js'
import FeatherIcon from 'feather-icons-react'
import 'mapbox-gl/dist/mapbox-gl.css'
import { DragDropContext } from 'react-beautiful-dnd'
import React, { useEffect, useState, useRef, useContext, createRef } from 'react'
import mapboxgl from '!mapbox-gl'

import CustomSelectAutocomplete from '../../Global Components/CustomSelectAutocomplete.jsx'
import ChartsController from './IseChartsController.jsx'
import MapContext from '../Context Components/MapContext.jsx'
import ReportContext from '../Context Components/ReportContext.jsx'
import { CHARTS, geoCoordsParams, mapSidebarInfo } from '../../constants.js'
import {
    calculatingLongitude,
    getParamData,
    extractDataPoint,
    getAHMUParamData
} from '../../usefulFunctions.js'
import PredictiveTooltip from '../../Global Components/PredictiveTooltip.jsx'
import ScrollToTopButton from './ScrollToTopButton.jsx'

import './Visualization.css'

mapboxgl.accessToken = `pk.eyJ1Ijoia2hvdW50ZWEiLCJhIjoiY2wzYWFuZjEzMDNwYjNqcjMzN2gzc29rMyJ9.CUTrfbteeAa2EOX34Z_fug`

const Visualization = props => {
    const {
        iseReport,
        aircraftData,
        flightReport,
        time,
        summaryData,
        initialZoom,
        updateCharts,
        additionalCharts,
        contextualCharts,
        continuousCharts,
        engineeredCharts,
        addTimeSeriesData,
        updateParamsToAdd,
        parametersToAdd,
        contextualParametersToAdd,
        continuousParametersToAdd,
        dropdownOptions,
        dropdownLabel,
        optionsIsLoading,
        updateDropdownOptions,
        anomalousPoints
    } = props
    const { mapValue, loadingValue } = useContext(ReportContext)

    const [canvasCharts, setCanvasCharts] = React.useState([])
    const mapContainer = useRef(null)
    const [map, setMap] = mapValue
    // State to keep track if map is a new instance
    const [isNewMap, setIsNewMap] = useState(false)
    const [, setLoading] = loadingValue
    const [iseCoords, setIseCoords] = useState([])
    const [destinationCoords, setDestinationCoords] = useState([])
    const [fullFlightCoords, setFullFlightCoords] = useState({
        longitude: [],
        latitude: []
    })
    const [sidebarInfo, setSidebarInfo] = useState(mapSidebarInfo)
    // Adding ref so we know where to scroll down to once additional contextual parameters loaded
    const addParamContainer = useRef(null)
    // For multiple parameter select options - if option is selected a button will appear to scroll down to charts
    const [showScrollButton, setShowScrollButton] = useState(false)
    //keeping count of charts to show in view chart button
    const [chartsCount, setChartsCount] = useState(0)

    const [canExpand, setCanExpand] = useState(false)
    const [parametersCollapsed, setParametersCollapsed] = useState(false)
    const [mapCollapsed, setMapCollapsed] = useState(false)
    const [AHMSCollapsed, setAHMSCollapsed] = useState(false)
    const [relevantParametersCollapsed, setRelevantParametersCollapsed] = useState(false)
    const [engineeredParametersCollapsed, setEngineeredParametersCollapsed] = useState(false)
    const [additionalParametersCollapsed, setAdditionalParametersCollapsed] = useState(false)
    const [parametersOverviewCollapsed, setParametersOverviewCollapsed] = useState(false)
    const [parametersVisualizationCollapsed, setParametersVisualizationCollapsed] = useState(false)
    const [ahmsDataPoints, setAhmsDataPoints] = useState([])

    //Colors to use in Charts
    const parameterColorArray = [
        '#07B9FC',
        '#FFB300',
        '#9AF000',
        '#fc0755',
        '#0062FF',
        '#33FF00',
        '#FFFE37',
        '#FF6600',
        '#CC00FF',
        '#EA0034'
    ]

    const rangeChartColorArray = [
        '#3d6630',
        '#FFFFFF',
        '#FFFE37',
        '#FF6600',
        '#0062FF',
        '#33FF00',
        '#EA0034',
        '#CC00FF',
        '#9AF000',
        '#fc0755'
    ]

    const updateAhnsDataPoints = newValues => {
        setAhmsDataPoints([...newValues])
    }

    const onExpandAll = () => {
        setCanExpand(false)
        setMapCollapsed(false)
        setParametersCollapsed(false)
        setAHMSCollapsed(false)
        setAdditionalParametersCollapsed(false)
        setRelevantParametersCollapsed(false)
        setEngineeredParametersCollapsed(false)
        setParametersOverviewCollapsed(false)
        setParametersVisualizationCollapsed(false)
    }

    const onCollapseAll = () => {
        setCanExpand(true)
        setMapCollapsed(true)
        setParametersCollapsed(true)
        setAHMSCollapsed(true)
        setAdditionalParametersCollapsed(true)
        setRelevantParametersCollapsed(true)
        setEngineeredParametersCollapsed(true)
        setParametersOverviewCollapsed(true)
        setParametersVisualizationCollapsed(true)
    }

    /** Functions related to Autocomplete Dropdown */
    const onDropdownChange = (event, value, reason) => {
        // need to check when to remove the chip when chart is removed
        if (reason === 'selectOption') {
            if (value.length > 0) {
                let buildParamsToAdd = parametersToAdd ? parametersToAdd : []
                for (let i = 0; i < value.length; i++) {
                    const parameter = value[i]
                    if (parameter) {
                        if (!buildParamsToAdd.includes(parameter)) {
                            setLoading(true)

                            parameter.firstLoad = true
                            parameter.loaded = false
                            parameter.hide = false
                            buildParamsToAdd.push(parameter)
                        }
                    }
                }
                updateParamsToAdd([...buildParamsToAdd], 'additional')
            }
        } else if (reason === 'removeOption' || reason === 'clear') {
            let buildParamsToAdd = []

            for (let i = 0; i < parametersToAdd.length; i++) {
                const parameter = parametersToAdd[i]
                const label = `${parameter.ATAChapter ? parameter.ATAChapter : ''} ${
                    parameter.parameterName
                }`

                if (
                    _.findIndex(value, function (p) {
                        return `${p.ATAChapter ? p.ATAChapter : ''} ${p.parameterName}` === label
                    }) !== -1
                ) {
                    buildParamsToAdd.push(parameter)
                }
            }

            updateParamsToAdd([...buildParamsToAdd], 'additional')
        }
    }

    /** Functions related to Charts */
    const scrollToCharts = () => {
        if (addParamContainer && addParamContainer.current) {
            // Scrolls to Additional Parameters to chart container once it's done loading
            if (window)
                window.scrollTo({
                    behavior: 'smooth',
                    top: addParamContainer.current.offsetTop
                })
        }
    }

    const setCoords = prop => {
        const lng = geoCoordsParams.find(param => param.name === 'Longitude')
        const lat = geoCoordsParams.find(param => param.name === 'Latitude')

        if (lng[prop] && lat[prop]) {
            const coords = [lng[prop], lat[prop]]
            if (prop === 'iseTime') setIseCoords(coords)
            if (prop === 'destinationValue') setDestinationCoords(coords)
        }
    }

    const getGeoCoords = async prop => {
        for (let i = 0; i < geoCoordsParams.length; i++) {
            if (
                (iseCoords.length === 0 || destinationCoords.length === 0) &&
                geoCoordsParams[i][prop]
            ) {
                // If value for geographical coords already exist, set the coords state and return
                setCoords(prop)
                return
            }

            const potentialParams = geoCoordsParams[i].potentialParams
            for (let j = 0; j < potentialParams.length; j++) {
                // Only go to the next potential parameter if the last one returned no value
                if (typeof geoCoordsParams[i][prop] !== 'undefined' && geoCoordsParams[i][prop])
                    break

                const parameter = potentialParams[j]
                let timestamp
                if (prop === 'iseTime' || prop === 'destinationValue') {
                    if (prop === 'iseTime') timestamp = iseReport.end_time
                    if (prop === 'destinationValue') timestamp = flightReport.arrivalTime

                    await getParamData(parameter, aircraftData, timestamp, 0)
                        .then(response => {
                            let data = extractDataPoint(response)

                            if (data === `N/A` && j === potentialParams.length - 1) {
                                geoCoordsParams[i][prop] = `N/A`
                                geoCoordsParams[i].dms = `N/A`
                            } else {
                                geoCoordsParams[i][prop] = data === `N/A` ? null : data

                                setCoords(prop)
                            }
                        })
                        .catch(err => {
                            if (err && err.response && err.response.status === 500) {
                                console.error(`No data available for ${parameter.parameterName}`)
                                if (j === potentialParams.length - 1) {
                                    geoCoordsParams[i][prop] = `N/A`
                                    setCoords(prop)
                                }
                            } else console.error(err)
                        })
                } else if (prop === 'fullFlight') {
                    await getAHMUParamData(
                        parameter,
                        aircraftData,
                        iseReport,
                        time.startTime,
                        time.endTime,
                        500
                    )
                        .then(response => {
                            let data =
                                _.get(response, 'data.data') || _.get(response, 'data[0].data')
                            if (!data || (data && data.length === 0)) data = `N/A`

                            if (data === `N/A` && j === potentialParams.length - 1) {
                                geoCoordsParams[i][prop] = `N/A`
                                return
                            }

                            geoCoordsParams[i][prop] = data === `N/A` ? null : data

                            if (data !== `N/A`) {
                                let name = geoCoordsParams[i].name.toLowerCase()
                                fullFlightCoords[name] = data
                                setFullFlightCoords({ ...fullFlightCoords })
                            }
                        })
                        .catch(err => {
                            if (err && err.response && err.response.status === 500) {
                                console.error(`No data available for ${parameter.parameterName}`)

                                if (j === potentialParams.length - 1) {
                                    let name = geoCoordsParams[i].name.toLowerCase()
                                    fullFlightCoords[name] = `N/A`
                                    setFullFlightCoords({ ...fullFlightCoords })
                                }
                            } else console.error(err)
                        })
                }
            }
        }
    }

    const isMapDoneLoading = () => {
        let flightPathLoaded = false

        if (map) {
            /* Check if flight path exists */
            flightPathLoaded = map.getSource('lines') ? true : false
        }

        return {
            flightPath: flightPathLoaded
        }
    }

    const editLongitudeForAllCoords = array => {
        let newArray = array
        for (let i = 0; i < newArray.length; i++) {
            newArray[i][0] = calculatingLongitude(array[i][0])
        }
        return newArray
    }

    // function to add coords between origin and destination
    const getFullFlightCombinedCoords = () => {
        let coords = []
        let increment = 20 // incrementing by 20 coords every time to add in the flight path
        let longitudeCoords = fullFlightCoords.longitude
        let latitudeCoords = fullFlightCoords.latitude
        let anomalyTime = new Date(iseReport.end_time)
        let arrivalTime = new Date(flightReport.arrivalTime)

        let anomalyIndex = null
        let arrivalIndex = null

        longitudeCoords.forEach((coord, index) => {
            let curr = new Date(coord.x)
            if (curr >= anomalyTime && !anomalyIndex) anomalyIndex = index
            if (curr >= arrivalTime && !arrivalIndex) arrivalIndex = index
        })

        // If the anomalyIndex has a value, but the arrivalIndex is still null after the loop, then assign to it the last index of the longitudeCoords
        if (!arrivalIndex) arrivalIndex = longitudeCoords.length - 1
        anomalyIndex = Math.round(anomalyIndex / increment) * increment // get nearest whole multiple of 20
        arrivalIndex = Math.round(arrivalIndex / increment) * increment // get nearest whole multiple of 20
        let i = 0
        for (i = 0; i < longitudeCoords.length; i = i + increment) {
            if (i === anomalyIndex && i === arrivalIndex) {
                if (anomalyTime > arrivalTime) {
                    coords.push(destinationCoords)
                    coords.push(iseCoords)
                } else {
                    coords.push(iseCoords)
                    coords.push(destinationCoords)
                }
            }
            if (i === anomalyIndex) coords.push(iseCoords)
            else if (i === arrivalIndex) coords.push(destinationCoords)
            else if (
                (longitudeCoords[i] !== 'undefined' || longitudeCoords[i] !== `N/A`) &&
                (latitudeCoords[i] !== 'undefined' || latitudeCoords[i] !== `N/A`)
            ) {
                let lng = longitudeCoords[i].y
                let lat = latitudeCoords[i].y
                coords.push([lng, lat])
            }
        }
        // Replacing the last element in the coords with destinationCoords if it does not contain
        if (!coords.includes(destinationCoords)) coords[coords.length - 1] = destinationCoords
        //https://github.com/mapbox/mapbox-gl-js/issues/3250?fbclid=IwAR3oZeB6pbrecMKxyyMjy6FOkw7CWdoMtCfwWkHXluq_DUT6i8_A6cp9f5Y
        let array1 = null
        let array2 = null
        let array3 = null
        for (let index = 0; index < coords.length - 1; index++) {
            if (Math.abs(coords[index][0] - coords[index + 1][0]) > 100) {
                // Split the coords array into 2 smaller arrays
                array1 = coords.slice(0, index + 1)
                array2 = coords.slice(index + 1)
            }
        }
        if (array2 !== null) array3 = editLongitudeForAllCoords(array2) // Convert the longitude for Coords, E.g: from 172 degree to 188 degree
        let combinedArray = null
        if (array2 !== null && array3 !== null) {
            combinedArray = [...array1, ...array3]
        }

        if (combinedArray !== null) coords = combinedArray

        // Remove all zeroes
        let newCoords = _.filter(coords, function (n) {
            return n[0] !== 0 || n[1] !== 0
        })

        return newCoords
    }

    const getCanvasChartIndex = id => {
        const chartIndex = _.findIndex(canvasCharts, c => {
            return c.chartId === id
        })

        return chartIndex
    }

    const onCanvasDragEnd = result => {
        const { source, destination } = result
        const tempCharts = canvasCharts

        // Avoid any crashes when destination is not detected
        if (!destination || !source) {
            return
        }

        // If source and destination are the same, do nothing
        if (source.droppableId === destination.droppableId) {
            return
        }

        const mapCharts = chartType => {
            let chart, params
            switch (chartType) {
                case CHARTS.CHART_TYPES.ADDITIONAL:
                    chart = additionalCharts
                    params = parametersToAdd
                    break
                case CHARTS.CHART_TYPES.CONTINUOUS:
                    chart = continuousCharts
                    params = continuousParametersToAdd
                    break
                case CHARTS.CHART_TYPES.COLLINEAR:
                    chart = engineeredCharts
                    params = continuousParametersToAdd
                    break
                case CHARTS.CHART_TYPES.CONTEXTUAL:
                    chart = contextualCharts
                    params = contextualParametersToAdd
                    break
            }
            return { chart, params }
        }

        let destChartType = destination.droppableId.split('-')[0]
        const destinationMapCharts = mapCharts(destChartType)
        const destCharts = destinationMapCharts.chart
        let destParamsToAdd = destinationMapCharts.params

        let sourceChartType = source.droppableId.split('-')[0]
        const sourceMapCharts = mapCharts(sourceChartType)
        const sourceCharts = sourceMapCharts.chart
        let sourceParamsToAdd = sourceMapCharts.params

        // It cannot be dragged from continuous to addition
        if (sourceChartType !== destChartType && destChartType === CHARTS.CHART_TYPES.ADDITIONAL) {
            return
        }

        const getChartIndex = (charts, dropId) => {
            return _.findIndex(charts, function (chart) {
                return chart.id === dropId
            })
        }

        const destinationIndex = getChartIndex(destCharts, destination.droppableId)
        const sourceIndex = getChartIndex(sourceCharts, source.droppableId)

        const isValidChart = (charts, dropId) => {
            let index = getChartIndex(charts, dropId)
            if (index === -1) return false
            return !(
                charts[index].unit === CHARTS.UNIT.DISCRETE || charts[index].unit === undefined
            )
        }

        // This condition is so that Discrete charts do not drag and drop
        if (
            !isValidChart(sourceCharts, source.droppableId) ||
            !isValidChart(destCharts, destination.droppableId)
        ) {
            return
        }

        if (destCharts[destinationIndex].options.data.length >= CHARTS.MAXIMUM_NUMBER_OF_SERIES)
            return

        // Find the chart in charts variable array that contains the source
        const sourceCanvasChartIndex = getCanvasChartIndex(sourceCharts[sourceIndex].id)

        // Find the chart in charts variable array that contains the destination
        const destCanvasChartIndex = getCanvasChartIndex(destCharts[destinationIndex].id)

        // This is used for the first item (green square button)
        let sourceDeviationSeries =
            tempCharts[sourceCanvasChartIndex].options.data[source.index - 1]
        // This is used for the second item (not green square button)
        let movedItem = tempCharts[sourceCanvasChartIndex].options.data[source.index]
        const isDraggedParameterContinuous =
            sourceDeviationSeries && sourceDeviationSeries.type === CHARTS.CHART_TYPES.RANGE_AREA
        if (
            destChartType === CHARTS.CHART_TYPES.CONTINUOUS ||
            destChartType === CHARTS.CHART_TYPES.COLLINEAR
        ) {
            if (
                (sourceChartType === CHARTS.CHART_TYPES.CONTINUOUS ||
                    sourceChartType === CHARTS.CHART_TYPES.COLLINEAR) &&
                isDraggedParameterContinuous
            ) {
                sourceDeviationSeries.color = rangeChartColorArray[0]
                sourceDeviationSeries.toggled = true
                sourceDeviationSeries.visible = false
            }
            movedItem.color =
                rangeChartColorArray[tempCharts[destCanvasChartIndex].options.data.length]
        } else {
            movedItem.color =
                parameterColorArray[tempCharts[destCanvasChartIndex].options.data.length]
        }
        // Make sure that the dragged parameter is continuous or additional (in case they both are in the same continuous chart)
        if (
            (destChartType === CHARTS.CHART_TYPES.CONTINUOUS ||
                destChartType === CHARTS.CHART_TYPES.COLLINEAR) &&
            (sourceChartType === CHARTS.CHART_TYPES.CONTINUOUS ||
                sourceChartType === CHARTS.CHART_TYPES.COLLINEAR) &&
            isDraggedParameterContinuous
        ) {
            tempCharts[destCanvasChartIndex].options.data.push(sourceDeviationSeries)
        }
        tempCharts[destCanvasChartIndex].options.data.push(movedItem)
        tempCharts[destCanvasChartIndex].render()

        // Remove moved item from source chart
        if (
            (sourceChartType === CHARTS.CHART_TYPES.CONTINUOUS ||
                sourceChartType === CHARTS.CHART_TYPES.COLLINEAR) &&
            isDraggedParameterContinuous
        ) {
            sourceCharts[sourceIndex].options.data.splice(source.index - 1, 2)
        } else {
            sourceCharts[sourceIndex].options.data.splice(source.index, 1)
        }

        // Delete the chart if the parameter being removed is the last one.
        if (sourceCharts[sourceIndex].options.data.length === 0) {
            for (let cIndex = sourceCanvasChartIndex; cIndex < canvasCharts.length - 1; cIndex++) {
                // Save chart options of both chart state variable
                const newCanvasChartOptions = canvasCharts[cIndex + 1]

                // Replace chart options of both chart state variable
                canvasCharts[cIndex] = newCanvasChartOptions

                // The charts that are changing must be re-rendered by canvas in order to get the changes
                if (canvasCharts[cIndex]) {
                    canvasCharts[cIndex].render()
                }
            }
            canvasCharts.pop()
            sourceCharts.splice(sourceIndex, 1)
        }

        updateCharts(destCharts, destChartType)
        updateCharts(sourceCharts, sourceChartType)
        const movedParameter = movedItem.parameter

        if (
            sourceChartType === CHARTS.CHART_TYPES.ADDITIONAL ||
            destChartType === CHARTS.CHART_TYPES.ADDITIONAL
        ) {
            // Remove parameter from source parameters to add
            let sourceFindParamIndex = _.findIndex(sourceParamsToAdd, [
                'parameterName',
                movedParameter.parameterName
            ])

            if (sourceFindParamIndex !== -1) {
                sourceParamsToAdd.splice(sourceFindParamIndex, 1)
                sourceParamsToAdd = sourceParamsToAdd.filter(item => item.parameter !== undefined)

                updateParamsToAdd(sourceParamsToAdd, sourceChartType)
            }
            destParamsToAdd.push(movedParameter)
            destParamsToAdd = destParamsToAdd.filter(item => item.parameter !== undefined)
            updateParamsToAdd(destParamsToAdd, destChartType)
        }
    }

    const updateCanvasCharts = charts => {
        setCanvasCharts(charts)
    }

    const syncHandler = e => {
        var syncedCharts = canvasCharts
        for (let i = 0; i < syncedCharts.length; i++) {
            let chart = syncedCharts[i]

            if (chart) {
                if (!chart.options.axisX) chart.options.axisX = {}

                if (!chart.options.axisY) chart.options.axisY = {}

                if (e.trigger === 'reset') {
                    chart.options.axisX.viewportMinimum = chart.options.axisX.viewportMaximum = null
                    chart.options.axisY.viewportMinimum = chart.options.axisY.viewportMaximum = null

                    chart.render()
                } else if (chart !== e.chart) {
                    chart.options.axisX.viewportMinimum = e.axisX[0].viewportMinimum
                    chart.options.axisX.viewportMaximum = e.axisX[0].viewportMaximum

                    chart.options.axisY.viewportMinimum = e.axisY[0].viewportMinimum
                    chart.options.axisY.viewportMaximum = e.axisY[0].viewportMaximum

                    chart.render()
                }
            }
        }
    }

    useEffect(() => {
        // if all dropdowns are collapsed
        if (
            mapCollapsed &&
            parametersCollapsed &&
            AHMSCollapsed &&
            relevantParametersCollapsed &&
            additionalParametersCollapsed &&
            parametersOverviewCollapsed &&
            engineeredParametersCollapsed &&
            parametersVisualizationCollapsed
        ) {
            setCanExpand(true)
        } else {
            // if there is a mix of collapsed and expanded dropdowns
            if (
                mapCollapsed ||
                parametersCollapsed ||
                AHMSCollapsed ||
                relevantParametersCollapsed ||
                additionalParametersCollapsed ||
                parametersOverviewCollapsed ||
                engineeredParametersCollapsed ||
                parametersVisualizationCollapsed
            ) {
                setCanExpand(true)
            } else {
                //if all dropdowns are expanded
                setCanExpand(false)
            }
        }
    }, [
        mapCollapsed,
        parametersCollapsed,
        AHMSCollapsed,
        relevantParametersCollapsed,
        additionalParametersCollapsed,
        parametersOverviewCollapsed,
        engineeredParametersCollapsed,
        parametersVisualizationCollapsed
    ])

    useEffect(() => {
        // If map doesn't exist, load it
        if (!map) {
            setLoading(true)
            const mapbox = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/dark-v10',
                center: [0, 0],
                zoom: 1
            })
            setIsNewMap(true)
            setMap(mapbox)
        } else {
            // If map does exist, load it from Report Context
            document.getElementById('map').replaceWith(map.getContainer())
            setIsNewMap(false)
        }

        getGeoCoords('iseTime')
    }, [])

    useEffect(() => {
        // Get geo coordinates at departure and arrival time once we received the Flight Report data
        if (flightReport) {
            getGeoCoords('destinationValue')
        }
    }, [flightReport])

    useEffect(() => {
        // Get geo coordinates for the full flight
        if (time.startTime !== null && time.endTime !== null) {
            getGeoCoords('fullFlight')
        }
    }, [time])

    useEffect(() => {
        const loadMap = () => {
            let isLoaded = isMapDoneLoading()
            // ISE end point
            const isePoint = {
                type: 'Feature',
                properties: {
                    name: 'ISE Location',
                    color: 'orange'
                },
                geometry: {
                    type: 'Point',
                    coordinates: iseCoords
                }
            }

            // Add Destination (Aircraft) Marker
            const destinationPoint = {
                type: 'Feature',
                properties: {
                    name: 'Destination Location',
                    color: 'orange'
                },
                geometry: {
                    type: 'Point',
                    coordinates: destinationCoords
                }
            }

            if (!isLoaded.flightPath) {
                /* Add ISE marker to map */

                // Check if marker already exists
                let elements = document.getElementsByClassName('visualization__map_marker')
                // If it doesn't exist, create it
                if (elements.length === 0) {
                    // Create a HTML element
                    const e = document.createElement('div')
                    e.className = 'anomaly_marker_container'

                    const el = document.createElement('div')
                    el.className = 'visualization__map_marker'

                    const tooltip = document.createElement('div')
                    tooltip.className = 'tooltiptext'
                    const text = document.createElement('span')
                    const bold = document.createElement('strong')
                    const textnode = document.createTextNode('Position of the aircraft')
                    bold.appendChild(textnode)
                    text.appendChild(bold)
                    const nonbold = document.createTextNode(` when ISE occurred`)
                    text.appendChild(nonbold)
                    tooltip.appendChild(text)

                    e.appendChild(tooltip)
                    e.appendChild(el)

                    // Make a marker and add to the map
                    new mapboxgl.Marker(e).setLngLat(isePoint.geometry.coordinates).addTo(map)
                    setMap(map)
                }

                /* Add destination marker to map */
                // Check if marker already exists
                elements = document.getElementsByClassName('visualization__map_dest_marker')
                // If it doesn't exist, create it
                if (elements.length === 0) {
                    // Create a HTML element
                    const e = document.createElement('div')
                    e.className = 'dest_marker_container'

                    const el = document.createElement('div')
                    el.className = 'visualization__map_dest_marker'

                    const tooltip = document.createElement('div')
                    tooltip.className = 'tooltiptext'
                    const text = document.createTextNode('Destination')
                    tooltip.appendChild(text)

                    e.appendChild(tooltip)
                    e.appendChild(el)

                    // Make a marker and add to the map
                    new mapboxgl.Marker(e)
                        .setLngLat(destinationPoint.geometry.coordinates)
                        .addTo(map)
                    setMap(map)
                }

                /* Trace flight path on map */
                let coords = getFullFlightCombinedCoords()

                const flightPathLine = {
                    type: 'Feature',
                    properties: {
                        name: 'Flight Path',
                        color: '#4287f5'
                    },
                    geometry: {
                        type: 'LineString',
                        coordinates: coords
                    }
                }

                map.addSource('lines', {
                    type: 'geojson',
                    lineMetrics: true,
                    data: {
                        type: 'FeatureCollection',
                        features: [flightPathLine]
                    }
                })

                // Add flight path line from origin of flight to destination point
                map.addLayer({
                    id: 'flight-path',
                    type: 'line',
                    source: 'lines',
                    paint: {
                        'line-color': ['get', 'color'],
                        'line-width': 8,
                        'line-gradient': [
                            'interpolate',
                            ['linear'],
                            ['line-progress'],
                            0,
                            '#626c24',
                            1,
                            '#056f9a'
                        ],
                        'line-opacity': 0.85
                    },
                    layout: {
                        'line-cap': 'round'
                    },
                    filter: ['==', '$type', 'LineString']
                })

                // Geographic coordinates of the flight path
                // Create a 'LngLatBounds' with both corners at the first coordinate.
                const coordinates = flightPathLine.geometry.coordinates
                const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])

                // extend the 'LngLatBounds' to include every coordinate in the bounds result.
                for (const coord of coordinates) {
                    bounds.extend(coord)
                }
                // bounds.extend(destinationPoint.geometry.coordinates);
                map.fitBounds(bounds, {
                    padding: { top: 125, bottom: 125, left: 125, right: 125 }
                })

                setMap(map)
            }
        }

        if (
            isNewMap &&
            map &&
            iseCoords.length === 2 &&
            iseCoords[0] !== `N/A` &&
            iseCoords[1] !== `N/A` &&
            destinationCoords.length === 2 &&
            destinationCoords[0] !== `N/A` &&
            destinationCoords[1] !== `N/A` &&
            fullFlightCoords.longitude.length > 0 &&
            fullFlightCoords.latitude.length > 0
        )
            loadMap()
    }, [isNewMap, iseCoords, destinationCoords, fullFlightCoords])

    useEffect(() => {
        const allDoneLoading = () => {
            let loaded = true

            // check if dropdown options are done loading
            if (optionsIsLoading) loaded = false

            return loaded
        }

        if (isNewMap) {
            const isAllDoneLoading = allDoneLoading()
            if (isAllDoneLoading) {
                setLoading(false)
            } else {
                setTimeout(() => {
                    setLoading(false)
                }, 1000 * 30)
            }
        }
    }, [isNewMap, map, sidebarInfo, optionsIsLoading])

    // Similar to componentDidMount and componentDidUpdate:
    // Needed because the charts to initially render correctly
    React.useEffect(() => {
        let newCanvasCharts = canvasCharts

        for (let chart of newCanvasCharts) {
            if (chart && typeof chart !== 'undefined') {
                chart.options.rangeChanged = syncHandler
            }
        }

        var syncedCharts = newCanvasCharts

        for (let i = 0; i < syncedCharts.length; i++) {
            var chart = syncedCharts[i]

            //Syncing charts and making sure things are updates correctly on render
            if (chart && typeof chart !== 'undefined' && chart.container !== null) {
                chart.options.toolTip = {
                    shared: true,
                    borderColor: '#808080',
                    // updated event handler can be used to sync toolTip across multiple charts in a page
                    updated: function (e) {
                        for (let j = 0; j < newCanvasCharts.length; j++) {
                            if (newCanvasCharts[j] != e.chart && newCanvasCharts[j]) {
                                newCanvasCharts[j].toolTip.showAtX(e.entries[0].xValue)
                            }
                        }
                    },
                    hidden: function (e) {
                        for (let j = 0; j < newCanvasCharts.length; j++) {
                            if (newCanvasCharts[j] != e.chart && newCanvasCharts[j]) {
                                newCanvasCharts[j].toolTip.hide()
                            }
                        }
                    }
                }

                chart.render()
            }
        }
    })

    const expandCollapseAllButtons = () => {
        return (
            <div className='visualization__expandCollapseButtonContainer'>
                {canExpand ? (
                    <button
                        className='predictive__button darkNoBorderButton'
                        onClick={() => onExpandAll()}
                    >
                        Expand all
                    </button>
                ) : (
                    <button
                        className='predictive__button darkNoBorderButton'
                        onClick={() => onCollapseAll()}
                    >
                        Collapse all
                    </button>
                )}
            </div>
        )
    }

    return (
        <div className='visualization__container'>
            {expandCollapseAllButtons()}
            <ScrollToTopButton />
            <ReportContext.Provider
                value={{
                    loadingValue
                }}
            >
                <DragDropContext onDragEnd={onCanvasDragEnd}>
                    {/* MAP CONTAINER */}
                    <div className='infoBox__container'>
                        <Accordion
                            style={{ backgroundColor: '#303030' }}
                            defaultExpanded={true}
                            expanded={!mapCollapsed}
                        >
                            <AccordionSummary
                                style={{
                                    backgroundColor: '#505050',
                                    cursor: 'pointer',
                                    borderTopLeftRadius: 5,
                                    borderTopRightRadius: 5,
                                    padding: 0,
                                    minHeight: 55,
                                    height: 55
                                }}
                                onClick={() => setMapCollapsed(!mapCollapsed)}
                            >
                                <div
                                    style={{
                                        color: 'white',
                                        fontWeight: 600,
                                        fontSize: 16,
                                        textAlign: 'center'
                                    }}
                                >
                                    <PredictiveTooltip
                                        element='Flight Path Overview'
                                        text={
                                            <React.Fragment>
                                                <b>Map</b> and <b>contextual</b> data when anomaly
                                                occurred
                                            </React.Fragment>
                                        }
                                        wide={true}
                                    />
                                </div>
                                <PredictiveTooltip
                                    element={
                                        <FeatherIcon
                                            icon={mapCollapsed ? 'chevron-down' : 'chevron-up'}
                                            style={{ color: 'white' }}
                                        />
                                    }
                                    text={mapCollapsed ? 'Expand' : 'Collapse'}
                                    fitted='100px'
                                    centered={true}
                                />
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: 0, color: 'white' }}>
                                <div className='infoBox' style={{ padding: 0 }}>
                                    <div className='infoBox__body' style={{ padding: 0 }}>
                                        <MapContext.Provider
                                            value={{
                                                sidebarValues: [sidebarInfo, setSidebarInfo]
                                            }}
                                        >
                                            <div className='visualization__map'>
                                                <div
                                                    ref={mapContainer}
                                                    id={'map'}
                                                    className='visualization__map_container'
                                                ></div>
                                            </div>
                                        </MapContext.Provider>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* CONTEXTUAL AHMS PARAMETERS / SUPPORTING PARAMETERS*/}
                    {dropdownOptions && (
                        <div className='visualization__chartSectionContainer'>
                            {initialZoom.min && initialZoom.max && anomalousPoints && (
                                <ChartsController
                                    chartType={'contextual'}
                                    title={'Supporting Parameters'}
                                    charts={contextualCharts}
                                    iseReport={iseReport}
                                    aircraftData={aircraftData}
                                    summaryData={summaryData}
                                    parameterColorArray={parameterColorArray}
                                    updateDropdownOptions={updateDropdownOptions}
                                    dropdownOptions={dropdownOptions}
                                    parametersToAdd={contextualParametersToAdd}
                                    updateParamsToAdd={updateParamsToAdd}
                                    initialZoom={initialZoom}
                                    canvasCharts={canvasCharts}
                                    updateCanvasCharts={updateCanvasCharts}
                                    updateCharts={updateCharts}
                                    anomalousPoints={anomalousPoints}
                                    isCollapseProp={AHMSCollapsed}
                                    handleChartCollapse={setAHMSCollapsed}
                                    time={time}
                                />
                            )}
                        </div>
                    )}

                    {/* FLAGGED PARAMETERS + ENG PARAMETERS + ADDITIONAL PARAMETERS*/}
                    <div className='infoBox__container'>
                        <Accordion
                            style={{ backgroundColor: '#303030' }}
                            defaultExpanded={true}
                            expanded={!parametersCollapsed}
                        >
                            <AccordionSummary
                                style={{
                                    backgroundColor: '#505050',
                                    cursor: 'pointer',
                                    borderTopLeftRadius: 5,
                                    borderTopRightRadius: 5,
                                    padding: 0,
                                    minHeight: 55,
                                    height: 55
                                }}
                                onClick={() => setParametersCollapsed(!parametersCollapsed)}
                            >
                                <div
                                    style={{
                                        color: 'white',
                                        fontWeight: 600,
                                        fontSize: 16,
                                        textAlign: 'center'
                                    }}
                                >
                                    Parameters Visualizations
                                </div>
                                <PredictiveTooltip
                                    element={
                                        <FeatherIcon
                                            icon={
                                                parametersCollapsed ? 'chevron-down' : 'chevron-up'
                                            }
                                            style={{ color: 'white' }}
                                        />
                                    }
                                    text={parametersCollapsed ? 'Expand' : 'Collapse'}
                                    fitted='100px'
                                    centered={true}
                                />
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: '#252525', padding: 0 }}>
                                <div className='additionalParamsSearch__container'>
                                    {showScrollButton && (
                                        <button
                                            className='viewCharts__button'
                                            onClick={scrollToCharts}
                                        >
                                            <div>Navigate to charts</div>
                                        </button>
                                    )}
                                    <CustomSelectAutocomplete
                                        handleChange={onDropdownChange}
                                        options={dropdownOptions}
                                        placeholder={
                                            optionsIsLoading
                                                ? '...Loading parameters...'
                                                : dropdownLabel
                                        }
                                        selectedValues={parametersToAdd ? parametersToAdd : []}
                                        optionsIsLoading={optionsIsLoading}
                                        noOptionsText={'No additional parameters found'}
                                    />
                                </div>
                                {dropdownOptions && (
                                    <div className='visualization__chartSectionContainer'>
                                        {/* TOP ANOMALOUS PARAMETERS */}
                                        {addTimeSeriesData &&
                                            initialZoom.min &&
                                            initialZoom.max &&
                                            anomalousPoints && (
                                                <ChartsController
                                                    chartType={'continuous'}
                                                    title={'Flagged Parameters'}
                                                    charts={continuousCharts}
                                                    iseReport={iseReport}
                                                    aircraftData={aircraftData}
                                                    summaryData={summaryData}
                                                    parameterColorArray={rangeChartColorArray}
                                                    updateDropdownOptions={updateDropdownOptions}
                                                    dropdownOptions={dropdownOptions}
                                                    parametersToAdd={continuousParametersToAdd}
                                                    updateParamsToAdd={updateParamsToAdd}
                                                    initialZoom={initialZoom}
                                                    canvasCharts={canvasCharts}
                                                    updateCanvasCharts={updateCanvasCharts}
                                                    updateCharts={updateCharts}
                                                    addTimeSeriesData={addTimeSeriesData}
                                                    anomalousPoints={anomalousPoints}
                                                    isCollapseProp={relevantParametersCollapsed}
                                                    handleChartCollapse={
                                                        setRelevantParametersCollapsed
                                                    }
                                                    darkAccordionSummary={true}
                                                    engineeredCharts={engineeredCharts}
                                                    engineeredChartsCollapseProp={
                                                        engineeredParametersCollapsed
                                                    }
                                                    handleEngineeredChartsCollapse={
                                                        setEngineeredParametersCollapsed
                                                    }
                                                    time={time}
                                                    ahmsDataPoints={ahmsDataPoints}
                                                    updateAhnsDataPoints={updateAhnsDataPoints}
                                                />
                                            )}

                                        {/* ADDITIONAL PARAMETERS */}
                                        {initialZoom.min && initialZoom.max && anomalousPoints && (
                                            <ChartsController
                                                chartType={'additional'}
                                                title={'Additional Parameters'}
                                                charts={additionalCharts}
                                                addParamContainerRef={addParamContainer}
                                                iseReport={iseReport}
                                                aircraftData={aircraftData}
                                                summaryData={summaryData}
                                                parameterColorArray={parameterColorArray}
                                                updateDropdownOptions={updateDropdownOptions}
                                                dropdownOptions={dropdownOptions}
                                                parametersToAdd={parametersToAdd}
                                                updateParamsToAdd={updateParamsToAdd}
                                                initialZoom={initialZoom}
                                                canvasCharts={canvasCharts}
                                                updateCanvasCharts={updateCanvasCharts}
                                                handleScrollButton={value =>
                                                    setShowScrollButton(value)
                                                }
                                                handleChartsCount={value => setChartsCount(value)}
                                                darkAccordionSummary={true}
                                                updateCharts={updateCharts}
                                                anomalousPoints={anomalousPoints}
                                                isCollapseProp={additionalParametersCollapsed}
                                                handleChartCollapse={
                                                    setAdditionalParametersCollapsed
                                                }
                                                time={time}
                                                onDropdownChange={onDropdownChange}
                                                optionsIsLoading={optionsIsLoading}
                                                dropdownLabel={dropdownLabel}
                                            />
                                        )}
                                    </div>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </DragDropContext>
            </ReportContext.Provider>
        </div>
    )
}

export default Visualization
