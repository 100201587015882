import React, { Component } from "react";
import "./Footer.css";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="footer">
        <div className="footer__content">{this.props.footerText}</div>
      </div>
    );
  }
}
