import React from "react";
import { Modal } from "react-bootstrap";
import { X as XIcon } from "react-feather";
import { Button } from "react-bootstrap";
import { ModalWrapper } from "../ModalWrapper/ModalWrapper";
import "./ErrorModal.scss";

export const ErrorModal = ({
  title,
  msg,
  icon,
  show,
  onHide,
  secondaryButton,
  secondaryButtonIcon,
  secondaryButtonClick,
  isDark
}) => {
  return (
    <ModalWrapper
      className="errorModal"
      show={show}
      onHide={onHide}
      animation={false}
      dialogClassName={`errorDialog ${isDark? "errorDialog-dark" : ""}`}>
      <Modal.Header>
        <Modal.Title className="errorModalTitleContainer">
          <div className={`errorModalTitleAndIcon ${isDark? "errorModalTitleAndIcon-dark" : ""}`}>
            {icon}
            <h1 className="errorModalTitle">{title}</h1>
          </div>
          <XIcon onClick={onHide} className={`errorModalXIcon ${isDark? "errorModalXIcon-dark" : ""}`} size="25" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="errorModalBody">
        <div>{msg}</div>
      </Modal.Body>
      <Modal.Footer>
        {secondaryButton && (
          <Button
            className="errorButton"
            onClick={() => secondaryButtonClick()}
          >
            {secondaryButton}
            {secondaryButtonIcon && (
              <React.Fragment>{secondaryButtonIcon}</React.Fragment>
            )}
          </Button>
        )}
        <Button className={`ba-button-primary errorButton ${isDark? "errorButton-dark" : ""}`} onClick={onHide}>
          {closeText}
        </Button>
      </Modal.Footer>
    </ModalWrapper>
  );
};

const closeText = "Close";

//Example usage
// <ErrorModal title={"Endpoint Down"} onHide={this.hideModal} icon={<AlertTriangle/>} msg={"The endpoint for the parts is currently down The endpoint for the parts is currently down"} show={this.state.showModal}/>
export default ErrorModal;