import _ from "lodash";
import AWS from "aws-sdk";
import moment from "moment";

import { FAULT_CODE } from "./constants";
import Months from "./resources/Months.json";
import { getParameterData, getAHMUParameterData } from "../clients/ParametersDataClient";

export const getFlightPhase = (value) => {
  if (value === "PRETAKEOFF") return "Pre Take-Off";
  if (value === "TAKEOFF") return "Take-Off";
  if (value === "CRUISE") return "Cruise";
  if (value === "LANDING") return "Landing";
  if (value === "DESCENT") return "Descent";
  if (value === "CLIMB") return "Climb"
  else return "N/A"
};

export const sortArray = (array, name, order = "newest") => {
  if (name === "anomaly_score") {
    return array.sort(
      function (objA, objB) {
        if (order === "lowest") {
          return objA[name] - objB[name];
        } else {
          return objB[name] - objA[name];
        }
      }
    );

  } else {
    return array.sort(
      function (objA, objB) {
        if (order === "newest") {
          return new Date(objB[name]) - new Date(objA[name]);
        } else {
          return new Date(objA[name]) - new Date(objB[name]);
        }
      }
    );
  }
};

export const getAircraftData = (serialNumber) => {
  const allAircrafts = JSON.parse(localStorage.getItem("allAircrafts"));
  let returnThis = {};
  if (allAircrafts) {
    allAircrafts.forEach((aircraft) => {
      // if the last 2 digits of serial number (b/c thats is whats passed from Ivado Labs) is equal to the last 2 digits of serial number of aircraft
      let aircraftSerialNumber = "";

      if (serialNumber < 10) {
        aircraftSerialNumber = `7000${serialNumber}`;
      } else if (serialNumber >= 10 && serialNumber < 100) {
        aircraftSerialNumber = `700${serialNumber}`;
      } else {
        aircraftSerialNumber = `70${serialNumber}`;
      }

      if (aircraft.serialNumber === aircraftSerialNumber) {
        returnThis = aircraft;
      }
    });
  }
  return returnThis;
};

export const getParamData = async (parameter, aircraftData, time, padding) => {
  const data = {
    tail: aircraftData.tailNumber,
    aircraftFamily: aircraftData.aircraftFamily,
    aircraftModel: aircraftData.aircraftModel,
    time: time,
    parameter: parameter
  }

  const paramData = await getParameterData(
    data.tail,
    FAULT_CODE,
    data.aircraftFamily,
    data.aircraftModel,
    data.time,
    data.parameter,
    padding,
    null,
    () => null
  );

  return paramData;
};

export const getAHMUParamData = async (parameter, aircraftData, iseReport, startTime, endTime, dataPoints) => {
  const data = {
    tail: aircraftData.tailNumber,
    aircraftFamily: aircraftData.aircraftFamily,
    aircraftModel: aircraftData.aircraftModel,
    parameter: parameter
  }

  if (typeof startTime === "undefined" && typeof endTime === "undefined") dataPoints = 300;

  if (typeof startTime === "undefined") startTime = moment.utc(iseReport.anomaly_timestamp).valueOf() - 120000;
  if (typeof endTime === "undefined") endTime = moment.utc(iseReport.anomaly_timestamp).valueOf() + 120000;

  const paramData = await getAHMUParameterData(
    data.tail,
    data.aircraftFamily,
    data.aircraftModel,
    startTime,
    endTime,
    data.parameter,
    null,
    () => null,
    null,
    typeof dataPoints !== "undefined" ? dataPoints : 400
  );

  return paramData;
};

export const convertToDMSCoords = (dd, isLng) => {
  var dir = dd < 0
    ? isLng ? 'W' : 'S'
    : isLng ? 'E' : 'N';

  var absDd = Math.abs(dd);
  var deg = absDd | 0;
  var frac = absDd - deg;
  var min = (frac * 60) | 0;
  var sec = frac * 3600 - min * 60;
  // Round it to 4 decimal points.
  sec = Math.round(sec * 10000) / 10000;
  return deg + "° " + min + "' " + sec + '" ' + dir;
};

export const selectFlightFromFlightReport = (allFlights, iseReport) => {
  const padding = 100000;
  let flightReport = null;
  let startTime = null;
  let endTime = null;

  // If only one flight in flight report, return that one
  if (allFlights.length === 1) {
    flightReport = allFlights[0]

    if (allFlights[0].departureTime && allFlights[0].arrivalTime) {
      startTime = new Date(allFlights[0].departureTime).getTime() - padding
      endTime = new Date(allFlights[0].arrivalTime).getTime() + padding
    }
  }
  else if (allFlights.length > 0) {
    let flights = [];

    // Filter all flights that contain anomaly
    flights = allFlights.filter((flight) => {
      let anomalyTimestamp = new Date(iseReport.anomaly_timestamp);

      return (
        anomalyTimestamp.getTime() >= new Date(flight.departureTime).getTime()
        && anomalyTimestamp.getTime() <= new Date(flight.arrivalTime).getTime()
      )
    });

    // If anomaly happened during the flight, choose that flight
    if (flights.length === 1) {
      flightReport = flights[0]

      if (flights[0].departureTime && flights[0].arrivalTime) {
        startTime = new Date(flights[0].departureTime).getTime() - padding
        endTime = new Date(flights[0].arrivalTime).getTime() + padding
      }
    }
    else {
      // Filter all flights that occured before anomaly
      flights = allFlights.filter((flight) => {
        return (
          new Date(
            iseReport.anomaly_timestamp).getTime() < new Date(flight.departureTime).getTime()
        )
      });

      // If anomaly happened before all flights, choose earliest flight from flight report
      if (flights.length === allFlights.length) {
        flightReport = flights[0]

        if (flights[0].arrivalTime) {
          startTime = new Date(iseReport.anomaly_timestamp).getTime() - padding
          endTime = new Date(flights[0].arrivalTime).getTime() + padding
        }
      } else {
        // Filter all flights that occured after anomaly
        flights = allFlights.filter((flight) => {
          return (
            new Date(
              iseReport.anomaly_timestamp).getTime() > new Date(flight.arrivalTime).getTime()
          )
        });

        // If anomaly happened after all flights, choose latest flight from flight report
        if (flights.length === allFlights.length) {
          flightReport = flights[flights.length - 1]

          if (flights[flights.length - 1].departureTime) {
            startTime = new Date(flights[flights.length - 1].departureTime).getTime() - padding
            endTime = new Date(iseReport.anomaly_timestamp).getTime() + padding
          }
        }
        // OR if anomaly happened between two flights, choose flight whose anomaly timestamp is the closest to it
        else if (flights.length < allFlights.length) {
          // diff between LAST flight that arrived and anomaly timestamp
          const arrivalDiff = new Date(iseReport.anomaly_timestamp).getTime() - new Date(allFlights[flights.length - 1].arrivalTime).getTime()
          // diff between NEXT flight and anomaly timestamp
          const departDiff = new Date(allFlights[flights.length].departureTime).getTime() - new Date(iseReport.anomaly_timestamp).getTime();

          // Select flight whose closest to anomaly timestamp
          flightReport = (arrivalDiff < departDiff) ? allFlights[flights.length - 1] : allFlights[flights.length]
          startTime = (arrivalDiff < departDiff)
            ? (allFlights[flights.length - 1].departureTime ? new Date(allFlights[flights.length - 1].departureTime).getTime() - padding : null)
            : (new Date(iseReport.anomaly_timestamp).getTime() - padding)
          endTime = (arrivalDiff < departDiff)
            ? (new Date(iseReport.anomaly_timestamp).getTime() + padding)
            : (allFlights[flights.length].arrivalTime ? new Date(allFlights[flights.length].arrivalTime).getTime() : null)
        }
      }
    };
  };

  return {
    flightReport: flightReport,
    startTime: startTime,
    endTime: endTime
  }
};

export const extractDataPoint = (response) => {
  let data = _.get(response, "data.data") || _.get(response, "data[0].data") || _.get(response, "data");

  if (!data || (data && data.length === 0))
    return `N/A`;

  else if (data && data.length > 0)
    return data[0][1];
};

export const cleanReportLocalStorage = () => {
  try {
    localStorage.removeItem("tmpDropdownOptions");

    return true;
  } catch (error) {
    return false;
  }
};

export const calculatingLongitude = () => {
  const result = -180 - (180 - longitude);
  return result;
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const getMonth = (number) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[number];
};

export const getMonthAbbrv = (number) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[number];
};

export const handleFiltering = (reportsList, filters, dateFilter, oldestAnomalyTs) => {
  let newReportsList = [];

  let newStart = null;
  let newEnd = null;
  let hasDateFilter = false;
  // Check if contains any filters
  let containsAFilter = false;

  // Check if contains filters
  for (const [key, array] of Object.entries(filters)) {
    if (array.length > 0)
      containsAFilter = true;
  };

  // Check if contain date filters
  if (dateFilter.startDate && dateFilter.endDate) {
    hasDateFilter = true;

    newStart = new Date(dateFilter.startDate).getTime();
    newEnd = new Date(dateFilter.endDate).getTime();
  };
  // If the user only enter the startDate without endDate, take today for endDate
  if (!hasDateFilter && dateFilter.startDate) {
    hasDateFilter = true;
    newStart = new Date(dateFilter.startDate).getTime();
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd + 'T00:00:00Z';
    newEnd = new Date(today).getTime();
    dateFilter.endDate = today;
  }
  // If the user only enter the endDate without startDate, take the oldest AnomalyTimeStamp for startDate
  if (!hasDateFilter && dateFilter.endDate) {
    hasDateFilter = true;
    newStart = new Date(oldestAnomalyTs).getTime();
    dateFilter.startDate = oldestAnomalyTs;   // Display startDate for user to see
    newEnd = new Date(dateFilter.endDate).getTime();
  }
  if (containsAFilter || hasDateFilter) {
    const isScreenedFilter = filters.type.includes("is_beta");
    for (let i = 0; i < reportsList.length; i++) {
      let containAllFilters = 0;
      let containDateFilter = false;
      const report = reportsList[i];
      if (hasDateFilter) {
        const dateWithoutTime = new Date(
          new Date(report.first_timestamp).getTime()
        ).setUTCHours(0, 0, 0, 0);

        if (
          dateWithoutTime >= newStart
          && dateWithoutTime <= newEnd
        ) {
          containDateFilter = true;
        }
      }
      if (
        (hasDateFilter && containDateFilter)
        || !hasDateFilter
      )
        for (const [key, array] of Object.entries(filters)) {
          if (array.length > 0) {
            for (let i = 0; i < array.length; i++) {
              const value = array[i];
              const isReviewed = (value === "is_reviewed");
              if (isScreenedFilter) {
                if (report[value] === true) {
                  newReportsList.push(report);
                  break;
                }
              }
              if (report[value] === isReviewed) {
                containAllFilters++;
                break;
              }
              if (String(report[key])?.toLowerCase() === String(value).toLowerCase()) {
                containAllFilters++;
                break;
              }
            }
          } else {
            if (!isScreenedFilter) {
              containAllFilters++;
            }
          }
        }
      if (containAllFilters === Object.keys(filters).length) {
        newReportsList.push(report);
      }
    }
  } else {
    newReportsList = reportsList;
  }

  return newReportsList;
};

export const handleFleetSearch = (text, list) => {
  if (text === "" || !text) return list;

  const inputs = text.toLowerCase().split(" ");

  const filteredList = _.filter(
    list,
    function (fleet) {
      const newFleet = {
        aircraftFamily: fleet.aircraftFamily,
        aircraftModel: fleet.aircraftModel,
        serialNumber: fleet.serialNumber,
        shortSerialNumber: fleet.shortSerialNumber,
        tailNumber: fleet.tailNumber
      }
      let fleetValues = Object.values(newFleet).toString().toLowerCase();

      let containsAllInputs = true;
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i] !== "") {
          if (!(fleetValues.includes(inputs[i]))) {
            containsAllInputs = false;
          }
        }
      }

      return containsAllInputs;

    }
  );

  return filteredList;
};

export const handleReportsSearch = (text, list, flightHistories) => {
  if (text === "" || !text) return list;

  const inputs = text.toLowerCase().split(" ");

  const filteredList = _.filter(
    list,
    function (item) {
      // Get date info
      let dateItem = new Date(item.first_timestamp);
      let localDate = new Date(dateItem.getTime() + 86400000);
      let flightHistory = flightHistories[item.session_key.split('_')[0]];
      let filteredHistory = [];
      let departureAirport = "";
      let arrivalAirport = "";

      if (flightHistory) {
        for (var i = 0; i < flightHistory.length; i++) {
          if (flightHistory[i].sessionNumber === item.flight_session) {
            filteredHistory.push(flightHistory[i]);
          }
        }
        const flightInfo = filteredHistory[parseInt(item.flight_leg) - 1];
        if (flightInfo) {
          departureAirport = `${flightInfo.departureAirportName} ${flightInfo.departureAirportIATA}`;
          arrivalAirport = `${flightInfo.arrivalAirportName} ${flightInfo.arrivalAirportIATA}`;
        }
      }

      // Construct new anomaly card with additional information
      const newItem = {
        ata_chapter: item.ata_chapter,
        ata_description: item.ata_description,
        first_timestamp: item.first_timestamp,
        flight_leg: item.flight_leg,
        flight_phase: item.flight_phase,
        flight_phase2: getFlightPhase(item.flight_phase),
        id: item.id,
        ise_name: item.ise_name,
        ise_num: item.ise_num,
        last_timestamp: item.last_timestamp,
        reviewed: item.reviewed,
        session_key: item.session_key,
        month: Months[localDate.getMonth()].toLowerCase(),
        year: localDate.getFullYear().toString(),
        ata: `ata${item.ata_chapter}`,
        serial_number: item.serial_number,
        status: item.status,
        departureAirport: departureAirport,
        arrivalAirport: arrivalAirport
      };

      let itemValues = Object.values(newItem).toString().toLowerCase();
      let containsAllInputs = true;

      containsAllInputs = inputs.every(input => itemValues.includes(input))

      return containsAllInputs;
    }
  );

  return filteredList;
};

export const findCollapsedParent = (node) => {
  if (!node.data.isExpanded) {
    return node;
  } else if (node.parent) {
    return findCollapsedParent(node.parent);
  } else {
    return null;
  }
}

export const getTopLeft = (node) => {
  return {
    top: node.y,
    left: node.x
  };
}

export const getAwsCredentials = () => {
  const { REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_SECRET_ACCESS_KEY } = process.env;


  // commented this out to see if the ec2 instance has access to any possible secrets and accesss id's

  AWS.config.update({
    accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
    signatureVersion: "v4",
    region: 'ca-central-1',
  });

  return AWS.config.credentials;
};

export function xmlToJson(xml) {
  if (typeof xml === 'string') {
    let parser = new DOMParser();
    xml = parser.parseFromString(xml, 'text/xml');
  }

  // Create the return object
  var obj = {};

  if (xml.nodeType == 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj['@attributes'] = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType == 3) {
    // text
    obj = xml.nodeValue;
  }

  // do children
  // If just one text node inside
  if (xml.hasChildNodes() && xml.childNodes.length === 1 && xml.childNodes[0].nodeType === 3) {
    obj = xml.childNodes[0].nodeValue;
  } else if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);
      var nodeName = item.nodeName;
      // camelCase
      nodeName = nodeName.charAt(0).toLowerCase() + nodeName.slice(1);
      if (typeof obj[nodeName] == 'undefined') {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof obj[nodeName].push == 'undefined') {
          var old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  return obj;
}

export const processFaultCounts = (faultCountsInFlights, faultCountsOutsideFlights) => {
  const processedFaultCounts = [];

  _.orderBy(faultCountsInFlights, ["departureTime"], ["asc"]).forEach(
    (flight) => {
      const departureTime = flight.departureTime;
      const arrivalTime = flight.arrivalTime;

      _.orderBy(flight.faultCounts).forEach((fCounts) => {
        if (_.find(processedFaultCounts, { faultCode: fCounts.faultCode })) {
          // Insert values to an exisitng faultcode
          _.find(processedFaultCounts, {
            faultCode: fCounts.faultCode,
          }).counts.push({
            countType: "INFLIGHT",
            countDepartureTime: departureTime,
            countArrivalTime: arrivalTime,
            countTimestamp: !arrivalTime
              ? "Current"
              : moment.utc(departureTime).format("MMM DD"),
            countTotal: fCounts.countTotal,
          });
        } else {
          // Insert a new faultcode
          if (fCounts.faultCode !== "None") {
            processedFaultCounts.push({
              faultCode: fCounts.faultCode,
              counts: [
                {
                  countType: "INFLIGHT",
                  countDepartureTime: departureTime,
                  countArrivalTime: arrivalTime,
                  countTimestamp: !arrivalTime
                    ? "Current"
                    : moment.utc(departureTime).format("MMM DD"),
                  countTotal: fCounts.countTotal,
                },
              ],
            });
          }
        }
      });
    }
  );

  _.orderBy(faultCountsOutsideFlights, ["faultCode"], ["asc"]).forEach(
    (fault) => {
      const tempProcessedCounts = _.find(processedFaultCounts, {
        faultCode: fault.faultCode,
      });

      if (tempProcessedCounts) {
        const auxMaxLevelTimestampIndex = tempProcessedCounts.counts.length;
        let auxLevelTimestampIndex = 0;
        let auxLevelTimestamp =
          tempProcessedCounts.counts[auxLevelTimestampIndex]
            .countDepartureTime;

        let processedCountObjectArray = [];
        let processedCountObject = null;
        let prevFaultTimestamp = null;

        const faultData = _.orderBy(
          fault.faultData,
          ["faultTimestamp"],
          ["asc"]
        );

        faultData.forEach((fData) => {
          const faultTimestamp = moment
            .utc(fData.faultTimestamp)
            .format("MMM DD");

          if (
            faultTimestamp !== prevFaultTimestamp ||
            fData.faultTimestamp > auxLevelTimestamp
          ) {
            if (processedCountObject) {
              processedCountObjectArray.push(processedCountObject);
            }

            if (fData.faultTimestamp > auxLevelTimestamp) {
              auxLevelTimestampIndex++;

              if (auxLevelTimestampIndex >= auxMaxLevelTimestampIndex) {
                auxLevelTimestamp =
                  faultData[faultData.length - 1].faultTimestamp;
              } else {
                auxLevelTimestamp =
                  tempProcessedCounts.counts[auxLevelTimestampIndex]
                    .countDepartureTime;
              }
            }

            processedCountObject = {
              countType: "ONGROUND",
              countDepartureTime: fData.faultTimestamp,
              countArrivalTime: fData.faultTimestamp,
              countTimestamp: faultTimestamp,
              countTotal: 1,
            };
          } else {
            processedCountObject.countTotal += 1;
          }

          prevFaultTimestamp = faultTimestamp;
        });

        // Pushing the last object created
        if (processedCountObject) {
          processedCountObjectArray.push(processedCountObject);
        }

        // Insert new counts to a fault
        _.find(processedFaultCounts, {
          faultCode: fault.faultCode,
        }).counts = _.concat(
          tempProcessedCounts.counts,
          processedCountObjectArray
        );
      } else {
        let processedCountObjectArray = [];
        let processedCountObject = null;
        let prevFaultTimestamp = null;

        _.orderBy(fault.faultData, ["faultTimestamp"], ["asc"]).forEach(
          (fData) => {
            const faultTimestamp = moment
              .utc(fData.faultTimestamp)
              .format("MMM DD");

            if (faultTimestamp !== prevFaultTimestamp) {
              if (processedCountObject) {
                processedCountObjectArray.push(processedCountObject);
              }
              processedCountObject = {
                countType: "ONGROUND",
                countDepartureTime: fData.faultTimestamp,
                countArrivalTime: fData.faultTimestamp,
                countTimestamp: faultTimestamp,

                countTotal: 1,
              };
            } else {
              processedCountObject.countTotal += 1;
            }

            prevFaultTimestamp = faultTimestamp;
          }
        );

        // Pushing the last object created
        if (processedCountObject) {
          processedCountObjectArray.push(processedCountObject);
        }

        // Insert a new faultcode
        processedFaultCounts.push({
          faultCode: fault.faultCode,
          counts: processedCountObjectArray,
        });
      }
    }
  );

  return processedFaultCounts;
}

export function convertMinutesToHoursMinutesText(timeInMinutes) {
  if (!timeInMinutes) {
    return "-";
  }
  const timeValues = convertMinutesToHoursMinutes(timeInMinutes);
  const hours = timeValues[0];
  const minutes = timeValues[1];

  return hours + " hrs. " + minutes + " min.";
}

export function convertMinutesToHoursMinutes(timeInMinutes) {
  if (!timeInMinutes) {
    return [0, 0];
  }
  const timeInMinutesRound = timeInMinutes.toFixed();
  const minutes = (timeInMinutesRound % 60).toFixed();
  const hours = ((timeInMinutesRound - minutes) / 60).toFixed();
  return [hours, minutes];
}

export function formatDepartureArrivalTime(unformattedTime) {
  return (
    moment
      .utc(unformattedTime)
      .format("MMM DD, YYYY - HH:mm:ss")
  );
}