import React from "react";
import "../styles/Global Components/Toast.css";
import NotificationIcon from "../Notification Components/NotificationIcon";
import FeatherIcon from "feather-icons-react";

/**
 * The toast reusable component.
 * @param message The message to show.
 * @param handleClick Handler for the click action.
 * @param handleClose Handler for the close action.
 * @returns {Element}
 * @constructor
 */
const Toast = ({ message = "", handleClick, handleClose }) => {
  return (
    <div className="toast">
      <div
        className="toast__redirectSection"
        onClick={handleClick || (() => { })}
      >
        <div className="toast__indicator"></div>
        <div className="toast__icon">
          <NotificationIcon size={36} color="#ffffff" type="icon" />
        </div>
        <div className="toast__message">{message}</div>
      </div>
      <div className="toast__close" onClick={handleClose}>
        <FeatherIcon
          icon="x"
          height={26}
          width={26}
          className={"toast__close__icon"}
        />
      </div>
    </div>
  );
};

export default Toast;
