import React, { useEffect, useState } from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import { Link } from "@mui/material";

import { sortArray, handleFleetSearch } from "../usefulFunctions";
import SearchBox from "../Global Components/SearchBox";
import PredictiveTooltip from "../Global Components/PredictiveTooltip";

import "./FleetList.css";

const FleetList = ({
  data,
  selectedFleet = null,
  fleetPressed,
  fleetList,
  updatePageInfo,
  currTab,
  filteredReports,
  filteredFleetList,

  reportSearch,
  fleetSearch,
  handleFleetSearchChange,
  prognosticsViewed
}) => {
  // ONLY FOR ARCHIVES
  const [currentFleets, setCurrentFleets] = useState([]);

  const fleetSelected = (fleet) => {
    fleetPressed(fleet, filteredReports);
  };

  // function for selected item to bring into view in fleet list.
  const scrollView = () => {
    const fleetContainer = document.getElementById("fleet_container");
    const selectedItem = document.getElementById("fleetListEachFleetSelected");
    if (fleetContainer && selectedItem) {
      fleetContainer.scrollTo({
        top: selectedItem.offsetTop - fleetContainer.offsetTop,
        behavior: "smooth",
      })
    }
  };

  const handleSearch = (searchText) => {
    searchText = searchText.toString();
    let filteredFleets = [];
    let newFleetList = [];

    if (searchText === "") {
      if (currTab === "Archives") {
        data.forEach((report) => {
          const serialNumber = report.serial_number;

          const fleetListIndex = _.findIndex(
            fleetList,
            function (fleet) {
              return fleet.shortSerialNumber === serialNumber
            }
          );

          const currFleetListIndex = _.findIndex(
            filteredFleets,
            function (fleet) {
              return fleet.shortSerialNumber === serialNumber
            }
          );

          if (fleetListIndex !== -1 && currFleetListIndex === -1) {
            filteredFleets.push(fleetList[fleetListIndex]);
          }

        });
      } else {
        filteredFleets = fleetList;
      }
    } else {
      if (searchText.includes(fleetSearch)) {
        newFleetList = handleFleetSearch(searchText, filteredFleetList);
      } else {
        newFleetList = handleFleetSearch(searchText, fleetList);
      }

      if (currTab === "Archives") {
        newFleetList.forEach(f => {
          for (const [month, reports] of Object.entries(filteredReports)) {
            let index = _.findIndex(
              reports,
              function (fleet) {
                return fleet.serial_number === f.shortSerialNumber
              }
            );

            if (index !== -1) {
              filteredFleets.push(f);
            }
          };
        });

      }
      else {
        newFleetList.forEach(f => {
          let index = _.findIndex(
            filteredReports,
            function (fleet) {
              return fleet.serial_number.toString() === f.shortSerialNumber.toString()
            }
          );

          if (index !== -1) {
            filteredFleets.push(f);
          }
        });
      }
    }

    updatePageInfo("filteredFleetList", [...filteredFleets], currTab);
    handleFleetSearchChange(searchText);
    fleetSelected(filteredFleets[0]);
  };

  // checks if all the components (including every fleet) have been loaded in the fleet container
  useEffect(() => {
    document.addEventListener("load", scrollView());
  });

  useEffect(() => {
    updatePageInfo("filteredFleetList", fleetList, currTab);
  }, [fleetList]);

  // ONLY FOR ARCHIVES
  useEffect(() => {
    if (currTab === "Archives") {
      let months = Object.keys(filteredReports);
      let currFleets = []
      for (const month of months) {
        filteredReports[month].forEach(item => {
          let newSN = "";
          if (item.serial_number < 10) {
            newSN = `7000${item.serial_number}`;
          } else if (item.serial_number >= 10 && item.serial_number < 100) {
            newSN = `700${item.serial_number}`;
          } else if (item.serial_number >= 100) {
            newSN = `70${item.serial_number}`;
          }

          if (!(currFleets.includes(newSN))) currFleets.push(newSN);
        });
      }
      let containsAll = true;
      for (let i = 0; i < filteredFleetList.length; i++) {
        if (!(currFleets.includes(filteredFleetList[i].serialNumber))) containsAll = false;
      }

      if (containsAll) setCurrentFleets([]);
      else setCurrentFleets([...currFleets]);
    }
  }, [filteredReports]);

  return (
    <div className="fleetList__container">
      <div className="infoBox">
        <div className="infoBox__header">
          <PredictiveTooltip
            element="Fleet"
            text={
              <React.Fragment>
                List of <b>assigned aircrafts</b>
              </React.Fragment>
            }
            fitted="220px"
            centered={true}
            lifted={true}
          />
        </div>
        <div
          style={{ margin: 10 }}>
          <SearchBox
            handleSearch={handleSearch}
          />
        </div>
        <div className="infoBox__body"
          id="fleet_container"
          style={{
            padding: "0 10px",
            overflowX: "hidden",
            height: "calc(100% - 150px)"
          }}
        >
          {/* <Dropdown 
                        customWidth={"200px"}
                        options={faultSeverityFilterOptions}
                        defaultValue={faultSeverityFilterOptions[0]}
                        onChange={handleFaultSeverityFilterChange}
      /> */}

          {(currTab === "Reports" ?
            selectedFleet : true)
            &&
            filteredFleetList?.map((item, index) => {

              return (
                <div
                  key={index}
                  id={selectedFleet.serialNumber === item.serialNumber ? "fleetListEachFleetSelected" : "fleetListEachFleet"}
                  className={
                    typeof filteredReports !== "undefined"
                      && selectedFleet === ""
                      && reportSearch !== null
                      && currentFleets.length > 0
                      ? `fleetList__eachFleet ${currentFleets.includes(item.serialNumber)
                        ? "selected" : ""}`
                      : `fleetList__eachFleet ${selectedFleet.serialNumber === item.serialNumber
                        ? "selected" : ""}`
                  }
                  style={
                    typeof filteredReports !== "undefined"
                      && selectedFleet === ""
                      && reportSearch !== null
                      && currentFleets.length > 0
                      ? currentFleets.includes(item.serialNumber) ? { opacity: 1 } : { opacity: 0.4 }
                      : selectedFleet === "" ? { opacity: 1 } : { opacity: 0.4 }
                  }
                  onClick={() => fleetSelected(item)}
                >
                  <div className="fleetItem__view">

                    {/* Only show report count when on Reports tab */}
                    <div className="fleetItem__top" >
                      {currTab === "Reports"
                        && <div className="fleetCount">{item.count ? item.count : 0}</div>
                      }
                      <div className="fleettailNumber">
                        {item.tailNumber}
                      </div>
                    </div>

                    <div className="fleetItem__middle" >
                      <div className="fleetfamilyModal">
                        {item.aircraftFamily} {item.aircraftModel}
                      </div>
                    </div>

                  </div>
                  <div className="fleetItem__footer">
                    <div className="fleetSerialNo">{item.serialNumber}</div>
                    {item.containsPrognostics &&
                      <Link href={`/predictive/prognostics/${item.shortSerialNumber}`} target="_blank" >
                        <FeatherIcon
                          width={20}
                          height={20}
                          color={_.findIndex(
                            prognosticsViewed,
                            function (o) {
                              return o.serialNumber === item.shortSerialNumber
                            }) === -1
                            ? "#FA00FF"
                            : "#FFFFFF"
                          }
                          icon={"trending-up"}
                          strokeWidth={2}
                          style={{
                            marginTop: 10
                          }}
                        />
                      </Link>
                    }
                  </div>
                </div>
              );

            })}

        </div>
      </div>
    </div>

  );
};

export default FleetList;
