import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./styles/App.css";
import Unauthorized from "./Auth Components/Unauthorized";
import GetCredentials from "./Auth Components/GetCredentials";
import FleetAlerts from "./Fleet View Components/FleetAlerts";
import Footer from "./Global Components/Footer";
import NoticeHeader from "./Global Components/NoticeHeader.jsx"
import AhmsForm from "./Charting Components/Ahms Controller Components/Ahms Tab Controller/ahmsTabController.js";
import LoginPrompt from "./Login Components/LoginPrompt";
import Aircrafts from "./Aircraft View Components/Aircrafts";
import ConfigView from "./Configuration View Components/ConfigView";
import GuidesView from "./Guides and References Components/GuidesView";
import PrivateRoute from "./Auth Components/PrivateRoute";
import SideNav from "./Global Components/SideNav.js";
import TagManager from "react-gtm-module";
import PredictiveView from "./Predictive Components/Predictive View Components/PredictiveView";
import PredictiveReports from "./Predictive Components/Predictive Reports Components/PredictiveReports";
import Prognostics from "./Predictive Components/Prognostics Components/Prognostics.jsx";
import RPDView from "./RPD View Components/RPDView";
import PrivateRouteWithMultiplePaths from "./Auth Components/PrivateRouteWithMultiplePaths";
import UploadsView from "./Uploads Components/UploadsView";
import { getUserNotificationsPaginated } from "./clients/NotificationsClient";
import { getAnnouncementList } from "./clients/AnnouncementsClient.js";
import _ from "lodash";
import NotificationsView from "./Notification Components/NotificationsView";
import { getRolesPermissions } from "./Auth Components/RbacValidation";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { sendPageViewEvent } from "./GA4/EventsCatalog.js";
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';

let user = {
  userId: null,
  orgId: null,
  userType: null,
};

if (
  localStorage.getItem("userInfo") &&
  process.env.REACT_APP_ENVIRONMENT !== "DEMO" &&
  process.env.REACT_APP_ENVIRONMENT !== "PREDICTIVE" &&
  process.env.REACT_APP_ENVIRONMENT !== "PREDICTIVE-DEV" &&
  process.env.REACT_APP_ENVIRONMENT !== "PREDICTIVE-QA"
) {
  user = JSON.parse(localStorage.getItem("userInfo"));
  let userType = "unknown";
  if (user.userId.substring(0, 1).toLowerCase() === "b") {
    userType = "internal";
  } else if (user.userId.substring(0, 1).toLowerCase() === "c") {
    userType = "customer";
  }
  user.userType = userType;
}

const queryClient = new QueryClient();

/* Google Tag Manager (GTM) initial arguments to create a new tag/events session */
const tagManagerArgs = {
  gtmId: "GTM-TGPSWCCC", // Unique GTM id created for this project
  dataLayer: {
    userId: user.userId,
    orgId: user.orgId,
    userProject: "myMaintenance", // Name of our project in GTM
    environment: process.env.REACT_APP_ENVIRONMENT,
  },
};

/* Initialize GTM with initial session/events arguments */
TagManager.initialize(tagManagerArgs);

let appDidInit = false; // top-level variable to track logic execution time (per app load rather than once per component mount).

function App() {
  const [sideNavBreadCrumbs, setSideNavBreadCrumbs] = useState(["/fleetview"]);
  const [sideNavNotificationCount, setSideNavNotificationCount] = useState(
    JSON.parse(localStorage.getItem("notificationCount"))
      ? JSON.parse(localStorage.getItem("notificationCount"))
      : 0
  );
  const [currentMode, setCurrentMode] = useState("MyMx"); // State to track which mode is active: MyMx || PMx
  const [notice, setNotice] = useState({});
  const [noticeModal, setNoticeModal] = useState(true);
  const rolesPermissions = getRolesPermissions("global");
  const uploadsRolesPermissions = getRolesPermissions("uploads");

  const handleNoticeModal = (state) => {
    setNoticeModal(state);
  }

  const handleBreadcrumb = (pathSelected) => {
    sendPageViewEvent(pathSelected);
    setSideNavBreadCrumbs(pathSelected);
  };

  const handleNotificationCount = (newCount) => {
    localStorage.setItem("notificationCount", newCount);
    setSideNavNotificationCount(newCount);
  };

  const handleNotificationActionUpdate = (notificationAmount) => {
    const newCount = sideNavNotificationCount + notificationAmount;
    handleNotificationCount(newCount);
  };

  const handleNoticeCount = (newNoticeCount) => {
    setSideNavNotificationCount(sideNavNotificationCount + newNoticeCount);
  };

  const loadNotificationDataCount = useCallback(() => {
    getUserNotificationsPaginated(1, 1, { notificationStatus: 'DISPATCHED_UNREAD' })
      .then((response) => {
        if (response.data) {
          let metadata = response.data.meta_data;
          handleNotificationCount(metadata.pagination.total_count);
        }
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }, []);

  const loadUserAnnouncements = useCallback(() => {
    // Get the system generated notices to show in the landing page.
    getAnnouncementList({ status: "PUBLISHED" })
      .then((apiResults) => {
        let notices = apiResults.data;
        if (notices?.length > 0 && noticeModal) {
          const today = new Date().toJSON();

          const currentNotices = notices.filter((notice) =>
            new Date(notice.scheduled_at).toJSON() < today &&
            new Date(notice.expires_at).toJSON() > today);

          // Sort by most recent expiration date
          const sortedNotices = currentNotices.sort(function (a, b) {
            return new Date(b.expires_at) - new Date(a.expires_at);
          });

          // Only display one announcement at a time
          setNotice(sortedNotices[0])

        } else {
          setNoticeModal(false)
        }
      })
  })

  useEffect(() => {
    // Get the system generated notices to show in the landing page.
    loadUserAnnouncements();

    // For PMx redirection after login
    if (window.location.href.includes("predictivereports")) {
      let currUrl = window.location.href;
      let urlSplit = currUrl.split("/");
      const anomalyId = urlSplit[urlSplit.length - 1];
      const anomalyStringified = JSON.stringify(anomalyId);
      localStorage.setItem("lastReportId", anomalyStringified);
    }

    if (!appDidInit) {
      appDidInit = true;
      loadNotificationDataCount();
    }
  }, []);

  //Creating Routes
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <div>
          <div id="container">
            <NoticeHeader
              showModal={noticeModal}
              handleNoticeModal={handleNoticeModal}
              notice={notice}
            />
            <div id="main">
              <Router>
                <SideNav
                  breadCrumbs={sideNavBreadCrumbs}
                  notificationCount={sideNavNotificationCount}
                  handleNotificationCount={handleNotificationCount}
                  currentMode={currentMode}
                  setCurrentMode={setCurrentMode}
                  rolesPermissions={rolesPermissions}
                  uploadsRolesPermissions={uploadsRolesPermissions}
                />
                <Switch>
                  {process.env.REACT_APP_ENVIRONMENT === "DEMO" ||
                    process.env.REACT_APP_ENVIRONMENT === "PREDICTIVE-DEV" ? (
                    <Route exact path="/">
                      <Redirect to={{ pathname: "/login" }} />
                    </Route>
                  ) : (
                    <Route exact path="/">
                      <Redirect
                        to={{
                          pathname: "/credentials/:userDetails/:originalURL",
                        }}
                      />
                    </Route>
                  )}
                  {process.env.REACT_APP_ENVIRONMENT === "DEMO" ||
                    process.env.REACT_APP_ENVIRONMENT === "PREDICTIVE-DEV" ? (
                    <Route exact path="/login">
                      <Login />
                    </Route>
                  ) : (
                    <Route path="/credentials/:userDetails/:originalURL/:parameter1?/:parameter2?/:parameter3?">
                      <GetCredentials />
                    </Route>
                  )}
                  <Route exact path="/unauthorized">
                    <Unauthorized />
                  </Route>
                  <PrivateRoute
                    path="/fleetview"
                    ChildComponent={FleetAlerts}
                    handleBreadcrumb={handleBreadcrumb}
                  />
                  <PrivateRoute
                    path="/ahmsview"
                    ChildComponent={AhmsForm}
                    handleBreadcrumb={handleBreadcrumb}
                  />
                  <PrivateRoute
                    path="/rpdview"
                    ChildComponent={RPDView}
                    handleBreadcrumb={handleBreadcrumb}
                  />

                  <PrivateRoute
                    path="/configview"
                    ChildComponent={ConfigView}
                    handleBreadcrumb={handleBreadcrumb}
                  />
                  <PrivateRoute
                    path="/userguides"
                    ChildComponent={GuidesView}
                    handleBreadcrumb={handleBreadcrumb}
                  />
                  <PrivateRoute
                    path="/notifications"
                    ChildComponent={NotificationsView}
                    handleNotificationActionUpdate={
                      handleNotificationActionUpdate
                    }
                    handleBreadcrumb={handleBreadcrumb}
                  />
                  <PrivateRoute
                    path="/predictivereports/:id"
                    ChildComponent={PredictiveReports}
                    handleBreadcrumb={handleBreadcrumb}
                    setCurrentMode={setCurrentMode}
                  />
                  <PrivateRoute
                    exact path="/predictive"
                    ChildComponent={PredictiveView}
                    handleBreadcrumb={handleBreadcrumb}
                    setCurrentMode={setCurrentMode}
                  />
                  <PrivateRoute
                    path="/predictive/prognostics/:serialNumber"
                    ChildComponent={Prognostics}
                    handleBreadcrumb={handleBreadcrumb}
                    setCurrentMode={setCurrentMode}
                  />
                  <PrivateRoute
                    path="/uploads"
                    ChildComponent={UploadsView}
                    handleBreadcrumb={handleBreadcrumb}
                  />
                  <PrivateRouteWithMultiplePaths
                    paths={[
                      "/aircraftview",
                      "/aircraftview/:serialNumber/:registrationNumber/:sessionNumber",
                    ]}
                    ChildComponent={Aircrafts}
                    handleBreadcrumb={handleBreadcrumb}
                  />
                </Switch>
              </Router>
            </div>
            <Footer footerText="Private and Confidential © Bombardier Inc or its Subsidiaries. All Rights Reserved" />
          </div>
          <Footer footerText="Private and Confidential © Bombardier Inc or its Subsidiaries. All Rights Reserved" />
        </div>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

function Login() {
  return (
    <div>
      <LoginPrompt />
    </div>
  );
}

export default App;
