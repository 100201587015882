import React, { useState } from "react";
import "./CustomInput.css";
import FeatherIcon from "feather-icons-react";

export default function CustomInput({
  id,
  defaultValue,
  handleInputOffFocus,
  placeholder,
}) {

  const handleOffFocus = (newInputValue) => {
    handleInputOffFocus(newInputValue);
  };

  return (
    <div className="container__header__title">
      <input
        style={{
          backgroundColor: "rgba(19,19,19,.5)",
          color: "#FFF",
          borderRadius: "3px"
        }}
        id={"custom-input-" + id}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type="text"
        onBlur={(event) => handleOffFocus(event.target.value)}
      />
      <FeatherIcon
        icon="edit-3"
        width={20}
        height={20}
        color={"#fff"}
      />
    </div>
  )
}

/**
 * The global input reusable component. It behaves as expected according to
 * the UX definition.
 * @param id A unique identifier.
 * @param label The label to show on top of the input.
 * @param defaultValue The default value (optional).
 * @param handleInputOffFocus Handler for when the input loses focus.
 * @param handleInputOnChange Handler for when there are changes in the input.
 * @param placeholder A placeholder.
 * @param validators A list of validators (Can be empty).
 * @param readonly A flag to show the input as read-only.
 * @param errorMessage The error message to show in case the validation is not successful.
 * @param maxLength The maximum length of the input. (Optional)
 * @return {JSX.Element}
 */
export function GlobalInput({
  id,
  label,
  defaultValue,
  handleInputOffFocus,
  handleInputOnChange,
  placeholder,
  validators = [],
  readonly,
  errorMessage,
  maxLength
}) {

  const [isInputValid, setIsInputValid] = useState(true);

  const handleOffFocus = (inputValue) => {
    validators.forEach(validator => {
      if(validator.empty === false && inputValue.length === 0) {
        setIsInputValid(false);
      }
      if(validator.regex) {
        const regex = new RegExp(validator.regex);
        if (!regex.test(inputValue)) {
          setIsInputValid(false);
        }
      }
    })
    if (handleInputOffFocus) {
      handleInputOffFocus(inputValue);
    }
  }

  const handleOnChange = (event) => {
    if(handleInputOnChange) {
      handleInputOnChange(event);
    }
  }

  return (
    <>
      <p className={"template__input__label"}>{label}</p>
      <input
        id={"template-input-" + id}
        type="text"
        placeholder={placeholder}
        className={!isInputValid ? "template__input template__input__invalid" : "template__input"}
        onBlur={(event) => handleOffFocus(event.target.value)}
        onChange={(e) => handleOnChange(e)}
        readOnly={readonly}
        defaultValue={defaultValue}
        onFocus={() => setIsInputValid(true)}
        maxLength={maxLength ? maxLength : 300}
      />
      {!isInputValid ? (
        <p className="template__input__hint">
          <FeatherIcon
            icon="alert-circle"
            width={20}
            height={20}
            color={"#E74B4B"}
            style={{
              top: "5px",
              position: "relative",
              marginRight: "10px"
            }}
          />
          {errorMessage}
        </p>
      ): (null)}

    </>
  )
}

// The validator for when the input must not be empty.
export const NotEmptyValidator = {
  empty: false,
  regex: null
}

