import React, { Component } from "react";
import "./OmsRow.css";
import "./OmsList.css";
import * as tools from "../utils/CommonTools";

export default class OmsRow extends Component {
  constructor(props) {
    super(props);

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  render() {
    if(this.props.faultSeverity==="F"||this.props.faultSeverity==="Z"){
      return (
        <div
          id={this.props.id}
          className={
            (this.props.faultSeverity==="Z"?"oms-row-black":"oms-row") +
            (this.props.rowIsSubtle ? " subtle-row" : "") +
            (!this.props.rowIsFiltered ? " invisible-row" : "")
          }
          style={{cursor: "default"}}
        >
          {this.props.aircraftUIConfig.hasMDCFaultsInTable ? (
            <div className={"oms-code-section oms-bold-link-font-white"}>
              {tools.formatCodeVisually(this.props.faultCode)}
            </div>
          ) : (
            <div style={{ width: "20px", height: "100%" }}></div>
          )}
          <div className="oms-message-section oms-font">
            {this.props.faultMessage}
          </div>
          {this.props.faultSeverity==="Z"?(<div></div>):(<div
            className={
              "oms-fde-section fde-cas-font" +
              (this.props.fdeRelatedCount > 0 ? " highlight" : "")
            }
          >
            {this.props.fdeRelatedCount > 0 ? this.props.fdeRelatedCount : "-"}
          </div>)}

          {this.props.faultSeverity==="Z"?(<div></div>):(<div className="oms-timestamp-section oms-font">
            {tools.formatDate(this.props.faultTimestamp)}
          </div>)}
        </div>
      );
    } else {
      return (
        <div
          id={this.props.id}
          className={
            ("oms-row") +
            (this.props.rowIsSubtle ? " subtle-row" : "") +
            (!this.props.rowIsFiltered ? " invisible-row" : "")
          }
          onClick={this.handleRowClick}
        >
          {this.props.aircraftUIConfig.hasMDCFaultsInTable ? (
            <div className={"oms-code-section oms-bold-link-font"}>
              {tools.formatCodeVisually(this.props.faultCode)}
            </div>
          ) : (
            <div style={{ width: "20px", height: "100%" }}></div>
          )}
          <div className="oms-message-section oms-font">
            {this.props.faultMessage}
          </div>
          <div
            className={
              "oms-fde-section fde-cas-font" +
              (this.props.fdeRelatedCount > 0 ? " highlight" : "")
            }
          >
            {this.props.fdeRelatedCount > 0 ? this.props.fdeRelatedCount : "-"}
          </div>
          <div className="oms-timestamp-section oms-font">
            {tools.formatDate(this.props.faultTimestamp)}
          </div>
        </div>
      );
    }
  }

  handleRowClick() {
    this.props.omsRowClickEvent(
      this.props.faultCode,
      this.props.faultTimestamp
    );
  }
}
