import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./FleetRow.css";

/**
 * The fleet row component for the list view.
 */
export default class FleetRow extends Component {
  constructor(props) {
    super(props);

    this.calculateFaultSeverity = this.calculateFaultSeverity.bind(this);
  }

  render() {
    return (
      <Link
        style={{ textDecoration: "none" }}
        to={{
          pathname: `/aircraftview`,
          state: {
            aircraftFamily: this.props.aircraftFamily,
            aircraftModel: this.props.aircraftModel,
            tail: this.props.tailNumber,
            serial: this.props.serialNumber,
            monitorStatus: this.props.monitorStatus,
          },
        }}
      >
        <div id={this.props.id} className={"fleetRow"}>
          <div className="fleetRow__sectionTail fleetRow__font">
            {this.props.serialNumber}
          </div>
          <div className="fleetRow__sectionRegistration fleetRow__font">
            {this.props.tailNumber}
          </div>
          <div className="fleetRow__sectionModel fleetRow__font">
            {this.props.aircraftFamily + " " + this.props.aircraftModel}
          </div>
          <div className="fleetRow__sectionStatus fleetRow__font">
            {this.props.status}
          </div>
          <div className="fleetRow__sectionWarning fleetRow__font warning">
            {this.props.faultsWarning}
          </div>
          <div className="fleetRow__sectionCaution fleetRow__font caution">
            {this.props.faultsCaution}
          </div>
          <div className="fleetRow__sectionAdvisory fleetRow__font advisory">
            {this.props.faultsAdvisory}
          </div>
          {/*<div className="fleetRow__sectionOMS fleetRow__font">
            {this.props.faultsOMS}
          </div>
          <div className="fleetRow__sectionCMS fleetRow__font">
            {this.props.faultsCMS}
        </div>*/}
        </div>
      </Link>
    );
  }

  calculateFaultSeverity(severity) {
    let color;
    if (severity === "Advisory") {
      color = "advisory";
    } else if (severity === "Caution") {
      color = "caution";
    } else if (severity === "Warning") {
      color = "warning";
    } else if (severity === "Status") {
      color = "status";
    } else {
      color = "info";
    }
    return color;
  }
}
