/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';

const Checkbox = ({ id, disabled, name, checked, onChange, label, children }) => (
  <div className="bba-checkbox">
    <input
      id={id}
      disabled={disabled}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={onChange && onChange}
    />
    <label htmlFor={id} className="bba-check-label">
      <span>
        {label && label}
        {children && <>{children}</>}
      </span>
    </label>
  </div>
);

export default Checkbox;

Checkbox.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
};
