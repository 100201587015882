import React, { useState, useEffect } from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import HistoricDataRow from "./HistoricDataRow";
import "./HistoricDataCollapseRow.css";
import CustomTooltip from "../Global Components/CustomTooltip";

function HistoricDataCollapseRow({
  sessionNumber,
  sessionStartTime,
  sessionEndTime,
  ifrSessionOrder,
  startTime,
  endTime,
  faultsInfoTotal,
  faultsAdvisoryTotal,
  faultsCautionTotal,
  faultsWarningTotal,
  flights,
  missingData,
  handleHistoricSessionSelection,
  handleRecentSessionSelection,
  selectedSessionStartTime,
  selectedSessionEndTime,
  selectedSessionNumber,
  externalExpand,
}) {
  const [collapsedRow, setCollapsedRow] = useState(false);

  const handleExternalExpand = () => {
    setCollapsedRow(!externalExpand);
  };

  const hilightRow = (
    tmpSelectedSessionStartTime,
    tmpSelectedSessionEndTime,
    tmpSelectedSessionNumber
  ) => {
    if (
      tmpSelectedSessionStartTime === sessionStartTime &&
      tmpSelectedSessionEndTime === sessionEndTime &&
      tmpSelectedSessionNumber === sessionNumber
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    handleExternalExpand();
  }, [externalExpand]);

  const renderHistoricDataCollapseRow = () => {
    const historicDataCollapseRowContent = (
      <div className="historicDataCollapseRow">
        <div
          className={
            "historicDataCollapseRow__mainRow" +
            " historicDataCollapseRow__mainRow__isDisabled-" +
            (missingData ? "true" : "false") +
            (selectedSessionStartTime === sessionStartTime &&
            selectedSessionEndTime === sessionEndTime &&
            selectedSessionNumber === sessionNumber
              ? " historicDataCollapseRow__mainRow__isSelected"
              : "")
          }
          onClick={() => handleRowClick()}
        >
          <div className="historicDataCollapseRow__column__sessionNumber">
            <span className="historicDataCollapseRow__collapseText">
              {sessionNumber}
            </span>
            <div className="historicDataCollapseRow__collapseIconContainer">
              <FeatherIcon
                icon={collapsedRow ? "chevron-down" : "chevron-up"}
                className={"historicDataCollapseRow__collapseIcon"}
              />
            </div>
          </div>
          <div className={"separator"}></div>
          <div className="historicDataCollapseRow__column__startDate">
            {startTime === "-" ? "(Data Incomplete)" : startTime}
          </div>
          <div className={"separator"}></div>
          <div className="historicDataCollapseRow__column__endDate">
            {endTime === "-" ? "(Data Incomplete)" : endTime}
          </div>
          <div className={"separator"}></div>
          <div className="historicDataCollapseRow__column__airports"></div>
          <div className={"separator"}></div>
          <CustomTooltip content={"Total FDE/CAS events in session."}>
            <div
              className={
                faultsWarningTotal === 0
                  ? hilightRow(
                      selectedSessionStartTime,
                      selectedSessionEndTime,
                      selectedSessionNumber
                    )
                    ? "value_zero highlight"
                    : "value_zero"
                  : "historicDataCollapseRow__column__faultWarning"
              }
            >
              {faultsWarningTotal.toString()}
            </div>
          </CustomTooltip>
          <CustomTooltip content={"Total FDE/CAS events in session."}>
            <div
              className={
                faultsCautionTotal === 0
                  ? hilightRow(
                      selectedSessionStartTime,
                      selectedSessionEndTime,
                      selectedSessionNumber
                    )
                    ? "value_zero highlight"
                    : "value_zero"
                  : "historicDataCollapseRow__column__faultCaution"
              }
            >
              {faultsCautionTotal.toString()}
            </div>
          </CustomTooltip>
          <CustomTooltip content={"Total FDE/CAS events in session."}>
            <div
              className={
                faultsAdvisoryTotal === 0
                  ? hilightRow(
                      selectedSessionStartTime,
                      selectedSessionEndTime,
                      selectedSessionNumber
                    )
                    ? "value_zero highlight"
                    : "value_zero"
                  : "historicDataCollapseRow__column__faultAdvisory"
              }
            >
              {faultsAdvisoryTotal.toString()}
            </div>
          </CustomTooltip>
          <CustomTooltip content={"Total FDE/CAS events in session."}>
            <div
              className={
                faultsInfoTotal === 0
                  ? hilightRow(
                      selectedSessionStartTime,
                      selectedSessionEndTime,
                      selectedSessionNumber
                    )
                    ? "value_zero highlight"
                    : "value_zero"
                  : "historicDataCollapseRow__column__faultInfo"
              }
            >
              {faultsInfoTotal.toString()}
            </div>
          </CustomTooltip>
        </div>
        {renderHistoricDataFlightRows()}
      </div>
    );

    return historicDataCollapseRowContent;
  };

  const handleRowClick = () => {
    setCollapsedRow(!collapsedRow);
  };

  const renderHistoricDataFlightRows = () => {
    let flightCount = flights.length + 1;
    return (
      <div
        className={
          "historicDataCollapseRow__flightRows__collapsed-" +
          (collapsedRow ? "true" : "false")
        }
      >
        {_.orderBy(
          flights,
          ["orderStartTime", "orderEndTime"],
          ["desc", "desc"]
        ).map((flight) => {
          flightCount--;
          return (
            <React.Fragment
              key={
                flight.rowType +
                "-" +
                flight.session +
                "-" +
                Math.random() * 90000
              }
            >
              <HistoricDataRow
                sessionNumber={flight.session}
                sessionStartTime={flight.sessionStartTime}
                sessionEndTime={flight.sessionEndTime}
                ifrSessionOrder={flight.ifrSessionOrder}
                departureTime={flight.departureTime}
                arrivalTime={flight.arrivalTime}
                sessionType={flight.sessionType}
                startTime={flight.startTime}
                endTime={flight.endTime}
                airports={flight.airports}
                faultsInfoTotal={flight.faultsInfoTotal}
                faultsAdvisoryTotal={flight.faultsAdvisoryTotal}
                faultsCautionTotal={flight.faultsCautionTotal}
                faultsWarningTotal={flight.faultsWarningTotal}
                flightCount={flightCount}
                missingData={flight.missingData}
                rowType={flight.rowType}
                handleHistoricSessionSelection={handleHistoricSessionSelection}
                handleRecentSessionSelection={handleRecentSessionSelection}
                selectedSessionStartTime={selectedSessionStartTime}
                selectedSessionEndTime={selectedSessionEndTime}
                selectedSessionNumber={selectedSessionNumber}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return renderHistoricDataCollapseRow();
}

export default HistoricDataCollapseRow;
