import React from "react";
import Badge from "@mui/material/Badge";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

import "./NotificationIcon.css";
import {styled} from "@mui/material/styles";

export default function NotificationIcon({
  MaterialIcon,
  size,
  color,
  badgeColor,
  notificationCount,
  //handleIconClick,
  type,
}) {
  /*const MaterialIconStyle = styled(MaterialIcon)(({ theme }) => ({
    color: color ? color : "#ffffff",
    fontSize: size ? size.toString() + "px" : "40px",
  }));*/

  const NotificationsOutlinedIconStyle = styled(NotificationsOutlinedIcon)(
    ({ theme }) => ({
      color: color ? color : "#ffffff",
      fontSize: size ? size.toString() + "px" : "40px",
    })
  );

  /* const handleClick = () => {
    if (handleIconClick) {
      handleIconClick();
    }
  };*/

  return (
    <div className="notificationIcon">
      {type === "iconButton" ? (
        <div className="notificationIcon__iconButton" id="notificationIcon">
          <Badge
            badgeContent={notificationCount}
            /*onClick={() => {
              handleClick();
            }}*/
            variant={"standard"}
            classes={{
              root: "notificationIcon__MuiBadge-root",
              badge: "notificationIcon__MuiBadge-badge",
              dot: "notificationIcon__MuiBadge-dot",
            }}
          >
            {MaterialIcon ? (
              <NotificationsOutlinedIconStyle />
            ) : (
              <NotificationsOutlinedIconStyle />
            )}
          </Badge>
        </div>
      ) : (
        <div className="notificationIcon__iconOnly">
          <Badge
            badgeContent={0}
            variant={"standard"}
            classes={{
              root: "notificationIcon__MuiBadge-root",
              badge: "notificationIcon__MuiBadge-badge-hide",
            }}
          >
            {MaterialIcon ? (
              <NotificationsOutlinedIconStyle />
            ) : (
              <NotificationsOutlinedIconStyle />
            )}
          </Badge>
        </div>
      )}
    </div>
  );
}
