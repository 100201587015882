import * as Api from "../Constants/ApiCatalog";
import {
  // getSecrets,
  // decodeSecret,
  createDevelopmentJWT,
} from "../utils/DevAccessTools";
import axios from "axios";

/*
  Clients for:
  - Demo instance generic login (temporary)
  - predictive maintenance MVP generic login (temporary)
  - Credentials API backend handshake
*/

const LOGIN_API = Api.LOGIN_API;
const CREDENTIALS_API = Api.CREDENTIALS_API;
const LOGIN_PREDICTIVE_API = Api.LOGIN_PREDICTIVE_API;

// Demo and prdictive instance generic login (temporary)
async function authenticate(user, password) {
  // NOTE This code should change once the API has been corrected
  let options;
  let data;
  let axiosInstance;
  let apiResult;

  // If instance is for the predictive MVP then use a specific API, if not use generic demo instance login
  if (process.env.REACT_APP_ENVIRONMENT === "PREDICTIVE-DEV") {
    options = {
      headers: {
        Accept: "application/json",
        demo: false,
        "-uid": user,
        password: password,
      },
    };

    data = {};

    axiosInstance = axios.create(options);
    apiResult = await axiosInstance.get(LOGIN_PREDICTIVE_API.url, data);
  } else {
    options = {
      headers: {
        Accept: "application/json",
        demo: false,
      },
    };

    data = {
      userId: user,
      password: password,
    };

    axiosInstance = axios.create(options);
    apiResult = await axiosInstance.post(LOGIN_API.url, data);
  }

  return apiResult;
}

// Oficial credentials API authentication handshake
async function getCredentials(userDetailsToken) {
  // TODO: This can be done in a better fashion
  localStorage.removeItem("credentialsError");
  if (
    process.env.REACT_APP_ENVIRONMENT !== "PROD" &&
    process.env.REACT_APP_ENVIRONMENT !== "PRE-PROD" &&
    process.env.REACT_APP_ENVIRONMENT !== "DEMO" &&
    userDetailsToken === "developmentAccessToken"
  ) {
    // let secrets;
    // await getSecrets()
    //   .then((res) => {
    //     secrets = decodeSecret(res);
    //   })
    //   .catch((error) => {
    //     console.error("Development Access Error - 2", error);
    //   });

    userDetailsToken = createDevelopmentJWT(
      //"b8000520", //PMx DEV User
      //"C5008581", //PMx DEV User
      // "C5058938", // PMx PROD user
      "b8000535", //myMX DEV User
      // "C5055044", //myMX PROD User
      // JSON.parse(secrets).JWT_SECRET
      'MyBAMarketplaceApplication'
    );
  }

  const options = {
    headers: {
      Accept: "application/json",
      "-uid": userDetailsToken,
    },
  };
  const axiosInstance = axios.create(options);
  const data = {};

  const apiResult = await axiosInstance.get(CREDENTIALS_API.url, data);

  return apiResult;
}

export { authenticate, getCredentials };
