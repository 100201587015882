import React, { Component } from "react";
import moment from "moment";
import * as tools from "../utils/CommonTools";
import "./ConfigCyclesHoursLog.css";

export default class ConfigCyclesHoursLog extends Component {
  render() {
    return (
      <div className="configCyclesHoursLog">
        <div className="configCyclesHoursLog__rows__title">
          <div className="configCyclesHoursLog__username">{"Name"}</div>
          <div className="configCyclesHoursLog__time">{"Time"}</div>
          <div className="configCyclesHoursLog__description">{"Change"}</div>
        </div>
        <div className="configCyclesHoursLog__list">
          {this.props.flightCycleLogs.map((log) => (
            <React.Fragment key={log.updateType + Math.random() * 90000}>
              {this.addLogRow(
                log.username,
                log.timestamp,
                log.updateValue.toString(),
                log.updateType,
                log.engineSerial
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }

  addLogRow(username, timestamp, value, updateType, engineSerial) {
    return (
      <div className="configCyclesHoursLog__rows">
        <div className="configCyclesHoursLog__username">{username}</div>
        <div className="configCyclesHoursLog__time">
          {moment.utc(timestamp).format("MMM DD YYYY, HH:mm:ss")}
        </div>
        <div className="configCyclesHoursLog__description">
          {(value.substring(0, 1) === "-" ? " subtracted " : " added ") +
            this.formatUpdateValue(updateType, value) +
            " to " +
            this.formatUpdateType(updateType, engineSerial)}
        </div>
      </div>
    );
  }

  formatUpdateValue(updateType, value) {
    switch (updateType) {
      case "AIRFRAME_CYCLES":
        return tools.formatCycles(parseInt(value));
      case "AIRFRAME_TIME":
        return tools.convertMinutesToHoursMinutesText(parseInt(value));
      case "ENGINE_CYCLES":
        return tools.formatCycles(parseInt(value));
      case "ENGINE_TIME":
        return tools.convertMinutesToHoursMinutesText(parseInt(value));
      default:
        return 0;
    }
  }

  formatUpdateType(updateType, engineSerial) {
    engineSerial = engineSerial ? engineSerial : "";

    switch (updateType) {
      case "AIRFRAME_CYCLES":
        return "Airframe Cycles";
      case "AIRFRAME_TIME":
        return "Airframe Time";
      case "ENGINE_CYCLES":
        return "Engine (" + engineSerial + ") Cycles";
      case "ENGINE_TIME":
        return "Engine (" + engineSerial + ") Time";
      default:
        return "*";
    }
  }
}
