import { Zoom } from "@visx/zoom";
import { localPoint } from "@visx/event";
import { RectClipPath } from "@visx/clip-path";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";

import Tree from "./Tree";
import ImageModal from "./ImageModal";
import PredictiveTooltip from "../../Global Components/PredictiveTooltip";

const ZoomContainer = (props) => {
  const {
    position,
    width,
    height,
    data,
    currentLRU,
    updateCurrentLRU,
    ata,
    system_name,
    lruImage,
    minTreeWidth = 1350
  } = props;

  const [showImageModal, setShowImageModal] = useState(false);
  const [zoomObj, setZoom] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [transformMatrix, setTransformMatrix] = useState({
    scaleX: 1,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0
  });

  const isImage = typeof lruImage === "undefined" ? false : true;
  const bg = isImage ? "#202020" : "#202020";
  const topLrusWidth = 1350;

  const handleShowImageModal = () => {
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  const handleZoomReset = (zoom) => {
    zoom.reset();
  };

  const setInitialZoomState = (zoom) => {
    if (firstLoad && isImage) {
      setFirstLoad(false);
      setZoom(zoom);
    }
  };

  useEffect(() => {
    let scale = 1;

    if (!isImage && data) {
      transformMatrix.translateX = width / 14;
      transformMatrix.translateY = height * 0.15;

      transformMatrix.scaleX = 0.85;
      transformMatrix.scaleY = 0.85;
    }
    else if (lruImage) {
      const lruImgWidth = lruImage.width;
      const lruImgHeight = lruImage.height;


      scale = (width / lruImgWidth) - 0.3;

      transformMatrix.scaleX = scale;
      transformMatrix.scaleY = scale;

      transformMatrix.translateX = - 15;
    }

    setTransformMatrix({ ...transformMatrix });
  }, []);

  useEffect(() => {
    // Reset zoom whenever the LRU node changes
    if (zoomObj) handleZoomReset(zoomObj);
  }, [currentLRU]);

  return (
    <>
      {transformMatrix.scaleX && transformMatrix.scaleY &&
        <Zoom
          width={width}
          height={height}
          scaleXMin={!isImage ? 0.5 : 0.05}
          scaleXMax={4}
          scaleYMin={!isImage ? 0.5 : 0.05}
          scaleYMax={4}
          initialTransformMatrix={transformMatrix}
        >
          {(zoom) => {
            setTimeout(() => {
              setInitialZoomState(zoom);
            }, 1000);

            return (
              <div>
                <svg
                  width={width}
                  height={height}
                  style={{
                    cursor: zoom.isDragging ? "grabbing" : "grab",
                  }}
                >
                  <RectClipPath
                    id="zoom-clip"
                    width={width}
                    height={height}
                  />
                  <rect
                    width={width}
                    height={height}
                    fill={bg}
                  />

                  {!isImage && (
                    <rect
                      width={width}
                      height={height}
                      fill="transparent"
                      // onWheel={zoom.handleWheel}
                      onMouseDown={zoom.dragStart}
                      onMouseMove={zoom.dragMove}
                      onMouseUp={zoom.dragEnd}
                      onMouseLeave={() => {
                        if (!zoom.isDragging) return;
                        zoom.dragEnd();
                      }}
                      onDoubleClick={(event) => {
                        const point = localPoint(event);
                        zoom.scale({
                          scaleX: 1.2,
                          scaleY: 1.2,
                          point,
                        });
                      }}
                    />
                  )}
                  <g transform={zoom.toString()}>
                    {isImage ? (
                      lruImage &&
                      <image
                        width={width}
                        height={height}
                        href={lruImage.url}
                      />
                    ) : (
                      typeof data !== 'undefined' && <Tree
                        data={data}
                        ata={ata}
                        system_name={system_name}
                        width={width > minTreeWidth ? width : minTreeWidth}
                        height={height - 15}
                        currentLRU={currentLRU}
                        updateCurrentLRU={updateCurrentLRU}
                        treePosition={position}
                      />
                    )}
                  </g>
                  {isImage && (
                    <rect
                      width={width}
                      height={height}
                      fill="transparent"
                      onWheel={zoom.handleWheel}
                      onMouseDown={zoom.dragStart}
                      onMouseMove={zoom.dragMove}
                      onMouseUp={zoom.dragEnd}
                      onMouseLeave={() => {
                        if (!zoom.isDragging) return;
                        zoom.dragEnd();
                      }}
                      onDoubleClick={(event) => {
                        const point = localPoint(event);
                        zoom.scale({
                          scaleX: 1.2,
                          scaleY: 1.2,
                          point,
                        });
                      }}
                    />
                  )}
                </svg>
                <div className={"top-zoom-container" + (isImage ? "-image" : "-tree")}>
                  {!isImage &&
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: 140,
                        fontWeight: 700,
                        fontSize: 16
                      }}>
                      ATA {ata} - {system_name}
                    </div>
                  }
                  <div className="top-controls-container">
                    <button
                      className="btn"
                      onClick={() =>
                        zoom.scale({ scaleX: 1.25, scaleY: 1.25 })
                      }
                    >
                      <PredictiveTooltip
                        element={<FeatherIcon
                          width={30}
                          height={30}
                          icon={"zoom-in"}
                          color={"#808080"}
                        />}
                        text="Zoom In"
                        fitted="100px"
                        centered={true}
                      />
                    </button>
                    <button
                      className="btn"
                      onClick={() => zoom.scale({ scaleX: 0.85, scaleY: 0.85 })}
                    >
                      <PredictiveTooltip
                        element={<FeatherIcon
                          width={30}
                          height={30}
                          icon={"zoom-out"}
                          color={"#808080"}
                        />}
                        text="Zoom Out"
                        fitted="100px"
                        centered={true}
                      />
                    </button>
                    <button className="btn" onClick={zoom.reset}>
                      <PredictiveTooltip
                        element={<FeatherIcon
                          width={30}
                          height={30}
                          icon={"move"}
                          color={"#808080"}
                        />}
                        text="Pan"
                        fitted="60px"
                        centered={true}
                      />
                    </button>
                    {isImage && (
                      <button className="btn" onClick={() => handleShowImageModal()}>
                        <PredictiveTooltip
                          element={<FeatherIcon
                            width={30}
                            height={30}
                            icon={"maximize"}
                            color={"#808080"}
                          />}
                          text="Enlarge Image"
                          fitted="100px"
                          centered={true}
                        />
                      </button>
                    )}
                  </div>
                </div>
                {/* Buttons for carousel */}
                {isImage && (
                  <div className="bottom-controls-container">
                    <div className="bottom-controls">
                      {/* PREVIOUS BUTTON */}
                      {/* Disable button if no previous images */}
                      <button
                        className={"btn-disabled"}
                      // onClick={() => {
                      //   if (imageIndex !== 0) {
                      //     handleImageCarousel("PREVIOUS");
                      //     zoom.reset();
                      //   }
                      // }}
                      >
                        <PredictiveTooltip
                          element={<FeatherIcon
                            width={30}
                            height={30}
                            icon={"chevron-left"}
                            color={"#909090"}
                          />}
                          text="Previous Image"
                          fitted="140px"
                          centered={true}
                        />
                      </button>
                      {/* <div style={{ fontWeight: 600, paddingBottom: 5 }}>
                        {imageIndex + 1} of {lruImage.length}
                      </div> */}
                      <div className="dots__container">
                        <span
                          className="dot"
                          style={{ backgroundColor: "#D9D9D9" }}
                        ></span>
                      </div>
                      {/* NEXT BUTTON */}
                      {/* Disable button if no next images */}
                      <button
                        className={"btn-disabled"}
                      // onClick={() => {
                      //   if (imageIndex + 1 < lruImage.length) {
                      //     handleImageCarousel("NEXT");
                      //     zoom.reset();
                      //   }
                      // }}
                      >
                        <PredictiveTooltip
                          element={<FeatherIcon
                            width={30}
                            height={30}
                            icon={"chevron-right"}
                            color={"#909090"}
                          />}
                          text="Next Image"
                          fitted="120px"
                          centered={true}
                        />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Zoom>
      }
      {isImage &&
        <ImageModal
          show={showImageModal}
          image={lruImage}
          currentLRU={currentLRU}
          handleCloseImageModal={handleCloseImageModal}
          ata={ata}
        />
      }
    </>
  )
};

export default ZoomContainer;
