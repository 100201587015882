import React from "react";
import TitleCard from "../TitleCard.jsx";
import Card from "../Card.jsx";
import * as C from "../Colors";
import {
  MessageCircle,
  Grid,
  Bell,
  Settings,
  MessageSquare,
} from "react-feather";

// Special title card
const Body = () => {
  return (
    <div className="onboardingTitleCard">
      <div className="icon">
        <MessageCircle size="44" strokeWidth="1" />
      </div>
      <div className="title">
        <h2>Welcome to</h2>
        <div style={{ fontWeight: "600", fontSize: "23px" }}>myMaintenance</div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="onboardingFooter">
      <p style={{ margin: "auto", width: "60%", textAlign: "center" }}>
        This brief overview will walk you through this visualization
        application.
      </p>
      <br />
      <p>
        Estimated time: <b>4 minutes</b>{" "}
      </p>
    </div>
  );
};

const titleContent = {
  body: <Body />,
  footer: <Footer />,
};

const overview = (
  <div>
    The <b>FLEET VIEW</b> page shows an overview of your registered aircraft.
    This is your home screen.
  </div>
);

const filters1 = (
  <div>
    Use the <b>FILTERS</b> on the left to sort by flight or FDE status.
  </div>
);

const filters2 = (
  <div>
    Use the <b>FILTERS</b> above to sort aircraft by registration number or
    model.
  </div>
);

// const sideBarDescr = (
//   <div>
//     Use the sidebar navigation to Return home <Grid /> &#40; i.e., back to Fleet
//     page&#41; OR go to other general pages like the Notifications page <Bell />,
//     Settings page <Settings /> or Feedback page <MessageSquare />.
//   </div>
// );

const stepContent = [
  {
    steps: [overview],
    placement: "center",
  },
  {
    steps: [filters1],
    target: "#fleetView_filter_whole",
    placement: "right-start",
    floaterProps: {
      placement: "right-start",
    },
  },
  {
    steps: [filters2],
    target: "#fleetView_search_dropdown_container",
    placement: "bottom",
    floaterProps: {
      placement: "left-start",
    },
    offset: "0",
  },
  // {
  //   steps: [
  //     'Use these two icons to toggle between the Grid/Card view AND the Table/List view.'
  //   ],
  //   target: "#fleetView_toggle_container",
  //   placement: "top-end",
  //   floaterProps: {
  //     placement: "left-start",
  //   },
  // },
  // {
  //   steps: [
  //     'Place mouse over (hover) colored regions to see count of latest FDE/CAS messages.'
  //   ],
  //   target: ".puck__body__flags",
  //   placement: "left-start",
  //   floaterProps: {
  //     placement: "left",
  //   },
  // },
  // {
  //   steps: [
  //     sideBarDescr
  //   ],
  //   target: "#sideNav__topSection",
  //   placement: "right-start",
  //   floaterProps: {
  //     placement: "right-start",
  //   },
  // }
];

export default function FleetViewOnboarding() {
  const defaultStyles = {
    options: C.styleReset,
    buttonNext: {
      ...C.buttonStyles,
      color: C.blue,
    },
    buttonBack: {
      ...C.buttonStyles,
      background: "transparent",
      border: "1px solid " + C.white,
      color: C.white,
    },
  };

  let trainings = [];

  trainings.push({
    target: "body",
    disableBeacon: true,
    disableOverlayClose: true,
    content: <TitleCard content={titleContent} />,
    placement: "center",
    showSkipButton: true,
    locale: {
      skip: "Cancel",
      next: "Ok, Proceed",
    },
    showProgress: false,
    styles: {
      options: {
        primaryColor: C.blue,
        textColor: C.white,
      },
      tooltip: {
        borderRadius: 12.5,
      },
      buttonSkip: {
        ...C.buttonStyles,
        background: "transparent",
        border: "1px solid " + C.white,
        color: C.white,
        marginLeft: "125px",
        marginBottom: "25px",
        width: "140px",
        fontWeight: "400",
      },
      buttonNext: {
        ...C.buttonStyles,
        color: C.white,
        marginRight: "125px",
        marginBottom: "25px",
        padding: "10px",
        width: "140px",
        fontWeight: "400",
      },
    },
  });

  // push remaining steps
  stepContent.map((x, index) => {
    trainings.push({
      target: x.target || "body",
      disableBeacon: x.disableBeacon || true,
      content: (
        <Card
          paragraphs={x.steps}
          index={index}
          stepCount={stepContent.length - 1}
          pageName={"Fleet"}
        />
      ),
      placement: x.placement || "center",
      styles: x.styles || defaultStyles,
      floaterProps: x.floaterProps || {},
      spotlightClicks: x.spotlightClicks || false,
    });
  });

  return trainings;
}
