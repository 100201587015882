import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for the aircraft session and flight history.
  - The Flight History API returns the history of sessions from any type of aircraft with its flight data.
  - The Recent History API returns the active SITA Inflight Report Sessions for the Global 7500.
*/

const ALL_FLIGHTS_HISTORY = Api.ALL_FLIGHTS_HISTORY;
const RECENT_SESSION_LIST = Api.RECENT_SESSION_LIST;

// Returns the history of sessions from any type of aircraft with its flight data.
async function getAicraftRecentSessionList(
  tailNumber,
  hmuLatestTransfer,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tailNumber,
      hmuLatestTransfer: hmuLatestTransfer,
    },
  };

  try {
    result = await AxiosService(
      RECENT_SESSION_LIST.url,
      RECENT_SESSION_LIST.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Returns the active SITA Inflight Report Sessions for the Global 7500.
async function getFlightListHistory(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  hmuLatestTransfer,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tailNumber,
      hmuLatestTransfer: hmuLatestTransfer,
    },
  };

  try {
    result = await AxiosService(
      ALL_FLIGHTS_HISTORY.url,
      ALL_FLIGHTS_HISTORY.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error("API ERROR: ", error);
  }

  return result;
}

export { getAicraftRecentSessionList, getFlightListHistory };
