import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import CustomButton from "../../Global Components/CustomButton";

import "./NotificationPopup.css";

export default function NotificationPopup(props) {
  let [currentNotif, setCurrentNotif] = useState({
    closeModal: "",
    userName: "",
    hideClose: "",
    description: "",
    descriptionSituation: "",
    color: "",
    title: ""
  });

  const handleWindowScrollStatus = (isModalOpen) => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  if (props.open) {
    handleWindowScrollStatus(true);
    if (props.closeModal) {
      currentNotif = {
        closeModal: props.closeModal,
        userName: props.userName,
        reloadPage: props.reloadPage !== null ? props.reloadPage : true,
        alternativeExistLink: props.alternativeExistLink
          ? props.alternativeExistLink
          : null,
        description: props.description ? props.description : null,
        descriptionSituation: props.descriptionSituation
          ? props.descriptionSituation
          : null,
        color: props.color ? props.color : "#ff5050",
        technical_detail: props.technical_detail
          ? props.technical_detail
          : null,
        title: props.title ? props.title : null,
        isError: props.isError !== null ? props.isError : true,
        containsClose: props.containsClose !== null ? props.containsClose : false
      };
    }
  }

  const closeNotificationModal = () => {
    handleWindowScrollStatus(false);
    currentNotif.closeModal();
  };

  const handleReloadPage = () => {
    handleWindowScrollStatus(false);
    window.location.reload();
  };

  const getTitle = () => {
    return (
      <div data-testid="some-id-for-title-element">
        {currentNotif.title
          ? currentNotif.title
          : "We’re sorry" +
          (currentNotif.userName ? ", " + currentNotif.userName : "")}
      </div>
    );
  };

  const getDescription = () => {
    return (
      <div className="descriptionSituation" id="situationDescription">
        {currentNotif.description}
      </div>
    );
  };

  const getButtonCombinations = () => {
    return (
      <div
        className="notif-popup-buttonSection"
        style={{
          justifyContent: currentNotif.reloadPage ? "space-between" : "center",
          padding: currentNotif.isError ? "0 0 0 65px" : "0 15px"
        }}
      >
        {currentNotif.alternativeExistLink && (
          <span>
            <Link to={currentNotif.alternativeExistLink}>
              <CustomButton
                id={"closeButtonErrorModal"}
                text={currentNotif.alternativeExistLink === "/fleetview"
                  ? "Back to myMaintenance"
                  : "BACK"
                }
                colorStyle={"custom"}
                customButtonColor={"white"}
                onClickAction={() => {
                  // For side navigation
                  if (currentNotif.alternativeExistLink === "/fleetview") {
                    props.setCurrentMode("MyMx");
                  }
                  closeNotificationModal();
                }}
              />
            </Link>
          </span>
        )}
        {currentNotif.reloadPage && (
          <span>
            <CustomButton
              id={"closeButtonErrorModal"}
              text={"RELOAD"}
              icon={<FeatherIcon icon="refresh-ccw" height={20} width={20} />}
              iconPosition={"end"}
              onClickAction={handleReloadPage}
            />
          </span>
        )}
        {currentNotif.containsClose && (<span>
          <CustomButton
            id={"closeButtonErrorModal"}
            text={"CONTINUE"}
            colorStyle={"custom"}
            customButtonColor={currentNotif.color}
            customButtonTextColor={"white"}
            onClickAction={closeNotificationModal}
          />
        </span>
        )}
      </div>
    );
  };

  useEffect(() => {
    setCurrentNotif({
      closeModal: props.closeModal || "",
      userName: props.userName || "",
      hideClose: props.hideClose || "",
      description: props.description || "",
      descriptionSituation: props.descriptionSituation || "",
      color: props.color || "",
      title: props.title || "",
      reloadPage: props.reloadPage || false
    });
  }, [
    props.closeModal,
    props.userName,
    props.hideClose,
    props.description,
    props.descriptionSituation,
    props.color,
    props.title,
    props.reloadPage
  ]);

  return (
    props.open && (
        <div
            data-testid="notif-modal-popup"
            className={
                "notif-modal-popup-open-" + (props.open === true ? "true" : "false")
            }
        >
        <div className="notif-modal-popup-screen">
          <div className={"notif-modal-popup"}>
            <div
              className="notif-popup-flag"
              style={{
                backgroundColor: currentNotif.color,
              }}
            ></div>
            <div className="notif-popup-body">
              <div className="error">
                {currentNotif.isError &&
                  <FeatherIcon
                    icon="alert-circle"
                    height={43}
                    width={43}
                    style={{
                      position: "relative",
                    }}
                  />
                }
                <div
                  className="title"
                  id="errorModalTitle"
                  style={{
                    padding: currentNotif.isError ? "5px 0 0 27px" : "0 15px",
                    justifyContent: currentNotif.isError ? "start" : "center",
                  }}
                >
                  {getTitle()}
                </div>
              </div>
              <div
                className="description"
                style={{
                  padding: currentNotif.isError ? "0 0 0 65px" : "0 15px"
                }}>
                {getDescription()}
              </div>
              {getButtonCombinations()}
            </div>
          </div>
        </div>
      </div>
    )
  );
}

NotificationPopup.prototype = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  userName: PropTypes.string,
};
