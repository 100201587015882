import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

const MDC_MESSAGES = Api.MDC_MESSAGES;

/**
   Get the MDC event history list for a specific retrofit aircraft session or date range.
   * @param {String} tailNumber 
   * @param {String} aircraftFamily 
   * @param {String} aircraftModel 
   * @param {String} sessionNumber 
    
   * @returns Mdc messsages
   **/
export async function getMdcMessages(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  sessionNumber,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      aircraftFamily,
      aircraftModel,
      tailNumber,
      sessionNumber,
    },
  };

  try {
    result = await AxiosService(
      MDC_MESSAGES.url,
      MDC_MESSAGES.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result.data;
}
