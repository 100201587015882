import React from "react";
import * as C from "./Colors";

import Joyride, { TooltipRenderProps, ACTIONS } from "react-joyride";
import getSteps from "./Onboarding Configurations";
import {
  getProgress,
  updateProgress,
} from "../clients/ApplicationOnboardingClient";

const debugSteps = false; // debug will always show steps

export default function Wizard(props) {
  const iofgKey = props.iofgKey || "NEW_USER_ONBOARDING";
  const pageKey = props.pageKey || "FLEET_V_PG";
  const [steps, setSteps] = React.useState([]);
  const [completionStatus, setCompletionStatus] = React.useState(false);
  const [haltWizard, setHaltWizard] = React.useState(false);

  React.useEffect(() => {
    let progress = getProgress();

    progress.then((progressReport) => {
      if (progressReport && progressReport.length > 0) {
        // filter progress for requested course
        let progress = progressReport.filter(
          (progressStep) => progressStep.iofgKey == iofgKey
        )[0];
        let topicResults = progress.appPages.filter(
          (page) => page.pageKey == pageKey
        )[0];

        if (
          debugSteps ||
          !topicResults ||
          !topicResults.hasOwnProperty("completionStatus") ||
          !topicResults.completionStatus
        ) {
          setSteps(getSteps(iofgKey, pageKey));
        }
      }
    });
  }, []);

  // effect for updates
  React.useEffect(() => {
    if (completionStatus) {
      let courseStatus = {
        iofgKey: iofgKey,
        pageKey: pageKey,
        completionStatus: completionStatus,
      };
      updateProgress(courseStatus);
    }
    if (haltWizard) {
      setSteps([]);
    }
  }, [completionStatus, haltWizard]);

  // styles
  const backgroundColor = C.grey;
  const styleOptions = {
    options: {
      backgroundColor: backgroundColor,
      arrowColor: backgroundColor,
      textColor: C.white,
      primaryColor: C.white,
      overlayColor: "rgba(0, 0, 0, 0.5)",
      hideBackButton: true,
      width: 600,
      zIndex: 5000,
    },
    buttonNext: {
      color: backgroundColor,
      borderRadius: "34.5px",
    },
    buttonBack: {
      color: backgroundColor,
      borderRadius: "34.5px",
    },
    tooltipContainer: {
      textAlign: "left",
      fontFamily: "DM Sans",
    },
  };

  const handleCallback = (data) => {
    if (data.action === ACTIONS.CLOSE) {
      setHaltWizard(true);
    }
    if (data.lifecycle === "complete" && data.status === "finished") {
      setCompletionStatus(true);
    }
    if (props.specialAction) {
      props.specialAction(data.index);
    }
  };

  const handleHelpers = (data) => {};

  const arrowHelper = (action, props) => {};

  const floaterSettings = {
    hideArrow: false,
    callback: arrowHelper,
  };
  // basic defaults
  const settings = {
    autoStart: true,
    callback: handleCallback,
    continuous: true,
    debug: false, // set true to debug joyride
    disableCloseOnEsc: false,
    disableOverlay: false,
    disableOverlayClose: true,
    disableScrolling: false,
    disableScrollParentFix: false,
    floaterProps: floaterSettings,
    getHelpers: handleHelpers,
    hideBackButton: false,
    hideCloseButton: true,
    locale: {
      back: "Back",
      close: "Close",
      last: "Done",
      next: "Next",
      open: "Start",
      skip: "Skip Ahead",
    },
    nonce: "xyz",
    scrollOffset: 20,
    scrollDuration: 300,
    scrollToFirstStep: false,
    showProgress: false,
    showSkipButton: false,
    spotlightPadding: 10,
    steps: steps,
    styles: styleOptions,
  };

  const wizard = () => {
    return (
      <div>
        <Joyride {...settings} />
      </div>
    );
  };

  return wizard();
}
