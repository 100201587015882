import axios from "axios";
import moment from "moment";
import * as Constants from "../Constants/Constants";
import * as Api from "../Constants/ApiCatalog";

/*
  API Axios client middleware.
  - This code intercepts and handles token expiration errors to call the refresh token API
  - This code hanbdles axios API cancellations to avoid memory leaks in cases when a user leaves a page before API response
  - This code handles the different http method calls and configuration for the axios client.
*/

const TOKEN_REFRESH = Api.TOKEN_REFRESH;

const handleTokenRefresh = () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("tokenRefresh")}`,
    demo: Constants.MYDOM_DEMO_INSTANCE(),
  };
  axios
    .post(TOKEN_REFRESH.url, undefined, {
      headers: headers,
    })
    .then((response) => {
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("tokenExpiration", response.data.exp);
    })
    .catch(function(error) {
      console.error("ERROR: ", error.response);
    });
};

const AxiosRequest = async (
  url,
  type,
  data,
  cancelSource,
  hasNotifications,
  config
) => {
  const axiosInstance = axios.create();

  // Request Interceptor: Validates for two minutes before expiration limit
  const secondsBeforeExpiration = 120 * -1;
  const tokenDuration =
    (moment.utc().valueOf() -
      moment.utc(localStorage.getItem("tokenExpiration")).valueOf()) /
    1000;

  axiosInstance.interceptors.request.use(
    async (config) => {
      if (tokenDuration > secondsBeforeExpiration) {
        await handleTokenRefresh();
      }

      if (hasNotifications) {
        config.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        };
      } else {
        config.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          demo: Constants.MYDOM_DEMO_INSTANCE(),
        };
      }

      return config;
    },
    (errorRes) => {
      console.error("API Request Error: ", errorRes);
    }
  );

  // Response Interceptor: Validates if 401 error is triggered to refresh token and launch the call again
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (!error.response) {
        console.error("API Response Error: ", error);
        return {
          error: "Unhandled Network Error: " + error,
        };
      }

      if (error.response.status !== 401) {
        if (axios.isCancel(error)) {
          console.error("Canceling Pending Request: ", error.response);
        } else {
          if (error.response.status === 403) {
            console.error("API Response Forbidden Error: ", error.response);
            return error.response;
          } else {
            console.error("API Response Error: ", error.response);
            return error;
          }
        }
      }

      if (error.response.status === 401) {
        console.error("API Response Token Error: ", error.response);
        await handleTokenRefresh();
      } else {
        console.error("API Response Error: ", error.response);
      }

      if (hasNotifications) {
        error.config.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        };
      } else {
        error.config.headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
          demo: Constants.MYDOM_DEMO_INSTANCE(),
        };
      }

      return new Promise((resolve, reject) => {
        axios
          .request(error.config)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  );

  let apiResult;
  if (cancelSource) {
    if (type === "PUT") {
      apiResult = await axiosInstance.put(url, data, {
        cancelToken: cancelSource.token,
      });
    } else if (type === "POST") {
      apiResult = await axiosInstance.post(url, data, {
        cancelToken: cancelSource.token,
      });
    } else if (type === "DELETE") {
      apiResult = await axiosInstance.delete(url, {
        data: data,
        cancelToken: cancelSource.token,
      });
    } else {
      apiResult = await axiosInstance.get(url, data, {
        cancelToken: cancelSource.token,
        withCredentials: true,
      });
    }
  } else {
    if (type === "PUT") {
      apiResult = await axiosInstance.put(url, data);
    } else if (type === "POST") {
      if (config) {
        apiResult = await axiosInstance.post(url, data, config);
      } else {
        apiResult = await axiosInstance.post(url, data);
      }
    } else if (type === "DELETE") {
      apiResult = await axiosInstance.delete(url, {
        data: data
      });
    } else {
      apiResult = await axiosInstance.get(url, data, { withCredentials: true });
    }
  }

  return apiResult;
};

export default AxiosRequest;
