import React, {useEffect, useRef, useState} from "react";
import TemplateTable from "../../Graph Components/TemplateTable.js";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import moment from "moment";
import CustomCheckbox from "../../Global Components/CustomCheckbox.jsx";
import CustomTooltip from "../../Global Components/CustomTooltip";
import {
  getMaterialReactTableColumnDef, getMaterialReactTableColumnDefCms,
  getMaterialTableColumnDef,
  getMaterialTableColumnDefCms,
} from "../../Graph Components/Chart Options/MaterialTableConfig.js";
import CustomButton from "../../Global Components/CustomButton.jsx";
import "./parameterSelection.css";
import FeatherIcon from "feather-icons-react";
import CircularProgress from "@mui/material/CircularProgress";
import * as tools from "../../utils/CommonTools";
import MaterialReactTable from "material-react-table";
import ParameterTable from "../../Global Components/ParameterTable.js";

export default function ParameterSelection(props) {
  const paramActionTabs = ["Add Parameters", "Parameters Overview"];
  const [checkedAllState, setCheckedAllState] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [oldRowSelection, setOldRowSelection] = useState({});

  // Catches the latest row selection and triggers the callback.
  useEffect(() => {
    let oldLength = Object.keys(oldRowSelection).length;
    let newLength = Object.keys(rowSelection).length;
    if( newLength > oldLength ) {
      for( let key in rowSelection ) {
        const selectedParameterName = props.allParameters[key].parameterName;
        const parameterExistsInOverview = props.parametersToAdd.find(param => {
          return param.parameterName === selectedParameterName;
        });
        if(!oldRowSelection[key]) {
          if(!parameterExistsInOverview) {
            props.parameterToggle(key, props.allParameters[key], false);
          }
        }
      }
    } else {
      for( let key in oldRowSelection) {
        const selectedParameterName = props.allParameters[key].parameterName;
        const parameterExistsInOverview = props.parametersToAdd.find(param => {
          return param.parameterName === selectedParameterName;
        });
        if(!rowSelection[key]) {
          if(parameterExistsInOverview) {
            removeParameterFromOverview(selectedParameterName, key);
          }
          props.parameterToggle(key, props.allParameters[key], true);
        }
      }
    }
    setOldRowSelection(rowSelection);
  }, [rowSelection]);

  useEffect(() => {
    const copy = {...rowSelection};
    let updateSelection = false;
    props.allParameters.forEach((param, index) => {
      if(param.checked && !copy[index]) {
        copy[index] = true;
        updateSelection = true;
      } else if (!param.checked && copy[index]) {
        delete copy[index];
        updateSelection = true;
      }
    });
    if(updateSelection) {
      setRowSelection(copy);
    }
  }, [props.allParameters]);


  const handleSelectionChange = (updater) => {
    setRowSelection((prevState) =>  {
      if (updater instanceof Function) {
        return updater(prevState)
      } else{
        return updater
      }
    });
  }
  const removeParameterFromOverview = (parameterName, index) => {
    props.removeParameter(
        parameterName,
        index
    );
    const copy = {...rowSelection};
    delete copy[index];
    setRowSelection(copy);
  }

  let parameterSelectionDescription = "Choose which Flight parameters to load:";

  let fdeColumnDefinition = getMaterialReactTableColumnDef();
  let cmsColumnDefinition = getMaterialReactTableColumnDefCms();
  if (props.dataFocus === "FLIGHT") {
    parameterSelectionDescription = (
      <div>
        <span style={{ fontWeight: "900" }}>
          {props.parameterListSizes.continuousParameters + " Continuous and "}
          {props.parameterListSizes.fdeFaultParameters + " Event "}
        </span>
        <span>{"parameters are related to the selected event"}</span>
      </div>
    );
  } else if (props.dataFocus === "CMS") {
    parameterSelectionDescription = (
      <div>
        <span style={{ fontWeight: "900" }}>
          {props.parameterListSizes.continuousParameters + " Continuous and "}
          {props.parameterListSizes.cmsParameters + " CMS Parameters"}
        </span>
      </div>
    );
  } else if (props.dataFocus === "IN-FLIGHT" || props.dataFocus === "FAULT") {
    parameterSelectionDescription = (
      <div>
        {props.dataFocus === "IN-FLIGHT" ? (
          <div>
            <span style={{ fontWeight: "900" }}>
              {props.parameterListSizes.fdeFaultParameters +
                " Event Parameters "}
            </span>
            <span>{"parameters are related to the selected event"}</span>
          </div>
        ) : (
          <div>
            <span style={{ fontWeight: "900" }}>
              {props.parameterListSizes.continuousParameters +
                " Continuous and "}
              {props.parameterListSizes.fdeFaultParameters + " Event "}
            </span>
            <span>{"parameters are related to the selected event"}</span>
          </div>
        )}
        {props.parameterListSizes.fdeFaultParameters !== 0 ? (
          <div>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div>
                <CustomCheckbox
                  isChecked={checkedAllState}
                  onChangeAction={() => {
                    setCheckedAllState(!checkedAllState);

                    if (props.dataFocus === "IN-FLIGHT") {
                      props.toggleAllCheckBoxes(checkedAllState);
                    } else {
                      props.toggleEventParameterCheckBoxes(checkedAllState);
                    }
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "6px",
                  fontWeight: "bolder",
                  fontSize: "1em",
                }}
              >
                {props.dataFocus === "Fault"
                  ? "Select all parameters."
                  : "Select all FDE Event parameters"}
              </div>
            </div>
            <div style={{ width: "100%", color: "#ff5050", fontSize: ".8em" }}>
              {
                "Warning: if you load all the parameters, the performance of the graphs may degrade significantly."
              }
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div
          style={{
            width: "100%",
            color: "#ff5050",
            fontSize: ".8em",
          }}
        >
          {
            "Note: Snapshot data is currently being plotted as a single data point in the graphs."
          }
        </div>
      </div>
    );
  }

  const cancelAction = () => {
    window.history.back();
  };

  //creating annotations for cms altitude chart
  const xAxisAnnotations = [];

  if (props.cmsFlights) {
    for (let i = 0; i < props.cmsFlights.length; i++) {
      let dAnnotation = {
        id: "flight_" + i + "_departure",
        x: props.cmsFlights[i]
          ? moment.utc(props.cmsFlights[i].departureTime).valueOf()
          : new Date(props.cmsFlights[i].departureTime).getTime(),
        strokeDashArray: 1,
        borderWidth: 1,
        borderColor: "white",
        label: {
          borderColor: "black",
          style: {
            color: "black",
            background: "white",
            fontSize: ".6em",
            fontWeight: "500",
          },
          text: "Departure",
          position: "top",
          textAnchor: "middle",
          orientation: "vertical",
          offsetX: 0,
          offsetY: -24,
        },
      };

      let aAnnotation = {
        id: "flight_" + i + "_arrival",
        x: props.cmsFlights[i]
          ? moment.utc(props.cmsFlights[i].arrivalTime).valueOf()
          : new Date(props.cmsFlights[i].arrivalTime).getTime(),
        strokeDashArray: 1,
        borderWidth: 1,
        borderColor: "white",
        label: {
          borderColor: "black",
          style: {
            color: "black",
            background: "white",
            fontSize: ".6em",
            fontWeight: "500",
          },
          text: "Arrival",
          position: "top",
          orientation: "vertical",
          textAnchor: "middle",
        },
      };
      xAxisAnnotations.push(dAnnotation);
      xAxisAnnotations.push(aAnnotation);
    }
  }

  const [cmsLineChartOpts] = useState({
    chart: {
      id: "chart2",
      type: "line",
      height: 230,
      toolbar: {
        autoSelected: "pan",
        show: false,
      },
    },
    colors: ["#008FFB"],
    stroke: {
      width: 3,
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      type: "datetime",
      labels: {
        show: false,
      },
    },
  });

  const [cmsBrushChartOpt, setCmsBrushChartOpt] = useState({
    chart: {
      id: "chart1",
      height: 130,
      type: "area",
      brush: {
        target: "chart2",
        enabled: true,
      },
      toolbar: {
        show: false,
      },
      selection: {
        enabled: true,
        fill: {
          color: "#FFFFFF",
          opacity: 0.4,
        },
        stroke: {
          color: "#FFFFFF",
          opacity: 4,
        },
        xaxis: {
          min: props.cmsStartTime,
          max: props.cmsEndTime,
        },
      },
      events: {
        selection: function(event, chartContext, config) {
          if (
            chartContext.xaxis.max - chartContext.xaxis.min <
            props.maxSessionChartingRange
          ) {
            cmsBrushChartOpt.chart.selection.xaxis.min = chartContext.xaxis.min;
            cmsBrushChartOpt.chart.selection.xaxis.max = chartContext.xaxis.max;
            setCmsBrushChartOpt({ ...cmsBrushChartOpt });
            // ApexCharts.exec(
            //   "chart1",
            //   "updateOptions",
            //   {
            //     charts: {
            //       selection: {
            //         xaxis: {
            //           min: chartContext.xaxis.min,
            //           max: chartContext.xaxis.max,
            //         },
            //       },
            //     },
            //   },
            //   false,
            //   true
            // );
            props.updateCmsStartTime(chartContext.xaxis.min);
            props.updateCmsEndTime(chartContext.xaxis.max);
          } else {
            ApexCharts.exec(
              "chart2",
              "updateOptions",
              {
                charts: {
                  selection: {
                    xaxis: {
                      min: props.cmsStartTime,
                      max: props.cmsEndTime,
                    },
                  },
                },
              },
              false,
              true
            );
          }
        },
      },
    },
    theme: {
      mode: "light",
      palette: "palette10",
      monochrome: {
        enabled: false,
        color: "#255aee",
        shadeTo: "dark",
        shadeIntensity: 0.65,
      },
    },
    colors: ["#008FFB"],
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      },
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        show: true,
        style: {
          colors: [
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
          ],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisTicks: {
        show: true,
      },
      min: props.startTime,
      max: props.endTime,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    annotations: {
      xaxis: xAxisAnnotations,
    },
  });

  return (
    <div className="parameter__selection__cntr">
      <div className="parameter__selection__instructions">
        {parameterSelectionDescription}
      </div>
      <div className="parameter__selection__tabs">
        {props.dataFocus !== "RPD" && (
          <div
            className={
              props.actionTab === paramActionTabs[0]
                ? "parameter__selection__tab param__action__tab__selected"
                : "parameter__selection__tab"
            }
            onClick={() => {
              props.changeActionTab(paramActionTabs[0]);
            }}
          >
            {paramActionTabs[0]}
          </div>
        )}
        <div
          className={
            props.actionTab === paramActionTabs[1]
              ? "parameter__selection__tab param__action__tab__selected"
              : "parameter__selection__tab"
          }
          onClick={() => {
            props.changeActionTab(paramActionTabs[1]);
          }}
        >
          {paramActionTabs[1]}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              fontWeight: "900",
              fontSize: "16px",
              width: "480px",
              marginBottom: "4px",
              textAlign: "right",
              height: "16px",
            }}
          >
            {"Parameters: " +
              props.parametersToAdd.length +
              "/" +
              props.numParametersAllowed}
          </div>
          {props.parametersToAdd.length === props.numParametersAllowed && (
            <div
              style={{
                color: "red",
                width: "480px",
                marginBottom: "4px",
                textAlign: "right",
                fontWeight: "bolder",
              }}
            >
              You have reached the max number of parameters allowed.
            </div>
          )}
        </div>
      </div>
      {props.actionTab === paramActionTabs[0] && (
        <div>
          {props.allParameters.length > 0 &&
          (props.dataFocus === "RPD" || props.parametersLoaded) ? (
              <ParameterTable
                columns={ props.dataFocus === "CMS"
                    ? cmsColumnDefinition
                    : fdeColumnDefinition}
                data={props.allParameters}
                dataFocus={props.dataFocus}
                getCmsParameterInfo={props.getCMSParameterInfo}
                externalSelectionState={rowSelection}
                handleExternalSelectionChange={handleSelectionChange}
              />
          ) : !props.parametersLoaded ? (
            <div
              style={{
                textAlign: "center",
                height: "300px",
                paddingTop: "100px",
                backgroundColor: "rgb(16, 16, 16)",
                border: "1px solid #979797",
              }}
            >
              <CircularProgress style={{ color: "#979797" }} />
            </div>
          ) : (
            <div className="param__selection_no_data_error">
              {
                "Sorry, there are no parameters returned for this time period. If this is an error, please submit feedback with details."
              }
            </div>
          )}
          {props.dataFocus === "CMS" && props.faultCode === "N/A" && (
            <div className="param__overview-cms-window-selection">
              <div>
                <span className="param__overview-cms-window-title">
                  Choose Time Interval:
                </span>
                <div>
                  {(props.cmsEndTime - props.cmsStartTime) / 60000 -
                    (
                      ((props.cmsEndTime - props.cmsStartTime) / 60000) %
                      1
                    ).toString() +
                    " / " +
                    Math.trunc(
                      props.maxSessionChartingRangeMinutes.toString()
                    ) +
                    " minutes maximum" +
                    " (" +
                    tools.convertMinutesToHoursMinutesText(
                      props.maxSessionChartingRangeMinutes
                    ) +
                    ")"}
                </div>
              </div>
              <div className="param__overview-cms-window-charts-ctnr">
                <div className="param__overview-cms-window-charts">
                  <div style={{ flex: "50%", display: "flex" }}>
                    <div
                      style={{
                        height: "100px",
                        width: "600px",
                        color: "white",
                        paddingLeft: "20px",
                        paddingTop: "40px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "bolder",
                          paddingBottom: "20px",
                          fontSize: "14px",
                        }}
                      >
                        <span> Start: </span>
                        &nbsp;
                        {moment
                          .utc(props.cmsStartTime)
                          .format("YYYY-MM-DD\xa0HH:mm:ss")}
                        &nbsp;&nbsp; - &nbsp;&nbsp;
                        <span> End:</span>
                        &nbsp;
                        {moment
                          .utc(props.cmsEndTime)
                          .format("YYYY-MM-DD\xa0HH:mm:ss")}
                      </div>
                      <span></span>
                    </div>
                  </div>
                  <div className="param__overview-cms-window-chart1">
                    <div style={{ width: "500px", display: "none" }}>
                      <ReactApexChart
                        style={{ border: "1px solid white" }}
                        options={cmsLineChartOpts}
                        series={[
                          props.altitudeData
                            ? {
                                name: props.altitudeData.name
                                  ? props.altitudeData.name
                                  : "Altitude",
                                data: props.altitudeData.data
                                  ? props.altitudeData.data
                                  : [0, 0, 0, 0, 0, 0],
                              }
                            : {
                                name: "Altitude",
                                data: [0, 0, 0, 0, 0, 0],
                              },
                        ]}
                        type="area"
                        height={0}
                      />
                    </div>
                  </div>
                  <div className="param__overview-cms-window-chart2">
                    <div className="param__overview-cms-window-chart2-label">
                      Altitude
                    </div>
                    <div style={{ width: "500px" }}>
                      <ReactApexChart
                        options={cmsBrushChartOpt}
                        series={[
                          props.altitudeData
                            ? {
                                name: props.altitudeData.name
                                  ? props.altitudeData.name
                                  : "Altitude",
                                data: props.altitudeData.data
                                  ? props.altitudeData.data
                                  : [0, 0, 0, 0, 0, 0],
                              }
                            : {
                                name: "Altitude",
                                data: [0, 0, 0, 0, 0, 0],
                              },
                        ]}
                        type="area"
                        height={120}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              padding: "20px",
              backgroundColor: "rgb(16, 16, 16)",
              paddingBottom: "60px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
              }}
              id={"cancel-update-charts"}
            >
              <div style={{ paddingLeft: "20px" }}>
                <CustomButton
                  id={"id-cancel-charts"}
                  text={"CANCEL"}
                  isDisabled={false}
                  customWidth={"200"}
                  customHeight={"40"}
                  colorStyle="secondary"
                  onClickAction={() => {
                    cancelAction();
                  }}
                />
              </div>
              <div style={{ paddingLeft: "20px" }}>
                <CustomButton
                  id={"id-update-charts"}
                  text={"CREATE CHARTS"}
                  isDisabled={props.updatingCharts ? true : false}
                  customWidth={"200"}
                  customHeight={"40"}
                  onClickAction={() => {
                    props.updateCharts();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {props.actionTab === paramActionTabs[1] && (
        <div>
          <div className="param__overview__header">
            <div className="param__overview__col__padding flex__40">
              Parameter Name (Long)
            </div>
            <div className="param__overview__col__padding flex__20">Type</div>
            <div className="param__overview__col__padding flex__10">
              "Delete?"
            </div>
            <div
              className="flex__30"
              style={{
                textAlign: "center",
                paddingTop: "22px",
                fontSize: "20px",
              }}
            >
              Load Status
            </div>
          </div>
          <div className="param__window__scroll">
            {props.parametersToAdd.map((paramToAdd, index) => (
              <React.Fragment key={paramToAdd.parameterName}>
                <div
                  style={{
                    width: "100%",
                    height: "60px",
                    borderTop: "1px solid #979797",
                    display: "flex",
                    backgroundColor: "rgb(16, 16, 16)",
                  }}
                >
                  <div
                    style={{
                      flex: "40%",
                      paddingTop: "16px",
                      paddingLeft: "16px",
                    }}
                  >
                    {paramToAdd.parameterName}
                  </div>
                  <div
                    style={{
                      flex: "20%",
                      paddingTop: "16px",
                      paddingLeft: "16px",
                    }}
                  >
                    {paramToAdd.type}
                  </div>
                  <div
                    style={{
                      flex: "10%",
                      paddingTop: "16px",
                      paddingLeft: "16px",
                    }}
                  >
                    <div style={{ cursor: "pointer" }}>
                      {paramToAdd.loaded !== null && (
                        <FeatherIcon
                          icon="trash-2"
                          height={30}
                          onClick={() => {
                            removeParameterFromOverview(paramToAdd.parameterName, index);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: "30%",
                      borderLeft: "4px solid #808080",
                    }}
                  >
                    {paramToAdd.loaded === null && (
                      <CustomTooltip
                        tooltipType={"semantic"}
                        content={"Click 'Create Charts' to load parameter."}
                        icon={<FeatherIcon icon="info" height={20} />}
                        placement={"left"}
                      >
                        <div className="parameterSelection__loadingStatus">
                          <FeatherIcon icon="circle" height={25} />
                          <span style={{ marginLeft: "10px" }}>
                            Waiting to load
                          </span>
                        </div>
                      </CustomTooltip>
                    )}
                    {paramToAdd.loaded === false && (
                      <div className="parameterSelection__loadingStatus">
                        <CircularProgress style={{ color: "#979797" }} />
                        <span style={{ marginLeft: "10px" }}>Loading</span>
                      </div>
                    )}
                    {paramToAdd.loaded === true && (
                      <CustomTooltip
                        tooltipType={"semantic"}
                        content={"Successfully Loaded"}
                        icon={<FeatherIcon icon="check-circle" height={20} />}
                        placement={"left"}
                      >
                        <div className="parameterSelection__loadingStatus parameterSelection__loadingStatus__successfull">
                          <FeatherIcon icon="check" height={25} />
                          <span style={{ marginLeft: "10px" }}>Loaded</span>
                        </div>
                      </CustomTooltip>
                    )}
                    {paramToAdd.loaded === "error" && (
                      <CustomTooltip
                        tooltipType={"semantic"}
                        content={paramToAdd.error}
                        icon={<FeatherIcon icon="alert-triangle" height={20} />}
                        placement={"left"}
                      >
                        <div className="parameterSelection__loadingStatus parameterSelection__loadingStatus__failed">
                          <FeatherIcon icon="x" height={25} />
                          <span style={{ marginLeft: "10px" }}>Failed</span>
                        </div>
                      </CustomTooltip>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          {/* {props.parametersToAdd.length === 0 && */}
          <div
            style={{
              width: "100%",
              height: "70px",
              borderTop: "1px solid #979797",
              backgroundColor: "rgb(16, 16, 16)",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            {props.parametersToAdd.length === 0 &&
              "No Parameters Loaded. Please select parameters to load."}
          </div>
        </div>
      )}
      {props.dataFocus === "RPD" && (
        <div
          style={{
            display: "flex",
            alignItems: "middle",
            justifyContent: "right",
            backgroundColor: "rgb(16, 16, 16)",
            padding: "0px 20px 20px 20px",
            height: "120px",
          }}
        >
          <div style={{ paddingLeft: "20px" }}>
            <CustomButton
              id={"id-update-charts"}
              text={"CANCEL"}
              isDisabled={false}
              customWidth={"200"}
              customHeight={"40"}
              colorStyle="secondary"
              onClickAction={() => {
                cancelAction();
              }}
            />
          </div>
          <div style={{ paddingLeft: "20px" }}>
            <CustomButton
              id={"id-update-charts"}
              text={"CREATE CHARTS"}
              isDisabled={props.updatingCharts ? true : false}
              customWidth={"200"}
              customHeight={"40"}
              onClickAction={() => {
                props.updateCharts();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
