import React, { useState } from "react";
import "../styles/Global Components/NotificationPopup.css";
import PropTypes from "prop-types";
import FeatherIcon from "feather-icons-react";
import ErrorCatalog from "../resources/ErrorCatalog.json";
import * as tools from "../utils/CommonTools";
import { Link } from "react-router-dom";
import { Link as LinkRedirect } from "@mui/material";
import CustomButton from "../Global Components/CustomButton";

export default function NotificationPopup(props) {
  const [showErrorDetail, setShowErrorDetail] = useState(false);
  const errorDetailOnClick = () => setShowErrorDetail(!showErrorDetail);

  var currentError = {
    errorCode: "",
    closeModal: "",
    userName: "",
    type: "",
    hideClose: "",
    description: "",
    descriptionSituation: "",
    color: "",
    apiErrorCode: "-"
  };

  const handleWindowScrollStatus = (isModalOpen) => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  if (props.open) {
    handleWindowScrollStatus(true);
    if (props.errorCode && props.closeModal) {
      const splitedErrorCode = props.errorCode
        .replace(/[\[\]]/g, "")
        .split("-");

      const errorInfoFromDB =
        ErrorCatalog[splitedErrorCode[0]][splitedErrorCode[1]][
        splitedErrorCode[2]
        ];
      let apiErrorCode = "-";
      if(props.errorObject) {
        apiErrorCode = props.errorObject['error_id'] ?
          props.errorObject['error_id'] : "-";
      }

      currentError = {
        errorCode: props.errorCode,
        closeModal: props.closeModal,
        userName: props.userName,
        type:
          splitedErrorCode[0] === "ER"
            ? "ERROR"
            : splitedErrorCode[0] === "NC"
              ? "NOTICE"
              : "ALERT",
        reloadPage: props.reloadPage
          ? props.reloadPage
          : errorInfoFromDB["reloadPage"],
        alternativeExistLink: props.alternativeExistLink
          ? props.alternativeExistLink
          : errorInfoFromDB["alternativeExistLink"],
        description: props.description
          ? props.description
          : errorInfoFromDB["description"],
        descriptionSituation: props.descriptionSituation
          ? props.descriptionSituation
          : null,
        color: props.color ? props.color : errorInfoFromDB["color"],
        technical_detail: props.technical_detail
          ? props.technical_detail
          : errorInfoFromDB["technical_detail"],
        noSessionStartAlternative: errorInfoFromDB["noSessionStartAlternative"]
          ? errorInfoFromDB["noSessionStartAlternative"]
          : false,
        apiErrorCode: apiErrorCode
      };
    }
  }

  const closeNotificationModal = () => {
    handleWindowScrollStatus(false);
    currentError.closeModal();
  };

  const handleReloadPage = () => {
    handleWindowScrollStatus(false);
    window.location.reload();
  };

  const handleLogout = () => {
    handleWindowScrollStatus(false);
    tools.cleanAuthLocalStorage();
  };

  const handleNoSessionStartAlternativeProceed = () => {
    if (props.noSessionStartAlternativeProceed) {
      closeNotificationModal();
      props.noSessionStartAlternativeProceed();
    }
  };

  const getWarningTitle = () => {
    if (currentError.noSessionStartAlternative) {
      return currentError.userName ? "" + currentError.userName : "";
    } else if (currentError.type === "NOTICE") {
      return "Important Notice";
    } else {
      return (
        "We’re sorry" +
        (currentError.userName ? ", " + currentError.userName : "")
      );
    }
  };

  const getWarningApologyDescription = () => {
    if (currentError.noSessionStartAlternative) {
      return "";
    } else {
      return (
        <div
          className={
            "descriptionSituation" +
            (currentError.type === "NOTICE" ? " notif-notice-font-title" : "")
          }
          id="apologyDescription"
        >
          {currentError.descriptionSituation
            ? currentError.descriptionSituation
            : currentError.type === "ALERT"
              ? "We’re currently not able to fulfill your request."
              : "We weren’t able to fulfill your request."}
        </div>
      );
    }
  };

  const getWarningDescription = () => {
    if (currentError.noSessionStartAlternative) {
      return (
        <div
          className="descriptionSituation notificationPopup__font__medium"
          id="situationDescription"
        >
          {currentError.description}
        </div>
      );
    } else {
      return (
        <div className="descriptionSituation" id="situationDescription">
          {currentError.description}
        </div>
      );
    }
  };

  const getWarningExitDescription = () => {
    if (currentError.noSessionStartAlternative) {
      return (
        <div
          className="descriptionSituation notificationPopup__font__medium"
          id="exitDescription"
        >
          {"Would you like to open the historic data for this aircraft?"}
        </div>
      );
    } else {
      return (
        <div className="descriptionSituation" id="exitDescription">
          {currentError.descriptionSituation
            ? "If you have further questions, please open a ticket in the feedback tab."
            : currentError.type === "ALERT"
              ? "We continuously receive and process new data. Your data will be available soon. If you have further questions, please open a ticket in the feedback tab."
              : "Please try reloading the page again. If this error persists, please open a ticket in the feedback tab."}
        </div>
      );
    }
  };

  const getWarningButtonCombinations = () => {
    if (currentError.type === "NOTICE") {
      return (
        <div className="notif-popup-buttonSection-notice">
          <CustomButton
            id={"closeButtonErrorModal"}
            text={"OK"}
            onClickAction={closeNotificationModal}
          />
        </div>
      );
    }
    return (
      <div className="notif-popup-buttonSection">
        {currentError.alternativeExistLink ? (
          <span>
            <Link to={currentError.alternativeExistLink}>
              <CustomButton
                id={"closeButtonErrorModal"}
                text={"CANCEL"}
                colorStyle={"secondary"}
                onClickAction={closeNotificationModal}
              />
            </Link>
          </span>
        ) : (
          currentError.type === "ALERT" && (
            <span>
              <LinkRedirect
                color="primary"
                onClick={handleLogout}
                href={"/applogout"}
              >
                <CustomButton
                  id={"logoutButtonErrorModal"}
                  text={"LOGOUT"}
                  colorStyle={"secondary"}
                  icon={<FeatherIcon icon="log-out" height={20} width={20} />}
                  iconPosition={"end"}
                />
              </LinkRedirect>
            </span>
          )
        )}
        {currentError.noSessionStartAlternative === false &&
          (currentError.reloadPage ? (
            <span>
              <CustomButton
                id={"closeButtonErrorModal"}
                text={"RELOAD"}
                icon={<FeatherIcon icon="refresh-ccw" height={20} width={20} />}
                iconPosition={"end"}
                onClickAction={handleReloadPage}
              />
            </span>
          ) : (
            <span>
              <CustomButton
                id={"closeButtonErrorModal"}
                text={"CLOSE"}
                icon={<FeatherIcon icon="x" height={20} width={20} />}
                iconPosition={"end"}
                onClickAction={closeNotificationModal}
              />
            </span>
          ))}

        {currentError.noSessionStartAlternative && (
          <div style={{ marginLeft: "50px" }}>
            <CustomButton
              id={"closeButtonErrorModal"}
              text={"PROCEED"}
              colorStyle={"custom"}
              customButtonColor={currentError.color}
              customButtonTextColor={"#000000"}
              icon={<FeatherIcon icon="arrow-right" height={20} width={20} />}
              iconPosition={"end"}
              onClickAction={handleNoSessionStartAlternativeProceed}
            />
          </div>
        )}
      </div>
    );
  };

  const getWarningTechnicalDetails = () => {
    if (currentError.noSessionStartAlternative) {
      return <div></div>;
    } else {
      return (
        <div>
          <div className="errorDetails" onClick={errorDetailOnClick}>
            {"Error details"}
            <div className="errorIcon">
              {showErrorDetail ? (
                <FeatherIcon
                  icon="chevron-up"
                  height={20}
                  width={20}
                  style={{
                    position: "relative",
                  }}
                />
              ) : (
                <FeatherIcon
                  icon="chevron-down"
                  height={20}
                  width={20}
                  style={{
                    position: "relative",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    props.open && (
      <div
        className={
          "notif-modal-popup-open-" + (props.open === true ? "true" : "false")
        }
      >
        <div className="notif-modal-popup-screen">
          <div
            className={
              "notif-modal-popup" +
              (currentError.type === "NOTICE"
                ? " notif-modal-popup-type-notice"
                : "")
            }
          >
            <div
              className="notif-popup-flag"
              style={{
                backgroundColor: currentError.color,
              }}
            ></div>
            {currentError.type === "NOTICE" ? (
              <div className="notif-popup-body">
                <div className="error">
                  <FeatherIcon
                    icon="alert-circle"
                    height={43}
                    width={43}
                    style={{
                      position: "relative",
                    }}
                  />
                  <div className="title" id="errorModalTitle">
                    {getWarningTitle()}
                  </div>
                </div>
                <div className="description-type-notice">
                  {getWarningApologyDescription()}
                  <div className="notif-notice-body">
                    {getWarningDescription()}
                  </div>
                </div>
                {getWarningButtonCombinations()}
              </div>
            ) : (
              <div className="notif-popup-body">
                <div className="error">
                  <FeatherIcon
                    icon="alert-circle"
                    height={43}
                    width={43}
                    style={{
                      position: "relative",
                    }}
                  />
                  <div className="title" id="errorModalTitle">
                    {getWarningTitle()}
                  </div>
                </div>
                <div className="description">
                  {getWarningApologyDescription()}
                  {getWarningDescription()}
                  {getWarningExitDescription()}
                </div>
                {getWarningButtonCombinations()}
                {getWarningTechnicalDetails()}

                <div className="errorMessage">
                  {showErrorDetail ? "" + currentError.errorCode : null}
                </div>
                <div className="errorMessage">
                  {showErrorDetail ? "aId: " + currentError.apiErrorCode : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}

NotificationPopup.prototype = {
  open: PropTypes.bool.isRequired,
  errorCode: PropTypes.string,
  closeModal: PropTypes.func,
  userName: PropTypes.string,
  errorObject: PropTypes.any
};