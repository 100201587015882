// This function determines the header that will be shown on the charts tab of the Charting Page
export const getChartTabContentHeader = (
  chartingDataFocus = "FLIGHT",
  faultCode = "",
  loadedTemplate
) => {
  let chartHeader = "";

  if (loadedTemplate) {
    // If a template has been loaded it will take the template name
    chartHeader = loadedTemplate.templateName;
  } else if (
    // If the focus of the charting is on a FAULT and is an inflight session it will take on the fault name
    chartingDataFocus === "FAULT" ||
    chartingDataFocus === "IN-FLIGHT"
  ) {
    chartHeader = faultCode + " FAULT Parameter Data";
  } else {
    chartHeader = "Flight Parameter Data";
  }

  return chartHeader;
};
