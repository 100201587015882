import React, { useEffect, useState, useContext, useMemo } from "react";
import { MaterialReactTable } from 'material-react-table';
import { summaryColumns, diagnosticsColumns } from "../../constants";
import ReportContext from "../Context Components/ReportContext";
import PredictiveTooltip from "../../Global Components/PredictiveTooltip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { capitalizeFirstLetter } from "../../usefulFunctions";
import Download from "../Download";

import "./Summary.css";

const Summary = (props) => {
    const {
        summaryData,
        diagnosticsData,
        iseReport,
        flightReport,
        aircraftData,
        sessionKey,
        iseNumber
    } = props;
    const {
        loadingValue
    } = useContext(ReportContext);

    const [flaggedParameters, setFlaggedParameters] = useState([]);
    const [loading, setLoading] = loadingValue;

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        if (summaryData) {
            setFlaggedParameters(summaryData.anomalies);
            setLoading(false);
        }
    }, [summaryData]);

    // Custom theme for the table.
    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: 'dark',
                    background: {
                        default: '#000'
                    }
                },
                typography: {
                    allVariants: {
                        color: "#FFF",
                        fontFamily: "'DM Sans', sans-serif",
                    }
                },
                components: {
                    MuiPaper: {
                        styleOverrides: {
                            root: {
                                boxShadow: 'none',
                            },
                        },
                    },
                    MuiTableCell: {
                        styleOverrides: {
                            root: {
                                fontSize: 16
                            },
                        },
                    },
                    MuiInputBase: {
                        styleOverrides: {
                            root: {
                                fontSize: 16
                            },
                        },
                    },
                    MuiSvgIcon: {
                        styleOverrides: {
                            root: {
                                color: "#FFF",
                                fill: "#FFF"
                            }
                        }
                    },
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                color: "#FFF !important",
                                '&.Mui-focused': {
                                    color: "#FFF !important",
                                },
                            },
                        },
                    }
                },
            })
    );

    const parametersColumns = useMemo(() => summaryColumns);

    const DiagnosticsItem = ({ name, value, width, islru }) => {
        const isValueList = !islru && Array.isArray(value);
        let newValues = _.cloneDeep(value);

        if (islru) {
            let newString = "";
            for (let j = 0; j < newValues.length; j++) {
                newString = newString.concat(newValues[j])
                if (j < newValues.length - 1) {
                    newString = newString.concat(" | ")
                }
            }
            newValues = newString;
        } else if (isValueList) {
            for (let j = 0; j < newValues.length; j++) {
                let item = newValues[j];

                // Split at numbered list
                let stringSplit = item.split(/([0-9]+)/);
                let newString = "";
                for (let i = 0; i < stringSplit.length; i++) {
                    const string = stringSplit[i];
                    if (!isNaN(string - parseFloat(string)) && parseFloat(string) < 10) {
                        if ((i + 1) <= (stringSplit.length - 1) && stringSplit[i + 1][0] === '.') {
                            newString += '<br></br>'
                        }
                    }
                    newString += string;
                }

                // Split at link and make link clickable
                stringSplit = newString.split('https');
                if (stringSplit.length > 1) {
                    const link = 'https' + stringSplit[1];
                    newString = stringSplit[0]
                        + `<a href="${link}" target="_blank">`
                        + link
                        + `</a>`
                }

                newValues[j] = newString;
            }
        }

        return (
            <div
                className="diagnostics__item"
                style={{ width: `${width}%` }}
            >
                <div
                    className="infoBox__body__rowLeft"
                    style={{ marginRight: 30 }}
                >
                    {name}
                </div>
                <div className="infoBox__body__rowRight" style={{ marginTop: 5 }}>
                    {isValueList
                        ? <>
                            {newValues.length > 1
                                ? <ul className="diagnostics__list">
                                    {newValues.map((item) => {
                                        return <li key={item} className="diagnostics__list__item">
                                            <span dangerouslySetInnerHTML={{ __html: item }} />
                                        </li>
                                    })}
                                </ul>
                                : <div
                                    style={{
                                        marginTop: 15
                                    }}
                                    dangerouslySetInnerHTML={{ __html: newValues[0] }}
                                />
                            }
                        </>
                        : newValues
                    }
                </div>
            </div>
        )
    }

    const DiagnosticsOverview = () => {
        return (
            <div className="infoBox__container">
                <div className="infoBox">
                    <div className="infoBox__header">
                        <PredictiveTooltip
                            element="In-Service Event Summary"
                            text={
                                <React.Fragment>
                                    In-Service Event Summary
                                </React.Fragment>
                            }
                            wide={true}
                        />
                    </div>
                    <div className="infoBox__body"
                        style={{
                            padding: 20,
                            backgroundColor: "#202020",
                            display: "flex",
                            flexFlow: "column"
                        }}
                    >
                        <div className="summary__diagnostics__row">
                            <DiagnosticsItem
                                name="In-Service Event Name:"
                                value={summaryData?.ise_name}
                                width={48}
                                containsBottomBorder={false}
                            />
                            <DiagnosticsItem
                                name="Potential Fault(s):"
                                value={
                                    diagnosticsData.potential_faults.length > 1
                                        ? diagnosticsData.potential_faults
                                        : diagnosticsData.potential_faults[0]
                                }
                                width={48}
                            />
                        </div>
                        <div className="summary__diagnostics__row">
                            <DiagnosticsItem
                                name="ATA Chapter:"
                                value={diagnosticsData.ata}
                                width={48}
                            />
                            <DiagnosticsItem
                                name="Detected In:"
                                value={diagnosticsData.system_name}
                                width={48}
                            />
                        </div>
                        <div className="summary__diagnostics__row summary__diagnostics__row--inner">
                            <DiagnosticsItem
                                name="Event Description(s):"
                                value={diagnosticsData.event_descriptions}
                                width={48}
                            />
                            <DiagnosticsItem
                                name="Recommendation(s):"
                                value={diagnosticsData.recommendations}
                                width={48}
                            />
                        </div>
                        <div className="summary__diagnostics__row">
                            <DiagnosticsItem
                                name="Affected LRU(s):"
                                value={
                                    diagnosticsData.lrus_info.map(lru => {
                                        return lru.lru_name
                                    })
                                }
                                islru={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <>
            {summaryData && (
                <div className="summary__container" style={{ flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                        {summaryData
                            && iseReport
                            && flightReport
                            && aircraftData
                            && diagnosticsData
                            && <Download
                                summaryData={summaryData}
                                iseReport={iseReport}
                                flightReport={flightReport}
                                aircraftData={aircraftData}
                                diagnosticsDataISE={diagnosticsData}
                                sessionKey={sessionKey}
                                iseNumber={iseNumber}
                            />
                        }
                    </div>
                    {!_.isEmpty(diagnosticsData) && <DiagnosticsOverview />}
                    {flaggedParameters?.length > 0 &&
                        <div className="infoBox__container">
                            <div className="infoBox">
                                <div className="infoBox__header">
                                    <PredictiveTooltip
                                        element="Flagged Parameters"
                                        text={
                                            <React.Fragment>
                                                The parameters that the <b>AI</b> flagged as <b>anomalous</b>
                                            </React.Fragment>
                                        }
                                        wide={true}
                                    />
                                </div>
                                <div className="infoBox__body"
                                    style={{
                                        padding: 20,
                                        backgroundColor: "#202020"
                                    }}
                                >
                                    <ThemeProvider theme={tableTheme}>
                                        <MaterialReactTable
                                            className="summary__table"
                                            data={flaggedParameters}
                                            columns={parametersColumns}
                                            sx={{
                                                borderRadius: 0
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    color: "#fff",
                                                    backgroundColor: "#202020",
                                                    borderBottom: "2px solid #101010"
                                                }
                                            }}
                                            muiTableBodyRowProps={{
                                                hover: false
                                            }}
                                            muiTableBodyProps={{
                                                sx: {
                                                    borderBottom: "2px solid #101010"
                                                }
                                            }}
                                            muiTableHeadCellProps={{
                                                sx: {
                                                    color: "#fff",
                                                    backgroundColor: "#202020",
                                                    height: 50,
                                                    fontWeight: "bold",
                                                    borderBottom: "2px solid #101010",
                                                }
                                            }}
                                            muiBottomToolbarProps={{
                                                sx: {
                                                    color: "#fff",
                                                    backgroundColor: "#202020",
                                                    height: 70,
                                                    fontWeight: "bold",
                                                    borderTop: "2px solid #101010",
                                                    padding: "16px 0"
                                                }
                                            }}
                                            muiPaginationProps={{
                                                rowsPerPageOptions: ['5', '10'],
                                                sx: {
                                                    color: "#fff",
                                                }
                                            }}
                                            enableSorting={false}
                                            enableTopToolbar={false}
                                            enableColumnActions={false}
                                            enableColumnFilters={false}
                                            initialState={{
                                                pagination: {
                                                    pageSize: 5,
                                                    pageIndex: 0
                                                }
                                            }}
                                        />
                                    </ThemeProvider>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )}
        </>
    );
};

export default Summary;
