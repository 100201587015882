import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for the RPD Request APIs
*/

const RPD_REQUEST = Api.RPD_REQUEST;
const RPD_EVENT_REQUEST = Api.RPD_EVENT_REQUEST;
const RPD_REQUEST_LIST = Api.RPD_REQUEST_LIST;
const RPD_RESPONSE_SIZE = Api.RPD_RESPONSE_SIZE;

// API client to GET the list of all RPD requests made for an aircraft
async function getRpdRequestList(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      tailNumber,
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
    },
  };

  try {
    result = await AxiosService(
      RPD_REQUEST_LIST.url,
      RPD_REQUEST_LIST.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// API client to CREATE a new Custom (adhoc) RPD request made for an aircraft
async function postRpdRequest(
  approvedStatus,
  tailNumber,
  requestType,
  faultCode,
  timestamp,
  duration,
  parameters,
  aircraftFamily,
  aircraftModel,
  rpdRequestName = "Default RPD Request Name",
  axiosCancelSource
) {
  requestType = requestType.toLowerCase();

  let result;
  const data = {
    aircraftFamily: aircraftFamily,
    aircraftModel: aircraftModel,
    approvedStatus: approvedStatus,
    tailNumber: tailNumber,
    requestType: requestType,
    faultCode: faultCode,
    timestamp: timestamp,
    duration: duration,
    qtyParameters: parameters.length,
    parameters: parameters,
    rpdRequestName: rpdRequestName
  };

  try {
    result = await AxiosService(
      RPD_REQUEST.url,
      RPD_REQUEST.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// API client to CREATE a new Event (FDE) RPD request made for an aircraft
async function postRpdEventRequest(
  approvedStatus,
  tailNumber,
  faultCode,
  timestamp,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  let result;
  const data = {
    aircraftFamily: aircraftFamily,
    aircraftModel: aircraftModel,
    approvedStatus: approvedStatus,
    tailNumber: tailNumber,
    faultCode: faultCode,
    timestamp: timestamp,
  };

  try {
    result = await AxiosService(
      RPD_EVENT_REQUEST.url,
      RPD_EVENT_REQUEST.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// API client to GET the estimated response size for a custom (adhoc) RPD request
async function getRpdResponseSize(
  aircraftFamily,
  aircraftModel,
  requestDurationSeconds,
  parameters,
  eventCode,
  axiosCancelSource
) {
  let result = null;
  let data;

  if (eventCode) {
    data = {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      //ahmuVersion: null,
      eventCode: eventCode,
    };
  } else {
    data = {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      requestDurationSeconds: requestDurationSeconds,
      //ahmuVersion: null,
      parameters: parameters,
    };
  }

  try {
    result = await AxiosService(
      RPD_RESPONSE_SIZE.url,
      RPD_RESPONSE_SIZE.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    return null;
  }

  return result;
}

export {
  getRpdRequestList,
  postRpdRequest,
  postRpdEventRequest,
  getRpdResponseSize,
};
