import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import FeatherIcon from 'feather-icons-react'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import LoadingSpinner from '../Global Components/LoadingSpinner'
import Header from '../../Global Components/Header'
import NotificationPopup from '../Global Components/NotificationPopup'
import DisclaimerPopup from '../Global Components/NotificationPopup'
import { PREDICTIVE_MAINTENANCE_URL } from '../../Constants/Constants'
import {
    geoCoordsParams,
    mapSidebarInfo,
    predictiveViewTabs,
    constantFilterOptions,
    statuses
} from '../constants.js'
import { sortArray, getAircraftData, getFlightPhase } from '../usefulFunctions'
import Reports from './Reports Components/Reports'
import Archives from './Archive Components/Archives'
import ToastNotification from '../Global Components/ToastNotification'

import './PredictiveView.css'
import TabItem from '../Global Components/TabItem'
import { getPmxFleet, getFleet } from '../../clients/FleetAlertsClient'

const PredictiveView = props => {
    const newUrl = PREDICTIVE_MAINTENANCE_URL()
    const [allAircrafts, setAllAircrafts] = useState(null)
    const [flightHistories, setFlightHistories] = useState({})
    const [loading, setLoading] = useState(false)
    const [userType, setUserType] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [prognosticsViewed, setPrognosticsViewed] = useState(
        localStorage.getItem('prognosticsViewed') &&
            typeof localStorage.getItem('prognosticsViewed') !== 'undefined'
            ? JSON.parse(localStorage.getItem('prognosticsViewed'))
            : []
    )
    const [tab, setTab] = useState(predictiveViewTabs[0])
    const [fleetListLength, setFleetListLength] = useState(0)
    const [reportSearch, setReportSearch] = useState('')
    const [fleetSearch, setFleetSearch] = useState('')
    const [reportsInfo, setReportsInfo] = useState({
        fleet:
            localStorage.getItem('currentReportsFleet') &&
            typeof localStorage.getItem('currentReportsFleet') !== 'undefined'
                ? JSON.parse(localStorage.getItem('currentReportsFleet'))
                : null,
        columns: null,
        data: null,
        fleetList: [],
        filteredFleetList: null, // filtered fleet list
        filteredReports: null, // filterd reports list
        searchFilteredReports: []
    })
    const [archivesInfo, setArchivesInfo] = useState({
        fleet: '',
        filteredReports: [],
        filteredFleetList: [],
        data: null,
        fleetList: [],
        count: 0 //keep track of total items being shown even when searching
    })
    const [disclaimerrModal, setDisclaimerModal] = useState({
        colorCode: '',
        description: '',
        alternativeExistLink: '',
        notifModalState: false,
        technical_detail: '',
        currentTab: '',
        title: '',
        reloadPage: true,
        isError: true,
        containsClose: false
    })
    const [notifModal, setNotifModal] = useState({
        colorCode: '',
        description: '',
        alternativeExistLink: '',
        notifModalState: false,
        technical_detail: '',
        currentTab: '',
        title: '',
        reloadPage: true,
        isError: true,
        containsClose: false
    })
    const [toastNotif, setToastNotif] = useState({
        message: '',
        item: null
    })
    const [currSort, setCurrSort] = useState({
        id: 'first_timestamp',
        sort: 'newest'
    })
    const [reportSearchParams, setReportsearchParams] = useState({
        oldText: '',
        text: '',
        load: false
    })
    const [oldestAnomalyTs, setOldestAnomalyTs] = useState(null)
    const [filterOptions, setFilterOptions] = useState(null)
    const [collapseHeader, setCollapseHeader] = useState(false)

    const openNotificationModal = ({
        type = 'notif',
        colorCode,
        description,
        alternativeExistLink,
        currentTab,
        title,
        reloadPage,
        isError = true,
        containsClose = false
    }) => {
        if (type === 'notif') {
            if (currentTab === tab.title) {
                setNotifModal({
                    colorCode: colorCode,
                    description: description,
                    alternativeExistLink: alternativeExistLink,
                    notifModalState: true,
                    title: title,
                    reloadPage: reloadPage,
                    isError: isError,
                    containsClose: containsClose
                })
            }
        } else if (type === 'disclaimer') {
            if (currentTab === tab.title) {
                setDisclaimerModal({
                    colorCode: colorCode,
                    description: description,
                    alternativeExistLink: alternativeExistLink,
                    notifModalState: true,
                    title: title,
                    reloadPage: reloadPage,
                    isError: isError,
                    containsClose: containsClose
                })
            }
        }
    }

    const closeNotificationModal = () => {
        setNotifModal({
            notifModalState: false
        })
    }

    const closeDisclaimerModal = () => {
        setDisclaimerModal({
            notifModalState: false
        })
    }

    const getIseReports = async () => {
        let filterOptions = [
            {
                title: 'ISE Name',
                id: 'ise_name',
                options: [],
                isCollapsed: true
            },
            {
                title: 'ATA Chapter',
                filterName: 'ATA - ',
                id: 'ata_chapter',
                options: [],
                isCollapsed: true
            },
            {
                title: 'Flight Phase',
                id: 'flight_phase',
                options: [],
                isCollapsed: true
            }
        ]

        let fleets = []
        let reports = []
        let archivedFleets = []

        let response = await axios.get(`${newUrl}/kanban/fleet`).catch(error => {
            console.error(`Error : ${error}`)
        })

        if (response?.data) {
            for (let i = 0; i < response.data.length; i++) {
                const fleet = response.data[i]
                const serialNumber = fleet.serial_number

                let fleetIndex = _.findIndex(fleets, function (f) {
                    return f.shortSerialNumber === fleet.serial_number
                })

                if (fleetIndex === -1) {
                    let aircraft = await getAircraftData(serialNumber)
                    if (aircraft?.aircraftFamily) {
                        fleets.push({
                            ...aircraft,
                            shortSerialNumber: fleet.serial_number,
                            oldestTs: fleet.oldest_ts,
                            newestTs: fleet.newest_ts,
                            count: fleet.count,
                            archived_count: fleet.archived_count,
                            containsPrognostics: fleet.contains_prognostics
                        })
                    }
                }
            }

            if (fleets.length > 0) {
                // Find the oldest anomaly timestamp
                let sortOldest = _.cloneDeep(response.data).sort(function (a, b) {
                    return a.oldest_ts?.localeCompare(b.oldest_ts)
                })
                let oldestTs = sortOldest[0].oldest_ts
                    ? sortOldest[0].oldest_ts?.substring(0, 10) + 'T00:00:00Z'
                    : null
                setOldestAnomalyTs(oldestTs)

                setFleetListLength(fleets.length)

                const aircrarftReportsPromises = []

                //iterating through every fleet
                for (let i = 0; i < fleets.length; i++) {
                    const aircraft = fleets[i]
                    let serialNumber = aircraft.shortSerialNumber

                    if (aircraft.count > 0 || aircraft.archived_count > 0) {
                        //getting all the reports for this fleet (in form of promises)
                        const reportsPromise = axios
                            .get(`${newUrl}/kanban/ise_reports?serial_number=${serialNumber}`)
                            .catch(() => {
                                console.error(`No ISE reports for aircraft ${serialNumber}`)
                            })

                        aircrarftReportsPromises.push(reportsPromise)
                    }

                    if (aircraft.archived_count > 0) {
                        archivedFleets.push(aircraft)
                    }
                }

                //iterate through reports of the fleet (in form of a promise)
                Promise.all(aircrarftReportsPromises).then(res => {
                    for (let a = 0; a < res.length; a++) {
                        if (typeof res[a] !== 'undefined') {
                            let aircraftReports = res[a].data

                            aircraftReports.forEach(report => {
                                filterOptions.forEach(filterOption => {
                                    let id = filterOption.id
                                    let options = filterOption.options

                                    if (
                                        _.findIndex(options, function (o) {
                                            return o.id === report[id]
                                        }) === -1
                                    ) {
                                        let title = report[id]

                                        // TODO: remove when we no longer need to filter out Flap Skew Fail ISE 9
                                        if (title !== 'Flap Skew Fail') {
                                            if (typeof filterOption.filterName !== 'undefined') {
                                                title = `${filterOption.filterName}${report[id]}`
                                            }
                                            if (filterOption.id === 'flight_phase') {
                                                title = getFlightPhase(report[id])
                                            }

                                            filterOption.options.push({
                                                title: title,
                                                id: report[id]
                                            })
                                        }
                                    }
                                })
                            })

                            //add the fleet's reports to the list of reports
                            reports = reports.concat(aircraftReports)
                        }
                    }

                    let archivedReports = _.remove(reports, function (o) {
                        return o.status === 'ARCHIVED'
                    })

                    filterOptions = filterOptions.concat(constantFilterOptions)

                    const removeDups = _.uniqBy(
                        [...fleets].concat([...allAircrafts]),
                        'shortSerialNumber'
                    )
                    handleFilterOptionsChange(filterOptions)
                    updatePageInfo('fleetList', removeDups, 'Reports')
                    updatePageInfo('data', [...reports], 'Reports')

                    updatePageInfo('fleetList', [...archivedFleets], 'Archives')
                    updatePageInfo('data', [...archivedReports], 'Archives')
                })
            } else {
                updatePageInfo('fleetList', [], 'Reports')
                updatePageInfo('data', [], 'Reports')
                updatePageInfo('data', [], 'Archives')

                openNotificationModal({
                    description: (
                        <div>
                            You need at least one aircraft assigned to your account.<br></br>
                            <b>Contact us</b> for more information.
                        </div>
                    ),
                    alternativeExistLink: `/fleetview`,
                    currentTab: 'Reports',
                    colorCode: '#F2F2F2',
                    title: 'No Aircraft is assigned to your account!',
                    reloadPage: false
                })
            }
        } else {
            updatePageInfo('data', [], 'Archives')
            updatePageInfo('data', [], 'Reports')
        }
    }

    const getFleetlist = async () => {
        const errorObj = {
            description: (
                <div>
                    You need at least one aircraft assigned to your account.<br></br>
                    <b>Contact us</b> for more information.
                </div>
            ),
            alternativeExistLink: `/fleetview`,
            currentTab: 'Reports',
            colorCode: '#F2F2F2',
            title: 'No Aircraft is assigned to your account!',
            reloadPage: false
        }

        // need to keep using myMx tails API for predictive instances
        // because we're stilling using b8000535 to login into predictive instances
        if (
            process.env.REACT_APP_ENVIRONMENT === 'PREDICTIVE-DEV' ||
            process.env.REACT_APP_ENVIRONMENT === 'PREDICTIVE-QA' ||
            process.env.REACT_APP_ENVIRONMENT === 'PREDICTIVE' ||
            typeof process.env.REACT_APP_ENVIRONMENT === 'undefined'
        ) {
            await getFleet()
                .then(res => {
                    if (
                        res.data &&
                        Array.isArray(res.data) &&
                        res.data.length > 0 &&
                        // TODO: Delete this error check after myMx backend pushes api changes to PROD
                        typeof res.data[0] === 'object'
                    ) {
                        let fleetList = []
                        res.data.forEach(tail => {
                            if (tail.tails.aircraftModel === '7500') {
                                let shortSerialNumber = tail.tails.serialNumber.slice(-3)
                                if (parseInt(shortSerialNumber) < 10) {
                                    shortSerialNumber = shortSerialNumber.slice(2)
                                } else if (
                                    parseInt(shortSerialNumber) >= 10 &&
                                    parseInt(shortSerialNumber) < 100
                                ) {
                                    shortSerialNumber = shortSerialNumber.slice(1)
                                }

                                let newTail = {
                                    shortSerialNumber: shortSerialNumber,
                                    registrationNumber: tail.tails.tailNumber,
                                    tailNumber: tail.tails.tailNumber,
                                    serialNumber: tail.tails.serialNumber,
                                    aircraftFamily: tail.tails.aircraftFamily,
                                    aircraftModel: tail.tails.aircraftModel
                                }

                                fleetList.push(newTail)
                            }
                        })

                        const removeDups = _.uniqBy(fleetList, 'serialNumber')
                        localStorage.setItem('allAircrafts', JSON.stringify(Array.from(removeDups)))
                        setAllAircrafts(removeDups)
                    } else {
                        updatePageInfo('fleetList', [], 'Reports')
                        updatePageInfo('data', [], 'Reports')
                        updatePageInfo('data', [], 'Archives')

                        openNotificationModal(errorObj)
                    }
                })
                .catch(error => {
                    console.error(`Error : ${error}`)
                    openNotificationModal(errorObj)
                })
        } else {
            await getPmxFleet()
                .then(res => {
                    if (
                        res.data &&
                        Array.isArray(res.data) &&
                        res.data.length > 0 &&
                        // TODO: Delete this error check after myMx backend pushes api changes to PROD
                        typeof res.data[0] === 'object'
                    ) {
                        let fleetList = []
                        res.data.forEach(tail => {
                            if (tail.aircraftModel === '7500') {
                                let shortSerialNumber = tail.serialNumber.slice(-3)
                                if (parseInt(shortSerialNumber) < 10) {
                                    shortSerialNumber = shortSerialNumber.slice(2)
                                } else if (
                                    parseInt(shortSerialNumber) >= 10 &&
                                    parseInt(shortSerialNumber) < 100
                                ) {
                                    shortSerialNumber = shortSerialNumber.slice(1)
                                }

                                let newTail = {
                                    shortSerialNumber: shortSerialNumber,
                                    registrationNumber: tail.registrationNumber,
                                    tailNumber: tail.registrationNumber,
                                    serialNumber: tail.serialNumber,
                                    aircraftFamily: tail.aircraftFamily,
                                    aircraftModel: tail.aircraftModel
                                }

                                fleetList.push(newTail)
                            }
                        })

                        const removeDups = _.uniqBy(fleetList, 'serialNumber')
                        localStorage.setItem('allAircrafts', JSON.stringify(Array.from(removeDups)))
                        setAllAircrafts(removeDups)
                    } else {
                        updatePageInfo('fleetList', [], 'Reports')
                        updatePageInfo('data', [], 'Reports')
                        updatePageInfo('data', [], 'Archives')

                        openNotificationModal(errorObj)
                    }
                })
                .catch(error => {
                    console.error(`Error : ${error}`)
                    openNotificationModal(errorObj)
                })
        }
    }

    const loadUserInfo = () => {
        if (localStorage.getItem('userInfo')) {
            let localStorageUserInfo = JSON.parse(localStorage.getItem('userInfo'))
            setUserInfo(localStorageUserInfo)
            setUserType(
                // localStorageUserInfo?.orgId === "10000186" ||
                localStorageUserInfo?.userId.charAt(0).toLowerCase() === 'b' ||
                    localStorageUserInfo?.userId.charAt(0).toLowerCase() === 'k'
                    ? 'internal'
                    : 'external'
            )
        }
    }

    const handleFlightHistoryChange = (fleet, list) => {
        if (fleet && list.length > 0) {
            flightHistories[fleet] = list
            setFlightHistories(flightHistories)
        }
    }

    const handleSortOptionChange = value => {
        setCurrSort(value)
    }

    const handleFilterOptionsChange = value => {
        if (value) setFilterOptions([...value])
    }

    const updatePageInfo = (name, value, currTab) => {
        let currentTab = tab.title
        if (currTab) currentTab = currTab

        if (name === 'data' || (currentTab === 'Reports' && name === 'filteredReports')) {
            value = sortArray(value, currSort.id, currSort.sort)
        }

        if (currentTab === 'Reports') {
            if (value.isArray) {
                reportsInfo[name] = [...value]
            } else {
                reportsInfo[name] = value
            }
            setReportsInfo({ ...reportsInfo })
        } else if (currentTab === 'Archives') {
            if (value.isArray) {
                archivesInfo[name] = [...value]
            } else {
                archivesInfo[name] = value
            }

            let total = 0
            for (const property in archivesInfo.filteredReports) {
                total += archivesInfo.filteredReports[property].length
            }

            setArchivesInfo({ ...archivesInfo, count: total })
        }
    }

    const handleTabPressed = data => {
        setTab(data)
        setReportSearch(null)
        setFleetSearch(null)
    }

    const updateIseStatus = async (sessionKey, iseNumber, oldStatus, newStatus) => {
        const response = await axios.put(`${newUrl}/kanban/change_status`, {
            session_key: sessionKey,
            ise_num: iseNumber,
            old_status: oldStatus.toUpperCase(),
            new_status: newStatus.toUpperCase(),
            user_data: {
                user_id:
                    userInfo?.userId && typeof userInfo?.userId !== 'undefined'
                        ? userInfo.userId
                        : 'N/A',
                name:
                    userInfo?.name && typeof userInfo?.name !== 'undefined' ? userInfo.name : 'N/A'
            }
        })

        return response.data
    }

    const myOnDragEnd = result => {
        // If no destination
        if (!result.destination) return

        const { source, destination, draggableId } = result

        const sourceId = parseInt(source.droppableId)
        const destinationId = parseInt(destination.droppableId)

        // If destination is same as source
        if (sourceId === destinationId) return

        let oldStatus = statuses[sourceId]
        let newStatus = statuses[destinationId]

        let columns = reportsInfo.columns
        let list = archivesInfo.filteredReports
        let fleet = null
        let data = null
        let fleetList = null

        if (tab.title === 'Reports') {
            fleet = reportsInfo.fleet
            data = reportsInfo.data ? reportsInfo.data : []
            fleetList = reportsInfo.fleetList ? reportsInfo.fleetList : []
        } else if (tab.title === 'Archives') {
            fleet = archivesInfo.fleet
            data = archivesInfo.data ? archivesInfo.data : []
        }

        let sessionKey = ''
        let iseNumber = ''

        // When on Reports Page
        if (tab.title === 'Reports' && destinationId !== 3) {
            const currItem = columns[source.droppableId].items[source.index]
            sessionKey = currItem.session_key
            iseNumber = currItem.ise_num

            // When dragging to ARCHIVED
            if (destinationId === 4) {
                // When dragging from CLOSED to ARCHIVES
                if (sourceId === 2) {
                    // Commenting out ability to drag to Archived because Archives page not ready yet
                    // // Update reports data
                    // let currItemInData = _.find(
                    //   data,
                    //   function (o) {
                    //     return o.session_key === currItem.session_key && o.ise_num === currItem.ise_num
                    //   });
                    // // Update status
                    // currItemInData.status = newStatus;
                    // // Update fleet count
                    // let currFleet = _.find(
                    //   fleetList,
                    //   function (o) {
                    //     return o.shortSerialNumber === fleet.shortSerialNumber
                    //   }
                    // )
                    // currFleet.count = currFleet.count - 1;
                    // // Adding item to Archives data
                    // archivesInfo.data.push(currItemInData);
                    // // Removing item from Reports data
                    // let newReportsData = _.filter(data, function (o) {
                    //   return o.session_key !== currItemInData.session_key && o.ise_num !== currItemInData.ise_num;
                    // });
                    // // Update columns on Reports
                    // const sourceColumn = columns[source.droppableId];
                    // const sourceItems = [...sourceColumn.items];
                    // sourceItems.splice(source.index, 1);
                    // reportsInfo.columns = {
                    //   ...columns,
                    //   [source.droppableId]: {
                    //     ...sourceColumn,
                    //     items: sourceItems,
                    //   },
                    // };
                    // updatePageInfo("fleetList", [...fleetList], "Reports");
                    // updatePageInfo("columns", reportsInfo.columns, "Reports");
                    // updatePageInfo("data", [...newReportsData], "Reports");
                    // updatePageInfo("data", archivesInfo.data, "Archives");
                    // //increment count of archives by 1
                    // setArchivesInfo({ ...archivesInfo, count: archivesInfo.count + 1 });
                    // setToastNotif({
                    //   message: `The Report ${currItem.ise_name} from session ${currItem.flight_session} was archived successfully`,
                    //   item: currItem
                    // });
                } else {
                    setToastNotif({
                        message: `Oops!  The Report ${currItem.ise_name} from session ${currItem.flight_session} has not been closed yet. Please close the report before archiving.`,
                        item: currItem
                    })
                }
            }
            // When dragging from NEW, OPEN, CLOSED to NEW, OPEN, CLOSED
            else {
                if (destinationId === sourceId + 1 || destinationId === sourceId - 1) {
                    // Update reports data
                    let currItemInData = _.find(data, function (o) {
                        return (
                            o.session_key === currItem.session_key && o.ise_num === currItem.ise_num
                        )
                    })
                    // Update status
                    currItemInData.status = newStatus

                    // Update fleet count
                    let currFleet = _.find(fleetList, function (o) {
                        return o.shortSerialNumber === fleet.shortSerialNumber
                    })
                    currFleet.count =
                        destinationId === sourceId + 1 ? currFleet.count - 1 : currFleet.count + 1

                    // Update columns on Reports
                    const sourceColumn = columns[source.droppableId]
                    const destColumn = columns[destination.droppableId]
                    const sourceItems = [...sourceColumn.items]
                    const destItems = [...destColumn.items]
                    const [removed] = sourceItems.splice(source.index, 1)
                    destItems.splice(destination.index, 0, removed)

                    reportsInfo.columns = {
                        ...columns,
                        [source.droppableId]: {
                            ...sourceColumn,
                            items: sourceItems
                        },
                        [destination.droppableId]: {
                            ...destColumn,
                            items: destItems
                        }
                    }

                    updatePageInfo('data', [...data])
                    updatePageInfo('columns', reportsInfo.columns)
                } else {
                    let message = `Oops! The Report ${currItem.ise_name} from session ${currItem.flight_session} has already been closed.`
                    if (destinationId === 2) {
                        message = `Oops! Please open the Report ${currItem.ise_name} from session ${currItem.flight_session} before closing.`
                    }
                    setToastNotif({
                        message: message,
                        item: currItem
                    })
                }
            }
        }

        // When dragging from ARCHIVES to REPORTS
        else if (tab.title === 'Archives' && sourceId === 5 && destinationId === 3) {
            sessionKey = draggableId.split('-')[0] + '-' + draggableId.split('-')[1]
            iseNumber = draggableId.split('-')[2]

            oldStatus = statuses[4] // Archived
            newStatus = statuses[1] // Open

            // Updating item status
            let months = Object.keys(list)
            let currItem = null
            let currMonth = null
            for (const month of months) {
                let findItem = _.find(list[month], function (o) {
                    return o.session_key === sessionKey && o.ise_num == iseNumber
                })

                if (findItem) {
                    currItem = findItem
                    currMonth = month
                }
            }
            currItem.status = newStatus

            // Removing item from Archives data
            let newArchivesData = _.filter(data, function (o) {
                return o.session_key !== sessionKey && o.ise_num !== iseNumber
            })

            // Update Archives current list
            let newMonthList = _.filter(list[currMonth], function (o) {
                return o.session_key !== sessionKey && o.ise_num !== iseNumber
            })
            let newList = { ...list }
            if (newMonthList && newMonthList.length > 0) {
                newList[currMonth] = newMonthList
            } else {
                delete newList[currMonth]
            }

            // Adding item to Reports data
            reportsInfo.data.push(currItem)

            updatePageInfo('filteredReports', newList)
            updatePageInfo('data', [...newArchivesData])
            // The aircraft related to the retrieved anomaly is selected
            updatePageInfo('fleet', archivesInfo.fleet, 'Reports')
            updatePageInfo('data', reportsInfo.data, 'Reports')

            setToastNotif({
                message: `The Report ${currItem.ise_name} from session ${currItem.flight_session} was retrieved successfully`,
                item: currItem
            })
        }

        if (sessionKey && iseNumber && newStatus && oldStatus && newStatus !== 'ARCHIVED')
            updateIseStatus(sessionKey, iseNumber, oldStatus, newStatus)
    }

    useEffect(() => {
        setLoading(true)

        // In case, app starts from Kanban page
        // Need to set the current mode of sideNav to PMx
        props.setCurrentMode('PMx')

        // Wait two seconds before displaying disclaimer
        // setTimeout(() => {
        //   openNotificationModal({
        //     type: "disclaimer",
        //     description: (
        //       <div>
        //         This app is currently under development, and the anomalies detected by its AI models may not always be reliable due to the evolving nature of AI Models. Please refrain from making any critical decisions or taking actions solely based on the anomalies listed within the app. The AI models used are still in their early stages and might not provide accurate or consistent results.
        //       </div>
        //     ),
        //     currentTab: "Reports",
        //     colorCode: "#003E51",
        //     title: "Welcome to Predictive Maintenance",
        //     isError: false,
        //     reloadPage: false,
        //     containsClose: true,
        //   });
        // }, 2000);

        getFleetlist()

        loadUserInfo()

        // reset data for map and sidebar
        geoCoordsParams.forEach(param => {
            delete param.originValue
            delete param.anomalyValue
            delete param.dms
        })
        mapSidebarInfo.forEach(param => {
            if (param.parameters) {
                param.parameters.forEach(p => delete p.value)
            } else delete param.value
        })
    }, [])

    useEffect(() => {
        if (userType && allAircrafts) {
            getIseReports()
        }
    }, [userType, allAircrafts])

    useEffect(() => {
        if (reportsInfo.data) {
            updatePageInfo('data', [...reportsInfo.data], 'Reports')
            updatePageInfo('filteredReports', [...reportsInfo.filteredReports], 'Reports')
        }
    }, [currSort])

    useEffect(() => {
        if (
            // archivesInfo.data
            reportsInfo.data &&
            reportsInfo.fleetList
        )
            setLoading(false)
    }, [archivesInfo, reportsInfo])

    useEffect(() => {
        // Track scoll position
        const onScroll = () => {
            if (!collapseHeader && window.scrollY > 30) {
                setCollapseHeader(true)
            }
            if (collapseHeader && window.scrollY < 2) {
                window.scrollTo(0, 0)
                setCollapseHeader(false)
            }
        }

        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [collapseHeader])

    return (
        <div className='predictiveView'>
            {notifModal && notifModal.notifModalState && (
                <NotificationPopup
                    userName={userInfo && userInfo.name}
                    open={notifModal.notifModalState}
                    closeModal={closeNotificationModal}
                    description={notifModal.description}
                    alternativeExistLink={notifModal.alternativeExistLink}
                    color={notifModal.colorCode}
                    technical_detail={notifModal.technical_detail}
                    title={notifModal.title}
                    reloadPage={notifModal.reloadPage}
                    isError={notifModal.isError}
                    containsClose={notifModal.containsClose}
                    setCurrentMode={props.setCurrentMode}
                />
            )}
            {disclaimerrModal && disclaimerrModal.notifModalState && (
                <DisclaimerPopup
                    userName={userInfo && userInfo.name}
                    open={disclaimerrModal.notifModalState}
                    closeModal={closeDisclaimerModal}
                    description={disclaimerrModal.description}
                    alternativeExistLink={disclaimerrModal.alternativeExistLink}
                    color={disclaimerrModal.colorCode}
                    technical_detail={disclaimerrModal.technical_detail}
                    title={disclaimerrModal.title}
                    reloadPage={disclaimerrModal.reloadPage}
                    isError={disclaimerrModal.isError}
                    containsClose={disclaimerrModal.containsClose}
                    setCurrentMode={props.setCurrentMode}
                />
            )}
            <div className='predictiveView__content'>
                {loading && <LoadingSpinner />}
                {reportSearchParams.load && <LoadingSpinner />}
                <DragDropContext onDragEnd={result => myOnDragEnd(result)}>
                    <div
                        className={`predictiveView__header 
              ${collapseHeader ? ' predictiveView__header--collapsed' : ''}`}
                    >
                        <div className='predictiveView__globalHeaderContainer'>
                            <div
                                className={`predictiveView__columnStart 
                  ${collapseHeader ? ' predictiveView__columnStart--collapsed' : ''}`}
                            >
                                <FeatherIcon
                                    icon={'cpu'}
                                    height={collapseHeader ? 24 : 30}
                                    width={collapseHeader ? 24 : 30}
                                    color={'#FFFFFF'}
                                    style={{ marginRight: '18px' }}
                                />
                                <span>Predictive Maintenance</span>
                            </div>
                            <div>
                                <Header logoText='BOMBARDIER' />
                            </div>
                        </div>
                        {/* TABS */}
                        <div
                            className={`predictiveView__header_lower 
                ${collapseHeader ? ' predictiveView__header_lower--collapsed' : ''}`}
                        >
                            <div className='predictiveView__tabsContainer'>
                                {predictiveViewTabs.map((item, index) => {
                                    return (
                                        <div
                                            key={item.title}
                                            style={{
                                                width: 300,
                                                marginRight: 20
                                            }}
                                        >
                                            <Droppable droppableId={item.status} key={item.status}>
                                                {(provided, snapshot) => {
                                                    return (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <span
                                                                style={{
                                                                    display: 'none'
                                                                }}
                                                            >
                                                                {provided.placeholder}
                                                            </span>
                                                            <TabItem
                                                                data={item}
                                                                text={item.title}
                                                                icon={item.icon}
                                                                color={'#D2D2D2'}
                                                                currentTab={tab}
                                                                deactivated={item.deactivated}
                                                                title={item.title}
                                                                onPress={handleTabPressed}
                                                                isDraggingOver={
                                                                    snapshot.isDraggingOver
                                                                }
                                                                count={null}
                                                                userType={userType}
                                                                tooltipText={
                                                                    <React.Fragment>
                                                                        <>
                                                                            {' '}
                                                                            {item.title ===
                                                                            'Archives' ? (
                                                                                <span>
                                                                                    <b>Archived</b>{' '}
                                                                                    Reports
                                                                                </span>
                                                                            ) : (
                                                                                <span>
                                                                                    <b>Active</b>{' '}
                                                                                    Reports
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            </Droppable>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='predictiveView__contentBody'>
                        {tab.title === 'Reports' && reportsInfo && reportsInfo.data && (
                            <Reports
                                data={reportsInfo.data}
                                fleet={reportsInfo.fleet}
                                columns={reportsInfo.columns}
                                fleetList={reportsInfo.fleetList}
                                filteredFleetList={reportsInfo.filteredFleetList}
                                filteredReports={reportsInfo.filteredReports}
                                searchFilteredReports={reportsInfo.searchFilteredReports}
                                updatePageInfo={updatePageInfo}
                                fleetSearch={fleetSearch}
                                handleFleetSearchChange={value => setFleetSearch(value)}
                                currSort={currSort}
                                handleSortOptionChange={handleSortOptionChange}
                                fleetListLength={fleetListLength}
                                searchParams={reportSearchParams}
                                handleSearchParams={obj => setReportsearchParams({ ...obj })}
                                oldestAnomalyTs={oldestAnomalyTs}
                                userType={userType}
                                filterOptions={filterOptions}
                                handleFilterOptionsChange={handleFilterOptionsChange}
                                handleFlightHistoryChange={handleFlightHistoryChange}
                                flightHistories={flightHistories}
                                prognosticsViewed={prognosticsViewed}
                            />
                        )}
                        {tab.title === 'Archives' && archivesInfo && archivesInfo.data && (
                            <Archives
                                data={archivesInfo.data}
                                fleet={archivesInfo.fleet}
                                fleetList={archivesInfo.fleetList}
                                filteredReports={archivesInfo.filteredReports}
                                filteredFleetList={archivesInfo.filteredFleetList}
                                updatePageInfo={updatePageInfo}
                                count={archivesInfo.count}
                                reportSearch={reportSearch}
                                handleReportSearchChange={value => setReportSearch(value)}
                                fleetSearch={fleetSearch}
                                handleFleetSearchChange={value => setFleetSearch(value)}
                                currSort={currSort}
                                fleetListLength={fleetListLength}
                            />
                        )}
                    </div>
                </DragDropContext>
            </div>

            <ToastNotification message={toastNotif.message} item={toastNotif.item} />
        </div>
    )
}

export default PredictiveView
