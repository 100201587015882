import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for the aircraft takeoff report data.
  This API returns
    - session (engine) start and end dates
    - all flights in a session
    - all general session data that will be shown in the application
*/

const TAKEOFF_REPORT = Api.TAKEOFF_REPORT;

// Takeoff report API
export async function getTakeOffReport(
  tailNumber,
  departureTimestamp,
  arrivalTimestamp,
  sitaInfoSource,
  aircraftFamily,
  aircraftModel,
  sessionNumber,
  axiosCancelSource
) {
  let result;
  let data;

  if (sessionNumber) {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        sessionNumber: sessionNumber,
        infoSource: sitaInfoSource ? "SITA" : "null",
      },
    };
  } else if (departureTimestamp && arrivalTimestamp) {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        startDate: departureTimestamp,
        endDate: arrivalTimestamp,
        infoSource: sitaInfoSource ? "SITA" : "null",
      },
    };
  } else {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
      },
    };
  }

  try {
    result = await AxiosService(
      TAKEOFF_REPORT.url,
      TAKEOFF_REPORT.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}
