import React, { Component } from "react";
import FilterBox from "../Global Components/FilterBox";
import _ from "lodash";
import OmsRow from "./OmsRow";
import InformativeBanner from "../Global Components/InformativeBanner";
import "./OmsList.css";

export default class OmsList extends Component {
  constructor(props) {
    super(props);
    this.createRows = this.createRows.bind(this);
    this.handleCodeFilterChange = this.handleCodeFilterChange.bind(this);
    this.handleMessageFilterChange = this.handleMessageFilterChange.bind(this);
  }

  render() {
    return this.handleTableRender(
      this.props.omsList,
      this.props.selectedOmsFault,
      this.props.selectedFdeFault
    );
  }

  handleTableRender(omsList, selectedOmsFault, selectedFdeFault) {
    const filteredOmsList = [];
    let faultShowCount = 0;
    omsList.forEach((fault) => {
      fault.isFilteredRow = this.isFilteredRow(
        fault.faultCode,
        fault.faultMessage
      );
      faultShowCount += fault.isFilteredRow ? 1 : 0;
      filteredOmsList.push(fault);
    });

    const scroll = faultShowCount > 5;

    return (
      <div className="flex-column-simple oms-table">
        <div className="flex-row-simple oms-header">
          {this.props.aircraftUIConfig.hasMDCFaultsInTable ? (
            <div className="oms-header-code-section oms-header-font">
              <FilterBox
                id={"oms-code-search"}
                placeholderText={"Code"}
                handleFilterChange={this.handleCodeFilterChange}
                customHeight={"42px"}
              />
            </div>
          ) : (
            <div></div>
          )}
          <div className="oms-header-message-section oms-header-font">
            <FilterBox
              id={"oms-message-search"}
              placeholderText={"Fault Message"}
              handleFilterChange={this.handleMessageFilterChange}
              customHeight={"42px"}
            />
          </div>
          <div className="oms-header-fde-section oms-header-font">FDE</div>
          <div
            className={
              "oms-header-font oms-header-timestamp-section" +
              (scroll ? "-with-bar" : "")
            }
          >
            Fault Timestamp
          </div>
        </div>
        {this.createRows(
          filteredOmsList,
          selectedOmsFault,
          selectedFdeFault,
          scroll
        )}
      </div>
    );
  }

  // Create Row method
  createRows(filteredOmsList, selectedOmsFault, selectedFdeFault, scroll) {
    let rows = <div />;
    let noDataBanner;

    if (this.props.showMdcInformativeBanner) {
      noDataBanner = (
        <InformativeBanner bannerMessage="MDC data will be available shortly." />
      );
    } else {
      noDataBanner = (
        <div className="oms-row oms-font oms-wip"> NO DATA AVAILABLE </div>
      );
    }

    if (!filteredOmsList) {
      return noDataBanner;
    }
    if (filteredOmsList.length <= 0) {
      return noDataBanner;
    }

    rows = (
      <div className={scroll ? "oms-container-overflow scrollbar" : null}>
        {filteredOmsList.map((item) => (
          <React.Fragment key={item.id}>
            <OmsRow
              aircraftUIConfig={this.props.aircraftUIConfig}
              id={item.id}
              faultCode={item.faultCode}
              faultMessage={item.faultMessage}
              faultTimestamp={item.faultTimestamp}
              fdeFaultCode={item.fdeFaultCode}
              faultSeverity={item.faultSeverity}
              smartfixUrl={item.smartfixUrl}
              rowIsSubtle={this.isSubtleRow(
                selectedOmsFault,
                selectedFdeFault,
                item.faultCode,
                item.faultTimestamp
              )}
              rowIsFiltered={item.isFilteredRow}
              omsRowClickEvent={this.props.omsRowClickEvent}
              fdeRelatedCount={item.fdeRelatedCount}
            />
          </React.Fragment>
        ))}
      </div>
    );
    return rows;
  }

  isSubtleRow(
    selectedOmsFault,
    selectedFdeFault,
    omsMessageFaultCode,
    omsMessageFaultTimestamp
  ) {
    let isSubtle = true;

    if (selectedOmsFault) {
      if (
        omsMessageFaultCode === selectedOmsFault.faultCode &&
        omsMessageFaultTimestamp === selectedOmsFault.faultTimestamp
      ) {
        isSubtle = false;
      }
    } else if (selectedFdeFault) {
      if (selectedFdeFault.omsRelatedCount > 0) {
        if (
          _.find(selectedFdeFault.omsMessages, {
            faultCode: omsMessageFaultCode,
            faultTimestamp: omsMessageFaultTimestamp,
          }) !== undefined
        ) {
          isSubtle = false;
        }
      }
    } else {
      isSubtle = false;
    }
    return isSubtle;
  }

  isFilteredRow(faultCode, faultMessage) {
    if (
      faultCode
        .toUpperCase()
        .includes(this.props.omsFilterText.faultCode.toUpperCase()) &&
      faultMessage
        .toUpperCase()
        .includes(this.props.omsFilterText.faultMessage.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleCodeFilterChange(textFieldFilterValue) {
    this.props.handleOmsFilterChange("CODE", textFieldFilterValue);
  }

  handleMessageFilterChange(textFieldFilterValue) {
    this.props.handleOmsFilterChange("MESSAGE", textFieldFilterValue);
  }
}
