import React, { useState, useEffect } from "react";
import RequestTable from "../Global Components/RequestTable";
import { getMaterialReactRpdLogColumnDef } from "../Graph Components/Chart Options/MaterialTableConfig.js";
import "./RPDNewRequest.css";
import { getRpdRequestList } from "../clients/RpdRequestClient";
import _ from "lodash";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import CustomButton from "../Global Components/CustomButton";
import Spinner from "../Global Components/Spinner";
import ParameterSummaryDialog from "../Global Components/ParameterSummaryDialog";
import "./RPDLogs.css";

/**
 * The RPD logs component.
 * @param tail The aircraft tail number.
 * @param aircraftFamily The aircraft family number.
 * @param aircraftModel The aircraft model.
 * @param handleDuplicateRPDRequest The handler for the duplicate action.
 * @param rpdRequestEnabled Request Enabled flag.
 * @param status The aircraft status.
 * @param serial The aircraft serial number.
 * @param monitorStatus The monitor status.
 * @returns {Element}
 * @constructor
 */
export default function RPDLogs({
  tail,
  aircraftFamily,
  aircraftModel,
  handleDuplicateRPDRequest,
  rpdRequestEnabled,
  status,
  serial,
  monitorStatus,
}) {
  let columnDefinition = getMaterialReactRpdLogColumnDef();

  const [isLoadingRequestList, setIsLoadingRequestList] = useState(true);
  const [rpdRequestList, setRPDRequestList] = useState([]);
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [validRPDLogRequest, setValidRPDLogRequest] = useState(false);
  const [showRequestSummary, setShowRequestSummary] = useState(false);
  const [summaryParameters, setSummaryParameters] = useState([]);
  const [viewRequestTitle, setViewRequestTitle] = useState("");
  const [showRefreshButton, setShowRefreshButton] = useState(false);

  /**
   * Loads the RPD log.
   */
  useEffect(() => {
    const getRequestList = async () => {
      await loadRequestList();
    };
    if (tail) {
      getRequestList().catch(console.error);
    }
  }, [tail]);

  useEffect(() => {
    setValidRPDLogRequest(selectedRequests.length > 0);
  }, [selectedRequests]);

  /**
   * Opens a rpd log item.
   * @param request The request data.
   */
  const viewRequest = (request) => {
    setShowRequestSummary(true);
    setSummaryParameters(request.requestParameters);
    setViewRequestTitle(request.requestName);
  };

  /**
   * Duplicates an RPD request.
   * @param request The request data.
   */
  const duplicateRequest = (request) => {
    handleDuplicateRPDRequest(request);
  };

  /**
   * Deletes an RPD request.
   * @param request The request data.
   */
  const deleteRequest = (request) => { };

  /**
   * Closes the summary dialog for an RPD request.
   */
  const closeSummaryDialog = () => {
    setShowRequestSummary(false);
  };

  const toggleRequestSelection = (request, state) => {
    let tempAllRequests = rpdRequestList;
    let tempSelectedRequests = selectedRequests;
    const index = _.findIndex(tempAllRequests, (req) => {
      return req.id === request.id;
    });
    if (!state) {
      tempSelectedRequests.push(request);
      tempAllRequests[index].checked = true;
    } else {
      _.remove(tempSelectedRequests, {
        id: request.id,
      });
      tempAllRequests[index].checked = false;
    }
    setRPDRequestList([...tempAllRequests]);
    setSelectedRequests([...tempSelectedRequests]);
  };

  const loadRequestList = async () => {
    setIsLoadingRequestList(true);
    try {
      const rpdRequestListRaw = await getRpdRequestList(
        tail,
        aircraftFamily,
        aircraftModel,
        null
      );

      let requestListResponse = [];

      if (rpdRequestListRaw.data.length > 0) {
        requestListResponse = _.orderBy(
          rpdRequestListRaw.data,
          ["userRequestTimestamp"],
          ["desc"]
        );
        setShowRefreshButton(true);
      }

      let requestList = [];

      _.forEach(requestListResponse, function (request) {
        const parameters = request.parameters ? request.parameters : [];

        let requestStatusFormatted = "";
        let requestTypeFormatted = "";
        let statusIcon = "";
        let iconColor = "";

        switch (request.status) {
          case "RESPONSE_SUCCESSFUL": // Succesful response and data retrieval.
            requestStatusFormatted = "Complete";
            statusIcon = "check";
            iconColor = "#4CAF50";
            break;
          case "RESPONSE_TIMEOUT":
            requestStatusFormatted = "Response Timeout";
            statusIcon = "x";
            iconColor = "#E5554B";
            break;
          case "RESPONSE_FAILED": // Failed response from DSP/DDS.
            requestStatusFormatted = "Response Failed";
            statusIcon = "x";
            iconColor = "#E5554B";
            break;
          case "REQUEST_TIMEOUT": // A succesful response was not received after 15 minutes of the request.
            requestStatusFormatted = "Request Timeout";
            statusIcon = "x";
            iconColor = "#E5554B";
            break;
          case "REQUEST_FAILED": // Request was not succesfully uploaded to the aircraft by SITA.
            requestStatusFormatted = "Request Failed";
            statusIcon = "x";
            iconColor = "#E5554B";
            break;
          case "REQUEST_SENT": // Request succesfully uploaded to aircraft by SITA
            requestStatusFormatted = "Transmitted";
            statusIcon = "radio";
            iconColor = "#FFC72C";
            break;
          case "REQUEST_CREATED":
            requestStatusFormatted = "Pending";
            statusIcon = "radio";
            iconColor = "#FFFFFF";
            break;
          default:
            requestStatusFormatted = "Pending";
            statusIcon = "radio";
            iconColor = "#FFFFFF";
            break;
        }

        switch (request.requestType) {
          case "fault":
            requestTypeFormatted = "Event";
            break;
          case "flight":
            requestTypeFormatted = "Custom";
            break;
          default:
            requestTypeFormatted = "Unknown";
            break;
        }

        const rpdRequestItem = {
          id: request.rpdRequestId,
          requestStatus: request.status,
          requestStatusFormatted: requestStatusFormatted,
          requestStatusIcon: statusIcon,
          requestStatusIconColor: iconColor,
          requestTimestampFormatted: request.rpdRequestTimestamp
            ? moment(request.rpdRequestTimestamp)
              .utc()
              .format("MMM DD, YYYY - HH:mm:ss")
            : null,
          requestTimestamp: request.rpdRequestTimestamp,
          userRequestTimestampFormatted: request.userRequestTimestamp
            ? moment(request.userRequestTimestamp)
              .utc()
              .format("MMM DD, YYYY - HH:mm:ss")
            : null,
          userRequestTimestamp: request.userRequestTimestamp,
          requestType: request.requestType
            ? request.requestType
            : "FAULT_EVENT",
          requestTypeFormatted: requestTypeFormatted,
          requestFaultCode: request.faultCode ? request.faultCode : "",
          requestDuration: request.rpdDuration ? request.rpdDuration : 0,
          requestName: request.rpdRequestName
            ? request.rpdRequestName
            : "RPD Request",
          requestParameters: parameters,
          checked: false,
        };
        const ahmsRpdInfo = {
          dataFocus: "RPD",
          tail: tail,
          aircraftFamily: aircraftFamily,
          aircraftModel: aircraftModel,
          status: status,
          faultCode: rpdRequestItem.requestFaultCode,
          faultSeverity: null,
          faultTimestamp: rpdRequestItem.requestTimestamp,
          faultMessage: null,
          faultDescription: null,
          serial: serial,
          monitorStatus: monitorStatus,
          rpdRequestId: rpdRequestItem.id,
          rpdRequestDuration:
            rpdRequestItem.requestDuration > 0
              ? rpdRequestItem.requestDuration
              : 120,
          rpdRequestParameters: parameters,
        };
        rpdRequestItem.ahmsRpdInfo = ahmsRpdInfo;
        requestList.push(rpdRequestItem);
      });

      setRPDRequestList(requestList);
      setIsLoadingRequestList(false);
    } catch (error) {
      console.error(error);
      setRPDRequestList([]);
      setIsLoadingRequestList(false);
    }
  };

  return (
    <div>
      <div
        className="rpdView__body"
        style={{
          marginTop: "20px",
        }}
      >
        {showRefreshButton && (
          <div className="rpd__refreshButtonContainer">
            <p className="rpd__informationalTitle">
              List of pending, failed, and completed RPD requests.
            </p>
            <div>
              <CustomButton
                id={"RPDRefreshButton"}
                text={"Refresh"}
                icon={<FeatherIcon icon="rotate-cw" height={20} />}
                iconPosition={"start"}
                customHeight={"40px"}
                onClickAction={loadRequestList}
                colorStyle={"secondary"}
                customButtonColor={"#535151"}
                customButtonTextColor={"#FFFFFF"}
                buttonStyle="pill"
                borderRadiusStyle={"squared"}
              />
            </div>
          </div>
        )}
        <div className="rpdView__rpdListContainer">
          <div className="rpdView__rpdListContainer__body">
            <div className="rpdView__parameterListContainer">
              {isLoadingRequestList ? (
                <div className="rpdView__parameterTableContainer">
                  <div
                    style={{
                      width: "100px",
                      paddingTop: "140px",
                      margin: "auto",
                      alignSelf: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                </div>
              ) : (
                <div className="rpdView__fullWidthContainer">
                  <div className="rpdView__parameterTableContainer">
                    <RequestTable
                      data={rpdRequestList}
                      columns={columnDefinition}
                      handlePreviewRequest={viewRequest}
                      handleLoadRequest={toggleRequestSelection}
                      handleDuplicateRequest={duplicateRequest}
                      handleDeleteRequest={deleteRequest}
                      rpdRequestEnabled={rpdRequestEnabled}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ParameterSummaryDialog
        active={showRequestSummary}
        title={viewRequestTitle}
        parameters={summaryParameters}
        handleSubmitClose={closeSummaryDialog}
      />
    </div>
  );
}
