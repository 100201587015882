import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import CanvasJS from "../resources/canvasjs.min.js";

export function getSeries(response, dataFocus, unit, discreteTranslation) {
  let data = null;
  if (
    dataFocus === "FAULT" ||
    dataFocus === "RPD" ||
    dataFocus === "IN-FLIGHT"
  ) {
    data = response.data.data || response.data[0].data;
  } else if (dataFocus === "FLIGHT" || dataFocus === "CMS") {
    data = response.data.data || response.data[0].data;

    if (data.length === 0 || isNaN(data[0][1])) {
      data = [];
    }
  }

  const sortedData = data.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b[0]) - new Date(a[0]);
  });

  let series = {
    toggleState: "eye",
    ata: response.config.params.ata,
    englishName: response.config.params.englishName,
    unit: unit,
    name: response.config.params.parameterName,
    type: unit === "Discrete" ? "area" : "line",
    data: sortedData,
    lru: response.config.params.lru,
    isDiscrete: response.config.params.unit === "Discrete" ? true : false,
    discreteTranslation:
      unit === "Discrete"
        ? discreteTranslation(response.config.params.parameterName)
        : null,
  };

  return series;
}

export function getDiscreteNonBinarySeries(s, config, discreteTranslation) {
  const sortedDate = s.valueStatus.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b[0]) - new Date(a[0]);
  });

  let series = {
    toggleState: "eye",
    ata: config.ata,
    englishName: s.value,
    unit: "Discrete Non-binary",
    name: s.value,
    type: "area",
    data: sortedDate,
    lru: config.lru,
    isDiscrete: true,
    discreteTranslation: discreteTranslation,
  };

  return series;
}

export function getCanvasLineChartOptions(unit, dataSeries) {
  return {
    id: "chart-" + unit + uuidv4(),
    chartName: "New Chart Unit - " + unit.toString(),
    unit: unit,
    isCollapsed: false,
    chartType: "continuous-line",
    options: {
      height: 350,
      theme: "dark2",
      colorSet: "greenShades",
      backgroundColor: "rgb(16, 16, 16)",
      zoomEnabled: true,
      exportEnabled: false,
      animationEnabled: true,
      axisY: {
        gridThickness: 0,
        lineThickness: 1,
        labelAutoFit: true,
        labelMaxWidth: 40,
        labelFontSize: 13,
        tickLength: 2,
        valueFormatString: "###,###.####",
        labelFormatter: function (e) {
          if (e.value.toString().length === 1) {
            return e.value.toFixed(4);
          }
          if (e.value.toString().length === 2) {
            return e.value.toFixed(3);
          }
          if (e.value.toString().length === 3) {
            return e.value.toFixed(2);
          }
          if (e.value.toString().length === 4) {
            return e.value.toFixed(1);
          }
          return e.value;
        },
      },
      axisX: {
        gridThickness: 0,
        tickLength: 3,
        margin: 3,
        lineThickness: 1,
        valueFormatString: "HH:mm:ss",
        labelFontSize: 13,
        labelFormatter: function (e) {
          return moment.utc(e.value).format("HH:mm:ss");
        },
      },
      data: dataSeries,
    },
  };
}

export function getCanvasChartOption(unit, dataSeries, parameterName) {
  return {
    id: "chart-" + unit + uuidv4(),
    chartName: "New Discrete Chart",
    unit: unit,
    isCollapsed: false,
    chartType: "discrete-line",
    parameterName: parameterName,
    options: {
      height: 60,
      theme: "dark2",
      colorSet: "discreteColor",
      backgroundColor: "rgb(16, 16, 16)",
      zoomEnabled: true,
      exportEnabled: false,
      animationEnabled: true,
      axisY: {
        gridThickness: 0,
        lineThickness: 1,
        labelAutoFit: true,
        labelMaxWidth: 40,
        labelFontSize: 13,
        minimum: 0,
        maximum: 1,
        interval: 2,
        tickLength: 2,
        valueFormatString: "###,###.####",
        labelFormatter: function (e) {
          if (e.value.toString().length === 1) {
            return e.value.toFixed(4);
          }
          if (e.value.toString().length === 2) {
            return e.value.toFixed(3);
          }
          if (e.value.toString().length === 3) {
            return e.value.toFixed(2);
          }
          if (e.value.toString().length === 4) {
            return e.value.toFixed(1);
          }
          return e.value;
        },
      },
      axisX: {
        gridThickness: 0,
        tickLength: 3,
        margin: 3,
        lineThickness: 1,
        valueFormatString: "HH:mm:ss",
        labelFontSize: 13,
        labelFormatter: function (e) {
          return moment.utc(e.value).format("HH:mm:ss");
        },
      },
      data: dataSeries,
    },
  };
}

export function getCanvasDiscreteNonBinaryChartOption(
  unit,
  dataSeries,
  parameterName
) {
  return {
    id: "chart-" + unit + uuidv4(),
    chartName: "New State Discrete Chart",
    unit: unit,
    isCollapsed: false,
    chartType: "discrete-nonbinary-line",
    parameterName: parameterName,
    options: {
      height: 60,
      theme: "dark2",
      //colorSet: "discreteColor",
      colorSet: "discreteNonBinaryColor",
      backgroundColor: "rgb(16, 16, 16)",
      zoomEnabled: true,
      exportEnabled: false,
      animationEnabled: true,
      axisY: {
        gridThickness: 0,
        lineThickness: 1,
        labelAutoFit: true,
        labelMaxWidth: 40,
        labelFontSize: 13,
        minimum: 0,
        maximum: 1,
        interval: 2,
        tickLength: 2,
        valueFormatString: "###,###.####",
        labelFormatter: function (e) {
          if (e.value.toString().length === 1) {
            return e.value.toFixed(4);
          }
          if (e.value.toString().length === 2) {
            return e.value.toFixed(3);
          }
          if (e.value.toString().length === 3) {
            return e.value.toFixed(2);
          }
          if (e.value.toString().length === 4) {
            return e.value.toFixed(1);
          }
          return e.value;
        },
      },
      axisX: {
        gridThickness: 0,
        tickLength: 3,
        margin: 3,
        lineThickness: 1,
        valueFormatString: "HH:mm:ss",
        labelFontSize: 13,
        labelFormatter: function (e) {
          return moment.utc(e.value).format("HH:mm:ss");
        },
      },
      data: dataSeries,
    },
  };
}

export function getDiscreteNonBinaryChartObject(
  index,
  options,
  faultTimeStamp,
  severityColor,
  series
) {
  // Object needed to create a chart
  let chart = {
    chartName: "Discrete Non-Binary_chart_" + index,
    chartIndex: index,
    unit: "Discrete Non-Binary",
    lru: "",
    series: [series],
    options: options,
    type: "line",
    size: "99%",
    id: "discreteNonBinary-" + index,
    index: index,
  };

  const annotations = {
    xaxis: [
      {
        x: new Date(faultTimeStamp).getTime(),
        strokeDashArray: 0,
        borderWidth: 4,
        offsetX: 0,
        offsetY: 0,
        borderColor: severityColor,
        label: {
          borderColor: severityColor,
          style: {
            color: severityColor,
            background: severityColor,
          },
        },
      },
    ],
  };

  chart.options.annotations = annotations;

  // Modifications for binary discrete data

  chart.options.stroke.curve = "stepline";
  chart.options.stroke.width = 2;
  chart.options.yaxis.tickAmount = 1;
  chart.height = 80;
  return chart;
}

export function getChartObject(
  series,
  index,
  options,
  faultTimeStamp,
  severityColor
) {
  // Object needed to create a chart
  let chart = {
    chartName: series.unit + "_chart_" + index,
    chartIndex: index,
    englishName: series.name,
    unit: series.unit,
    height: 350,
    lru: series.lru,
    series: [series],
    options: options,
    type: "line",
    size: "99%",
    id: "line-" + index,
    index: index,
  };

  const annotations = {
    xaxis: [
      {
        x: new Date(faultTimeStamp).getTime(),
        strokeDashArray: 0,
        borderWidth: 4,
        offsetX: 0,
        offsetY: 0,
        borderColor: severityColor,
        label: {
          borderColor: severityColor,
          style: {
            color: severityColor,
            background: severityColor,
          },
        },
      },
    ],
  };

  chart.options.annotations = annotations;

  // Modifications for binary discrete data

  if (chart.unit === "Discrete" || chart.unit === "Discrete Non-binary") {
    chart.options.stroke.curve = "stepline";
    chart.options.stroke.width = 2;
    chart.options.yaxis.tickAmount = 1;
    chart.height = 80;
  }
  return chart;
}

export function getFaultSeverityColor(severity) {
  let color = "#FFFFFF";
  switch (severity) {
    case "C":
      color = "#FFC72C";
      break;
    case "W":
      color = "#FF5050";
      break;
    case "A":
      color = "#709DFF";
      break;
    case "S":
      color = "#FFFFFF";
      break;
    default:
      color = "#FFFFFF";
      break;
  }
  return color;
}

export function getUnit(unit) {
  if (unit) {
    if (unit.lastIndexOf(")") !== -1) {
      unit = unit.substring(unit.lastIndexOf("(") + 1, unit.lastIndexOf(")"));
    }
    if (
      unit === "As per Group Definition" ||
      unit === "As per Group definition"
    ) {
      unit = "APGP";
    }
    return unit;
  } else {
    return "n/a";
  }
}
