import React from 'react';
import Card from '../Card.jsx';
import * as C from '../Colors';

const overview = (
  <div>
    Selecting a parameter will take you to the <b>TEMPLATES</b> page. 
    This page lets you load individual parameters or templates into visual charts. 
  </div>
)

const parameters = (
  <div>
    Choose which individual <b>PARAMETERS</b> to visualize, by using the category filters. 
  </div>
)

const templates = (
  <div>
    Use the <b>TEMPLATES</b> tab to load, save, and share custom visualization templates.
  </div>
)

const stepContent = [
  {
    steps: [
      overview
    ],
  },
  {
    steps: [
      parameters
    ],
    target: '#id_parameters_tab',
    placement: 'right',
    floaterProps: {
      placement: 'left-start',
    }
  },
  {
    steps: [
      templates
    ],
    target: '#id_template_tab',
    placement: 'right',
    floaterProps: {
      placement: 'left-start',
    }
  },
  // {
  //   steps: [
  //     "Filter the parameters by ATA, original parameter name, plain English keywork, units.",
  //   ],
  //   target: '.MuiTableBody-root',
  //   placement: 'left',
  //   floaterProps: {
  //     placement: 'right-start',
  //   }
  // },
  // {
  //   steps: [
  //     "Select / add parameters to view.",
  //   ],
  //   target: '.PrivateSwitchBase-input-22',
  //   placement: 'right',
  //   floaterProps: {
  //     placement: 'right-start',
  //   }
  // },
  // {
  //   steps: [
  //     "Create Charts -> to load parameters on Chart view.",
  //     "Cancel - > Go back to historic AHMS Session / page.",
  //   ],
  //   target: '#cancel-update-charts',
  //   placement: 'left',
  //   floaterProps: {
  //     placement: 'right-start',
  //   }
  // },
  // {
  //   steps: [
  //     "Save Template - > Create new personal template.",
  //     "Export AHMS Data -> Exports AHMS data as CSV file(s).",
  //   ],
  //   target: '#id_save_template_export_data',
  //   placement: 'left',
  //   floaterProps: {
  //     placement: 'right-start',
  //   }
  // },
  // {
  //   steps: [
  //     "This is the Templates section, of the AHMS charting.", 
  //     "Use this to View and Load Personal and Shared templates (Buttons at Right section).",
  //     "Templates can also be uploaded from Local computer using the “Upload Template” button located at bottom left.",
  //   ],
  // },
];

export default function AHMSDataOnboarding () {

  // defaults for this set
  const defaultStyles = {
    options: C.styleReset,
    buttonNext: {
      ...C.buttonStyles,
      color: C.blue,
    },
    buttonBack: {
      ...C.buttonStyles,
      background: "transparent",
      border: "1px solid " + C.white,
      color: C.white,
    },
  };

  let trainings = [];

  stepContent.map( (x, index) => {
    trainings.push({
      target: x.target || 'body',
      disableBeacon: x.disableBeacon || true,
      content: 
        <Card paragraphs={x.steps} 
          index={index}
          stepCount={stepContent.length-1}
          pageName={"Templates"}
        />,
      placement: x.placement || 'center',
      styles: x.styles || defaultStyles, 
      spotlightClicks: x.spotlightClicks || false,
    });
  });

  return trainings;
}