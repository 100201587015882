import React from "react";
import ReactApexChart from "react-apexcharts";
import Legend from "./Legend.js";
import "../styles/Graph Components/Chart.css";
import FullChart from "../Ahms View Components/FullChart.js";
import ApexCharts from "apexcharts";
import FeatherIcon from "feather-icons-react";
import moment from "moment";

export default function Chart(props) {
  const [chartCollapsed, setChartCollapsed] = React.useState(false);
  const [chartName, setChartName] = React.useState(props.chartName);
  const [state] = React.useState({
    series: props.series,
    options: props.options,
    chartModalId: "full_chart_" + props.id,
    style:
      props.type === "line"
        ? "chart-container-line"
        : "chart-container-discrete",
  });

  const colors = [
    "#07B9FC",
    "#FFB300",
    "#EA0034",
    "#9AF000",
    "#ec008c",
    "#0062FF",
    "#33FF00",
    "#FFFE37",
    "#FF6600",
    "#CC00FF",
  ];

  const getModifiedOptions = (index, name) => {
    let modifiedOptions = props.options;

    modifiedOptions.chart.id = name;
    modifiedOptions.grid.yaxis.lines.show = true;
    if (props.unit === "Discrete Non-Binary") {
      modifiedOptions.chart.type = "area";
      modifiedOptions.colors = colors;
    } else {
      modifiedOptions.colors = [colors[index]];
    }

    if (index > 0) {
      modifiedOptions.chart.toolbar.show = false;
      modifiedOptions.xaxis.labels = {
        offsetY: -3,
        style: {
          colors: "#101010",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
        rotateAlways: false,
        formatter(val, timestamp) {
          return moment.utc(timestamp).format("HH:mm:ss.SSS");
        },
      };
      modifiedOptions.xaxis.axisBorder = {
        show: false,
      };
      modifiedOptions.xaxis.axisTicks = {
        show: false,
      };
    }
    return modifiedOptions;
  };

  const toggleParameter = (parameter, state, index) => {
    props.toggleParameterFn(state, index, props.id);

    ApexCharts.exec(props.id, "toggleSeries", parameter);
    ApexCharts.exec(
      props.id,
      "updateOptions",
      {
        legend: {
          show: false,
        },
      },
      false,
      true
    );
  };

  const handleTitleOffFocus = (newChartName) => {
    props.updateChartName(props.id, newChartName);
  };

  const handleTitleOnChange = (newChartName) => {
    setChartName(newChartName);
  };

  const handleChartDisplace = (direction, chartIndex) => {
    props.handleChartDisplace(direction, chartIndex);
  };

  const chart = () => {
    return (
      <div className="chart-container">
        <div className="chart-container-header">
          <div className="chart-container-header-collapse">
            <FeatherIcon
              icon={chartCollapsed ? "chevron-down" : "chevron-up"}
              height={30}
              className="chart-container-header-collapse-icon"
              onClick={(event) => {
                setChartCollapsed(!chartCollapsed);
              }}
            />
          </div>
          <div className="chart-container-header-title">
            <input
              id={"chart-title-" + props.id}
              value={chartName}
              placeholder="Chart Title"
              type="text"
              onBlur={(event) => handleTitleOffFocus(event.target.value)}
              onChange={(event) => handleTitleOnChange(event.target.value)}
            />
          </div>
          <div className="chart-container-header-displace">
            <div className="chart-container-header-displace-section">
              <FeatherIcon
                icon={"arrow-up-circle"}
                height={30}
                className={
                  "chart-container-header-displace-icon" +
                  (props.index === 0 ? "-disabled" : "")
                }
                disabled={props.index === 0 ? true : false}
                onClick={(event) => handleChartDisplace("UP", props.index)}
              />
              <FeatherIcon
                icon={"arrow-down-circle"}
                height={30}
                className={
                  "chart-container-header-displace-icon" +
                  (props.index + 1 === props.chartNumberMax ? "-disabled" : "")
                }
                disabled={
                  props.index + 1 === props.chartNumberMax ? true : false
                }
                onClick={(event) => handleChartDisplace("DOWN", props.index)}
              />
            </div>
            <div className="chart-container-header-displace-section">
              {(props.index + 1).toString() + " of " + props.chartNumberMax}
            </div>
          </div>
          {props.unit !== "Discrete" && props.unit !== "Discrete Non-Binary" && (
            <div className="chart-container-header-fullScreen">
              <FullChart
                modalId={state.chartModalId}
                series={state.series}
                chartName={chartName}
                faultTime={props.faultTimeStamp}
              />
            </div>
          )}
        </div>
        <div
          className={state.style + (chartCollapsed ? "-collapsed-true" : "")}
        >
          <div className="legend-normal">
            <div style={{ height: "100%" }}>
              <Legend
                {...props}
                legendSeries={state.series}
                toggleFunc={toggleParameter}
                removeParam={props.removeParameter}
                colors={colors}
              />
            </div>
          </div>
          <div className="chart-space-normal">
            {props.unit === "Discrete Non-Binary" &&
              props.series.map((series, index) => (
                <React.Fragment key={series.englishName}>
                  <ReactApexChart
                    options={getModifiedOptions(index, series.englishName)}
                    series={series.seriesData}
                    type="area"
                    height={80}
                  />
                </React.Fragment>
              ))}
            {props.unit === "Discrete" &&
              props.series.map((series, index) => (
                <React.Fragment key={series.name}>
                  <ReactApexChart
                    options={getModifiedOptions(index, series.name)}
                    series={[series]}
                    type={props.type}
                    height={80}
                  />
                </React.Fragment>
              ))}
            {props.unit !== "Discrete" &&
              props.unit !== "Discrete Non-Binary" && (
                <ReactApexChart
                  options={state.options}
                  series={state.series}
                  type={props.type}
                  height={350}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  return chart();
}
