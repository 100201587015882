import * as Constants from "./Constants";
import * as featureToggleTools from "../Feature Toggles/FeatureToggleTools";

/*
    Catalog of all the API and their versions used on the application.
    Notes:
      - All "TODO:" are api that need to be integrated into this catalog
*/

const BOMBARDIER_API_URL = Constants.BOMBARDIER_API_URL();
const METHOD_GET = "GET";
const METHOD_POST = "POST";
const METHOD_PUT = "PUT";
const METHOD_DELETE = "DELETE";

// 1.1 Generic Login
export const LOGIN_API = {
  url: BOMBARDIER_API_URL + "/v8.1/login",
  method: METHOD_POST,
};

// 1.2 Get Tails
export const TAILS = {
  url: BOMBARDIER_API_URL + "/v10/tails",
  method: METHOD_GET,
};

// 1.6 Get Parameter Data
export const PARAMETER_DATA_ENDPOINT = {
  url: BOMBARDIER_API_URL + "/v10/parameter-data",
  method: METHOD_GET,
};

// 1.8 Get Parameter List
export const PARAMETERS_ENDPOINT = {
  url: BOMBARDIER_API_URL + "/v10/parameters",
  method: METHOD_POST,
};

// 1.9 Get Flight Cycles
export const FLIGHT_CYCLES = {
  url: BOMBARDIER_API_URL + "/v10/flight-cycles",
  method: METHOD_GET,
};

/*
// TODO: 1.10 Get Chart Template
export const CHART_TEMPLATE = {
  url: BOMBARDIER_API_URL + "/v10/charts-template",
  method: "GET",
};

// TODO: 1.11 Get Chart Template
export const CHART_TEMPLATE = {
  url: BOMBARDIER_API_URL + "/v10/charts-template",
  method: "GET",
};
*/

// 1.14 Get OMS Messages
export const FAULTS_OMS = {
  url: 
    featureToggleTools.isFeatureToggleActive("ft-new-oms-api-version")
      ? BOMBARDIER_API_URL + "/v10.5/faults/oms"
      : BOMBARDIER_API_URL + "/v10/faults/oms",
  method: METHOD_GET,
};

// 1.15 Get FDECAS Messages
export const FAULTS_FDE = {
  url: 
    featureToggleTools.isFeatureToggleActive("ft-new-fde-api-version")
      ? BOMBARDIER_API_URL + "/v10.5/faults/fdecas"
      : BOMBARDIER_API_URL + "/v10/faults/fdecas",
  method: METHOD_GET,
};

// 1.17 Takeoff Report
export const TAKEOFF_REPORT = {
  url: BOMBARDIER_API_URL + "/v10/flightreport",
  method: METHOD_GET,
};

// 1.21 Flight History List
export const ALL_FLIGHTS_HISTORY = {
  url: BOMBARDIER_API_URL + "/v10/flight-history",
  method: METHOD_GET,
};

// 1.22 AHMU Parameter Data
export const AHMU_PARAMETER_DATA_ENDPOINT = {
  url: BOMBARDIER_API_URL + "/v10/ahmu-parameter-data",
  method: METHOD_GET,
};

// 1.23 Fault Event Count
export const FAULT_EVENT_COUNTS = {
  url: BOMBARDIER_API_URL + "/v10/fault-count-stats",
  method: METHOD_POST,
};

// 1.24 Update Flight Cycles
export const POST_FLIGHT_CYCLES = {
  url: BOMBARDIER_API_URL + "/v10/flight-cycles",
  method: METHOD_POST,
};

// 1.26 Update Monitor Status
export const UPDATE_MONITOR = {
  url: BOMBARDIER_API_URL + "/v10/aircraft-monitor",
  method: METHOD_PUT,
};

// 1.27 Create RPD Request
export const RPD_REQUEST = {
  url: BOMBARDIER_API_URL + "/v10/rpd-request",
  method: METHOD_POST,
};

// 1.28 Get RPD Request List
export const RPD_REQUEST_LIST = {
  url: BOMBARDIER_API_URL + "/v10/rpd-request-list",
  method: METHOD_GET,
};

/*
// TODO: 1.29 RPD Parameter Data
export const RPD_PARAMETER_DATA = {
  url: BOMBARDIER_API_URL + "/v10/rpd-parameter-data",
  method: "GET",
};
*/

// 1.31 Access Token Refresh
export const TOKEN_REFRESH = {
  url: BOMBARDIER_API_URL + "/v10/token-refresh",
  method: METHOD_POST,
};

// 1.32 Aircraft Metadata
export const AIRCRAFT_METADATA = {
  url: BOMBARDIER_API_URL + "/v10/aircraft-metadata",
  method: METHOD_GET,
};

// 1.33 CSV Parameter Data
export const CSV_PARAMETER_DATA_ENDPOINT = {
  url: BOMBARDIER_API_URL + "/v10/parameter-csv",
  method: METHOD_POST,
};

// 1.34 Flight Cycle Update Logs
export const FLIGHT_CYCLE_LOGS = {
  url: BOMBARDIER_API_URL + "/v10/logs-flight-cycle",
  method: METHOD_GET,
};

// 1.35 Get SITA Recent Session List
export const RECENT_SESSION_LIST = {
  url: BOMBARDIER_API_URL + "/v10/aircraft-recent-sessions",
  method: METHOD_GET,
};

// 1.36 Get Event Recording Duration
export const PARAMETER_DURATION = {
  url: BOMBARDIER_API_URL + "/v10/event-duration",
  method: METHOD_GET,
};

// 1.37 Get CMS Messages
export const FAULTS_CMS = {
  url: BOMBARDIER_API_URL + "/v10/cms-faults",
  method: METHOD_GET,
};

// 1.38 Reduce FDE Configuration List
export const COMMON_FDE_LIST = {
  url: BOMBARDIER_API_URL + "/v10/reduce-fde-list",
  method: METHOD_GET,
};

// 1.39 Reduce FDE Configuration Edit
export const COMMON_FDE_UPDATE = {
  url: BOMBARDIER_API_URL + "/v10/reduce-fde",
  method: METHOD_POST,
};

// 1.40 Credentials
export const CREDENTIALS_API = {
  url: BOMBARDIER_API_URL + "/v10/credentials",
  method: METHOD_GET,
};

// 1.41 Create RPD Event Request
export const RPD_EVENT_REQUEST = {
  url: BOMBARDIER_API_URL + "/v10/rpd-event-request",
  method: METHOD_POST,
};

/*
// TODO: 1.42 Get Charts Template List
export const ChartsTemplateList = {
  url: BOMBARDIER_API_URL + "/v10/charts-template-list",
  method: "GET",
};
*/

// 1.43 Get Aircraft Permission Configuration
export const AIRCRAFT_SETTINGS = {
  url: BOMBARDIER_API_URL + "/v10/aircraft-permission",
  method: METHOD_GET,
};

// 1.44 Update Aircraft Permission Configuration
export const AIRCRAFT_SETTINGS_UPDATE = {
  url: BOMBARDIER_API_URL + "/v10/aircraft-permission",
  method: METHOD_POST,
};

/*
// TODO: 1.46 RPD Parameter List
export const RPD_PARAMETER_LIST = {
  url: BOMBARDIER_API_URL + "/v10/rpd_parameter_list",
  method: "GET",
};
*/

// 1.48 Get Tail General Data
export const TAIL_GENERAL_DATA = {
  url: BOMBARDIER_API_URL + "/v10/aircraft-general-data",
  method: METHOD_GET,
};

// 1.49 Get CMS Parameter List
export const CMS_PARAMETERS_ENDPOINT = {
  url: BOMBARDIER_API_URL + "/v10/cms-parameters",
  method: METHOD_GET,
};

// 1.50 Get CMS Parameter Data
export const CMS_PARAMETER_DATA_ENDPOINT = {
  url: BOMBARDIER_API_URL + "/v10/cms-parameter-data",
  method: METHOD_GET,
};

// 1.54 CMS Session List
export const CMS_SESSIONS_LIST = {
  url: BOMBARDIER_API_URL + "/v10/cms-sessions-list",
  method: METHOD_GET,
};

// 1.55 Get CMS Parameter Additional Info
export const CMS_PARAMETER_INFO_ENDPOINT = {
  url: BOMBARDIER_API_URL + "/v10/cms-parameter-info",
  method: METHOD_GET,
};

// 1.56 EIFR List
export const EIFR_ENDPOINT = {
  url: BOMBARDIER_API_URL + "/v10/eifr-list",
  method: METHOD_GET,
};

// 1.57 RPD Respose Size
export const RPD_RESPONSE_SIZE = {
  url: BOMBARDIER_API_URL + "/v10/rpd-response-size",
  method: METHOD_POST,
};

// 1.58 CSV CMS Data
export const CMS_PARAMETER_CSV_DATA = {
  url: BOMBARDIER_API_URL + "/v10/parameter-csv-cms",
  method: METHOD_POST,
};

// TODO: 1.59 - Update Chart Template Status

// 1.61 - Save image to s3
export const S3_UPLOAD = {
  url: BOMBARDIER_API_URL + "/v10/save-s3-image",
  method: METHOD_POST,
};

// TODO: 1.62 - Parameter Data Excel Export

// 1.63 - Interactive Onboarding Feature Guide User Tracking
export const IOFG_USER_LIST = {
  url: BOMBARDIER_API_URL + "/v10/iofg-user-list",
  method: METHOD_GET,
};

// 1.64 - Interactive Onboarding Feature Guide User Tracking Update
export const IOFG_USER_UPDATE = {
  url: BOMBARDIER_API_URL + "/v10/iofg-user-update",
  method: METHOD_PUT,
};

// TODO: 1.66 - RPD Request Delete

// 1.67 - User Help Documentation List
export const GET_USERHELP_DOC_LIST = {
  url: BOMBARDIER_API_URL + "/v10/userhelp-doc-list",
  method: METHOD_GET,
};

// 1.68 - User Help Documentation Manual Downloadd
export const GET_USERHELP_DOC = {
  url: BOMBARDIER_API_URL + "/v10/userhelp-doc",
  method: METHOD_GET,
};

// 1.75 CMS IF Messages
export const CMS_IF_MESSAGES = {
  url: BOMBARDIER_API_URL + "/v10/cms-if-messages",
  method: METHOD_GET,
};

// TODO: 1.76 - Create Chart Templates

// Login Predictive API
export const LOGIN_PREDICTIVE_API = {
  url: BOMBARDIER_API_URL + "/v10/credentials_pred_maint",
  method: METHOD_GET,
};

// 1.89 - Events List
export const EVENTS_LIST = {
  url: BOMBARDIER_API_URL + "/v10/events",
  method: METHOD_GET,
};

// 1.90 - Session Event Counts
export const SESSION_EVENT_COUNTS = {
  url: BOMBARDIER_API_URL + "/v10/events",
  method: METHOD_POST,
};

// 1.91 Get Ground Loading History
export const GROUND_LOADING_LIST = {
  url: BOMBARDIER_API_URL + "/v10/ground-loading",
  method: METHOD_GET,
};

// 1.94 Create New Ground Loading Session
export const NEW_GROUND_LOADING_SESSION = {
  url: BOMBARDIER_API_URL + "/v10/ground-loading",
  method: METHOD_POST,
};

export const UPLOAD_GROUND_LOADING_FILE = {
  url: BOMBARDIER_API_URL + "/v10/ground-loading-file",
  method: METHOD_POST,
};

export const DELETE_GROUND_LOADING_FILE = {
  url: BOMBARDIER_API_URL + "/v10/ground-loading-file",
  method: METHOD_DELETE,
};

export const SUBMIT_GROUND_LOADING_SESSION = {
  url: BOMBARDIER_API_URL + "/v10/ground-loading",
  method: METHOD_PUT,
};

export const MDC_MESSAGES = {
  url: BOMBARDIER_API_URL + "/v10/mdc-messages",
  method: METHOD_GET,
};

export const GET_NOTIFICATIONS_SETTINGS_V10 = {
  url: BOMBARDIER_API_URL + "/v10/user-notification-settings",
  method: METHOD_GET,
};

export const POST_NOTIFICATIONS_SETTINGS_V10 = {
  url: BOMBARDIER_API_URL + "/v10/user-notification-settings",
  method: METHOD_POST,
};

export const PMX_USER_AIRCRAFT_SUBSCRIPTIONS = {
  url: BOMBARDIER_API_URL + "/v10/pmx-useraircraft-subscription",
  method: METHOD_GET,
};

export const USER_AIRCRAFT_SUBSCRIPTIONS = {
  url: BOMBARDIER_API_URL + "/v10/user-aircraft-subscriptions",
  method: METHOD_GET,
};

export const UPDATE_USER_NOTIFICATION = {
  url: BOMBARDIER_API_URL + "/v10/user-notifications",
  method: METHOD_PUT,
};

export const GET_USER_NOTIFICATIONS_PAGINATED = {
  url: BOMBARDIER_API_URL + "/v10.1/user-notifications",
  method: METHOD_GET,
}

// 2.0 GET Announcements
export const GET_ANNOUNCEMENT_LIST = {
  url: BOMBARDIER_API_URL + "/v10/user-announcements",
  method: METHOD_GET
};

// 2.0 GET Announcements Paginated
export const GET_ANNOUNCEMENT_LIST_PAGINATED = {
  url: BOMBARDIER_API_URL + "/v10.1/user-announcements",
  method: METHOD_GET
};