import React, { useState } from "react";
import "../styles/Global Components/ToggleSwitch.css";
import CustomSwitch from "./CustomSwitch";

function ToggleSwitch({
  id,
  isChecked,
  handleSwitchChange,
  isDisabled = false,
}) {
  const [switchChecked, setSwitchChecked] = useState(isChecked);

  const handleChange = (event) => {
    setSwitchChecked(event.target.checked);
    if (handleSwitchChange) {
      handleSwitchChange(event.target.checked);
    }
  };

  return (
    <div>
      <CustomSwitch
        checked={switchChecked}
        onChange={handleChange}
        name={id}
        disabled={isDisabled ? true : false}
        disableRipple
      />
    </div>
  );
}

export default ToggleSwitch;
