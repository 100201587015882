import React from "react";
import { Group } from "@visx/group";
import { NodeGroup } from "react-move";
import Node from "./Node";
import { findCollapsedParent, getTopLeft } from "../../../usefulFunctions";

const Nodes = ({
    nodes,
    layout,
    orientation,
    onNodeClick,
    treePosition,
    updateCurrentLRU,
    treeWidth
}) => {
    return (
        <NodeGroup
            data={nodes}
            keyAccessor={d => {
                if (d.parent) return `${d.parent.data.name}_${d.data.name}`;
                else return d.data.name;
            }}
            start={node => {
                const parentTopLeft = getTopLeft(
                    node.parent || { x: 0, y: 0 },
                    layout,
                    orientation
                );
                return {
                    top: parentTopLeft.top,
                    left: parentTopLeft.left,
                    opacity: 0
                };
            }}
            enter={node => {
                const topLeft = getTopLeft(node, layout, orientation);
                return {
                    top: [topLeft.top],
                    left: [topLeft.left],
                    opacity: [1]
                };
            }}
            update={node => {
                const topLeft = getTopLeft(node, layout, orientation);
                return {
                    top: [topLeft.top],
                    left: [topLeft.left],
                    opacity: [1]
                };
            }}
            leave={node => {
                const collapsedParent = findCollapsedParent(node.parent);
                const collapsedParentPrevPos = {
                    x: collapsedParent.data.x0,
                    y: collapsedParent.data.y0
                };
                const topLeft = getTopLeft(collapsedParentPrevPos, layout, orientation);
                return {
                    top: [topLeft.top],
                    left: [topLeft.left],
                    opacity: [0]
                };
            }}
        >
            {nodes => (
                <Group>
                    {nodes.map(({ key, data: node, state }) => {
                        return (
                            <Group
                                top={state.top}
                                left={state.left}
                                key={key}
                                opacity={state.opacity}
                            >
                                <Node
                                    updateCurrentLRU={updateCurrentLRU}
                                    treePosition={treePosition}
                                    node={node}
                                    layout={layout}
                                    orientation={orientation}
                                    onClick={event => onNodeClick(node, event)}
                                    key={key}
                                    treeWidth={treeWidth}
                                />
                            </Group>
                        );
                    })}
                </Group>
            )}
        </NodeGroup>
    );
}

export default Nodes;
