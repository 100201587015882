/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import './Radio.scss';

const Radio = ({
  id,
  checked,
  onChange,
  name,
  value,
  defaultChecked,
  key,
  label,
  children,
}) => {
  return (
    <div className="radio">
      <input
        id={id}
        checked={checked}
        onChange={onChange}
        name={name}
        type="radio"
        value={value}
        aria-label={value}
        defaultChecked={defaultChecked}
        key={key}
        required
      />
      <label htmlFor={id} className="radioLabel">
        <span>
          {label}
          {children && <>{children}</>}
        </span>
      </label>
    </div>
  );
};

export default Radio;

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  key: PropTypes.string,
  defaultChecked: PropTypes.bool,
};
