import React, { useState } from "react";
import Banner from "../Global Components/Banner";
import "./UploadsView.css";
import GroundLoadingHistory from "./Ground Loading/GroundLoadingHistory";

/**
 * The Uploads main view. Used for remote staging (ground loading).
 * @param props TODO: Define if there are any props required.
 * @return {JSX.Element}
 * @constructor
 */
const UploadsView = (props) => {

  // The tabs to be displayed on the top.
  const [tabs, setTabs] = useState(["Remote Staging"]);
  // The selected tab.
  const [selectedTab, setSelectedTab] = useState("Remote Staging");

  return (
    <div className={"uploads__main__container"}>
      <Banner
        pageType={"Uploads"}
        icon={"upload"}
        logoText={"BOMBARDIER"}
      />
      <div className="uploads__tab__container">
        {tabs.map((tab) => (
          <React.Fragment key={tab}>
            <div
              className={
                selectedTab === tab
                  ? "uploads__tab uploads__tab__selected"
                  : "uploads__tab"
              }
              onClick={() => {
                setSelectedTab(tab);
              }}
            >
              {tab}
            </div>
          </React.Fragment>
        ))}
      </div>
      {selectedTab === "Remote Staging" ?
        <GroundLoadingHistory />
        :
        null
      }

    </div>
  )
}

export default UploadsView;