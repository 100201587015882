import React from "react";
import "./RPDSelectedParameters.css";
import FeatherIcon from "feather-icons-react";

/**
 * Component that displays the selected parameters for the New RPD Request component.
 * @param summaryParameters The selected parameters.
 * @param handleRemoveParameterFromList Function to handle the removal of a parameter from the list.
 * @param rpdRequestType The request type.
 * @return {JSX.Element}
 * @constructor
 */
const RPDSelectedParameters = ({
  summaryParameters,
  handleRemoveParameterFromList,
  rpdRequestType
}) => {
  /**
   * Proxies the removal of a parameter to the parent component.
   * @param parameter parameter to be removed.
   */
  const handleRemoveParameter = (parameter) => {
    handleRemoveParameterFromList(null, parameter, true);
  };
  return (
    <div className="rpdView__selectedParameter__container">
      <span className="rpdView__parameterCount">
        {rpdRequestType !== "EVENT" ? summaryParameters.length + " parameters selected:" : "Event Request Selected."}
      </span>
      {rpdRequestType !== "EVENT" ? (
        summaryParameters.map((parameter, index) => (
          <React.Fragment key={"rpdSelectedParameter_" + index}>
            <p className="rpdView__selectedParameter">
              <FeatherIcon
                icon="x"
                width={17}
                height={17}
                onClick={() => handleRemoveParameter(parameter)}
              /> {parameter.parameterName}
            </p>
          </React.Fragment>
        ))
      ) : null }
    </div>
  )
}

export default RPDSelectedParameters;