import { createTheme } from '@mui/material/styles'

export const colors = {
    b10: '#A7A7A7',
    b40: '#707070',
    b60: '#505050',
    b70: '#404040',
    b80: '#303030'
}

export const customTheme = outerTheme =>
    createTheme({
        palette: {
            mode: 'dark',
            primary: { main: colors.b10 }
        }
    })
