import React, { useState } from "react";
import moment from "moment";
import {
    BlobProvider,
    View,
    StyleSheet,
    Document,
    Page,
    Text,
    Svg,
    Path,
    Link,
    Image
} from '@react-pdf/renderer';
import baLogo from "../../resources/Bombardier-Logo-Small.png";

export const Download = (props) => {
    const {
        summaryData,
        iseReport,
        flightReport,
        aircraftData,
        diagnosticsDataISE,
        sessionKey,
        iseNumber
    } = props;

    const [generatePdf, setGeneratePdf] = useState(false);

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#FFF',
            padding: 0,
        },
        headerContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#003E51',
            color: '#FFFFFF',
            padding: '15px 25px',
            width: '100%',
        },
        headerLeft: {
            fontFamily: 'Helvetica-Bold',
            fontSize: 15,
            color: '#FFFFFF',
            lineHeight: 'normal'
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 6,
        },
        lruRow: {
            flexDirection: 'row',
        },
        column: {
            flexDirection: 'column',
            width: '50%',
        },
        leftAlignedRowBigMargin: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: 20,
        },
        leftAlignedRow: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: 6,
        },
        rightAlignedRowBigMargin: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: 20,
        },
        rightAlignedRow: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: 6,
        },
        contentBold: {
            fontFamily: 'Helvetica-Bold',
            fontSize: 12,
            marginBottom: 6,
            color: '#003E51',
        },
        content: {
            fontFamily: 'Helvetica',
            fontSize: 12,
            marginBottom: 6,
            color: '#003E51'
        },
        contentSection: {
            margin: '20px 25px',
        },
        section: {
            marginBottom: 6
        },
        sectionWithMargin: {
            marginTop: 20,
        },
        sectionHeader: {
            fontFamily: 'Helvetica-Bold',
            fontSize: 12,
            marginBottom: 6,
            color: '#003E51',
        },
        table: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 1,
        },
        tableRow: {
            flexDirection: 'row',
            borderColor: '#000',
            backgroundColor: '#FFFFFF'
        },
        ataTableCell: {
            fontSize: 12,
            padding: '5px 5px 5px 0',
            width: '70px',
            color: '#003E51'
        },
        tableCell: {
            fontSize: 12,
            padding: 5,
            flex: '1 1',
            color: '#003E51'
        },
        ataTableHeader: {
            fontFamily: 'Helvetica-Bold',
            fontSize: 12,
            padding: '5px 5px 5px 0',
            width: '70px',
            color: '#003E51'
        },
        tableHeader: {
            fontFamily: 'Helvetica-Bold',
            fontSize: 12,
            padding: 5,
            flex: '1 1',
            color: '#003E51',
        },
        mainContent: {
            flexGrow: 1,
        },
        footer: {
            backgroundColor: '#003E51',
            padding: 15,
        },
        footerLink: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            color: '#D19000',
            fontSize: 12,
            textDecoration: 'none'
        },
        footerText: {
            marginLeft: 10,
        },
        footerBoldText: {
            fontFamily: 'Helvetica-Bold',
        },
        image: {
            width: 150
        }
    });

    const List = ({ array, header }) => {
        return <>
            <Text style={styles.sectionHeader}>{header}</Text>
            {array.map((el, index) => (
                array.length > 1
                    ? <Text key={index} style={styles.content}>&#x2022; {el}</Text>
                    : <Text key={index} style={styles.content}>{el}</Text>
            ))}
        </>
    }

    const LinkIcon = () => (
        <Svg style={{ width: 24, height: 24 }}>
            <Path d="M10 13C10.4295 13.5741 10.9774 14.0491 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9548 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.5521 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997"
                stroke="#D19000"
                strokeWidth="2"
                strokeLinecap="round" stroke-linejoin="round" />
            <Path d="M14.0002 11C13.5707 10.4259 13.0228 9.9508 12.3936 9.60704C11.7645 9.26328 11.0687 9.05886 10.3535 9.00765C9.63841 8.95643 8.92061 9.05961 8.24885 9.3102C7.5771 9.56079 6.96709 9.95291 6.4602 10.46L3.4602 13.46C2.54941 14.403 2.04544 15.666 2.05683 16.977C2.06822 18.288 2.59407 19.542 3.52111 20.4691C4.44815 21.3961 5.70221 21.922 7.01319 21.9334C8.32418 21.9447 9.58719 21.4408 10.5302 20.53L12.2402 18.82" stroke="#D19000"
                strokeWidth="2"
                strokeLinecap="round" stroke-linejoin="round" />
        </Svg>
    )

    const MyDocument = ({ flaggedParameters, iseReport, flightReport, aircraftData, diagnosticsDataISE, reportURL }) => (
        <Document>
            <Page size="B4" style={styles.page}>
                <View style={styles.mainContent}>
                    {/* HEADER */}
                    <View style={styles.headerContainer}>
                        <Text style={styles.headerLeft}>In-Service Event Summary</Text>
                        <Image style={styles.image} src={baLogo} />
                    </View>
                    <View style={styles.contentSection}>
                        {/* Flight Information */}
                        <View style={styles.row}>
                            {/* Flight Information Left Side */}
                            <View style={styles.column}>
                                <View style={styles.leftAlignedRowBigMargin}>
                                    <Text style={styles.contentBold}>Aircraft Tail Number:&nbsp;</Text>
                                    <Text style={styles.content}>{`  ${aircraftData.serialNumber}`}</Text>
                                </View>
                                <View style={styles.leftAlignedRow}>
                                    <Text style={styles.contentBold}>Session Number:&nbsp;</Text>
                                    <Text style={styles.content}>{`  ${iseReport?.session_number} - Flight ${iseReport?.flight_leg}`}</Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={styles.contentBold}>Departure: </Text>
                                    <Text style={styles.content}>{flightReport && flightReport.departureTime
                                        ? moment
                                            .utc(flightReport.departureTime)
                                            .format("MMM DD, YYYY - HH:mm:ss")
                                        : "-"}
                                    </Text>
                                </View>
                                <Text style={styles.content}>
                                    {flightReport && flightReport.departureAirportName
                                        ? `${flightReport.departureAirportName} (${flightReport.departureAirportIATA})`
                                        : "-"}
                                </Text>
                            </View>
                            {/* Flight Information Right Side */}
                            <View style={styles.column}>
                                <View style={styles.rightAlignedRowBigMargin}>
                                    <Text style={styles.contentBold}>Aircraft Serial Number:</Text>
                                    <Text style={styles.content}>{` ${aircraftData.tailNumber}`}</Text>
                                </View>
                                {iseReport?.event_id && (
                                    <View style={styles.rightAlignedRow}>
                                        <Text style={styles.contentBold}>Event ID: </Text>
                                        <Text style={styles.content}>{iseReport.event_id}</Text>
                                    </View>
                                )}
                                <View style={styles.rightAlignedRow}>
                                    <Text style={styles.contentBold}>Arrival: </Text>
                                    <Text style={styles.content}>{flightReport && flightReport.arrivalTime
                                        ? moment.utc(flightReport.arrivalTime).format("MMM DD, YYYY - HH:mm:ss")
                                        : "-"}
                                    </Text>
                                </View>
                                {flightReport && flightReport.arrivalAirportName && (
                                    <View style={styles.rightAlignedRow}>
                                        <Text style={styles.content}>{`${flightReport.arrivalAirportName} (${flightReport.arrivalAirportIATA})`}</Text>
                                    </View>
                                )}
                            </View>
                        </View>
                        {/* Diagnostics Information */}
                        {diagnosticsDataISE && Object.keys(diagnosticsDataISE).length > 0 &&
                            <>
                                <View style={styles.sectionWithMargin}>
                                    <View style={styles.row}>
                                        <Text style={styles.contentBold}>In-Service Event Name: </Text>
                                        <Text style={styles.content}>{` ${iseReport?.ise_name || "-"}`}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={styles.contentBold}>ATA Chapter: </Text>
                                        <Text style={styles.content}>{` ${diagnosticsDataISE.ata}`}</Text>
                                    </View>

                                    {diagnosticsDataISE.potential_faults.length === 1
                                        ? <View style={styles.row}>
                                            <Text style={styles.contentBold}>Potential Faults: </Text>
                                            <Text style={styles.content}>{diagnosticsDataISE.potential_faults[0]}</Text>
                                        </View>
                                        : <View style={styles.section}>
                                            <Text style={styles.sectionHeader}>Potential Faults: </Text>
                                            {diagnosticsDataISE.potential_faults.map((el, index) => (
                                                <Text key={index} style={styles.content}>&#x2022; {el}</Text>
                                            ))}
                                        </View>
                                    }

                                    <View style={styles.row}>
                                        <Text style={styles.contentBold}>Detected In: </Text>
                                        <Text style={styles.content}>{diagnosticsDataISE.system_name}</Text>
                                    </View>
                                </View>
                                <View style={styles.sectionWithMargin}>
                                    <View style={styles.section}>
                                        <List
                                            header='Event Description:'
                                            array={diagnosticsDataISE.event_descriptions}
                                        />
                                    </View>

                                    <View style={styles.section}>
                                        <Text style={styles.sectionHeader}>Affected LRUs: </Text>
                                        <View style={styles.lruRow}>
                                            {diagnosticsDataISE.lrus_info.map((lru, index) => (
                                                <Text key={index} style={styles.content}>
                                                    {lru.lru_name}
                                                    {index === diagnosticsDataISE.lrus_info.length - 1 ? "" : " | "}
                                                </Text>
                                            ))}
                                        </View>
                                    </View>

                                    <View style={styles.section}>
                                        <List
                                            header='Recommendations:'
                                            array={diagnosticsDataISE.recommendations}
                                        />
                                    </View>
                                </View>
                            </>
                        }
                        {/* Flagged Parameters */}
                        <View style={diagnosticsDataISE && Object.keys(diagnosticsDataISE).length > 0 ? styles.section : styles.sectionWithMargin}>
                            <Text style={styles.sectionHeader}>Flagged Parameters</Text>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.ataTableHeader}>ATA</Text>
                                    <Text style={styles.tableHeader}>ATA Chapter</Text>
                                    <Text style={styles.tableHeader}>Description Parameter</Text>
                                </View>
                                {/* Add table rows here */}
                                {flaggedParameters.map((param, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <Text style={styles.ataTableCell}>{param.ata_chapter}</Text>
                                        <Text style={styles.tableCell}>{param.ata_description}</Text>
                                        <Text style={styles.tableCell}>{param.parameter_name}</Text>
                                    </View>
                                ))}
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.footer}>
                    <Link style={styles.footerLink} src={reportURL}>
                        <LinkIcon />
                        <Text style={styles.footerText}>
                            Link to the&nbsp;
                            <Text style={styles.footerBoldText}>In-Service Event</Text>
                            &nbsp;report in&nbsp;
                            <Text style={styles.footerBoldText}>Predictive Maintenance</Text>
                        </Text>
                    </Link>
                </View>
            </Page>
        </Document>
    );

    const handleButtonClick = () => {
        // Handle the button click event
        setGeneratePdf(true);
    };

    const downloadPdf = (url) => {
        // For testing purposes,
        // This opens the pdf in a new tab instead of downloading it
        // let tab = window.open();
        // tab.location.href = url;

        const link = document.createElement('a');
        link.href = url;
        link.download = 'In-Service_Event_Summary.pdf';
        link.click();
        setGeneratePdf(false);
    };

    const getURLByEnvironment = () => {
        switch (process.env.REACT_APP_ENVIRONMENT) {
            case "PREDICTIVE-QA":
                return "http://crc-app-predictive-qa-684253456.ca-central-1.elb.amazonaws.com/";
            case "PREDICTIVE-DEV":
                return "http://crc-app-predictive-dev-1218375377.ca-central-1.elb.amazonaws.com/";
            case "PREDICTIVE":
                return "https://myba.aircraftpredictive.bombardier.cloud/";
            case "PROD":
                return "https://myba.aircraft.bombardier.cloud/";
            case "PRE-PROD":
                return "http://myba.aircraft.preprod.bombardier.cloud/";
            case "DEV":
                return "https://myba.aircraft.dev.bombardier.cloud/";
            default:
                return "https://myba.aircraft.bombardier.cloud/";
        }
    };

    const reportURL = `${getURLByEnvironment()}predictivereports/${sessionKey}/${iseNumber}`;

    return (
        <React.Fragment>
            <button
                className="predictive__button darkNoBorderButton"
                onClick={() => handleButtonClick()}
            >
                Generate PDF
            </button>
            {generatePdf && (
                <BlobProvider document={
                    <MyDocument
                        flaggedParameters={summaryData.anomalies}
                        iseReport={iseReport}
                        flightReport={flightReport}
                        aircraftData={aircraftData}
                        diagnosticsDataISE={diagnosticsDataISE}
                        reportURL={reportURL} />
                }>
                    {({ blob, url, loading, error }) => {
                        // Automatically download the file when it's ready
                        if (url && !loading && !error) {
                            downloadPdf(url);
                        }
                        // Optionally, handle loading and error states here
                        if (loading) {
                            return <div>Generating PDF...</div>;
                        }
                        if (error) {
                            console.error(error);
                            return <div>Failed to generate PDF</div>;
                        }
                        return null;
                    }}
                </BlobProvider>
            )}
        </React.Fragment>
    )
};

export default Download;