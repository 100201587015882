export const FAULT_CODE = '000000';
export const termsOfUseLink = 'https://businessaircraft.bombardier.com/en/terms-use';
export const privacyStatementLink = 'https://businessaircraft.bombardier.com/en/privacy';
export const diagnosticsBucket = 'bba-operationalzone-predictive-maintenance-project';

export const contextualAHMSCharts = [
  {
    id: 0,
    chartTitle: "Altitude.R-RALT.10349216.13",
    parameters: [
      {
        parameterName: "Altitude.R-RALT.10349216.13",
        unitOfMeasure: "Feet (Ft)"
      }
    ]
  }, {
    id: 1,
    chartTitle: "Calibrated Airspeed.ADSP_2.8718232.12",
    parameters: [
      {
        parameterName: "Calibrated Airspeed.ADSP_2.8718232.12",
        unitOfMeasure: "Knot"
      }
    ]
  }, {
    id: 2,
    chartTitle: "Filtered Wheel Speed",
    parameters: [{
      parameterName: "Left_Filtered_Wheel_Speed.BCU_A.8273928.17",
      unitOfMeasure: "Knot"
    }, {
      parameterName: "Left_Filtered_Wheel_Speed.BCU_B.8273928.17",
      unitOfMeasure: "Knot"
    }, {
      parameterName: "Right_Filtered_Wheel_Speed.BCU_A.8273936.17",
      unitOfMeasure: "Knot"
    }, {
      parameterName: "Right_Filtered_Wheel_Speed.BCU_B.8273936.17",
      unitOfMeasure: "Knot"
    }]
  }
];

export const geoCoordsParams = [
  {
    name: "Longitude",
    potentialParams: [{
      parameterName: "Longitude.R-GPS.9160624.9",
      parameterEnglishName: "Longitude"
    }, {
      parameterName: "Longitude.L-GPS.9160624.9",
      parameterEnglishName: "Longitude"
    }]
  }, {
    name: "Latitude",
    potentialParams: [{
      parameterName: "Latitude.R-GPS.9160616.9",
      parameterEnglishName: "Latitude",
    }, {
      parameterName: "Latitude.L-GPS.9160616.9",
      parameterEnglishName: "Latitude",
    }]
  }
];

export const mapSidebarInfo = [
  {
    type: "icon",
    name: null
  }, {
    type: "param",
    name: "Flight Phase",
    unitOfMeasure: null,
    potentialParams: [{
      parameterName: "Der_Flight_Phase.FMF",
      parameterEnglishName: "Flight Phase"
    }]
  }, {
    type: "param",
    name: "Hdg/Track",
    unitOfMeasure: "°",
    potentialParams: [{
      parameterName: "Track Angle (True).R-GPS.9160576.14",
      parameterEnglishName: "Track Angle (True)"
    }, {
      parameterName: "Track Angle (True).L-GPS.9160576.14",
      parameterEnglishName: "Track Angle (True)"
    }]
  }, {
    type: "param",
    name: "Altitude",
    unitOfMeasure: "ft",
    potentialParams: [{
      parameterName: "Altitude.R-GPS.9160536.9",
      parameterEnglishName: "Altitude"
    }, {
      parameterName: "Altitude.L-GPS.9160536.9",
      parameterEnglishName: "Altitude"
    }]
  }, {
    type: "icon",
    name: "wind"
  }, {
    type: "param",
    name: "Indicated IAS",
    unitOfMeasure: "kts",
    potentialParams: [{
      parameterName: "Calibrated Airspeed.ADSP_1.8717720.12",
      parameterEnglishName: "Calibrated Airspeed"
    }, {
      parameterName: "Calibrated Airspeed.ADSP_2.8717720.12",
      parameterEnglishName: "Calibrated Airspeed"
    }]
  }, {
    type: "param",
    name: "Ground Speed",
    unitOfMeasure: "kts",
    potentialParams: [{
      parameterName: "Ground Speed.L-IRS.9052936.11",
      parameterEnglishName: "Ground Speed"
    },
    {
      parameterName: "Ground Speed.R-IRS.9052936.11",
      parameterEnglishName: "Ground Speed"
    }]
  }, {
    type: "icon",
    name: "cloud"
  }, {
    type: "param",
    name: "Temperature",
    unitOfMeasure: "° C",
    potentialParams: [{
      parameterName: "Total Air Temperature.ADSP_3.8718256.11",
      parameterEnglishName: "Total Air Temperature",
    }]
  }, {
    type: "param",
    name: "Wind",
    unitOfMeasure: "kts",
    parameters: [{
      name: "Speed",
      potentialParams: [{
        parameterName: "Wind Speed.L-IRS.9052960.11",
        parameterEnglishName: "Wind Speed"
      },
      {
        parameterName: "Wind Speed.R-IRS.9052960.11",
        parameterEnglishName: "Wind Speed"
      }]
    }, {
      name: "Direction",
      potentialParams: [{
        parameterName: "True Wind Direction.R-IRS.9052968.11",
        parameterEnglishName: "True Wind Direction"
      },
      {
        parameterName: "True Wind Direction.L-IRS.9052968.11",
        parameterEnglishName: "True Wind Direction"
      }]
    }]
  }
];

export const statuses = {
  0: 'NEW',
  1: 'OPEN',
  2: 'CLOSED',
  3: 'ACTIVE',
  4: 'ARCHIVED',
  5: 'ARCHIVED_REPORTS'
};

export const columnsFromBackend = {
  '0': {
    name: "NEW",
    items: []
  },
  '1': {
    name: "OPEN",
    items: []
  },
  '2': {
    name: "CLOSED",
    items: []
  },
};

export const predictiveViewTabs = [
  {
    title: "Reports",
    icon: "clipboard",
    deactivated: false,
    status: '3'
  },
  {
    title: "Archives",
    icon: "archive",
    deactivated: false,
    status: '4'
  }
];

export const prognosticsTabs = [
  {
    title: "Prognostics",
    icon: "trending-up",
    deactivated: false
  },
  {
    title: "ISE Reports",
    icon: "clipboard",
    deactivated: false
  }
];

export const anomalyTabsForInternalUsers = [
  {
    title: "Summary",
    icon: "file",
    deactivated: false,
  },
  {
    title: "Visualization",
    icon: "bar-chart-2",
    deactivated: false,
  },
  {
    title: "Diagnostics",
    icon: "eye",
    deactivated: false,
  },
  // {
  //   title: "Prognostics",
  //   icon: "trending-up",
  //   deactivated: true,
  // },
  // {
  //   title: "Review",
  //   icon: "check-square",
  //   deactivated: false,
  // },
  {
    title: "Restore",
    icon: "rotate-ccw",
    deactivated: false
  }
];

export const anomalyTabsForExternalUsers = [
  {
    title: "Summary",
    icon: "file",
    deactivated: false,
  },
  {
    title: "Visualization",
    icon: "bar-chart-2",
    deactivated: false,
  },
  {
    title: "Diagnostics",
    icon: "eye",
    deactivated: true,
  },
  {
    title: "Prognostics",
    icon: "trending-up",
    deactivated: true,
  },
  {
    title: "Restore",
    icon: "rotate-ccw",
    deactivated: false
  }
];

export const personalTemplatesColumns = [
  {
    title: "Template Name",
    field: "templateName",
    placeholder: "Template Name",
    width: "17%",
  },
  {
    title: "Description",
    field: "notes",
    placeholder: "Template Description",
    width: "30%",
  },
  {
    title: "ATA",
    field: "ataChapter",
    placeholder: "ATA",
    width: "9%",
  },
  {
    title: "Created by",
    field: "createdBy",
    placeholder: "Created By",
    width: "17%",
  }, {
    title: "",

  },
  {
    title: "Edit",
    width: "9%",
  },
  {
    title: "Load",
    width: "9%",
  },
  {
    title: "More",
    width: "9%",
  },
];

export const sharedTemplatesColumns = [
  {
    title: "Template Name",
    field: "templateName",
    placeholder: "Template Name",
    width: "17%",
  },
  {
    title: "Description",
    field: "notes",
    placeholder: "Template Description",
    width: "30%",
  },
  {
    title: "ATA",
    field: "ataChapter",
    placeholder: "ATA",
    width: "9%",
  },
  {
    title: "Created by",
    field: "createdBy",
    placeholder: "Created By",
    width: "17%",
  }, {
    title: "",

  },
  {
    title: "View",
    width: "13%",
  },
  {
    title: "Load",
    width: "13%",
  },
  {
    title: "Copy",
    width: "13%",
  },
];

export const parameterSelectionColumns = [
  {
    accessorKey: 'ata_chapter',
    header: 'ATA',
    size: "100"
  },
  {
    accessorKey: 'ata_description',
    header: 'Description',
    size: "200"
  },
  {
    accessorKey: 'parameter_name',
    header: 'Parameter Name (long)'
  }
]


export const summaryColumns = [
  {
    accessorKey: "ata_chapter",
    header: "ATA Chapter",
    size: "100"
  },
  {
    accessorKey: "ata_description",
    header: "ATA Chapter Description",
    size: "200"
  },
  {
    accessorKey: "parameter_name",
    header: "Parameter"
  }
];

export const diagnosticsColumns = [
  {
    header: "LRU Name",
    accessorKey: "lruName",
    size: "200"
  },
  {
    header: "LRU Score",
    accessorKey: "value",
    size: "50"
  },
  {
    header: "Part Number",
    accessorKey: "partNumber"
  },
];

export const prognosticsColumns = [
  {
    id: "name",
    title: "LRU Name"
  },
  {
    id: "partNumber",
    title: "Part Number"
  },
  {
    id: "score",
    title: "Health Score",
    align: "right"
  }
];

export const dropdownFields = [{
  answerFamily: "Dropdown list #1",
  name: "parameter",
  fieldId: "parameter",
  field: "parameter",
  placeholder: "Parameters List"
}, {
  answerFamily: "Dropdown list #3",
  name: "lru",
  fieldId: "partnumber",
  field: "lruname",
  placeholder: "LRU List"
}, {
  answerFamily: "Dropdown list #2",
  name: "fm",
  fieldId: "description",
  field: "description",
  placeholder: "FM List"
}];

export const templateForReview = [
  {
    question_id: "-1",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "0",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "1",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "2",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "3",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "4",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "5",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "6",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "7",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "8A",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "8B",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "9A",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "10",
    answer_text: "",
    answer_ids: ""
  }
  , {
    question_id: "11",
    answer_text: "",
    answer_ids: ""
  },
  {
    question_id: "12",
    answer_text: "",
    answer_ids: ""
  }

];

export const prognosticsRes = {};

export const CHARTS = {
  CHART_TYPES: {
    COLLINEAR: "collinear",
    ADDITIONAL: "additional",
    CONTINUOUS: "continuous",
    CONTEXTUAL: "contextual",
    OVERVIEW: "overview",
    RANGE_AREA: "rangeArea",
    LINE: "line",
    TEMPLATE: "template"
  },
  UNIT: {
    DISCRETE: "Discrete"
  },
  MAXIMUM_NUMBER_OF_SERIES: 10,
  SERIES: {
    RANGE: "range",
    EXPECTED: "Expected",
  }
};

export const constantFilterOptions = [
  {
    title: "Report Status",
    id: "status",
    options: [{
      title: "New",
      id: "NEW"
    }, {
      title: "Open",
      id: "OPEN"
    }, {
      title: "Closed",
      id: "CLOSED"
    }, {
      title: "Reviewed",
      id: "is_reviewed"
    }],
    isCollapsed: true
  }
];

