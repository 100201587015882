import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";

import "./SearchBox.css";

const SearchBox = (props) => {
    const {
        handleSearch,
        placeholder = "Search",
        width = "100%",
    } = props;
    const [searchText, setSearchText] = useState("");
    const handleOnEnterKey = (e) => {
        const searchText = e?.target?.value ? e.target.value : "";

        setSearchText(searchText);
        handleSearch(searchText);
    };

    const clearText = () => {
        console.log("clearText() is called");
        const searchText = "";
        setSearchText(searchText);
        handleSearch(searchText);
    };

    return (
        <div
            className="searchContainer"
            style={{ width: width }}
            data-testid="search_container"
        >
            <input
                className="searchContainer__input"
                onChange={(e) => setSearchText(e?.target?.value ? e.target.value : "")}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        handleOnEnterKey(e);
                    }
                }}
                placeholder={placeholder}
                value={searchText}
                data-testid="input_form"
            />

            {searchText === ""
                ? ( <FeatherIcon
                    width={25}
                    height={25}
                    icon={"search"}
                    style={{ color: "D2D2D2" }}
                    data-testid="search-icon"
                /> )
                : (<FeatherIcon
                    width={25}
                    height={25}
                    icon={"x"}
                    style={{ color: "#909090", cursor: "pointer" }}
                    onClick={() => clearText()}
                    data-testid="clear-icon"
                />)
            }
        </div>
    )
};

export default SearchBox;
