import React from "react";
import "./FullChart.css";
import FeatherIcon from "feather-icons-react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { getFullSizeLineChartOptions } from "../Graph Components/Chart Options/FullSizeLineChart";

export default function FullChart(props) {
  const [isOpen, setIsOpen] = React.useState(false);

  let cOptions = getFullSizeLineChartOptions(props.faultTime, props.modalId);
  cOptions.chart.height = "500px";
  cOptions.chart.toolbar.offsetX = "-10";

  const [options] = React.useState(cOptions);
  const [itemState, setItemState] = React.useState([
    {
      state: true,
      id: "legend_item_1",
    },
    {
      state: true,
      id: "legend_item_2",
    },
    {
      state: true,
      id: "legend_item_3",
    },
    {
      state: true,
      id: "legend_item_4",
    },
    {
      state: true,
      id: "legend_item_5",
    },
    {
      state: true,
      id: "legend_item_6",
    },
    {
      state: true,
      id: "legend_item_7",
    },
    {
      state: true,
      id: "legend_item_8",
    },
    {
      state: true,
      id: "legend_item_9",
    },
    {
      state: true,
      id: "legend_item_10",
    },
  ]);

  // method to open modal
  const openModal = async () => {
    let modal = document.getElementById(props.modalId);
    setIsOpen(true);
    modal.style.display = "block";
  };

  // method to close modal
  const closeModal = () => {
    setIsOpen(false);
    let modal = document.getElementById(props.modalId);
    modal.style.display = "none";
  };

  const toggleParameter = (index) => {
    ApexCharts.exec(props.modalId, "toggleSeries", props.series[index].name);
    if (itemState[index].state) {
      itemState[index].state = false;
      document.getElementById(itemState[index].id).style.backgroundColor =
        "#000000";
    } else {
      itemState[index].state = true;
      document.getElementById(itemState[index].id).style.backgroundColor =
        options.colors[index];
    }
    setItemState(itemState);
  };

  return (
    <div className="fs__modal__btn__placement">
      <div className="fs__open__modal__btn">
        <FeatherIcon icon="maximize" height={30} onClick={openModal} />
      </div>
      <div id={props.modalId} className="fs__chart__modal">
        {isOpen ? (
          <div>
            <div className="fs__modal_close_section">
              <div className="fs__modal__close__icon__ctnr">
                <FeatherIcon
                  icon="x"
                  height={40}
                  width={40}
                  onClick={closeModal}
                />
              </div>
            </div>
            <div className="fs__modal__header">
              <div className="fs__modal__chart__title">{props.chartName}</div>
            </div>
            <div className="fs__modal__chart__padding__top"></div>
            <div className="fs__modal__chart_ctnr">
              <ReactApexChart
                options={options}
                series={props.series}
                type="line"
                height="500px"
              />
            </div>
            <div className="fs__modal__chart_legend_ctnr">
              <div className="fs__legend__row">
                <div className="fs__legend__column">
                  <div
                    className="fs__legend__item"
                    style={{ backgroundColor: "#101010" }}
                  >
                    <div className="fs_legend_item_col fs__col__60">
                      Parameter
                    </div>
                    <div className="fs_legend_item_col fs__col__20">Units</div>
                    <div className="fs_legend_item_col fs__col__15">LRU</div>
                    <div className="fs_legend_item_col fs__col__5">
                      <FeatherIcon icon="eye" height={20} width={20} />
                    </div>
                  </div>
                </div>
                <div className="fs__legend__column">
                  <div
                    className="fs__legend__item"
                    style={{ backgroundColor: "#101010" }}
                  >
                    <div className="fs_legend_item_col fs__col__60">
                      Parameter
                    </div>
                    <div className="fs_legend_item_col fs__col__20">Units</div>
                    <div className="fs_legend_item_col fs__col__15">LRU</div>
                    <div className="fs_legend_item_col fs__col__5">
                      <FeatherIcon icon="eye" height={20} width={20} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="fs__legend__spacer"></div>
              <div className="fs__legend__row">
                <div className="fs__legend__column">
                  {props.series[0] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[0].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[0].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[0].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[0].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(0);
                          }}
                          style={{ backgroundColor: options.colors[0] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="fs__legend__column">
                  {props.series[1] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[1].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[1].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[1].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[1].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(1);
                          }}
                          style={{ backgroundColor: options.colors[1] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="fs__legend__row">
                <div className="fs__legend__column">
                  {props.series[2] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[2].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[2].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[2].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[2].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(2);
                          }}
                          style={{ backgroundColor: options.colors[2] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="fs__legend__column">
                  {props.series[3] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[3].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[3].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[3].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[3].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(3);
                          }}
                          style={{ backgroundColor: options.colors[3] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="fs__legend__row">
                <div className="fs__legend__column">
                  {props.series[4] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[4].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[4].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[4].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[4].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(4);
                          }}
                          style={{ backgroundColor: options.colors[4] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="fs__legend__column">
                  {props.series[5] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[5].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[5].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[5].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[5].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(5);
                          }}
                          style={{ backgroundColor: options.colors[5] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="fs__legend__row">
                <div className="fs__legend__column">
                  {props.series[6] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[6].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[6].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[6].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[6].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(6);
                          }}
                          style={{ backgroundColor: options.colors[6] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="fs__legend__column">
                  {props.series[7] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[7].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[7].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[7].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[7].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(7);
                          }}
                          style={{ backgroundColor: options.colors[7] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="fs__legend__row">
                <div className="fs__legend__column">
                  {props.series[8] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[8].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[8].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[8].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[8].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(8);
                          }}
                          style={{ backgroundColor: options.colors[8] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="fs__legend__column">
                  {props.series[9] ? (
                    <div className="fs__legend__item">
                      <div className="fs_legend_item_col fs__col__60">
                        {props.series[9].name}
                      </div>
                      <div className="fs_legend_item_col fs__col__20">
                        {props.series[9].unit}
                      </div>
                      <div className="fs_legend_item_col fs__col__15">
                        {props.series[9].lru}
                      </div>
                      <div className="fs_legend_item_col fs__col__5">
                        <div
                          id={itemState[9].id}
                          className="fs__legend__item__toggle"
                          onClick={() => {
                            toggleParameter(9);
                          }}
                          style={{ backgroundColor: options.colors[9] }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
