import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as tools from "../utils/CommonTools";
import Banner from "../Global Components/Banner";
import Spinner from "../Global Components/Spinner.js";
import "../styles/Global Components/Button.css";
import "../styles/Global Components/LoaderContainer.css";
import {
  getDocLink,
  getUserManuals,
  // TODO: Links are not yet enanbled
  // getLinks,
  // TODO: Videos are not yet enanbled
  // getVideoTutorials
} from "../clients/GuideViewClient.js";
import {
  getProgress,
  updateProgress,
} from "../clients/ApplicationOnboardingClient";
import GuidesPDF from "./GuidesPDF";
// TODO: Links are not yet enanbled
// import GuidesLink from "./GuidesLink.jsx";
// TODO: Videos are not yet enanbled
// import GuidesVideo from "./GuidesVideo.jsx";
import PDFReader from "../Global Components/PDFReader";
import Avatar from "@mui/material/Avatar";
import Switch from "@mui/material/Switch";
import FeatherIcon from "feather-icons-react";

import "./GuidesView.css";

/**
 * Main view component for user manual, guides, videos, etc.
 */
export default function GuidesView(props) {
  const [documentTypeTitle] = useState([
    "User Manuals",
    "Hints & Tips",
    "Video Tutorials",
  ]);

  // Page load state variable is set to true on initial load.
  const [loadingManuals, setLoadingManuals] = useState(true);
  const [userManuals, setUserManuals] = useState([]);
  const [userLinks, setUserLinks] = useState([]);
  const [userVideos, setUserVideos] = useState([]);
  // File parameters
  const [pageViewType, setPageViewType] = useState("default");
  const [currFile, setFile] = useState("");
  const [currDate, setDate] = useState("");
  const [currTitle, setTitle] = useState("");
  const [currDocType, setDocType] = useState("");
  const [
    interactiveOnboardingGuideProgress,
    setInteractiveOnboardingGuideProgress,
  ] = useState([]);
  const [
    interactiveOnboardingSwitchValue,
    setInteractiveOnboardingSwitchValue,
  ] = useState(false);
  const [
    loadingInteractiveOnboardingProcess,
    setLoadingInteractiveOnboardingProcess,
  ] = useState(false);

  const interactiveOnboardingProCount =
    Object.keys(interactiveOnboardingGuideProgress).length !== 0 &&
    interactiveOnboardingGuideProgress.appPages.reduce((cont, page) => {
      if (page.completionStatus) {
        cont++;
      }
      return cont;
    }, 0);

  const interactiveOnboardingProcessCompleted =
    interactiveOnboardingProCount === 4; // number of app pages

  /**
   * Loads all the manuals, links and guides.
   * @return {Promise<void>}
   */
  const loadAllManuals = () => {
    // API call to get all user manual
    getUserManuals()
      .then((response) => {
        if (response.data.documentList) {
          setUserManuals(response.data.documentList);
          setLoadingManuals(false);
        }
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });

    // API call to get all user manual
    // TODO: Links are not yet enanbled
    setUserLinks([]);

    // API call to get all user manual
    // TODO: Videos are not yet enanbled
    setUserVideos([]);
  };

  /**
   * Loads the user progress of all the application onboarding guides.
   * @return {Promise<void>}
   */
  const loadInteractiveOnboardingGuideProgress = () => {
    getProgress()
      .then((response) => {
        if (response) {
          setInteractiveOnboardingGuideProgress(response[0]);
        }
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  };

  // Inital page load
  useEffect(() => {
    loadAllManuals();
  }, []);

  // Loads when the interactive onboarding guide progress is updated
  useEffect(() => {
    loadInteractiveOnboardingGuideProgress();

    if (interactiveOnboardingProcessCompleted) {
      setInteractiveOnboardingSwitchValue(true);
    }
  }, [loadingInteractiveOnboardingProcess]);

  function compareDocs(a, b) {
    if (a.docHelpTitle < b.docHelpTitle) {
      return -1;
    }
    if (a.docHelpTitle > b.docHelpTitle) {
      return 1;
    }
    return 0;
  }

  /**
   * Calls the guide content API to load the content.
   * @param docHelpId Document/Guide ID.
   * @return {Promise<void>}
   */
  const loadDoc = (docHelpId) => {
    getDocLink(docHelpId)
      .then((response) => {
        if (response.data.contentDocumentLink) {
          setFile(response.data.contentDocumentLink);
        }
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  };

  /**
   * Handles the event for the swith toggle that enables and disables the
   * application interactive onboarding guide.
   * @return {EventHandler}
   */
  const handleSwitchChange = async () => {
    setInteractiveOnboardingSwitchValue(!interactiveOnboardingSwitchValue);
    setLoadingInteractiveOnboardingProcess(true);

    await Promise.allSettled([
      updateProgress({
        iofgKey: interactiveOnboardingGuideProgress.iofgKey,
        pageKey: "AIRCRAFT_V_PG",
        completionStatus: false,
      }),
      updateProgress({
        iofgKey: interactiveOnboardingGuideProgress.iofgKey,
        pageKey: "RPD_PG",
        completionStatus: false,
      }),
      updateProgress({
        iofgKey: interactiveOnboardingGuideProgress.iofgKey,
        pageKey: "FLEET_V_PG",
        completionStatus: false,
      }),
      updateProgress({
        iofgKey: interactiveOnboardingGuideProgress.iofgKey,
        pageKey: "PARAM_V_PG",
        completionStatus: false,
      }),
      updateProgress({
        iofgKey: interactiveOnboardingGuideProgress.iofgKey,
        pageKey: "CONF_PG",
        completionStatus: false,
      }),
    ]);

    setLoadingInteractiveOnboardingProcess(false);
  };

  /**
   * Returns loader HTML.
   */
  const loader = (
    <div
      style={{
        width: "100%",
        height: "100%",
        zIndex: "2000",
      }}
    >
      <div style={{ width: "100px", paddingTop: "10%", margin: "auto" }}>
        <Spinner />
      </div>
    </div>
  );

  /**
   * Sets the file parameters that will load and be viewed when a user clicks on a guide.
   * @param id Document/Guide ID.
   * @param view UI view type: PDF, video, link, etc.
   * @param date Date the file/guide was modified.
   * @param title Title of the document/guide.
   * @param docType Document/guide type title.
   * @return {Promise<void>}
   */
  const setFileParams = (id, view, date, title, docType) => {
    loadDoc(id);
    setPageViewType(view);
    setDate(date);
    setTitle(title);
    setDocType(docType);
  };

  /**
   * Closes the document/guide view mode and cleans up all parameter values.
   * @return {Promise<void>}
   */
  const closeViewer = () => {
    setPageViewType("default");
    setFile("");
    setDate("");
    setTitle("");
    setDocType("");
  };

  /**
   * Returns the HTML that handles the guide content section
   * and the interactive onboarding guide section of the page.
   * @return {HTML}
   */
  const getGuidesContent = () => {
    return (
      <div className="guidesView__contentBody">
        <div className="guidesView__leftContainer">
          <div className="guidesView__leftContainer__titleWrapper">
            {documentTypeTitle[0]}
          </div>
          <div className="guides__separator__in"></div>
          {loadingManuals ? (
            loader
          ) : userManuals.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              {"There are no available" +
                documentTypeTitle[0].toLowerCase +
                "at the moment."}
              <br></br>
              Please check back later.
            </div>
          ) : (
            <div className="guides_section">
              {userManuals.sort(compareDocs).map((manual) => {
                return (
                  <React.Fragment key={manual.docHelpId}>
                    <GuidesPDF
                      id={manual.docHelpId}
                      title={manual.docHelpTitle}
                      date={tools
                        .formatDate(manual.lastModifiedDate)
                        .substring(0, 13)}
                      onClick={() =>
                        setFileParams(
                          manual.docHelpId,
                          "pdf",
                          tools
                            .formatDate(manual.lastModifiedDate)
                            .substring(0, 13),
                          manual.docHelpTitle,
                          documentTypeTitle[0]
                        )
                      }
                    />
                  </React.Fragment>
                );
              })}
            </div>
          )}

          {/* TODO: To be implemented in the future */}
          {/* <div className="guidesView__leftContainer__titleWrapper">
            {documentTypeTitle[1]}
          </div>
          <div className="guides__separator__in"></div>
          {loadingManuals ?
            loader :
            userLinks.length === 0 ?
            <div style={{ textAlign: "center" }}>
              {"There are no available" +
                documentTypeTitle[1].toLowerCase +
                "at the moment."}
              <br></br>
              Please check back later.
            </div>
            :
            <div className="guides_section">
              {userLinks.sort(compareDocs).map((manual) => {
                return (
                  <React.Fragment key={manual.docHelpId}>
                    <GuidesLink
                      id={manual.docHelpId}
                      title={manual.docHelpTitle}
                      description={manual.docHelpDescription}
                      date={tools.formatDate(manual.lastModifiedDate).substring(0, 13)}
                      url={manual.docHelpContentLink}
                      onClick={() => setFileParams(
                        manual.docHelpId,
                        "link",
                        tools.formatDate(manual.lastModifiedDate).substring(0, 13),
                        manual.docHelpTitle,
                        manual.createdByUser,
                        documentTypeTitle[1],
                      )}
                    />
                  </React.Fragment>
                )
              })}
            </div>
          } */}
        </div>
        <div className="guidesView__rightContainer">
          <div className="guidesView__leftContainer__titleWrapper">
            Onboarding
          </div>
          <div className="guides__separator__in"></div>
          <div className="guides__onboardingSection">
            {loadingInteractiveOnboardingProcess ? (
              <Spinner />
            ) : (
              <>
                <div className="guides__onboardingSection__progress">
                  <div
                    className={
                      interactiveOnboardingProcessCompleted
                        ? "guides__onboardingSection__progress__content__completed"
                        : "guides__onboardingSection__progress__content"
                    }
                  >
                    {interactiveOnboardingProcessCompleted ? (
                      <Avatar sx={{ bgcolor: "#D19000", color: "#ffffff" }}>
                        <FeatherIcon icon="check" />
                      </Avatar>
                    ) : (
                      <Avatar
                        sx={{
                          color: "#A7A7A7",
                          bgcolor: "#1f1e1e",
                          border: 3,
                          borderColor: "#A7A7A7",
                        }}
                      >
                        {interactiveOnboardingProCount}
                      </Avatar>
                    )}
                  </div>
                  {interactiveOnboardingProcessCompleted ? (
                    <span className="guides__onboardingSection__progress__label__completed">
                      Completed
                    </span>
                  ) : (
                    <span className="guides__onboardingSection__progress__label">
                      In Progress
                    </span>
                  )}
                </div>
                <div className="guides__onboardingSection__switch">
                  <span className="guides__onboardingSection__switch__label1">
                    {interactiveOnboardingProcessCompleted
                      ? "Reenable Onboarding process?"
                      : "Disable Onboarding process?"}
                  </span>
                  <Switch
                    checked={interactiveOnboardingSwitchValue}
                    onChange={handleSwitchChange}
                    name="onboardingSwitch"
                    sx={{
                      " .MuiSwitch-track": {
                        backgroundColor: "#ffffff",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#D19000",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "#ffffff",
                        },
                    }}
                  />
                  <span className="guides__onboardingSection__switch__label2">
                    {interactiveOnboardingProcessCompleted
                      ? "Disabled"
                      : "Reenabled"}
                  </span>
                </div>
              </>
            )}
            {/* TODO: To be implemented in the future */}
            {/* <div className="guides__onboardingSection__progress">
              <div
                className={
                  interactiveOnboardingProcessCompleted
                    ? "guides__onboardingSection__progress__content__completed"
                    : "guides__onboardingSection__progress__content"
                }
              >
                {interactiveOnboardingProcessCompleted ? (
                  <Avatar >
                    <FeatherIcon icon="check" />
                  </Avatar>
                ) : (
                  <Avatar >
                    {interactiveOnboardingProCount}
                  </Avatar>
                )}
              </div>
              {interactiveOnboardingProcessCompleted ? (
                <span className="guides__onboardingSection__progress__label__completed">
                  Completed
                </span>
              ) : (
                <span className="guides__onboardingSection__progress__label">
                  In Progress
                </span>
              )}
            </div>
            <div className="guides__onboardingSection__switch">
              <span className="guides__onboardingSection__switch__label1">
                {interactiveOnboardingProcessCompleted
                  ? "Reenable Onboarding process?"
                  : "Disable Onboarding process?"}
              </span>
              <CustomSwitch
                checked={interactiveOnboardingSwitchValue}
                onChange={handleSwitchChange}
                name="onboardingSwitch"
              />
              <span className="guides__onboardingSection__switch__label2">
                {interactiveOnboardingProcessCompleted ? "Disabled" : "Reenabled"}
              </span>
            </div> */}
          </div>
        </div>

        {/* TODO: To be implemented in the future */}
        {/* <div className="guidesView__rightContainer">
          <div className="guidesView__leftContainer__titleWrapper">
            {documentTypeTitle[2]}
          </div>
          <div className="guides__separator__in"></div>
          <div className="guides_videoSection">
            {loadingManuals ?
            loader :
              userVideos.length === 0 ?
              <div style={{ textAlign: "center" }}>
                {"There are no available" +
                documentTypeTitle[2].toLowerCase +
                "at the moment."}
                <br></br>
                Please check back later.
              </div>
              :
              userVideos.sort(compareDocs).map((manual) => {
                return (
                  <React.Fragment key={manual.docHelpId}>
                    <GuidesVideo
                      id={manual.docHelpId}
                      title={manual.docHelpTitle}
                      description={manual.docHelpDescription}
                      date={manual.lastModifiedDate}
                      url={tools.formatDate(manual.lastModifiedDate).substring(0, 13)}
                      thumbnail={manual.docHelpThumbnailImage}
                      onClick={() => setFileParams(
                        manual.docHelpContentLink,
                        "video",
                        tools.formatDate(manual.lastModifiedDate).substring(0, 13),
                        manual.docHelpTitle,
                        manual.createdByUser,
                        documentTypeTitle[2],
                      )}
                    />
                  </React.Fragment>
                )
              })
            }
          </div>
        </div> */}
      </div>
    );
  };

  /**
   * Returns the main HTML of the page.
   * @return {HTML}
   */
  return (
    <div className="guidesView">
      <div className="guidesView__content">
        <Banner
          pageType={"Guides & References"}
          logoText={"BOMBARDIER"}
          icon={"book-open"}
        />
        {pageViewType === "pdf" ? (
          <PDFReader
            file={currFile}
            date={currDate}
            title={currTitle}
            docType={currDocType}
            onClose={closeViewer}
          />
        ) : pageViewType === "link" ? (
          <div></div>
        ) : pageViewType === "video" ? (
          <div></div>
        ) : (
          getGuidesContent()
        )}
      </div>
    </div>
  );
}
