import React from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import * as tools from "../utils/CommonTools";

export const Dropdown = ({
  options,
  defaultValue,
  onChange,
  customWidth,
  templateStyle,
  label,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: templateStyle ? "rgb(16, 16, 16)" : "#303030",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      width: "1.5px",
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "white",
      "&:hover": {
        color: "black",
      },
      "&:active": {
        color: "black",
      },
      width: "43px",
      height: "43px",
      padding: "12px",
    }),

    container: (base, state) => ({
      ...base,
      width: customWidth ? customWidth : "100px",
      height: "45px",
      borderColor: "white",
      //borderRadius: "2px",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: templateStyle ? "5px" : "0px",
    }),
    menu: (base, state) => ({
      ...base,
      backgroundColor: templateStyle ? "rgb(16, 16, 16)" : "#303030",
      color: "white",
      zIndex: 1000
    }),
    placeholder: (base, state) => ({
      ...base,
      color: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: templateStyle ? "rgb(16, 16, 16)" : "#303030",
      borderBottom: "1px solid white",
      borderLeft: "1px solid white",
      borderRight: "1px solid white",
      color: state.isFocused ? "white" : "", //empty since it will depend on the theme (light: color will be white)
      "&:hover": {
        color: "black",
        backgroundColor: "white",
      },
      "&:active": {
        color: "black",
        backgroundColor: "white",
      },
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#FFFFFF",
    }),
  };

  return (
    <div>
      {label && (
        <p
          style={{
            fontSize: "16px",
            color: "#FFFFFF",
            margin: "5px 0",
          }}
        >
          {label}
        </p>
      )}
      <Select
        classNamePrefix="fleetview__dropdown"
        styles={customStyles}
        options={options.sort().map((t) => ({ value: t, label: t }))}
        defaultValue={
          defaultValue
            ? { label: defaultValue, value: defaultValue }
            : { label: "Filter by Aircraft Model", value: 0 }
        }
        onChange={onChange}
      />
    </div>
  );
};

export const TemplateDropdown = ({
  options,
  defaultValue,
  onChange,
  customWidth,
  templateStyle,
  label,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: templateStyle ? "rgb(16, 16, 16)" : "#303030",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      width: "1.5px",
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "white",
      "&:hover": {
        color: "black",
      },
      "&:active": {
        color: "black",
      },
      width: "43px",
      height: "43px",
      padding: "12px",
    }),

    container: (base, state) => ({
      ...base,
      width: customWidth ? customWidth : "100px",
      height: "45px",
      borderColor: "white",
      //borderRadius: "2px",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: templateStyle ? "5px" : "0px",
    }),
    menu: (base, state) => ({
      ...base,
      backgroundColor: templateStyle ? "rgb(16, 16, 16)" : "#303030",
      color: "white",
    }),
    placeholder: (base, state) => ({
      ...base,
      color: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: templateStyle ? "rgb(16, 16, 16)" : "#303030",
      borderBottom: "1px solid white",
      borderLeft: "1px solid white",
      borderRight: "1px solid white",
      color: state.isFocused ? "white" : "", //empty since it will depend on the theme (light: color will be white)
      "&:hover": {
        color: "black",
        backgroundColor: "white",
      },
      "&:active": {
        color: "black",
        backgroundColor: "white",
      },
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#FFFFFF",
    }),
  };

  return (
    <div>
      <p
        style={{
          fontSize: "16px",
          color: "#FFFFFF",
          margin: "5px 0",
        }}
      >
        {label}
      </p>
      <Select
        value={defaultValue}
        options={options}
        onChange={onChange}
        styles={customStyles}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {e.icon}
            <span style={{ marginLeft: 10, fontSize: 14 }}>{e.text}</span>
          </div>
        )}
      />
    </div>
  );
};

export const DropdownTemplateVersion = ({
  options,
  defaultVersion,
  rowId,
  onChange,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#303030",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
      minHeight: "none",
      height: "28px",
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      width: "1.5px",
      height: "28px",
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "white",
      "&:hover": {
        color: "black",
      },
      "&:active": {
        color: "black",
      },
      width: "33px",
      height: "30px",
      padding: "5.5px",
    }),

    container: (base, state) => ({
      ...base,
      width: "70px",
      height: "30px",
      borderColor: "white",
      //borderRadius: "2px",
      borderStyle: "solid",
      borderWidth: "1px",
      flex: "1",
    }),
    menu: (base, state) => ({
      ...base,
      backgroundColor: "#303030",
      color: "white",
    }),
    placeholder: (base, state) => ({
      ...base,
      color: "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: "#303030",
      color: state.isFocused ? "white" : "", //empty since it will depend on the theme (light: color will be white)
      "&:hover": {
        color: "black",
        backgroundColor: "white",
      },
      "&:active": {
        color: "black",
        backgroundColor: "white",
      },
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#FFFFFF",
      padding: "2px 3px",
    }),
  };
  // var a = ''+rowId
  // const tempOptions = [
  //     { value: a , label: '3'},
  //     { value: a, label: '2'},
  //     { value: a, label: '1'}

  //   ]

  // const generateOptions = (upperLimit) =>{
  //   let tempOp = []
  //   for(let i=upperLimit; i > 0; i--){
  //     tempOp.push({value: ''+rowId, label: i})
  //   }
  //   return tempOp
  // }
  // const tempoptions = [
  //   { value: 1, label: '3' },
  //   { value: 1, label: '2' },
  //   { value: 1, label: '1' }
  // ]

  return (
    <div>
      <Select
        maxMenuHeight={200}
        styles={customStyles}
        options={options}
        defaultValue={
          defaultVersion
            ? { value: rowId, label: defaultVersion }
            : { label: -1, value: "N/A" }
        }
        onChange={onChange}
      />
    </div>
  );
};

export const DropdownListFilterVersion = ({
  options,
  defaultValue,
  onChange,
  width,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      width: "1.5px",
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "#979797",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      width: "28px",
      height: "100%",
      padding: "8px 5px 8px 5px",
    }),

    container: (base, state) => ({
      ...base,
      width: width ? width : "100%",
      height: "100%",
      borderColor: "#d2d2d2",
      borderStyle: "solid",
      borderWidth: "1px",
    }),
    menu: (base, state) => ({
      ...base,
      backgroundColor: "#505050",
      color: "white",
      width: width ? (width * 1.2).toString() + "px" : "100%",
    }),
    placeholder: (base, state) => ({
      ...base,
      color: "white",
    }),
    option: (base, state) => ({
      ...base,
      width: width ? (width * 1.2).toString() + "px" : "100%",
      backgroundColor: "#505050",
      color: state.isFocused ? "white" : "", //empty since it will depend on the theme (light: color will be white)
      "&:hover": {
        color: "black",
        backgroundColor: "white",
      },
      "&:active": {
        color: "black",
        backgroundColor: "white",
      },
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#FFFFFF",
    }),
  };

  return (
    <div>
      <Select
        maxMenuHeight={200}
        styles={customStyles}
        options={options.map((t) => ({ value: t, label: t }))}
        defaultValue={
          defaultValue
            ? { label: defaultValue, value: defaultValue }
            : { label: "Filter by Aircraft Model", value: 0 }
        }
        onChange={onChange}
      />
    </div>
  );
};

export const DropdownListTailFamilyModelVersion = ({
  options,
  defaultValue,
  onChange,
  width,
  selectedValue,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      display: "flex",
      flexFlow: "row",
      justifyContent: "flex-end",
      width: "100%",
      maxWidth: "",
      backgroundColor: "transparent",
      border: state.isFocused ? 0 : 0,
      color: "#ffffff",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "#979797",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      width: "35px",
      height: "100%",
      display: "flex",
      flexFlow: "row",
      justifyContent: "center",
      alignItems: "center",
    }),

    indicatorSeparator: (base, state) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      marginLeft: "10px",
      marginRight: "0px",
      width: "1px",
      borderColor: "#979797",
    }),

    container: (base, state) => ({
      ...base,
      display: "flex",
      width: "100%",
      height: "100%",
      color: "#ffffff",
    }),

    menu: (base, state) => ({
      ...base,
      backgroundColor: "#303030",
      color: "white",
      fontSize: "16px",
      lineHeight: "32px",
      letterSpacing: "0.56px",
      fontWeight: "normal",
      width: "calc(100% + 45px)",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
      marginLeft: "-45px",
    }),

    menuList: (base, state) => ({
      ...base,
      paddingTop: "0px",
      paddingBottom: "0px",
    }),

    placeholder: (base, state) => ({
      ...base,
      color: "#ffffff",
    }),

    option: (base, state) => ({
      ...base,
      width: "100%",
      backgroundColor: "#303030",
      color: state.isFocused ? "#ffffff" : "", //empty since it will depend on the theme (light: color will be white)
      fontSize: "16px",
      lineHeight: "32px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
      "&:hover": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
      "&:active": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
    }),

    singleValue: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "18px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      width: "100%",
    }),

    input: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "18px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
    }),
  };

  return (
    <div>
      <Select
        maxMenuHeight={200}
        styles={customStyles}
        options={options.map((o) => ({ value: o.value, label: o.label }))}
        value={{ label: selectedValue.label, value: selectedValue.value }}
        onChange={onChange}
      />
    </div>
  );
};

export const DropdownListFamilyEventCountVersion = ({
  options,
  onChange,
  selectedValue,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      display: "flex",
      flexFlow: "row",
      justifyContent: "flex-end",
      width: "100%",
      maxWidth: "",
      backgroundColor: "transparent",
      border: state.isFocused ? 0 : 0,
      color: "#ffffff",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "#979797",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      width: "35px",
      height: "100%",
      display: "flex",
      flexFlow: "row",
      justifyContent: "center",
      alignItems: "center",
    }),

    indicatorSeparator: (base, state) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      marginLeft: "10px",
      marginRight: "0px",
      width: "1px",
      borderColor: "#979797",
    }),

    container: (base, state) => ({
      ...base,
      display: "flex",
      width: "100%",
      height: "100%",
      color: "#ffffff",
    }),

    menu: (base, state) => ({
      ...base,
      backgroundColor: "#303030",
      color: "white",
      fontSize: "16px",
      lineHeight: "32px",
      letterSpacing: "0.56px",
      fontWeight: "normal",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
    }),

    menuList: (base, state) => ({
      ...base,
      paddingTop: "0px",
      paddingBottom: "0px",
    }),

    placeholder: (base, state) => ({
      ...base,
      color: "#ffffff",
    }),

    option: (base, state) => ({
      ...base,
      width: "100%",
      backgroundColor: "#303030",
      color: state.isFocused ? "#ffffff" : "", //empty since it will depend on the theme (light: color will be white)
      fontSize: "16px",
      lineHeight: "32px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
      "&:hover": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
      "&:active": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
    }),

    singleValue: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "16px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      width: "100%",
    }),

    input: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "18px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
    }),
  };

  return (
    <div>
      <Select
        maxMenuHeight={200}
        styles={customStyles}
        options={options.map((o) => ({ value: o.value, label: o.label }))}
        value={
          selectedValue && {
            label: selectedValue.label,
            value: selectedValue.value,
          }
        }
        onChange={onChange}
      />
    </div>
  );
};

export const DropdownEventTypeVersion = ({
  options,
  defaultValue,
  onChange,
  selectedValue,
}) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      display: "flex",
      flexFlow: "row",
      justifyContent: "flex-end",
      width: "100%",
      maxWidth: "",
      backgroundColor: "transparent",
      border: state.isFocused ? 0 : 0,
      color: "#ffffff",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "#979797",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      width: "35px",
      height: "100%",
      display: "flex",
      flexFlow: "row",
      justifyContent: "center",
      alignItems: "center",
    }),

    indicatorSeparator: (base, state) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      marginLeft: "10px",
      marginRight: "0px",
      width: "1px",
      borderColor: "#979797",
    }),

    container: (base, state) => ({
      ...base,
      display: "flex",
      width: "100%",
      height: "100%",
      color: "#ffffff",
    }),

    menu: (base, state) => ({
      ...base,
      backgroundColor: "#303030",
      color: "white",
      fontSize: "16px",
      lineHeight: "32px",
      letterSpacing: "0.56px",
      fontWeight: "normal",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
    }),

    menuList: (base, state) => ({
      ...base,
      paddingTop: "0px",
      paddingBottom: "0px",
    }),

    placeholder: (base, state) => ({
      ...base,
      color: "#ffffff",
    }),

    option: (base, state) => ({
      ...base,
      width: "100%",
      backgroundColor: "#303030",
      color: state.isFocused ? "#ffffff" : "", //empty since it will depend on the theme (light: color will be white)
      fontSize: "15px",
      lineHeight: "32px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
      "&:hover": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
      "&:active": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
    }),

    singleValue: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "15px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      width: "100%",
    }),

    input: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "18px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
    }),
  };

  return (
    <div>
      <Select
        maxMenuHeight={200}
        styles={customStyles}
        options={
          options && options.map((o) => ({ value: o.value, label: o.label }))
        }
        value={{ label: selectedValue.label, value: selectedValue.value }}
        onChange={onChange}
      />
    </div>
  );
};

export const DropdownTailsVersion = ({ options, onChange, selectedValue }) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      display: "flex",
      flexFlow: "row",
      justifyContent: "flex-end",
      width: "100%",
      maxWidth: "",
      backgroundColor: "transparent",
      border: state.isFocused ? 0 : 0,
      color: "#ffffff",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "#979797",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      width: "35px",
      height: "100%",
      display: "flex",
      flexFlow: "row",
      justifyContent: "center",
      alignItems: "center",
    }),

    indicatorSeparator: (base, state) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      marginLeft: "10px",
      marginRight: "0px",
      width: "1px",
      borderColor: "#979797",
    }),

    container: (base, state) => ({
      ...base,
      display: "flex",
      width: "100%",
      height: "100%",
      color: "#ffffff",
    }),

    menu: (base, state) => ({
      ...base,
      backgroundColor: "#303030",
      color: "white",
      fontSize: "16px",
      lineHeight: "32px",
      letterSpacing: "0.56px",
      fontWeight: "normal",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
    }),

    menuList: (base, state) => ({
      ...base,
      paddingTop: "0px",
      paddingBottom: "0px",
    }),

    placeholder: (base, state) => ({
      ...base,
      color: "#ffffff",
    }),

    option: (base, state) => ({
      ...base,
      width: "100%",
      backgroundColor: "#303030",
      color: state.isFocused ? "#ffffff" : "", //empty since it will depend on the theme (light: color will be white)
      fontSize: "16px",
      lineHeight: "32px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
      "&:hover": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
      "&:active": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
    }),

    singleValue: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "16px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      width: "100%",
    }),

    input: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "18px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
    }),
  };

  return (
    <div>
      <Select
        maxMenuHeight={200}
        styles={customStyles}
        options={options}
        value={selectedValue}
        getOptionLabel={(option) =>
          option.serialNumber + " | " + option.registrationNumber
        }
        getOptionValue={(option) => option.registrationNumber}
        onChange={onChange}
        placeholder="Select aircraft..."
      />
    </div>
  );
};

// This customized MenuList uses the { FixedSizeList as List } from "react-window" which greatly improves the performance on large lists.
const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const height = 75;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

export const DropdownEventCodesVersion = ({
  options,
  defaultValue,
  onChange,
  selectedValue,
  isLoadingEventsCode,
  selectedEventType,
}) => {
  const rectangle = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 3,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 40,
      width: 8,
    },
  });

  const rectangleSingleValue = (color = "transparent") => ({
    alignItems: "center",
    display: "-webkit-box",

    ":before": {
      backgroundColor: color,
      borderRadius: 3,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 40,
      width: 13,
    },
  });

  const customStyles = {
    control: (base, state) => ({
      ...base,
      display: "flex",
      flexFlow: "row",
      justifyContent: "flex-end",
      width: "100%",
      maxWidth: "",
      backgroundColor: "transparent",
      border: state.isFocused ? 0 : 0,
      color: "#ffffff",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      color: "#979797",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      width: "35px",
      height: "100%",
      display: "flex",
      flexFlow: "row",
      justifyContent: "center",
      alignItems: "center",
    }),

    indicatorSeparator: (base, state) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      marginLeft: "10px",
      marginRight: "0px",
      width: "1px",
      borderColor: "#979797",
    }),

    container: (base, state) => ({
      ...base,
      display: "flex",
      width: "100%",
      height: "100%",
      color: "#ffffff",
    }),

    menu: (base, state) => ({
      ...base,
      backgroundColor: "#303030",
      color: "white",
      fontSize: "16px",
      lineHeight: "32px",
      letterSpacing: "0.56px",
      fontWeight: "normal",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
    }),

    menuList: (base, state) => ({
      ...base,
      paddingTop: "0px",
      paddingBottom: "0px",
    }),

    placeholder: (base, state) => ({
      ...base,
      color: "#ffffff",
    }),

    option: (base, state) => ({
      ...base,
      width: "100%",
      backgroundColor: "#303030",
      color: state.isFocused ? "#ffffff" : "", //empty since it will depend on the theme (light: color will be white)
      fontSize: "14px",
      lineHeight: "13px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      borderColor: "#909090",
      borderWidth: "1px",
      borderStyle: "solid",
      "&:hover": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
      "&:active": {
        color: "black",
        backgroundColor: "#d2d2d2",
      },
      ...rectangle(
        tools.getFaultColor(
          state.data.eventSeverityComponent
            ? state.data.eventSeverityComponent
            : selectedEventType.value
        )
      ),
    }),

    singleValue: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "16px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
      width: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      ...rectangleSingleValue(
        tools.getFaultColor(
          state.data.eventSeverityComponent
            ? state.data.eventSeverityComponent
            : selectedEventType.value
        )
      ),
    }),

    input: (base, state) => ({
      ...base,
      color: "#ffffff",
      fontSize: "18px",
      lineHeight: "35px",
      letterSpacing: "0.56px",
      fontWeight: "500",
    }),
  };

  return (
    <div>
      <Select
        cacheOptions
        components={{ MenuList }}
        maxMenuHeight={200}
        styles={customStyles}
        options={options}
        value={selectedValue}
        getOptionLabel={(option) => option.eventCode + " - " + option.eventName}
        getOptionValue={(option) => option.eventCode}
        onChange={onChange}
        placeholder="Select event..."
        isLoading={isLoadingEventsCode}
        noOptionsMessage={() => "No events found"}
      />
    </div>
  );
};

export default {
  Dropdown,
  DropdownListFilterVersion,
  DropdownTemplateVersion,
  DropdownListTailFamilyModelVersion,
};
