/* eslint-disable func-names */
import React from "react";
import FeatherIcon from "feather-icons-react";
import "../styles/Graph Components/Chart.css";
import "../styles/Graph Components/Legend.css";
import { getUnit } from "./Helper";
import MenuMoreOptions from "./MenuMoreOptions";
import { Draggable, Droppable } from "react-beautiful-dnd";
import CustomTooltip from "../Global Components/CustomTooltip";

export default function Legend(props) {
  if (props.unit !== "Discrete Non-Binary") {
    props.legendSeries.forEach((p) => {
      if (!p.englishName) {
        p.englishName = p.name;
      }
    });
  }

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "rgba(173, 216, 230, 0.7)" : "transparent",
    width: "100%",
    border: "none",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "rgba(144, 238, 144, 0.9)" : "transparent",
    ...draggableStyle,
  });

  const discreteNonBinaryLegend = () => {
    return (
      <div className="continuous-params-legend-container">
        <div className="continuous-params-header">
          <div className="continuous-params-header-parameter">Parameter</div>
          <div className="continuous-params-header-lru">LRU</div>
          <div className="continuous-params-header-actions">
            <FeatherIcon icon={"edit-3"} height={20} />
          </div>
          <div className="continuous-params-header-toggle">
            <FeatherIcon icon={"eye"} height={20} />
          </div>
        </div>
        <div className="continuous-params-container">
          {props.legendSeries.map((item, index) => (
            <React.Fragment key={item.englishName}>
              <div className={"continuous-params params-margin-discrete"}>
                <div className="parameter-name">
                  <CustomTooltip
                    content={item.englishName}
                    tooltipType={"general"}
                    placement={"top"}
                  >
                    <p>{item.englishName + " (" + getUnit(item.unit) + ")"}</p>
                  </CustomTooltip>
                </div>
                <div className="continuous-params-actions">
                  <MenuMoreOptions
                    chartId={props.id}
                    parameterName={item.name}
                    downloadXLSX={props.downloadXLSX}
                    removeParam={props.removeParam}
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const discreteChartLegend = () => {
    return (
      <div className="continuous-params-legend-container">
        <div className="continuous-params-header">
          <div className="continuous-params-header-parameter">Parameter</div>
          <div className="continuous-params-header-actions">
            <FeatherIcon icon={"edit-3"} height={20} />
          </div>
          <div className="continuous-params-header-toggle">
            <FeatherIcon icon={"eye"} height={20} />
          </div>
        </div>
        <div className="continuous-params-container">
          {props.legendSeries.map((item, index) => (
            <React.Fragment key={item.name}>
              <div
                className={
                  props.unit === "Discrete"
                    ? "continuous-params params-margin-discrete"
                    : "continuous-params params-margin-continuous"
                }
              >
                {props.dataFocus === "CMS" ? (
                  <div className="parameter-name">
                    <div className="tooltip">
                      <p>{item.name + " (" + getUnit(item.unit) + ")"}</p>
                      <span className="tooltiptext">{item.englishName}</span>
                    </div>
                  </div>
                ) : (
                  <div className="parameter-name">
                    <div className="tooltip">
                      <p>
                        {item.englishName + " (" + getUnit(item.unit) + ")"}
                      </p>
                      <span className="tooltiptext">{item.name}</span>
                    </div>
                  </div>
                )}
                <div className="continuous-params-actions">
                  <MenuMoreOptions
                    chartId={props.id}
                    parameterName={item.name}
                    downloadXLSX={props.downloadXLSX}
                    removeParam={props.removeParam}
                  />
                </div>
                <div className="continuous-params-toggle">
                  {props.unit === "Discrete" ? (
                    <div
                      className="legend-pill"
                      style={{
                        backgroundColor: props.colors[index],
                        border: "1px solid " + props.colors[index],
                        cursor: "default",
                      }}
                    ></div>
                  ) : (
                    <div
                      className="legend-pill"
                      style={{
                        backgroundColor:
                          item.toggleState === "eye"
                            ? props.colors[index]
                            : "#000000",
                        border:
                          "1px solid " +
                          (item.toggleState === "eye"
                            ? props.colors[index]
                            : "#aaaaaa"),
                      }}
                      onClick={(event) =>
                        props.toggleFunc(item.name, item.toggleState, index)
                      }
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const nonDiscreteChartLegend = () => {
    return (
      <div className="continuous-params-legend-container">
        <div className="continuous-params-header">
          <div className="continuous-params-header-parameter">Parameter</div>
          <div className="continuous-params-header-lru">LRU</div>
          <div className="continuous-params-header-actions">
            <FeatherIcon icon={"edit-3"} height={20} />
          </div>
          <div className="continuous-params-header-toggle">
            <FeatherIcon icon={"eye"} height={20} />
          </div>
        </div>
        <Droppable droppableId={props.id}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {/* --------------------------------------------------------------------------------------------------------- */}

              <div className="continuous-params-container">
                {props.legendSeries.map((item, index) => (
                  <Draggable
                    key={item.name}
                    draggableId={item.name}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <React.Fragment key={item.name}>
                          <div
                            className={
                              props.unit === "Discrete"
                                ? "continuous-params params-margin-discrete"
                                : "continuous-params params-margin-continuous"
                            }
                          >
                            {props.dataFocus === "CMS" ? (
                              <div className="parameter-name">
                                <div className="tooltip">
                                  <p>
                                    {item.name +
                                      " (" +
                                      getUnit(item.unit) +
                                      ")"}
                                  </p>
                                  <span className="tooltiptext">
                                    {item.englishName}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="parameter-name">
                                <div className="tooltip">
                                  <p>
                                    {item.englishName +
                                      " (" +
                                      getUnit(item.unit) +
                                      ")"}
                                  </p>
                                  <span className="tooltiptext">
                                    {item.name}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="continuous-params-actions">
                              <MenuMoreOptions
                                chartId={props.id}
                                parameterName={item.name}
                                downloadXLSX={props.downloadXLSX}
                                removeParam={props.removeParam}
                              />
                            </div>
                            <div className="continuous-params-toggle">
                              {props.unit === "Discrete" ? (
                                <div
                                  className="legend-pill"
                                  style={{
                                    backgroundColor: props.colors[index],
                                    border: "1px solid " + props.colors[index],
                                    cursor: "default",
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className="legend-pill"
                                  style={{
                                    backgroundColor:
                                      item.toggleState === "eye"
                                        ? props.colors[index]
                                        : "#000000",
                                    border:
                                      "1px solid " +
                                      (item.toggleState === "eye"
                                        ? props.colors[index]
                                        : "#aaaaaa"),
                                  }}
                                  onClick={(event) =>
                                    props.toggleFunc(
                                      item.name,
                                      item.toggleState,
                                      index
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                        {/* --------------------------------------------------------------------------------------------------------- */}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  const createLegend = () => {
    if (props.unit === "Discrete") {
      return discreteChartLegend();
    } else if (props.unit === "Discrete Non-Binary") {
      return discreteNonBinaryLegend();
    } else {
      return nonDiscreteChartLegend();
    }
  };

  return createLegend();
}
