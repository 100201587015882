import React, { useState, useEffect } from "react";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import CircularProgress from "@mui/material/CircularProgress";

import CanvasJSReact from "../../resources/canvasjs.react";
import { prognosticsColumns } from "../constants";
import { getCanvasDegradationOptions } from "../Graph Components/Helper";

import "./ComponentsHealthBox.css"
import "../canvasChartsStyles.css";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ComponentHealthBox = (props) => {
    const {
        components,
        aircraftInfo,
        handleGraphIconClick
    } = props;
    const [activeItem, setActiveItem] = useState(components?.length > 0
        ? components[0] : null
    );
    const [partTotalHours, setPartTotalHours] = useState("");
    const [chart, setChart] = useState(null);
    const [chartRef, setChartRef] = useState(null);

    const onClick = (component) => {
        if (component?.part_name !== activeItem?.part_name || component?.part_number !== activeItem?.part_number) {
            setActiveItem(component);
        }
    };

    const setCanvasChartDataSeries = (
        chartType = "line",
        dataPoints,
        name,
        color = null,
        lineDashType = null
    ) => {
        var dataSeries = {
            type: chartType,
            // xValueFormatString: "UTC:YYYY-MM-DD",
        };
        if (chartType === "rangeArea") {
            dataSeries.lineThickness = 1;
            dataSeries.markerSize = 0;
        } else if (chartType === "line") {
            dataSeries.markerSize = 0;
        } else {
            dataSeries.markerSize = 5;
        }
        if (color) {
            dataSeries.color = color;
        }
        if (lineDashType) {
            dataSeries.lineDashType = "dash";
        }
        dataSeries.name = name;
        dataSeries.dataPoints = dataPoints;
        dataSeries.showInLegend = false;
        dataSeries.toggled = false;
        dataSeries.legendMarkerType = "circle";

        return dataSeries;
    };

    const formatChart = (component) => {
        if (component?.degradation_history?.data) {

            let highest = 0;
            let degradationPred = [];
            let randomFailurePred = [];

            for (let i = 0; i < component.degradation_history.data.length; i++) {
                let dataPoint = component.degradation_history.data[i];

                if (typeof dataPoint.parallel_hazard_degradation_predictions === "number"
                    && typeof dataPoint.parallel_hazard_random_failure_predictions === "number") {
                    degradationPred.push({
                        x: Number(dataPoint.future_hours),
                        y: Number(dataPoint.parallel_hazard_degradation_predictions) * 100
                    });

                    randomFailurePred.push({
                        x: Number(dataPoint.future_hours),
                        y: Number(dataPoint.parallel_hazard_random_failure_predictions) * 100
                    });

                    if (Number(dataPoint.future_hours) > highest) {
                        highest = Number(dataPoint.future_hours);
                    }
                }
            };

            const degradationPredSeries = setCanvasChartDataSeries(
                "line",
                degradationPred,
                "Design Prognosis",
                "#f94238",
                true
            );

            const randomFailurePredSeries = setCanvasChartDataSeries(
                "line",
                randomFailurePred,
                "In service Prognosis",
                "#6345fc",
                false
            );

            if (Number(component.life_limit) > highest) {
                highest = Number(component.life_limit);
            }
            if (Number(component.suggested_removal_nb_hours) > highest) {
                highest = Number(component.suggested_removal_nb_hours);
            }

            const options = getCanvasDegradationOptions(
                [randomFailurePredSeries, degradationPredSeries],
                typeof component.design_failure_limit !== "undefined" ? component.design_failure_limit * 100 : null, // horizontal line
                typeof component.unscheduled_failure_limit !== "undefined" ? component.unscheduled_failure_limit * 100 : null, // horizontal line
                partTotalHours, // vertical line
                typeof component.suggested_removal_nb_hours !== "undefined" ? Number(component.suggested_removal_nb_hours) : null, // vertical line
                typeof component.life_limit !== "undefined" ? Number(component.life_limit) : null, // vertical line
                highest + 200
            );

            setChart(options);
        }
    };

    const chartRender = () => {
        return (
            <>
                <CanvasJSChart
                    options={chart.options}
                    onRef={(ref) => setChartRef(ref)}
                />
                <div style={{ textAlign: "center", fontSize: 16, color: "#b3b3b3" }}>
                    Flight Hours Since Installation Date
                </div>
            </>
        )
    };

    useEffect(() => {
        if (activeItem?.part_total_hours && aircraftInfo) {
            // Check if there's aicraft info
            if (typeof aircraftInfo.flightHours === "undefined"
                || aircraftInfo.flightHours === "N/A") {
                setPartTotalHours(Number(activeItem.part_total_hours));
            } else {
                const flightHours = aircraftInfo.flightHours.split(" ")[0];
                setPartTotalHours(Math.min(Number(activeItem.part_total_hours), Number(flightHours)));
            }
        };
    }, [activeItem, aircraftInfo]);

    useEffect(() => {
        if (activeItem && typeof partTotalHours === 'number') {
            if (activeItem.degradation_history?.data?.length > 0)
                formatChart(activeItem);
        }
    }, [activeItem, partTotalHours]);

    useEffect(() => {
        if (components?.length > 0) {
            onClick(components[0]);
        }
    }, [components]);

    const ComponentHealthListItem = ({
        component,
        active,
        onClick
    }) => {
        return (
            <div
                className={"component__health__box__list_row"
                    + (active ? " selectedRow" : "")}
                onClick={() => onClick(component)}
            >
                <div className="component__health__box__list_item">
                    {component.part_name}

                </div>
                <div className="component__health__box__list_item">
                    {component.part_number}

                </div>
                <div className="component__health__box__list_item"
                    style={{ textAlign: "right" }}>
                    {component.health_score
                        ? `${(Number(component.health_score) * 100).toFixed(0)}`
                        : "- "
                    }
                    %
                </div>
            </div>
        )
    };

    const ComponentHealthGraphField = ({
        name,
        value,
        color = "transparent"
    }) => {
        return (
            <div className="component__graph__fieldContainer">
                {color &&
                    <div
                        className="component__graph__fieldContainer_color"
                        style={{ backgroundColor: color }}></div>
                }
                <div>
                    <div className="component__graph__fieldTitle">{name}</div>
                    <div style={{ fontWeight: 600 }}>{value}</div>
                </div>
            </div>
        )
    };

    return (
        <div className="infoBox components__health__box">
            <div className="infoBox__header">
                LRU Health
            </div>
            <div className={"infoBox__body" + (components?.length > 0 ? "" : "-no-content")}>
                {components
                    ?
                    <>
                        {/* LEFT HAND SIDE */}
                        <div className="component__health__box__list">
                            <div className="component__health__box__list_header section__header">
                                {prognosticsColumns.map((column) => (
                                    <div
                                        key={column.id}
                                        style={{ textAlign: column.align }}
                                    >
                                        {column.title}
                                    </div>
                                ))}
                            </div>
                            <div className="component__health__box__list_body">
                                {components.map((component, componentIndex) =>
                                    <ComponentHealthListItem
                                        key={`${component.part_name}-${component.part_number}-${componentIndex}`}
                                        active={activeItem?.part_name === component?.part_name && activeItem?.part_number === component?.part_number}
                                        onClick={onClick}
                                        component={component}
                                    />
                                )}
                            </div>
                        </div>
                        {/* RIGHT HAND SIDE */}
                        <div className="component__health__box__degradationHistory">
                            <div className="component__health__box__degradationHistory_header">
                                <div className="component__health__box__degradationHistory_header_top">
                                    <div className="section__header" style={{ display: "flex" }}>
                                        Health Score Projection
                                        <div className="component__health__box__graphExplanation__icon"
                                            onClick={() => handleGraphIconClick(true)}>
                                            <FeatherIcon
                                                icon="info"
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ fontWeight: 600, fontSize: 14 }}>{activeItem?.part_name}</div>
                                </div>
                                <div className="component__health__box__degradationHistory_header_bottom">
                                    <div>
                                        <ComponentHealthGraphField
                                            name={"Installation Date"}
                                            value={activeItem?.latest_installation_date
                                                ? `${moment(activeItem.latest_installation_date).format('MMM DD, YYYY')}`
                                                : "-"}
                                        />
                                        <ComponentHealthGraphField
                                            name={"Part Total Hours"}
                                            value={partTotalHours
                                                ? `${partTotalHours} flight hours`
                                                : "- "}
                                            color={"#578a64"}
                                        />
                                    </div>
                                    <div>
                                        <ComponentHealthGraphField
                                            name={"Suggested Removal"}
                                            value={activeItem?.suggested_removal_nb_hours
                                                ? `${Number(activeItem.suggested_removal_nb_hours).toFixed(0)} flight hours`
                                                : "-"}
                                            color={"#92d197"}
                                        />
                                        <ComponentHealthGraphField
                                            name={"Design Removal"}
                                            value={activeItem?.life_limit
                                                ? `${activeItem.life_limit} flight hours`
                                                : "-"}
                                            color={"#6cdceb"}
                                        />
                                    </div>
                                    <div>
                                        <ComponentHealthGraphField
                                            name={"In service Failure Limit"}
                                            value={activeItem?.unscheduled_failure_limit
                                                ? `${(Number(activeItem.unscheduled_failure_limit) * 100).toFixed(0)}%`
                                                : "-"}
                                            color={"#e6c647"}
                                        />
                                        <ComponentHealthGraphField
                                            name={"Design Failure Limit"}
                                            value={activeItem?.design_failure_limit
                                                ? `${activeItem.design_failure_limit * 100}%`
                                                : "-"}
                                            color={"#c46dc7"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="component__health__box__degradationHistory_body">
                                {chart && chartRender()}
                            </div>
                        </div>
                    </>
                    :
                    <div className="component__health__box__circularProgress__container">
                        <CircularProgress style={{ color: "#979797" }} />
                    </div>
                }
            </div>
        </div>
    )
};

export default ComponentHealthBox;