import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { X } from "react-feather";
import "./PDFReader.css";

// Needs to load the pdfjs distribution from a CDN for the global worker to work.
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://unpkg.com//pdfjs-dist@3.11.174/build/pdf.worker.min.js";

/**
 * PDF Reader component.
 * @param file File download link.
 * @param date Date the file/guide was modified.
 * @param title Title of the document/guide.
 * @param docType Document/guide type title.
 * @param onClose Event hanlder that will close selected PDF guide.
 * @return {HTML}
 */
export default function PDFReader({ file, date, title, docType, onClose }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber] = useState(1);
  const [showToolMenu, setShowToolMenu] = useState(false);
  const [pageWidth, setPageWidth] = useState(800);
  const [pageScale, setPageScale] = useState(1);
  const [pageScaleIncrement] = useState(0.25);
  const [minPageScale] = useState(0);
  const [maxPageScale] = useState(4);

  /**
   * Set PDF attributes when the file has loaded successfully.
   * @return {Promise<void>}
   */
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setShowToolMenu(true);
  }

  /**
   * PFD reader zoom out which reduces the scale of the document view.
   * @return {Promise<void>}
   */
  const reduceScale = () => {
    if (pageScale - pageScaleIncrement > minPageScale) {
      setPageScale(pageScale - pageScaleIncrement);
    }
  };

  /**
   * PFD reader zoom in which increments the scale of the document view.
   * @return {Promise<void>}
   */
  const incrementScale = () => {
    if (pageScale + pageScaleIncrement < maxPageScale) {
      setPageScale(pageScale + pageScaleIncrement);
    }
  };

  // Window resize event listener for better responsiveness
  window.addEventListener("resize", () => {
    if (window.innerWidth >= 1000) {
      setPageWidth(1200);
    } else if (window.innerWidth >= 700) {
      setPageWidth(800);
    } else {
      setPageWidth(400);
    }
  });

  /**
   * Returns the main HTML of the page.
   * @return {HTML}
   */
  return (
    <div className="pdfReader">
      <div className={"pdf__separator__in"} />
      <div style={{ display: "flex" }}>
        <span className={"pdf_doctype"}>{docType}</span>
        <span className={"pdf_title_date_outer"}>
          <div className={"pdf_title"}> {title} </div>
          <div className={"pdf_date"}>Uploaded {date}</div>
        </span>
        <span>
          <X
            className={"pdf_x"}
            style={{ width: "32px", height: "100%", cursor: "pointer" }}
            onClick={onClose}
          />
        </span>
      </div>
      <div className={"pdf__separator__in"} style={{ marginTop: "20px" }} />
      <div className={"pdf_document"}>
        <div className={"pdf_x-scroll"}>
          <Document
            //className={"pdf_document_outer"}
            file={file}
            error={"Failed to load the PDF file. Please check back later."}
            loading={"Loading PDF file..."}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode={"canvas"}
            style={{ align: "center" }}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <React.Fragment key={index}>
                <div style={{ paddingBottom: "25px" }}>
                  <Page
                    //className={"pdf_document_page"}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    error={"Failed to load the page. Please check back later."}
                    loading={"Loading page..."}
                    scale={pageScale}
                    width={pageWidth}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                  <p
                    style={{
                      display: "flex",
                      paddingTop: "5px",
                      justifyContent: "center",
                    }}
                  >
                    Page {pageNumber + index} of {numPages}
                  </p>
                </div>
              </React.Fragment>
            ))}
          </Document>
        </div>
        {showToolMenu && (
          <div className="pdf__tool_menu">
            <button
              className="pdf__zoom__button pdf__zoom__button__left"
              onClick={reduceScale}
              disabled={pageScale - pageScaleIncrement <= minPageScale}
            >
              -
            </button>
            <button
              className="pdf__zoom__button pdf__zoom__button__right"
              onClick={incrementScale}
              disabled={pageScale + pageScaleIncrement >= maxPageScale}
            >
              +
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
