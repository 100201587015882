import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client to manage the aircraft monitoring settings
  Note: This API is ccurrently not in use
*/

const UPDATE_MONITOR = Api.UPDATE_MONITOR;

export async function updateAircraftMonitoring(
  tailNumber,
  status,
  axiosCancelSource
) {
  let result;
  const data = {
    tailNumber: tailNumber,
    enable: status.toString(),
  };

  try {
    result = await AxiosService(
      UPDATE_MONITOR.url,
      UPDATE_MONITOR.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}
