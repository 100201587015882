import React from "react";
import Spinner from "../../Global Components/Spinner.js";

const LoadingSpinner = () => (
  <div className="loadSpinner-screen">
    <div className="loadSpinner-internal-container">
      <Spinner />
    </div>
  </div>
);
export default LoadingSpinner;
