import React, {forwardRef, useState} from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import "./CustomTooltip.css";

/*
  Platform Custom Tooltip Component Possible Values
  
  tooltipType = (general, semantic, contextual, custom)
  title = must be a string
  content = can be HTML content or a string
  icon = must be an object
  placement = (bottom-end, bottom-start, bottom, left-end, left-start, left, right-end, right-start, right, top-end, top-start, top)
*/

const CustomTooltip = ({
  children,
  tooltipType,
  title,
  content,
  icon,
  placement,
}, ref) => {
  // Default non customizable tooltip attributes, if these ever become customizable please add a default value
  const [showTooltipArrow] = useState(true);
  const [tooltipEnterDelay] = useState(500);
  const [tooltipLeaveDelay] = useState(80);

  // Get the Style Class object for the different tooltip templates
  const getTooltipStyleClasses = () => {
    return {
      tooltip: "customTooltip__MuiTooltip-tootip",
      arrow: "customTooltip__MuiTooltip-arrow",
      popper: "customTooltip__MuiTooltip-popper",
    };
  };

  // Format the content of the tooltip according to the tooltip template
  const formatTooltipContent = () => {
    const mainContent = (
      <span className="customTooltip__content__body">{content}</span>
    );

    // If the tooltip template is "general" only return the main content
    if (tooltipType === "general") {
      return mainContent;
    }

    const withTitleContent = (
      <div className="customTooltip__content">
        {title && (
          <span className="customTooltip__content__title">{title}</span>
        )}
        {mainContent}
      </div>
    );

    // If the tooltip template is "contextual" return the main content and a title
    if (tooltipType === "contextual") {
      return withTitleContent;
    }

    const withIconContent = (
      <div className="customTooltip__iconContent">
        {icon ? (
          <span className="customTooltip__iconContent__icon">{icon}</span>
        ) : (
          <span className="customTooltip__iconContent__icon">{"icon"}</span>
        )}
        {mainContent}
      </div>
    );

    // If the tooltip template is "semantic" return the main content and an icon
    if (tooltipType === "semantic") {
      return withIconContent;
    }

    const customContent = (
      <div className="customTooltip__iconContent">
        {icon && (
          <span className="customTooltip__iconContent__icon">{icon}</span>
        )}
        <div className="customTooltip__content">
          {title && (
            <span className="customTooltip__content__title">{title}</span>
          )}
          {mainContent}
        </div>
      </div>
    );

    // If the tooltip template is "custom" return the main content, title and icon if any
    if (tooltipType === "custom") {
      return customContent;
    }

    return mainContent;
  };

  // Render
  return (
    children && (
      <Tooltip
        title={formatTooltipContent()}
        arrow={showTooltipArrow}
        placement={placement}
        TransitionComponent={Fade}
        enterDelay={tooltipEnterDelay}
        leaveDelay={tooltipLeaveDelay}
        classes={getTooltipStyleClasses()}
      >
          {children}
      </Tooltip>
    )
  );
};

export default forwardRef(CustomTooltip);

CustomTooltip.defaultProps = {
  tooltipType: "general",
  title: null,
  content: "",
  icon: null,
  placement: "right",
};

CustomTooltip.propTypes = {
  tooltipType: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.object,
  placement: PropTypes.string,
};
