import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for the interactive onboard feature guides
*/

const IOFG_USER_LIST = Api.IOFG_USER_LIST;
const IOFG_USER_UPDATE = Api.IOFG_USER_UPDATE;

// Get the progress of all the onboarding guides specific to a user
async function getProgress() {
  let result;

  try {
    result = await AxiosService(IOFG_USER_LIST.url, IOFG_USER_LIST.method);
  } catch (error) {
    console.error(error);
  }

  return result.data;
}

// Update the progress of an onboarding guide for a user
async function updateProgress(progress) {
  // test/validate inputs?
  let result;
  const data = {};
  const disableUpdate = false;

  const requestString = disableUpdate
    ? `${IOFG_USER_UPDATE.url}?iofgKey=${progress.iofgKey}&pageKey=${progress.pageKey
    }&completionStatus=${false}`
    : `${IOFG_USER_UPDATE.url}?iofgKey=${progress.iofgKey}&pageKey=${progress.pageKey}&completionStatus=${progress.completionStatus}`;

  try {
    // test inputs
    result = await AxiosService(requestString, IOFG_USER_UPDATE.method, data);
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}

export { getProgress, updateProgress };
