import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Link } from '@mui/material'
import moment from 'moment'
import FeatherIcon from 'feather-icons-react'
import { Draggable } from 'react-beautiful-dnd'
import { getTakeOffReport } from '../../clients/TakeOffReportClient'
import { getFlightPhase, getMonthAbbrv } from '../usefulFunctions'
import { formatDepartureArrivalTime } from '../usefulFunctions'

import './AnomalyCard.css'

const AnomalyCard = ({ data, index, fleet, flightHistories }) => {
    const [departureAirport, setDepartureAirport] = useState(null)
    const [departureTime, setDepartureTime] = useState(null)
    const [arrivalAirport, setArrivalAirport] = useState(null)
    const [arrivalTime, setArrivalTime] = useState(null)

    const type = data.status === 'ARCHIVED' ? 'archivesItem' : 'reportsItem'

    useEffect(() => {
        if (fleet.tailNumber) {
            getTakeOffReportData().then(response => {
                if (response) {
                    let allFlights = _.get(response, 'flights')
                    if (allFlights.length !== 0) {
                        let flightInfo = allFlights[parseInt(data.flight_leg) - 1]
                        setDepartureAirport(
                            `${flightInfo.departureAirportName} (${flightInfo.departureAirportIATA})`
                        )
                        setDepartureTime(formatDepartureArrivalTime(flightInfo.departureTime))
                        setArrivalAirport(
                            `${flightInfo.arrivalAirportName} (${flightInfo.arrivalAirportIATA})`
                        )
                        setArrivalTime(formatDepartureArrivalTime(flightInfo.arrivalTime))
                    }
                }
            })
        }
    }, [fleet, data])

    const getTakeOffReportData = async () => {
        const takeOffReport = await getTakeOffReport(
            fleet.tailNumber,
            null,
            null,
            null,
            fleet.aircraftFamily,
            fleet.aircraftModel,
            data.flight_session,
            undefined
        )
        return takeOffReport.data
    }

    return (
        <Draggable key={data.id} index={index} draggableId={`${data.session_key}-${data.ise_num}`}>
            {(provided, snapshot) => {
                return (
                    <div
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Link
                            href={`/predictivereports/${data.session_key}/${data.ise_num}`}
                            target='_blank'
                        >
                            <div className={`card__container ${type}`}>
                                <div
                                    className='card__header fullBold'
                                    style={{ backgroundColor: '#202020' }}
                                >
                                    <div
                                        style={{
                                            textTransform: 'uppercase',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {data.ise_name}
                                    </div>
                                </div>
                                <div className='card__info'>
                                    <div className='card__info__section ataInfo'>
                                        <div>
                                            <div className='fullBold'>
                                                {data?.ata_chapter ? `ATA ${data.ata_chapter}` : ''}
                                            </div>
                                            <div className='lowBold'>
                                                {data?.ata_description
                                                    ? data.ata_description.toUpperCase()
                                                    : ''}
                                            </div>
                                        </div>
                                        <div>
                                            {data.reviewed && (
                                                <div className='reviewed_check'>
                                                    <FeatherIcon
                                                        id='feather_icon'
                                                        icon='check-circle'
                                                        color='#3ABB58'
                                                        height={20}
                                                        width={20}
                                                        style={{ marginLeft: 5 }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='card__info__section__separator'></div>

                                    {data.flight_session && (
                                        <div className='card__info__section flightSessionInfo'>
                                            <span className='lowBold'>
                                                {`Flight Session: `}
                                                <span className='fullBold'>{`${data.flight_session} `}</span>
                                                | Flight {data.flight_leg}
                                            </span>
                                        </div>
                                    )}
                                    <div className='card__info__subsection departureArrivalContainer'>
                                        <div className='departureArrivalInfo fullBold'>
                                            {`Departure: `}
                                            <span className='lowBold'>
                                                {departureTime
                                                    ? departureTime
                                                    : 'Departure time unavailable'}
                                            </span>
                                            {departureAirport
                                                ? departureAirport
                                                : 'Airport unavailable'}
                                        </div>

                                        <div className='departureArrivalInfo fullBold'>
                                            {`Arrival: `}
                                            <span className='lowBold'>
                                                {arrivalTime
                                                    ? arrivalTime
                                                    : 'Arrival time unavailable'}
                                            </span>
                                            {arrivalAirport
                                                ? arrivalAirport
                                                : 'Airport unavailable'}
                                        </div>
                                    </div>

                                    <div className='card__info__section__separator'></div>

                                    <div
                                        className={`card__info__section ${
                                            data?.event_id
                                                ? 'flightPhaseInfoWithAnomalyID'
                                                : 'flightPhaseInfoWithoutAnomalyID'
                                        } lowBold`}
                                    >
                                        {data?.event_id && (
                                            <div className='midBold'>
                                                Event ID:
                                                <span className='fullBold'>
                                                    {` ${data.event_id}`}
                                                </span>
                                            </div>
                                        )}
                                        <div className='midBold'>
                                            {data?.flight_phase &&
                                                getFlightPhase(data?.flight_phase)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            }}
        </Draggable>
    )
}

export default AnomalyCard
