import { sendCustomGA4Event } from "./EventTracking";

const CATEGORY_AUTH = "authentication";
const CATEGORY_INTERACTION = "interaction";

// Used to send GA4 event when user login is successful
export function sendLoginSuccessfulEvent() {
    sendCustomGA4Event("login", CATEGORY_AUTH, "successful_login", true);
}

// Used to send GA4 event when user navigates to a new page
export function sendPageViewEvent(pathSelected) {
    sendCustomGA4Event(
        "page_view", 
        "navigation", 
        pathSelected, 
        false
    );
}

// Used to send GA4 event when user charts AHMS
export function sendAHMSChartingEvent(analyticsChartingInfo) {
    sendCustomGA4Event(
        "charting", 
        CATEGORY_INTERACTION,
        "parameter_charting", 
        true, 
        analyticsChartingInfo
    );
}

// Used to send GA4 event when user charts full flight parameters
export function sendChartFullFlightParamsEvent() {
    sendCustomGA4Event(
        'chart_full_flight_parameters',
        'fault message tracking',
        'Chart Full Flight parameters',
        true
    );
}

// Used to send GA4 event when user charts event parameters
export function sendChartEventParamsEvent() {
    sendCustomGA4Event(
        'chart_event_parameters',
        'fault message tracking',
        'Chart Event parameters',
        true
    );
}

// Used to send GA4 event when user charts continuous parameters
export function sendChartContParamsEvent() {
    sendCustomGA4Event(
        'chart_continuous_parameters',
        'fault message tracking',
        'Chart Cont. parameters',
        true
    );
}

// Used to send GA4 event when user charts fault parameters
export function sendChartFaultParamsEvent() {
    sendCustomGA4Event(
        'chart_fault_parameters',
        'fault message tracking',
        'Chart Fault parameters',
        true
    );
}

// Used to send GA4 event when user selects a different session
export function sendChooseHistoricalSessionEvent(
    sessionNumber,
    sessionStart,
    sessionEnd,
    airports,
    totalFaultCount
) {
    sendCustomGA4Event(
        'historical_session',
        CATEGORY_INTERACTION,
        'Historical session data',
        true,
        {
            session_number: sessionNumber,
            session_start: sessionStart,    
            session_end: sessionEnd,   
            airports: airports,
            faultCount: totalFaultCount
        }
    );
}

// Used to send GA4 event when user clicks on a link to Smart Fix Plus
export function sendSmartFixClickEvent() {
    sendCustomGA4Event(
        'smart_fix',
        'fault message tracking',
        'Smart Fix ',
        true
    );
}

// Used to send GA4 event when user expands the airframe details dropdown on aircraft page
export function sendAirframeDetailsDropdownEvent(
    airframeHours,
    airframeCycles,
    engineLeftHours,
    engineLeftCycles,
    engineRightHours,
    engineRightCycles
) {
    sendCustomGA4Event(
        'airframe_details',
        CATEGORY_INTERACTION,
        'Airframe Hours',
        true,
        {
            airframe_hours: airframeHours,
            airframe_cycles: airframeCycles,
            engine_left_hours: engineLeftHours,
            engine_left_cycles: engineLeftCycles,
            engine_right_hours: engineRightHours,
            engine_right_cycles: engineRightCycles
        }
    );
}