import React, { useEffect, useState } from "react";
import _ from "lodash";
import "./GroundLoadingHistory.css";
import GlobalDropdown from "../../Global Components/Dropdown/GlobalDropdown";
import CustomButton from "../../Global Components/CustomButton";
import { Grid } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import NewGroundLoadingRequest from "./NewGroundLoadingRequest";
import { getGroundLoadingHistory } from "../../clients/GroundLoadingClient";
import { getRolesPermissions } from "../../Auth Components/RbacValidation";

/**
 * The Ground Loading history component.
 * TODO: Fill the filters options and apply them.
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export default function GroundLoadingHistory({}) {
  const [groundLoadingHistory, setGroundLoadingHistory] = useState([]);
  const [
    filteredGroundLoadingHistory,
    setFilteredGroundLoadingHistory,
  ] = useState([]);
  const [tailFilter, setTailFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [order, setOrder] = useState("Newest");
  const [displayNewUpload, setDisplayNewUpload] = useState(false);
  const [tailFilterOptions, setTailFilterOptions] = useState(["All"]);
  const [userInfo, setUserInfo] = useState(null);
  const [
    groundLoadingRequestPermission,
    setGroundLoadingRequestPermission,
  ] = useState(false);

  /**
   * Load the user info when the component is created.
   */
  useEffect(() => {
    loadUserInfo();
  }, []);

  /**
   * Function to get the user information from the local storage.
   * @return {null}
   */
  const loadUserInfo = () => {
    if (localStorage.getItem("userInfo")) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setUserInfo(userInfo);
    }
    const userPermissions = getRolesPermissions("uploads");
    setGroundLoadingRequestPermission(
      userPermissions.createGroundLoadingRequest
    );
  };

  /**
   * After the user information is retrieved gets the ground loading history.
   */
  useEffect(() => {
    const getHistory = async () => {
      await retrieveGroundLoadingHistory();
    };
    getHistory().catch(console.error);
  }, [userInfo]);

  /**
   * Handles filtering.
   */
  useEffect(() => {
    let filteredGLHistory = [...groundLoadingHistory];
    if (tailFilter === "All") {
      filteredGLHistory = [...groundLoadingHistory];
    } else {
      filteredGLHistory = _.filter(filteredGLHistory, (item) => {
        return item.registrationId === tailFilter;
      });
    }

    if (statusFilter === "All") {
      filteredGLHistory = _.filter(filteredGLHistory, (item) => {
        return (
          item.groundLoadingStatus === "SUBMITTED" ||
          item.groundLoadingStatus === "COMPLETED"
        );
      });
    } else {
      let statusFilterValue = statusFilter;
      if (statusFilter === "SENT") {
        statusFilterValue = "COMPLETED";
      }
      filteredGLHistory = _.filter(filteredGLHistory, (item) => {
        return item.groundLoadingStatus === statusFilterValue;
      });
    }

    filteredGLHistory = _.orderBy(
      filteredGLHistory,
      ["createdAt"],
      [order === "Newest" ? "desc" : "asc"]
    );
    setFilteredGroundLoadingHistory([...filteredGLHistory]);
  }, [tailFilter, statusFilter, order]);

  /**
   * Method to retrieve the ground loading history items.
   */
  const retrieveGroundLoadingHistory = async () => {
    let history = [];
    if (userInfo) {
      try {
        await getGroundLoadingHistory(userInfo.userId, null)
          .then((response) => {
            history = _.filter(response.data, (item) => {
              return item.groundLoadingStatus !== "PENDING";
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    }

    const tfOptions = ["All"];

    history.forEach((item) => {
      if (tfOptions.indexOf(item.registrationId) === -1) {
        tfOptions.push(item.registrationId);
      }
      let requestSize = 0;
      let hiddenFileCount = 0;
      let collapsedFiles = [];
      if (item.createdAt) {
        item.createdAt = moment(item.createdAt).utc();
      }
      if (item.updatedAt) {
        item.updatedAt = moment(item.updatedAt).utc();
      }
      if (item.groundLoadingStatus === "COMPLETED") {
        // There's only space for two file names if the status is "COMPLETED"
        // so we have to calculate how many files will be hidden.
        if (item.files.length > 2) {
          collapsedFiles.push(item.files[0].fileName);
          collapsedFiles.push(item.files[1].fileName);
          hiddenFileCount = item.files.length - 2;
          collapsedFiles.push(`+ ${hiddenFileCount} more...`);
        } else {
          item.files.forEach((file) => {
            collapsedFiles.push(file.fileName);
          });
        }
      } else {
        if (item.files.length > 3) {
          collapsedFiles.push(item.files[0].fileName);
          collapsedFiles.push(item.files[1].fileName);
          collapsedFiles.push(item.files[2].fileName);
          hiddenFileCount = item.files.length - 3;
          collapsedFiles.push(`+ ${hiddenFileCount} more...`);
        } else {
          item.files.forEach((file) => {
            collapsedFiles.push(file.fileName);
          });
        }
      }
      // Add the file size of each individual file and format it.
      item.files.forEach((file) => {
        requestSize += file.fileSize;
      });
      const requestSizeText = formatRequestSize(requestSize);
      _.assign(item, {
        requestSize: requestSizeText,
        hiddenFileCount: hiddenFileCount,
        isCollapsed: true,
        collapsedFiles: collapsedFiles,
      });
    });
    history = _.orderBy(history, ["createdAt"], ["desc"]);
    setGroundLoadingHistory([...history]);
    setFilteredGroundLoadingHistory([...history]);
    setTailFilterOptions([...tfOptions]);
  };

  /**
   * Helper to format the estimated request size.
   * TODO: This function may be moved to the reusable tools component.
   * @param bytes Request size in bytes.
   * @return {string}
   */
  const formatRequestSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1000;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  /**
   * Handles the cancel action from the new ground loading request view.
   */
  const handleCancelClick = () => {
    setDisplayNewUpload(false);
  };

  /**
   * Handles when a new ground loading session submission is successful.
   * @return {Promise<void>}
   */
  const handleSubmitSuccessful = async () => {
    setDisplayNewUpload(false);
    await retrieveGroundLoadingHistory();
  };

  const displayHistory = () => {
    return (
      <>
        <div className="ground__loading__filters__container">
          <div className="ground__loading__tail__filter">
            <GlobalDropdown
              options={tailFilterOptions}
              onChange={(option) => {
                setTailFilter(option.value);
              }}
              placeholder={"Search by registration #"}
              disabled={!groundLoadingRequestPermission}
            />
          </div>
          <div className="ground__loading__status__filter">
            <GlobalDropdown
              options={["All", "SUBMITTED", "SENT"]}
              onChange={(option) => {
                setStatusFilter(option.value);
              }}
              placeholder={"Filter by status"}
              disabled={!groundLoadingRequestPermission}
            />
          </div>
          <div className="ground__loading__order__filter">
            <GlobalDropdown
              options={["Newest", "Oldest"]}
              onChange={(option) => {
                setOrder(option.value);
              }}
              placeholder={"Order by..."}
              disabled={!groundLoadingRequestPermission}
            />
          </div>
        </div>
        <div className="ground__loading__newRequest__container">
          <CustomButton
            id={"btn-new-ground-loading-request"}
            text={"NEW UPLOAD"}
            buttonStyle={"pill"}
            customButtonColor={"#F0A400"}
            customButtonTextColor={"#000"}
            customHeight={"40px"}
            borderStyle="none"
            onClickAction={() => setDisplayNewUpload(true)}
            isDisabled={!groundLoadingRequestPermission}
          />
        </div>
        <div className="ground__loading__history__container">
          {groundLoadingRequestPermission ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 1, sm: 1, md: 4, lg: 9, xl: 9 }}
            >
              {filteredGroundLoadingHistory.map((glItem) => (
                <Grid item xs={1} sm={1} md={2} lg={3} key={glItem.id}>
                  <div className="ground__loading__history__item__container">
                    <div
                      className={
                        glItem.isCollapsed
                          ? "helper__container"
                          : "helper__container helper__container__large"
                      }
                    >
                      <div
                        className={
                          glItem.groundLoadingStatus === "SUBMITTED"
                            ? "ground__loading__history__status__container"
                            : "ground__loading__history__status__container ground__loading__history__status__container__success"
                        }
                      >
                        <div
                          className={
                            "ground__loading__history__status__icon__container"
                          }
                        >
                          <div className="ground__loading__history__status__icon__with__text">
                            <FeatherIcon
                              icon={
                                glItem.groundLoadingStatus === "SUBMITTED"
                                  ? "clock"
                                  : "check-circle"
                              }
                              height={"30px"}
                              width={"30px"}
                              color={
                                glItem.groundLoadingStatus === "SUBMITTED"
                                  ? "#303030"
                                  : "#FFFFFF"
                              }
                            />
                            <p className="ground__loading__history__item__status__text">
                              {glItem.groundLoadingStatus !== "COMPLETED"
                                ? glItem.groundLoadingStatus
                                : "SENT"}
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            "ground__loading__history__status__info__container"
                          }
                        >
                          <p
                            className={
                              "ground__loading__history__status__info__registration"
                            }
                          >
                            {glItem.registrationId}
                          </p>
                          {/* TODO: This should be the aircraft serial number but it is not available.
                          <p className={"ground__loading__history__status__info__identifier"}>{glItem.id}</p>

                            */}
                        </div>
                      </div>

                      <div className="ground__loading__history__content__container">
                        <div className="ground__loading__history__content__header">
                          <div className="ground__loading__history__dates">
                            {glItem.groundLoadingStatus === "COMPLETED" && (
                              <p>
                                Complete:{" "}
                                {glItem.updatedAt.format(
                                  "DD.MM.YYYY | HH:mm:ss"
                                )}{" "}
                                UTC
                              </p>
                            )}
                            <p>
                              Uploaded:{" "}
                              {glItem.createdAt.format("DD.MM.YYYY | HH:mm:ss")}{" "}
                              UTC
                            </p>
                          </div>
                          <div
                            className="ground__loading__history__dropdown__container"
                            onClick={() => {
                              if (glItem.isCollapsed) {
                                filteredGroundLoadingHistory.forEach((item) => {
                                  item.isCollapsed = true;
                                });
                                glItem.isCollapsed = false;
                              } else {
                                glItem.isCollapsed = !glItem.isCollapsed;
                              }
                              setFilteredGroundLoadingHistory([
                                ...filteredGroundLoadingHistory,
                              ]);
                            }}
                          >
                            <FeatherIcon
                              icon={
                                glItem.isCollapsed
                                  ? "chevron-down"
                                  : "chevron-up"
                              }
                              height={"24px"}
                              width={"24px"}
                              color={"#FFFFFF"}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            glItem.groundLoadingStatus === "SUBMITTED"
                              ? "ground__loading__history__content__files"
                              : "ground__loading__history__content__files ground__loading__history__content__files__completed"
                          }
                        >
                          {glItem.isCollapsed
                            ? glItem.collapsedFiles.map((fileName) => (
                                <p>{fileName}</p>
                              ))
                            : glItem.files.map((file) => (
                                <p>{file.fileName}</p>
                              ))}

                          {!glItem.isCollapsed && glItem.notes && (
                            <div
                              className={
                                "ground__loading__history__content__notes"
                              }
                            >
                              <h3>Notes:</h3>
                              <p>{glItem.notes}</p>
                            </div>
                          )}
                        </div>

                        <div className="ground__loading__history__content__details">
                          <p className="ground__loading__history__content__uploader">
                            <FeatherIcon
                              icon={"user"}
                              height={"20px"}
                              width={"20px"}
                              color={"#FFFFFF"}
                              className={"ground__loading__user__icon"}
                            />
                            {glItem.userFullName}
                          </p>
                          <p className="ground__loading__history__content__size">
                            {glItem.requestSize}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div className="ground__loading__history__not__allowed__container">
              <div className="gl__not__allowed__icon">
                <FeatherIcon
                  icon={"alert-circle"}
                  height={"34px"}
                  width={"34px"}
                  color={"#FFFFFF"}
                  style={{
                    marginTop: "6px",
                  }}
                />
              </div>
              <div className="gl__not__allowed__text">
                <h4>You don't have access to any Remote Staging uploads.</h4>
                <p>Contact your administrator to request access</p>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {displayNewUpload ? (
        <NewGroundLoadingRequest
          userInfo={userInfo}
          handleCancelAction={handleCancelClick}
          handleSubmitSuccessful={handleSubmitSuccessful}
        />
      ) : (
        displayHistory()
      )}
    </>
  );
}
