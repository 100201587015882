import React, { useState, useEffect } from "react";
import "./ConfigCyclesHoursCalculate.css";
import * as tools from "../utils/CommonTools";
import { DatePicker } from "@mui/x-date-pickers";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import CustomButton from "../Global Components/CustomButton";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
//import utc from "dayjs/plugin/utc";

const ConfigCyclesHoursCalculate = (props) => {
  const [totalFlightTime, setTotalFlightTime] = useState(
    props.flightCycles?.totalFlightTime
  );
  const [totalFlightCycles, setTotalFlightCycles] = useState(
    props.flightCycles?.totalFlightCycles
  );
  const [totalEngine1Time, setTotalEngine1Time] = useState(
    props.flightCycles?.totalEngine1Time
  );
  const [totalEngine1Cycles, setTotalEngine1Cycles] = useState(
    props.flightCycles?.totalEngine1Cycles
  );
  const [totalEngine2Time, setTotalEngine2Time] = useState(
    props.flightCycles?.totalEngine2Time
  );
  const [totalEngine2Cycles, setTotalEngine2Cycles] = useState(
    props.flightCycles?.totalEngine2Cycles
  );
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [isCalculatedByDate, setIsCalculatedByDate] = useState(false);

  const [flightCyclesByDate, setFlightCyclesByDate] = useState({
    totalFlightTimeByDate: 0,
    totalFlightCyclesByDate: 0,
    totalEngine1TimeByDate: 0,
    totalEngine1CyclesByDate: 0,
    totalEngine2TimeByDate: 0,
    totalEngine2CyclesByDate: 0,
  });

  dayjs.extend(utc);
  const UTC_TIME = dayjs.utc().format("HH") + ":" + dayjs.utc().format("mm");

  // These props are being saved in separate state
  // due to the RESET button functionality and re-render value changes.

  useEffect(() => {
    setTotalFlightTime(props.flightCycles?.totalFlightTime);
    setTotalFlightCycles(props.flightCycles?.totalFlightCycles);
    setTotalEngine1Time(props.flightCycles?.totalEngine1Time);
    setTotalEngine1Cycles(props.flightCycles?.totalEngine1Cycles);
    setTotalEngine2Time(props.flightCycles?.totalEngine2Time);
    setTotalEngine2Cycles(props.flightCycles?.totalEngine2Cycles);
  }, [
    props.flightCycles?.totalFlightTime,
    props.flightCycles?.totalFlightCycles,
    props.flightCycles?.totalEngine1Time,
    props.flightCycles?.totalEngine1Cycles,
    props.flightCycles?.totalEngine2Time,
    props.flightCycles?.totalEngine2Cycles,
  ]);

  function validateEngineSerialNumber(engineSerial) {
    if (engineSerial) {
      return engineSerial;
    }
    return "-";
  }

  const engine1Serial = validateEngineSerialNumber(
    props.flightCycles?.engine1Serial
  );
  const engine2Serial = validateEngineSerialNumber(
    props.flightCycles?.engine2Serial
  );

  const handleReset = () => {
    setSelectedDate(new Date());
    setIsCalculatedByDate(false);
  };

  const handleApply = async () => {
    const res = await props.processFlightCyclesByDate(
      props.serialNumber,
      props.selectedTail.aircraftFamily,
      props.selectedTail.aircraftModel,
      //TODO: This should be changed to proper utc formatting
      dayjs(selectedDate).utc().format("YYYY-MM-DDTHH:mm:00.000").toString() +
        "Z"
    );

    setFlightCyclesByDate({
      totalFlightTimeByDate: res.totalFlightTime,
      totalFlightCyclesByDate: res.totalFlightCycles,
      totalEngine1TimeByDate: res.totalEngine1Time,
      totalEngine1CyclesByDate: res.totalEngine1Cycles,
      totalEngine2TimeByDate: res.totalEngine2Time,
      totalEngine2CyclesByDate: res.totalEngine2Cycles,
    });

    setIsCalculatedByDate(true);
  };

  // getTimezoneOffset() offset local time to UTC before it goes into DateTimePicker as value and then
  // offset UTC to local after it comes out of DateTimePicker through onChange (localToUTC()).

  const getTimezoneOffset = (value) => value.getTimezoneOffset() * 60000;

  const makeLocalAppearUTC = (value) => {
    //TODO: This needs to be optimized
    const dateTime = new Date(value);

    const utcFromLocal = dayjs(
      dateTime.getTime() + getTimezoneOffset(dateTime)
    );

    return utcFromLocal;
  };

  const localToUTC = (dateTime) => {
    if (dateTime) {
      const tempDateTime = new Date(dateTime);

      const utcFromLocal = dayjs(
        tempDateTime.getTime() - getTimezoneOffset(tempDateTime)
      );

      setSelectedDate(utcFromLocal);
    }
  };

  const compareDates = () => {
    return makeLocalAppearUTC(selectedDate) > makeLocalAppearUTC(new Date());
  };

  const datePickerTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="configCyclesHoursCalculate">
        <form>
          <div className="configCyclesHoursCalculate__rows__title">
            <div className="configCyclesHoursCalculate__currentValue">
              <div className="configCyclesHoursCalculate__currentValue__label">
                System
              </div>
              <div className="configCyclesHoursCalculate__currentValue__value">
                Count
              </div>
            </div>
          </div>
          <div className="configCyclesHoursCalculate__rows__subtitle">
            <div className="configCyclesHoursCalculate__currentValue">
              <div className="configCyclesHoursCalculate__currentValue__label__subtitle">
                Calculated on:
              </div>
            </div>
          </div>
          <div className="configCyclesHoursCalculate__list">
            <div className="configCyclesHoursCalculate__rows">
              <div className="configCyclesHoursCalculate__currentValue">
                <div className="configCyclesHoursCalculate__currentValue__label">
                  Airframe Hours
                </div>
                <div className="configCyclesHoursCalculate__currentValue__value">
                  {isCalculatedByDate
                    ? tools.convertMinutesToHoursMinutesText(
                        flightCyclesByDate.totalFlightTimeByDate
                      )
                    : tools.convertMinutesToHoursMinutesText(totalFlightTime)}
                </div>
              </div>
            </div>
            <div className="configCyclesHoursCalculate__rows">
              <div className="configCyclesHoursCalculate__currentValue">
                <div className="configCyclesHoursCalculate__currentValue__label">
                  Airframe Cycles
                </div>
                <div className="configCyclesHoursCalculate__currentValue__value">
                  {isCalculatedByDate
                    ? tools.formatCyclesText(
                        flightCyclesByDate.totalFlightCyclesByDate
                      )
                    : tools.formatCyclesText(totalFlightCycles)}
                </div>
              </div>
            </div>
            <div className="configCyclesHoursCalculate__rows">
              <div className="configCyclesHoursCalculate__currentValue">
                <div className="configCyclesHoursCalculate__currentValue__label">
                  {"Engine (" + engine1Serial + ") Hours"}
                </div>
                <div className="configCyclesHoursCalculate__currentValue__value">
                  {isCalculatedByDate
                    ? tools.convertMinutesToHoursMinutesText(
                        flightCyclesByDate.totalEngine1TimeByDate
                      )
                    : tools.convertMinutesToHoursMinutesText(totalEngine1Time)}
                </div>
              </div>
            </div>
            <div className="configCyclesHoursCalculate__rows">
              <div className="configCyclesHoursCalculate__currentValue">
                <div className="configCyclesHoursCalculate__currentValue__label">
                  {"Engine (" + engine1Serial + ") Cycles"}
                </div>
                <div className="configCyclesHoursCalculate__currentValue__value">
                  {isCalculatedByDate
                    ? tools.formatCyclesText(
                        flightCyclesByDate.totalEngine1CyclesByDate
                      )
                    : tools.formatCyclesText(totalEngine1Cycles)}
                </div>
              </div>
            </div>
            <div className="configCyclesHoursCalculate__rows">
              <div className="configCyclesHoursCalculate__currentValue">
                <div className="configCyclesHoursCalculate__currentValue__label">
                  {"Engine (" + engine2Serial + ") Hours"}
                </div>
                <div className="configCyclesHoursCalculate__currentValue__value">
                  {isCalculatedByDate
                    ? tools.convertMinutesToHoursMinutesText(
                        flightCyclesByDate.totalEngine2TimeByDate
                      )
                    : tools.convertMinutesToHoursMinutesText(totalEngine2Time)}
                </div>
              </div>
            </div>
            <div className="configCyclesHoursCalculate__rows">
              <div className="configCyclesHoursCalculate__currentValue">
                <div className="configCyclesHoursCalculate__currentValue__label">
                  {"Engine (" + engine2Serial + ") Cycles"}
                </div>
                <div className="configCyclesHoursCalculate__currentValue__value">
                  {isCalculatedByDate
                    ? tools.formatCyclesText(
                        flightCyclesByDate.totalEngine2CyclesByDate
                      )
                    : tools.formatCyclesText(totalEngine2Cycles)}
                </div>
              </div>
            </div>
          </div>
          <div className="configCyclesHoursCalculate__dateTimePicker">
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={datePickerTheme}>
                <DatePicker
                  value={makeLocalAppearUTC(selectedDate)}
                  onChange={localToUTC}
                  format="DD/MM/YYYY HH:mm"
                  disableFuture
                />
              </ThemeProvider>
            </StyledEngineProvider>
            {compareDates() ? (
              <span
                className="configCyclesHoursCalculate__info"
                style={{ color: "red" }}
              >
                *The selected datetime must not be over the current UTC datetime{" "}
                {UTC_TIME}
              </span>
            ) : null}
            <span className="configCyclesHoursCalculate__info">
              Date format is (DD-MM-YYYY)
            </span>
            <span className="configCyclesHoursCalculate__info">
              Input hours and press
              <span style={{ fontWeight: "bold" }}> APPLY </span>to recalculate
              hour and cycle data.
            </span>
            <span className="configCyclesHoursCalculate__info">
              Press<span style={{ fontWeight: "bold" }}> RESET </span>to obtain
              latest hour and cycle data.
            </span>
            <span className="configCyclesHoursCalculate__dateTimePicker__resetButton">
              <CustomButton
                id="id-configCyclesHoursCalculate-apply"
                text="APPLY"
                buttonStyle="pill"
                isDisabled={false}
                preventDefault
                onClickAction={handleApply}
              />
              <CustomButton
                id="id-configCyclesHoursCalculate-reset"
                text="RESET"
                buttonStyle="pill"
                isDisabled={false}
                preventDefault
                onClickAction={handleReset}
              />
            </span>
          </div>
        </form>
      </div>
    </LocalizationProvider>
  );
};

export default ConfigCyclesHoursCalculate;
