import React from "react";
import Chart from "../../Graph Components/Chart.js";
import { DragDropContext } from "react-beautiful-dnd";
import "./chartsController.css";
import FeatherIcon from "feather-icons-react";

export default function ChartsController(props) {
  let chartHeader = "Flight Parameter Data";
  if (props.loadedTemplate) {
    chartHeader = props.loadedTemplate.templateName;
  } else if (props.dataFocus === "FAULT" || props.dataFocus === "IN-FLIGHT") {
    chartHeader = props.faultCode + " FAULT Parameter Data";
  }

  const [snapshotCollapsed, setSnapshotCollapsed] = React.useState(false);

  return (
    <div
      id="charts-controller"
      style={{ color: "white", paddingBottom: "100px" }}
    >
      {props.snapshotParameters.length > 0 && (
        <div className="chart-container">
          <div className="chart-container-header">
            <div className="chart-container-header-collapse">
              <FeatherIcon
                icon={snapshotCollapsed ? "chevron-down" : "chevron-up"}
                height={30}
                className="chart-container-header-collapse-icon"
                onClick={(event) => {
                  setSnapshotCollapsed(!snapshotCollapsed);
                }}
              />
            </div>
            <div className="chart-container-header-title">
              Snapshot Single parameter data
            </div>
          </div>
          {!snapshotCollapsed && (
            <div className="row">
              {props.snapshotParameters.map((param) => (
                <div className="column snapshot-parameter">
                  <span style={{ fontWeight: "bold" }}>
                    {param.englishName}:{" "}
                  </span>
                  {param.seriesData[0][1]} {param.unit}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {props.charts.length > 0 && (
        <div className="charts-title">
          {props.loadedTemplate && (
            <div style={{ paddingRight: "16px" }}>
              {props.loadedTemplate.templatePrivacyPolicy === "PRIVATE" && (
                <FeatherIcon icon="user" height={24} />
              )}
              {props.loadedTemplate.templatePrivacyPolicy === "INT_PUBLIC" && (
                <FeatherIcon icon="users" height={24} />
              )}
              {props.loadedTemplate.templatePrivacyPolicy === "EXT_PUBLIC" && (
                <FeatherIcon icon="star" width={24} height={24} />
              )}
            </div>
          )}
          <span>{chartHeader}</span>
        </div>
      )}
      <DragDropContext onDragEnd={props.onDragEnd}>
        {props.charts.map((chart, index) => (
          <React.Fragment key={chart.id}>
            <Chart
              expandedLegend={props.expandedLegend}
              chartIndex={chart.chartIndex}
              englishName={chart.englishName}
              unit={chart.unit}
              lru={chart.lru}
              series={chart.series}
              options={chart.options}
              type={chart.type}
              size={chart.size}
              id={chart.id}
              height={chart.height}
              index={index}
              dataFocus={props.dataFocus}
              tail={props.tail}
              faultCode={props.faultCode}
              faultTimeStamp={props.faultTimeStamp}
              chartNumberMax={props.charts.length}
              toggleParameterFn={props.toggleParameterFn}
              downloadXLSX={props.downloadXLSX}
              save={() => {
                return 0;
              }}
              updateCharts={() => {
                return 0;
              }}
              deleteChart={props.deleteChart}
              removeParameter={props.removeParameter}
              updateChartName={() => {
                return 0;
              }}
              handleChartDisplace={props.moveChart}
              resizeLegend={props.resizeLegend}
            />
          </React.Fragment>
        ))}
      </DragDropContext>
    </div>
  );
}
