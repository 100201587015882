import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import "../styles/Global Components/FilterBox.css";

const CustomTextField = styled(TextField)({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  margin: "0px",

  "& label.Mui-focused": {
    color: "#D2D2D2",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#D2D2D2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D2D2D2",
      borderWidth: "1px",
      borderRadius: "0px",
    },
    "&:hover fieldset": {
      borderColor: "#D2D2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D2D2D2",
    },
  },
  "& .MuiOutlinedInput-adornedEnd": {
    paddingRight: "10px",
  },
});

function FilterBox({
  id = "general-filter-box",
  placeholderText = "",
  handleFilterChange,
  customWidth,
  customHeight,
  borderThickness,
  autoComplete = "off",
  customBorderRadius,
}) {
  const [textValue, setTextValue] = useState("");

  const handleTextChange = (textFieldValue) => {
    setTextValue(textFieldValue);
    handleFilterChange(textFieldValue);
  };

  return (
    <div className="filterBox">
      <CustomTextField
        id={id}
        value={textValue}
        placeholder={placeholderText}
        fullWidth={true}
        type="text"
        margin="none"
        autoComplete={autoComplete}
        onChange={(event) => handleTextChange(event.target.value)}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              classes={{ positionEnd: "filterBox__inputAdornment" }}
            >
              <Search style={{ color: "#FFFFFF" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          minWidth: customWidth,
          "& .MuiOutlinedInput-root": {
            height: customHeight,
            "& fieldset": {
              borderWidth: borderThickness === "thick" ? "2px" : "1px",
              borderRadius: customBorderRadius === "rounded" ? "20px" : "0px",
            },
          },
        }}
      />
    </div>
  );
}

export default FilterBox;

FilterBox.defaultProps = {
  customWidth: "100%",
  customHeight: "46px",
  borderThickness: "thin",
  customBorderRadius: "rectangular",
};

FilterBox.propTypes = {
  customWidth: PropTypes.string,
  customHeight: PropTypes.string,
  borderThickness: PropTypes.string,
  customBorderRadius: PropTypes.string,
};
