import { ThemeProvider } from '@emotion/react'
import { Chip } from '@mui/material'
import FeatherIcon from 'feather-icons-react'
import _ from 'lodash'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Wizard from '../Application Onboarding Components/Wizard.js'
import Banner from '../Global Components/Banner'
import Breadcrumb from '../Global Components/Breadcrumb/Breadcrumb'
import CustomButton from '../Global Components/CustomButton'
import CustomTooltip from '../Global Components/CustomTooltip'
import FilterBox from '../Global Components/FilterBox'
import NotificationPopup from '../Global Components/NotificationPopup.js'
import Spinner from '../Global Components/Spinner.js'
import ToggleButton from '../Global Components/ToggleButton'
import { getFleet } from '../clients/FleetAlertsClient'
import { colors, customTheme } from '../styles/theme.js'
import * as tools from '../utils/CommonTools'
import './FleetAlerts.css'
import FleetList from './FleetList'
import { FleetViewDropdown } from './FleetViewDropdown'
import Puck from './Puck'

/**
 * The Fleet View component.
 */
export default class FleetAlerts extends Component {
    constructor(props) {
        super(props)
        this.homeCrumb = {
            view: '/fleetview',
            value: 'Home'
        }
        tools.cleanAhmsLocalStorage()
        tools.cleanAircrfatLocalStorage()

        this.renderFleetViewContent = this.renderFleetViewContent.bind(this)
        this.handleFleetViewGridRender = this.handleFleetViewGridRender.bind(this)
        this.handleViewTypeToggle = this.handleViewTypeToggle.bind(this)
        this.toggleStatusFilter = this.toggleStatusFilter.bind(this)
        this.resetToggle = this.resetToggle.bind(this)
        this.closeNotificationModal = this.closeNotificationModal.bind(this)
        this.transformNoticeFrequency = this.transformNoticeFrequency.bind(this)
        this.updateCurrentAircraftModel = this.updateCurrentAircraftModel.bind(this)
        this.handleTextFieldTailFilter = this.handleTextFieldTailFilter.bind(this)

        this.state = {
            userName: localStorage.getItem('userInfo')
                ? JSON.parse(localStorage.getItem('userInfo')).name
                : '',
            userOrganizationId: localStorage.getItem('userInfo')
                ? JSON.parse(localStorage.getItem('userInfo')).orgId
                : '',
            userId: localStorage.getItem('userInfo')
                ? JSON.parse(localStorage.getItem('userInfo')).userId
                : '',
            pucks: [],
            serviceLvlPucks: [],
            showPucks: [],
            isLoading: true,
            filterExpanded: false,
            toggleInFlightActive: false,
            toggleLandedActive: false,
            toggleWarningActive: false,
            toggleCautionActive: false,
            toggleAdvisoryActive: false,
            toggleInfoActive: false,
            notifModalState: false,
            alternativeExistLink: '',
            errorColorCode: '',
            errorDescription: '',
            errorCode: 'ER-FL-00000',
            errorType: '',
            technical_detail: '',
            existingAircraftModel: [],
            currentAircraftModel:
                localStorage.getItem('defaultAircraftModelSelection') !== null
                    ? tryParseJson(localStorage.getItem('defaultAircraftModelSelection'), {})
                    : {},
            fleetViewGrid:
                localStorage.getItem('defaultGridView') === null ||
                localStorage.getItem('defaultGridView') === 'true'
                    ? true
                    : false,
            fleetFilterBoxText: '',
            errorObject: null
        }
    }

    // A temporary function that will transform the frequency from single digits to specicied time periods.
    // TODO: This needs to be formaly created in a global component and apply the precise rules discussed
    transformNoticeFrequency(originalFrequency) {
        switch (originalFrequency) {
            case '0':
                return '8640'
            case '1':
                return '24'
            case '2':
                return '24'
            case '3':
                return '1'
            case '4':
                return '168'
            case '5':
                return '720'
            case '6':
                return '8640'
            default:
                return '8640'
        }
    }

    componentDidMount() {
        getFleet()
            .then(res => {
                const pucks = []

                if (res.data !== '' && res.status !== 204) {
                    res.data.forEach(tail => {
                        if (
                            _.find(tools.allowedAircraftFamilyModels(), {
                                aircraftFamily: tail.tails.aircraftFamily,
                                aircraftModel: tail.tails.aircraftModel
                            })
                        ) {
                            // NOTE: This is a temporary fix it should be deleted once backend has fixed it
                            if (
                                !_.find(pucks, {
                                    tailNumber: tail.tails.tailNumber
                                })
                            ) {
                                pucks.push(tail.tails)
                            }
                        }
                    })
                }

                var airModel = new Set()

                pucks.forEach(item => {
                    airModel.add(item['aircraftFamily'] + ' ' + item['aircraftModel'])
                })

                const ifm = this.inFlightMonitoring(pucks)
                const serviceLevelPucks = ifm.inFlightPucks
                    .concat(ifm.landedPucks)
                    .concat(ifm.noStatusPucks)

                const inFlightPucks = _.orderBy(
                    _.filter(serviceLevelPucks, ['status', 'IN-FLIGHT']),
                    [
                        'flagSeverityTop',
                        'flagSeverityBottom',
                        'flagSeverityTimeTop',
                        'flagSeverityTimeBottom'
                    ],
                    ['asc', 'asc', 'desc', 'desc']
                )
                const landedPucks = _.orderBy(
                    _.filter(serviceLevelPucks, ['status', 'ON GROUND']),
                    [
                        'flagSeverityTimeTop',
                        'flagSeverityTimeBottom',
                        'flagSeverityTop',
                        'flagSeverityBottom'
                    ],
                    ['desc', 'desc', 'asc', 'asc']
                )

                const noStatusPucks = _.orderBy(
                    _.filter(serviceLevelPucks, ['status', '-']),
                    [
                        'flagSeverityTimeTop',
                        'flagSeverityTimeBottom',
                        'flagSeverityTop',
                        'flagSeverityBottom'
                    ],
                    ['desc', 'desc', 'asc', 'asc']
                )

                let sortedServiceLevelPucks = _.concat(inFlightPucks, landedPucks, noStatusPucks)

                var showPucks = sortedServiceLevelPucks
                let aircraftModelSelection = this.state.currentAircraftModel

                if (
                    aircraftModelSelection !== null &&
                    Object.keys(aircraftModelSelection).length !== 0
                ) {
                    showPucks = _.filter(sortedServiceLevelPucks, function (puck) {
                        let showPuck = true
                        showPuck =
                            showPuck &&
                            aircraftModelSelection[puck.aircraftFamily + ' ' + puck.aircraftModel]

                        if (showPuck) {
                            return puck
                        }
                    })
                }

                this.setState({
                    isLoading: false,
                    pucks,
                    serviceLvlPucks: sortedServiceLevelPucks,
                    showPucks: aircraftModelSelection ? showPucks : sortedServiceLevelPucks,
                    existingAircraftModel: [...airModel].sort()
                })
            })
            .catch(error => {
                console.error(`Error : ${error}`)
                this.setState({
                    isLoading: false,
                    errorObject: error
                })
                this.openNotificationModal({ errorCode: 'ER-FL-00001' })
            })
    }

    // Splitting out tails that aren't signed up for in flight monitoring
    inFlightMonitoring(pucks) {
        const inFlightPucks = []
        const landedPucks = []
        const noStatusPucks = []

        pucks.forEach(puck => {
            puck.metaStatus = puck.status ? puck.status : '-1'
            puck.status = tools.convertAircraftFlightStatus(puck.status)
            this.addPuckSortingInfo(puck)
            const serviceLevelValidation =
                puck.serviceLevel === '0' ||
                puck.serviceLevel === '1' ||
                puck.serviceLevel === '2' ||
                puck.serviceLevel === 'None' ||
                puck.serviceLevel === '0.0' ||
                puck.serviceLevel === '1.0' ||
                puck.serviceLevel === '2.0' ||
                puck.serviceLevel === 'nan'

            if (serviceLevelValidation && puck.status === 'IN-FLIGHT') {
                inFlightPucks.push(puck)
            } else if (serviceLevelValidation && puck.status === 'ON GROUND') {
                landedPucks.push(puck)
            } else if (serviceLevelValidation && puck.status === '-') {
                noStatusPucks.push(puck)
            }
        })

        return {
            inFlightPucks,
            landedPucks,
            noStatusPucks
        }
    }

    // Filtering pucks based on status
    async toggleStatusFilter(id, active) {
        await this.toggleOn(id, active)
        this.handleFleetFilter()
    }

    setAircraftModel(currentAircraftModel) {
        this.setState({
            currentAircraftModel
        })
        localStorage.setItem('defaultAircraftModelSelection', JSON.stringify(currentAircraftModel))
    }

    async updateCurrentAircraftModel(currentAircraftModel) {
        await this.setAircraftModel(currentAircraftModel)
        this.handleFleetFilter()
    }

    handleFleetFilter() {
        let filteredPucks = []
        const inFlight = this.state.toggleInFlightActive
        const onGround = this.state.toggleLandedActive
        const warning = this.state.toggleWarningActive
        const caution = this.state.toggleCautionActive
        const advisory = this.state.toggleAdvisoryActive
        const info = this.state.toggleInfoActive
        const currentAircraftModel = this.state.currentAircraftModel
        const currentFilterBoxText = this.state.fleetFilterBoxText
        const fleetViewGrid = this.state.fleetViewGrid

        if (
            inFlight ||
            onGround ||
            warning ||
            caution ||
            advisory ||
            info ||
            Object.keys(currentAircraftModel).length ||
            currentFilterBoxText !== ''
        ) {
            filteredPucks = _.filter(this.state.serviceLvlPucks, function (puck) {
                let showPuck = true

                if (inFlight) {
                    showPuck = showPuck && puck.status === 'IN-FLIGHT'
                }
                if (onGround) {
                    showPuck = showPuck && puck.status === 'ON GROUND'
                }
                if (warning) {
                    showPuck =
                        showPuck &&
                        (puck.flagSeverityTop === 'flag-1-warning' ||
                            puck.flagSeverityBottom === 'flag-1-warning')
                }
                if (caution) {
                    showPuck =
                        showPuck &&
                        (puck.flagSeverityTop === 'flag-2-caution' ||
                            puck.flagSeverityBottom === 'flag-2-caution')
                }
                if (advisory) {
                    if (fleetViewGrid) {
                        showPuck =
                            showPuck &&
                            (puck.flagSeverityTop === 'flag-3-advisory' ||
                                puck.flagSeverityBottom === 'flag-3-advisory')
                    } else {
                        showPuck = showPuck && puck.faultCountAdvisory > 0
                    }
                }
                if (info) {
                    showPuck =
                        showPuck &&
                        (puck.flagSeverityTop === 'flag-5-info' ||
                            puck.flagSeverityBottom === 'flag-5-info')
                }

                if (Object.keys(currentAircraftModel).length) {
                    showPuck =
                        showPuck &&
                        currentAircraftModel[puck.aircraftFamily + ' ' + puck.aircraftModel]
                }

                if (currentFilterBoxText !== '') {
                    showPuck =
                        showPuck &&
                        (puck.tailNumber
                            .toUpperCase()
                            .includes(currentFilterBoxText.toUpperCase()) ||
                            puck.serialNumber
                                .toUpperCase()
                                .includes(currentFilterBoxText.toUpperCase()))
                }

                if (showPuck) {
                    return puck
                }
            })
        } else {
            filteredPucks = this.state.serviceLvlPucks
        }

        this.setState({
            showPucks: filteredPucks
        })
    }

    toggleOn(id, active) {
        if (id === 'IN-FLIGHT') {
            this.setState({
                toggleInFlightActive: active,
                toggleLandedActive: active ? false : this.state.toggleLandedActive
            })
        } else if (id === 'ON-GROUND') {
            this.setState({
                toggleLandedActive: active,
                toggleInFlightActive: active ? false : this.state.toggleInFlightActive
            })
        } else if (id === 'WARNING') {
            this.setState({
                toggleWarningActive: active
            })
        } else if (id === 'CAUTION') {
            this.setState({
                toggleCautionActive: active
            })
        } else if (id === 'ADVISORY') {
            this.setState({
                toggleAdvisoryActive: active
            })
        } else if (id === 'INFO') {
            this.setState({
                toggleInfoActive: active
            })
        }
    }

    // reseting toggles before styles based on filter are applied
    resetToggle() {
        this.setState(
            {
                toggleInFlightActive: false,
                toggleLandedActive: false,
                toggleWarningActive: false,
                toggleCautionActive: false,
                toggleAdvisoryActive: false,
                toggleInfoActive: false
            },
            () => {
                this.handleFleetFilter()
            }
        )
    }

    addPuckSortingInfo(puck) {
        const statWarning = _.find(puck.faults, ['severity', 'Warning'])
        const statCaution = _.find(puck.faults, ['severity', 'Caution'])
        const statAdvisory = _.find(puck.faults, ['severity', 'Advisory'])
        // These will be used until there is a timestamp that can be returned that displays session date
        const statStatus = _.find(puck.faults, ['severity', 'Status'])
        const statNonDisplay = _.find(puck.faults, ['severity', 'Non-display'])

        puck.flagSeverityTop = puck.flagSeverityBottom = 'flag-5-info'
        puck.flagCountTop = puck.flagCountBottom = 0
        puck.flagSeverityTimeTop = puck.flagSeverityTimeBottom = ''
        puck.faultCountWarning = 0
        puck.faultCountCaution = 0
        puck.faultCountAdvisory = 0

        // These will be used until there is a timestamp that can be returned that displays session date
        if (statStatus !== undefined) {
            puck.flagSeverityTimeTop = puck.flagSeverityTimeBottom = statStatus.recentTimestamp
                ? statStatus.recentTimestamp
                : ''
        }
        if (statNonDisplay !== undefined) {
            puck.flagSeverityTimeTop = puck.flagSeverityTimeBottom = statNonDisplay.recentTimestamp
                ? statNonDisplay.recentTimestamp
                : ''
        }

        if (statAdvisory !== undefined) {
            puck.flagSeverityTop = 'flag-3-advisory'
            puck.flagCountTop = statAdvisory.count.toString()
            puck.flagSeverityTimeTop = statAdvisory.recentTimestamp
            puck.faultCountAdvisory = statAdvisory.count.toString()
        }
        if (statCaution !== undefined) {
            puck.flagSeverityBottom = puck.flagSeverityTop
            puck.flagCountBottom = puck.flagCountTop.toString()
            puck.flagSeverityTimeBottom = puck.flagSeverityTimeTop

            puck.flagSeverityTop = 'flag-2-caution'
            puck.flagCountTop = statCaution.count.toString()
            puck.flagSeverityTimeTop = statCaution.recentTimestamp
            puck.faultCountCaution = statCaution.count.toString()
        }
        if (statWarning !== undefined) {
            puck.flagSeverityBottom = puck.flagSeverityTop
            puck.flagCountBottom = puck.flagCountTop.toString()
            puck.flagSeverityTimeBottom = puck.flagSeverityTimeTop

            puck.flagSeverityTop = 'flag-1-warning'
            puck.flagCountTop = statWarning.count.toString()
            puck.flagSeverityTimeTop = statWarning.recentTimestamp
            puck.faultCountWarning = statWarning.count.toString()
        }
        if (puck.flagSeverityBottom === 'flag-5-info' && puck.flagSeverityTop !== 'flag-5-info') {
            puck.flagSeverityBottom = puck.flagSeverityTop
            puck.flagCountBottom = puck.flagCountTop.toString()
            puck.flagSeverityTimeBottom = puck.flagSeverityTimeTop
        }
    }

    openNotificationModal({
        errorColorCode,
        errorDescription,
        alternativeExistLink,
        errorCode,
        errorType
    }) {
        this.setState({
            errorColorCode: errorColorCode,
            errorDescription: errorDescription,
            alternativeExistLink: alternativeExistLink,
            notifModalState: true,
            errorCode: errorCode,
            errorType: errorType
        })
    }

    closeNotificationModal = () => {
        this.setState({
            notifModalState: false,
            errorDescription: '',
            alternativeExistLink: null,
            errorColorCode: '',
            errorCode: 'ER-AH-00000',
            errorType: '',
            technical_detail: ''
        })
    }

    handleViewTypeToggle(viewType) {
        const newViewType = viewType === 'grid'
        if (this.state.fleetViewGrid !== newViewType) {
            localStorage.setItem('defaultGridView', !this.state.fleetViewGrid)
            this.setState(
                {
                    fleetViewGrid: !this.state.fleetViewGrid
                },
                () => {
                    this.handleFleetFilter()
                }
            )
        }
    }

    handleTextFieldTailFilter = filterText => {
        this.setState(
            {
                fleetFilterBoxText: filterText
            },
            () => {
                this.handleFleetFilter()
            }
        )
    }

    render() {
        return (
            <ThemeProvider theme={customTheme}>
                <div className='fleetView'>{this.renderFleetViewContent(this.state.isLoading)}</div>
            </ThemeProvider>
        )
    }

    renderFleetViewContent(loading) {
        const loader = (
            <div
                style={{
                    position: 'fixed',
                    width: '100%',
                    marginLeft: 'calc(-7% + 60px)',
                    marginRight: '-7%',
                    marginTop: '-50px',
                    height: '100%',
                    backgroundColor: 'rgb(0,0,0,.5)',
                    zIndex: '2000'
                }}
            >
                <div style={{ width: '100px', paddingTop: '20%', margin: 'auto' }}>
                    <Spinner />
                </div>
            </div>
        )

        const errorNotification = (
            <NotificationPopup
                userName={this.state.userName}
                open={this.state.notifModalState}
                closeModal={this.closeNotificationModal}
                description={this.state.errorDescription}
                alternativeExistLink={this.state.alternativeExistLink}
                color={this.state.errorColorCode}
                errorCode={this.state.errorCode}
                type={this.state.errorType}
                technical_detail={this.state.technical_detail}
                errorObject={this.state.errorObject}
            />
        )

        const fleetViewContent = (
            <div className='fleetView__content'>
                <Breadcrumb crumbItem={this.homeCrumb} display={false}></Breadcrumb>
                <Banner pageType={'Fleet View'} icon={'grid'} logoText={'BOMBARDIER'} />
                <div className='fleetView__body'>
                    <div
                        className={
                            'fleetView__filterContainer' +
                            (this.state.filterExpanded
                                ? ' fleetView__filterContainer__expanded'
                                : '')
                        }
                    >
                        <div className='fleetView__filterContainer__header'>
                            <span>Filter</span>
                        </div>
                        <div
                            className={
                                'fleetView__filterContainer__body' +
                                (this.state.filterExpanded
                                    ? ' fleetView__filterContainer__body__expanded'
                                    : '')
                            }
                            id='fleetView_filter_whole'
                        >
                            <ToggleButton
                                id={'IN-FLIGHT'}
                                expanded={this.state.filterExpanded}
                                disabled={false}
                                active={this.state.toggleInFlightActive}
                                onToggleEvent={this.toggleStatusFilter}
                                label={'IN-FLIGHT'}
                                labelExpand={'Status: IN-FLIGHT'}
                                btnStyle={'toggle__aircraftStatus'}
                                btnStyleActive={'toggle__active'}
                                btnStyleExpanded={'toggle__aircraftStatus__expanded'}
                            />
                            <ToggleButton
                                id={'ON-GROUND'}
                                expanded={this.state.filterExpanded}
                                disabled={false}
                                active={this.state.toggleLandedActive}
                                onToggleEvent={this.toggleStatusFilter}
                                label={'ON GROUND'}
                                labelExpand={'Status: ON GROUND'}
                                btnStyle={'toggle__aircraftStatus'}
                                btnStyleActive={'toggle__active'}
                                btnStyleExpanded={'toggle__aircraftStatus__expanded'}
                            />
                            <div
                                className={
                                    'fleetView__filterContainer__body__separator' +
                                    (this.state.filterExpanded
                                        ? ' fleetView__filterContainer__body__separator__expanded'
                                        : '')
                                }
                            ></div>
                            <ToggleButton
                                id={'WARNING'}
                                expanded={this.state.filterExpanded}
                                disabled={false}
                                active={this.state.toggleWarningActive}
                                onToggleEvent={this.toggleStatusFilter}
                                label={'WARNING'}
                                btnStyle={'toggle__warning'}
                                btnStyleActive={'toggle__active'}
                                btnStyleExpanded={'toggle__warning__expanded'}
                            />
                            <ToggleButton
                                id={'CAUTION'}
                                expanded={this.state.filterExpanded}
                                disabled={false}
                                active={this.state.toggleCautionActive}
                                onToggleEvent={this.toggleStatusFilter}
                                label={'CAUTION'}
                                btnStyle={'toggle__caution'}
                                btnStyleActive={'toggle__active'}
                                btnStyleExpanded={'toggle__caution__expanded'}
                            />
                            <ToggleButton
                                id={'ADVISORY'}
                                expanded={this.state.filterExpanded}
                                disabled={false}
                                active={this.state.toggleAdvisoryActive}
                                onToggleEvent={this.toggleStatusFilter}
                                label={'ADVISORY'}
                                btnStyle={'toggle__advisory'}
                                btnStyleActive={'toggle__active'}
                                btnStyleExpanded={'toggle__advisory__expanded'}
                            />
                        </div>
                        <div
                            className={
                                'fleetView__filterContainer__footer' +
                                (this.state.filterExpanded
                                    ? ' fleetView__filterContainer__footer__expanded'
                                    : '')
                            }
                        >
                            <CustomButton
                                id={'btn-deselect'}
                                text={this.state.filterExpanded ? 'RESET FILTER' : 'RESET'}
                                icon={
                                    <FeatherIcon
                                        icon={'filter'}
                                        height={20}
                                        width={20}
                                        color={'#FFFFFF'}
                                    />
                                }
                                iconPosition={'end'}
                                onClickAction={this.resetToggle}
                            />
                        </div>
                    </div>
                    <div
                        className={
                            'fleetView__fleetContainer' +
                            (this.state.filterExpanded
                                ? ' fleetView__fleetContainer__expanded'
                                : '')
                        }
                    >
                        <div className='fleetView__fleetContainer__body'>
                            <div className='fleetView__fleetContainer__tool'>
                                <div
                                    className='fleetView_search_dropdown_container'
                                    id='fleetView_search_dropdown_container'
                                >
                                    <div className='fleetView__search'>
                                        <FilterBox
                                            id={'fleet-filter-box'}
                                            placeholderText={'Filter by Tail or Registration #'}
                                            handleFilterChange={this.handleTextFieldTailFilter}
                                            customWidth={'300px'}
                                            borderThickness={'thick'}
                                        />
                                    </div>
                                    <div className='fleetView__dropdown'>
                                        {/* <Dropdown
                                            options={this.state.existingAircraftModel}
                                            defaultValue={this.state.currentAircraftModel}
                                            onChange={this.updateCurrentAircraftModel}
                                            customWidth={'215px'}
                                        /> */}

                                        <FleetViewDropdown
                                            existingAircraftModel={this.state.existingAircraftModel}
                                            value={Object.keys(this.state.currentAircraftModel)}
                                            onChange={(e, value) => {
                                                // Convert from array of strings to object with true values
                                                // Empty object represents no filters show all mode
                                                const currentAircraftModel = value.reduce(
                                                    (acc, val) => ({ ...acc, [val]: true }),
                                                    {}
                                                )
                                                this.updateCurrentAircraftModel(
                                                    currentAircraftModel
                                                )
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='fleetView__fleetContainer__viewToggleSection'>
                                    <div
                                        className='fleetView_toggle_container'
                                        id='fleetView_toggle_container'
                                    >
                                        <CustomTooltip
                                            content={'Grid View'}
                                            tooltipType={'general'}
                                            placement={'bottom'}
                                        >
                                            <div
                                                id='fleetViewGridView'
                                                className={
                                                    'fleetView__fleetContainer__viewToggle' +
                                                    (this.state.fleetViewGrid
                                                        ? ' fleetView__fleetContainer__viewToggle-active'
                                                        : '')
                                                }
                                                onClick={() => this.handleViewTypeToggle('grid')}
                                            >
                                                <FeatherIcon icon={'grid'} height={29} width={29} />
                                            </div>
                                        </CustomTooltip>
                                        <CustomTooltip
                                            content={'List View'}
                                            tooltipType={'general'}
                                            placement={'bottom'}
                                        >
                                            <div
                                                className={
                                                    'fleetView__fleetContainer__viewToggle' +
                                                    (!this.state.fleetViewGrid
                                                        ? ' fleetView__fleetContainer__viewToggle-active'
                                                        : '')
                                                }
                                                onClick={() => this.handleViewTypeToggle('list')}
                                            >
                                                <FeatherIcon icon={'list'} height={29} width={29} />
                                            </div>
                                        </CustomTooltip>
                                    </div>
                                </div>
                            </div>
                            <div className='fleetView__searchDescription'>
                                {localStorage.getItem('userType') === 'customer'
                                    ? ''
                                    : 'Customer permission must be acquired before accessing Aircraft through search bar.'}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '8px',
                                    marginTop: '8px',
                                    marginBottom: '8px'
                                }}
                            >
                                {Object.keys(this.state.currentAircraftModel).map((el, i) => {
                                    return (
                                        <Chip
                                            style={{ backgroundColor: colors.b60 }}
                                            key={i}
                                            label={el}
                                            onDelete={() => {
                                                const currentAircraftModel = {
                                                    ...this.state.currentAircraftModel
                                                }
                                                delete currentAircraftModel[el]
                                                this.updateCurrentAircraftModel(
                                                    currentAircraftModel
                                                )
                                            }}
                                        />
                                    )
                                })}
                                {Object.keys(this.state.currentAircraftModel).length > 0 && (
                                    <Chip
                                        style={{ backgroundColor: colors.b40 }}
                                        label={'Clear All'}
                                        onDelete={() => {
                                            this.updateCurrentAircraftModel({})
                                        }}
                                    />
                                )}
                            </div>
                            {this.handleFleetViewGridRender()}
                        </div>
                    </div>
                </div>
            </div>
        )

        return loading ? loader : this.state.notifModalState ? errorNotification : fleetViewContent
    }

    handleFleetViewGridRender() {
        let fleetView
        let currentAircraftModel = this.state.currentAircraftModel
        let filters = []
        if (this.state.fleetFilterBoxText !== '') filters.push(this.state.fleetFilterBoxText)
        if (this.state.toggleInFlightActive) filters.push('In-Flight')
        if (this.state.toggleLandedActive) filters.push('On-Ground')
        if (this.state.toggleWarningActive) filters.push('Warning')
        if (this.state.toggleCautionActive) filters.push('Caution')
        if (this.state.toggleAdvisoryActive) filters.push('Advisory')

        if (this.state.showPucks.length > 0) {
            if (this.state.fleetViewGrid === false) {
                fleetView = (
                    <div className='fleetView__tableContainer'>
                        <FleetList tailList={this.state.showPucks} />
                    </div>
                )
            } else {
                fleetView = (
                    <div className='fleetView__gridContainer'>
                        <Wizard iofgKey='NEW_USER_ONBOARDING' pageKey='FLEET_V_PG' />
                        <div className='fleetView__fleetGrid'>
                            {this.state.showPucks.map(puck => (
                                <React.Fragment key={puck.serialNumber + '-' + puck.tailNumber}>
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to={{
                                            pathname: `/aircraftview`,
                                            state: {
                                                aircraftFamily: puck.aircraftFamily,
                                                aircraftModel: puck.aircraftModel,
                                                tail: puck.tailNumber,
                                                serial: puck.serialNumber,
                                                monitorStatus: true // Note: This code will be left here for further conversation on aircraft monitoring coming from APIs
                                            }
                                        }}
                                    >
                                        <Puck
                                            tail={puck.tailNumber}
                                            serial={puck.serialNumber}
                                            status={puck.status}
                                            flagSeverityTop={puck.flagSeverityTop}
                                            flagSeverityBottom={puck.flagSeverityBottom}
                                            flagCountTop={puck.flagCountTop}
                                            flagCountBottom={puck.flagCountBottom}
                                            aircraftModel={puck.aircraftModel}
                                            aircraftFamily={puck.aircraftFamily}
                                        />
                                    </Link>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                )
            }
        } else if (filters.length > 0) {
            // If no results because filters
            fleetView = (
                <div className='fleetView__noTails'>
                    Sorry, we couldn't find any matches for {filters.join(', ').toUpperCase()}
                    {Object.keys(currentAircraftModel).length
                        ? ` for ${Object.keys(currentAircraftModel).join(', ')}.`
                        : '.'}
                </div>
            )
        } else {
            fleetView = (
                <div className='fleetView__noTails'>
                    After subscribing, aircraft info will appear here.
                </div>
            )
        }

        return fleetView
    }
}

const tryParseJson = (json_string, default_value = undefined) => {
    try {
        return JSON.parse(json_string)
    } catch (error) {
        return default_value
    }
}
