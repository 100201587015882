import React from "react";
import Header from "./Header";
import FeatherIcon from "feather-icons-react";

function Banner({ pageType, logoText, icon }) {
    return (
        <div style={{
            display: "flex",
            flexFlow: "column",
            flexWrap: "wrap",
            width: "100%",
            minWidth: "860px",
            // height: "100%",
            color: "#ffffff",
            fontFamily: "DM Sans",
        }} >
            <div style={{
                display: "flex",
                flexFlow: "row",
                width: "100%",
                minHeight: "70px",
                justifyContent: "flex-end",
                alignItems: "flex-start",
            }}>
                <div style={{
                    display: "flex",
                    flexFlow: "row",
                    flex: "50%",
                    height: "100%",
                    alignItems: "flex-start",
                    fontSize: "calc(22px + 0.4vw)",
                    lineHeight: "35px",
                    letterSpacing: "0.7px"
                }}>
                    <FeatherIcon
                        icon={icon}
                        height={32}
                        width={32}
                        color={"#FFFFFF"}
                        style={{ marginRight: "18px" }}
                    />
                    <span>{pageType}</span>
                </div>
                <div style={{
                    display: "flex",
                    flexFlow: "column",
                    flex: "50%",
                    height: "100%",
                    alignItems: "flex-end",
                }}>
                    <Header logoText={logoText} />
                </div>
            </div>
        </div>
    );
}

export default Banner;