import React from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import { Modal, Fade } from "@mui/material";
import Xarrow from "react-xarrows";

import graph from "./GraphExplanation.png"

import "./GraphExplanationModal.css"

const GraphExplanationModal = (props) => {
    const {
        show,
        handleGraphIconClick
    } = props;

    const closeGraphModal = () => {
        handleGraphIconClick(false)
    };

    return (
        <Modal
            className="graphExplanation__modalContainer"
            open={show}
            onClose={closeGraphModal}
            closeAfterTransition
        >
            <Fade in={show}>
                <div className="graphExplanation__containter">
                    <div className="graphExplanation__header">
                        <div>
                            Graph Explanation
                        </div>
                        <hr />
                        <div
                            className="graphExplanation__closeIcon"
                            onClick={closeGraphModal}
                        >
                            <FeatherIcon
                                width={30}
                                height={30}
                                color={"#FFFFFF"}
                                icon={"x"}
                            />
                        </div>
                    </div>
                    <div className="graphExplanation__body">
                        <div className="graphExplanation__body_top">
                            <span style={{ fontWeight: 600 }}>How to interpret the graph:</span>
                            <ol>
                                <li>Ensure the component is NEW, the prognostics models assumes a new component was installed</li>
                                <li>Follow the in-service prognosis curve which shows how the component will degrade under degradation conditions</li>
                                <li>Follow the in-service failure limit curve which shows the health index when most components had to be replaced in the past for unscheduled reasons</li>
                                <li>The intersection of the in-service failure limit and in-service prognosis curve gives an indication of when to replace the part under in-service conditions</li>
                                <li>The intersection of the design prognosis curve and the in-service failure limit gives a replacement date that is more optimistic</li>
                                <li>Finally use the scheduled removal date to estimate how good or bad the component is doing relatively to the maintenance process</li>
                            </ol>
                        </div>
                        <div className="graphExplanation__body_bottom">
                            <div className="explanations__container">
                                <div className="point__explanation padRight">
                                    <span id="part-total-hours-explanation">Part Total Hours
                                    </span>
                                    <br />
                                    <span>
                                        The number of in-service-hours since the installation date of the component on the day the anomaly card was generated.
                                    </span>
                                </div>
                                <div style={{ marginLeft: 100 }} className="point__explanation padLeft">
                                    <span id="suggested-removal-explanation">Suggested Removal Date
                                    </span>
                                    <br />
                                    <span>
                                        The date suggested by the model for replacement of the component based on the In-service Prognosis Curve and previously observed replacement dates for this component
                                    </span>
                                </div>
                                <div className="point__explanation padLeft">
                                    <span id="design-removal-explanation" >Design removal date / Scheduled removal date
                                    </span>
                                    <br />
                                    <span>
                                        Last ditch removal date, based on supplier specifications. If the value is provided in cycles, equivalent flight hours are inferred from average flight duration and frequency
                                    </span>
                                </div>
                            </div>
                            <div className="explanations__container">
                                <div style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <div className="point__explanation padRight">
                                        <span id="y-axis-explanation" >Vertical Axis – Health index
                                        </span>
                                        <br />
                                        <span>
                                            Defined as value from 0-100%. Represents the health index values. 100% means they are NOT just newly installed but are NEW*. Generally speaking, the component tends to need replace when the health index is around 65%. This is when most components have been replaced for unscheduled reasons in the past
                                        </span>
                                    </div>
                                </div>
                                <div className="graphExplanation__imageContainer">
                                    <span
                                        id="part-total-hours"
                                        className="graphExplanation__point"></span>
                                    <span
                                        id="suggested-removal"
                                        className="graphExplanation__point"></span>
                                    <span
                                        id="design-removal"
                                        className="graphExplanation__point"></span>
                                    <span
                                        id="design-failure-limit"
                                        className="graphExplanation__point"></span>
                                    <span
                                        id="in-service-failure-limit"
                                        className="graphExplanation__point"></span>
                                    <span
                                        id="in-service-prognosis"
                                        className="graphExplanation__point"></span>
                                    <span
                                        id="design-prognosis"
                                        className="graphExplanation__point"></span>
                                    <span
                                        id="x-axis"
                                        className="graphExplanation__point"></span>
                                    <span
                                        id="y-axis"
                                        className="graphExplanation__point"></span>
                                    <img src={graph} alt="Graph" />
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexFlow: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}>
                                    <div className="point__explanation padLeft">
                                        <span id="in-service-failure-limit-explanation" >In Service Failure Limit
                                        </span>
                                        <br />
                                        <span>
                                            The Health Index value when most components were observed to be replaced for unscheduled reasons in the past
                                        </span>
                                    </div>
                                    <div className="point__explanation padLeft">
                                        <span id="design-failure-limit-explanation" >Design Failure Limit
                                        </span>
                                        <br />
                                        <span>
                                            The Health Index value when the components should be replaced based on the theoretical/ design life limit of the component
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="explanations__container">
                                <div className="point__explanation padRight">
                                    <span id="x-axis-explanation" >Horizontal Axis – Component Life
                                    </span>
                                    <br />
                                    <span>
                                        Calculated in flight hours, represents amount of flight hours accumulated on component since last installation date
                                    </span>
                                </div>
                                <div className="point__explanation padRight">
                                    <span id="in-service-prognosis-explanation" >In Service Prognosis Curve
                                    </span>
                                    <br />
                                    <span>
                                        Represents the observed degradation of the part considering the anomalies captured by the condition monitoring module
                                    </span>
                                </div>
                                <div className="point__explanation padLeft">
                                    <span id="design-prognosis-explanation" >Design Prognosis Curve
                                    </span>
                                    <br />
                                    <span>
                                        Represents the expected normal degradation of a part assuming no anomalies
                                    </span>
                                </div>
                            </div>
                            <Xarrow
                                color={"white"}
                                strokeWidth={1}
                                path={"grid"}
                                headShape={"circle"}
                                start={"part-total-hours"}
                                end={"part-total-hours-explanation"}
                            />
                            <Xarrow
                                color={"white"}
                                strokeWidth={1}
                                path={"grid"}
                                headShape={"circle"}
                                start={"suggested-removal"}
                                end={"suggested-removal-explanation"}
                            />
                            <Xarrow
                                color={"white"}
                                strokeWidth={1}
                                path={"grid"}
                                headShape={"circle"}
                                start={"design-removal"}
                                end={"design-removal-explanation"}
                            />
                            <Xarrow
                                color={"white"}
                                strokeWidth={1}
                                path={"grid"}
                                headShape={"circle"}
                                start={"design-failure-limit"}
                                end={"design-failure-limit-explanation"}
                            />
                            <Xarrow
                                color={"white"}
                                strokeWidth={1}
                                path={"grid"}
                                headShape={"circle"}
                                start={"in-service-failure-limit"}
                                end={"in-service-failure-limit-explanation"}
                            />
                            <Xarrow
                                color={"white"}
                                strokeWidth={1}
                                path={"grid"}
                                headShape={"circle"}
                                start={"in-service-prognosis"}
                                end={"in-service-prognosis-explanation"}
                            />
                            <Xarrow
                                color={"white"}
                                strokeWidth={1}
                                path={"grid"}
                                headShape={"circle"}
                                start={"design-prognosis"}
                                end={"design-prognosis-explanation"}
                            />
                            <Xarrow
                                color={"white"}
                                strokeWidth={1}
                                path={"grid"}
                                headShape={"circle"}
                                start={"x-axis"}
                                end={"x-axis-explanation"}
                            />
                            <Xarrow
                                color={"white"}
                                strokeWidth={1}
                                path={"grid"}
                                headShape={"circle"}
                                start={"y-axis"}
                                end={"y-axis-explanation"}
                            />
                        </div>
                    </div>
                    <div className="graphExplanation__footer">
                        *Old components that are rotated in DO NOT apply and it is up to the user to understand that this prognosis DOES NOT work for rotated older components. The component must be NEW.
                    </div>
                </div>
            </Fade >
        </Modal >
    )
};

export default GraphExplanationModal;