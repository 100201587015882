import _ from "lodash";
import moment from "moment";
import CanvasJSReact from "../../../resources/canvasjs.react.js";
import CanvasJS from "../../../resources/canvasjs.min.js";
import 'mapbox-gl/dist/mapbox-gl.css';
import { Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useState, useContext } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import FeatherIcon from "feather-icons-react";

import ReportContext from "../Context Components/ReportContext.jsx";
import CustomSelectAutocomplete from "../../Global Components/CustomSelectAutocomplete.jsx";
import ParameterSelectionTable from "./ParameterSelectionTable.jsx";
import ChartNameController from "../../Graph Components/chartNameController.js";
import { getAHMUParamData } from "../../usefulFunctions.js";
import {
    getCanvasChartOption,
    getCanvasLineChartOptions,
} from "../../Graph Components/Helper.js";
import { CHARTS, contextualAHMSCharts } from "../../constants.js";
import PredictiveTooltip from "../../Global Components/PredictiveTooltip.jsx";

import "../../canvasChartsStyles.css";
import "./ChartsController.css";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ChartsController = (props) => {
    const {
        chartType,
        charts,
        iseReport,
        aircraftData,
        summaryData,
        addParamContainerRef,
        dropdownOptions,
        parametersToAdd,
        title,
        initialZoom,
        canvasCharts,
        parameterColorArray,
        addTimeSeriesData,
        anomalousPoints,
        isCollapseProp,
        handleChartCollapse,
        darkAccordionSummary = false,
        engineeredCharts,
        engineeredChartsCollapseProp,
        handleEngineeredChartsCollapse,
        time,
        onDropdownChange,
        optionsIsLoading,
        dropdownLabel,
        ahmsDataPoints,
        updateAhnsDataPoints
    } = props;
    const {
        loadingValue
    } = useContext(ReportContext);

    const stripLineColor = "#ffffff";
    const paramNameMaxL = 30;
    const totalLegendNameMaxL = 38;

    const [firstLoad, setFirstLoad] = useState(true);
    const [canvasChartCount, setCanvasChartCount] = React.useState(0);
    const [, setLoading] = loadingValue;
    // Variable for collapse/expand charts section
    const [isCollapse, setIsCollapse] = useState(isCollapseProp);
    // Variable for collapse/expand engineered charts section
    const [isEngineeredChartCollapse, setIsEngineeredChartCollapse] = useState(engineeredChartsCollapseProp);

    /** Functions related to Parameter selection table */
    const onLoadChartsClick = async (selectedParameters) => {
        let startTime = new Date(initialZoom.min).getTime() > new Date(time.startTime).getTime()
            ? time.startTime
            : initialZoom.min;
        let endTime = new Date(initialZoom.max).getTime() > new Date(time.endTime).getTime()
            ? initialZoom.max
            : time.endTime;

        let newAhmsDataPoints = ahmsDataPoints;
        let buildCharts = charts ? charts : [];
        let engBuildCharts = engineeredCharts ? engineeredCharts : [];
        let paramsNotCharted = [];

        // First get all parameters that aren't charted yet
        selectedParameters.forEach((parameter) => {
            if (typeof parameter.type === "undefined") {
                let currCharts = parameter.feature_type.toLowerCase() === "collinear" ? engBuildCharts : buildCharts;
                let isCharted = false;
                for (let i = 0; i < currCharts.length; i++) {
                    const chart = currCharts[i];
                    const data = chart?.options?.data;

                    if (
                        data
                        && typeof data !== "undefined"
                    ) {
                        for (let k = 0; k < data.length; k++) {
                            const parameterName = data[k].parameter.parameterName;

                            if (parameter.parameter == parameterName) {
                                isCharted = true;
                            }
                        }
                    }
                }

                if (!isCharted) {
                    paramsNotCharted.push(parameter);
                }
            }
        });

        if (paramsNotCharted.length > 0) {
            let allParams = [];
            for (let i = 0; i < paramsNotCharted.length; i++) {
                if (paramsNotCharted[i].feature_type.toLowerCase() === CHARTS.CHART_TYPES.COLLINEAR) {
                    allParams = _.unionBy(paramsNotCharted[i].parameters, allParams, 'parameter_name')
                } else {
                    allParams.push(paramsNotCharted[i])
                }
            }

            // Get series data from AHMU Parameter Data API
            const parameterDataPromises = [];
            for (let i = 0; i < allParams.length; i++) {
                let parameterName = allParams[i].parameter_name;
                let index = _.findIndex(
                    newAhmsDataPoints,
                    function (o) {
                        return o.name === parameterName
                    }
                );

                if (index === -1) {
                    const parameterDataPromise = getAHMUParamData(
                        {
                            parameterName: parameterName,
                        },
                        aircraftData,
                        iseReport,
                        startTime,
                        endTime,
                        25000
                    ).catch(() => console.log(`No data for ${parameterName}`))
                    parameterDataPromises.push(parameterDataPromise);
                }
            }

            if (parameterDataPromises.length > 0) {
                const responses = await Promise.all(parameterDataPromises);
                for (let i = 0; i < responses.length; i++) {
                    if (responses[i]?.data?.length > 0) {
                        const data = responses[i].data[0];
                        if (data.data && data.name) {
                            newAhmsDataPoints.push(data)
                        }
                    }
                }
            }

            let currCharts;
            for (let i = 0; i < paramsNotCharted.length; i++) {
                let parameterName = paramsNotCharted[i].parameter_name;
                let featureType = paramsNotCharted[i].feature_type;

                if (paramsNotCharted[i].feature_type.toLowerCase() === CHARTS.CHART_TYPES.CONTINUOUS) {
                    currCharts = buildCharts;

                    let serieData = []; // standard/observed series data
                    let expectedData = []; // expected series data
                    let deviationData = []; // min - max range data

                    for (let index = 0; index < addTimeSeriesData.expected_behaviour.length; index++) {
                        const item = addTimeSeriesData.expected_behaviour[index];
                        const time = new Date(item.time);

                        let expectedY = item[parameterName];

                        if (typeof expectedY === "undefined") {
                            continue;
                        }

                        const obj = {
                            x: time,
                            y: Number(expectedY.toFixed(3))
                        }; // expected

                        const deviationObj = [time, addTimeSeriesData.expected_deviation[index][parameterName]];
                        let deviation = Number(deviationObj[1].toFixed(3)); // deviation value

                        if (
                            typeof obj.y !== "undefined"
                            && typeof deviation !== "undefined"
                        ) {
                            expectedData.push(obj);

                            deviationData.push({
                                x: time,
                                y: [obj.y - deviation, obj.y + deviation]
                            });
                        }
                    }

                    let seriesFromAHMUIndex = _.findIndex(
                        newAhmsDataPoints,
                        function (o) {
                            return o.name === parameterName
                        }
                    );

                    if (seriesFromAHMUIndex !== -1) {
                        serieData = newAhmsDataPoints[seriesFromAHMUIndex].data;
                    }

                    if (serieData.length > 0 && expectedData.length > 0 && deviationData.length > 0) {
                        // Get Parameter information and plug it in parameter variable
                        // Need this when creating options object
                        let parameter = {
                            parameterName: parameterName,
                            unitOfMeasure: newAhmsDataPoints[seriesFromAHMUIndex].unitOfMeasure || "N/A"
                        };

                        let unit = parameter.unitOfMeasure;

                        // Creating series for standard data
                        const dataSeries = setCanvasChartDataSeries(
                            "line",
                            unit,
                            parameterName,
                            serieData,
                            parameter,
                            null,
                            parameterName
                        );

                        const deviationSeries = setCanvasChartDataSeries(
                            "rangeArea",
                            unit,
                            "Range",
                            deviationData,
                            parameter,
                            "#3d6630"
                        );

                        // Creating new options for new CanvasJS Chart
                        CanvasJS.addColorSet("rangeShades", getCanvasChartColorSet());

                        const newOptions = getCanvasLineChartOptions(
                            unit,
                            [deviationSeries, dataSeries],
                            anomalousPoints,
                            "rangeShades",
                            featureType.toLowerCase(),
                            initialZoom,
                            stripLineColor,
                            featureType
                        );

                        let chartIndex = _.findIndex(
                            currCharts,
                            function (o) {
                                return o.chartName === newOptions.chartName
                            }
                        );

                        if (chartIndex === -1) {
                            currCharts.push(newOptions);
                        }
                    }
                }
                if (paramsNotCharted[i].feature_type.toLowerCase() === CHARTS.CHART_TYPES.COLLINEAR) {
                    let parameters = paramsNotCharted[i].parameters;
                    currCharts = engBuildCharts;
                    let unitOfMeasure = "";

                    let hasAllAHMSDataSeries = true;
                    parameters.forEach(p => {
                        let seriesFromAHMUIndex = _.findIndex(
                            newAhmsDataPoints,
                            function (o) {
                                return o.name === p.parameter_name
                            }
                        );
                        if (seriesFromAHMUIndex !== -1) {
                            p.serieData = newAhmsDataPoints[seriesFromAHMUIndex].data;
                            unitOfMeasure = newAhmsDataPoints[seriesFromAHMUIndex].unitOfMeasure || "N/A"
                        } else {
                            hasAllAHMSDataSeries = false
                        }
                    })

                    if (hasAllAHMSDataSeries) {
                        let parameter = {
                            parameterName: parameterName,
                            unitOfMeasure: unitOfMeasure
                        };

                        let unit = parameter.unitOfMeasure;
                        let series = [];
                        let colorArray = [
                            "#07B9FC",
                            "#FFB300",
                        ];
                        for (let i = 0; i < parameters.length; i++) {
                            let parameter = parameters[i];
                            series.push(setCanvasChartDataSeries(
                                "line",
                                unit,
                                parameter.parameter_name,
                                parameter.serieData,
                                parameter,
                                colorArray[i],
                                parameterName
                            ));
                        }

                        const newOptions = getCanvasLineChartOptions(
                            unit,
                            series,
                            anomalousPoints,
                            "chartsShades",
                            featureType.toLowerCase(),
                            initialZoom,
                            stripLineColor,
                            featureType
                        );

                        let chartIndex = _.findIndex(
                            currCharts,
                            function (o) {
                                return o.chartName === newOptions.chartName
                            }
                        );

                        if (chartIndex === -1) {
                            currCharts.push(newOptions);
                        }
                    }
                }
            }

            let allCharts = [{
                type: CHARTS.CHART_TYPES.CONTINUOUS,
                charts: buildCharts
            }, {
                type: CHARTS.CHART_TYPES.COLLINEAR,
                charts: engBuildCharts
            }
            ];

            allCharts.forEach((charts) => {
                // Get all parameters that are not in this new list anymore
                // And delete them from charts
                let newCharts = [];
                for (let i = 0; i < charts.charts.length; i++) {
                    const chart = charts.charts[i];
                    const data = chart.options.data;

                    if (charts.type === CHARTS.CHART_TYPES.COLLINEAR) {
                        const parameterName = chart.chartName;

                        let selectedParamIndex = _.findIndex(
                            selectedParameters,
                            function (o) {
                                let parameter = o.parameter_name;
                                return (
                                    parameter.toLowerCase() == parameterName.toLowerCase()
                                )
                            }
                        );

                        if (selectedParamIndex === -1) {
                            const canvasChartIndex = getCanvasChartIndex(i, charts.charts);
                            canvasCharts.splice(canvasChartIndex, 1);
                        } else {
                            newCharts.push(chart);
                        }
                    }
                    else {
                        for (let k = 0; k < data.length; k++) {
                            const parameterName = data[k].parameter.parameter_name || data[k].parameter.parameterName;

                            let selectedParamIndex = _.findIndex(
                                selectedParameters,
                                function (o) {
                                    let parameter = o.parameter_name;
                                    return (
                                        parameter.toLowerCase() == parameterName.toLowerCase()
                                    )
                                }
                            );

                            if (selectedParamIndex === -1) {
                                data.splice(k, 1);
                                k = k - 1;

                                // If chart has no more data series
                                // Delete the canvas chart and chart
                                if (data.length === 0) {
                                    const canvasChartIndex = getCanvasChartIndex(i, charts.charts);
                                    canvasCharts.splice(canvasChartIndex, 1);
                                }
                            }
                        }
                        if (data.length > 0) {
                            newCharts.push(chart);
                        }
                    }
                }
                props.updateCharts(newCharts, charts.type);
            });

            if (parameterDataPromises.length > 0) {
                updateAhnsDataPoints(newAhmsDataPoints);
            }
        } else {
            let allCharts = [{
                type: CHARTS.CHART_TYPES.CONTINUOUS,
                charts: buildCharts
            }, {
                type: CHARTS.CHART_TYPES.COLLINEAR,
                charts: engBuildCharts
            }
            ];

            allCharts.forEach((charts) => {
                // Get all parameters that are not in this new list anymore
                // And delete them from charts
                let newCharts = [];
                for (let i = 0; i < charts.charts.length; i++) {
                    const chart = charts.charts[i];
                    const data = chart.options.data;

                    if (charts.type === CHARTS.CHART_TYPES.COLLINEAR) {
                        const parameterName = chart.chartName;

                        let selectedParamIndex = _.findIndex(
                            selectedParameters,
                            function (o) {
                                let parameter = o.parameter_name;
                                return (
                                    parameter.toLowerCase() == parameterName.toLowerCase()
                                )
                            }
                        );

                        if (selectedParamIndex === -1) {
                            const canvasChartIndex = getCanvasChartIndex(i, charts.charts);
                            canvasCharts.splice(canvasChartIndex, 1);
                        } else {
                            newCharts.push(chart);
                        }
                    }
                    else {
                        for (let k = 0; k < data.length; k++) {
                            const parameterName = data[k].parameter.parameter_name || data[k].parameter.parameterName;

                            let selectedParamIndex = _.findIndex(
                                selectedParameters,
                                function (o) {
                                    let parameter = o.parameter_name;
                                    return (
                                        parameter.toLowerCase() == parameterName.toLowerCase()
                                    )
                                }
                            );

                            if (selectedParamIndex === -1) {
                                data.splice(k, 1);
                                k = k - 1;

                                // If chart has no more data series
                                // Delete the canvas chart and chart
                                if (data.length === 0) {
                                    const canvasChartIndex = getCanvasChartIndex(i, charts.charts);
                                    canvasCharts.splice(canvasChartIndex, 1);
                                }
                            }
                        }
                        if (data.length > 0) {
                            newCharts.push(chart);
                        }
                    }
                }
                props.updateCharts(newCharts, charts.type);
            });
        }
    };

    const getUnitName = (unit, name) => {
        let unitName = "";
        let leftoverSpace = name.length > paramNameMaxL ? (totalLegendNameMaxL - paramNameMaxL) : (totalLegendNameMaxL - name.length)

        let unitFullName = unit.split(" (")[0];
        if (unitFullName.length > leftoverSpace) {
            let shorterUnitName = unitFullName.substr(0, leftoverSpace)
            unitName = shorterUnitName + "...";
        } else {
            unitName = unitFullName;
        }

        return unitName;
    };

    const getCanvasChartColorSet = () => {
        return parameterColorArray;
    };

    // Function checks if the current charts already contains the unit / the chart title
    const hasCharts = (unit, buildCharts, chartTitle = null) => {
        let chartIndex = -1;

        if (chartType === CHARTS.CHART_TYPES.CONTEXTUAL) {
            // If charts are from contextual section, check if charts contains the title
            chartIndex = _.findIndex(
                buildCharts,
                (c) => {
                    return c.chartName === chartTitle
                },
                0
            );
        } else {
            // If charts are from other sections, check if charts contains the unit of measure
            chartIndex = _.findIndex(
                buildCharts,
                (c) => {
                    return (
                        c.unit === unit &&
                        c.options.data.length < 10 &&
                        c.options.data.length > 0
                    );
                },
                0
            );
        }

        return chartIndex;
    };

    // Get the list style for the drag and drop component
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "rgba(173, 216, 230, 0.7)" : "transparent",
        width: "100%",
        border: "none",
    });

    // Get the item style for the drag and drop component
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        background: isDragging ? "rgba(144, 238, 144, 0.9)" : "transparent",
        ...draggableStyle,
    });

    // Funtion that handles the chart collapse action
    const setChartCollapsed = (chartIndex, newCollapsedValue) => {
        if (chartIndex !== null) {
            charts[chartIndex].isCollapsed = newCollapsedValue;
            props.updateCharts(charts, chartType);
        }
    };

    // Keep the original code when checked out from predictive-dev
    const setCanvasChartDataSeries = (
        chartType = "line",
        unit,
        parameterName,
        dataPoints,
        parameter,
        color = null,
        chartTitle = null,
        lineDashType = null,
    ) => {
        var dataSeries = {
            type: chartType,
            xValueFormatString: "UTC:YYYY-MM-DD, HH:mm:ss",
        };
        if (chartType === "rangeArea") {
            dataSeries.fillOpacity = 0.65;
            dataSeries.lineThickness = 1;
            dataSeries.markerSize = 0;
        } else if (chartType === "line") {
            dataSeries.markerSize = 0;
            dataSeries.lineThickness = 1;
        } else {
            dataSeries.markerSize = 5;
        }
        if (lineDashType) {
            dataSeries.lineDashType = lineDashType;
        }
        if (chartTitle) {
            dataSeries.chartTitle = chartTitle;
        }
        if (color) {
            dataSeries.color = color;
        }
        dataSeries.name = parameterName;
        dataSeries.unit = unit;
        dataSeries.dataPoints = dataPoints;
        dataSeries.showInLegend = false;
        dataSeries.toggled = false;
        dataSeries.legendMarkerType = "circle";
        dataSeries.parameter = parameter;

        return dataSeries;
    };

    const buildChart = (
        parameter,
        parameterName,
        unit,
        series,
        buildCharts,
        newChartCount,
        chartTitle = null
    ) => {
        // If unit is discrete or undefined it gets its own chart
        if (unit === "Discrete" || unit === undefined) {
            const dataSeries = [
                setCanvasChartDataSeries(
                    "stepArea",
                    "discrete",
                    parameterName,
                    series,
                    parameter,
                    parameterColorArray[0]
                ),
            ];
            CanvasJS.addColorSet("discreteColor", [
                getCanvasChartColorSet()[0],
            ]);
            const newOptions = getCanvasChartOption(
                unit,
                dataSeries,
                anomalousPoints,
                "discreteColor",
                chartType,
                initialZoom,
                stripLineColor
            );
            buildCharts.push(newOptions);
            newChartCount++;
        } else {
            // Unit is intended for line charts
            // Checking if any charts currently exist
            if (buildCharts.length > 0) {
                // Looking for chart to put parameter in
                let chartIndex = hasCharts(unit, buildCharts, chartTitle);

                if (chartIndex !== -1) {
                    // Setting up data series object to be added
                    const dataSeries = setCanvasChartDataSeries(
                        "line",
                        unit,
                        parameterName,
                        series,
                        parameter,
                        null,
                        null,
                        0 % 2 === 1 ? "shortDash" : null
                    );

                    buildCharts[chartIndex].options.data.push(dataSeries);
                } else {
                    // No chart with same unit or with same unit but are full so we create a new chart for it
                    const dataSeries = [
                        setCanvasChartDataSeries(
                            "line",
                            unit,
                            parameterName,
                            series,
                            parameter,
                            parameterColorArray[0],
                            chartTitle
                        ),
                    ];

                    CanvasJS.addColorSet("chartsShades", getCanvasChartColorSet());
                    const newOptions = getCanvasLineChartOptions(
                        unit,
                        dataSeries,
                        anomalousPoints,
                        "chartsShades",
                        chartType,
                        initialZoom,
                        stripLineColor,
                        null,
                    );
                    buildCharts.push(newOptions);
                    newChartCount++;
                }
            } else {
                // No charts currently exist so we add a new line chart
                const dataSeries = [
                    setCanvasChartDataSeries(
                        "line",
                        unit,
                        parameterName,
                        series,
                        parameter,
                        parameterColorArray[0],
                        chartTitle
                    ),
                ];

                CanvasJS.addColorSet("chartsShades", getCanvasChartColorSet());
                const newOptions = getCanvasLineChartOptions(
                    unit,
                    dataSeries,
                    anomalousPoints,
                    "chartsShades",
                    chartType,
                    initialZoom,
                    stripLineColor,
                    null,
                    iseReport.anomaly_timestamp
                );
                buildCharts.push(newOptions);
                newChartCount++;
            }
        }

        return {
            buildCharts: buildCharts,
            newChartCount: newChartCount
        };
    };

    const handleChartTitleOffFocus = (chartIndex, newChartName) => {
        charts[chartIndex].chartName = newChartName;
        props.updateCharts(charts, chartType);
    };

    const getIndexesByParameterName = (parameterName) => {
        let chartId = -1;
        let seriesId = -1;
        for (let i = 0; i < charts.length; i++) {
            const series = charts[i].options.data;
            const seriesIndex = _.findIndex(
                series,
                function (d) {
                    return d.name === parameterName
                }
            )
            if (seriesIndex !== -1) {
                chartId = i;
                seriesId = seriesIndex;
                break;
            }
        }

        return {
            chartId: chartId,
            seriesId: seriesId
        }
    }

    const getCanvasChartIndex = (chartIndex, charts) => {
        const canvasIndex = _.findIndex(
            canvasCharts,
            (c) => {
                return c.chartId === charts[chartIndex].id
            }
        );

        return canvasIndex;
    };

    // This method toggles the legend
    const canvasLegendToggle = (chart, seriesIndex) => {
        let currCharts = charts;
        let currChartType = chartType;
        chart.options.data[seriesIndex].toggled = !chart.options.data[seriesIndex].toggled;


        let chartIndex = currCharts.findIndex(item => item.id === chart.id);
        // if chart is not found in charts, means it's from the engineered charts
        if (
            chartIndex === -1
            && currChartType === CHARTS.CHART_TYPES.CONTINUOUS) {
            currChartType = CHARTS.CHART_TYPES.COLLINEAR;
            currCharts = engineeredCharts;
            chartIndex = currCharts.findIndex(item => item.id === chart.id);
        }
        const canvasChartsIndex = getCanvasChartIndex(chartIndex, currCharts);
        const selectedChart = canvasCharts[canvasChartsIndex];

        selectedChart.options.data[seriesIndex].visible = !(typeof selectedChart.options.data[seriesIndex].visible === "undefined" ||
            selectedChart.options.data[seriesIndex].visible);

        selectedChart.render();
        props.updateCharts(currCharts, currChartType);
    };

    // This method moves the chart up or down
    const moveCanvasChart = (direction, index, charts, chartType) => {
        direction = direction.toUpperCase();
        // Initializing indexes of the two charts switching
        const sourceIndex = index;
        let destinationIndex = null;

        let tempChartIndex;
        if (direction === "UP") {
            tempChartIndex = sourceIndex - 1;
            while (destinationIndex === null && tempChartIndex >= 0) {
                if (charts[tempChartIndex].options.data.length > 0) {
                    destinationIndex = tempChartIndex;
                }
                tempChartIndex--;
            }
        } else if (direction === "DOWN") {
            tempChartIndex = sourceIndex + 1;
            while (destinationIndex === null && tempChartIndex < charts.length) {
                if (charts[tempChartIndex].options.data.length > 0) {
                    destinationIndex = tempChartIndex;
                }
                tempChartIndex++;
            }
        }

        const sourceCanvasIndex = getCanvasChartIndex(sourceIndex, charts);
        const destCanvasIndex = getCanvasChartIndex(destinationIndex, charts);

        // Determines the index of the chart that the source chart is switching with
        if (destinationIndex !== null) {
            // If the move is valid then move the chart up or down
            const sourceChart = charts[sourceIndex];
            const destinationChart = charts[destinationIndex];

            const sourceOptions = canvasCharts[sourceCanvasIndex];
            const destinationOptions = canvasCharts[destCanvasIndex];

            // The charts that are changing must be re-rendered by canvas in order to get the changes
            canvasCharts[sourceCanvasIndex] = destinationOptions;
            canvasCharts[destCanvasIndex] = sourceOptions;

            props.updateCanvasCharts(canvasCharts);
            canvasCharts[sourceCanvasIndex].render();
            canvasCharts[destCanvasIndex].render();

            charts[sourceIndex] = destinationChart;
            charts[destinationIndex] = sourceChart;

            props.updateCharts(charts, chartType);
        }
    };

    // Remove a parameter from the legend of the canvas js charts
    const removeCanvasParameter = (chartIndex, seriesIndex) => {
        // Find the parameter to be removed from the main charts state variable
        const parameterToRemove = charts[chartIndex].options.data[seriesIndex].parameter;

        // Find index of parameter in Canvas Charts using chart index
        let canvasChartIndex = getCanvasChartIndex(chartIndex, charts);

        // Remove the parameter from the main charts state variable
        // This will also remove the value in tempCanvasCharts
        charts[chartIndex].options.data.splice(seriesIndex, 1);

        // Delete the chart if the parameter being removed is the last one.
        if (charts[chartIndex].options.data.length === 0) {
            removeCanvasChart(chartIndex, canvasChartIndex);
        } else {
            props.updateCharts(charts, chartType);
        }

        let findParamIndex = _.findIndex(parametersToAdd, ['parameterName', parameterToRemove.parameterName
        ]);
        if (findParamIndex !== -1) {
            // Remove it from parametersToAdd
            parametersToAdd.splice(findParamIndex, 1);
            props.updateParamsToAdd([...parametersToAdd], chartType);
        }
    };

    // Remove a canvas chart
    const removeCanvasChart = (chartIndex, canvasChartIndex) => {
        // Loop through the canvas chart state variable to move all charts after the rmeoved one an array space up
        for (var cIndex = canvasChartIndex; cIndex < canvasCharts.length - 1; cIndex++) {
            // Save chart options of both chart state variable
            const newCanvasChartOptions = canvasCharts[cIndex + 1];

            // Replace chart options of both chart state variable
            canvasCharts[cIndex] = newCanvasChartOptions;
        }
        canvasCharts.pop();

        // Remove chart at index
        charts.splice(chartIndex, 1);
        props.updateCharts(charts, chartType);

        setCanvasChartCount(canvasChartCount - 1);
    };

    const loadMultipleParametersCharts = (paramsToAdd, charts) => {
        let startTime = new Date(initialZoom.min).getTime() > new Date(time.startTime).getTime() ? time.startTime : initialZoom.min;
        let endTime = new Date(initialZoom.max).getTime() > new Date(time.endTime).getTime() ? initialZoom.max : time.endTime;

        let buildCharts = charts;
        let newChartCount = 0;

        let newTimeSeries = {
            buildCharts: buildCharts,
            newChartCount: newChartCount
        };

        // Constructing array of parameter data promises
        const parameterDataPromises = [];

        for (let i = 0; i < paramsToAdd.length; i++) {
            if ((
                paramsToAdd[i].loaded === null
                || typeof paramsToAdd[i].loaded === "undefined"
                || !paramsToAdd[i].loaded
            )) {

                paramsToAdd[i].loaded = false;
                const parameterDataPromise = getAHMUParamData(
                    paramsToAdd[i],
                    aircraftData,
                    iseReport,
                    startTime,
                    endTime,
                    25000
                ).catch(() => {
                    paramsToAdd[i].loaded = true;
                    props.updateParamsToAdd(parametersToAdd ? [...parametersToAdd] : [...paramsToAdd], chartType);

                    console.log(`No data for ${paramsToAdd[i]?.parameterName}`);
                })
                parameterDataPromises.push(parameterDataPromise);
            }
        }

        Promise.all(parameterDataPromises)
            .then((res) => {
                // Looping through new parameters to be added
                for (let p = 0; p < res.length; p++) {
                    if (typeof res[p] !== "undefined") {
                        // Getting parameterName and Series
                        const seriesData = _.get(res[p], "data[0].data");
                        const parameter = paramsToAdd[p];
                        const parameterName = parameter.parameterName;
                        const unit = parameter.parameterType === "discrete" ? "Discrete" : parameter.unitOfMeasure;;
                        const chartTitle = parameter.chartTitle;

                        const series = seriesData;

                        // Set parameters that have returned parameter data as loaded
                        // Find Index for parameters to set as Loaded
                        const pAddIndex = _.findIndex(parametersToAdd, (paramToAdd) => {
                            return paramToAdd.parameterName === parameterName;
                        });

                        // Set parameters that have returned parameter data as loaded
                        if (pAddIndex !== -1) {
                            parametersToAdd[pAddIndex].loaded = true;
                            parametersToAdd[pAddIndex].firstLoad = false;
                        }

                        if (series && series.length > 0) {
                            newTimeSeries = buildChart(
                                parameter,
                                parameterName,
                                unit,
                                series,
                                buildCharts,
                                newChartCount,
                                chartTitle
                            );
                        }
                    }
                }

                props.updateParamsToAdd(parametersToAdd ? [...parametersToAdd] : [...paramsToAdd], chartType);
                props.updateCharts(newTimeSeries.buildCharts, chartType);
                setCanvasChartCount(newTimeSeries.buildCharts.length);
            })
            .catch((err) => {
                console.error("ERROR: ", err);
            });
    };

    const loadAHMSCharts = () => {
        let newParamsToAdd = [];

        for (let i = 0; i < contextualAHMSCharts.length; i++) {
            let chart = contextualAHMSCharts[i];

            for (let j = 0; j < chart.parameters.length; j++) {
                let parameter = chart.parameters[j];

                const dropDownIndex = _.findIndex(
                    dropdownOptions,
                    function (option) {
                        return option.parameterName === parameter.parameterName
                    });

                if (dropDownIndex !== -1) {
                    // Add parameter to parameters to add
                    parameter = dropdownOptions[dropDownIndex];

                    // Remove parameter from dropdown options
                    _.remove(
                        dropdownOptions,
                        function (option) {
                            return option.parameterName === parameter.parameterName
                        }
                    );
                } else {
                    parameter = {
                        parameterName: parameter.parameterName,
                        unitOfMeasure: "N/A"
                    }
                }
                parameter.chartTitle = chart.chartTitle;
                parameter.chartId = chart.id;
                parameter.loaded = null;
                newParamsToAdd.push(parameter);
            }
        }

        loadMultipleParametersCharts(newParamsToAdd, []);

        props.updateDropdownOptions([...dropdownOptions]);
    };

    const onCollapse = () => {
        handleChartCollapse(!isCollapse);
        setIsCollapse(!isCollapse);
    };

    const onEngineeredChartCollapse = () => {
        handleEngineeredChartsCollapse(!isEngineeredChartCollapse);
        setIsEngineeredChartCollapse(!isEngineeredChartCollapse)
    };

    function shouldDisplayChart(chart) {
        return (chart.options.data && chart.options.data.length > 0);
    }

    useEffect(() => {
        setIsCollapse(isCollapseProp);
    }, [isCollapseProp]);

    useEffect(() => {
        setIsEngineeredChartCollapse(engineeredChartsCollapseProp);
    }, [engineeredChartsCollapseProp]);

    useEffect(() => {
        if (charts) {
            setCanvasChartCount(charts.length);
        } else {
            // Load top anomalous parameters charts
            if (
                summaryData
                && chartType === CHARTS.CHART_TYPES.CONTINUOUS
            ) {
                onLoadChartsClick(parametersToAdd);
            }
            // Load contextual ahms parameters / supporting parameters charts
            else if (chartType === CHARTS.CHART_TYPES.CONTEXTUAL) {
                loadAHMSCharts();
            }
        }
    }, []);

    useEffect(() => {
        if (chartType === CHARTS.CHART_TYPES.ADDITIONAL) {
            if (charts && charts.length > 0) {
                let doneLoad = !(_.some(parametersToAdd, ['loaded', false]));
                if (doneLoad) {
                    props.handleScrollButton(true);
                    props.handleChartsCount(charts.length);
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }
            } else {
                props.handleScrollButton(false);
            }
        }
    }, [charts]);

    useEffect(() => {
        if (chartType === CHARTS.CHART_TYPES.ADDITIONAL) {

            if (parametersToAdd) {
                let paramsRemoved = [];

                if (charts) {
                    // Get all parameters that were removed from the last selected additional parameters to chart
                    for (let i = 0; i < charts.length; i++) {
                        const options = charts[i].options;
                        for (let d = 0; d < options.data.length; d++) {
                            const data = options.data[d];

                            if (_.findIndex(
                                parametersToAdd,
                                function (o) {
                                    return o.parameterName === data.name
                                }
                            ) === -1) {
                                paramsRemoved.push(data.name)
                            }
                        }
                    }

                    // Remove all of these parameters data series from the charts
                    for (let i = 0; i < paramsRemoved.length; i++) {
                        const parameterName = paramsRemoved[i];
                        const { chartId, seriesId } = getIndexesByParameterName(parameterName);
                        removeCanvasParameter(chartId, seriesId);
                    }
                }


                let paramsNotLoaded = [];
                for (let i = 0; i < parametersToAdd.length; i++) {
                    const parameter = parametersToAdd[i];

                    if (
                        ((typeof parameter.loaded === "undefined" || parameter.loaded === false)
                            && parameter.firstLoad)
                        && !parameter.loaded
                    ) {
                        let isCharted = false;
                        // Check if they are already charted
                        if (charts) {
                            for (let i = 0; i < charts.length; i++) {
                                const data = charts[i].options.data;
                                let index = _.findIndex(
                                    data,
                                    function (o) {
                                        return o.name === parameter.parameterName
                                    }
                                );

                                if (index !== -1) {
                                    isCharted = true
                                }
                            }
                        }

                        if (!isCharted) {
                            paramsNotLoaded.push(parameter);
                        }
                    }
                }

                if (paramsNotLoaded.length > 0) {
                    paramsNotLoaded.forEach((param) => {
                        param.firstLoad = false;
                    });

                    loadMultipleParametersCharts(paramsNotLoaded, charts ? charts : []);
                }
            }
        }
    }, [parametersToAdd]);

    /**
     * Render toggle button inside parameter table
     * @param chart
     * @param series
     * @param seriesIndex
     * @returns {JSX.Element}
     * @constructor
     */
    const ChartLegendToggleButton = (chart, series, seriesIndex) => {
        /**
         * Style and properties of the chart legend toggle button
         */
        return <div
            className="canvasjs__chart__legend__pill"
            style={{
                border:
                    "1px solid " +
                    (!series.toggled
                        ? (series.color
                            ? series.color
                            : parameterColorArray[
                            seriesIndex
                            ]
                        )
                        : "#ffffff"),

                cursor: "pointer",
                backgroundColor: !series.toggled
                    ? (series.color
                        ? series.color
                        : parameterColorArray[
                        seriesIndex
                        ]
                    )
                    : "#000000",
            }}
            onClick={() => {
                canvasLegendToggle(
                    chart,
                    seriesIndex
                );
            }}
        ></div>
    };

    /**
     * @description: Render button rows for parameter table
     * @param chart
     * @param series
     * @param seriesIndex: Index of the series in the chart.options.data. For anomalous chart, seriesIndex starts with 2, 4, 6... (seriesIndex = 1, series.unit is null)
     * @returns {JSX.Element}
     */
    const ChartLegendToggleButtons = (chart, series, seriesIndex, chartType) => {
        if ((chartType === CHARTS.CHART_TYPES.CONTINUOUS
            || chartType === CHARTS.CHART_TYPES.COLLINEAR)
            && seriesIndex && seriesIndex >= 1) {
            if (typeof (chart.options.data[seriesIndex].parameter.col_name) === 'undefined') {
                const deviationSeries = chart.options.data[seriesIndex - 1];
                return (
                    <>
                        {(deviationSeries
                            && deviationSeries.type === CHARTS.CHART_TYPES.RANGE_AREA)
                            && ChartLegendToggleButton(chart, deviationSeries, seriesIndex - 1)}
                        <span style={{ marginRight: 5 }}></span>
                        {ChartLegendToggleButton(chart, series, seriesIndex)}
                        <span style={{ marginRight: 5 }}></span>
                    </>
                );
            }
        }
        return <div>{ChartLegendToggleButton(chart, series, seriesIndex)}</div>
    };

    /**
     * Render parameter table for a chart
     * @param chart
     * @param series
     * @param seriesIndex: index of a series in chart.options.data
     * @returns {JSX.Element}
     * @constructor
     */
    const ChartLegend = ({ chart, series, seriesIndex, chartType }) => {
        const isUnitNameDisplayed = series.unit && series.unit.toUpperCase() !== "DISCRETE" && series.unit !== "N/A";

        const legendNameWithUnitName = <>{(series.name.length > paramNameMaxL
            ? series.name.substr(0, paramNameMaxL) + "..."
            : series.name) +
            (isUnitNameDisplayed
                ? " (" + getUnitName(series.unit, series.name) + ")"
                : "")}</>;
        const legendNameWithoutUnitName = <>{(series.name.length > totalLegendNameMaxL
            ? series.name.substr(0, totalLegendNameMaxL) + "..."
            : series.name) +
            (isUnitNameDisplayed
                ? " (" + getUnitName(series.unit, series.name) + ")"
                : "")}</>;

        return (
            <React.Fragment>
                {series.unit &&
                    <div className="canvasjs__chart__legend__params">
                        <div className="canvasjs__chart__legend__params__name">
                            <PredictiveTooltip
                                element={isUnitNameDisplayed ? legendNameWithUnitName : legendNameWithoutUnitName}
                                text={series.name + (isUnitNameDisplayed ? " (" + series.unit + ")" : "")}
                                centered={true}
                                fitted={"400px"}
                                lifted={true}
                            />
                        </div>
                        {chartType === CHARTS.CHART_TYPES.ADDITIONAL &&
                            <div>
                                <FeatherIcon
                                    style={{ cursor: "pointer", marginTop: 2 }}
                                    icon={"trash-2"}
                                    height={18}
                                    width={18}
                                    onClick={() => removeCanvasParameter(
                                        charts.findIndex(item => item.id === chart.id),
                                        seriesIndex
                                    )}
                                />
                            </div>
                        }
                        <div className="canvasjs__chart__legend__params__toggle">
                            {/*Using below conditions to display the chart legend toggle buttons*/}
                            {ChartLegendToggleButtons(chart, series, seriesIndex, chartType)}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    };

    const chartsRender = (charts, chartType) => {
        let chartCount = 0;

        return (
            <React.Fragment>
                {charts?.length > 0 && charts.map(
                    (chart, cIndex) =>
                        chart &&
                        <React.Fragment key={chart.id}>
                            <div
                                className={
                                    "canvasjs__chart__container" +
                                    (!shouldDisplayChart(chart) ? "-noDisplay" : "")
                                }
                                id={chart.id}
                            >
                                <div className="canvasjs__chart__container__header"
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr 170px"
                                    }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%"
                                        }}>
                                        <div className="canvasjs__chart__container__header__collapse">
                                            <FeatherIcon
                                                icon={
                                                    chart.isCollapsed ? "chevron-down" : "chevron-up"
                                                }
                                                height={30}
                                                style={{ marginTop: 5 }}
                                                className="canvasjs__chart__container__header__collapse-icon"
                                                onClick={(event) => {
                                                    setChartCollapsed(cIndex, !chart.isCollapsed);
                                                }}
                                            />
                                        </div>
                                        <ChartNameController
                                            chartId={chart.chartId}
                                            chartIndex={cIndex}
                                            chartName={chart.chartName}
                                            handleChartTitleOffFocus={handleChartTitleOffFocus}
                                        />
                                    </div>
                                    <div className="canvasjs__chart__container__header__displace">
                                        <div className="canvasjs__chart__container__header__displace__section">
                                            <FeatherIcon
                                                icon={"arrow-up-circle"}
                                                height={30}
                                                className={
                                                    "canvasjs__chart__container__header__displace-icon" +
                                                    (chartCount === 0 ? "-disabled" : "")
                                                }
                                                disabled={chartCount === 0 ? true : false}
                                                onClick={(event) => moveCanvasChart("UP", cIndex, charts, chartType)}
                                            />
                                            <FeatherIcon
                                                icon={"arrow-down-circle"}
                                                height={30}
                                                className={
                                                    "canvasjs__chart__container__header__displace-icon" +
                                                    (chartCount === charts.length - 1
                                                        ? "-disabled"
                                                        : "")
                                                }
                                                disabled={
                                                    chartCount === charts.length
                                                }
                                                onClick={(event) => moveCanvasChart("DOWN", cIndex, charts)}
                                            />
                                        </div>
                                        <div className="canvasjs__chart__container__header__displace__section"
                                            style={{ marginRight: 25 }}>
                                            {chart.options.data.length > 0
                                                ? (chartCount = chartCount + 1).toString() +
                                                " of " +
                                                charts.length
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        "canvasjs__chart__container__body" +
                                        (chart.isCollapsed ? "-collapsed-true" : "")
                                    }
                                >
                                    <div className="canvasjs__chart__legend__container">
                                        <div className="canvasjs__chart__legend__header">
                                            <div className="canvasjs__chart__legend__header__parameter">
                                                Parameter
                                            </div>
                                        </div>
                                        <Droppable
                                            droppableId={chart.id}
                                            key={chart.id}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    <div className="canvasjs__chart__legend__params__container">
                                                        {chart.options.data.map((series, index) => {
                                                            let newChartType = chartType;
                                                            if (chartType === CHARTS.CHART_TYPES.CONTINUOUS
                                                                || chartType === CHARTS.CHART_TYPES.COLLINEAR) {
                                                                if (typeof (series.parameter.col_name) === 'undefined') {
                                                                    newChartType = CHARTS.CHART_TYPES.CONTINUOUS
                                                                } else {
                                                                    newChartType = CHARTS.CHART_TYPES.COLLINEAR
                                                                }
                                                            }
                                                            return (
                                                                series.type !== 'rangeArea' &&
                                                                <React.Fragment key={`${series.name}-${index}`}>
                                                                    {
                                                                        (chartType === CHARTS.CHART_TYPES.ADDITIONAL
                                                                            || chartType === CHARTS.CHART_TYPES.CONTINUOUS
                                                                            || chartType === CHARTS.CHART_TYPES.COLLINEAR
                                                                        )
                                                                            ? <Draggable
                                                                                key={`${newChartType}-${series.name}`}
                                                                                draggableId={`${newChartType}-${series.name}`}
                                                                                index={index}
                                                                            >
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={getItemStyle(
                                                                                            snapshot.isDragging,
                                                                                            provided.draggableProps.style
                                                                                        )}
                                                                                    >
                                                                                        <ChartLegend
                                                                                            chart={chart}
                                                                                            series={series}
                                                                                            seriesIndex={index}
                                                                                            chartType={chartType}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                            :
                                                                            <ChartLegend
                                                                                chart={chart}
                                                                                series={series}
                                                                                seriesIndex={index}
                                                                                chartType={chartType}
                                                                            />
                                                                    }

                                                                </React.Fragment>
                                                            )
                                                        }

                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                    <div className="canvasjs__chart__container__inner">
                                        <CanvasJSChart
                                            options={chart.options}
                                            onRef={(ref) => {
                                                if (chart?.id && typeof ref != "undefined") {

                                                    ref.chartId = chart.id;
                                                    canvasCharts.push(ref);

                                                    // Filter out all canvas chart that doesn't have a ref in the page anymore
                                                    let filteredCanvas = _.filter(
                                                        canvasCharts,
                                                        function (o) {
                                                            return o.container !== null
                                                        }
                                                    )

                                                    props.updateCanvasCharts([...filteredCanvas])
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>


                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {((chartType === CHARTS.CHART_TYPES.ADDITIONAL && parametersToAdd?.length > 0)
                || chartType !== CHARTS.CHART_TYPES.ADDITIONAL)
                &&
                <div
                    className="infoBox__container"
                    style={darkAccordionSummary ? { backgroundColor: "#252525" } : {}}
                >
                    <Accordion
                        ref={chartType === CHARTS.CHART_TYPES.ADDITIONAL ? addParamContainerRef : null}
                        style={darkAccordionSummary ? { backgroundColor: "#252525" } : { backgroundColor: "#303030" }}
                        defaultExpanded={true}
                        expanded={!isCollapse}
                    >
                        <AccordionSummary
                            style={
                                darkAccordionSummary ?
                                    {
                                        backgroundColor: "#252525",
                                        cursor: "pointer",
                                        borderTopLeftRadius: 5,
                                        borderTopRightRadius: 5,
                                        padding: 0,
                                        minHeight: 55,
                                        height: 55
                                    }
                                    :
                                    {
                                        backgroundColor: "#505050",
                                        cursor: "pointer",
                                        borderTopLeftRadius: 5,
                                        borderTopRightRadius: 5,
                                        padding: 0,
                                        minHeight: 55,
                                        height: 55
                                    }
                            }
                            onClick={() => onCollapse()}
                        >
                            <div style={{
                                color: "white",
                                fontWeight: 600,
                                fontSize: 16,
                                textAlign: "center"
                            }}>
                                {
                                    chartType === CHARTS.CHART_TYPES.CONTEXTUAL ?
                                        <PredictiveTooltip
                                            element={title}
                                            text={
                                                <React.Fragment>
                                                    Chosen parameters by the <b>Subject Matter Experts</b>
                                                </React.Fragment>
                                            }
                                            wide={true}
                                        />
                                        :
                                        title
                                }
                            </div>
                            <PredictiveTooltip
                                element={<FeatherIcon
                                    icon={isCollapse ? "chevron-down" : "chevron-up"}
                                    style={{ color: "white" }}
                                />}
                                text={isCollapse ? "Expand" : "Collapse"}
                                fitted="100px"
                                centered={true}
                            />
                        </AccordionSummary>
                        <AccordionDetails
                            style={{ backgroundColor: "#101010", padding: 0 }}
                        >
                            {chartType === CHARTS.CHART_TYPES.ADDITIONAL &&
                                <div className="additionalParamsSearch__container">
                                    <CustomSelectAutocomplete
                                        handleChange={onDropdownChange}
                                        options={dropdownOptions}
                                        placeholder={optionsIsLoading ? "...Loading parameters..." : dropdownLabel}
                                        selectedValues={parametersToAdd ? parametersToAdd : []}
                                        optionsIsLoading={optionsIsLoading}
                                        noOptionsText={"No additional parameters found"}
                                    />
                                </div>
                            }
                            {charts?.length > 0
                                ? <>
                                    {chartsRender(charts, chartType)}
                                </>
                                :
                                <>
                                    {(
                                        (chartType === CHARTS.CHART_TYPES.ADDITIONAL && parametersToAdd?.length > 0)
                                        || (chartType === CHARTS.CHART_TYPES.CONTEXTUAL && !parametersToAdd)
                                        || (chartType === CHARTS.CHART_TYPES.CONTINUOUS && !charts)
                                    ) &&
                                        <div
                                            style={{
                                                height: "200px",
                                                backgroundColor: "rgb(16, 16, 16)",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        > <CircularProgress style={{ color: "#979797" }} />
                                        </div>
                                    }
                                </>
                            }
                        </AccordionDetails>
                    </Accordion>

                    {/* FOR FLAGGED PARAMETERS ENGINEERED PARAMS ARE SEPERATED */}
                    {chartType === CHARTS.CHART_TYPES.CONTINUOUS
                        && <Accordion
                            style={darkAccordionSummary ? { backgroundColor: "#252525" } : { backgroundColor: "#303030" }}
                            defaultExpanded={true}
                            expanded={!isEngineeredChartCollapse}
                        >
                            <AccordionSummary
                                style={
                                    darkAccordionSummary ?
                                        {
                                            backgroundColor: "#252525",
                                            cursor: "pointer",
                                            borderTopLeftRadius: 5,
                                            borderTopRightRadius: 5,
                                            padding: 0,
                                            minHeight: 55,
                                            height: 55
                                        }
                                        :
                                        {
                                            backgroundColor: "#505050",
                                            cursor: "pointer",
                                            borderTopLeftRadius: 5,
                                            borderTopRightRadius: 5,
                                            padding: 0,
                                            minHeight: 55,
                                            height: 55
                                        }
                                }
                                onClick={() => onEngineeredChartCollapse()}
                            >
                                <div style={{
                                    color: "white",
                                    fontWeight: 600,
                                    fontSize: 16,
                                    textAlign: "center"
                                }}>
                                    Engineered Parameters
                                </div>
                                <PredictiveTooltip
                                    element={<FeatherIcon
                                        icon={isEngineeredChartCollapse ? "chevron-down" : "chevron-up"}
                                        style={{ color: "white" }}
                                    />}
                                    text={isEngineeredChartCollapse ? "Expand" : "Collapse"}
                                    fitted="100px"
                                    centered={true}
                                />
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ backgroundColor: "#101010", padding: 0 }}
                            >
                                {engineeredCharts?.length > 0
                                    ? chartsRender(engineeredCharts, CHARTS.CHART_TYPES.COLLINEAR)
                                    :
                                    <>
                                        {!engineeredCharts
                                            &&
                                            <div
                                                style={{
                                                    height: "200px",
                                                    backgroundColor: "rgb(16, 16, 16)",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            > <CircularProgress style={{ color: "#979797" }} />
                                            </div>
                                        }
                                    </>
                                }
                            </AccordionDetails>
                        </Accordion>
                    }

                    {/* PARAMETER SELECTION TABLE */}
                    {chartType === CHARTS.CHART_TYPES.CONTINUOUS
                        && summaryData?.anomalies &&
                        <ParameterSelectionTable
                            summaryData={summaryData}
                            onLoadChartsClick={onLoadChartsClick}
                            selectedParams={parametersToAdd}
                            updateParamsToAdd={props.updateParamsToAdd}
                        />
                    }
                </div>
            }
        </React.Fragment>
    )
};

export default ChartsController;