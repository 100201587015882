import React, { Component } from "react";
import ConfigCyclesHours from "./ConfigCyclesHours";
import ConfigCyclesHoursLog from "./ConfigCyclesHoursLog";
import ConfigCyclesHoursCalculate from "./ConfigCyclesHoursCalculate";
import "./ConfigCyclesHoursBox.css";
import FeatherIcon from "feather-icons-react";

export default class ConfigCyclesHoursBox extends Component {
  constructor(props) {
    super(props);

    this.handleConfigCyclesHours = this.handleConfigCyclesHours.bind(this);
    this.handleConfigCyclesHoursLog = this.handleConfigCyclesHoursLog.bind(
      this
    );
    if (props.flightCyclesError) {
      this.state = {
        selectedTab: "CONFIG",
      };
    } else {
      this.state = {
        selectedTab: "CALCULATE",
      };
    }

  }

  render() {
    return (
      <div className="configCyclesHoursBox" id="config_cycles_hours">
        <div className="configCyclesHoursBox__tabContainer">
          <button
            className={
              this.state.selectedTab === "CALCULATE"
                ? "configCyclesHoursBox__tabPanel active"
                : "configCyclesHoursBox__tabPanel"
            }
            onClick={() => {
              this.handleTabSelection("CALCULATE");
            }}
          >
            Calculate
          </button>
          <button
            className={
              this.state.selectedTab === "CONFIG"
                ? "configCyclesHoursBox__tabPanel active"
                : "configCyclesHoursBox__tabPanel"
            }
            onClick={() => {
              this.handleTabSelection("CONFIG");
            }}
          >
            Configure
          </button>
          <button
            className={
              this.state.selectedTab === "LOG"
                ? "configCyclesHoursBox__tabPanel active"
                : "configCyclesHoursBox__tabPanel"
            }
            onClick={() => {
              this.handleTabSelection("LOG");
            }}
          >
            Log
          </button>
        </div>
        {!this.props.permEditFlightCycles ? (
          <div className="configCyclesHoursBox__alertBanner">
            <FeatherIcon icon={"alert-triangle"} height={20} />
            <span style={{ marginLeft: "5px" }}>
              {
                "This user does not have permissions to update aircraft hours and cycles."
              }
            </span>
          </div>
        ) : (
          <div className="configCyclesHoursBox__alertBanner-inactive"></div>
        )}
        <div
          className={
            "configCyclesHoursBox__content__active-" +
            (this.state.selectedTab === "CALCULATE" ? "true" : "false")
          }
          id="config-cycle-hour-config-calculate"
        >
          {this.handleConfigCyclesHoursCalculate()}
        </div>
        <div
          className={
            "configCyclesHoursBox__content__active-" +
            (this.state.selectedTab === "CONFIG" ? "true" : "false")
          }
          id="config-cycle-hour-config"
        >
          {this.handleConfigCyclesHours()}
        </div>
        <div
          className={
            "configCyclesHoursBox__content__active-" +
            (this.state.selectedTab === "LOG" ? "true" : "false")
          }
          id="config-cycle-hour-log"
        >
          {this.handleConfigCyclesHoursLog()}
        </div>
      </div>
    );
  }

  handleTabSelection(tab) {
    this.setState({
      selectedTab: tab,
    });
  }

  handleConfigCyclesHours() {
    return (
      <ConfigCyclesHours
        serialNumber={this.props.serialNumber}
        flightCycles={this.props.flightCycles}
        handleCyclesHoursSubmit={this.props.handleCyclesHoursSubmit}
        permEditFlightCycles={this.props.permEditFlightCycles}
        editCyclesPermissionAircraft={this.props.editCyclesPermissionAircraft}
        flightCyclesError={this.props.flightCyclesError}
      />
    );
  }

  handleConfigCyclesHoursLog() {
    return (
      <ConfigCyclesHoursLog
        serialNumber={this.props.serialNumber}
        flightCycleLogs={this.props.flightCycleLogs}
      />
    );
  }
  // This is the "Calculate" tab, processFlightCyclesByDate is
  // function to handle the request.
  handleConfigCyclesHoursCalculate() {
    return (
      <ConfigCyclesHoursCalculate
        serialNumber={this.props.serialNumber}
        flightCycles={this.props.flightCycles}
        processFlightCyclesByDate={this.props.processFlightCyclesByDate}
        selectedTail={this.props.selectedTail}
      />
    );
  }
}
