import React from 'react';
function CheckCircle({isDark}) {
    const pathColor = isDark ? 'lightgray': '#003E51';
    const polylineColor = isDark ? '#FFC72C': '#D19000';

    return(
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle">
        <path style={{color: pathColor}} d="M22 11.08V12a10 10 0 1 1-5.93-9.14">
        </path>
        <polyline style={{color: polylineColor}} points="22 4 12 14.01 9 11.01">
        </polyline>
    </svg>

    )
}

export default CheckCircle;