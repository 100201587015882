import React, { Component } from 'react'
import * as tools from '../utils/CommonTools'
import moment from 'moment'
import './ContentFDEFault.css'

export default class ContentFDEFault extends Component {
    constructor(props) {
        super(props)

        this.state = {
            relatedTabLabel: 'Related ' + this.props.aircraftUIConfig.aircraftViewPage.mdcTabLabel,
            activeTabID: this.props.viewType === 'HISTORIC' ? 'FDE_DESC' : 'OVERVIEW',
            overviewTabStyle:
                this.props.viewType === 'HISTORIC'
                    ? 'contentFdeFault__tabPanel'
                    : 'contentFdeFault__tabPanel active',
            fdeDescriptionTabStyle:
                this.props.viewType === 'HISTORIC'
                    ? 'contentFdeFault__tabPanel active'
                    : 'contentFdeFault__tabPanel',
            eventCountTabStyle: 'contentFdeFault__tabPanel',
            omsRelatedTabStyle: 'contentFdeFault__tabPanel'
        }
        this.handleShowTab = this.handleShowTab.bind(this)
    }

    render() {
        return (
            <div className='contentFdeFault'>
                <div className='contentFdeFault__tabContainer'>
                    {this.props.viewType === 'IFR' ? (
                        <button
                            className={this.state.overviewTabStyle}
                            onClick={() => {
                                this.handleTabSelection('OVERVIEW')
                            }}
                        >
                            Overview
                        </button>
                    ) : (
                        ''
                    )}
                    <button
                        className={this.state.fdeDescriptionTabStyle}
                        onClick={() => {
                            this.handleTabSelection('FDE_DESC')
                        }}
                    >
                        FDE description
                    </button>
                    {this.props.aircraftUIConfig.aircraftViewPage.hasOmsMdcMessages &&
                        this.props.infoBoxType !== 'INFO' &&
                        this.props.infoBoxType !== 'STATUS' &&
                        this.state.fdeFault !== null && (
                            <button
                                className={this.state.omsRelatedTabStyle}
                                onClick={() => {
                                    this.handleTabSelection('RELA_OMS')
                                }}
                            >
                                {this.state.relatedTabLabel}
                            </button>
                        )}
                </div>
                {this.handleShowTab()}
            </div>
        )
    }

    handleTabSelection(tab) {
        this.setState({
            overviewTabStyle:
                tab === 'OVERVIEW'
                    ? 'contentFdeFault__tabPanel active'
                    : 'contentFdeFault__tabPanel',
            fdeDescriptionTabStyle:
                tab === 'FDE_DESC'
                    ? 'contentFdeFault__tabPanel active'
                    : 'contentFdeFault__tabPanel',
            omsRelatedTabStyle:
                tab === 'RELA_OMS'
                    ? 'contentFdeFault__tabPanel active'
                    : 'contentFdeFault__tabPanel',
            eventCountTabStyle:
                tab === 'EVT_COUNT'
                    ? 'contentFdeFault__tabPanel active'
                    : 'contentFdeFault__tabPanel',
            activeTabID: tab
        })
    }

    handleShowTab() {
        let showTab = <div />
        if (this.state.activeTabID === 'FDE_DESC') {
            showTab = (
                <div className='flex-row-simple contentFdeFault__body content-fde-fault-body-font'>
                    <div className='flex-column-simple content-fde-fault-column-overflow'>
                        {this.addDataRow('', this.props.faultDescription, '')}
                    </div>
                </div>
            )
        } else if (this.state.activeTabID === 'RELA_OMS' && this.props.omsMessages) {
            showTab = (
                <div className='flex-row-simple contentFdeFault__body content-fde-fault-body-font'>
                    <div className='flex-column-simple content-fde-fault-column-overflow'>
                        {this.props.omsMessages.map(message => (
                            <React.Fragment
                                key={
                                    this.props.faultCode +
                                    '_' +
                                    message.faultCode +
                                    '_' +
                                    Math.random() * 90000
                                }
                            >
                                {this.addOmsDataRow(message.faultMessage, message.faultDescription)}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )
        } else if (this.state.activeTabID === 'OVERVIEW') {
            showTab = (
                <div className='flex-row-simple contentFdeFault__body content-fde-fault-body-font'>
                    <div className='flex-column-simple content-fde-fault-column-left'>
                        {this.addDataRow(
                            'Time',
                            moment.utc(this.props.faultTimestamp).format('HH:mm:ss'),
                            ''
                        )}
                        {this.addDataRow('Altitude', this.props.altitude, 'ft')}
                        {this.addDataRow('Outside Temp', this.props.outsideTemp, '°C')}
                        {this.addDataRow('Air Speed', Math.round(this.props.airSpeed), 'kts')}
                    </div>
                    <div className='flex-column-simple content-fde-fault-column-right'>
                        {this.addDataRow('Flight Phase', this.props.flightPhase, '')}
                        {this.addDataRow(
                            'Fault time since take off',
                            this.props.departureTime
                                ? tools.getFormattedTimeDiff(
                                      this.props.faultTimestamp,
                                      this.props.departureTime
                                  )
                                : '-',
                            ''
                        )}
                    </div>
                </div>
            )
        }

        return showTab
    }

    addDataRow(key, value, unit) {
        let formattedValue = '-'
        if (value || value !== '') {
            formattedValue = value
            if (unit || unit !== '') {
                formattedValue = formattedValue + ' ' + unit
            }
        }

        return (
            <div className='flex-row-simple content-fde-fault-row'>
                {key !== '' ? <div className='content-fde-fault-row-key'>{key + ':'}</div> : ''}
                <div>{formattedValue}</div>
            </div>
        )
    }

    addOmsDataRow(mesage, messageDescription) {
        return (
            <div className='contentFdeFault__omsMessage'>
                <div className='contentFdeFault__omsMessage__row contentFdeFault__omsMessage__key__font'>
                    Related OMS message:
                </div>
                <div className='contentFdeFault__omsMessage__row'>
                    {mesage || mesage !== '' ? mesage : '-'}
                </div>
                <div className='contentFdeFault__omsMessage__row'>
                    <span className='contentFdeFault__omsMessage__key__font'>Description:</span>
                    {messageDescription || messageDescription !== '' ? messageDescription : '-'}
                </div>
            </div>
        )
    }
}
