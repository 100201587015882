import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import "./CmsRow.css";
import "./CmsList.css";
import * as tools from "../utils/CommonTools";
import CustomTooltip from "../Global Components/CustomTooltip";

export default class CmsRow extends Component {
  constructor(props) {
    super(props);

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  render() {
    if (this.props.faultSeverity === "F" || this.props.faultSeverity === "Z") {
      return (
        <div
          id={this.props.id}
          className={
            (this.props.faultSeverity === "Z" ? "cms-row-black" : "cms-row") +
            (this.props.rowIsSubtle ? " subtle-row" : "") +
            (!this.props.rowIsFiltered ? " invisible-row" : "")
          }
          onClick={() => {
            if (this.props.faultSeverity === "Z") {
              this.props.changeFlightSelection({
                label: this.props.faultCode.replace(/-/g, " "),
                value: null,
              });
            } else {
              this.handleRowClick();
            }
          }}
        >
          <div className={`indicator ${this.props.color}`}></div>
          <div className={"cms-code-section cms-bold-link-font-white"}>
            {tools.formatCodeVisually(this.props.faultCode)}
          </div>
          <div className="cms-message-section cms-font">
            {this.props.faultMessage}
          </div>
          {this.props.faultSeverity === "Z" ? (
            <div></div>
          ) : (
            <div
              className={
                "cms-timestamp-section cms-font" +
                  this.props.usingApproximateTimestamp ===
                true
                  ? " cms-approx-timestamp"
                  : " "
              }
            >
              <div className="cms-timestamp-text">
                {this.props.faultDescription ===
                "This fault has an invalid date."
                  ? "-"
                  : tools.formatDate(this.props.faultTimestamp)}
                <div
                  style={{
                    display: "flex",
                    alignSelf: "flexEnd",
                    width: "40%",
                  }}
                >
                  {this.props.usingApproximateTimestamp && (
                    <CustomTooltip
                      content={
                        "This approximate timestamp will be updated once data is uploaded."
                      }
                      tooltipType={"semantic"}
                      icon={
                        <FeatherIcon
                          icon="alert-circle"
                          width={36}
                          height={36}
                          className="cms__informativeMessage__banner__icon"
                        />
                      }
                      placement={"right-start"}
                    >
                      <div>
                        <FeatherIcon
                            icon="info"
                            height={14}
                            width={14}
                            style={{
                              color: "#FFFFFF",
                              fontWeight: "700",
                              marginTop: "3px",
                              cursor: "pointer",
                              display: "flex",
                              // position: "relative",
                              // justifyContent: "flex-start",
                            }}
                        />
                      </div>
                    </CustomTooltip>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div
          id={this.props.id}
          className={
            "cms-row" +
            (this.props.rowIsSubtle ? " subtle-row" : "") +
            (!this.props.rowIsFiltered ? " invisible-row" : "")
          }
          onClick={this.handleRowClick}
        >
          <div className={`indicator ${this.props.color}`}></div>
          <div className={"cms-code-section cms-bold-link-font"}>
            {tools.formatCodeVisually(this.props.faultCode)}
          </div>
          <div className="cms-message-section cms-font">
            {this.props.faultMessage}
          </div>
          <div
            className={
              "cms-timestamp-section cms-font" +
                this.props.usingApproximateTimestamp ===
              true
                ? " cms-approx-timestamp"
                : " "
            }
          >
            <div className="cms-timestamp-text">
              {this.props.faultDescription === "This fault has an invalid date."
                ? "-"
                : tools.formatDate(this.props.faultTimestamp)}
              <div
                style={{ display: "flex", alignSelf: "flexEnd", width: "40%" }}
              >
                {this.props.usingApproximateTimestamp && (
                  <CustomTooltip
                    content={
                      "This approximate timestamp will be updated once data is uploaded."
                    }
                    tooltipType={"semantic"}
                    icon={
                      <FeatherIcon
                        icon="alert-circle"
                        width={36}
                        height={36}
                        className="cms__informativeMessage__banner__icon"
                      />
                    }
                    placement={"right-start"}
                  >
                    <div>
                      <FeatherIcon
                          icon="info"
                          height={14}
                          width={14}
                          style={{
                            color: "#FFFFFF",
                            fontWeight: "700",
                            marginTop: "3px",
                            cursor: "pointer",
                            display: "flex",
                            // position: "relative",
                            // justifyContent: "flex-start",
                          }}
                      />
                    </div>
                  </CustomTooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  handleRowClick() {
    this.props.cmsRowClickEvent(
      this.props.faultCode,
      this.props.faultTimestamp
    );
  }
}
