import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for the engine in flight reports (EIFR) on the Global Vision aircrafts
*/

const EIFR_ENDPOINT = Api.EIFR_ENDPOINT;

// Returns the list of EIFR fo an aircraft for a specific session number
export async function getEifrList(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  startDate,
  endDate,
  serialNumber,
  axiosCancelSource
) {
  let result;

  const data = {
    params: {
      tailNumber: tailNumber,
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      startDate: startDate,
      endDate: endDate,
      serialNumber: serialNumber,
    },
  };

  try {
    result = await AxiosService(EIFR_ENDPOINT.url, EIFR_ENDPOINT.method, data);
  } catch (error) {
    console.error(error);
  }

  return result;
}
