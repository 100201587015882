import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import FeatherIcon from 'feather-icons-react'
import './SideNav.css'
import Feedback from '../FeedBack Components/src/Feedback/Feedback' //Do not delete until global styles are removed from this file.
import MyCRCProps from '../Constants/FeedbackConsts'
import NotificationIcon from '../Notification Components/NotificationIcon'
import NotificationToast from '../Notification Components/NotificationToast'
import _ from 'lodash'
import CustomButton from '../Global Components/CustomButton'
import CustomTooltip from '../Global Components/CustomTooltip'
import { sendEmail } from '../utils/CommonTools'
import { Link, useHistory } from 'react-router-dom'
import * as featureToggleTools from '../Feature Toggles/FeatureToggleTools'
import Expand from '../resources/expand.svg'
import Collapse from '../resources/collapse.svg'
import UserGuides from '../resources/user-guides.svg'
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined'
import MemoryRoundedIcon from '@mui/icons-material/MemoryRounded'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutIcon from '@mui/icons-material/Logout'

const listStyle = {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#202020',
    width: 280,
    height: '100%',
    zIndex: '0 !important',
    color: 'white',
    padding: '0',
    margin: '0'
}

const fullListStyle = {
    width: 'auto',
    zIndex: '0 !important',
    backgroundColor: '#303030',
    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.5)',
    padding: '0',
    margin: '0'
}

export default function SwipeableTemporaryDrawer(props) {
    const [state, setState] = useState({
        left: false
    })
    const [navigationOptions, setNavigationOptions] = useState([])
    const [midNavigationOptions, setMidNavigationOptions] = useState([])
    const [bottomNavigationOptions, setBottomNavigationOptions] = useState([])
    const myMx = 'MyMx'
    const pMx = 'PMx'
    const [userInfo] = useState(
        localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
    )
    const history = useHistory()

    /**
     * Function to handle navigation options for MyMaintenance mode.
     * @requires props.uploadsRolesPermissions.
     * @returns navigationOptions (state) updated.
     *
     */
    const loadMyMaintenanceNavigationOptions = () => {
        const myMxNavOptions = [
            {
                title: 'Fleet View',
                link: '/fleetview',
                icon: (
                    <FeatherIcon
                        icon='grid'
                        className='sideNav__options__icons'
                        id={'fleetviewIcon'}
                    />
                )
            },

            {
                title: 'Notifications',
                link: '/notifications',
                icon: (
                    <FeatherIcon
                        icon='bell'
                        className='sideNav__options__icons'
                        id={'notificationIcon'}
                    />
                )
            },
            {
                title: 'Configuration',
                link: '/configview',
                icon: (
                    <FeatherIcon
                        icon='settings'
                        className='sideNav__options__icons'
                        id={'configIcon'}
                    />
                )
            },
            {
                title: 'User Guides & References',
                link: '/userguides',
                icon: (
                    <FeatherIcon
                        icon='book-open'
                        className='sideNav__options__icons'
                        id={'guidesIcon'}
                    />
                )
            }
        ]

        const uploadOptions = {
            title: 'Uploads',
            link: '/uploads',
            icon: <FeatherIcon icon='upload' className='sideNav__options__icons' id='uploadsIcon' />
        }

        const pmxOptions = {
            title: 'Predictive Maintenance',
            tooltipText: (
                <span>
                    Switch to <br />
                    <strong>Predictive Maintenance</strong>
                </span>
            ),
            link: '/predictive',
            handleMode: () => props.setCurrentMode(pMx),
            icon: (
                <div className='pmx__icon'>
                    <FeatherIcon
                        icon='cpu'
                        className='sideNav__options__icons'
                        id='predictiveMaintenaceIcon'
                    />
                    <span className='pmx__icon__text'>Alpha</span>
                </div>
            )
        }

        if (props.uploadsRolesPermissions?.createGroundLoadingRequest) {
            myMxNavOptions.push(uploadOptions)
        }

        if (
            featureToggleTools.isFeatureToggleActive('ft-pmx-enable') &&
            props.rolesPermissions?.predictiveMaintenanceAccess
        ) {
            myMxNavOptions.push(pmxOptions)
        }

        setNavigationOptions(myMxNavOptions)
    }

    /**
     * Function to handle navigation options for Predictive Maintenance mode.
     * @returns navigationOptions (state) updated.
     *
     */
    const loadPredictiveNavigationOptions = () => {
        const pMxNavOptions = [
            {
                title: 'myMaintenance',
                tooltipText: (
                    <span>
                        Switch to <br />
                        <strong>myMaintenance</strong>
                    </span>
                ),
                deactivated: false,
                link: '/fleetview',
                handleMode: () => props.setCurrentMode(myMx),
                icon: (
                    <GridViewRoundedIcon
                        fontSize='large'
                        className='sideNav__options__icons sideNav__options__icons--gold'
                        alt='my-maintenance'
                        style={{ color: '#9F8A76' }}
                    />
                )
            },
            {
                title: 'Predictive Maintenance',
                deactivated: false,
                link: '/predictive',
                icon: (
                    <MemoryRoundedIcon
                        fontSize='large'
                        className='sideNav__options__icons sideNav__options__icons--gold'
                        alt='predictive-maintenance'
                    />
                )
            }
        ]

        const pMxMidNavOptions = [
            {
                title: 'Landing Page',
                tooltipText: 'Landing Page',
                deactivated: false,
                link: '/predictive',
                icon: (
                    <AutoAwesomeMosaicOutlinedIcon
                        fontSize='large'
                        className='sideNav__options__icons'
                        alt='landing-page'
                    ></AutoAwesomeMosaicOutlinedIcon>
                )
            },
            {
                title: 'Notifications',
                tooltipText: 'Notifications (Not available for Alpha)',
                deactivated: true,
                link: '#',
                icon: (
                    <NotificationsOutlinedIcon
                        fontSize='large'
                        className='sideNav__options__icons'
                        alt='notifications'
                        id={'notificationIcon'}
                    />
                )
            }
        ]

        const pMxBottomNavOptions = [
            {
                title: 'Configuration',
                tooltipText: 'Configuration (Not available for Alpha)',
                deactivated: true,
                link: '#',
                icon: (
                    <SettingsOutlinedIcon
                        fontSize='large'
                        className='sideNav__options__icons'
                        alt='configurations'
                        id={'configIcon'}
                    />
                )
            },
            {
                title: 'User Guides and Manuals',
                tooltipText: 'User Guides and Manuals (Coming soon)',
                deactivated: true,
                link: '#',
                icon: <img src={UserGuides} className='sideNav__options__icons' id={'guidesIcon'} />
            }
        ]

        setNavigationOptions(pMxNavOptions)
        setBottomNavigationOptions(pMxBottomNavOptions)
        setMidNavigationOptions(pMxMidNavOptions)
    }

    const toggleDrawer = anchor => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: !state.left })
    }

    const handleNotificationIndicator = count => {
        props.handleNotificationCount(count)
    }

    const handleLogout = () => {
        // tools.cleanAuthLocalStorage(); // It will be skipped in case that index.js express middleware is blocking the login page.
    }

    const usabillaGetFeedbackTriggerFunctions = () => {
        window.usabilla_live('data', {
            custom: {
                userID: userInfo.userId,
                userEmail: userInfo.userEmail,
                appMode: props.currentMode
            }
        }) // Sends custom data to usabilla getfeeback froms
        window.usabilla_live('click') // Triggers the GetFeedback feedback forms.
    }

    const handleFeedBackClick = anchor => {
        toggleDrawer(anchor, false)

        if (featureToggleTools.isFeatureToggleActive('ft-getfeedback-feedback')) {
            // If usabilla has not been initialized then we do it and call the
            // click action.
            if (!window.usabilla_live) {
                loadGetFeedbackScript()
                setTimeout(() => {
                    usabillaGetFeedbackTriggerFunctions()
                }, 500)
            } else {
                usabillaGetFeedbackTriggerFunctions()
            }
        } else {
            document.getElementById('feedback-btn').click()
        }
    }

    /**
     * Loads the getFeedback script.
     * @type {(function(): void)|*}
     */
    const loadGetFeedbackScript = useCallback(() => {
        const head = document.getElementsByTagName('head')[0]
        const script = document.createElement('script')
        script.src = 'getFeedback.js'
        head.appendChild(script)
    }, [])

    useEffect(() => {
        if (props.currentMode === myMx && props.rolesPermissions && props.uploadsRolesPermissions) {
            loadMyMaintenanceNavigationOptions()
        } else if (props.currentMode === pMx) {
            loadPredictiveNavigationOptions()
        }
    }, [props.currentMode, props.rolesPermissions, props.uploadsRolesPermissions])

    // Updates the myMX menu configuration when the browser back arrow is clicked from PMx.
    // It is after of the return of useEffect, to fire when it is about to unmount.
    useEffect(() => {
        return () => {
            if (history && history.action === 'POP' && props.currentMode === pMx) {
                props.setCurrentMode(myMx)
            }
        }
    }, [history, history?.action, props.currentMode])

    /* The expanded side navigation list */
    const list = (anchor, currentMode) => {
        if (currentMode === myMx) {
            return (
                <div id='side-nav' className='sideNav__expanded' style={{ background: '#202020' }}>
                    <div
                        className={clsx(listStyle, {
                            [fullListStyle]: anchor === 'top' || anchor === 'bottom'
                        })}
                        role='presentation'
                        onKeyDown={toggleDrawer(anchor, false)}
                    >
                        <div className='sideNav__expanded__topSection'>
                            <div className='sideNav__expanded__options__header'>
                                <div
                                    className={
                                        'sideNav__expanded__options__iconsContainer sideNav__options__iconsContainer__active-false'
                                    }
                                >
                                    <Button
                                        onClick={toggleDrawer(anchor, true)}
                                        className={'sideNav__menuButton'}
                                    >
                                        <FeatherIcon
                                            icon='menu'
                                            className='sideNav__options__icons'
                                            style={{ transform: 'rotate(90deg)' }}
                                        />
                                    </Button>
                                </div>
                                <span className='sideNav__options__listItemText__MuiListItemText-primary no-text-cursor'>
                                    {props.currentMode === myMx
                                        ? 'myMaintenance'
                                        : 'Predictive Maintenance'}
                                </span>
                            </div>
                            <List className='sideNav__expanded__options__list'>
                                {navigationOptions.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Link
                                            color='primary'
                                            to={item.link}
                                            onClick={toggleDrawer(anchor, false)}
                                        >
                                            <ListItem
                                                button
                                                key={item.link}
                                                className={'sideNav__expanded__options__listItem'}
                                                onClick={item.handleMode ? item.handleMode : null}
                                            >
                                                <div
                                                    className={
                                                        'sideNav__expanded__options__iconsContainer sideNav__options__iconsContainer__active-' +
                                                        (props.breadCrumbs === item.link
                                                            ? 'true'
                                                            : 'false')
                                                    }
                                                >
                                                    {item.link === '/notifications' &&
                                                    props.notificationCount ? (
                                                        <NotificationIcon
                                                            notificationCount={
                                                                props.notificationCount
                                                            }
                                                            type={'iconButton'}
                                                            id={'notificationIcon'}
                                                        />
                                                    ) : (
                                                        item.icon
                                                    )}
                                                </div>
                                                <ListItemText
                                                    primary={item.title}
                                                    classes={{
                                                        primary:
                                                            'sideNav__options__listItemText__MuiListItemText-primary'
                                                    }}
                                                />
                                            </ListItem>
                                        </Link>
                                    </React.Fragment>
                                ))}
                            </List>
                            <div className='sideNav__separator__out'>
                                <div className='sideNav__separator__in'></div>
                            </div>
                        </div>
                        <div className='sideNav__expanded__bottomSection'>
                            <div className='sideNav__options__logout'>
                                <Link to={'/applogout'}>
                                    <CustomButton
                                        id={'historic-data-button'}
                                        text={'LOG OUT'}
                                        buttonStyle={'pill'}
                                        colorStyle={'secondary'}
                                        onClickAction={handleLogout}
                                        customWidth={'200'}
                                    />
                                </Link>
                            </div>
                            <div className='sideNav__separator__out'>
                                <div className='sideNav__separator__in__yellow'></div>
                            </div>
                            <div
                                key='app-feedback'
                                className='sideNav__options__feedback'
                                onClick={anchor => {
                                    handleFeedBackClick(anchor)
                                }}
                            >
                                <div
                                    className={
                                        'sideNav__expanded__options__iconsContainer sideNav__options__iconsContainer__active-false'
                                    }
                                >
                                    <FeatherIcon
                                        icon='message-square'
                                        className='sideNav__options__icons__yellow'
                                    />
                                </div>
                                <span className='sideNav__options__listItemText__MuiListItemText-primary__yellow'>
                                    SEND FEEDBACK
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (currentMode === pMx) {
            return (
                <div
                    id='side-nav'
                    className='sideNav__expanded__pMx'
                    style={{ background: '#202020' }}
                >
                    <div
                        className={clsx(listStyle, {
                            [fullListStyle]: anchor === 'top' || anchor === 'bottom'
                        })}
                        role='presentation'
                        onKeyDown={toggleDrawer(anchor, false)}
                        style={listStyle}
                    >
                        <div className='sideNav__topSection__pMx'>
                            <CustomTooltip content={'Collapse Menu'} tooltipType={'general'}>
                                <div className='sideNav__options__header'>
                                    <Button
                                        onClick={toggleDrawer(anchor, true)}
                                        className={'sideNav__menuButton'}
                                    >
                                        <img src={Collapse} className='sideNav__options__icons' />
                                    </Button>
                                </div>
                            </CustomTooltip>
                            <div className='sideNav__divider'></div>
                            <div className='sideNav__divider'></div>
                            <div className='sideNav__options__list__container__pMx'>
                                <div className='sideNav__options__list'>
                                    {navigationOptions.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {item.title === 'Predictive Maintenance' ? (
                                                <div
                                                    className='sideNav__options__listItem__pMx'
                                                    onClick={() => toggleDrawer(anchor, false)}
                                                >
                                                    <div
                                                        className={
                                                            'sideNav__options__container__pMx ' +
                                                            (item.title === 'Predictive Maintenance'
                                                                ? 'sideNav__options__container__pMxIcon__active-'
                                                                : 'sideNav__options__container__pMx__active-') +
                                                            (props.breadCrumbs?.includes(
                                                                item.link
                                                            ) || item.link == '/predictive'
                                                                ? 'true'
                                                                : 'false') +
                                                            ' sideNav__options__container__pMx__deactivated-' +
                                                            (item.deactivated ? 'true' : 'false')
                                                        }
                                                        onClick={
                                                            item.handleMode ? item.handleMode : null
                                                        }
                                                    >
                                                        <div
                                                            className='sideNav__expanded__options__iconsContainer__pMx sideNav__options__titleContainer--pMx__upper_tab'
                                                            id={item.title}
                                                            style={{ color: '#c7b289' }}
                                                        >
                                                            {item.title ===
                                                                'Predictive Maintenance' && (
                                                                <div className='sideNav__options__alphaBannerContainer'>
                                                                    <div className='sideNav__options__alphaBanner'>
                                                                        Alpha
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item.icon}
                                                        </div>
                                                        <div
                                                            style={{ color: '#c7b289' }}
                                                            className='sideNav__options__titleContainer'
                                                        >
                                                            {item.title}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <span>
                                                    <div className='sideNav__options__listItem__pMx'>
                                                        <Link
                                                            color='primary'
                                                            to={item.link}
                                                            onClick={toggleDrawer(anchor, false)}
                                                        >
                                                            <div
                                                                className={
                                                                    'sideNav__options__container__pMx ' +
                                                                    (item.title ===
                                                                    'Predictive Maintenance'
                                                                        ? 'sideNav__options__container__pMxIcon__active-'
                                                                        : 'sideNav__options__container__pMx__active-') +
                                                                    (props.breadCrumbs?.includes(
                                                                        item.link
                                                                    ) || item.link == '/predictive'
                                                                        ? 'true'
                                                                        : 'false') +
                                                                    ' sideNav__options__container__pMx__deactivated-' +
                                                                    (item.deactivated
                                                                        ? 'true'
                                                                        : 'false')
                                                                }
                                                                onClick={
                                                                    item.handleMode
                                                                        ? item.handleMode
                                                                        : null
                                                                }
                                                            >
                                                                <div
                                                                    className='sideNav__expanded__options__iconsContainer__pMx sideNav__options__icons--gold'
                                                                    id={item.title}
                                                                    style={{ color: '#9F8A76' }}
                                                                >
                                                                    {item.title ===
                                                                        'Predictive Maintenance' && (
                                                                        <div className='sideNav__options__alphaBannerContainer'>
                                                                            <div className='sideNav__options__alphaBanner'>
                                                                                Alpha
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {item.icon}
                                                                </div>
                                                                <div
                                                                    className='sideNav__options__titleContainer'
                                                                    style={{ color: '#9F8A76' }}
                                                                >
                                                                    {item.title}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    {index < navigationOptions.length - 1 && (
                                                        <div
                                                            className='sideNav__divider'
                                                            style={{ margin: 0 }}
                                                        ></div>
                                                    )}
                                                </span>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            <div className='sideNav__divider'></div>
                            <div className='sideNav__divider'></div>
                            <div className='sideNav__divider'></div>
                        </div>
                        <div className='sideNav__midSection__pMx'>
                            <div className='sideNav__options__list__container__pMx'>
                                <div className='sideNav__options__list'>
                                    {midNavigationOptions.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div
                                                className={
                                                    'sideNav__options__listItem__pMx__active-' +
                                                    (props.breadCrumbs === item.link
                                                        ? 'true'
                                                        : 'false')
                                                }
                                                style={{
                                                    cursor: item.deactivated
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                            >
                                                <Link
                                                    color='primary'
                                                    to={item.link}
                                                    onClick={toggleDrawer(anchor, false)}
                                                    style={{
                                                        pointerEvents: item.deactivated
                                                            ? 'none'
                                                            : 'auto'
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            'sideNav__options__container__pMx sideNav__options__container__pMx__active-' +
                                                            (props.breadCrumbs === item.link ||
                                                            item.link == '/predictive'
                                                                ? 'true'
                                                                : 'false') +
                                                            ' sideNav__options__container__pMx__deactivated-' +
                                                            (item.deactivated ? 'true' : 'false')
                                                        }
                                                        onClick={
                                                            item.handleMode ? item.handleMode : null
                                                        }
                                                    >
                                                        <div
                                                            className='sideNav__expanded__options__iconsContainer__pMx'
                                                            id={item.title}
                                                        >
                                                            {item.icon}
                                                        </div>
                                                        <div className='sideNav__options__titleContainer'>
                                                            {item.title}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='sideNav__bottomSection__pMx'>
                            <div className='sideNav__divider'></div>
                            <div className='sideNav__divider'></div>

                            <div className='sideNav__options__list__container__pMx'>
                                <div className='sideNav__options__list'>
                                    {bottomNavigationOptions.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div
                                                className='sideNav__options__listItem__pMx'
                                                style={{
                                                    cursor: item.deactivated
                                                        ? 'not-allowed'
                                                        : 'auto'
                                                }}
                                            >
                                                <Link
                                                    color='primary'
                                                    to={item.link}
                                                    onClick={toggleDrawer(anchor, false)}
                                                    style={{
                                                        pointerEvents: item.deactivated
                                                            ? 'none'
                                                            : 'auto'
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            'sideNav__options__container__pMx sideNav__options__container__pMx__active-' +
                                                            (props.breadCrumbs === item.link ||
                                                            item.link === '/predictive'
                                                                ? 'true'
                                                                : 'false') +
                                                            ' sideNav__options__container__pMx__deactivated-' +
                                                            (item.deactivated ? 'true' : 'false')
                                                        }
                                                        onClick={
                                                            item.handleMode ? item.handleMode : null
                                                        }
                                                    >
                                                        <div
                                                            className='sideNav__expanded__options__iconsContainer__pMx'
                                                            id={item.title}
                                                        >
                                                            {item.icon}
                                                        </div>
                                                        <div className='sideNav__options__titleContainer'>
                                                            {item.title}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>

                            <div className='sideNav__divider'></div>
                            <div className='sideNav__divider'></div>

                            <div className='sideNav__expanded__options__logout'>
                                <Link to={'/applogout'}>
                                    <div
                                        className={
                                            'sideNav__options__container__pMx sideNav__options__container__pMx__active-true'
                                        }
                                        id={'historic-data-button'}
                                        onClick={() => handleLogout}
                                    >
                                        <div className='sideNav__expanded__options__iconsContainer__pMx'>
                                            <FeatherIcon
                                                icon='log-out'
                                                className='sideNav__options__icons'
                                            />
                                        </div>
                                        <div className='sideNav__options__titleContainer'>
                                            Log out
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className='menu-hb sideNav'>
            {props.currentMode === myMx ? (
                <React.Fragment>
                    <div id='sideNav__topSection' className='sideNav__topSection'>
                        {['left'].map(anchor => (
                            <React.Fragment key={anchor}>
                                <CustomTooltip content={'Expand Menu'} tooltipType={'general'}>
                                    <div className='sideNav__options__header'>
                                        <Button
                                            onClick={toggleDrawer(anchor, true)}
                                            className={'sideNav__menuButton'}
                                        >
                                            <FeatherIcon
                                                icon='menu'
                                                className='sideNav__options__icons'
                                            />
                                        </Button>
                                    </div>
                                </CustomTooltip>
                                <div className='sideNav__options__list'>
                                    {navigationOptions.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <CustomTooltip
                                                content={
                                                    item.title === 'Predictive Maintenance' ||
                                                    item.title === 'myMaintenance'
                                                        ? item.tooltipText
                                                        : item.title
                                                }
                                                tooltipType={'general'}
                                            >
                                                <div className='sideNav__options__listItem'>
                                                    <Link
                                                        color='primary'
                                                        to={item.link}
                                                        onClick={
                                                            item.handleMode ? item.handleMode : null
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                'sideNav__options__iconsContainer sideNav__options__iconsContainer__active-' +
                                                                (props.breadCrumbs === item.link
                                                                    ? 'true'
                                                                    : 'false')
                                                            }
                                                            id={item.title}
                                                        >
                                                            {item.link === '/notifications' ? (
                                                                <NotificationIcon
                                                                    notificationCount={
                                                                        props.notificationCount
                                                                    }
                                                                    type={'iconButton'}
                                                                />
                                                            ) : (
                                                                item.icon
                                                            )}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </CustomTooltip>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <SwipeableDrawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    onOpen={toggleDrawer(anchor, true)}
                                >
                                    {list(anchor, props.currentMode)}
                                </SwipeableDrawer>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className='sideNav__bottomSection'>
                        <div className='sideNav__separator__in__yellow'></div>
                        <CustomTooltip
                            content={
                                <span>
                                    How can we improve?
                                    <br></br>
                                    {/* TODO: This is disabled until feedback server is fixed */
                                    /*<b>Send Feedback.</b>*/}
                                    <b>
                                        Please contact us by email at smartlink@aero.bombardier.com.
                                    </b>
                                </span>
                            }
                            tooltipType={'general'}
                            placement={'top-end'}
                        >
                            <div className='sideNav__options__listItem'>
                                <div
                                    key='app-feedback'
                                    id='app-feedback'
                                    /* TODO: This is disabled until feedback server is fixed */
                                    /*className={
                    "sideNav__options__iconsContainer sideNav__options__iconsContainer__active-false"
                  }*/
                                    className={'sideNav__options__iconsContainer'}
                                    onClick={anchor => {
                                        /* TODO: This is disabled until feedback server is fixed */
                                        /* handleFeedBackClick(anchor); */
                                        sendEmail(
                                            'smartlink@aero.bombardier.com',
                                            'Feedback on myMaintenance',
                                            'I would like to provide feedback on...'
                                        )
                                    }}
                                >
                                    <FeatherIcon
                                        icon='message-square'
                                        className='sideNav__options__icons__yellow'
                                    />
                                </div>
                            </div>
                        </CustomTooltip>
                    </div>
                    <div style={{ visibility: 'hidden' }}>
                        <Feedback navOpen={false} {...MyCRCProps} />
                    </div>

                    {props.currentMode === myMx ? (
                        <NotificationToast
                            url={''}
                            redirectPath={'/notifications'}
                            handleNotificationIndicator={handleNotificationIndicator}
                        />
                    ) : null}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div id='sideNav__topSection' className='sideNav__topSection__pMx'>
                        {['left'].map(anchor => (
                            <React.Fragment key={anchor}>
                                <CustomTooltip content={'Expand Menu'} tooltipType={'general'}>
                                    <div className='sideNav__options__header'>
                                        <Button
                                            onClick={toggleDrawer(anchor, true)}
                                            className={'sideNav__menuButton'}
                                        >
                                            <img src={Expand} className='sideNav__options__icons' />
                                        </Button>
                                    </div>
                                </CustomTooltip>
                                <div className='sideNav__divider'></div>
                                <div className='sideNav__divider'></div>
                                <div className='sideNav__options__list__container__pMx'>
                                    <div className='sideNav__options__list'>
                                        {navigationOptions.map((item, index) => (
                                            <React.Fragment key={index}>
                                                {item.title === 'Predictive Maintenance' ? (
                                                    <span>
                                                        <div className='sideNav__options__listItem__pMx'>
                                                            <div
                                                                className={
                                                                    'sideNav__options__iconsContainer__pMx sideNav__options__container__pMxIcon__active-' +
                                                                    (props.breadCrumbs?.includes(
                                                                        item.link
                                                                    )
                                                                        ? 'true'
                                                                        : 'false')
                                                                }
                                                                id={item.title}
                                                            >
                                                                <div className='sideNav__options__alphaBannerContainer'>
                                                                    <div className='sideNav__options__alphaBanner'>
                                                                        Alpha
                                                                    </div>
                                                                </div>
                                                                {item.icon}
                                                            </div>
                                                        </div>
                                                        {index < navigationOptions.length - 1 && (
                                                            <div
                                                                className='sideNav__divider'
                                                                style={{ margin: 0 }}
                                                            ></div>
                                                        )}
                                                    </span>
                                                ) : (
                                                    <CustomTooltip
                                                        content={item.tooltipText}
                                                        tooltipType={'general'}
                                                    >
                                                        <span>
                                                            <div className='sideNav__options__listItem__pMx'>
                                                                <Link
                                                                    color='primary'
                                                                    to={item.link}
                                                                    onClick={
                                                                        item.handleMode
                                                                            ? item.handleMode
                                                                            : null
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            'sideNav__options__iconsContainer__pMx sideNav__options__container__pMx__active-' +
                                                                            (props.breadCrumbs?.includes(
                                                                                item.link
                                                                            )
                                                                                ? 'true'
                                                                                : 'false')
                                                                        }
                                                                        id={item.title}
                                                                    >
                                                                        {item.title ===
                                                                            'Predictive Maintenance' && (
                                                                            <div className='sideNav__options__alphaBannerContainer'>
                                                                                <div className='sideNav__options__alphaBanner'>
                                                                                    Alpha
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {item.icon}
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            {index <
                                                                navigationOptions.length - 1 && (
                                                                <div
                                                                    className='sideNav__divider'
                                                                    style={{ margin: 0 }}
                                                                ></div>
                                                            )}
                                                        </span>
                                                    </CustomTooltip>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                <div className='sideNav__divider'></div>
                                <div className='sideNav__divider'></div>
                                <div className='sideNav__divider'></div>
                                <SwipeableDrawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    onOpen={toggleDrawer(anchor, true)}
                                >
                                    {list(anchor, props.currentMode)}
                                </SwipeableDrawer>
                            </React.Fragment>
                        ))}
                    </div>
                    <div id='sideNav__midSection' className='sideNav__midSection__pMx'>
                        <div className='sideNav__options__list__container__pMx'>
                            <div className='sideNav__options__list'>
                                {midNavigationOptions.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <CustomTooltip
                                            content={item.tooltipText}
                                            tooltipType={'general'}
                                        >
                                            <div
                                                className={
                                                    'sideNav__options__listItem__pMx__active-' +
                                                    (props.breadCrumbs === item.link
                                                        ? 'true'
                                                        : 'false')
                                                }
                                                style={{
                                                    cursor: item.deactivated
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                            >
                                                <Link
                                                    color='primary'
                                                    to={item.link}
                                                    onClick={
                                                        item.handleMode ? item.handleMode : null
                                                    }
                                                    style={{
                                                        pointerEvents: item.deactivated
                                                            ? 'none'
                                                            : 'auto'
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            'sideNav__options__iconsContainer__pMx sideNav__options__container__pMx__active-' +
                                                            (props.breadCrumbs === item.link ||
                                                            item.link === '/predictive'
                                                                ? 'true'
                                                                : 'false') +
                                                            ' sideNav__options__iconsContainer-deactivated-' +
                                                            (item.deactivated ? 'true' : 'false')
                                                        }
                                                        id={item.title}
                                                    >
                                                        {item.icon}
                                                    </div>
                                                </Link>
                                            </div>
                                        </CustomTooltip>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div id='sideNav__bottomSection' className='sideNav__bottomSection__pMx'>
                        <div className='sideNav__divider'></div>
                        <div className='sideNav__divider'></div>

                        <div className='sideNav__options__list__container__pMx'>
                            <div className='sideNav__options__list'>
                                {bottomNavigationOptions.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <CustomTooltip
                                            content={item.tooltipText}
                                            tooltipType={'general'}
                                        >
                                            <div
                                                className='sideNav__options__listItem__pMx'
                                                style={{
                                                    cursor: item.deactivated
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                            >
                                                <Link
                                                    color='primary'
                                                    to={item.link}
                                                    onClick={
                                                        item.handleMode ? item.handleMode : null
                                                    }
                                                    style={{
                                                        pointerEvents: item.deactivated
                                                            ? 'none'
                                                            : 'auto'
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            'sideNav__options__iconsContainer__pMx sideNav__options__container__pMx__active-' +
                                                            (props.breadCrumbs === item.link ||
                                                            item.link === '/predictive'
                                                                ? 'true'
                                                                : 'false') +
                                                            ' sideNav__options__iconsContainer-deactivated-' +
                                                            (item.deactivated ? 'true' : 'false')
                                                        }
                                                        id={item.title}
                                                    >
                                                        {item.icon}
                                                    </div>
                                                </Link>
                                            </div>
                                        </CustomTooltip>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>

                        <div className='sideNav__divider'></div>
                        <div className='sideNav__divider'></div>

                        <CustomTooltip content='Log out' tooltipType={'general'}>
                            <div className='sideNav__options__listItem__pMx'>
                                <Link to={'/applogout'}>
                                    <div
                                        className={'sideNav__options__iconsContainer__pMx'}
                                        id={'historic-data-button'}
                                        onClick={() => handleLogout}
                                    >
                                        <LogoutIcon
                                            fontSize='large'
                                            alt='log-out'
                                            className='sideNav__options__icons'
                                        />
                                    </div>
                                </Link>
                            </div>
                        </CustomTooltip>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}
