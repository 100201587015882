import React, { Component } from "react";
import _ from "lodash";
import FilterBox from "../Global Components/FilterBox";
import FdeCasRow from "./FdeCasRow";
import "./FdeCasList.css";
import FeatherIcon from "feather-icons-react";

export default class FdeCasList extends Component {
  constructor(props) {
    super(props);
    this.createRows = this.createRows.bind(this);
    this.handleCodeFilterChange = this.handleCodeFilterChange.bind(this);
    this.handleMessageFilterChange = this.handleMessageFilterChange.bind(this);
    this.hasEventHeaderColumn = this.hasEventHeaderColumn.bind(this);
  }

  render() {
    return this.handleTableRender(
      this.props.fdecasList,
      this.props.selectedFdeFault,
      this.props.selectedOmsFault
    );
  }

  hasClearedCheckBox() {
    if (this.props.aircraftUIConfig.hasFaultTableFaultCode) {
      return (
        <div className="fde-cas-header-cleared-section fde-cas-header-font"></div>
      );
    } else {
      return <div style={{ width: "4%" }}></div>;
    }
  }

  hasOmsMdcMessages() {
    if (this.props.aircraftUIConfig.hasOmsMdcMessages) {
      return (
        <div className="fde-cas-header-oms-section fde-cas-header-font">
          {this.props.aircraftUIConfig.mdcTabLabel}
        </div>
      );
    } else {
      return <div style={{ width: "20px" }}></div>;
    }
  }

  hasEventHeaderColumn() {
    if (
      this.props.aircraftUIConfig.hasInFlightDataCard ||
      this.props.viewType !== "IFR"
    ) {
      return <div className="fde-cas-header-event">{"Event"}</div>;
    }
  }

  //
  handleTableRender(fdecasList, selectedFdeFault, selectedOmsFault) {
    const filteredFdeCasList = [];
    let faultShowCount = 0;
    fdecasList.forEach((fault) => {
      if (
        fault.faultSeverity !== (!this.props.infoSeriesToggle ? "N" : "") &&
        fault.faultSeverity !== (!this.props.statusSeriesToggle ? "S" : "") &&
        ((this.props.reduceCommonFaultsSeriesToggle === false &&
          fault.reduceCommonFaultStatus === false) ||
          this.props.reduceCommonFaultsSeriesToggle === true)
      ) {
        fault.isFilteredRow = this.isFilteredRow(
          fault.faultCode,
          fault.faultMessage
        );
        faultShowCount += fault.isFilteredRow ? 1 : 0;
        filteredFdeCasList.push(fault);
      }
    });

    const scroll = faultShowCount > 5;

    return (
      <div className="flex-column-simple fde-cas-table">
        <div className="flex-row-simple fde-cas-header">
          <div className="fde-cas-header-flag-section"></div>
          {this.props.aircraftUIConfig.hasFaultTableFaultCode ? (
            <div className="fde-cas-header-code-section fde-cas-header-font">
              <FilterBox
                id={"fde-cas-code-search"}
                placeholderText={"Code"}
                handleFilterChange={this.handleCodeFilterChange}
                customHeight={"42px"}
              />
            </div>
          ) : (
            <div></div>
          )}
          {this.hasClearedCheckBox()}
          {this.hasEventHeaderColumn()}
          <div className="fde-cas-header-fmsg-section fde-cas-header-font">
            <FilterBox
              id={"fde-cas-message-search"}
              placeholderText={"Fault Message"}
              handleFilterChange={this.handleMessageFilterChange}
              customHeight={"42px"}
            />
          </div>
          {this.hasOmsMdcMessages()}
          <div
            className={
              "fde-cas-header-font fde-cas-header-timestamp-section" +
              (scroll ? "-with-bar" : "")
            }
          >
            Fault Timestamp
          </div>
        </div>
        {this.props.aircraftUIConfig.fdeCasAvailable
          ? this.createRows(
              filteredFdeCasList,
              selectedFdeFault,
              selectedOmsFault,
              scroll
            )
          : this.fdeCasUnavailable()}
      </div>
    );
  }

  fdeCasUnavailable() {
    return (
      <div>
        <div className="fde-unavailable-title-container">
          <div className="fde-unavailable-title-icon">
            <FeatherIcon
              icon={"alert-triangle"}
              height={"26px"}
              width={"26px"}
              color={"#FFC72C"}
            />
          </div>
          <div className="fde-unavailable-title-text">
            <span>FDE Event Update</span>
          </div>
        </div>
        <p className="fde-unavailable-description">
          All FDE/CAS notifications have currently been paused for the{" "}
          {`${this.props.aircraftFamily} ${this.props.aircraftModel}`} aircraft.
          Our engineers are working to restore this functionality as soon as
          possible.
        </p>
      </div>
    );
  }

  // Create Row method
  createRows(filteredFdeCasList, selectedFdeFault, selectedOmsFault, scroll) {
    let rows = <div />;
    if (filteredFdeCasList) {
      rows = (
        <div className={scroll ? "fde-container-overflow scrollbar" : null}>
          {filteredFdeCasList.map((item) => (
            <React.Fragment key={item.id}>
              <FdeCasRow
                viewType={this.props.viewType}
                changeFlightSelection={this.props.changeFlightSelection}
                aircraftUIConfig={this.props.aircraftUIConfig}
                aircraftFamily={this.props.aircraftFamily}
                id={item.id}
                hasInFlightParameterData={item.hasInFlightParameterData}
                faultCode={item.faultCode}
                faultMessage={item.faultMessage}
                isFlightRow={item.isFlightRow}
                faultSeverity={item.faultSeverity}
                faultTimestamp={item.faultTimestamp}
                smartfixUrl={item.sfp_url}
                status={item.status}
                rowIsSubtle={this.isSubtleRow(
                  selectedFdeFault,
                  selectedOmsFault,
                  item.faultCode,
                  item.faultTimestamp,
                  item.id
                )}
                rowIsFiltered={item.isFilteredRow}
                faultCorrelationClickEvent={
                  this.props.faultCorrelationClickEvent
                }
                faultCleared={item.faultCleared}
                clearedTimestamp={item.clearedTimestamp}
                omsRelatedCount={item.omsRelatedCount}
                faultMessageBoxRef={this.props.faultMessageBoxRef}
              />
            </React.Fragment>
          ))}
        </div>
      );
      return rows;
    }
    return (
      <div className="fde-cas-row fde-cas-font fde-cas-wip">
        NO DATA AVAILABLE
      </div>
    );
  }

  isSubtleRow(
    selectedFdeFault,
    selectedOmsFault,
    fdeFaultCode,
    fdeFaultTimestamp,
    id
  ) {
    let isSubtle = true;

    if (selectedFdeFault) {
      if (
        fdeFaultCode === selectedFdeFault.faultCode &&
        fdeFaultTimestamp === selectedFdeFault.faultTimestamp &&
        id === selectedFdeFault.id
      ) {
        return false;
      }
    } else if (selectedOmsFault) {
      if (selectedOmsFault.fdeRelatedCount > 0) {
        if (
          _.find(selectedOmsFault.fdeRelated, {
            faultCode: fdeFaultCode,
            faultTimestamp: fdeFaultTimestamp,
          }) !== undefined
        ) {
          isSubtle = false;
        }
      }
    } else {
      isSubtle = false;
    }
    return isSubtle;
  }

  isFilteredRow(faultCode, faultMessage) {
    if (
      faultCode
        .toUpperCase()
        .includes(this.props.fdeFilterText.faultCode.toUpperCase()) &&
      faultMessage
        .toUpperCase()
        .includes(this.props.fdeFilterText.faultMessage.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleCodeFilterChange(textFieldFilterValue) {
    this.props.handleFdeFilterChange("CODE", textFieldFilterValue);
  }

  handleMessageFilterChange(textFieldFilterValue) {
    this.props.handleFdeFilterChange("MESSAGE", textFieldFilterValue);
  }
}
