import React from 'react';
import Card from '../Card.jsx';
import * as C from '../Colors';

const notifSideBarDescr = (
  <div>
    This is the Notifications page. 
    <br/>
    You can see latest events data here, as well as updates on <b>new features
    , releases, training materials &#38; schedules, </b> 
    etc.
  </div>
); 

const stepContent = [
  {
    steps: [
      notifSideBarDescr,
    ],
  },
];

export default function NotificationsViewOnboarding () {

  // defaults for this set
  const defaultStyles = {
    options: C.styleReset,
    buttonNext: {
      ...C.buttonStyles,
      color: C.blue,
    },
    buttonBack: {
      ...C.buttonStyles,
      background: "transparent",
      border: "1px solid " + C.white,
      color: C.white,
    },
    tooltipContainer: {
      textAlign: 'left'
    }
  };

  let trainings = [];

  stepContent.map( (x, index) => {
    trainings.push({
      target: x.target || 'body',
      disableBeacon: x.disableBeacon || true,
      content: 
        <Card paragraphs={x.steps} 
          index={index}
          stepCount={stepContent.length-1}
          pageIndex={3}
          pageName={"Notifications"}
        />, 
      placement: x.placement || 'center',
      styles: x.styles || defaultStyles, 
      spotlightClicks: x.spotlightClicks || false,
    });
  });

  return trainings;
}