import React from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import { RadioGroup, FormControlLabel, Checkbox } from "@mui/material";
import CustomCheckbox from "../../../Global Components/CustomCheckbox.jsx";
import PredictiveTooltip from "../../Global Components/PredictiveTooltip.jsx";

const CustomRadioGroup = ({
    id,
    reviewForm,
    handleChange,
    options,
}) => {
    let currValue = reviewForm && reviewForm.length > 0 && _.find(reviewForm, { question_id: id }).answer_ids;
    let newOptions = options.sort(
        function (optionA, optionB) {
            return optionA.option_id - optionB.option_id;
        }
    );

    return (
        <RadioGroup row>
            {newOptions.map((option, index) => {
                const containsMaintenanceContextTooltip = option.option.toLowerCase().includes("maintenance")
                return <FormControlLabel
                    name={`${id}`}
                    key={index}
                    value={option.option_id}
                    label={containsMaintenanceContextTooltip
                        ? <div style={{ display: "flex" }}>
                            {option.option}
                            <PredictiveTooltip
                                style={{ display: "flex" }}
                                element={<FeatherIcon
                                    icon="info"
                                    width={20}
                                    height={20}
                                    strokeWidth={1}
                                />}
                                text="A condition in which the aircraft requires some sort of maintenance (troubleshooting, inspection, repair, etc.)."
                                wide={true}
                            />
                        </div>
                        : option.option
                    }
                    control={
                        <CustomCheckbox
                            isChecked={currValue === option.option_id}
                            onChangeAction={() => handleChange(id, option, "option", "option_id")}
                        />
                    }
                    style={{ marginLeft: 50, marginRight: 0 }}
                />
            })}
        </RadioGroup>
    )
};

export default CustomRadioGroup;