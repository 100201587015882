import React from "react";
import CustomTooltip from "../Global Components/CustomTooltip";
import "./HistoricDataRow.css";
import { sendChooseHistoricalSessionEvent } from "../GA4/EventsCatalog";

function HistoricDataRow({
  sessionNumber,
  sessionStartTime,
  sessionEndTime,
  ifrSessionOrder,
  departureTime,
  arrivalTime,
  sessionType,
  startTime,
  endTime,
  airports,
  faultsInfoTotal,
  faultsAdvisoryTotal,
  faultsCautionTotal,
  faultsWarningTotal,
  flightCount,
  rowType,
  missingData,
  handleHistoricSessionSelection,
  handleRecentSessionSelection,
  selectedSessionStartTime,
  selectedSessionEndTime,
  selectedSessionNumber,
}) {
  const eventCountTooltipText =
    sessionType === "GROUND"
      ? "Total FDE/CAS events on ground."
      : "Total FDE/CAS events in flight.";

  // Conditions to be met to hilight a selected session row
  const hilightRow = (
    tmpSelectedSessionStartTime,
    tmpSelectedSessionEndTime,
    tmpSelectedSessionNumber
  ) => {
    if (tmpSelectedSessionNumber) {
      if (
        sessionStartTime === tmpSelectedSessionStartTime &&
        sessionEndTime === tmpSelectedSessionEndTime &&
        sessionNumber === tmpSelectedSessionNumber
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        sessionStartTime === tmpSelectedSessionStartTime &&
        sessionEndTime ===
          (tmpSelectedSessionEndTime
            ? tmpSelectedSessionEndTime
            : sessionEndTime)
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const renderHistoricDataRow = () => {
    const historicDataRowContent = (
      <div
        className={
          "historicDataRow" +
          " historicDataRow__isDisabled-" +
          (missingData ? "true" : "false") +
          (hilightRow(
            selectedSessionStartTime,
            selectedSessionEndTime,
            selectedSessionNumber
          ) ||
          ((!selectedSessionNumber || selectedSessionNumber === "CURRENT") &&
            sessionNumber === "CURRENT")
            ? " historicDataRow__isSelected"
            : "")
        }
        onClick={() => handleRowClick()}
        disabled={missingData}
      >
        {rowType !== "RECENT_SESSION" && rowType !== "RECENT_SESSION_0" && (
          <div className="historicDataRow__column__historyFlightSpacing"></div>
        )}
        <div className="historicDataRow__column__sessionNumber">
          <span className="historicDataRow__column__sessionNumber__text">
            {rowType === "RECENT_SESSION" || rowType === "RECENT_SESSION_0"
              ? sessionNumber
              : sessionType === "FLIGHT"
              ? sessionType + " " + flightCount
              : sessionType}
          </span>
        </div>
        <div className={"separator"}></div>
        <div className="historicDataRow__column__startDate">
          {rowType === "RECENT_SESSION" ? arrivalTime : startTime}
        </div>
        <div className={"separator"}></div>
        <div className="historicDataRow__column__endDate">
          {rowType === "RECENT_SESSION" ? departureTime : endTime}
        </div>
        <div className={"separator"}></div>
        <div className="historicDataRow__column__airports">{airports}</div>
        <div className={"separator"}></div>
        <CustomTooltip content={eventCountTooltipText}>
          <div
            className={
              faultsWarningTotal === 0
                ? hilightRow(
                    selectedSessionStartTime,
                    selectedSessionEndTime,
                    selectedSessionNumber
                  ) ||
                  ((!selectedSessionNumber ||
                    selectedSessionNumber === "CURRENT") &&
                    sessionNumber === "CURRENT")
                  ? "value_zero highlight"
                  : "value_zero"
                : "historicDataRow__column__faultWarning"
            }
          >
            {faultsWarningTotal.toString()}
          </div>
        </CustomTooltip>
        <CustomTooltip content={eventCountTooltipText}>
          <div
            className={
              faultsCautionTotal === 0
                ? hilightRow(
                    selectedSessionStartTime,
                    selectedSessionEndTime,
                    selectedSessionNumber
                  ) ||
                  ((!selectedSessionNumber ||
                    selectedSessionNumber === "CURRENT") &&
                    sessionNumber === "CURRENT")
                  ? "value_zero highlight"
                  : "value_zero"
                : "historicDataRow__column__faultCaution"
            }
          >
            {faultsCautionTotal.toString()}
          </div>
        </CustomTooltip>
        <CustomTooltip content={eventCountTooltipText}>
          <div
            className={
              faultsAdvisoryTotal === 0
                ? hilightRow(
                    selectedSessionStartTime,
                    selectedSessionEndTime,
                    selectedSessionNumber
                  ) ||
                  ((!selectedSessionNumber ||
                    selectedSessionNumber === "CURRENT") &&
                    sessionNumber === "CURRENT")
                  ? "value_zero highlight"
                  : "value_zero"
                : "historicDataRow__column__faultAdvisory"
            }
          >
            {faultsAdvisoryTotal.toString()}
          </div>
        </CustomTooltip>
        <CustomTooltip content={eventCountTooltipText}>
          <div
            className={
              faultsInfoTotal === 0
                ? hilightRow(
                    selectedSessionStartTime,
                    selectedSessionEndTime,
                    selectedSessionNumber
                  ) ||
                  ((!selectedSessionNumber ||
                    selectedSessionNumber === "CURRENT") &&
                    sessionNumber === "CURRENT")
                  ? "value_zero highlight"
                  : "value_zero"
                : "historicDataRow__column__faultInfo"
            }
          >
            {faultsInfoTotal.toString()}
          </div>
        </CustomTooltip>
      </div>
    );

    return historicDataRowContent;
  };

  const handleRowClick = () => {
    sendChooseHistoricalSessionEvent(
      sessionNumber,
      sessionStartTime,    
      sessionEndTime,   
      airports,
      (
        faultsInfoTotal+
        faultsAdvisoryTotal+
        faultsCautionTotal+
        faultsWarningTotal
      )
    )
    if (missingData === false) {
      if (rowType === "RECENT_SESSION_0") {
        if (handleRecentSessionSelection) {
          handleRecentSessionSelection(null, null, ifrSessionOrder);
        }
      } else if (rowType === "RECENT_SESSION") {
        if (handleRecentSessionSelection) {
          handleRecentSessionSelection(
            sessionStartTime,
            sessionEndTime,
            ifrSessionOrder
          );
        }
      } else {
        if (handleHistoricSessionSelection) {
          handleHistoricSessionSelection(
            sessionStartTime,
            sessionEndTime,
            sessionNumber,
            departureTime,
            arrivalTime,
            sessionType
          );
        }
      }
    }
  };

  return renderHistoricDataRow();
}

export default HistoricDataRow;
