import React, { Component, createRef } from "react";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import * as tools from "../utils/CommonTools";
import FeatherIcon from "feather-icons-react";
import ApexCharts from "apexcharts";
import { Redirect } from "react-router-dom";
import Spinner from "../Global Components/Spinner.js";
import Header from "../Global Components/Header";
import FaultMessageBox from "./FaultMessageBox";
import FlightInfoBox from "./FlightInfoBox";
import ChartBoxFde from "./ChartBoxFde";
import ChartBoxCms from "./ChartBoxCms";
import HistoricDataModal from "./HistoricDataModal";
import ParameterChartsRouting from "./ParameterChartsRouting.jsx";
import Card from "../Global Components/Card";
import AirframeUIConfigs from "../resources/AirframeUIConfigs.json";
import { getTailGeneralData } from "../clients/FleetAlertsClient";
import { getTakeOffReport } from "../clients/TakeOffReportClient";
import {
  getFaultsFDE,
  getFaultsOMS,
  getFaultsCMS,
} from "../clients/FaultsClient";
import { getEifrList } from "../clients/EIFRClient";
import { getCommonFDEList, postCommonFDEList } from "../clients/ConfigFaults";
import { updateAircraftMonitoring } from "../clients/AircraftMonitorClient";
import { getFlightCycles } from "../clients/AircraftFlightsClient";
import { getAircraftMetadata } from "../clients/AircraftMetadataClient";
import { getAircraftSettings } from "../clients/AircraftSettingsConfig";
import { getCmsSessionList, getCmsIfMessages } from "../clients/CmsSessionData";
import "../styles/Global Components/LoaderContainer.css";
import "./Aircraft.css";
import "./FlightInfoBox.css";
import NotificationPopup from "../Global Components/NotificationPopup.js";
import ConfirmationPopup from "../Global Components/ConfirmationPopup";
import { getRolesPermissions } from "../Auth Components/RbacValidation.js";
import { v4 as uuidv4 } from "uuid";
import Wizard from "../Application Onboarding Components/Wizard.js";
import EventCountModal from "../Event Count Components/EventCountModal";
import { getFleet } from "../clients/FleetAlertsClient";
import GlobalHeader from "../Global Components/GlobalHeader/GlobalHeader";
import { matchPath } from "react-router";
import GlobalDropdown from "../Global Components/Dropdown/GlobalDropdown";
import CustomTooltip from "../Global Components/CustomTooltip";
import { sendSmartFixClickEvent } from "../GA4/EventsCatalog.js";

// Create context object
export const AppContext = React.createContext();

export default class Aircrafts extends Component {
  constructor(props) {
    super(props);

    this.aircraftViewCrumb = {
      view: "/aircraftview",
      value: "Current Session",
    };

    tools.cleanAhmsLocalStorage();

    this.handleAircraftViewLoad = this.handleAircraftViewLoad.bind(this);
    this.loadAircraftPageInfo = this.loadAircraftPageInfo.bind(this);
    this.getInformation = this.getInformation.bind(this);
    this.loadUserInfo = this.loadUserInfo.bind(this);
    this.createFdeCasRow = this.createFdeCasRow.bind(this);
    this.createOmsRow = this.createOmsRow.bind(this);
    this.createCmsRow = this.createCmsRow.bind(this);
    this.processFaults = this.processFaults.bind(this);
    this.handleLollipopClick = this.handleLollipopClick.bind(this);
    this.handleFaultCorrelationClick =
      this.handleFaultCorrelationClick.bind(this);
    this.handleFlightChartBoxLogic = this.handleFlightChartBoxLogic.bind(this);
    this.handleOmsRowClick = this.handleOmsRowClick.bind(this);
    this.handleCmsRowClick = this.handleCmsRowClick.bind(this);
    this.handleFaultDeselect = this.handleFaultDeselect.bind(this);
    this.handleClearHistoricDataSelection =
      this.handleClearHistoricDataSelection.bind(this);
    // this.onboardingHistoricDataModalToggle = this.onboardingHistoricDataModalToggle.bind(
    //   this
    // );
    this.handleHistoricDataModalToggle =
      this.handleHistoricDataModalToggle.bind(this);
    this.handleHistoricDataSelection =
      this.handleHistoricDataSelection.bind(this);
    this.handleRecentSessionSelection =
      this.handleRecentSessionSelection.bind(this);
    this.BuildCardsHtml = this.BuildCardsHtml.bind(this);
    this.handleSeriesToggleEvent = this.handleSeriesToggleEvent.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleFdeFilterChange = this.handleFdeFilterChange.bind(this);
    this.handleOmsFilterChange = this.handleOmsFilterChange.bind(this);
    this.handleCmsFilterChange = this.handleCmsFilterChange.bind(this);
    this.handleFaultMessageTabChange =
      this.handleFaultMessageTabChange.bind(this);
    this.handleSFPLink = this.handleSFPLink.bind(this);
    this.hanldeReduceCommonFaultClick =
      this.hanldeReduceCommonFaultClick.bind(this);
    this.handleAircraftPageRefresh = this.handleAircraftPageRefresh.bind(this);
    this.openNotificationModal = this.openNotificationModal.bind(this);
    this.closeNotificationModal = this.closeNotificationModal.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.submitOKConfirmationModal = this.submitOKConfirmationModal.bind(this);
    this.warningNoSessionStart = this.warningNoSessionStart.bind(this);
    this.handleNoSessionStartProceed =
      this.handleNoSessionStartProceed.bind(this);
    this.changeFlightSelection = this.changeFlightSelection.bind(this);
    this.highlightChartSection = this.highlightChartSection.bind(this);
    this.renderSessionNumber = this.renderSessionNumber.bind(this);
    this.toggleEventCountModal = this.toggleEventCountModal.bind(this);
    this.processTailList = this.processTailList.bind(this);
    this.setMdcRowData = this.setMdcRowData.bind(this);
    this.promisedSetState = this.promisedSetState.bind(this);

    const userInfo = this.loadUserInfo();
    const rolesPermissions = getRolesPermissions("aircraftview");

    this.axiosCancelSource = undefined;
    this.axiosCancelSourceEventCount = undefined;

    this.faultMessageBoxRef = createRef(null);

    this.state = {
      rolesPermissions: rolesPermissions,
      noPageData: false,
      userName: userInfo ? userInfo.name : "",
      userOrganizationId: userInfo ? userInfo.orgId : "",
      userId: userInfo ? userInfo.userId : "",
      tailOrganizationId: "",
      loading: true,
      loadingFaultEventCount: true,
      fdecasList: [],
      omsList: [],
      cmsList: [],
      cmsListCopy: [],
      cmsListFilter: "all",
      eifrList: [],
      faultEventCountDataFDECAS: [],
      faultEventCountDataOMS: [],
      faultEventCountDataCMS: [],
      aircraftFamily: "",
      aircraftModel: "",
      aircraftUIConfig: null,
      tail: "",
      status: "",
      serial: "",
      monitorStatus: "",
      monitorStatusDisable: false,
      infoBoxType: "FLIGHT",
      latestFlight: undefined,
      infoFlight: undefined,
      fdeInfoFault: undefined,
      omsInfoFault: undefined,
      cmsInfoFault: undefined,
      viewType: "IFR",
      infoSeriesToggle: true,
      statusSeriesToggle: true,
      reduceCommonFaultsSeriesToggle: false,
      flightHistorySessionStartTime: "",
      flightHistorySessionEndTime: "",
      flightHistorySession: "",
      flightHistorySessionType: "",
      dateDataTransfer: null,
      recentSessionOrder: "",
      activeSessionNumber: null,
      takeOffReport: {
        serialNumber: "",
        engineStartTime: undefined,
        engineEndTime: undefined,
        approximateEngineStart: undefined,
        approximateEngineStop: undefined,
        flights: [],
      },
      flightCycles: {
        totalFlightTime: null,
        totalFlightCycles: null,
        engine1Serial: null,
        totalEngine1Time: null,
        totalEngine1Cycles: null,
        engine2Serial: null,
        totalEngine2Time: null,
        totalEngine2Cycles: null,
      },
      fdeFilterText: { faultCode: "", faultMessage: "" },
      omsFilterText: { faultCode: "", faultMessage: "" },
      cmsFilterText: { faultCode: "", faultMessage: "" },
      selectedFaultMessageTab: "FDECAS",
      confirmModalState: false,
      reduceCommonFaultEditData: null,
      notifModalState: false,
      alternativeExistLink: "",
      errorColorCode: "",
      errorDescription: "",
      errorCode: "ER-AC-00000",
      errorType: "",
      technical_detail: "",
      aircraftSettings: { externalRpdRequest: null },
      aircraftCmsSupport: false,
      rpdSatcomSupport: false,
      isOpenHistoricDataModal: false,
      cmsSessionsList: [],
      latestCmsIfMessageDate: null,
      dropDownFlights: null,
      selectedSessionFlight: null,
      aircraftEditCyclesPerm: false,
      customStyles: {
        control: (base, state) => ({
          ...base,
          backgroundColor: "#303030",
          border: 0,
          boxShadow: 0,
          "&:hover": {
            border: 0,
          },
        }),
        indicatorSeparator: (base, state) => ({
          ...base,
          marginBottom: "0px",
          marginTop: "0px",
          width: "1.5px",
        }),

        dropdownIndicator: (base, state) => ({
          ...base,
          color: "white",
          "&:hover": {
            color: "black",
          },
          "&:active": {
            color: "black",
          },
          width: "38px",
          height: "38px",
          padding: "12px",
        }),

        container: (base, state) => ({
          ...base,
          width: "200px !important",
          height: "40px",
          borderColor: "white",
          //borderRadius: "2px",
          borderStyle: "solid",
          borderWidth: "1px",
        }),
        menu: (base, state) => ({
          ...base,
          backgroundColor: "#303030",
          color: "white",
        }),
        placeholder: (base, state) => ({
          ...base,
          color: "white",
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: "#303030",
          borderBottom: "1px solid white",
          borderLeft: "1px solid white",
          borderRight: "1px solid white",
          color: state.isFocused ? "white" : "", //empty since it will depend on the theme (light: color will be white)
          "&:hover": {
            color: "black",
            backgroundColor: "white",
          },
          "&:active": {
            color: "black",
            backgroundColor: "white",
          },
        }),
        singleValue: (base, state) => ({
          ...base,
          color: "#FFFFFF",
        }),
      },
      eventCountModalIsOpen: false,
      tailList: [],
      familyModelList: [],
      allEventTypes: AirframeUIConfigs,
      familyModelListEventAvailableAircrafts: [],
      selectedMdcRowData: {},
      loadingInitialData: false,
      errorObject: null,
    };
  }

  /**
   * Treats the setState as a promise to be able to await for the change.
   * @param newState The new state.
   * @return {Promise<void>}
   */
  promisedSetState(newState) {
    return new Promise((resolve) => this.setState(newState, resolve));
  }

  // Verify if the component is loading directly or is from a back action(browser action) and load initial data.
  async getInformation() {
    this.setState({
      loadingInitialData: true,
      loading: true,
    });
    let tmpAircraftFamily = localStorage.getItem("tmpAircraftFamily");
    let tmpAircraftModel = localStorage.getItem("tmpAircraftModel");
    let tmpTail = localStorage.getItem("tmpTail");
    let tmpSerial = localStorage.getItem("tmpSerial");
    let tmpMonitorStatus = localStorage.getItem("tmpMonitorStatus");
    let tmpACSession = localStorage.getItem("tmpACSession");

    let isFromUrlNavigation = false;
    let willRedirect = false;
    // This match const contains the params from the URL
    const match = matchPath(this.props.location.pathname, {
      path: "/aircraftview/:serialNumber/:registrationNumber/:sessionNumber",
      exact: true,
      strict: false,
    });

    if (
      tmpTail === null ||
      tmpSerial === null ||
      tmpMonitorStatus === null ||
      tmpAircraftFamily === null ||
      tmpAircraftModel === null
    ) {
      if (this.props.location.state) {
        tmpAircraftFamily = this.props.location.state.aircraftFamily;
        tmpAircraftModel = this.props.location.state.aircraftModel;
        tmpTail = this.props.location.state.tail;
        tmpSerial = this.props.location.state.serial;
        tmpMonitorStatus = this.props.location.state.monitorStatus;
        sessionStorage.removeItem("isFromUrlNavigation");
      } else if (match) {
        tmpTail = match.params.registrationNumber;
        tmpSerial = match.params.serialNumber;
        tmpACSession = match.params.sessionNumber;
        isFromUrlNavigation = true;
      } else {
        tmpAircraftFamily = null;
        tmpAircraftModel = null;
        tmpTail = null;
        tmpSerial = null;
        tmpMonitorStatus = null;
        willRedirect = true;
      }
    } else if (
      // If an URL session navigation is already active, and the user searches for a new one:
      tmpTail &&
      tmpSerial &&
      tmpMonitorStatus &&
      tmpAircraftFamily &&
      tmpAircraftModel &&
      match
    ) {
      tmpTail = match.params.registrationNumber;
      tmpSerial = match.params.serialNumber;
      tmpACSession = match.params.sessionNumber;
      isFromUrlNavigation = true;
    } else {
      localStorage.removeItem("tmpTail");
      localStorage.removeItem("tmpSerial");
      localStorage.removeItem("tmpMonitorStatus");
      tmpMonitorStatus = tmpMonitorStatus === "true" ? true : false;
    }

    let tmpACSessionStartDate = localStorage.getItem("tmpACSessionStartDate");
    let tmpACSessionEndDate = localStorage.getItem("tmpACSessionEndDate");
    let tmpACHistorySessionType = localStorage.getItem(
      "tmpACHistorySessionType"
    );
    let tmpACSitaSessionOrder = localStorage.getItem("tmpACSitaSessionOrder");

    if (tmpTail !== null) {
      if (tmpACSessionStartDate === null || tmpACSessionEndDate === null) {
        tmpACSessionStartDate = null;
        tmpACSessionEndDate = null;
        localStorage.removeItem("tmpACSessionStartDate");
        localStorage.removeItem("tmpACSessionEndDate");
        // localStorage.removeItem("tmpACSession");
        localStorage.removeItem("tmpACHistorySessionType");
        localStorage.removeItem("tmpACSitaSessionOrder");
      }
    }

    if (willRedirect) {
      this.setState({ noPageData: true });
      return;
    }

    // Condition to load initial data using URL navigation values.
    if (isFromUrlNavigation) {
      // Get the general data for the tail that will load the page.
      const aircraftGeneralData = await this.processAircraftGenerlData(
        tmpTail,
        tmpSerial
      );

      localStorage.setItem(
        "tmpAircraftFamily",
        aircraftGeneralData.aircraftFamily
      );
      localStorage.setItem(
        "tmpAircraftModel",
        aircraftGeneralData.aircraftModel
      );
      localStorage.setItem("tmpTail", tmpTail);
      localStorage.setItem("tmpSerial", aircraftGeneralData.serialNumber);
      localStorage.setItem(
        "tmpMonitorStatus",
        aircraftGeneralData.monitorStatus
      );
      localStorage.setItem("tmpACSession", tmpACSession);
      sessionStorage.setItem("isFromUrlNavigation", true);

      await this.loadAircraftPageInfo(
        tmpACSession, // This param should be required to keep the same arguments in other invocations.
        tmpACSessionStartDate, // This param should be required to keep the same arguments in other invocations.
        tmpACSessionEndDate, // This param should be required to keep the same arguments in other invocations.
        tmpACHistorySessionType ? tmpACHistorySessionType : "FLIGHT", // This param should be required to keep the same arguments in other invocations.
        tmpTail,
        aircraftGeneralData.serialNumber,
        aircraftGeneralData.aircraftFamily,
        aircraftGeneralData.aircraftModel,
        tmpACSitaSessionOrder ? tmpACSitaSessionOrder : 0,
        aircraftGeneralData.monitorStatus
      );
    } else {
      await this.loadAircraftPageInfo(
        tmpACSession, // This param should be required to keep the same arguments in other invocations.
        tmpACSessionStartDate, // This param should be required to keep the same arguments in other invocations.
        tmpACSessionEndDate, // This param should be required to keep the same arguments in other invocations.
        tmpACHistorySessionType ? tmpACHistorySessionType : "FLIGHT", // This param should be required to keep the same arguments in other invocations.
        tmpTail,
        tmpSerial,
        tmpAircraftFamily,
        tmpAircraftModel,
        tmpACSitaSessionOrder ? tmpACSitaSessionOrder : 0,
        tmpMonitorStatus
      );
    }

    this.setState({
      loadingInitialData: false,
    });
  }

  highlightChartSection(clabel) {
    for (let z = 0; z < this.state.dropDownFlights.length; z++) {
      ApexCharts.exec(
        "flight_lollipop",
        "removeAnnotation",
        this.state.dropDownFlights[z].label.replace(/\s/g, "")
      );
    }

    for (let j = 0; j < this.state.dropDownFlights.length; j++) {
      let label =
        this.state.dropDownFlights[j].value.departureAirportIATA +
        "-" +
        this.state.dropDownFlights[j].value.arrivalAirportIATA;

      if (
        !this.state.dropDownFlights[j].value.departureAirportIATA &&
        !this.state.dropDownFlights[j].value.arrivalAirportIATA
      ) {
        label = "FLIGHT";
      } else if (
        this.state.dropDownFlights[j].value.departureAirportIATA &&
        !this.state.dropDownFlights[j].value.arrivalAirportIATA
      ) {
        label =
          this.state.dropDownFlights[j].value.departureAirportIATA + "- N/A";
      } else if (
        !this.state.dropDownFlights[j].value.departureAirportIATA &&
        this.state.dropDownFlights[j].value.arrivalAirportIATA
      ) {
        label =
          "N/A - " + this.state.dropDownFlights[j].value.arrivalAirportIATA;
      }

      const backgroundColor =
        clabel.toLowerCase() ===
          this.state.dropDownFlights[j].label.toLowerCase()
          ? "#808080"
          : "#303030";

      const annotation = {
        id: this.state.dropDownFlights[j].label.replace(/\s/g, ""),
        x: moment
          .utc(this.state.dropDownFlights[j].value.departureTime)
          .valueOf(),
        x2: moment
          .utc(this.state.dropDownFlights[j].value.arrivalTime)
          .valueOf(),
        opacity: 0.3,
        offsetY: 275,
        fillColor: backgroundColor,
        label: {
          borderColor: "#ffffff",
          borderWidth: 1,
          position: "bottom",
          text: label,
          offsetY: 0,
          style: {
            background: backgroundColor,
            color: "#FFFFFF",
            height: "200px !important",
            fontSize: "13px",
            fontWeight: "500",
          },
        },
      };

      ApexCharts.exec("flight_lollipop", "addXaxisAnnotation", annotation);
    }
  }

  async changeFlightSelection(value) {
    this.highlightChartSection(value.label);
    await this.promisedSetState({
      selectedSessionFlight: value,
    });

    let i = null;
    if (!value.value) {
      i = _.findIndex(this.state.dropDownFlights, function (f) {
        return value.label.toLowerCase() === f.label.toLowerCase();
      });

      if (i !== -1) {
        value.value = this.state.dropDownFlights[i].value;
      }
    }

    await this.setLatestFlight(value.value);
    await this.handleLollipopClick(
      "FLIGHT",
      value.value.departureTime,
      value.label
    );

    const faultList =
      this.state.selectedFaultMessageTab === "CMS"
        ? this.state.cmsList
        : this.state.fdecasList;

    const selectedFault = _.find(faultList, (l) => {
      return (
        l.faultCode.replace("-", " ").toLowerCase() ===
        value.label.toLowerCase()
      );
    });

    const element = document.getElementById(selectedFault.id);

    element.scrollIntoView({ behavior: "smooth" });
    ApexCharts.exec("flight_lollipop", "zoomX");
  }

  loadUserInfo() {
    if (localStorage.getItem("userInfo")) {
      return JSON.parse(localStorage.getItem("userInfo"));
    } else {
      return null;
    }
  }

  toggleEventCountModal() {
    this.setState(({ eventCountModalIsOpen }) => ({
      eventCountModalIsOpen: !eventCountModalIsOpen,
    }));
  }

  // Updates the mdc row selection value and toggles the MDC smartfix url.
  setMdcRowData(rowData) {
    if (_.isEmpty(rowData)) {
      this.setState({ selectedMdcRowData: {} });
    } else {
      let finalSmartFixURL;
      if (localStorage.getItem("userType") === "customer") {
        finalSmartFixURL = rowData.sfpExternalUrl
          ? rowData.sfpExternalUrl
          : "/";
      } else {
        finalSmartFixURL = rowData.smartFixPlus ? rowData.smartFixPlus : "/";
      }
      this.setState({
        selectedMdcRowData: {
          ...rowData,
          cards: [
            {
              id: "card__smartfix",
              textTitle: "",
              text:
                this.state.aircraftUIConfig?.aircraftViewPage?.mdcTabLabel.toString() +
                " Smart Fix",
              featherIconName: "external-link",
              sfpLink: finalSmartFixURL,
              disabled: !this.getSmartFixLinkEnabledStatus(
                this.state.aircraftFamily,
                this.state.aircraftModel
              )
                ? true
                : finalSmartFixURL === "/",
              clickEvent: this.handleSFPLink,
            },
          ],
        },
      });
    }
  }

  // Renders the components
  render() {
    if (this.state.noPageData) {
      return <Redirect to={{ pathname: "/fleetview" }} />;
    } else {
      return (
        <div>
          <ConfirmationPopup
            active={this.state.confirmModalState}
            submitOK={this.submitOKConfirmationModal}
            submitCANCEL={this.closeConfirmationModal}
            descriptionText={
              "Would you like to hide/unhide similar messages going forward? You can configure more messages in the configuration page."
            }
            cancelText={"CANCEL"}
            acceptText={"PROCEED"}
          />
          {this.state.loadingInitialData ? (
            <div>
              <div
                style={{
                  position: "fixed",
                  width: "100%",
                  marginLeft: "calc(-7% + 60px)",
                  marginRight: "-7%",
                  marginTop: "-50px",
                  height: "100%",
                  backgroundColor: "rgb(0,0,0,.5)",
                  zIndex: "2000",
                }}
              >
                <div
                  style={{ width: "100px", paddingTop: "20%", margin: "auto" }}
                >
                  <Spinner />
                </div>
              </div>
            </div>
          ) : (
            this.handleAircraftViewLoad(this.state.loading)
          )}
        </div>
      );
    }
  }

  handleAircraftViewLoad(loading) {
    const loader = (
      <div>
        <div
          style={{
            position: "fixed",
            width: "100%",
            marginLeft: "calc(-7% + 60px)",
            marginRight: "-7%",
            marginTop: "-50px",
            height: "100%",
            backgroundColor: "rgb(0,0,0,.5)",
            zIndex: "2000",
          }}
        >
          <div style={{ width: "100px", paddingTop: "20%", margin: "auto" }}>
            <Spinner />
          </div>
        </div>
      </div>
    );

    const errorNotification = (
      <NotificationPopup
        userName={this.state.userName}
        open={this.state.notifModalState}
        closeModal={this.closeNotificationModal}
        description={this.state.errorDescription}
        alternativeExistLink={this.state.alternativeExistLink}
        color={this.state.errorColorCode}
        errorCode={this.state.errorCode}
        type={this.state.errorType}
        technical_detail={this.state.technical_detail}
        noSessionStartAlternativeProceed={this.handleNoSessionStartProceed}
        errorObject={this.state.errorObject}
      />
    );

    const aircraftViewContent = (
      <div className="aircraftView">
        <Wizard
          iofgKey="NEW_USER_ONBOARDING"
          pageKey="AIRCRAFT_V_PG"
        // specialAction={this.onboardingHistoricDataModalToggle}
        />
        <div className="aircraftView__container">
          <div className="aircraftView__headerContainer">
            <div className="aircraftView__aircraftInfoHeaderContainer">
              <GlobalHeader
                serialNumber={this.state.serial}
                registration={this.state.tail}
                aircraftFamily={this.state.aircraftFamily}
                aircraftModel={this.state.aircraftModel}
                timeInFlight={
                  this.state.latestFlight
                    ? tools.getFormattedTimeDiff(
                      this.state.latestFlight?.departureTime,
                      this.state.latestFlight?.arrivalTime
                    )
                    : "-"
                }
                latestDataTransferDate={this.state.dateDataTransfer}
                crumb={this.aircraftViewCrumb}
                hasLatestHmuTransferDate={
                  this.state.aircraftUIConfig?.aircraftViewPage
                    ?.hasLatestHmuTransferDate
                }
                airframeTime={this.state.flightCycles?.totalFlightTime}
                airframeCycles={this.state.flightCycles?.totalFlightCycles}
                engine1Serial={
                  this.state.flightCycles?.engine1Serial
                    ? this.state.flightCycles?.engine1Serial
                    : "-"
                }
                engine1Time={this.state.flightCycles?.totalEngine1Time}
                engine1Cycles={this.state.flightCycles?.totalEngine1Cycles}
                engine2Serial={
                  this.state.flightCycles?.engine2Serial
                    ? this.state.flightCycles?.engine2Serial
                    : "-"
                }
                engine2Time={this.state.flightCycles?.totalEngine2Time}
                engine2Cycles={this.state.flightCycles?.totalEngine2Cycles}
                enableCycleEdit={
                  this.state.rolesPermissions?.editFlightCycles &&
                  this.state.aircraftEditCyclesPerm
                }
                currentAircraftStatus={this.state.status}
                monitorStatus={this.state.monitorStatus}
                dataFocus={"aircraft"}
                sessionNumber={this.state.activeSessionNumber}
              />
            </div>
            <div className="aircraftView__globalHeaderContainer__logo">
              <Header logoText="BOMBARDIER" />
            </div>
            <div className="aircraftView__headerContainer-separator"></div>
          </div>
          <div className="aircraftView__bodyContainer">
            {this.state.eventCountModalIsOpen && (
              <EventCountModal
                toggleModal={this.toggleEventCountModal}
                modalId="EventCountModal"
                tailList={this.state.tailList}
                familyModelList={
                  this.state.familyModelListEventAvailableAircrafts
                }
                aircraftUIConfig={this.state.allEventTypes}
                preselectedSerialNumber={this.state.serial}
                preselectedRegistrationNumber={this.state.tail}
                preselectedAircraftFamily={this.state.aircraftFamily}
                preselectedAircraftModel={this.state.aircraftModel}
                fdeInfoFault={this.state.fdeInfoFault}
                omsInfoFault={this.state.omsInfoFault}
                cmsInfoFault={this.state.cmsInfoFault}
              />
            )}
            <div className="aircraftView__bodyContainer__section-one">
              <div className="aircraftView__aircraftSessionContainer">
                <div
                  className="aircraftView__session__wrapper"
                  id="aircraftView__session__wrapper"
                >
                  <div className="aircraftView__sessionData__wrapper">
                    <div className="aircraftView__sessionData__header">
                      <div
                        style={{
                          width: "65%",
                          display: "flex",
                          marginLeft: "15px",
                        }}
                      >
                        <span
                          className="aircraftView__data_size-medium"
                          style={{ marginRight: "10px" }}
                        >
                          {this.state.flightHistorySessionType === "FLIGHT"
                            ? "Flight Session"
                            : this.state.flightHistorySessionType === "GROUND"
                              ? "Ground Session"
                              : ""}
                        </span>
                        {this.renderSessionNumber()}
                      </div>
                      <div
                        style={{
                          width: "35%",
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <HistoricDataModal
                          aircraftUIConfig={this.state.aircraftUIConfig}
                          aircraftViewType={this.state.viewType}
                          tailNumber={this.state.tail}
                          aircraftFamily={this.state.aircraftFamily}
                          aircraftModel={this.state.aircraftModel}
                          hmuLatestTransfer={this.state.dateDataTransfer}
                          handleHistoricDataSelection={
                            this.handleHistoricDataSelection
                          }
                          handleRecentSessionSelection={
                            this.handleRecentSessionSelection
                          }
                          handleHistoricDataModalToggle={
                            this.handleHistoricDataModalToggle
                          }
                          isOpen={this.state.isOpenHistoricDataModal}
                          selectedSessionStartTime={
                            this.state.takeOffReport?.engineStartTime
                              ? moment
                                .utc(this.state.takeOffReport.engineStartTime)
                                .format("YYYY-MM-DD HH:mm:ss")
                              : null
                          }
                          selectedSessionEndTime={
                            this.state.takeOffReport?.engineEndTime
                              ? moment
                                .utc(this.state.takeOffReport.engineEndTime)
                                .format("YYYY-MM-DD HH:mm:ss")
                              : null
                          }
                          selectedSessionNumber={this.state.activeSessionNumber}
                          serialNumber={this.state.serial}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ width: "300px", marginLeft: "15px" }}>
                        <GlobalDropdown
                          options={this.state.dropDownFlights}
                          defaultValue={this.state.selectedSessionFlight}
                          onChange={(value) => {
                            this.changeFlightSelection(value);
                          }}
                          optionsAreObjects={true}
                        />
                      </div>
                      <div
                        style={{
                          width: "calc(100% - 350px)",
                          marginLeft: "50px",
                        }}
                      >
                        <p className="time__in__flight__label">
                          Time in flight:
                        </p>
                        <p className="time__in__flight__value">
                          {this.state.latestFlight
                            ? tools.getFormattedTimeDiff(
                              this.state.latestFlight.departureTime,
                              this.state.latestFlight.arrivalTime
                            )
                            : "-"}
                        </p>
                      </div>
                    </div>

                    <div className="aircraftView__sessionData__flightInfo">
                      <div
                        style={{
                          width: "50%",
                          height: "100px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ fontWeight: "700", paddingLeft: "15px" }}>
                          {"Departure:"}
                        </div>
                        <div style={{ paddingLeft: "15px" }}>
                          {this.state.latestFlight
                            ? this.state.latestFlight.departureTime
                              ? moment
                                .utc(this.state.latestFlight.departureTime)
                                .format("MMM DD, YYYY - HH:mm:ss")
                              : "-"
                            : "-"}
                        </div>
                        <div style={{ paddingLeft: "15px" }}>
                          {this.state.latestFlight
                            ? this.state.latestFlight.departureAirportName
                              ? this.state.latestFlight.departureAirportName +
                              " (" +
                              this.state.latestFlight.departureAirportIATA +
                              ")"
                              : "-"
                            : "-"}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          height: "100px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ fontWeight: "700", paddingLeft: "15px" }}>
                          {"Arrival:"}
                        </div>
                        <div style={{ paddingLeft: "15px" }}>
                          {this.state.latestFlight
                            ? this.state.latestFlight.arrivalTime
                              ? moment
                                .utc(this.state.latestFlight.arrivalTime)
                                .format("MMM DD, YYYY - HH:mm:ss")
                              : "-"
                            : "-"}
                        </div>
                        <div style={{ paddingLeft: "15px" }}>
                          {this.state.latestFlight
                            ? this.state.latestFlight.arrivalAirportName
                              ? this.state.latestFlight.arrivalAirportName +
                              " (" +
                              this.state.latestFlight.arrivalAirportIATA +
                              ")"
                              : "-"
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="aircraftView__sessionChart__wrapper">
                    {this.handleFlightChartBox()}
                  </div>
                </div>
              </div>
            </div>
            <div className="aircraftView__bodyContainer__section-separator"></div>
            <div className="aircraftView__bodyContainer__section-two">
              <div
                className="aircraftView__faultMessageContainer"
                id="aircraftView__faultMessageContainer"
              >
                <div
                  className={
                    this.state.selectedFaultMessageTab === "EIFR"
                      ? "aircraftView__faultMessage__expandedWrapper"
                      : "aircraftView__faultMessage__wrapper"
                  }
                >
                  {this.handleFaultMessageBox()}
                  {this.BuildCardsHtml()}
                </div>
              </div>
              <div
                className="aircraftView__flightInfoContainer"
                id="aircraftView__flightInfoContainer"
              >
                {this.state.selectedFaultMessageTab !== "EIFR" &&
                  this.handleFlightInfoBox()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return loading
      ? loader
      : this.state.notifModalState
        ? errorNotification
        : aircraftViewContent;
  }

  // Handles the fdecas/oms/pareto table component rendering
  handleFaultMessageBox() {
    const faultMessageBox = (
      <FaultMessageBox
        changeFlightSelection={this.changeFlightSelection}
        viewType={this.state.viewType}
        eifrList={this.state.eifrList}
        flightList={this.state.takeOffReport.flights}
        selectedTab={this.state.selectedFaultMessageTab}
        handleFaultMessageTabChange={this.handleFaultMessageTabChange}
        fdecasList={this.state.fdecasList}
        selectedFdeFault={this.state.fdeInfoFault}
        infoSeriesToggle={this.state.infoSeriesToggle}
        statusSeriesToggle={this.state.statusSeriesToggle}
        reduceCommonFaultsSeriesToggle={
          this.state.reduceCommonFaultsSeriesToggle
        }
        aircraftUIConfig={this.state.aircraftUIConfig}
        faultCorrelationClickEvent={this.handleFaultCorrelationClick}
        fdeFilterText={this.state.fdeFilterText}
        handleFdeFilterChange={this.handleFdeFilterChange}
        omsList={this.state.omsList}
        cmsList={this.state.cmsList}
        cmsFilterText={this.state.cmsFilterText}
        handleCmsFilterChange={this.handleCmsFilterChange}
        cmsRowClickEvent={this.handleCmsRowClick}
        selectedOmsFault={this.state.omsInfoFault}
        selectedCmsFault={this.state.cmsInfoFault}
        omsFilterText={this.state.omsFilterText}
        omsRowClickEvent={this.handleOmsRowClick}
        handleOmsFilterChange={this.handleOmsFilterChange}
        aircraftCmsSupport={this.state.aircraftCmsSupport}
        latestCmsIfMessageDate={this.state.latestCmsIfMessageDate}
        aircraftFamily={this.state.aircraftFamily}
        aircraftModel={this.state.aircraftModel}
        tailNumber={this.state.tail}
        setMdcRowData={this.setMdcRowData}
        fdeInfoFault={this.state.fdeInfoFault}
        sessionNumber={this.state.activeSessionNumber}
        faultMessageBoxRef={this.faultMessageBoxRef}
      />
    );
    return faultMessageBox;
  }

  handleFlightChartBox() {
    const flightChartBox = this.handleFlightChartBoxLogic();
    return flightChartBox;
  }

  handleFlightChartBoxLogic() {
    let flightChartBox;
    if (this.state.selectedFaultMessageTab === "CMS") {
      flightChartBox = (
        <ChartBoxCms
          selectedSessionFlight={this.state.selectedSessionFlight}
          aircraftUIConfig={this.state.aircraftUIConfig}
          engineStartTime={this.state.takeOffReport.engineStartTime}
          engineEndTime={this.state.takeOffReport.engineEndTime}
          approximateEngineStart={
            this.state.takeOffReport.approximateEngineStart
          }
          approximateEngineStop={this.state.takeOffReport.approximateEngineStop}
          flights={this.state.takeOffReport.flights}
          fdeFaults={[]}
          cmsFaults={this.state.cmsList}
          lollipopClickEvent={this.handleLollipopClick}
          seriesToggleEvent={this.handleSeriesToggleEvent}
          cmsSessionsList={this.state.cmsSessionsList}
        />
      );
    } else {
      flightChartBox = (
        <ChartBoxFde
          selectedSessionFlight={this.state.selectedSessionFlight}
          aircraftUIConfig={this.state.aircraftUIConfig}
          engineStartTime={this.state.takeOffReport.engineStartTime}
          engineEndTime={this.state.takeOffReport.engineEndTime}
          approximateEngineStart={
            this.state.takeOffReport.approximateEngineStart
          }
          approximateEngineStop={this.state.takeOffReport.approximateEngineStop}
          flights={this.state.takeOffReport.flights}
          fdeFaults={
            this.state.aircraftUIConfig?.aircraftViewPage?.fdeCasAvailable
              ? this.state.fdecasList
              : []
          }
          cmsFaults={[]}
          lollipopClickEvent={this.handleLollipopClick}
          seriesToggleEvent={this.handleSeriesToggleEvent}
        />
      );
    }
    return flightChartBox;
  }

  handleFlightInfoBox() {
    let flightInfoBox = (
      <FlightInfoBox
        aircraftUIConfig={this.state.aircraftUIConfig}
        aircraftFamily={this.state.aircraftFamily}
        aircraftModel={this.state.aircraftModel}
        viewType={this.state.viewType}
        infoBoxType={this.state.infoBoxType}
        serial={this.state.serial}
        serialNumber={this.state.takeOffReport.serialNumber}
        flight={this.state.infoFlight}
        fdeFault={this.state.fdeInfoFault}
        omsFault={this.state.omsInfoFault}
        cmsFault={this.state.cmsInfoFault}
        faultEventCountData={
          this.state.cmsInfoFault
            ? this.state.faultEventCountDataCMS
            : this.state.omsInfoFault
              ? this.state.faultEventCountDataOMS
              : this.state.faultEventCountDataFDECAS
        }
        fault={
          this.state.cmsInfoFault
            ? this.state.cmsInfoFault
            : this.state.omsInfoFault
              ? this.state.omsInfoFault
              : this.state.fdeInfoFault
        }
        hanldeReduceCommonFaultClick={this.hanldeReduceCommonFaultClick}
        loadingFaultEventCount={this.state.loadingFaultEventCount}
        activeTab={this.state.selectedFaultMessageTab}
        selectedMdcRowData={this.state.selectedMdcRowData}
      />
    );

    return flightInfoBox;
  }

  renderSessionNumber() {
    let displaySessionNumber = "";
    let helpIcon = false;
    let sessionType = "";

    if (this.state.flightHistorySessionType === "FLIGHT") {
      sessionType = "Flight ";
    } else if (this.state.flightHistorySessionType === "GROUND") {
      sessionType = "Ground ";
    }

    if (this.state.viewType === "IFR") {
      if (this.state.recentSessionOrder > 0) {
        displaySessionNumber =
          "In-Flight Report -" + this.state.recentSessionOrder.toString();
      } else {
        displaySessionNumber = this.state.activeSessionNumber
          ? this.state.activeSessionNumber + " - IFR"
          : "In-Flight Report";
        helpIcon = this.state.activeSessionNumber ? true : false;
      }
    } else {
      displaySessionNumber = this.state.activeSessionNumber
        ? this.state.activeSessionNumber
        : "";
    }

    this.aircraftViewCrumb.value = `${sessionType}Session ${displaySessionNumber}`;
    return (
      <span className="aircraftView__data_size-medium-bold">
        {displaySessionNumber}
        {helpIcon && <FeatherIcon icon={"help-circle"} height={14} />}
      </span>
    );
  }

  async getTailList() {
    try {
      const tailList = [];
      const completeTailList = [];
      let tailObject = null;

      await getFleet().then((res) => {
        res.data.forEach((tail) => {
          tailObject = {
            serialNumber: tail.tails.serialNumber,
            registrationNumber: tail.tails.tailNumber,
            aircraftType: tail.tails.aircraftType,
            organizationID: tail.tails.organizationID,
            organization: tail.tails.organizationID,
            aircraftFamily: tail.tails.aircraftFamily,
            aircraftModel: tail.tails.aircraftModel,
            editCyclesPermission: tail.tails.editCyclesPermission,
          };

          if (
            _.find(tools.allowedAircraftFamilyModels(), {
              aircraftFamily: tailObject.aircraftFamily,
              aircraftModel: tailObject.aircraftModel,
            })
          ) {
            completeTailList.push(tailObject);
          }

          tailList.push(tailObject);
        });
      });
      return [tailList, completeTailList];
    } catch (error) {
      console.error("ERROR: ", error);
      return [];
    }
  }

  getFaultFamilyModelRules(aircraftFamily, aircraftModel) {
    return {
      faultCode:
        AirframeUIConfigs[aircraftFamily][aircraftModel].configVIewPage
          .showFaultCode,
    };
  }

  getSmartFixLinkEnabledStatus(aircraftFamily, aircraftModel) {
    return AirframeUIConfigs[aircraftFamily][aircraftModel].aircraftViewPage
      .smartFixLinkEnabled;
  }

  async processTailList() {
    try {
      const tailList = this.getTailList();
      const promiseArray = [tailList];
      const aircraftPageData = await Promise.all(
        promiseArray.map((req) => req)
      );

      const tempTailList = _.orderBy(
        aircraftPageData[0][0],
        ["aircraftFamily", "aircraftModel", "serialNumber"],
        ["desc", "asc", "asc"]
      );

      const tempCompleteTailList = _.orderBy(
        aircraftPageData[0][1],
        ["aircraftFamily", "aircraftModel", "serialNumber"],
        ["desc", "asc", "asc"]
      );

      // Procesing the family model list based on the aircrafts that the user has acces to.
      const familyTailList = [];
      _.uniqBy(tempCompleteTailList, function (uTail) {
        return uTail.aircraftFamily + uTail.aircraftModel;
      }).forEach((oTail) => {
        familyTailList.push({
          aircraftFamily: oTail.aircraftFamily,
          aircraftModel: oTail.aircraftModel,
          faultRules: this.getFaultFamilyModelRules(
            oTail.aircraftFamily,
            oTail.aircraftModel
          ),
        });
      });

      const tempFamilyTailList = _.orderBy(
        familyTailList,
        ["aircraftFamily", "aircraftModel"],
        ["desc", "asc"]
      );

      const tempFamilyTailListEventsFiltered = [];
      tempFamilyTailList.forEach((aircraft) => {
        if (
          _.find(tools.allowedAircraftFamilyModelsEventHistory(), {
            aircraftFamily: aircraft.aircraftFamily,
            aircraftModel: aircraft.aircraftModel,
          })
        ) {
          tempFamilyTailListEventsFiltered.push(aircraft);
        }
      });

      // const tempFamilyTailListEventsFiltered = tempFamilyTailList.filter(
      //   (aircraft) =>
      //     aircraft.aircraftModel !== "650" &&
      //     aircraft.aircraftModel !== "605" &&
      //     aircraft.aircraftModel !== "5000"
      // );

      this.setState({
        tailList: tempTailList,
        familyModelList: tempFamilyTailList,
        familyModelListEventAvailableAircrafts:
          tempFamilyTailListEventsFiltered,
      });
    } catch (error) {
      console.error("ERROR:", error);
      this.setState({
        tailList: [],
        familyModelList: [],
      });
    }
  }

  async componentDidMount() {
    await this.getInformation();
    this.processTailList();
  }

  componentWillUnmount() {
    if (this.axiosCancelSource) {
      this.axiosCancelSource.cancel("Cancelling pending requests");
    }
    if (this.axiosCancelSourceEventCount) {
      this.axiosCancelSourceEventCount.cancel("Cancelling pending requests");
    }
  }

  updateFaultListSelection(value, fdecasList, cmsList) {
    if (value) {
      this.highlightChartSection(value.label);

      let i = null;
      if (!value.value) {
        i = _.findIndex(this.state.dropDownFlights, function (f) {
          return value.label.toLowerCase() === f.label.toLowerCase();
        });

        if (i !== -1) {
          value.value = this.state.dropDownFlights[i].value;
        }
      }

      this.handleLollipopClick(
        "FLIGHT",
        value.value.departureTime,
        value.label
      );

      let fdecasListSorted = _.orderBy(fdecasList, ["faultTimestamp"], ["asc"]);
      let cmsListSorted = _.orderBy(cmsList, ["faultTimestamp"], ["asc"]);

      const faultList =
        this.state.selectedFaultMessageTab === "CMS"
          ? cmsListSorted
          : fdecasListSorted;

      const selectedFault = _.find(faultList, (l) => {
        return (
          l.faultCode.replace("-", " ").toLowerCase() ===
          value.label.toLowerCase()
        );
      });

      if (selectedFault) {
        setTimeout(() => {
          document
            .getElementById(selectedFault.id)
            .scrollIntoView({ behavior: "smooth" });
        }, 50);
      }

      ApexCharts.exec("flight_lollipop", "zoomX");
    }
  }

  async loadAircraftPageInfo(
    sessionNumber,
    startTime,
    endTime,
    sessionType,
    tail = "",
    serial = "",
    aircraftFamily = "",
    aircraftModel = "",
    recentSessionOrder = "",
    monitorStatus = ""
  ) {
    this.setLatestFlight(null);
    this.axiosCancelSource = axios.CancelToken.source();

    await this.promisedSetState({
      loading: true,
      loadingFaultEventCount: true,
      aircraftFamily,
      aircraftModel,
      tail,
      serial,
      monitorStatus,
      flightHistorySessionStartTime: startTime,
      flightHistorySessionEndTime: endTime,
      flightHistorySession: sessionNumber,
      flightHistorySessionType: sessionType ? sessionType : "FLIGHT",
      recentSessionOrder,
      aircraftUIConfig: AirframeUIConfigs[aircraftFamily][aircraftModel],
    });

    try {
      if (this.props.location.state || (tail && serial)) {
        const tmpTail = tail ? tail : this.props.location.state.tail;

        const tmpTailSerial = serial
          ? serial
          : this.props.location.state.serial;

        // Start setting up the first part of the aircraft view information to load
        const hmuLatestTransfer = this.processLatestAHMSDownload(
          this.axiosCancelSource,
          tmpTail,
          aircraftFamily,
          aircraftModel
        );

        // Get takeoff report data for the aircraft, this returns engine dates and flights
        const takeOffReport = await this.processTakeOffReport(
          this.axiosCancelSource,
          tmpTail,
          aircraftFamily,
          aircraftModel,
          startTime,
          endTime,
          recentSessionOrder > 0 ? true : false,
          sessionNumber,
          sessionType
        );

        // Get Aircraft Permission Settings
        const aircraftSettings = this.processAircraftSettings(
          tmpTail,
          aircraftFamily,
          aircraftModel
        );
        // Get the general data for the tail that will load the page
        const aircraftGeneralData = this.processAircraftGenerlData(
          tmpTail,
          tmpTailSerial
        );

        const aircraftPagePromiseArrayPartOne = [
          hmuLatestTransfer,
          takeOffReport,
          aircraftSettings,
          aircraftGeneralData,
        ];

        const aircraftPageDataPartOne = await Promise.all(
          aircraftPagePromiseArrayPartOne.map((req) => req)
        );

        // Catching any errors that might surge from the main "header" API
        if (
          !aircraftPageDataPartOne[1] ||
          !aircraftPageDataPartOne[2] ||
          !aircraftPageDataPartOne[3]
        ) {
          this.setState({
            loading: false,
          });

          this.openNotificationModal({ errorCode: "ER-AC-00008" });
          return;
        }

        const tmpACViewType = aircraftPageDataPartOne[1].viewType;
        sessionNumber = aircraftPageDataPartOne[1].sessionNumber;

        // Creating temporary storage for the flight array
        // Using temporary storage because I need to modify the array
        let sessionFlights = aircraftPageDataPartOne[1].flights;

        sessionFlights.sort((a, b) => {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.departureTime) - new Date(b.departureTime);
        });

        const addDropDownFlights = [];

        for (let f = 0; f < sessionFlights.length; f++) {
          const flightIndex = f + 1;
          addDropDownFlights.push({
            value: sessionFlights[f],
            label: "Flight " + flightIndex,
          });
        }

        // Sets a default value with the latest flight in a session including multiple flights.
        // if (!this.state.latestFlight) {
        await this.setLatestFlight(sessionFlights[sessionFlights.length - 1]);
        // }

        let intialDdSelection = _.findIndex(addDropDownFlights, (f) => {
          return this.state.latestFlight?.arrivalTime === f.value.arrivalTime;
        });

        // Initial data necessary for processing other information //TODO: Check if intialDdSelection is undefined
        await this.promisedSetState({
          viewType: tmpACViewType,
          dropDownFlights: addDropDownFlights,
          selectedSessionFlight:
            intialDdSelection !== -1
              ? addDropDownFlights[intialDdSelection]
              : addDropDownFlights[0],
        });

        // Validate the take off report for an engine start
        if (aircraftPageDataPartOne[1].engineStartTime === null) {
          await this.promisedSetState({
            takeOffReport: { flights: aircraftPageDataPartOne[1].flights },
          });
          this.setState((prevState) => ({
            infoFlight: aircraftPageDataPartOne[1].flights.at(-1),
            takeOffReport: {
              ...prevState.takeOffReport,
              engineStartTime: aircraftPageDataPartOne[1].engineStartTime,
              engineEndTime: aircraftPageDataPartOne[1].engineEndTime,
              approximateEngineStart:
                aircraftPageDataPartOne[1].approximateEngineStart,
              approximateEngineStop:
                aircraftPageDataPartOne[1].approximateEngineStop,
              flights: aircraftPageDataPartOne[1].flights,
            },
            activeSessionNumber: sessionNumber,
            dateDataTransfer: aircraftPageDataPartOne[0],
            aircraftSettings: aircraftPageDataPartOne[2],
            rpdSatcomSupport: aircraftPageDataPartOne[3].rpdSatcomSupport,
            tailOrganizationId: aircraftPageDataPartOne[3].organizationID,
            aircraftCmsSupport: aircraftPageDataPartOne[3].cmsSupport,
            status: tools.convertAircraftFlightStatus(
              aircraftPageDataPartOne[3].status
            ),
          }));

          this.warningNoSessionStart();
          return;
        }

        const flightCycles = this.processFlightCycles(
          this.axiosCancelSource,
          tmpTail,
          aircraftFamily,
          aircraftModel
        );

        const cmsSessionsList = this.processCmsSessionList(
          tmpTail,
          aircraftFamily,
          aircraftModel,
          aircraftPageDataPartOne[1].engineStartTime,
          aircraftPageDataPartOne[1].engineEndTime
            ? aircraftPageDataPartOne[1].engineEndTime
            : aircraftPageDataPartOne[1].engineStartTime,
          aircraftPageDataPartOne[3].cmsSupport,
          sessionNumber
        );

        const cmsIfMessageLatestDate = this.processCmsIfMessages(
          aircraftFamily,
          aircraftModel,
          tmpTail,
          aircraftPageDataPartOne[3].cmsSupport
        );

        const faults = this.getFaultsList(
          this.axiosCancelSource,
          tmpTail,
          aircraftFamily,
          aircraftModel,
          startTime,
          endTime,
          recentSessionOrder > 0 ? true : false,
          endTime
            ? endTime
            : aircraftPageDataPartOne[1].engineEndTime
              ? aircraftPageDataPartOne[1].engineEndTime
              : startTime
                ? startTime
                : aircraftPageDataPartOne[1].engineStartTime,
          tmpACViewType === "HISTORIC" ? "FULL" : "IF",
          aircraftPageDataPartOne[3].cmsSupport,
          sessionNumber
        );

        const eifrData = this.getEifrData(
          aircraftFamily,
          aircraftModel,
          startTime,
          endTime,
          serial
        );

        const promiseArray = [
          faults,
          flightCycles,
          cmsSessionsList,
          eifrData,
          cmsIfMessageLatestDate,
        ];
        const aircraftPageData = await Promise.all(
          promiseArray.map((req) => req)
        );

        const tempFdecasList = aircraftPageData[0][0];
        const tempOmsList = aircraftPageData[0][1];
        const tempCmsList = aircraftPageData[0][2];

        // Checking to make sure flights exist in a safe way
        let tempFlights = _.get(aircraftPageDataPartOne, "[1].flights");

        // If we have a flight array then add arrival and departure dates of flights to table
        if (tempFlights) {
          tempFlights = _.orderBy(tempFlights, ["departureTime"], ["asc"]);
          for (let fIndex = 0; fIndex < tempFlights.length; fIndex++) {
            if (tempFlights[fIndex].departureTime) {
              //Creating Fault Row for Takeoff
              const flightHeader = {
                id: uuidv4(),
                cards: [],
                clearedTimestamp: "",
                faultCleared: false,
                faultCode: "FLIGHT-" + (fIndex + 1),
                faultDescription: "",
                faultMessage: "",
                faultSeverity: "Z",
                faultTimestamp: tempFlights[fIndex].departureTime,
                hasInFlightParameterData: false,
                isFilteredRow: false,
                isFlightRow: true,
                flightPhase: "1",
                omsMessages: [],
                omsRelatedCount: 0,
                reduceCommonFaultStatus: false,
                timestamp: tempFlights[fIndex].departureTime,
              };

              tempFdecasList.push(flightHeader);
              tempOmsList.push(flightHeader);
              tempCmsList.push(flightHeader);

              const row = {
                id: uuidv4(),
                cards: [],
                clearedTimestamp: "",
                faultCleared: false,
                faultCode: "TAKEOFF",
                faultDescription: "FLIGHT " + (fIndex + 1),
                faultMessage: "FLIGHT " + (fIndex + 1),
                faultSeverity: "F",
                faultTimestamp: tempFlights[fIndex].departureTime,
                hasInFlightParameterData: false,
                isFilteredRow: false,
                isFlightRow: false,
                flightPhase: "1",
                omsMessages: [],
                omsRelatedCount: 0,
                reduceCommonFaultStatus: false,
                timestamp: tempFlights[fIndex].departureTime,
              };

              // Adding Row to all fault tabs
              tempFdecasList.push(row);
              tempOmsList.push(row);
              tempCmsList.push(row);
            }

            if (tempFlights[fIndex].arrivalTime) {
              //Creating Fault Row for Landing
              const row = {
                id: uuidv4(),
                cards: [],
                clearedTimestamp: "",
                faultCleared: false,
                faultCode: "LANDING",
                faultDescription: "FLIGHT " + (fIndex + 1),
                faultMessage: "FLIGHT " + (fIndex + 1),
                faultSeverity: "F",
                flightPhase: "4",
                faultTimestamp: tempFlights[fIndex].arrivalTime,
                hasInFlightParameterData: false,
                isFlightRow: false,
                isFilteredRow: false,
                omsMessages: [],
                omsRelatedCount: 0,
                reduceCommonFaultStatus: false,
                timestamp: tempFlights[fIndex].arrivalTime,
              };

              //Adding Row to all fault tabs
              tempFdecasList.push(row);
              tempOmsList.push(row);
              tempCmsList.push(row);
            }
          }
        }
        await this.promisedSetState({
          takeOffReport: { flights: aircraftPageDataPartOne[1].flights },
        });
        await this.promisedSetState((prevState) => ({
          fdecasList: _.orderBy(tempFdecasList, ["faultTimestamp"], ["asc"]),
          omsList: _.orderBy(tempOmsList, ["faultTimestamp"], ["asc"]),
          cmsList: _.orderBy(tempCmsList, ["faultTimestamp"], ["asc"]),
          cmsListCopy: _.orderBy(tempCmsList, ["faultTimestamp"], ["asc"]),
          eifrList: aircraftPageData[3],
          infoFlight: aircraftPageDataPartOne[1].flights[0],
          takeOffReport: {
            ...prevState.takeOffReport,
            engineStartTime: aircraftPageDataPartOne[1].engineStartTime,
            engineEndTime: aircraftPageDataPartOne[1].engineEndTime,
            approximateEngineStart:
              aircraftPageDataPartOne[1].approximateEngineStart,
            approximateEngineStop:
              aircraftPageDataPartOne[1].approximateEngineStop,
            flights: aircraftPageDataPartOne[1].flights,
          },
          activeSessionNumber: sessionNumber,
          rpdSatcomSupport: aircraftPageDataPartOne[3].rpdSatcomSupport,
          tailOrganizationId: aircraftPageDataPartOne[3].organizationID,
          aircraftCmsSupport: aircraftPageDataPartOne[3].cmsSupport,
          dropDownFlights: addDropDownFlights,
          status: tools.convertAircraftFlightStatus(
            aircraftPageDataPartOne[3].status
          ),
          flightCycles: aircraftPageData[1],
          dateDataTransfer: aircraftPageDataPartOne[0],
          aircraftSettings: aircraftPageDataPartOne[2],
          loading: false,
          cmsSessionsList: aircraftPageData[2],
          latestCmsIfMessageDate: aircraftPageData[4],
          aircraftEditCyclesPerm:
            aircraftPageDataPartOne[3].editCyclesHoursPerm,
        }));

        // This will update the flight selection on FaultMessageBox
        this.updateFaultListSelection(
          this.state.selectedSessionFlight,
          tempFdecasList,
          tempCmsList
        );
      }
    } catch (error) {
      console.error("ERROR: ", error);
      this.setLatestFlight(undefined);
      this.setState({
        fdecasList: [],
        omsList: [],
        cmsList: [],
        infoFlight: undefined,
        loading: false,
        loadingFaultEventCount: false,
      });
    }
  }

  async processAircraftGenerlData(
    registrationNumber,
    serialNumber,
    tailPeriodTimestamp = null
  ) {
    try {
      let generalData = await getTailGeneralData(
        registrationNumber,
        serialNumber,
        tailPeriodTimestamp
      );

      return generalData.data;
    } catch (error) {
      console.error("ERROR: ", error);
      this.setState({
        errorObject: error,
      });
      this.openNotificationModal({ errorCode: "ER-AC-00007" });
      return false;
    }
  }

  async processAircraftSettings(tailNumber, aircraftFamily, aircraftModel) {
    try {
      let settings = await getAircraftSettings(
        tailNumber,
        aircraftFamily,
        aircraftModel
      );

      return settings.data;
    } catch (error) {
      console.error("ERROR: ", error);
      this.openNotificationModal({ errorCode: "ER-AC-00005" });
      return false;
    }
  }

  async setLatestFlight(flight) {
    await this.promisedSetState({
      latestFlight: flight,
    });
  }

  async processTakeOffReport(
    axiosCancelSource,
    tailNumber,
    aircraftFamily,
    aircraftModel,
    startTime,
    endTime,
    sitaInfoSource,
    sessionNumber,
    sessionType
  ) {
    try {
      let takeOffReport = await getTakeOffReport(
        tailNumber,
        startTime,
        endTime,
        sitaInfoSource,
        aircraftFamily,
        aircraftModel,
        sessionNumber,
        axiosCancelSource
      );
      takeOffReport = takeOffReport.data;

      const viewType = this.getSessionViewType(
        takeOffReport.sessionNumber,
        this.state.aircraftUIConfig?.aircraftViewPage?.hasSitaData,
        takeOffReport.hasSessionDataTransfer,
        sessionType
      );
      const frSessionNumber =
        takeOffReport.sessionNumber && takeOffReport.sessionNumber !== ""
          ? takeOffReport.sessionNumber
          : null;
      const engineStartTime = takeOffReport.engineStartTime;
      const engineEndTime =
        takeOffReport.engineEndTime !== "NaT"
          ? takeOffReport.engineEndTime
          : null;
      const approximateEngineStart = takeOffReport.approximateEngineStart;
      const approximateEngineStop = takeOffReport.approximateEngineStop;
      const flights = _.orderBy(
        _.filter(takeOffReport.flights, function (flight) {
          return flight.departureTime || flight.arrivalTime;
        }),
        ["departureTime"],
        ["desc"]
      );
      _.forEach(flights, function (flight) {
        flight.departureTime = moment
          .utc(flight.departureTime)
          .format("YYYY-MM-DDTHH:mm:ss");
        flight.arrivalTime =
          flight.arrivalTime && flight.arrivalTime !== "NaT"
            ? moment.utc(flight.arrivalTime).format("YYYY-MM-DDTHH:mm:ss")
            : null;
      });

      return {
        engineStartTime: engineStartTime,
        engineEndTime: engineEndTime,
        approximateEngineStart: approximateEngineStart,
        approximateEngineStop: approximateEngineStop,
        flights: flights,
        sessionNumber: frSessionNumber,
        viewType: viewType,
      };
    } catch (error) {
      console.error("Error: ", error);
      this.openNotificationModal({
        errorCode: "ER-AC-00002",
      });
      return false;
    }
  }

  getSessionViewType(
    sessionNumber,
    hasSitaData,
    hasSessionDataTransfer,
    sessionType
  ) {
    let sessionViewType = "IFR";

    if (hasSitaData) {
      if (sessionNumber && sessionNumber !== "") {
        sessionViewType = "HISTORIC";
      } else {
        sessionViewType = "IFR";
      }
    } else {
      if (hasSessionDataTransfer || sessionType === "GROUND") {
        sessionViewType = "HISTORIC";
      } else {
        sessionViewType = "IFR";
      }
    }

    return sessionViewType;
  }

  async processFlightCycles(
    axiosCancelSource,
    tailNumber,
    aircraftFamily,
    aircraftModel
  ) {
    try {
      const flightCycles = await getFlightCycles(
        tailNumber,
        aircraftFamily,
        aircraftModel,
        axiosCancelSource
      );

      return flightCycles.data;
    } catch (error) {
      console.error("Error: ", error);
      return [
        {
          totalFlightTime: null,
          totalFlightCycles: null,
          engine1Serial: null,
          totalEngine1Time: null,
          totalEngine1Cycles: null,
          engine2Serial: null,
          totalEngine2Time: null,
          totalEngine2Cycles: null,
        },
      ];
    }
  }

  async processCmsSessionList(
    tailNumber,
    aircraftFamily,
    aircraftModel,
    ahmuSessionStartTime,
    ahmuSessionEndTime,
    cmsSupport,
    sessionNumber
  ) {
    try {
      if (
        cmsSupport &&
        ((ahmuSessionStartTime && ahmuSessionEndTime) || sessionNumber)
      ) {
        const cmsSessionsList = await getCmsSessionList(
          tailNumber,
          aircraftFamily,
          aircraftModel,
          ahmuSessionStartTime,
          ahmuSessionEndTime,
          sessionNumber,
          null
        );

        return cmsSessionsList.data ? cmsSessionsList.data : [];
      }

      return [];
    } catch (error) {
      console.error("Error: ", error);
      return [];
    }
  }

  // Process the list of CMS in flight Message dates to return the latest one and confirm if CMS is actaully working
  async processCmsIfMessages(
    aircraftFamily,
    aircraftModel,
    tailNumber,
    cmsSupport
  ) {
    try {
      // IF this is not an IF session or the aircraft does not have CMS support we do not call for the list
      if (cmsSupport && this.state.viewType === "IFR") {
        const cmsSessionsList = await getCmsIfMessages(
          aircraftFamily,
          aircraftModel,
          tailNumber,
          null
        );

        const arrayLength = cmsSessionsList.data
          ? cmsSessionsList.data.cmsIfMessageTimestamps?.length
          : 0;

        // If the is some data returning from the API we get the latest date if not we return null
        if (arrayLength > 0) {
          return _.sortBy(
            cmsSessionsList.data.cmsIfMessageTimestamps,
            function (value) {
              return new Date(value);
            }
          )[arrayLength - 1];
        }
      }

      return null;
    } catch (error) {
      console.error("Error: ", error);
      return null;
    }
  }

  async processLatestAHMSDownload(
    axiosCancelSource,
    tailNumber,
    aircraftFamily,
    aircraftModel
  ) {
    try {
      // Validate if the aircraft family/model uses the latest hmu transfer date
      if (
        AirframeUIConfigs[aircraftFamily][aircraftModel]?.aircraftViewPage
          ?.hasLatestHmuTransferDate
      ) {
        const aircraftMetadata = await getAircraftMetadata(
          tailNumber,
          aircraftFamily,
          aircraftModel,
          axiosCancelSource
        );

        return aircraftMetadata.data.ahmsLatestDownload;
      }

      return null;
    } catch (error) {
      console.error("Error: ", error);
      return null;
    }
  }

  async getEifrData(
    aircraftFamily,
    aircraftModel,
    startTime,
    endTime,
    serialNumber
  ) {
    try {
      if (this.state.aircraftUIConfig?.aircraftViewPage?.hasEIFRTab) {
        const eifrData = await getEifrList(
          null,
          aircraftFamily,
          aircraftModel,
          startTime,
          endTime,
          serialNumber,
          null
        );

        return eifrData.data;
      }
    } catch (error) {
      console.error("ERROR: ", error);
      return [];
    }
  }

  // Get the faults from the endpoint then sends the information
  // to create the proper data structures to create fdecas and oms rows
  async getFaultsList(
    axiosCancelSource,
    tail,
    aircraftFamily,
    aircraftModel,
    starTime,
    endTime,
    sitaInfoSource,
    cmsDefaultEndDate,
    cmsInfoSource,
    cmsSupport,
    sessionNumber
  ) {
    try {
      const commonFaultsFdeCas = getCommonFDEList(
        aircraftFamily,
        aircraftModel
      );
      const faultsFdeCas = getFaultsFDE(
        tail,
        starTime,
        endTime,
        sitaInfoSource,
        aircraftFamily,
        aircraftModel,
        sessionNumber,
        axiosCancelSource
      );
      let faultsOms;
      if (this.state.aircraftUIConfig?.aircraftViewPage?.hasOmsMdcMessages) {
        faultsOms = getFaultsOMS(
          tail,
          starTime,
          endTime,
          sitaInfoSource,
          aircraftFamily,
          aircraftModel,
          sessionNumber,
          axiosCancelSource
        );
      } else {
        faultsOms = { data: [] };
      }

      let faultsCms;
      if (cmsSupport) {
        faultsCms = getFaultsCMS(
          tail,
          starTime,
          endTime,
          cmsInfoSource,
          aircraftFamily,
          aircraftModel,
          sessionNumber,
          axiosCancelSource
        );
      } else {
        faultsCms = { data: [] };
      }

      const faultPromiseArray = [
        faultsFdeCas,
        commonFaultsFdeCas,
        faultsOms,
        faultsCms,
      ];
      const faultsData = await Promise.all(faultPromiseArray.map((req) => req));

      const results = await this.processFaults(
        faultsData[0].data ? faultsData[0].data : [],
        faultsData[1].data ? faultsData[1].data : [],
        faultsData[2].data ? faultsData[2].data : [],
        faultsData[3].data ? faultsData[3].data : [],
        cmsDefaultEndDate
      );

      return results;
    } catch (error) {
      console.error("Error: ", error);
      this.openNotificationModal({
        errorCode: "ER-AC-00003",
      });
      return [];
    }
  }

  // Creates the lists of faults based on the type of the fault
  async processFaults(
    faultsFdeCas,
    commonFaultsFdeCas,
    faultsOms,
    faultsCms,
    cmsDefaultEndTime
  ) {
    const fdecasList = [];
    const omsList = [];
    const cmsList = [];

    // Filter out FOQA data, CEOD data, All Data Forward & MDC Retrieve faults
    faultsFdeCas = _.filter(
      faultsFdeCas,
      function (o) {
        return o.faultMessage !== "All Data Forward"
          && o.faultMessage !== "FOQA data"
          && o.faultMessage !== "CEOD data"
          && o.faultMessage !== "MDC RETRIEVE"
          && o.faultMessage !== "LANDING"
          && o.faultMessage !== "TAKE OFF"
      }
    )

    const tempCommonFdeFaultsStatusList = _.intersectionBy(
      commonFaultsFdeCas,
      faultsFdeCas,
      "faultCode"
    );

    try {
      const miniFaultFdeCasSet = faultsFdeCas.slice(0, 1000);
      const miniFaultOms = faultsOms.slice(0, 1000);
      const miniFaultCms = faultsCms.slice(0, 1000);

      const splitCMSFaults = _.partition(miniFaultCms, (param) => {
        return param.faultTimestamp === "1970-01-01T00:00:00.000000Z";
      });

      splitCMSFaults[0].forEach((fault) => {
        fault.cmsFaultDescription = "This fault has an invalid date.";
        fault.faultTimestamp = cmsDefaultEndTime;
      });

      // FDE Depends on OMS
      for (const fault of miniFaultFdeCasSet) {
        let commonFdeFaultStatus = false;
        let foundFault = _.find(tempCommonFdeFaultsStatusList, {
          faultCode: fault.faultCode,
        });
        if (foundFault) {
          commonFdeFaultStatus = foundFault.reduceStatus;
        }

        const tmpRow = this.createFdeCasRow(
          fault,
          this.state.tail,
          this.state.status,
          miniFaultOms,
          commonFdeFaultStatus
        );
        if (tmpRow) fdecasList.push(tmpRow);
      }

      // OMS Must for first
      for (const fault of miniFaultOms) {
        const tmpRow = await this.createOmsRow(fault, miniFaultFdeCasSet);
        omsList.push(tmpRow);
      }

      // OMS Must for first
      for (const fault of miniFaultCms) {
        const tmpRow = await this.createCmsRow(fault);
        cmsList.push(tmpRow);
      }

      return [fdecasList, omsList, cmsList];
    } catch (error) {
      console.error("Error: ", error);
      return [[], [], []];
    }
  }

  findRelatedOmsMessages(omsMessages, fdeFaultCode, fdeFaultTimestamp) {
    const relatedOmsMessages = [];
    _.forEach(omsMessages, function (omsMessage) {
      if (
        _.find(omsMessage.fdeFaultCode, function (o) {
          return o === fdeFaultCode;
        })
      ) {
        const omsTimestamp = moment.utc(omsMessage.faultTimestamp).valueOf();
        if (
          omsTimestamp >= fdeFaultTimestamp - 60000 &&
          omsTimestamp <= fdeFaultTimestamp + 60000
        ) {
          relatedOmsMessages.push({
            faultCode: omsMessage.faultCode,
            faultTimestamp: omsMessage.faultTimestamp,
            faultMessage: omsMessage.faultMessage,
            faultDescription: omsMessage.omsFaultDescription,
          });
        }
      }
    });
    return relatedOmsMessages;
  }

  findRelatedFdeCasMessages(fdeCasList, omsFaultTimestamp, omsFdeFaults) {
    const relatedFdeFaults = [];
    _.forEach(fdeCasList, function (fdeFault) {
      if (
        _.find(omsFdeFaults, function (o) {
          return o === fdeFault.faultCode;
        })
      ) {
        const fdeTimestamp = moment.utc(fdeFault.faultTimestamp).valueOf();
        if (
          omsFaultTimestamp >= fdeTimestamp - 60000 &&
          omsFaultTimestamp <= fdeTimestamp + 60000
        ) {
          relatedFdeFaults.push({
            faultCode: fdeFault.faultCode,
            faultTimestamp: fdeFault.faultTimestamp,
            faultMessage: fdeFault.faultMessage,
            faultDescription: fdeFault.faultDescription,
          });
        }
      }
    });
    return relatedFdeFaults;
  }

  // Create a data structure to create an fde row from the faults endpoint
  createFdeCasRow(txt, tail, status, omsMessages, commonFdeFaultStatus) {
    try {
      const relatedOmsMessages = this.findRelatedOmsMessages(
        omsMessages,
        txt.faultCode,
        moment.utc(txt.faultTimestamp).valueOf()
      );

      let finalSmartFixURL;
      if (localStorage.getItem("userType") === "customer") {
        finalSmartFixURL = txt.sfpExternalUrl ? txt.sfpExternalUrl : "/";
      } else {
        finalSmartFixURL = txt.sfp_url ? txt.sfp_url : "/";
      }

      let cards = [];
      if (this.state.aircraftUIConfig?.aircraftViewPage?.hasSmartLinkPlus) {
        cards.push({
          id: "card__smartfix",
          textTitle: "",
          text: "FDE Smart Fix",
          featherIconName: "external-link",
          sfpLink: finalSmartFixURL,
          disabled: !this.getSmartFixLinkEnabledStatus(
            this.state.aircraftFamily,
            this.state.aircraftModel
          )
            ? true
            : finalSmartFixURL === "/",
          clickEvent: this.handleSFPLink,
        });
      }

      const row = {
        cards: cards,
        id: `${txt.faultCode.replace(/\s/g, "")}-${moment
          .utc(txt.faultTimestamp)
          .format("YYYY-MM-DDTHH:mm:ss")}-${Math.floor(Math.random() * 90000)}`,
        faultCode: txt.faultCode.replace("/", " "),
        tail,
        faultSeverity: txt.faultSeverity,
        faultMessage: txt.faultMessage,
        faultTimestamp: txt.faultTimestamp,
        rpdEventRequestAvailable: txt.rpdEventRequestAvailable,
        timestamp: moment.utc(txt.faultTimestamp).format("YYYY-MM-DDTHH:mm:ss"),
        sfp_url: finalSmartFixURL,
        status,
        flightPhase: txt.flightPhase,
        faultDescription: txt.fdeDescription,
        outsideTemp: txt.outsideTemp,
        hasInFlightParameterData: txt.hasInFlightParameterData,
        airSpeed: txt.airSpeed,
        altitude: txt.altitude,
        allOmsMessages: txt.omsRelatedFaultCodes,
        omsMessages: relatedOmsMessages,
        isFilteredRow: false,
        faultCleared: txt.faultCleared,
        clearedTimestamp: txt.clearedTimestamp ? txt.clearedTimestamp : "",
        omsRelatedCount: relatedOmsMessages.length,
        reduceCommonFaultStatus: commonFdeFaultStatus,
        eventUid: txt.eventUid ? txt.eventUid : "",
        eventStartTime: txt.eventStartTime ? txt.eventStartTime : "",
        eventEndTime: txt.eventEndTime ? txt.eventEndTime : "",
      };
      return row;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // Create a data structure to create an oms row from the faults endpoint
  async createOmsRow(txt, fdeCasList) {
    try {
      const relatedFdeCasMessages = this.findRelatedFdeCasMessages(
        fdeCasList,
        moment.utc(txt.faultTimestamp).valueOf(),
        txt.fdeFaultCode
      );

      let finalSmartFixURL;
      if (localStorage.getItem("userType") === "customer") {
        finalSmartFixURL = txt.sfpExternalUrl ? txt.sfpExternalUrl : "/";
      } else {
        finalSmartFixURL = txt.smartFixPlus ? txt.smartFixPlus : "/";
      }

      let cards = [];
      if (this.state.aircraftUIConfig?.aircraftViewPage?.hasSmartLinkPlus) {
        cards.push({
          id: "card__smartfix",
          textTitle: "",
          text:
            this.state.aircraftUIConfig?.aircraftViewPage?.mdcTabLabel.toString() +
            " Smart Fix",
          featherIconName: "external-link",
          sfpLink: finalSmartFixURL,
          disabled: !this.getSmartFixLinkEnabledStatus(
            this.state.aircraftFamily,
            this.state.aircraftModel
          )
            ? true
            : finalSmartFixURL === "/",
          clickEvent: this.handleSFPLink,
        });
      }

      const row = {
        cards: cards,
        id: `${txt.faultCode}${Math.floor(Math.random() * 90000)}`,
        faultCode: txt.faultCode ? txt.faultCode : "",
        faultSeverity: txt.severity,
        faultMessage: txt.faultMessage ? txt.faultMessage : "",
        faultTimestamp: moment
          .utc(txt.faultTimestamp)
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        faultDescription: txt.omsFaultDescription
          ? txt.omsFaultDescription
          : "",
        fdeFaultCode: txt.fdeFaultCode,
        smartfixUrl: finalSmartFixURL,
        fdeRelated: relatedFdeCasMessages,
        fdeRelatedCount: relatedFdeCasMessages.length,
      };
      return row;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // Create a data structure to create an oms row from the faults endpoint
  async createCmsRow(txt) {
    try {
      let finalSmartFixURL;
      if (localStorage.getItem("userType") === "customer") {
        finalSmartFixURL = txt.sfpExternalUrl ? txt.sfpExternalUrl : "/";
      } else {
        finalSmartFixURL = txt.smartFixPlus ? txt.smartFixPlus : "/";
      }

      let cards = [];
      if (this.state.aircraftUIConfig?.aircraftViewPage?.hasSmartLinkPlus) {
        cards.push({
          id: "card__smartfix",
          textTitle: "",
          text: "CMS Smart Fix",
          featherIconName: "external-link",
          sfpLink: finalSmartFixURL,
          disabled: !this.getSmartFixLinkEnabledStatus(
            this.state.aircraftFamily,
            this.state.aircraftModel
          )
            ? true
            : finalSmartFixURL === "/",
          clickEvent: this.handleSFPLink,
        });
      }

      const row = {
        id: `${txt.faultCode}${Math.floor(Math.random() * 90000)}`,
        cards: cards,
        faultCode: txt.maintenanceId ? txt.maintenanceId : "",
        faultCodeActual: txt.cmsFaultCode ? txt.cmsFaultCode : "",
        LRUSourceId: txt.LRUSourceId ? txt.LRUSourceId : "",
        faultMessage: txt.faultMessage ? txt.faultMessage : "",
        faultStatusDescription: txt.faultStatusDescription
          ? txt.faultStatusDescription
          : "N/A",
        faultStatusAction: txt.faultStatusAction
          ? txt.faultStatusAction
          : "N/A",
        faultTimestamp: txt.faultTimestamp,
        faultSeverity: txt.faultSeverity,
        faultSystem: txt.system,
        faultSystemId: txt.systemID,
        faultDescription: txt.cmsFaultDescription
          ? txt.cmsFaultDescription
          : "",
        smartfixUrl: finalSmartFixURL,
        timestamp: moment.utc(txt.faultTimestamp).format("YYYY-MM-DDTHH:mm:ss"),
        usingApproximateTimestamp: txt.usingApproximateTimestamp,
      };
      return row;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async handleClearHistoricDataSelection() {
    await this.handleHistoricDataSelection(
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  async handleHistoricDataSelection(
    fhSessionStartTime,
    fhSessionEndTime,
    fhSession,
    fhDepartureTime,
    fhArrivalTime,
    fhHistorySessionType
  ) {
    if (fhSession || (fhSessionStartTime && fhSessionEndTime)) {
      this.setState(
        {
          viewType: "HISTORIC",
          flightHistorySessionStartTime: fhSessionStartTime,
          flightHistorySessionEndTime: fhSessionEndTime,
          flightHistorySession: fhSession,
          flightHistorySessionType: fhHistorySessionType,
          fdeInfoFault: null,
          omsInfoFault: null,
          infoBoxType: "FLIGHT",
          recentSessionOrder: 0,
          selectedMdcRowData: {},
        },
        async () => {
          localStorage.setItem(
            "tmpACSessionStartDate",
            this.state.flightHistorySessionStartTime
          );
          localStorage.setItem(
            "tmpACHistorySessionType",
            this.state.flightHistorySessionType
          );
          localStorage.setItem(
            "tmpACSessionEndDate",
            this.state.flightHistorySessionEndTime
          );
          localStorage.setItem("tmpACSession", this.state.flightHistorySession);
          localStorage.removeItem("tmpACSitaSessionOrder");
          await this.loadAircraftPageInfo(
            this.state.flightHistorySession,
            this.state.flightHistorySessionStartTime,
            this.state.flightHistorySessionEndTime,
            this.state.flightHistorySessionType,
            this.state.tail,
            this.state.serial,
            this.state.aircraftFamily,
            this.state.aircraftModel,
            this.state.recentSessionOrder,
            this.state.monitorStatus
          );

          let selectedFlight = _.find(this.state.takeOffReport.flights, {
            departureTime: moment
              .utc(fhDepartureTime)
              .format("YYYY-MM-DDTHH:mm:ss"),
          });

          if (!selectedFlight) {
            selectedFlight = _.find(this.state.takeOffReport.flights, {
              arrivalTime: moment
                .utc(fhArrivalTime)
                .format("YYYY-MM-DDTHH:mm:ss"),
            });
          }

          if (selectedFlight && selectedFlight !== this.state.latestFlight) {
            const selection = _.find(this.state.dropDownFlights, (v) => {
              if (selectedFlight.flightLeg) {
                return v.value.flightLeg === selectedFlight.flightLeg;
              } else {
                return v.value.departureTime === selectedFlight.departureTime;
              }
            });

            await this.setLatestFlight(selectedFlight);
            await this.changeFlightSelection(selection);
          }
        }
      );
    } else {
      if (this.state.viewType === "HISTORIC") {
        this.setState(
          {
            viewType: "IFR",
            flightHistorySessionStartTime: null,
            flightHistorySessionEndTime: null,
            flightHistorySession: null,
            fdeInfoFault: null,
            omsInfoFault: null,
            infoBoxType: "FLIGHT",
            recentSessionOrder: 0,
            selectedMdcRowData: {},
          },
          () => {
            localStorage.removeItem("tmpACSessionStartDate");
            localStorage.removeItem("tmpACSessionEndDate");
            localStorage.removeItem("tmpACSession");
            localStorage.removeItem("tmpACHistorySessionType");
            localStorage.removeItem("tmpACSitaSessionOrder");
            this.loadAircraftPageInfo(
              this.state.flightHistorySession,
              this.state.flightHistorySessionStartTime,
              this.state.flightHistorySessionEndTime,
              this.state.flightHistorySessionType,
              this.state.tail,
              this.state.serial,
              this.state.aircraftFamily,
              this.state.aircraftModel,
              this.state.recentSessionOrder,
              this.state.monitorStatus
            );
          }
        );
      }
    }
  }

  handleRecentSessionSelection(
    startTime,
    endTime,
    ifrSessionOrder,
    addExtraParams
  ) {
    this.setState(
      {
        recentSessionOrder: startTime !== null ? ifrSessionOrder : 0,
        viewType: "IFR",
        flightHistorySessionStartTime: startTime,
        flightHistorySessionEndTime: endTime,
        flightHistorySession: null,
        fdeInfoFault: null,
        omsInfoFault: null,
        infoBoxType: "FLIGHT",
        selectedMdcRowData: {},
      },
      () => {
        localStorage.setItem("tmpACSitaSessionOrder", ifrSessionOrder);
        localStorage.removeItem("tmpACSession");
        localStorage.removeItem("tmpACHistorySessionType");

        if (startTime === null || endTime === null) {
          localStorage.removeItem("tmpACSessionStartDate");
          localStorage.removeItem("tmpACSessionEndDate");
        } else {
          localStorage.setItem(
            "tmpACSessionStartDate",
            this.state.flightHistorySessionStartTime
          );
          localStorage.setItem(
            "tmpACSessionEndDate",
            this.state.flightHistorySessionEndTime
          );
        }

        // If the data comes from URL navigation, it needs tail and serial numbers to load in-flight report.
        if (addExtraParams) {
          this.loadAircraftPageInfo(
            null,
            startTime, // Use param instead of direct state in case of deferred value.
            endTime, // Use param instead of direct state in case of deferred value.
            null,
            this.state.tail, // Extra param to safely render the view.
            this.state.serial, // Extra param to safely render the view.
            this.state.aircraftFamily,
            this.state.aircraftModel,
            ifrSessionOrder
          );
        } else {
          this.loadAircraftPageInfo(
            null,
            this.state.flightHistorySessionStartTime,
            this.state.flightHistorySessionEndTime,
            null,
            this.state.tail,
            this.state.serial,
            this.state.aircraftFamily,
            this.state.aircraftModel,
            ifrSessionOrder
          );
        }
      }
    );
  }

  BuildCardsHtml() {
    let cards = [];

    let newTitle = "";
    if (this.state.selectedFaultMessageTab === "FDECAS") {
      newTitle = "FDE";
    } else if (this.state.selectedFaultMessageTab === "OMS") {
      newTitle = this.state.aircraftUIConfig?.aircraftViewPage?.mdcTabLabel;
    } else {
      newTitle = this.state.selectedFaultMessageTab;
    }

    if (
      this.state.aircraftUIConfig?.aircraftViewPage?.hasSmartLinkPlus &&
      this.state.selectedFaultMessageTab !== "EIFR"
    ) {
      cards.push({
        id: "card__smartfix",
        textTitle: newTitle,
        text: "Smart Fix",
        featherIconName: "external-link",
        disabled: true,
        clickEvent: undefined,
      });
    }

    let paramDataLink = (
      <ParameterChartsRouting
        aircraftUIConfig={this.state.aircraftUIConfig}
        fdecasList={this.state.fdecasList}
        userId={this.state.userId}
        orgId={this.state.userOrganizationId}
        crcId={this.state.userId}
        selectedTab={this.state.selectedFaultMessageTab}
        serial={this.state.serial}
        status={this.state.status}
        aircraftSession={
          this.state.flightHistorySession
            ? this.state.flightHistorySession
            : this.state.activeSessionNumber
        }
        hasInFlightData={
          this.state.aircraftUIConfig?.aircraftViewPage?.hasInFlightDataCard
        }
        hasRPDFunctionality={
          this.state.aircraftUIConfig?.aircraftViewPage?.hasRPDFunctionality
        }
        hasParameterDataDictionary={
          this.state.aircraftUIConfig?.globalApp?.hasParameterDataDictionary
        }
        tail={this.state.tail}
        aircraftFamily={this.state.aircraftFamily}
        takeOffReport={this.state.takeOffReport}
        aircraftModel={this.state.aircraftModel}
        viewType={this.state.viewType}
        infoBoxType={this.state.infoBoxType}
        fdeInfoFault={this.state.fdeInfoFault}
        cmsInfoFault={this.state.cmsInfoFault}
        cmsSessionsList={this.state.cmsSessionsList}
        recentSessionOrder={this.state.recentSessionOrder}
        infoFlight={this.state.infoFlight}
        departureTime={
          this.state.latestFlight ? this.state.latestFlight.departureTime : null
        }
        arrivalTime={
          this.state.latestFlight ? this.state.latestFlight.arrivalTime : null
        }
        monitorStatus={this.state.monitorStatus}
        rpdStartTime={
          this.state.latestFlight ? this.state.latestFlight.departureTime : null
        }
        departureAirportName={
          this.state.latestFlight
            ? this.state.latestFlight.departureAirportName
            : null
        }
        departureAirportIATA={
          this.state.latestFlight
            ? this.state.latestFlight.departureAirportIATA
            : null
        }
        arrivalAirportName={
          this.state.latestFlight
            ? this.state.latestFlight.arrivalAirportName
            : null
        }
        arrivalAirportIATA={
          this.state.latestFlight
            ? this.state.latestFlight.arrivalAirportIATA
            : null
        }
        externalRpdRequest={this.state.aircraftSettings.externalRpdRequest}
        rpdSatcomSupport={this.state.rpdSatcomSupport}
        toggleModal={this.toggleEventCountModal}
        latestDataTransferDate={this.state.dateDataTransfer}
        hasLatestHmuTransferDate={
          this.state.aircraftUIConfig?.aircraftViewPage
            ?.hasLatestHmuTransferDate
        }
        timeInFlight={
          this.state.latestFlight
            ? tools.getFormattedTimeDiff(
              this.state.latestFlight.departureTime,
              this.state.latestFlight.arrivalTime
            )
            : "-"
        }
        currentAircraftStatus={this.state.status}
        // monitorStatus={this.state.monitorStatus}
        selectedMdcRowData={this.state.selectedMdcRowData}
        activeTab={this.state.selectedFaultMessageTab}
      />
    );

    if (
      this.state.selectedFaultMessageTab === "FDECAS" &&
      this.state.fdeInfoFault
    ) {
      cards = this.state.fdeInfoFault.cards;
    }

    if (
      this.state.selectedFaultMessageTab === "OMS" &&
      this.state.omsInfoFault
    ) {
      cards = this.state.omsInfoFault.cards;
    }

    if (
      this.state.selectedFaultMessageTab === "CMS" &&
      this.state.cmsInfoFault
    ) {
      cards = this.state.cmsInfoFault.cards;
    }

    if (
      this.state.selectedFaultMessageTab === "MDC" &&
      this.state.selectedMdcRowData?.cards?.length
    ) {
      cards = this.state.selectedMdcRowData.cards;
    }

    // The SmartFix card will be viewed with an a tag, rather than a card
    let cardsHtml = (
      <div
        className="aircraftView__faultMessage__cardWrapper"
        id="aircraftView__faultMessage__cardWrapper"
      >
        {paramDataLink}
        {cards.map((card) => (
          <React.Fragment key={card.id}>
            {card.id === "card__smartfix" &&
              !this.getSmartFixLinkEnabledStatus(
                this.state.aircraftFamily,
                this.state.aircraftModel
              ) ? (
              <CustomTooltip
                tooltipType="contextual"
                content="We are currently experiencing technical difficulties rendering this URL for SmartFix Plus unavailable. We are actively working on resolving the issue."
                placement={"top-end"}
              >
                <div>
                  <Card
                    id={card.id}
                    textTitle={card.textTitle}
                    text={card.text}
                    sfpLink={card.sfpLink}
                    featherIconName={card.featherIconName}
                    disabled={card.disabled}
                    onCardClickEvent={card.clickEvent}
                    isLoading={false}
                    active={false}
                    textTitleActive={card.textTitleActive}
                    textActive={card.textActive}
                    featherIconNameActive={card.featherIconNameActive}
                  ></Card>
                </div>
              </CustomTooltip>
            ) : (
              <Card
                id={card.id}
                textTitle={card.textTitle}
                text={card.text}
                sfpLink={card.sfpLink}
                featherIconName={card.featherIconName}
                disabled={card.disabled}
                onCardClickEvent={card.clickEvent}
                isLoading={false}
                active={false}
                textTitleActive={card.textTitleActive}
                textActive={card.textActive}
                featherIconNameActive={card.featherIconNameActive}
              ></Card>
            )}
          </React.Fragment>
        ))}
      </div>
    );

    return cardsHtml;
  }

  // onboardingHistoricDataModalToggle(index) {
  //   if (index === 10) {
  //     this.handleHistoricDataModalToggle(true);
  //   } else {
  //     this.handleHistoricDataModalToggle(false);
  //   }
  // }

  handleHistoricDataModalToggle(status) {
    this.setState({
      isOpenHistoricDataModal: status,
    });
  }

  // This code handles the fault selection clicks on the lollipop charts
  async handleLollipopClick(dataSeries, faultTimestamp, faultCode) {
    let faultList =
      this.state.selectedFaultMessageTab === "CMS"
        ? this.state.cmsList
        : this.state.fdecasList;
    let infoFault =
      this.state.selectedFaultMessageTab === "CMS"
        ? this.state.cmsInfoFault
        : this.state.fdeInfoFault;

    dataSeries =
      this.state.selectedFaultMessageTab === "CMS" && dataSeries !== "FLIGHT"
        ? "CMS"
        : dataSeries;

    let selectedFlight = null;
    let selectedFault = null;
    let selectedFaultMessageTab = this.state.selectedFaultMessageTab;
    let resetInfoSelection = false;

    if (dataSeries === "FLIGHT") {
      if (faultCode === "TAKEOFF") {
        selectedFlight = _.find(this.state.takeOffReport.flights, {
          departureTime: faultTimestamp,
        });

        const sF = _.find(this.state.dropDownFlights, (t) => {
          return t.value.flightLeg === selectedFlight.flightLeg;
        });
        selectedFault = _.find(faultList, {
          faultCode: "TAKEOFF",
          timestamp: faultTimestamp,
        });

        this.highlightChartSection(sF.label);
        const element = document.getElementById(selectedFault.id);

        element.scrollIntoView({ behavior: "smooth" });
        this.setState({
          selectedSessionFlight: sF,
        });
      } else if (faultCode === "LANDING") {
        selectedFlight = _.find(this.state.takeOffReport.flights, {
          arrivalTime: faultTimestamp,
        });

        const sF = _.find(this.state.dropDownFlights, (t) => {
          return t.value.flightLeg === selectedFlight.flightLeg;
        });
        selectedFault = _.find(faultList, {
          faultCode: "LANDING",
          timestamp: faultTimestamp,
        });

        this.highlightChartSection(sF.label);
        const element = document.getElementById(selectedFault.id);

        element.scrollIntoView({ behavior: "smooth" });
        this.setState({
          selectedSessionFlight: sF,
        });
      } else if (faultCode.toLowerCase().includes("flight")) {
        selectedFlight = _.find(this.state.takeOffReport.flights, {
          departureTime: faultTimestamp,
        });
      }

      if (!selectedFlight) {
        resetInfoSelection = true;
      } else if (
        selectedFlight.departureTime ===
        (this.state.infoFlight
          ? this.state.infoFlight.departureTime
          : null) &&
        selectedFlight.arrivalTime ===
        (this.state.infoFlight ? this.state.infoFlight.arrivalTime : null)
      ) {
        let fCode = faultCode.replace(/\s+/g, "-").toUpperCase();

        // if (faultCode === "ARRIVAL") {
        //   fCode = "LANDING";
        // } else if (faultCode === "TAKEOFF") {
        //   fCode = "TAKEOFF";
        // }

        selectedFault = _.find(faultList, {
          faultCode: fCode,
          timestamp: faultTimestamp,
        });

        selectedFlight = this.findLatestFlightFromFault(faultTimestamp);

        const element = document.getElementById(selectedFault.id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        resetInfoSelection = true;
      }
    } else {
      if (
        (dataSeries === "CMS" &&
          (faultCode === "TAKEOFF" || faultCode === "LANDING")) ||
        selectedFaultMessageTab === "EIFR"
      ) {
        resetInfoSelection = true;
      }

      selectedFault = _.find(faultList, {
        faultCode: faultCode,
        timestamp: faultTimestamp,
      });

      if (selectedFault) {
        if (
          selectedFault.faultCode ===
          (infoFault ? infoFault.faultCode : null) &&
          selectedFault.faultTimestamp ===
          (infoFault ? infoFault.faultTimestamp : null)
        ) {
          resetInfoSelection = true;
        } else {
          selectedFlight = this.findLatestFlightFromFault(faultTimestamp);
          const element = document.getElementById(selectedFault.id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
          selectedFaultMessageTab = this.state.selectedFaultMessageTab;
        }
      }
    }

    if (resetInfoSelection) {
      dataSeries = "FLIGHT";
      selectedFault = null;
      selectedFlight = this.state.latestFlight;
    } else {
      if (
        selectedFlight !== undefined &&
        selectedFlight !== this.state.latestFlight
      ) {
        await this.setLatestFlight(selectedFlight);
      }
    }
    this.setState((prevState) => ({
      infoBoxType: dataSeries,
      infoFlight: selectedFlight,
      fdeInfoFault:
        this.state.selectedFaultMessageTab === "FDECAS" ||
          this.state.selectedFaultMessageTab === "OMS"
          ? selectedFault
          : null,
      cmsInfoFault:
        this.state.selectedFaultMessageTab === "CMS" ? selectedFault : null,
      omsInfoFault: null,
      fdeFilterText: {
        ...prevState.fdeFilterText,
        faultCode: "",
        faultMessage: "",
      },
      omsFilterText: {
        ...prevState.omsFilterText,
        faultCode: "",
        faultMessage: "",
      },
      cmsFilterText: {
        ...prevState.cmsFilterText,
        faultCode: "",
        faultMessage: "",
      },
      selectedFaultMessageTab: selectedFaultMessageTab,
    }));
  }

  // This code handles the fault selection clicks on the fault message tables
  async handleFaultCorrelationClick(
    faultCode,
    faultTimestamp,
    faultSeverity,
    id
  ) {
    const chartId = "flight_lollipop";
    let selectedFault = _.find(this.state.fdecasList, {
      id: id,
    });

    let infoBoxType = "FLIGHT";
    if (selectedFault) {
      if (faultCode !== "TAKEOFF" && faultCode !== "LANDING") {
        infoBoxType =
          faultSeverity === "N" || faultSeverity === "S" ? "INFO" : faultCode;
      }

      let latestFlight = this.state.latestFlight;

      const flight = await this.findLatestFlightFromFault(faultTimestamp);
      if (flight !== undefined && flight !== latestFlight) {
        latestFlight = flight;

        await this.setLatestFlight(latestFlight);
      }

      const zoomBuffer = 30000;
      let zoomStartTimestamp = null;
      let zoomEndTimestamp = null;

      if (
        selectedFault.faultCode ===
        (this.state.fdeInfoFault
          ? this.state.fdeInfoFault.faultCode
          : null) &&
        selectedFault.faultTimestamp ===
        (this.state.fdeInfoFault
          ? this.state.fdeInfoFault.faultTimestamp
          : null)
      ) {
        this.handleFaultDeselect();
      } else {
        latestFlight = this.findLatestFlightFromFault(faultTimestamp);
        if (latestFlight === undefined) {
          latestFlight = this.state.latestFlight;
        }
        zoomStartTimestamp = moment.utc(faultTimestamp).valueOf() - zoomBuffer;
        zoomEndTimestamp = moment.utc(faultTimestamp).valueOf() + zoomBuffer;

        this.setState((prevState) => ({
          infoBoxType: infoBoxType,
          infoFlight: latestFlight,
          fdeInfoFault: selectedFault,
          omsInfoFault: null,
          cmsInfoFault: null,
          selectedMdcRowData: {},
          fdeFilterText: {
            ...prevState.fdeFilterText,
            faultCode: "",
            faultMessage: "",
          },
          omsFilterText: {
            ...prevState.omsFilterText,
            faultCode: "",
            faultMessage: "",
          },
          cmsFilterText: {
            ...prevState.cmsFilterText,
            faultCode: "",
            faultMessage: "",
          },
        }));
        ApexCharts.exec(chartId, "zoomX", zoomStartTimestamp, zoomEndTimestamp);
      }
    }
  }

  handleOmsRowClick(faultCode, faultTimestamp) {
    const chartId = "flight_lollipop";
    let selectedFault = _.find(this.state.omsList, {
      faultCode: faultCode,
      faultTimestamp: faultTimestamp,
    });

    if (selectedFault) {
      let infoBoxType = "INFO";
      let latestFlight = this.state.latestFlight;
      let zoomStartTimestamp = null;
      let zoomEndTimestamp = null;

      if (
        selectedFault.faultCode ===
        (this.state.omsInfoFault
          ? this.state.omsInfoFault.faultCode
          : null) &&
        selectedFault.faultTimestamp ===
        (this.state.omsInfoFault
          ? this.state.omsInfoFault.faultTimestamp
          : null)
      ) {
        this.handleFaultDeselect();
      } else {
        latestFlight = this.findLatestFlightFromFault(faultTimestamp);
        this.setState((prevState) => ({
          infoBoxType: infoBoxType,
          infoFlight: latestFlight,
          fdeInfoFault: null,
          cmsInfoFault: null,
          omsInfoFault: selectedFault,
          fdeFilterText: {
            ...prevState.fdeFilterText,
            faultCode: "",
            faultMessage: "",
          },
          omsFilterText: {
            ...prevState.omsFilterText,
            faultCode: "",
            faultMessage: "",
          },
          cmsFilterText: {
            ...prevState.cmsFilterText,
            faultCode: "",
            faultMessage: "",
          },
        }));

        ApexCharts.exec(chartId, "zoomX", zoomStartTimestamp, zoomEndTimestamp);
      }
    }
  }

  handleCmsRowClick(faultCode, faultTimestamp) {
    const chartId = "flight_lollipop";
    let zoomStartTimestamp = null;
    let zoomEndTimestamp = null;

    let selectedFault = _.find(this.state.cmsList, {
      faultCode: faultCode,
      faultTimestamp: faultTimestamp,
    });

    let infoBoxType = "CMS";
    let latestFlight = this.state.latestFlight;

    if (selectedFault) {
      if (
        selectedFault.faultCode ===
        (this.state.cmsInfoFault
          ? this.state.cmsInfoFault.faultCode
          : null) &&
        selectedFault.faultTimestamp ===
        (this.state.cmsInfoFault
          ? this.state.cmsInfoFault.faultTimestamp
          : null)
      ) {
        this.handleFaultDeselect();
      } else {
        const zoomBuffer = 30000;
        latestFlight = this.findLatestFlightFromFault(faultTimestamp);
        zoomStartTimestamp = moment.utc(faultTimestamp).valueOf() - zoomBuffer;
        zoomEndTimestamp = moment.utc(faultTimestamp).valueOf() + zoomBuffer;

        this.setState((prevState) => ({
          infoBoxType: infoBoxType,
          infoFlight: latestFlight,
          fdeInfoFault: null,
          omsInfoFault: null,
          cmsInfoFault: selectedFault,
          fdeFilterText: {
            ...prevState.fdeFilterText,
            faultCode: "",
            faultMessage: "",
          },
          omsFilterText: {
            ...prevState.omsFilterText,
            faultCode: "",
            faultMessage: "",
          },
          cmsFilterText: {
            ...prevState.cmsFilterText,
            faultCode: "",
            faultMessage: "",
          },
        }));

        ApexCharts.exec(chartId, "zoomX", zoomStartTimestamp, zoomEndTimestamp);
      }
    }
  }

  handleFaultDeselect() {
    this.setState((prevState) => ({
      infoBoxType: "FLIGHT",
      infoFlight: this.state.latestFlight,
      fdeInfoFault: null,
      omsInfoFault: null,
      cmsInfoFault: null,
      fdeFilterText: {
        ...prevState.fdeFilterText,
        faultCode: "",
        faultMessage: "",
      },
      omsFilterText: {
        ...prevState.omsFilterText,
        faultCode: "",
        faultMessage: "",
      },
      cmsFilterText: {
        ...prevState.cmsFilterText,
        faultCode: "",
        faultMessage: "",
      },
    }));

    const chartId = "flight_lollipop";
    ApexCharts.exec(chartId, "zoomX", null, null);
  }

  findLatestFlightFromFault(faultTimestamp) {
    let filteredFlights;
    if (this.state.takeOffReport.flights.length > 1) {
      filteredFlights = _.filter(
        this.state.takeOffReport.flights,
        function (flight) {
          return flight.departureTime <= faultTimestamp;
        }
      );
    } else {
      filteredFlights = this.state.takeOffReport.flights;
    }

    const latestFlight = _.orderBy(
      filteredFlights,
      ["departureTime"],
      ["desc"]
    )[0];
    return latestFlight;
  }

  handleSeriesToggleEvent(dataSeries, toggleState) {
    if (dataSeries === "RCOMMON") {
      this.setState({
        reduceCommonFaultsSeriesToggle: toggleState,
      });
    } else if (dataSeries === "STATUS") {
      this.setState({
        statusSeriesToggle: toggleState,
      });
    } else {
      this.setState({
        infoSeriesToggle: toggleState,
      });
    }
  }

  handleSwitchChange(newStatus) {
    try {
      this.setState(
        {
          monitorStatusDisable: true,
        },
        async () => {
          await updateAircraftMonitoring(this.state.tail, newStatus);
        }
      );
    } catch (error) {
      newStatus = !newStatus;
      this.openNotificationModal({
        errorCode: "ER-AC-00004",
      });
    }
    this.setState(
      {
        monitorStatus: newStatus,
        monitorStatusDisable: false,
      },
      () => {
        localStorage.setItem("tmpMonitorStatus", newStatus);
      }
    );
  }

  handleFdeFilterChange(type, text) {
    if (type === "CODE") {
      this.setState((prevState) => ({
        fdeFilterText: {
          ...prevState.fdeFilterText,
          faultCode: text,
        },
      }));
    } else {
      this.setState((prevState) => ({
        fdeFilterText: {
          ...prevState.fdeFilterText,
          faultMessage: text,
        },
      }));
    }
  }

  handleOmsFilterChange(type, text) {
    if (type === "CODE") {
      this.setState((prevState) => ({
        omsFilterText: {
          ...prevState.omsFilterText,
          faultCode: text,
        },
      }));
    } else {
      this.setState((prevState) => ({
        omsFilterText: {
          ...prevState.omsFilterText,
          faultMessage: text,
        },
      }));
    }
  }

  handleCmsFilterChange(type, text) {
    if (type === "CODE") {
      this.setState((prevState) => ({
        cmsFilterText: {
          ...prevState.cmsFilterText,
          faultCode: text,
        },
      }));
    } else {
      this.setState((prevState) => ({
        cmsFilterText: {
          ...prevState.cmsFilterText,
          faultMessage: text,
        },
      }));
    }
  }

  handleFaultMessageTabChange(tab) {
    const currentSelectedTab = this.state.selectedFaultMessageTab;

    this.setState(
      {
        selectedFaultMessageTab: tab,
        cmsList: this.state.cmsListCopy,
        cmsListFilter: "all",
      },
      () => {
        this.validFaultDeselectCombination(currentSelectedTab, tab) &&
          this.handleFaultDeselect();
      }
    );
  }

  validFaultDeselectCombination(currentSelectedTab, tab) {
    if (
      (currentSelectedTab === "CMS" && tab !== "CMS") ||
      (currentSelectedTab === "EIFR" && tab !== "EIFR")
    ) {
      return true;
    }
    return false;
  }

  handleSFPLink(link) {
    let faultCode = null;
    if (this.state.fdeInfoFault) {
      faultCode = this.state.fdeInfoFault.faultCode;
    } else if (this.state.omsInfoFault) {
      faultCode = this.state.omsInfoFault.faultCode;
    } else if (this.state.cmsInfoFault) {
      faultCode = this.state.cmsInfoFault.faultCode;
    }
    sendSmartFixClickEvent();

    window.open(link, "_blank", "noopener,noreferrer");
  }

  hanldeReduceCommonFaultClick(faultCode, reduceCommonStatus) {
    this.setState({
      confirmModalState: true,
      reduceCommonFaultEditData: {
        faultCode: faultCode,
        reduceStatus: reduceCommonStatus,
      },
    });
    this.openConfirmationModal();
  }

  handleNoSessionStartProceed() {
    this.setState({
      isOpenHistoricDataModal: true,
    });
  }

  openConfirmationModal() {
    this.setState({
      confirmModalState: true,
    });
  }

  closeConfirmationModal() {
    this.setState({
      confirmModalState: false,
      flightCycleEditData: null,
    });
  }

  async submitOKConfirmationModal() {
    this.setState({
      loadAircraftPageInfo: true,
    });
    const faultCodeToEdit = this.state.reduceCommonFaultEditData.faultCode;
    const statusToEdit = this.state.reduceCommonFaultEditData.reduceStatus;
    const cleanFaultList = [
      {
        faultCode: faultCodeToEdit,
        reduceStatus: statusToEdit,
      },
    ];

    postCommonFDEList(
      cleanFaultList,
      this.state.aircraftFamily,
      this.state.aircraftModel
    )
      .then(async (res) => {
        this.closeConfirmationModal();
        this.handleAircraftPageRefresh();
      })
      .catch((error) => {
        this.closeConfirmationModal();
        console.error(`Error : ${error}`);
      });
  }

  async handleAircraftPageRefresh() {
    localStorage.removeItem("tmpACSessionStartDate");
    localStorage.removeItem("tmpACSessionEndDate");
    localStorage.removeItem("tmpACSession");

    await this.setState((prevState) => ({
      viewType: "IFR",
      infoBoxType: "FLIGHT",
      fdeInfoFault: null,
      omsInfoFault: null,
      cmsInfoFault: null,
      infoSeriesToggle: true,
      statusSeriesToggle: true,
      reduceCommonFaultsSeriesToggle: false,
      flightHistorySessionStartTime: null,
      flightHistorySessionEndTime: null,
      flightHistorySession: null,
      selectedFaultMessageTab: "FDECAS",
      recentSessionOrder: 0,
      fdeFilterText: {
        ...prevState.fdeFilterText,
        faultCode: "",
        faultMessage: "",
      },
      omsFilterText: {
        ...prevState.omsFilterText,
        faultCode: "",
        faultMessage: "",
      },
      cmsFilterText: {
        ...prevState.cmsFilterText,
        faultCode: "",
        faultMessage: "",
      },
    }));

    this.loadAircraftPageInfo(null, null, null, null);
  }

  openNotificationModal({
    errorColorCode,
    errorDescription,
    alternativeExistLink,
    errorCode,
    errorType,
  }) {
    this.setState({
      errorColorCode: errorColorCode,
      errorDescription: errorDescription,
      alternativeExistLink: alternativeExistLink,
      notifModalState: true,
      errorCode: errorCode,
      errorType: errorType,
    });
  }

  closeNotificationModal = () => {
    this.setState({
      notifModalState: false,
      alternativeExistLink: "",
      errorColorCode: "",
      errorDescription: "",
      errorCode: "ER-AC-00000",
      errorType: "",
      technical_detail: "",
      errorObject: null,
    });
  };

  warningNoSessionStart() {
    this.setLatestFlight(undefined);
    this.setState({
      fdecasList: [],
      omsList: [],
      cmsList: [],
      infoFlight: undefined,
      loading: false,
      loadingFaultEventCount: false,
    });

    this.openNotificationModal({
      errorCode: "ER-AC-00006",
    });
  }
}
