import React, { useEffect, useState } from "react";
import { breadcrumbService } from "./BreadcrumbService";
import { Breadcrumbs, Link } from "@mui/material";
import "./Breadcrumb.css";

/**
 * The breadcrumb component.
 * @param crumbItem Crumb to be added to the breadcrumb.
 * @param display Flag
 * @return {JSX.Element}
 * @constructor
 */
export default function Breadcrumb({ crumbItem, display = true, urlPath }) {
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [displayBreadcrumb, setDisplayBreadcrumb] = useState(false);
  const [severityColor, setSeverityColor] = useState("#FFF");
  const [isFault, setIsFault] = useState(false);
  const [faultCode, setFaultCode] = useState("");
  const [faultMessage, setFaultMessage] = useState("");
  const [faultDate, setFaultDate] = useState("");

  let isFromUrlNavigation = sessionStorage.getItem("isFromUrlNavigation");

  useEffect(() => {
    // Subscribe to the breadcrumb service to update the component.
    const subscription = breadcrumbService
      .getBreadcrumb()
      .subscribe((breadcrumbObs) => {
        setBreadcrumb(breadcrumbObs);
      });

    return () => subscription.unsubscribe();
  }, []);

  /**
   * Adds the crumb to the breadcrumb service.
   */
  useEffect(() => {
    if (crumbItem) {
      breadcrumbService.addCrumb(crumbItem);
      if (crumbItem.isFault) {
        setIsFault(crumbItem.isFault);
        setFaultCode(crumbItem.faultCode);
        setFaultMessage(crumbItem.faultMessage);
        setFaultDate(crumbItem.faultDate);
        setSeverityColor(crumbItem.severityColor);
      }
    }
  }, [crumbItem]);

  useEffect(() => {
    setDisplayBreadcrumb(display);
  }, [display]);

  /**
   * Handles the rendering of a fault crumb.
   * @return {JSX.Element}
   */
  const renderFaultCrumb = () => {
    return (
      <>
        <span
          className="dot"
          style={{
            backgroundColor: isFault ? severityColor : "FFF",
          }}
        ></span>
        <span
          className="faultCode"
          style={{
            color: isFault ? severityColor : "FFF",
          }}
        >
          {" "}
          {faultCode !== faultMessage ? faultCode : ""}{" "}
        </span>
        {faultMessage} - {faultDate}
      </>
    );
  };

  return (
    <>
      {displayBreadcrumb ? (
        <Breadcrumbs>
          {breadcrumb.map((crumb, i, breadcrumb) => (
            <Link
              href={
                crumb.value !== "Home" && isFromUrlNavigation
                  ? urlPath
                  : crumb.view
              }
              key={"appBreadCrumb_" + i}
              className={`breadcrumb__item ${
                i + 1 === breadcrumb.length ? "breadcrumb__item__bold" : ""
              }`}
              color={"#FFF"}
            >
              {crumb.isFault ? renderFaultCrumb() : crumb.value}
            </Link>
          ))}
        </Breadcrumbs>
      ) : null}
    </>
  );
}
