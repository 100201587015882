import React from "react";
import { styled } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";

// Inspired by the former Facebook spinners.
const DivRoot = styled("div")(({ theme }) => ({
  position: "relative",
}));

const CircularProgressBottom = styled(CircularProgress)(({ theme }) => ({
  color: "#ffc72c",
}));

const CircularProgressTop = styled(CircularProgress)(({ theme }) => ({
  color: "#606060",
  animationDuration: "550ms",
  position: "absolute",
  left: 0,
}));

function FacebookCircularProgress(props) {
  return (
    <DivRoot id="spinner">
      <CircularProgressBottom
        variant="determinate"
        size={90}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgressTop
        variant="indeterminate"
        disableShrink
        size={90}
        thickness={5}
        {...props}
      />
    </DivRoot>
  );
}

const Container = styled("div")({
  flexGrow: 1,
});

export default function CustomizedProgressBars() {

  return (
    <Container>
      <FacebookCircularProgress />
    </Container>
  );
}
