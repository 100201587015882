import React, { Component } from "react";
import * as tools from "../utils/CommonTools";
import FeatherIcon from "feather-icons-react";
import ContentTOReport from "./ContentTOReport";
import ContentInfoMessage from "./ContentInfoMessage";
import ContentFDEFault from "./ContentFDEFault";
import ContentMdcReport from "./ContentMdcReport";
import "./FlightInfoBox.css";
import * as featureToggleTools from "../Feature Toggles/FeatureToggleTools";

export default class FlightInfoBox extends Component {
  constructor(props) {
    super(props);
    this.handleFlightInfoHeader = this.handleFlightInfoHeader.bind(this);
    this.handleFlightInfoContent = this.handleFlightInfoContent.bind(this);
    this.hanldeReduceCommonFaultClick = this.hanldeReduceCommonFaultClick.bind(
      this
    );
  }

  render() {
    return (
      <div
        className="flex-column-simple flight-info-paddings"
        id="flightInfoBox"
      >
        {featureToggleTools.isFeatureToggleActive("ft-mdc-fmh-smh") &&
        this.props.activeTab === "MDC"
          ? this.handleFlightInfoHeader("MDC")
          : this.handleFlightInfoHeader(this.props.infoBoxType)}
        {featureToggleTools.isFeatureToggleActive("ft-mdc-fmh-smh") &&
        this.props.activeTab === "MDC"
          ? this.handleFlightInfoContent("MDC")
          : this.handleFlightInfoContent(this.props.infoBoxType)}
      </div>
    );
  }

  handleFlightInfoHeader(infoBoxType) {
    let infoTypeHeader = "";
    let infoTypeHeaderSubtitle = <div></div>;

    if (infoBoxType === "CMS" && !this.props.cmsFault) {
      infoBoxType = "FLIGHT";
    }

    if (infoBoxType === "FLIGHT") {
      infoTypeHeader = "Take off report";
    } else {
      if (infoBoxType === "INFO" || infoBoxType === "STATUS") {
        if (this.props.omsFault) {
          infoTypeHeader =
            this.props.aircraftUIConfig?.aircraftViewPage?.mdcTabLabel +
            ": " +
            this.props.omsFault?.faultCode;
        } else {
          infoTypeHeader =
            (this.props.fault?.faultSeverity === "S"
              ? "Status"
              : "Information") +
            " Message: " +
            this.props.fdeFault?.faultCode;
        }
      } else if (infoBoxType === "CMS") {
        infoTypeHeader = "CMS: " + this.props.cmsFault?.faultCode;
      } else if (infoBoxType === "MDC") {
        infoTypeHeader = "MDC report";
      } else {
        infoTypeHeader =
          (this.props.fault?.faultSeverity === "W"
            ? "Warning"
            : this.props.fault?.faultSeverity === "C"
            ? "Caution"
            : this.props.fault?.faultSeverity === "A"
            ? "Advisory"
            : "FDE") +
          " Message: " +
          this.props.fdeFault?.faultCode;
      }
      // Adding the fault cleared status.
      if (
        infoBoxType !== "CMS" &&
        infoBoxType !== "INFO" &&
        infoBoxType !== "MDC" &&
        !this.props.aircraftUIConfig?.aircraftViewPage?.hideClearedFaults
      ) {
        infoTypeHeaderSubtitle =
          this.props.fdeFault?.faultSeverity !== "A" ? (
            <div className="flight-info-subtitle-content">
              <span style={{ marginRight: "8px" }}>Status:</span>
              {!this.props.omsFault ? (
                this.props.fdeFault?.faultCleared ? (
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span className="flight-info-cleared-font">Cleared</span>
                    <FeatherIcon
                      icon={"check"}
                      className={"flight-info-cleared-icon"}
                    />
                    <span>
                      {this.props.fdeFault?.clearedTimestamp
                        ? tools.formatDate(this.props.fdeFault.clearedTimestamp)
                        : ""}
                    </span>
                  </span>
                ) : (
                  <span>Not Cleared</span>
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          );
      }
    }

    const flightInfoHeader = (
      <div className="flex-row-simple flight-info-header flight-info-header-font">
        <div className="flex-column-simple flight-info-header-title flight-info-header-title-font">
          {infoTypeHeader}
        </div>
        <div className="flex-column-simple flight-info-header-subtitle">
          <div className="flex-row-simple">
            {infoTypeHeaderSubtitle}
            {infoBoxType !== "FLIGHT" && this.props.fdeFault ? (
              <div
                className="flight-info-reduce-common-button"
                onClick={this.hanldeReduceCommonFaultClick}
              >
                {this.props.fdeFault?.reduceCommonFaultStatus ? (
                  <FeatherIcon icon={"eye-off"} height={18} color={"#FFFFFF"} />
                ) : (
                  <FeatherIcon icon={"eye"} height={18} color={"#FFFFFF"} />
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
    return flightInfoHeader;
  }

  handleFlightInfoContent(infoBoxType) {
    if (infoBoxType === "CMS" && !this.props.cmsFault) {
      infoBoxType = "FLIGHT";
    }

    let flightInfoContent = <div />;
    if (infoBoxType === "FLIGHT") {
      flightInfoContent = (
        <div className="flex-row-simple flight-info-body flight-info-body-font">
          {this.props.flight ? (
            <ContentTOReport
              aircraftFamily={this.props.aircraftFamily}
              aircraftModel={this.props.aircraftModel}
              flight={this.props.flight}
            />
          ) : (
            <ContentTOReport
              aircraftFamily={this.props.aircraftFamily}
              aircraftModel={this.props.aircraftModel}
              flight={null}
            />
          )}
        </div>
      );
    } else if (infoBoxType === "MDC") {
      flightInfoContent = (
        <div className="flex-row-simple flight-info-body flight-info-body-font">
          <ContentMdcReport
            selectedMdcRowData={this.props.selectedMdcRowData}
          />
        </div>
      );
    } else if (infoBoxType === "INFO" || infoBoxType === "STATUS") {
      let faultInfo;
      let faultType;

      // OMS Messages
      if (this.props.omsFault !== null) {
        faultInfo = this.props.omsFault;
        faultType = "OMS";

        flightInfoContent = (
          <div className="flex-row-simple flight-info-body flight-info-body-font">
            <ContentInfoMessage
              faultType={faultType}
              faultTimestamp={faultInfo?.faultTimestamp}
              faultCode={faultInfo?.faultCode}
              faultMessage={faultInfo?.faultMessage}
              flightPhase={faultInfo?.flightPhase}
              faultDescription={faultInfo?.faultDescription}
              faultEventCountData={this.props.faultEventCountData}
              fault={this.props.fault}
              loadingFaultEventCount={this.props.loadingFaultEventCount}
            />
          </div>
        );
      } else {
        faultInfo = this.props.fdeFault;
        faultType = "FDE";

        //When infoBoxType is "Info", tab view needs to be shown. ContentFDEFault component does it for now
        //It should be its own custom component eventually
        flightInfoContent = (
          <div className="flex-row-simple flight-info-body flight-info-body-font">
            <ContentFDEFault
              aircraftUIConfig={this.props.aircraftUIConfig}
              altitude={faultInfo?.altitude}
              airSpeed={faultInfo?.airSpeed}
              departureTime={
                this.props.flight ? this.props.flight?.departureTime : null
              }
              fault={faultInfo}
              infoBoxType={infoBoxType}
              faultEventCountData={this.props.faultEventCountData}
              faultDescription={faultInfo?.faultDescription}
              faultTimestamp={faultInfo?.faultTimestamp}
              flightPhase={faultInfo?.flightPhase}
              omsMessages={faultInfo?.omsMessages}
              outsideTemp={faultInfo?.outsideTemp}
              viewType={this.props.viewType}
              loadingFaultEventCount={this.props.loadingFaultEventCount}
            />
          </div>
        );
      }
    } else if (infoBoxType === "CMS") {
      let faultInfo = this.props.cmsFault;
      flightInfoContent = (
        <div className="flex-row-simple flight-info-body flight-info-body-font">
          <ContentInfoMessage
            faultType="CMS"
            LRUSourceId={faultInfo?.LRUSourceId}
            faultCodeActual={faultInfo?.faultCodeActual}
            faultStatusDescription={faultInfo?.faultStatusDescription}
            faultStatusAction={faultInfo?.faultStatusAction}
            faultTimestamp={faultInfo?.faultTimestamp}
            faultCode={faultInfo?.faultCode}
            faultSystem={faultInfo?.faultSystem}
            faultSystemId={faultInfo?.faultSystemId}
            faultMessage={faultInfo?.faultMessage}
            flightPhase="INFLIGHT"
            faultDescription={faultInfo?.faultDescription}
            faultEventCountData={this.props.faultEventCountData}
            fault={this.props.fault}
            loadingFaultEventCount={this.props.loadingFaultEventCount}
          />
        </div>
      );
    } else {
      flightInfoContent = (
        <div className="flex-row-simple flight-info-body flight-info-body-font">
          <ContentFDEFault
            aircraftUIConfig={this.props.aircraftUIConfig}
            altitude={this.props.fdeFault?.altitude}
            airSpeed={this.props.fdeFault?.airSpeed}
            departureTime={
              this.props.flight ? this.props.flight?.departureTime : null
            }
            fault={this.props.fault}
            faultEventCountData={this.props.faultEventCountData}
            faultDescription={this.props.fdeFault?.faultDescription}
            faultTimestamp={this.props.fdeFault?.faultTimestamp}
            flightPhase={this.props.fdeFault?.flightPhase}
            omsMessages={this.props.fdeFault?.omsMessages}
            outsideTemp={this.props.fdeFault?.outsideTemp}
            viewType={this.props.viewType}
            loadingFaultEventCount={this.props.loadingFaultEventCount}
          />
        </div>
      );
    }
    return flightInfoContent;
  }

  hanldeReduceCommonFaultClick() {
    this.props.hanldeReduceCommonFaultClick(
      this.props.fdeFault.faultCode,
      !this.props.fdeFault.reduceCommonFaultStatus
    );
  }
}
