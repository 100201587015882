import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import "../styles/Global Components/ToggleButton.css";

export default class ToggleButton extends Component {
  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);

    this.state = {
      cardId: this.props.id
        ? this.props.id
        : "BA_TOGGLE_ID_" + Math.floor(Math.random() * 90000),
    };
  }

  render() {
    const toggleLabel = this.props.label ? this.props.label : "-";

    return (
      <div
        id={this.state.cardId}
        className={
          "toggleButton" +
          " toggleButton__expanded-" +
          (this.props.expanded ? "true" : "false") +
          (this.props.disabled ? " toggleButton__disabled" : "") +
          (this.props.btnStyle ? " " + this.props.btnStyle : "") +
          (this.props.active
            ? this.props.btnStyle
              ? this.props.btnStyleActive
                ? " " + this.props.btnStyleActive
                : " toggleButton__customDefault__active"
              : " toggleButton__active"
            : "") +
          (this.props.expanded
            ? this.props.btnStyleExpanded
              ? " " + this.props.btnStyleExpanded
              : ""
            : "")
        }
        disabled={this.props.disabled}
        onClick={this.handleToggle}
      >
        <div
          className={
            "toggleButton__content" +
            (this.props.expanded ? " toggleButton__content__expanded" : "")
          }
        >
          {this.props.expanded ? (
            this.props.iconCustom ? (
              <div
                className={
                  "toggleButton__content__text" +
                  (this.props.expanded
                    ? " toggleButton__content__text__expanded"
                    : "")
                }
              >
                {this.props.iconCustom}
                <span>
                  {this.props.labelExpand
                    ? this.props.labelExpand
                    : toggleLabel}
                </span>
              </div>
            ) : (
              <span>
                {this.props.labelExpand ? this.props.labelExpand : toggleLabel}
              </span>
            )
          ) : this.props.iconCustom ? (
            this.props.iconCustom
          ) : this.props.featherIconName ? (
            <FeatherIcon
              icon={this.props.featherIconName}
              height={20}
              width={20}
              color={"#FFFFFF"}
            />
          ) : (
            <span>{toggleLabel}</span>
          )}
        </div>
      </div>
    );
  }

  handleToggle() {
    if (
      this.props.onToggleEvent !== null &&
      this.props.onToggleEvent !== undefined
    ) {
      if (!this.props.disabled) {
        this.props.onToggleEvent(this.state.cardId, !this.props.active);
      }
    }
  }
}
