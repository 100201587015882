import React from "react";
import PropTypes from "prop-types";
import "./NeedHelp.scss";

//Note that the children variable is auto set to be the children of the component (props.children)
const NeedHelp = ({ contactUsLink }) =>
  contactUsLink !== undefined ? (
    <p className="feedbackModalContact">
      Need help?
      <a
        className="feedbackModalContactLink opoLink"
        target="_blank"
        rel="noopener noreferrer"
        href={contactUsLink}
      >
        Contact Us
      </a>
    </p>
  ) : (
    <span></span>
  );

export default NeedHelp;

NeedHelp.propTypes = {
  contactUsLink: PropTypes.string,
};
