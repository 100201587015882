import React, { useState } from "react";
import "../styles/Global Components/NoticePopup.css";
import PropTypes from "prop-types";
import FeatherIcon from "feather-icons-react";
import SystemNotificationContent from "../Global Components/SystemNotificationContent.jsx";

export default function NoticePopup(props) {
  const [modalOpen,] = useState(props.open);

  const handleWindowScrollStatus = (isModalOpen) => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  if (modalOpen) {
    handleWindowScrollStatus(true);
  }

  const closeNotificationModal = () => {
    handleWindowScrollStatus(false);
    props.handleNoticeModal(false);
    localStorage.setItem(`viewedNotice-${props.id}`, new Date().getTime());
  };

  const replaceCustomTags = (rawText) => {
    let processedHTML = (
      <SystemNotificationContent
        originalContentString={rawText}
        notificationId={props.id}
      />
    );

    return processedHTML;
  };

  return (
    modalOpen && (
      <div
        className={
          "notice-modal-popup-open-" + (modalOpen === true ? "true" : "false")
        }
      >
        <div className="notice-modal-popup-screen">
          <div className={"notice-modal-popup notice-modal-popup-type-notice"}>
            <div className="notice-popup-body">
              <div className="notice-popup-body-top">
                <FeatherIcon
                  icon="minus"
                  height={35}
                  width={35}
                  onClick={() => closeNotificationModal()}
                  style={{
                    cursor: "pointer"
                  }}
                />
              </div>
              <div className="notice-popup-body-bottom">
                <div className="notice-popup-title">
                  <FeatherIcon
                    icon="alert-circle"
                    height={25}
                    width={25}
                    style={{
                      position: "relative",
                      marginRight: 25
                    }}
                  />
                  <div id="errorModalTitle">
                    {props.descriptionSituation}
                  </div>
                </div>
                <div className="notice-description">
                  <div>
                    {replaceCustomTags(props.description)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

NoticePopup.prototype = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  descriptionSituation: PropTypes.string,
};
