import { useEffect, useState } from "react";
import { getUserNotificationsPaginated } from "./NotificationsClient";

// TODO: This should be able to be used with any API
const usePullData = (pullFrequency) => {
  const [data, setData] = useState();

  const successfulFetch = (fetchData) => {
    setData(fetchData);
  };

  const failureFetch = (error) => {
    console.error("ERROR: ", error);
  };

  useEffect(() => {
    const fetchNotifications = () => {
      getUserNotificationsPaginated(1, 1, { notificationStatus: 'DISPATCHED_UNREAD' })
        .then((response) => {
          if (response && response.status === 200) {
            successfulFetch(response.data);
          } else {
            failureFetch("Notification Fetch");
          }
        })
        .catch((err) => {
          console.error("ERROR: ", err);
        });
    };

    const timer = setInterval(() => fetchNotifications(), pullFrequency); // Invoke in interval callback

    return () => {
      clearInterval(timer);
    };
  }, []);

  return [data];
};

export default usePullData;
