import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import React, { Fragment, useEffect } from "react";

const Node = ({
  updateCurrentLRU,
  node,
  onClick,
  treePosition,
  treeWidth,
}) => {
  const lruDims = {
    width: 155,
    height: 100
  }

  const toolTipWidth = 275;
  const { tooltipOpen, showTooltip, hideTooltip, tooltipData } = useTooltip();

  const { TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const lruTooltip = <React.Fragment>Likely Impacted LRUs</React.Fragment>
  const similarityScoretooltip = <React.Fragment><b>Similarity Score:</b> Score quantifying how closely the anomalous parameters match LRUs and failure modes</React.Fragment>;
  const failureModeTooltip = <React.Fragment><b>Failure Mode:</b> Related to selected LRU</React.Fragment>;

  const handleMouseOver = (event, datum) => {
    if (datum === "lru") {
      showTooltip({
        tooltipLeft: treePosition.left + treePosition.width - toolTipWidth,
        tooltipTop: treePosition.top,
        tooltipData: lruTooltip
      });
    } else if (datum === "similarity") {
      showTooltip({
        tooltipLeft: treePosition.left + treePosition.width - toolTipWidth,
        tooltipTop: treePosition.top,
        tooltipData: similarityScoretooltip
      });
    } else {
      showTooltip({
        tooltipLeft: treePosition.left + treePosition.width - toolTipWidth,
        tooltipTop: treePosition.top,
        tooltipData: failureModeTooltip
      });
    }
  };

  useEffect(() => {
    // Show LRUs on load
    if (node.depth === 0 && !node.data.isExpanded) onClick(node);

    if (node.data.isExpanded) {
      if (node.depth === 1) updateCurrentLRU(node);
    }

    // Expand first LRU on load
    if (node.data.first) {
      if (!node.data.isExpanded) onClick(node);

      delete node.data.first;
      if (node.depth === 1) updateCurrentLRU(node);
    };
  }, []);

  return (
    <Fragment>
      {node.depth !== 0 && (
        <>
          <rect
            height={lruDims.height}
            width={lruDims.width}
            y={-lruDims.height / 2}
            x={-lruDims.width / 2}
            fill={"#101010"}
            stroke={"white"}
            strokeWidth={node.data.isExpanded ? 9 : 4}
            strokeDasharray={0}
            strokeOpacity={node.data.isExpanded ? 1 : .5}
            rx={0}
            onClick={onClick}
          />
          <rect
            height={lruDims.height - 5}
            width={lruDims.width - 5}
            y={-(lruDims.height - 5) / 2}
            x={-(lruDims.width - 5) / 2}
            fill={"#202020"}
            stroke={"black"}
            strokeWidth={node.data.isExpanded ? 5 : 3}
            strokeDasharray={0}
            strokeOpacity={node.data.isExpanded ? 1 : .5}
            rx={0}
            onClick={onClick}
          />
          <rect
            height={(lruDims.height) - 10}
            width={(lruDims.width) - 10}
            y={-((lruDims.height) - 10) / 2}
            x={-((lruDims.width) - 10) / 2}
            fill={"#343434"}
            rx={0}
            strokeOpacity={node.data.isExpanded ? 1 : .5}
            onClick={onClick}
          />
        </>
      )}
      {node.depth === 1 && (
        <>
          <foreignObject
            x={-(lruDims.width - 10) / 2}
            y={-(lruDims.height - 10) / 2}
            width={lruDims.width - 10}
            height={lruDims.height - 10}
            onClick={onClick}
            opacity={node.data.isExpanded ? 1 : .5}
            fontFamily={`'DM Sans', sans-serif`}
            fontSize={14}
            fontWeight={(node.data.isExpanded) ? 500 : 400}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexFlow: "column",
                justifyContent: "center",
                textAlign: "center",
                padding: 3
              }}
            >
              <div>
                <p>{node.data.name}</p>
              </div>
            </div>
          </foreignObject>
        </>
      )}
    </Fragment>
  );
};

export default Node;