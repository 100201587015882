import React, { Component } from "react";
import CustomCheckbox from "../Global Components/CustomCheckbox.jsx";
import "./ConfigCommonFaultRow.css";

export default class ConfigCommonFaultRow extends Component {
  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  render() {
    return (
      <div
        id={this.props.id}
        className={
          "configCommonFaultRow" +
          (this.props.rowIsSubtle ? " configCommonFaultRow__subtleRow" : "") +
          (!this.props.rowIsFiltered
            ? " configCommonFaultRow__invisibleRow"
            : "")
        }
      >
        <div
          className={
            "configCommonFaultRow__sectionCategory configCommonFaultRow__font " +
            this.calculateFaultSeverity(this.props.fdeCategory)
          }
        >
          <div
            className={
              "configCommonFaultRow__sectionCategory__indicator fill-" +
              this.calculateFaultSeverity(this.props.fdeCategory)
            }
          ></div>
          {this.props.fdeCategory}
        </div>
        <div className="configCommonFaultRow__sectionAta configCommonFaultRow__font">
          {this.props.ataChapter}
        </div>
        <div
          className={
            "configCommonFaultRow__sectionCode configCommonFaultList__header__font" +
            (this.props.faultFamilyModelRules
              ? !this.props.faultFamilyModelRules.faultCode
                ? " configCommonFaultRow__sectionCode-hideFaultCode"
                : ""
              : "")
          }
        >
          {this.props.faultCode}
        </div>
        <div
          className={
            "configCommonFaultRow__sectionName configCommonFaultList__header__font" +
            (this.props.faultFamilyModelRules
              ? !this.props.faultFamilyModelRules.faultCode
                ? " configCommonFaultRow__sectionName-hideFaultCode"
                : ""
              : "")
          }
        >
          {this.props.faultMessage}
        </div>
        <div className="configCommonFaultRow__sectionStatusFilter configCommonFaultRow__font">
          {this.props.reduceStatus ? "Off" : "On"}
        </div>
        <div className="configCommonFaultRow__sectionEdit configCommonFaultRow__font">
          <CustomCheckbox
            isChecked={!this.props.reduceStatus}
            onChangeAction={this.handleEditClick}
          />
        </div>
      </div>
    );
  }

  calculateFaultSeverity(severity) {
    severity = severity.toString().toUpperCase();
    let color;
    if (severity === "ADVISORY") {
      color = "advisory";
    } else if (severity === "CAUTION") {
      color = "caution";
    } else if (severity === "WARNING") {
      color = "warning";
    } else if (severity === "STATUS") {
      color = "status";
    } else if (severity === "INFO") {
      color = "info";
    } else {
      color = "none";
    }
    return color;
  }

  handleEditClick() {
    this.props.handleEditCommonFaultClick(
      this.props.faultCode,
      !this.props.reduceStatus
    );
  }
}
