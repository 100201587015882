import React, { useState, useEffect } from "react";
import _ from "lodash";
import "./EventCountModal.css";
import FeatherIcon from "feather-icons-react";
import {
  DropdownListFamilyEventCountVersion,
  DropdownEventTypeVersion,
  DropdownTailsVersion,
  DropdownEventCodesVersion,
} from "../Global Components/Dropdown.jsx";
import DatePickerEventCount from "./DatePickerEventCount";
import CustomButton from "../Global Components/CustomButton";
import SessionEventCountTable from "./SessionEventCountTable";
import {
  getEventList,
  getFaultCountsByAircraft,
} from "../clients/FaultsClient";
import * as tools from "../utils/CommonTools";
import Spinner from "../Global Components/Spinner.js";
import moment from "moment";

const TODAY = new Date();
const PRIOR_DATE = new Date(new Date().setDate(TODAY.getDate() - 30));

const EventCountModal = ({
  toggleModal,
  modalId,
  tailList,
  familyModelList,
  aircraftUIConfig,
  preselectedSerialNumber,
  preselectedRegistrationNumber,
  preselectedAircraftFamily,
  preselectedAircraftModel,
  fdeInfoFault,
  omsInfoFault,
  cmsInfoFault,
}) => {
  const [familyModelOptions, setFamilyModelOptions] = useState([]);
  const [selectedFamilyModel, setSelectedFamilyModel] = useState({
    label: preselectedAircraftFamily + " " + preselectedAircraftModel,
    value: preselectedAircraftFamily + "-" + preselectedAircraftModel,
  });
  const [startDate, setStartDate] = useState(PRIOR_DATE);
  const [endDate, setEndDate] = useState(new Date());
  const [selectedEventType, setSelectedEventType] = useState({
    value: "",
    label: "",
  });
  const [selectedTail, setSelectedTail] = useState("");
  const [faultCountsByAircraft, setFaultCountByAircraft] = useState();
  const [eventCodesList, setEventCodesList] = useState([]);
  const [selectedEventCode, setSelectedEventCode] = useState("");
  const [isLoadingEventsCode, setIsLoadingEventsCode] = useState(false);
  const [
    isLoadingSessionEventCounts,
    setIsLoadingSessionEventCounts,
  ] = useState(false);
  const [minStartDate, setMinStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 100))
  );
  const [showSessionEventCountTable, setShowSessionEventCountTable] = useState(
    false
  );

  // This function updates the minStartDate (to ensure that start date is not greater than 100 days from end date)
  // when a new endDate is selected
  const handleSetEndDate = (date) => {
    setEndDate(date);

    const minStartDateUpdated = moment(date).subtract(100, "days")._d;

    setMinStartDate(minStartDateUpdated);
  };

  // Format aircraft values for dropdown
  const formatDropdownFamilyValues = (aircraftFamily, aircraftModel) => {
    return {
      value: aircraftFamily + "-" + aircraftModel,
      label: aircraftFamily + " " + aircraftModel,
    };
  };

  // Filter available event types in AirframeUIConfigs JSON file based on aircraft.
  const filterEventTypes = (familyModel) => {
    if (Object.keys(familyModel).length !== 0 && aircraftUIConfig) {
      const aircraftFamilySelected =
        aircraftUIConfig[
        familyModel?.label.substring(0, familyModel.label.indexOf(" "))
        ];

      const availableEvents =
        aircraftFamilySelected &&
        aircraftFamilySelected[
          familyModel?.label.substring(familyModel.label.indexOf(" ") + 1)
        ].globalApp;

      return availableEvents?.supportedEvents ?? [{ value: "", label: "" }];
    }
  };

  // Filter the tails list with aircraft family and model
  const filterTailsByFamilyModel = (familyModel) => {
    if (Object.keys(familyModel).length !== 0 && tailList) {
      const selectedAircraftFamily = familyModel?.label.substring(
        0,
        familyModel.label.indexOf(" ")
      );
      const selectedAircraftModel = familyModel?.label.substring(
        familyModel.label.indexOf(" ") + 1
      );

      const filteredTails = tailList.filter((aircraft) => {
        return (
          aircraft.aircraftFamily === selectedAircraftFamily &&
          aircraft.aircraftModel === selectedAircraftModel
        );
      });

      return filteredTails;
    }
  };

  const handleOnChangeFamilyModelAircraft = (option) => {
    setSelectedFamilyModel({
      value: option.value,
      label: option.label,
    });

    setSelectedEventType({
      value: "",
      label: "",
    });

    setSelectedTail("");

    setSelectedEventCode("");

    setEventCodesList([]);

    setShowSessionEventCountTable(false);
  };

  // This handles the onChange event in the Event Type dropdown and it triggers the getEventList request.
  const handleOnChangeEventType = async (eventType) => {
    setSelectedEventCode("");
    setSelectedEventType(eventType);
    setIsLoadingEventsCode(true);
    setShowSessionEventCountTable(false);

    const selectedAircraftFamily = selectedFamilyModel?.label.substring(
      0,
      selectedFamilyModel?.label.indexOf(" ")
    );
    const selectedAircraftModel = selectedFamilyModel?.label.substring(
      selectedFamilyModel?.label.indexOf(" ") + 1
    );

    const res = await getEventList(
      selectedAircraftFamily,
      selectedAircraftModel,
      eventType.value
    );
    if (res) {
      setEventCodesList(res.events);
      setIsLoadingEventsCode(false);
    }
  };

  const handleSearchFaultCounts = async () => {
    setIsLoadingSessionEventCounts(true);

    const selectedAircraft = {
      tailNumber: selectedTail.registrationNumber,
      aircraftFamily: selectedTail.aircraftFamily,
      aircraftModel: selectedTail.aircraftModel,
      startDate,
      endDate,
      eventCode: selectedEventCode.eventCode,
      eventType: selectedEventType.value,
    };
    const res = await getFaultCountsByAircraft(selectedAircraft);
    if (res) {
      setFaultCountByAircraft(res);
      setIsLoadingSessionEventCounts(false);
      setShowSessionEventCountTable(true);
    }
  };

  // Sets the event type and fetch the event codes with preselected fault data.
  const handleEventCodesWithPreselectedFault = async (
    preselectedEventType,
    faultInfo
  ) => {
    setSelectedEventType(preselectedEventType);
    setIsLoadingEventsCode(true);

    const selectedAircraftFamily = selectedFamilyModel?.label.substring(
      0,
      selectedFamilyModel?.label.indexOf(" ")
    );
    const selectedAircraftModel = selectedFamilyModel?.label.substring(
      selectedFamilyModel?.label.indexOf(" ") + 1
    );

    const res = await getEventList(
      selectedAircraftFamily,
      selectedAircraftModel,
      preselectedEventType.value
    );
    if (res) {
      setEventCodesList(res.events);
      setSelectedEventCode({
        eventCode: faultInfo.faultCode,
        eventName: faultInfo.faultMessage,
        eventSeverityComponent: faultInfo.faultSeverity,
      });
      setIsLoadingEventsCode(false);
    }
  };

  const handleTailSelection = (tail) => {
    setSelectedTail(tail);

    setSelectedEventCode("");

    setShowSessionEventCountTable(false);
  };

  useEffect(() => {
    setFamilyModelOptions(
      familyModelList?.map((fm) =>
        formatDropdownFamilyValues(fm.aircraftFamily, fm.aircraftModel)
      )
    );
  }, [familyModelList]);

  useEffect(() => {
    if ((tailList, preselectedSerialNumber, preselectedRegistrationNumber)) {
      const preselectedTailInfo = tailList.find((tail) => {
        return (
          tail.serialNumber === preselectedSerialNumber &&
          tail.registrationNumber === preselectedRegistrationNumber
        );
      });

      setSelectedTail(preselectedTailInfo);
    }
  }, []);

  // Checks what fault was selected in the FaultMessageBox component.
  useEffect(() => {
    if (fdeInfoFault) {
      handleEventCodesWithPreselectedFault(
        { value: "FDE/CAS", label: "FDE/CAS" },
        fdeInfoFault
      );
    } else if (omsInfoFault) {
      handleEventCodesWithPreselectedFault(
        { value: "OMS", label: "OMS" },
        omsInfoFault
      );
    } else if (cmsInfoFault) {
      handleEventCodesWithPreselectedFault(
        { value: "CMS", label: "CMS" },
        cmsInfoFault
      );
    } else {
      setSelectedEventType({ value: "", label: "" });
    }
  }, []);

  const isDisabledSearchButton =
    Object.keys(selectedTail).length < 1 || !selectedEventCode;

  return (
    <div className="event__count__modal" id={modalId}>
      <div className="event__count__modal__content">
        <div className="event__count__modal__header">
          <FeatherIcon icon="layers" height={30} width={30} />
          <span className="event__count__modal__title">Event History</span>
          <div className="event__count__modal__close__icon">
            <FeatherIcon
              icon="x-circle"
              height={30}
              width={30}
              onClick={toggleModal}
            />
          </div>
        </div>
        <div className="event__count__modal__components">
          <div className="event__count__modal__components__aircraft__wrap">
            <p className="event__count__modal__components__label">
              Aircraft Family*
            </p>
            <div className="event__count__modal__components__aircraftFamily">
              <div className="event__count__modal__components__aircraftFamily_select">
                {familyModelOptions && (
                  <DropdownListFamilyEventCountVersion
                    options={familyModelOptions}
                    onChange={handleOnChangeFamilyModelAircraft}
                    selectedValue={selectedFamilyModel}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="event__count__modal__components__startDate">
            <p className="event__count__modal__components__label">
              Start date*
            </p>
            <DatePickerEventCount
              selectedDate={startDate}
              handleDateChange={setStartDate}
              minStartDate={minStartDate}
              errorMessage={
                startDate > endDate
                  ? "The start date must be earlier than the end one"
                  : null
              }
            />
          </div>
          <div className="event__count__modal__components__endDate">
            <p className="event__count__modal__components__label">End date*</p>
            <DatePickerEventCount
              selectedDate={endDate}
              handleDateChange={handleSetEndDate}
            />
          </div>
          <div className="event__count__modal__components__eventType__wrap">
            <p className="event__count__modal__components__label">
              Event type*
            </p>
            <div className="event__count__modal__components__eventType">
              <div className="event__count__modal__components__eventType_select">
                <DropdownEventTypeVersion
                  options={filterEventTypes(selectedFamilyModel)}
                  onChange={handleOnChangeEventType}
                  selectedValue={selectedEventType}
                />
              </div>
            </div>
          </div>
          {eventCodesList && (
            <div className="event__count__modal__components__eventFaults__wrap">
              <p className="event__count__modal__components__label">Event*</p>
              <div className="event__count__modal__components__eventFaults">
                <div className="event__count__modal__components__eventFaults_select">
                  <DropdownEventCodesVersion
                    options={eventCodesList}
                    onChange={setSelectedEventCode}
                    selectedValue={selectedEventCode}
                    isLoadingEventsCode={isLoadingEventsCode}
                    selectedEventType={selectedEventType}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="event__count__modal__components__searchButton">
            <CustomButton
              id="btn-search-faults"
              text="SEARCH"
              icon={<FeatherIcon icon="search" height={20} />}
              iconPosition="start"
              onClickAction={handleSearchFaultCounts}
              isDisabled={isDisabledSearchButton}
            />
          </div>
          <div className="event__count__modal__components__tailsDropdown__wrap">
            <p className="event__count__modal__components__label">Aircraft*</p>
            <div className="event__count__modal__components__tailsDropdown">
              <div className="event__count__modal__components__tailsDropdown__select">
                <DropdownTailsVersion
                  options={filterTailsByFamilyModel(selectedFamilyModel)}
                  onChange={handleTailSelection}
                  selectedValue={selectedTail}
                />
              </div>
            </div>
            <div className="event__count__modal__components__sessionsTable">
              {!isLoadingSessionEventCounts ? (
                faultCountsByAircraft &&
                showSessionEventCountTable && (
                  <SessionEventCountTable
                    faultCountsByAircraft={faultCountsByAircraft}
                    selectedTail={selectedTail}
                    currentFaultColor={tools.getFaultColor(
                      selectedEventCode.eventSeverityComponent
                        ? selectedEventCode.eventSeverityComponent
                        : selectedEventType.value
                    )}
                  />
                )
              ) : (
                <div className="event__count__modal__components__sessionsTable__spinner__wrapper">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCountModal;
