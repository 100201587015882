import React from 'react';
import Card from '../Card.jsx';
import * as C from '../Colors';

const overview = (
  <div>
    Selecting a tail will take you to the <b>AIRCRAFT</b> page. 
    This page shows detailed information of a current flight and lets you visualize data points. 
  </div>
)

const flightData = (
  <div>
    Recent <b>FLIGHT DATA</b> is visualized here, organized by flight. 
  </div>
)

const table = (
  <div>
    Find <b>INFORMATION</b> here for flight deck events, faults, maintenance codes, and cabin systems. 
    <br/>
    Use the buttons to create charts and visualize this data.
  </div>
)

const report = (
  <div>
    This window shows <b>RELATED INFORMATION</b> for the selected code.
  </div>
)

const chooseSession = (
  <div>
    To navigate between sessions, click on the <b>CHOOSE SESSION</b> button.
  </div>
)

const stepContent = [
  {
    steps: [
      overview
    ],
    target: 'body',
    placement: 'center',
  },
  {
    steps: [
      flightData
    ],
    target: '#aircraftView__session__wrapper',
    placement: 'right',
    floaterProps: {
      placement: 'right-start'
    },
    styles:{
      options: {
        backgroundColor: "#3F51B5",
        arrowColor: "#3F51B5",
        textColor: C.white,
        primaryColor: C.white,
        overlayColor: "rgba(0, 0, 0, 0.5)",
        hideBackButton: true,
        width: 500,
        zIndex: 5000,
      },
      buttonNext: {
        ...C.buttonStyles,
        color: C.blue,
      },
      buttonBack: {
        ...C.buttonStyles,
        background: "transparent",
        border: "1px solid " + C.white,
        color: C.white,
      },
    }
  },
  {
    steps: [
      table
    ],
    target: '#aircraftView__faultMessageContainer',
    placement: 'left',
  },
  {
    steps: [
      report
    ],
    target: '#aircraftView__flightInfoContainer',
    placement: 'left',
    floaterProps: {
      placement: 'right-start',
    }
  },
  {
    steps: [
      chooseSession
    ],
    target: '#historic-data-button',
    placement: 'right',
    floaterProps: {
      placement: 'right-start',
    }
  },
//   {
//     steps: [
//       'Aircraft Page – Enhanced functionalities',
//       'Use the buttons located here to perform enhanced platform functionalities like RPD request, AHMS Data query (in Historical session), and Smart Fix Troubleshooting guide.',
//     ],
//     target: '#aircraftView__faultMessage__cardWrapper',
//     placement: 'left',
//   },
//   {
//     steps: [
//       "This is the historic or previous session of the aircraft.",
//       "Use the “Full Flight Parameters” button OR the “Load Fault Parameters” button to view AHMS parameter data."
//     ],
//     target: '#card_ahms_data',
//     placement: 'left',
//     floaterProps: {
//       placement: 'right-start',
//     }
//   },
//   {
//     steps: [
//       chooseSession
//     ],
//     target: '#historic-data-button',
//     placement: 'right',
//     floaterProps: {
//       placement: 'right-start',
//     }
//   },
//   {
//     steps: [
//       "Use this page to navigate between aircraft sessions and flights (current and historical)."
//     ],
//     placement: "center",
//   },
];

export default function AirplaneViewOnboarding () {

  // defaults for this set
  const defaultStyles = {
    options: C.styleReset,
    buttonNext: {
      ...C.buttonStyles,
      color: C.blue,
    },
    buttonBack: {
      ...C.buttonStyles,
      background: "transparent",
      border: "1px solid " + C.white,
      color: C.white,
    },
  };

  let trainings = [];

  stepContent.map( (x, index) => {
    trainings.push({
      target: x.target || 'body',
      disableBeacon: x.disableBeacon || true,
      content: 
        <Card paragraphs={x.steps} 
          index={index}
          stepCount={stepContent.length-1}
          pageName={"Aircraft"}
        />, 
      placement: x.placement || 'center',
      styles: x.styles || defaultStyles, 
    });
  });

  return trainings;
}