import React, { useEffect, useState } from "react";
import "./ahmsTabController.css";
import "../../Ahms Charts Components/canvasChartsStyles.css";
import ParameterSelection from "../../Ahms Parameters Components/parameterSelection.js";
import ChartNameController from "../../Ahms Charts Components/chartNameController.js";
import ChartsController from "../../Ahms Charts Components/chartsController.js";
import TemplateSelection from "../../Ahms Templates Components/templateSelection.js";
import FullChart from "../../../Ahms View Components/FullChart.js";
import * as tools from "../../../utils/CommonTools";
import MenuMoreOptions from "../../../Graph Components/MenuMoreOptions";
import { getLineChartOptions } from "../../../Graph Components/Chart Options/LineChart.js";
import {
  getSeries,
  getDiscreteNonBinarySeries,
  getChartObject,
  getDiscreteNonBinaryChartObject,
  getFaultSeverityColor,
  getCanvasChartOption,
  getCanvasLineChartOptions,
  getCanvasDiscreteNonBinaryChartOption,
} from "../../../Graph Components/Helper";
import * as chartingComponentTools from "../../ahmsChartingComponentTools.js";
import CustomButton from "../../../Global Components/CustomButton.jsx";
import CrcModalSave from "../../../Global Components/CrcModal.js";
import ApexCharts from "apexcharts";
import _ from "lodash";
import {
  getAllParameters,
  getParameterGroupMetaData,
  getParameters,
  getEventDuration,
  getCmsParameters,
} from "../../../clients/ParametersClient";
import {
  getParameterData,
  getAHMUParameterData,
  getCSVParameterData,
  getCmsParameterData,
  getCmsParameterInfo,
  cmsParameterDataForCsv,
} from "../../../clients/ParametersDataClient";
import { getExcelReport } from "../../../clients/DocumentClient.js";
import { CSVLink } from "react-csv";
import { getATAChapterList } from "../../../clients/TemplateClient.js";
import {
  getChartsTemplate,
  getChartsTemplateList,
} from "../../../clients/ChartsTemplate";
import AirframeUIConfigs from "../../../resources/AirframeUIConfigs.json";
import { saveAs } from "file-saver";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import CanvasJSReact from "../../../resources/canvasjs.react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Redirect } from "react-router-dom";
import { getRpdParameterData } from "../../../clients/RpdParametersDataClient";
import NotificationPopup from "../../../Global Components/NotificationPopup.js";
import { getRolesPermissions } from "../../../Auth Components/RbacValidation.js";
import CanvasJS from "../../../resources/canvasjs.min.js";
import Wizard from "../../../Application Onboarding Components/Wizard.js";
import CustomTooltip from "../../../Global Components/CustomTooltip";
import Breadcrumb from "../../../Global Components/Breadcrumb/Breadcrumb";
import GlobalHeader from "../../../Global Components/GlobalHeader/GlobalHeader";
import {sendAHMSChartingEvent} from "../../../GA4/EventsCatalog.js";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function AhmsTabController(props) {
  // Page constants
  const faultCode = props.location.state?.faultCode;
  const faultMessage = props.location.state?.faultMessage;
  const faultDescription = props.location.state?.faultDescription;
  const severity = props.location.state?.faultSeverity
    ? props.location.state?.faultSeverity
    : props.location.state?.faultSystem;
  const severityColor = tools.getFaultColor(severity);
  const aircraftFamily = props.location.state?.aircraftFamily;
  const aircraftModel = props.location.state?.aircraftModel;
  const faultTimeStamp = props.location.state?.faultTimestamp;
  const omsFaultList = props.location.state?.omsFaultList;
  const dataFocus = props.location.state?.dataFocus;
  const tail = props.location.state?.tail;
  const serialNumber = props.location.state?.serial;
  const session = props.location.state?.aircraftSession;
  const rpdRequestId = props.location.state?.rpdRequestId;
  const system = props.location.state?.faultSystem;
  const rpdRequestDuration = props.location.state?.rpdRequestDuration;
  const rpdRequestParameters = props.location.state?.rpdRequestParameters;
  const cmsSessionsList = props.location.state?.cmsSessionsList;
  const userName = props.location.state?.userName;

  // FDE/CAS event metadata that helps in event parameter data queries
  const eventUid = props.location.state?.eventUid;
  const eventStartTime = props.location.state?.eventStartTime;
  const eventEndTime = props.location.state?.eventEndTime;

  // Global Header stuff
  const timeInFlight = props.location.state?.timeInFlight;
  const latestDataTransferDate = props.location.state?.latestDataTransferDate;
  const hasLatestHmuTransferDate =
    props.location.state?.hasLatestHmuTransferDate;
  const currentStatus = props.location.state?.currentAircraftStatus
    ? props.location.state?.currentAircraftStatus
    : props.location.state?.status;

  const userPermissions = getRolesPermissions("ahmsview");
  const alternativeExistLink = null;
  const technical_detail =
    "This file format is not supported when creating a template";
  const noPageData =
    !dataFocus || !tail || !aircraftFamily || !aircraftModel || !serialNumber;
  const aircraftUIConfig =
    noPageData === true
      ? null
      : AirframeUIConfigs[aircraftFamily][aircraftModel];
  let startTime = null;
  let endTime = null;

  const [cmsDateWindowMoved, setCmsDateWindowMoved] = React.useState(false);

  // Validatting location data and saving it to local storage, needed to return to the aircraft view.
  localStorage.setItem("tmpTail", tail);
  localStorage.setItem("tmpAircraftFamily", aircraftFamily);
  localStorage.setItem("tmpAircraftModel", aircraftModel);
  localStorage.setItem("tmpSerial", serialNumber);
  localStorage.setItem("tmpMonitorStatus", props.location.state?.monitorStatus);

  let paddingInMinutes;
  // Configuring a padding acording to the type of charting
  // If there is no eventUid assigned then use the default start and end times.
  if (dataFocus === "FAULT" || dataFocus === "IN-FLIGHT") {
    if (eventUid !== "" && eventStartTime !== "" && eventEndTime !== "") {
      startTime = new Date(eventStartTime).getTime();
      endTime = new Date(eventEndTime).getTime();
    } else {
      startTime = new Date(faultTimeStamp).getTime() - 61000;
      endTime = new Date(faultTimeStamp).getTime() + 61000;
    }
  } else if (dataFocus === "FLIGHT") {
    paddingInMinutes = 5;
    startTime =
      moment.utc(props.location.state?.departureTime).valueOf() -
      paddingInMinutes * 60000;
    endTime =
      moment.utc(props.location.state?.arrivalTime).valueOf() +
      paddingInMinutes * 60000;
  } else if (dataFocus === "CMS") {
    if (faultTimeStamp) {
      paddingInMinutes = 25;
      startTime = new Date(faultTimeStamp) - paddingInMinutes * 60000;
      endTime = new Date(faultTimeStamp).getTime() + paddingInMinutes * 60000;
    } else {
      startTime = new Date(cmsSessionsList[0].cmsSessionStartTime).getTime();
      endTime = new Date(
        cmsSessionsList[cmsSessionsList.length - 1].cmsSessionEndTime
      ).getTime();
    }
  } else if (dataFocus === "RPD") {
    paddingInMinutes = 2;
    startTime = moment.utc(faultTimeStamp).valueOf() - paddingInMinutes * 60000;
    endTime = moment.utc(faultTimeStamp).valueOf() + paddingInMinutes * 60000;
  }

  let configMaxSessionChartingRangeMinutes = 480; // 8 Hours
  let tempMaxSessionChartingRangeMinutes =
    endTime - startTime < configMaxSessionChartingRangeMinutes * 60000
      ? (endTime - startTime) / 60000
      : configMaxSessionChartingRangeMinutes;

  const [csvFileName, setCsvFileName] = React.useState(
    serialNumber + "_" + tail + "_" + severity + ".csv"
  );

  const numParametersAllowed =
    dataFocus === "FAULT" || dataFocus === "IN-FLIGHT" ? 50 : 50;

  // tabs
  const tabs = ["PARAMETERS", "CHARTS"];
  if (dataFocus !== "RPD" && dataFocus !== "IN-FLIGHT") {
    tabs.splice(1, 0, "TEMPLATES");
  }

  // Adding selected tab to state so we can keep up with when that changes
  const [selectedTab, setSelectedTab] = React.useState(tabs[0]);
  // Adding parameter list we fetch from backend to store parameter in state since they will change.
  const [parameterList, setParameterList] = React.useState([]);
  // Adding charts array to state so we can keep up with changes to charts.
  const [charts, setCharts] = React.useState([]);
  // Adding charts array to state so we can keep up with changes to discrete charts
  const [parametersToAdd, setParametersToAdd] = React.useState([]);
  // Adding storage for parameters the user wants to remove
  const [parametersToRemove, setParametersToRemove] = React.useState([]);
  // Adding snapshot parameters to an array
  const [snapshotParameters, setSnapshotParameters] = React.useState([]);
  // List of fde/cas fault parameters
  const [
    parameterSelectionTabStates,
    setParameterSelectionTabStates,
  ] = React.useState("Add Parameters");
  // set fdeEventDuration in state
  const [fdeEventDuration, setFdeEventDuration] = React.useState(60);
  // set parameter list lengths before combining
  const [parameterListSizes, setParameterListSizes] = React.useState({
    continuousParameters: 0,
    cmsParameters: 0,
    fdeFaultParameters: 0,
  });
  const [ataChapterList, setAtaChapterList] = React.useState([]);
  const [csvData, setCsvData] = React.useState([]);
  const [loadingAhmsCsv, setLoadingAhmsCsv] = React.useState(false);
  const [loadingCmsCsv, setLoadingCmsCsv] = React.useState(false);

  const [updatingCharts, setUpdatingCharts] = React.useState(false);
  const [onDragEndDetails, setOnDragEndDetails] = React.useState({
    onDragStartIndex: null,
    onDragEndIndex: null,
  });
  const [expandedLegend, setExpandedLegend] = React.useState(true);
  const [xZoom, setXZoom] = React.useState({
    min: null,
    max: null,
  });
  const [parametersLoaded, setParametersLoaded] = React.useState(false);
  const [altitudeData, setAltitudeData] = React.useState({});
  const [cmsStartTime, setCmsStartTime] = React.useState(startTime);
  const [cmsEndTime, setCmsEndTime] = React.useState(endTime);
  const [maxSessionChartingRangeMinutes] = React.useState(
    tempMaxSessionChartingRangeMinutes
  );
  const [maxSessionChartingRange] = React.useState(
    (tempMaxSessionChartingRangeMinutes + 0.0001) * 60000
  );

  const [ahmsParametersSelected, setAhmsParametersSelected] = React.useState(0);
  const [cmsParametersSelected, setCmsParametersSelected] = React.useState(0);

  // Canvas JS State Management
  const [canvasCharts] = React.useState([]);
  const [canvasChartCount, setCanvasChartCount] = React.useState(0);

  // Template Related State Management
  const [templateList, setTemplateList] = React.useState();
  const [selectedTemplate, setSelectedTemplate] = React.useState([]);
  const [templateError, setTemplateError] = React.useState(false);
  const [loadedTemplate, setLoadedTemplate] = React.useState();
  const [usingTemplate, setUsingTemplate] = React.useState(false);
  const [unitMapping, setUnitMapping] = React.useState(new Map());
  //const [unitMapping, setUnitMapping] = React.useState();
  const [isTemplateDetailLoading, setIsTemplateDetailLoading] = React.useState(
    false
  );
  const [ahmsCrumb, setAhmsCrumb] = useState({});
  let csvBtnLink = React.useRef();

  const reloadTemplates = () => {
    setTemplateList(null);
    getChartsTemplateList(aircraftModel, aircraftFamily).then((res) => {
      let templatesResponse = _.get(res, "data");
      let templates = templatesResponse.templateList;
      templates.forEach((template) => {
        _.assign(template, { parameters: [] });
      });
      templates = addTemplateNamePlusCreatedByField(templates);
      templates = sortTemplates(templates);
      templates = showRelevantTemplates(templates);
      if (templates) {
        setTemplateList(templates);
      }
    });
  };

  // Method to update tab css based on selection and child page
  const changeTab = (newTab) => {
    if (newTab === "PARAMETERS" && dataFocus !== "RPD") {
      setParameterSelectionTabStates("Add Parameters");
    } else {
      setParameterSelectionTabStates("Parameters Overview");
    }
    setSelectedTab(newTab);
  };

  //switches tabs for onboarding
  const changeTabOnIndex = (index) => {
    if (index === 2) {
      changeTab("TEMPLATES");
    } else {
      changeTab("PARAMETERS");
    }
  };

  const closeNotificationModal = () => {
    setTemplateError(false);
  };

  const updateCmsStartTime = (start) => {
    xZoom.min = start;
    setXZoom({ ...xZoom });
    setCmsDateWindowMoved(true);
    setCmsStartTime(start);
  };

  const updateCmsEndTime = (end) => {
    xZoom.max = end;
    setXZoom({ ...xZoom });
    setCmsDateWindowMoved(true);
    setCmsEndTime(end);
  };

  const settingZoomCoord = (xMin, xMax) => {
    setXZoom({
      min: xMin,
      max: xMax,
    });
  };

  const syncCharts = (e) => {
    var syncedCharts = charts;
    for (var i = 0; i < syncedCharts.length; i++) {
      var chart = syncedCharts[i];

      if (!chart.options.axisX) chart.options.axisX = {};

      if (!chart.options.axisY) chart.options.axisY = {};

      if (e.trigger === "reset") {
        chart.options.axisX.viewportMinimum = chart.options.axisX.viewportMaximum = null;
        chart.options.axisY.viewportMinimum = chart.options.axisY.viewportMaximum = null;

        chart.render();
      } else if (chart !== e.chart) {
        chart.options.axisX.viewportMinimum = e.axisX[0].viewportMinimum;
        chart.options.axisX.viewportMaximum = e.axisX[0].viewportMaximum;

        chart.options.axisY.viewportMinimum = e.axisY[0].viewportMinimum;
        chart.options.axisY.viewportMaximum = e.axisY[0].viewportMaximum;

        chart.render();
      }
    }
  };

  // This will have to be re-thought
  // Currently when saving a template, it uses the parametersToAdd list
  const canvasUpdateParameterOverview = (parameter) => {
    _.remove(parametersToAdd, {
      parameterName: parameter,
    });

    _.find(parameterList, function(p) {
      if (p.parameterName === parameter) {
        return true;
      }
    }).checked = false;
    setParameterList([...parameterList]);
    setParametersToAdd([...parametersToAdd]);
  };

  const updateCharts = () => {
    setUpdatingCharts(true);

    setParameterSelectionTabStates("Parameters Overview");
    // Adding parameters to charts
    // Group parameters by unit, 10 per chart
    for (let r = 0; r < parametersToRemove.length; r++) {
      let ptaIndex = _.findIndex(parametersToAdd, function(p) {
        return p.parameterName === parametersToRemove[r].parameterName;
      });
      removeParameter(parametersToRemove[r].parameterName, ptaIndex);
    }

    let loadedPGroup = _.filter(parametersToAdd, function(p) {
      return p.loaded === null;
    });
    if (loadedPGroup) {
      setUpdatingCharts(false);
    }

    for (let i = 0; i < parametersToAdd.length; i++) {
      if (parametersToAdd[i].loaded === null) {
        const reqFaultCode =
          parametersToAdd[i].type === "FDE Event Parameter"
            ? faultCode
            : "000000";
        const reqFaultName =
          parametersToAdd[i].type === "FDE Event Parameter"
            ? faultMessage
            : "000000";
        const queryFaultCode = faultCode ? faultCode : null;
        parametersToAdd[i].loaded = false;

        setParametersToAdd([...parametersToAdd]);
        let getSeriesData = null;

        if (dataFocus === "FAULT" || dataFocus === "IN-FLIGHT") {
          getSeriesData = getParameterData(
            tail,
            reqFaultCode,
            aircraftFamily,
            aircraftModel,
            faultTimeStamp,
            parametersToAdd[i],
            fdeEventDuration,
            queryFaultCode,
            getParametersToAdd,
            reqFaultName
          );
        } else if (dataFocus === "RPD") {
          getSeriesData = getRpdParameterData(
            rpdRequestId,
            parametersToAdd[i],
            rpdRequestDuration,
            aircraftFamily,
            aircraftModel
          );
        }

        getSeriesData
          .then((response, request) => {
            const unit = getUnit(response.config.params.parameterName);
            let series;
            let data =
              _.get(response, "data.data") || _.get(response, "data[0].data");
            if (
              data &&
              data.length === 1 &&
              unit !== "Discrete Non-binary" &&
              dataFocus !== "CMS"
            ) {
              series = {
                seriesData: data,
                englishName: response.config.params.parameterName,
                unit: unit,
              };

              // TODO: WHEN TO ADD THIS !!!
              addSnapshotParameter(series);

              // Show Parameter as loaded
              const findAddParamIndex = _.findIndex(parametersToAdd, function(
                paramToAdd
              ) {
                return paramToAdd.parameterName === series.englishName;
              });

              if (findAddParamIndex !== -1) {
                parametersToAdd[findAddParamIndex].loaded = true;
              }
            } else if (data && data.length > 0) {
              if (unit === "Discrete Non-binary") {
                series = {
                  seriesData: [],
                  englishName: response.config.params.parameterName,
                  unit: "Discrete Non-Binary",
                };
                let nonBinarySeries = _.get(response, "data[0].data");
                if (nonBinarySeries && nonBinarySeries.length > 0) {
                  for (let i = 0; i < nonBinarySeries.length; i++) {
                    let s = getDiscreteNonBinarySeries(
                      nonBinarySeries[i],
                      response.config.params,
                      getParametersToAdd(series.englishName)
                    );
                    series.seriesData.push(s);
                  }

                  const isCharted = addParameter(series);

                  if (!isCharted) {
                    addNonBinaryDiscreteChart(series);
                  }

                  // Show Parameter as loaded
                  const findAddParamIndex = _.findIndex(
                    parametersToAdd,
                    function(paramToAdd) {
                      return paramToAdd.parameterName === series.englishName;
                    }
                  );

                  if (findAddParamIndex !== -1) {
                    parametersToAdd[findAddParamIndex].loaded = true;
                  }
                } else {
                  // Show Parameter as loaded
                  const findAddParamIndex = _.findIndex(
                    parametersToAdd,
                    function(paramToAdd) {
                      return (
                        paramToAdd.parameterName ===
                        response.config.params.parameterName
                      );
                    }
                  );

                  if (findAddParamIndex !== -1) {
                    parametersToAdd[findAddParamIndex].loaded = "error";
                  }
                }
              } else {
                series = getSeries(
                  response,
                  dataFocus,
                  unit,
                  getParametersToAdd
                );
                // Create series object

                if (series.unit === "Binary") {
                  series.unit = "Discrete";
                  series.isDiscrete = true;
                }

                // Check to see if there is a chart to add parameter to
                const isCharted = addParameter(series);

                // If not charted then create chart and add it to chart
                if (!isCharted) {
                  addChart(series);
                }

                // Show Parameter as loaded
                const findAddParamIndex = _.findIndex(parametersToAdd, function(
                  paramToAdd
                ) {
                  return paramToAdd.parameterName === series.name;
                });

                if (findAddParamIndex !== -1) {
                  parametersToAdd[findAddParamIndex].loaded = true;
                }
              }
            } else {
              const findAddParamIndex = _.findIndex(parametersToAdd, function(
                paramToAdd
              ) {
                return (
                  paramToAdd.parameterName ===
                  response.config.params.parameterName
                );
              });

              if (findAddParamIndex !== -1) {
                parametersToAdd[findAddParamIndex].loaded = "error";
                parametersToAdd[findAddParamIndex].error =
                  "No data points found for this parameter during this timeframe.";
                setParametersToAdd([...parametersToAdd]);
                areChartsDoneLoading();
              }
            }
            setParametersToAdd([...parametersToAdd]);

            areChartsDoneLoading();
          })
          .catch((error, req) => {
            /*TODO*/
            // Trying to catch all the error messages
            let error0 = _.get(error, "response.data[0]");
            let error1 = _.get(error, "response.data.message");
            let error2 = _.get(error, "response.data.error");

            let errorObject = error0 || error1 || error2;

            if (errorObject?.length === 1) {
              errorObject = _.get(error, "response.statusText");
            }

            if (errorObject) {
              const findAddParamIndex = _.findIndex(parametersToAdd, function(
                paramToAdd
              ) {
                return (
                  paramToAdd.parameterName ===
                  error.response.config.params.parameterName
                );
              });

              if (findAddParamIndex !== -1) {
                parametersToAdd[findAddParamIndex].loaded = "error";
                parametersToAdd[findAddParamIndex].error = errorObject;
                setParametersToAdd([...parametersToAdd]);
                areChartsDoneLoading();
              }
            }
            setUpdatingCharts(false);
          });
      }
    }
  };

  const removeCanvasParameterByName = (parameterName) => {
    let foundChartIndex = -1;
    let foundParameterIndex = -1;

    for (let someChart = 0; someChart < charts.length; someChart++) {
      let chartDataArray = charts[someChart].options.data;

      // If the chart is valid (has data in it) proceed
      if (chartDataArray.length > 0) {
        // Look for the parameter in the series data of each chart
        let pIndex = _.findIndex(chartDataArray, function(paramData) {
          return paramData.name === parameterName;
        });
        // If the parameter is there then we save the chart index also
        if (pIndex !== -1) {
          foundChartIndex = someChart;
          foundParameterIndex = pIndex;
        }
      }
    }

    if (foundChartIndex !== -1 && foundParameterIndex !== -1) {
      removeCanvasParameter(foundChartIndex, foundParameterIndex);
    }
  };

  const updateCanvasCharts = () => {
    let analyticsChartingInfo = {
      aircraft_family: aircraftFamily,
      aircraft_model: aircraftModel,
      serial: serialNumber,
      fault_code: faultCode ? faultCode : null,
      charting_type: dataFocus
    };
    // The CMS full session window has been moved
    if (cmsDateWindowMoved) {
      setCmsDateWindowMoved(false);

      for (let x = 0; x < parametersToAdd.length; x++) {
        parametersToAdd[x].loaded = null;
      }
      setParametersToAdd([...parametersToAdd]);

      // TODO: TEST THIS !!!
      clearCanvasCharts(false);

      setTimeout(() => {
        return true;
      }, 5000);
    }

    const buildCharts = charts;
    let newChartCount = 0;

    setUpdatingCharts(true);
    setParameterSelectionTabStates("Parameters Overview");

    // Go over the parameters to remove and remove them from parameters to add
    for (let r = 0; r < parametersToRemove.length; r++) {
      removeCanvasParameterByName(parametersToRemove[r].parameterName);
    }

    // Checking to see if parameters are needed to load
    let loadedPGroup = _.filter(parametersToAdd, function(p) {
      return p.loaded === null;
    });

    if (loadedPGroup) {
      setUpdatingCharts(false);
    }

    // Constructing array of parameter data promises
    const parameterDataPromises = [];
    const queryFaultCode = faultCode ? faultCode : null;
    const analyticsParameterData = [];

    for (let i = 0; i < parametersToAdd.length; i++) {
      analyticsParameterData.push({
        ata: parametersToAdd[i].ATAChapter,
        parameter_name: parametersToAdd[i].parameterName,
        unit: parametersToAdd[i].unitOfMeasure,
        parameter_type: parametersToAdd[i].type
      });
      if (parametersToAdd[i].loaded === null) {
        parametersToAdd[i].loaded = false;
        setParametersToAdd([...parametersToAdd]);
        let parameterDataPromise;

        if (dataFocus === "CMS") {
          if (parametersToAdd[i].type === "CMS Parameter") {
            parameterDataPromise = getCmsParameterData(
              tail,
              aircraftFamily,
              aircraftModel,
              serialNumber,
              cmsStartTime,
              cmsEndTime,
              parametersToAdd[i],
              queryFaultCode,
              getParametersToAdd
            );
          } else if (parametersToAdd[i].type === "Continuous Parameter") {
            parameterDataPromise = getAHMUParameterData(
              tail,
              aircraftFamily,
              aircraftModel,
              cmsStartTime,
              cmsEndTime,
              parametersToAdd[i],
              queryFaultCode,
              getParametersToAdd
              //reqFaultName
            );
          }
        } else {
          parameterDataPromise = getAHMUParameterData(
            tail,
            aircraftFamily,
            aircraftModel,
            startTime,
            endTime,
            parametersToAdd[i],
            null,
            getParametersToAdd
          );
        }

        parameterDataPromises.push(parameterDataPromise);
      }
    }

    Promise.all(parameterDataPromises)
      .then((res) => {
        // Looping through new parameters to be added
        for (let p = 0; p < res.length; p++) {
          // Getting parameterName and Series
          const parameterName =
            _.get(res[p], "data[0].name") ||
            _.get(res[p], "data.data.name") ||
            _.get(res[p], "data.name");
          const series =
            _.get(res[p], "data[0].data") || _.get(res[p], "data.data");

          const unit = unitMapping.get(parameterName);

          // Find Index for parameters to set as Loaded
          const pAddIndex = _.findIndex(parametersToAdd, (paramToAdd) => {
            return paramToAdd.parameterName === parameterName;
          });

          // Set parameters that have returned parameter data as loaded
          if (pAddIndex !== -1) {
            parametersToAdd[pAddIndex].loaded = true;
          }

          // If unit is discrete or undefined it gets its own chart
          if (unit === "Discrete" || unit === undefined) {
            const dataSeries = [
              setCanvasChartDataSeries(
                "stepArea",
                "discrete",
                parameterName,
                series
              ),
            ];
            CanvasJS.addColorSet("discreteColor", [
              getCanvasChartColorSet()[0],
            ]);
            const newOptions = getCanvasChartOption(
              unit,
              dataSeries,
              parameterName
            );
            buildCharts.push(newOptions);
            newChartCount++;
          } else if (unit === "Discrete Non-binary") {
            /*TODO: NONBINARY */
            const dataSeries = [];
            series.forEach((diffValue) => {
              let objectValues = [];
              diffValue.valueStatus.forEach((arrayValue) => {
                objectValues.push({
                  x: new Date(arrayValue[0]),
                  y: arrayValue[1],
                });
              });

              dataSeries.push(
                setCanvasChartDataSeries(
                  "stepArea",
                  "discrete-non-binary",
                  diffValue.value,
                  objectValues
                )
              );
            });

            /*
                if (nonBinarySeries && nonBinarySeries.length > 0) {
                  for (let i = 0; i < nonBinarySeries.length; i++) {
                    let s = getDiscreteNonBinarySeries(
                      nonBinarySeries[i],
                      response.config.params,
                      getParametersToAdd(series.englishName)
                    );
                    series.seriesData.push(s);
                  }

                  const isCharted = addParameter(series);

                  if (!isCharted) {
                    addNonBinaryDiscreteChart(series);
                  }

                  // Show Parameter as loaded
                  const findAddParamIndex = _.findIndex(
                    parametersToAdd,
                    function(paramToAdd) {
                      return paramToAdd.parameterName === series.englishName;
                    }
                  );

                  if (findAddParamIndex !== -1) {
                    parametersToAdd[findAddParamIndex].loaded = true;
                  }
                } else {
                  // Show Parameter as loaded
                  const findAddParamIndex = _.findIndex(
                    parametersToAdd,
                    function(paramToAdd) {
                      return (
                        paramToAdd.parameterName ===
                        response.config.params.parameterName
                      );
                    }
                  );

                  if (findAddParamIndex !== -1) {
                    parametersToAdd[findAddParamIndex].loaded = "error";
                  }
                }
            */

            CanvasJS.addColorSet(
              "discreteNonBinaryColor",
              getCanvasChartColorSet()
            );
            const newOptions = getCanvasDiscreteNonBinaryChartOption(
              unit,
              dataSeries,
              parameterName
            );
            buildCharts.push(newOptions);
            newChartCount++;
          } else {
            // Unit is intended for line charts
            // Checking if any charts currently exist
            if (buildCharts.length > 0) {
              // Looking for chart to put parameter in
              const hasCharts = _.findIndex(
                charts,
                (c) => {
                  return (
                    c.unit === unit &&
                    c.options.data.length < 10 &&
                    c.options.data.length > 0
                  );
                },
                0
              );

              if (hasCharts !== -1) {
                // Setting up data series object to be added
                const dataSeries = setCanvasChartDataSeries(
                  "line",
                  unit,
                  parameterName,
                  series,
                  p % 2 === 1 ? "shortDash" : null
                );

                buildCharts[hasCharts].options.data.push(dataSeries);
              } else {
                // No chart with same unit or with same unit but are full so we create a new chart for it
                const dataSeries = [
                  setCanvasChartDataSeries("line", unit, parameterName, series),
                ];
                CanvasJS.addColorSet("greenShades", getCanvasChartColorSet());
                const newOptions = getCanvasLineChartOptions(unit, dataSeries);
                buildCharts.push(newOptions);
                newChartCount++;
              }
            } else {
              // No charts currently exist so we add a new line chart
              const dataSeries = [
                setCanvasChartDataSeries("line", unit, parameterName, series),
              ];
              CanvasJS.addColorSet("greenShades", getCanvasChartColorSet());
              const newOptions = getCanvasLineChartOptions(unit, dataSeries);
              buildCharts.push(newOptions);
              newChartCount++;
            }
          }
        }
        setCharts([...buildCharts]);
        setCanvasChartCount(canvasChartCount + newChartCount);
        setParametersToAdd([...parametersToAdd]);
        analyticsChartingInfo.parameters = analyticsParameterData;
        sendAHMSChartingEvent(analyticsChartingInfo);
        changeTab("CHARTS");
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  };

  const setCanvasChartDataSeries = (
    chartType = "line",
    unit,
    parameterName,
    dataPoints,
    lineDashType = null
  ) => {
    var dataSeries = {
      type: chartType,
      xValueFormatString: "UTC:YYYY-MM-DD, HH:mm:ss",
    };
    if (lineDashType) {
      dataSeries.lineDashType = lineDashType;
    }

    dataSeries.name = parameterName;
    dataSeries.unit = unit;
    dataSeries.dataPoints = dataPoints;
    dataSeries.showInLegend = false;
    dataSeries.toggled = false;
    dataSeries.legendMarkerType = "circle";

    return dataSeries;
  };

  const getCanvasChartColorSet = () => {
    return [
      "#07B9FC",
      "#FFB300",
      "#EA0034",
      "#9AF000",
      "#ec008c",
      "#0062FF",
      "#33FF00",
      "#FFFE37",
      "#FF6600",
      "#CC00FF",
    ];
  };

  const clearCharts = () => {
    // Clear parametersToAdd
    for (let i = parametersToAdd.length; i >= 0; i--) {
      parametersToAdd.splice(i, 1);
    }
    setParametersToAdd([...parametersToAdd]);

    // Clear Charts
    for (let i = charts.length; i >= 0; i--) {
      charts.splice(i, 1);
    }
    setCharts([...charts]);

    // Clear Check Boxes
    for (let pIndex = 0; pIndex < parameterList.length; pIndex++) {
      parameterList[pIndex].checked = false;
    }

    setParameterList([...parameterList]);
  };

  const clearCanvasCharts = (clearParametersToAdd = true) => {
    if (clearParametersToAdd) {
      // Clear parametersToAdd
      for (let i = parametersToAdd.length; i >= 0; i--) {
        parametersToAdd.splice(i, 1);
      }
      setParametersToAdd([...parametersToAdd]);
    }

    // Clear Charts
    for (let i = 0; i < charts.length; i++) {
      // Save the values of the removed chart into the last space on each chart state variables (NULL for the canvas)
      charts[i].options.data = [];
      canvasCharts[i] = null;
    }
    setCharts([...charts]);
    setCanvasChartCount(0);

    if (clearParametersToAdd) {
      // Clear Check Boxes
      for (let pIndex = 0; pIndex < parameterList.length; pIndex++) {
        parameterList[pIndex].checked = false;
      }
      setParameterList([...parameterList]);
    }
  };

  const loadTemplate = async (template) => {
    setUsingTemplate(false);
    if (template.parameters.length === 0) {
      setIsTemplateDetailLoading(true);
      await getChartsTemplate(template.templateId)
        .then((res) => {
          template.parameters = res.data.parameters;
          setIsTemplateDetailLoading(false);
        })
        .catch((error) => {
          template.parameters = [];
          setIsTemplateDetailLoading(false);
        });
    }
    if (template.parameters) {
      changeTab(tabs[0]);
      setParameterSelectionTabStates("Parameters Overview");

      if (dataFocus === "FLIGHT" || dataFocus === "CMS") {
        clearCanvasCharts();
      } else {
        clearCharts();
      }

      const maxTemplateParameterCounter =
        template.parameters.length > numParametersAllowed
          ? numParametersAllowed
          : template.parameters.length;

      for (let i = 0; i < maxTemplateParameterCounter; i++) {
        let parameterListIndex = _.findIndex(parameterList, function(
          paramInList
        ) {
          return (
            paramInList.parameterName === template.parameters[i].parameterName
          );
        });

        if (parameterListIndex !== -1) {
          parameterList[parameterListIndex].checked = true;
          let param = parameterList[parameterListIndex];
          param.loaded = null;
          parametersToAdd.push(parameterList[parameterListIndex]);
          setAhmsParametersSelected(ahmsParametersSelected + 1);
        } else {
          let parameterNotFound = {
            parameterName: template.parameters[i].parameterName,
            type: "n/a",
            loaded: "error",
            error: "Parameter Data Unavailable.",
          };
          parametersToAdd.push(parameterNotFound);
        }
      }

      // checking permissions to show button
      if (template.templatePrivacyPolicy === "PRIVATE") {
        setUsingTemplate(true);
      }

      if (
        template.templatePrivacyPolicy === "INT_PUBLIC" &&
        userPermissions.templatePublicPolicyInternal
      ) {
        setUsingTemplate(true);
      }

      if (
        template.templatePrivacyPolicy === "EXT_PUBLIC" &&
        userPermissions.templatePublicPolicyExternal
      ) {
        setUsingTemplate(true);
      }

      setLoadedTemplate(template);
      setParametersToAdd([...parametersToAdd]);
      setParameterList([...parameterList]);

      if (dataFocus === "FLIGHT" || dataFocus === "CMS") {
        updateCanvasCharts();
      } else {
        updateCharts();
      }
    }
  };

  // Function to download a template JSON file.
  const saveTemplate = (template) => {
    const jsonTemplate = JSON.stringify(template);

    var blob = new Blob([jsonTemplate], {
      type: "text/plain;charset=utf-8",
    });

    // Adddng the template name as the name of the file
    let templateName = template?.templateName ? template.templateName : "";
    templateName = templateName.replaceAll(" ", "_");

    saveAs(blob, tail + "_" + templateName + "_template.txt");
  };

  const getParameterUnits = (parameter) => {
    let paramMap = new Map(
      parametersToAdd.map((key) => [key.parameterName, key.unitOfMeasure])
    );

    const unit = paramMap.get(parameter);
    return unit;
  };

  const getParametersToAdd = (parameter) => {
    let paramMap = new Map(
      parametersToAdd.map((key) => [
        key.parameterName,
        key.discreteValueTranslation,
      ])
    );

    const discreteTranslation = paramMap.get(parameter);
    return discreteTranslation;
  };

  const getCSVParameterList = () => {
    setLoadingAhmsCsv(true);
    let parametersInOverview = [];

    try {
      //Adding Continuous Parameters and data needed to build raw data csv
      parametersToAdd.forEach((p) => {
        if (p.loaded !== "error") {
          let parameterForCsvReq = {
            parameterName: p.parameterName,
            parameterType: "continuous",
            discrete: false,
          };

          if (p.unitOfMeasure.type === "FDE Event Parameter") {
            parameterForCsvReq.parameterType = "event";
          }

          if (p.unitOfMeasure === "Discrete") {
            parameterForCsvReq.discrete = true;

            parameterForCsvReq.discreteTranslation = getParametersToAdd(
              parameterForCsvReq.parameterName
            );
          }

          parametersInOverview.push(parameterForCsvReq);
        }
      });

      return parametersInOverview;
    } catch (error) {
      console.error("Error Exporting CSV: ", error);
      return [];
    }
  };

  const fetchCSVData = (parameterDataType) => {
    if (parameterDataType === "CMS") {
      setCsvFileName(
        "CMS_" + serialNumber + "_" + tail + "_" + session + "_Flight_Data.csv"
      );
      setLoadingCmsCsv(true);
      const cmsParameters = [];

      for (let i = 0; i < parametersToAdd.length; i++) {
        if (
          parametersToAdd[i].type === "CMS Parameter" &&
          parametersToAdd[i].loaded !== "error"
        ) {
          cmsParameters.push({
            parameterName: parametersToAdd[i].parameterName,
            parameterType: "CMS Parameter",
          });
        }
      }

      // Commnets
      cmsParameterDataForCsv(
        cmsParameters,
        tail,
        aircraftFamily,
        aircraftModel,
        faultCode !== "N/A" ? faultCode : "*",
        startTime,
        endTime,
        null,
        null
      )
        .then((res) => {
          let CSVData = [];

          //Creating Header Row
          let headerRow = ["Date"];
          res.data.parameterData.forEach((param, pIndex) => {
            headerRow.push(param.columnName);

            //setting discrete translation for values
            let listIndex = _.findIndex(cmsParameters, function(parameter) {
              return param.columnName === parameter.parameterName;
            });

            if (listIndex !== -1) {
              if (cmsParameters[listIndex].discrete === true) {
                param.discreteTranslation =
                  cmsParameters[listIndex].discreteTranslation;
                param.values.forEach((value, vIndex) => {
                  if (value === "1.0") {
                    param.values[vIndex] =
                      cmsParameters[listIndex].discreteTranslation.on;
                  } else if (value === "0.0") {
                    param.values[vIndex] =
                      cmsParameters[listIndex].discreteTranslation.off;
                  }
                });
              }
            }
          });

          CSVData.push(headerRow);

          //Creating Data Rows
          res.data.dateData.forEach((date, index) => {
            let row = [];
            let longDate = moment
              .utc(date)
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            row.push(longDate);

            res.data.parameterData.forEach((param) => {
              row.push(param.values[index]);
            });

            CSVData.push(row);
          });

          setCsvData([...CSVData]);
        })
        .catch((error) => {
          console.error("error: ", error);
        });
    } else if (parameterDataType === "AHMS") {
      setCsvFileName(
        "AHMS_" + serialNumber + "_" + tail + "_" + session + "_Flight_Data.csv"
      );

      let list = getCSVParameterList();

      getCSVParameterData(
        list,
        tail,
        faultCode ? faultCode : "*",
        startTime,
        endTime,
        aircraftModel,
        aircraftFamily
      )
        .then((response) => {
          let CSVData = [];

          //Creating Header Row
          let headerRow = ["Date"];
          response.data.parameterData.forEach((param, pIndex) => {
            headerRow.push(param.columnName);

            //setting discrete translation for values
            let listIndex = _.findIndex(list, function(parameter) {
              return param.columnName === parameter.parameterName;
            });

            if (listIndex !== -1) {
              if (list[listIndex].discrete === true) {
                param.discreteTranslation = list[listIndex].discreteTranslation;
                param.values.forEach((value, vIndex) => {
                  if (value === "1.0") {
                    param.values[vIndex] =
                      list[listIndex].discreteTranslation.on;
                  } else if (value === "0.0") {
                    param.values[vIndex] =
                      list[listIndex].discreteTranslation.off;
                  }
                });
              }
            }
          });

          CSVData.push(headerRow);

          //Creating Data Rows
          response.data.dateData.forEach((date, index) => {
            let row = [];
            let longDate = moment
              .utc(date)
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            row.push(longDate);

            response.data.parameterData.forEach((param) => {
              row.push(param.values[index]);
            });

            CSVData.push(row);
          });

          setCsvData([...CSVData]);
        })
        .catch((error) => {
          console.error(`CSV API Export Error : ${error}`);
          setLoadingAhmsCsv(false);
        });
    }
  };

  React.useEffect(() => {
    setLoadingAhmsCsv(false);
    setLoadingCmsCsv(false);
    setTimeout(() => {
      if (csvData.length > 0) {
        csvBtnLink.current.link.click();
      }
    }, 2000);
  }, [csvData]);

  const areChartsDoneLoading = () => {
    let allLoaded = true;

    for (let i = 0; i < parametersToAdd.length; i++) {
      if (
        parametersToAdd[i].loaded === null ||
        parametersToAdd[i].loaded === false
      ) {
        allLoaded = false;
      }
    }

    if (allLoaded) {
      setUpdatingCharts(false);

      if (charts.length > 0 || snapshotParameters.length > 0) {
        changeTab("CHARTS");
        resetCharts();
      }
    }

    return allLoaded;
  };

  const zoomAllCharts = (xMinimum, xMaxmimum) => {
    let zoomStart = dataFocus === "CMS" ? cmsStartTime : startTime;
    let zoomEnd = dataFocus === "CMS" ? cmsEndTime : endTime;

    if (xMinimum && xMaxmimum) {
      zoomStart = xMinimum;
      zoomEnd = xMaxmimum;
    }
    // Updating options and zooming seems to keep the graphs from blanking out.
    for (let i = 0; i < charts.length; i++) {
      if (charts[i].unit === "Discrete") {
        charts[i].series.forEach((s) => {
          ApexCharts.exec(
            s.name,
            "updateOptions",
            {
              legend: {
                show: false,
              },
            },
            false,
            true
          );
          ApexCharts.exec(s.name, "zoomX", zoomStart, zoomEnd);
        });
      } else if (charts[i].unit === "Discrete Non-Binary") {
        charts[i].series.forEach((p) => {
          ApexCharts.exec(
            p.englishName,
            "updateOptions",
            {
              legend: {
                show: false,
              },
            },
            false,
            true
          );
          ApexCharts.exec(p.englishName, "zoomX", zoomStart, zoomEnd);
        });
      } else {
        ApexCharts.exec(
          charts[i].id,
          "updateOptions",
          {
            legend: {
              show: false,
            },
          },
          false,
          true
        );
        ApexCharts.exec(charts[i].id, "zoomX", zoomStart, zoomEnd);
      }
    }
  };

  const resetCharts = () => {
    for (let i = 0; i < charts.length; i++) {
      if (charts[i].unit === "Discrete") {
        charts[i].series.forEach((s) => {
          ApexCharts.exec(
            s.name,
            "updateOptions",
            {
              charts: {
                redrawOnWindowResize: true,
              },
            },
            true,
            true
          );
        });
      } else {
        ApexCharts.exec(
          charts[i].id,
          "updateOptions",
          {
            charts: {
              redrawOnWindowResize: true,
            },
          },
          true,
          true
        );
      }
    }
    zoomAllCharts(xZoom.min, xZoom.max);
  };

  const getUnit = (name) => {
    let index = _.findIndex(parametersToAdd, function(p) {
      return p.parameterName === name;
    });
    if (index !== -1) {
      if (parametersToAdd[index].parameterType === "discrete") {
        return "Discrete";
      } else {
        return parametersToAdd[index].unitOfMeasure;
      }
    } else {
      return "Unknown";
    }
  };

  const addChart = (series) => {
    const index = charts.length;

    const options = getLineChartOptions(
      dataFocus === "CMS" ? cmsStartTime : startTime,
      dataFocus === "CMS" ? cmsEndTime : endTime,
      faultTimeStamp,
      "line-" + index,
      zoomAllCharts,
      null, //TODO: This has to change
      series.unit === "Discrete" ? "Discrete" : "Line",
      settingZoomCoord,
      series.unit === "Discrete" ? getParametersToAdd(series.name) : null,
      getParameterUnits
    );

    let newChart = getChartObject(
      series,
      index,
      options,
      faultTimeStamp,
      severityColor
    );

    charts.push(newChart);
    setCharts([...charts]);
  };

  const addNonBinaryDiscreteChart = (series) => {
    const index = charts.length;
    const discreteTranslation = getParametersToAdd(series.englishName);

    const options = getLineChartOptions(
      dataFocus === "CMS" ? cmsStartTime : startTime,
      dataFocus === "CMS" ? cmsEndTime : endTime,
      faultTimeStamp,
      "line-" + index,
      zoomAllCharts,
      null, //TODO: This has to change
      "Discrete Non-Binary",
      settingZoomCoord,
      discreteTranslation,
      null,
      series.englishName
    );

    let newChart = getDiscreteNonBinaryChartObject(
      index,
      options,
      faultTimeStamp,
      severityColor,
      series
    );

    charts.push(newChart);
    setCharts([...charts]);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the a dropable area.
    if (!destination) {
      return;
    }

    // Find the chart that the parameter belongs to.
    let sourceIndex = _.findIndex(charts, function(chart) {
      return chart.id === source.droppableId;
    });

    // Find the destination chart that the parameter will be placed at.
    let destinationIndex = _.findIndex(charts, function(chart) {
      return chart.id === destination.droppableId;
    });

    if (sourceIndex !== -1 && destinationIndex !== -1) {
      if (charts[destinationIndex].series.length < 10) {
        let parameter = charts[sourceIndex].series.splice(source.index, 1);
        // allCharts[sourceIndex].options.tempSeries =
        //   allCharts[sourceIndex].params;

        charts[destinationIndex].series.splice(
          destination.index,
          0,
          parameter[0]
        );

        setCharts([...charts]);

        setOnDragEndDetails({
          onDragStartIndex: sourceIndex,
          onDragEndIndex: destinationIndex,
        });
      } else {
        console.error("Parameter cap per chart reached.");
      }
    } else {
      console.error("Not Working!");
    }
  };

  React.useEffect(() => {
    if (
      onDragEndDetails.onDragStartIndex !== null &&
      onDragEndDetails.onDragEndIndex !== null
    ) {
      const originChart = charts[onDragEndDetails.onDragStartIndex];
      const destinationChart = charts[onDragEndDetails.onDragEndIndex];

      const originChartId = originChart.id;
      const destinationChartId = destinationChart.id;

      // Update the end/destination chart if they are different charts
      if (originChartId !== destinationChartId) {
        // Update series
        ApexCharts.exec(
          destinationChartId,
          "updateSeries",
          destinationChart.series
        );

        // Update the toggles on the series
        destinationChart.series.forEach((p) => {
          if (p.toggleState === "eye-off") {
            ApexCharts.exec(destinationChartId, "toggleSeries", p.name);
          }
        });

        // Update the options
        ApexCharts.exec(
          destinationChartId,
          "updateOptions",
          destinationChart.options
        );
      }

      // Delete the start/origin chart if it will have no parameters left
      if (originChart.series.length <= 0) {
        const chartsToDelete = [
          {
            chartID: originChartId,
            type: "line",
            chartIndex: originChart.chartIndex,
          },
        ];

        deleteChart(chartsToDelete);
      } else {
        // Update the start/origin chart
        // Update the series
        ApexCharts.exec(originChartId, "updateSeries", originChart.series);

        // Update the toggles on the series
        originChart.series.forEach((p) => {
          if (p.toggleState === "eye-off") {
            ApexCharts.exec(originChartId, "toggleSeries", p.name);
          }
        });

        // Update the options
        ApexCharts.exec(originChartId, "updateOptions", originChart.options);
      }
    }
  }, [onDragEndDetails]);

  const addParameter = (series) => {
    let isCharted = false;
    for (let i = 0; i < charts.length; i++) {
      if (charts[i].unit === series.unit && charts[i].series.length < 10) {
        isCharted = true;
        charts[i].series.push(series);
        ApexCharts.exec(charts[i].id, "updateSeries", charts[i].series);
        setCharts([...charts]);
      }
    }
    return isCharted;
  };

  const addSnapshotParameter = (series) => {
    snapshotParameters.push(series);
    setSnapshotParameters([...snapshotParameters]);
  };

  const moveItem = (from, to) => {
    // remove `from` item and store it
    let f = charts.splice(from, 1)[0];
    // insert stored item into position `to`
    charts.splice(to, 0, f);
    setCharts([...charts]);
  };

  const moveChart = (direction, index) => {
    if (direction === "DOWN") {
      if (index < charts.length) {
        moveItem(index, index + 1);
      }
    } else if (direction === "UP") {
      if (index > 0) {
        moveItem(index, index - 1);
      }
    }
  };

  // Method for fetching XLSX chart
  const exportChartAsExcel = (parameterName, chartId) => {
    try {
      // Getting a charts series
      const series = charts.filter((chart) => {
        return chart.id === chartId;
      })[0].series;

      // Finding the parameter data for the specified parameter in the chart series
      const parameterData = series.filter((s) => {
        return s.name === parameterName;
      })[0];

      // Creating Titles Array
      const titles = [parameterData.name];

      // Mapping the date from the data for the fields in the excelDataStructure
      const fields = parameterData.data.map((p) => {
        return p[0];
      });

      // Creating Data Object in excel Data Structure
      const data = {};
      data[parameterData.name] = {};

      for (let i = parameterData.data.length - 1; i >= 0; i--) {
        data[parameterData.name][parameterData.data[i][0]] =
          parameterData.data[i][1];
      }

      const excelDataStructure = {
        titles: titles,
        fields: fields,
        data: data,
      };

      getExcelReport(excelDataStructure)
        .then((response) => {
          var bblob = new Blob([response.data], { type: "" });
          saveAs(bblob, parameterName + "_chart.xlsx");
        })
        .catch((err) => {
          console.error("ERROR: ", err);
        });
    } catch (error) {
      //TODO: ERROR HANDLING
      console.error(error);
    }
  };

  // Method for fetching XLSX chart for canvas charts
  const exportChartAsExcelForCanvas = (parameterName, chartId) => {
    try {
      // Getting a charts series
      const series = charts.filter((chart) => {
        return chart.id === chartId;
      })[0]?.options?.data;

      // Finding the parameter data for the specified parameter in the chart series
      const parameterData = series.filter((s) => {
        return s.name === parameterName;
      })[0];

      // Creating Titles Array
      const titles = [parameterData.name];
      // Mapping the date from the data for the fields in the excelDataStructure

      const fields = parameterData.dataPoints.map((p) => {
        return moment.utc(p.x).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      });

      // Creating Data Object in excel Data Structure
      const data = {};
      data[parameterData.name] = {};

      for (let i = parameterData.dataPoints.length - 1; i >= 0; i--) {
        data[parameterData.name][
          moment
            .utc(parameterData.dataPoints[i].x)
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        ] = parameterData.dataPoints[i].y;
      }

      const excelDataStructure = {
        titles: titles,
        fields: fields,
        data: data,
      };

      getExcelReport(excelDataStructure)
        .then((response) => {
          var bblob = new Blob([response.data], { type: "" });
          saveAs(bblob, parameterName + "_chart.xlsx");
        })
        .catch((err) => {
          console.error("ERROR: ", err);
        });
    } catch (error) {
      //TODO: ERROR HANDLING
      console.error(error);
    }
  };

  const getParameterLocation = (parameter) => {
    for (let i = 0; i < charts.length; i++) {
      const seriesIndex = _.findIndex(charts[i].series, function(series) {
        return series.name === parameter;
      });
      if (seriesIndex !== -1) {
        return {
          chartIndex: i,
          seriesIndex: seriesIndex,
          unit: charts[i].unit,
        };
      }
    }

    return {
      chartIndex: -1,
      seriesIndex: -1,
      unit: null,
    };
  };

  const uploadLocalTemplate = (file) => {
    let text;

    if (file) {
      let reader = new FileReader();
      reader.onload = function(event) {
        text = event.target.result;
        try {
          let templateJson = JSON.parse(text);
          loadTemplate(templateJson);
        } catch (error) {
          console.error("Error with processing file: ", error);
          setTemplateError(true);
        }
      };

      reader.readAsText(file);
    }
  };

  const removeParameter = (parameter, index) => {
    // If the index is null or undefined this was truggered from the chart legend and we find it a value from the "parametersToAdd" array
    if (!index) {
      index = _.findIndex(parametersToAdd, function(p) {
        return p.parameterName === parameter;
      });
    }

    // If there is a valid index, delete the parameterToAdd array
    if (index !== -1) {
      parametersToAdd.splice(index, 1);
      setParametersToAdd([...parametersToAdd]);
    }

    // Uncheck from list
    let pListIndex = _.findIndex(parameterList, function(p) {
      return p.parameterName === parameter;
    });

    if (pListIndex !== -1) {
      parameterList[pListIndex].checked = false;
      if (parameterList[pListIndex].type === "CMS Parameter") {
        setCmsParametersSelected(cmsParametersSelected - 1);
      } else if (parameterList[pListIndex].type === "Continuous Parameter") {
        setAhmsParametersSelected(ahmsParametersSelected - 1);
      }
    }

    setParameterList([...parameterList]);

    const parameterLocation = getParameterLocation(parameter);

    if (parameterLocation.chartIndex !== -1) {
      if (charts[parameterLocation.chartIndex].series.length > 1) {
        if (
          parameterLocation.seriesIndex === 0 &&
          charts[parameterLocation.chartIndex].unit === "Discrete"
        ) {
          ApexCharts.exec(
            charts[parameterLocation.chartIndex].series[
              parameterLocation.seriesIndex + 1
            ].name,
            "updateOptions",
            {
              xaxis: {
                labels: {
                  offsetX: 0,
                  offsetY: -3,
                  style: {
                    colors: "white",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    cssClass: "apexcharts-xaxis-label",
                  },
                  rotateAlways: false,
                  formatter(val, timestamp) {
                    return moment.utc(timestamp).format("HH:mm:ss.SSS");
                  },
                },
              },
            }
          );
        }

        charts[parameterLocation.chartIndex].series.splice(
          parameterLocation.seriesIndex,
          1
        );

        charts[parameterLocation.chartIndex].series.forEach((s, index) => {
          const colors = [
            "#07B9FC",
            "#FFB300",
            "#EA0034",
            "#9AF000",
            "#ec008c",
            "#0062FF",
            "#33FF00",
            "#FFFE37",
            "#FF6600",
            "#CC00FF",
          ];

          ApexCharts.exec(s.name, "updateOptions", {
            colors: [colors[index]],
          });
        });

        ApexCharts.exec(
          charts[parameterLocation.chartIndex].id,
          "updateSeries",
          charts[parameterLocation.chartIndex].series
        );
        setCharts([...charts]);
      } else {
        deleteChart([
          {
            chartID: charts[parameterLocation.chartIndex].id,
            type: charts[parameterLocation.chartIndex].type,
            chartIndex: charts[parameterLocation.chartIndex].chartIndex,
          },
        ]);
      }
    }
  };

  const deleteChart = (chartsToDelete) => {
    // Charts to delete must be an object with the following items chartID, type, chartIndex
    chartsToDelete.forEach((c) => {
      ApexCharts.exec(c.chartID, "destroy");
      _.remove(charts, {
        id: c.chartID,
      });
    });

    setCharts([...charts]);
  };

  const toggleParameterFn = (state, index, id) => {
    const chartIndex = _.findIndex(charts, function(chart) {
      return chart.id === id;
    });

    let toggleState = "eye";
    if (state === "eye") {
      toggleState = "eye-off";
    }
    charts[chartIndex].series[index].toggleState = toggleState;
    setCharts([...charts]);
  };

  const toggleAllCheckBoxes = (state) => {
    const maxParameterListLength =
      parameterList.length <= numParametersAllowed
        ? parameterList.length
        : numParametersAllowed;

    for (let i = 0; i < maxParameterListLength; i++) {
      parameterList[i].checked = !state;
      if (!state) {
        parameterList[i].loaded = null;
        parametersToAdd.push(parameterList[i]);
      }
    }
    if (state) {
      setParametersToAdd([]);
      changeTab("PARAMETERS");
    }
    setParameterList([...parameterList]);
  };

  // Get the additional info of a CMS parameter that will be shown in the parameter list table
  const getCMSParameterInfo = (selectedParameterName, rowId) => {
    getCmsParameterInfo(
      aircraftFamily,
      aircraftModel,
      tail,
      selectedParameterName,
      session
    )
        .then((response) => {
          if (response.data.null) {
            parameterList[rowId].info = {
              error: response.data,
            };
          } else {
            parameterList[rowId].info = response.data;
          }
          setParameterList([...parameterList]);
        })
        .catch((error) => {
          parameterList[rowId].info = {
            error: error,
          };
          setParameterList([...parameterList]);
        });
  };

  // This toggles the event parameter checkboxes
  const toggleEventParameterCheckBoxes = (checkedState) => {
    const maxParameterListLength =
      parameterList.length <= numParametersAllowed
        ? parameterList.length
        : numParametersAllowed;

    for (let i = 0; i < maxParameterListLength; i++) {
      // toggling all uncharted event parameters
      if (
        parameterList[i].type === "FDE Event Parameter"
      ) {
        parameterList[i].checked = !checkedState;

        parameterList[i].loaded = null;
        //parametersToAdd.push(parameterList[i]);
      }
    }

    /* Removing all non charted parameters from parameters overview section
    if (checkedState) {
      for (let j = parametersToAdd.length - 1; j >= 0; j--) {
        if (
          parametersToAdd[j].type === "FDE Event Parameter" &&
          parametersToAdd[j].loaded !== true
        ) {
          parametersToAdd.splice(j, 1);
        }
      }
    }*/

    setParameterList([...parameterList]);
    //setParametersToAdd([...parametersToAdd]);
  };

  const handleParameterListToggle = (id, parameter, state) => {
    // Toggling check box
    if (!state && parametersToAdd.length > numParametersAllowed) {
    } else {
      parameterList[id].checked = !state;
      setParameterList([...parameterList]);

      // Check if parameter is already charted
      let chartedIndex = _.findIndex(parametersToAdd, function(chartedParam) {
        return (
          chartedParam.parameterName === parameter.parameterName &&
          chartedParam.loaded !== null
        );
      });

      // If state is false we need to see if is already charted and if not chart it
      // If state is true we need to see if it is already charted, and if it is then add to remove list
      if (state) {
        if (parameter.type === "CMS Parameter") {
          setCmsParametersSelected(cmsParametersSelected - 1);
        } else if (parameter.type === "Continuous Parameter") {
          setAhmsParametersSelected(ahmsParametersSelected - 1);
        }

        let toAddIndex = _.findIndex(parametersToAdd, function(paramToAdd) {
          return paramToAdd.parameterName === parameter.parameterName;
        });

        if (chartedIndex !== -1) {
          parametersToRemove.push(parameter);
        }

        if (toAddIndex !== -1) {
          if (parametersToAdd[toAddIndex].loaded !== true) {
            parametersToAdd.splice(toAddIndex, 1);
          }
        }
      } else {
        if (parameter.type === "CMS Parameter") {
          setCmsParametersSelected(cmsParametersSelected + 1);
        } else if (parameter.type === "Continuous Parameter") {
          setAhmsParametersSelected(ahmsParametersSelected + 1);
        }

        let toRemoveIndex = _.findIndex(parametersToRemove, function(
          paramToRemove
        ) {
          return paramToRemove.parameterName === parameter.parameterName;
        });

        if (chartedIndex === -1) {
          parameter.loaded = null;
          parametersToAdd.push(parameter);
        }

        if (toRemoveIndex !== -1) {
          parametersToRemove.splice(toRemoveIndex, 1);
        }
      }

      setParametersToRemove([...parametersToRemove]);
      setParametersToAdd([...parametersToAdd]);
    }
  };

  // Function to sort functions by faultCode, then by date
  const sortTemplates = (templates) => {
    templates.sort(function(a, b) {
      const faultA = a.faultCode.toUpperCase();
      const faultB = b.faultCode.toUpperCase();

      if (faultA < faultB) {
        return -1;
      }

      if (faultA > faultB) {
        return 1;
      }

      if (faultA === faultB) {
        const dateA = new Date(a.createdDate);
        const dateB = new Date(b.createdDate);

        return dateB - dateA;
      }

      return 0;
    });

    return templates;
  };

  // This function is used to add n extar field to the template list that combines tempate and and created by.
  // This filed is used to be able to filter on materia table using both text
  const addTemplateNamePlusCreatedByField = (templates) => {
    for (let i = templates.length - 1; i >= 0; i--) {
      templates[i].templateNamePlusCreatedBy =
        templates[i].templateName + " " + templates[i].createdBy;
    }
    return templates;
  };

  // Function to move relevant templates to the top
  const showRelevantTemplates = (templates) => {
    let templateType = "AHMS FULL FLIGHT";

    if (dataFocus === "FLIGHT") {
      templateType = "AHMS FULL FLIGHT";
    }

    if (dataFocus === "FAULT") {
      templateType = faultCode;
    }

    if (dataFocus === "CMS") {
      if (faultCode !== "N/A") {
        templateType = faultCode;
      } else {
        templateType = "CMS FULL SESSION";
      }
    }

    const priorityTemplates = [];

    for (let i = templates.length - 1; i >= 0; i--) {
      if (templates[i].faultCode === templateType) {
        priorityTemplates.push(templates.splice(i, 1)[0]);
      }
    }
    templates = priorityTemplates.reverse().concat(templates);
    return templates;
  };

  // Method to Fetch and load Continioulsy recorded parameters
  const loadContinouslyRecordedParameters = (eventParameters) => {
    getAllParameters(
      tail,
      startTime,
      endTime,
      aircraftModel,
      aircraftFamily
    ).then((res) => {
      // Setting Size of Chunks
      const chunkSize = 500;
      // Creating Array of Chunk Promises
      const parameterDataPromiseArray = [];
      // Figuring out number of Chunks
      const chunkArray = _.chunk(res.data, chunkSize);
      // Adding Promised to Promise Array
      for (let c = 0; c < chunkArray.length; c++) {
        parameterDataPromiseArray.push(
          getParameterGroupMetaData(
            tail,
            startTime,
            endTime,
            aircraftModel,
            aircraftFamily,
            chunkArray[c]
          )
        );
      }

      // Creating unit mapping to quickly grab parameter unit
      let existingUnitMapping = unitMapping;
      res.data.map((object) => {
        existingUnitMapping.set(object.parameter_name, object.parameter_unit);
      });
      setUnitMapping(existingUnitMapping);

      // Resolving Promises
      Promise.all(parameterDataPromiseArray)
        .then((res) => {
          // Creating storage for joining response from resolved promise array
          let parameters = [];

          for (let p of res) {
            if (p.data) {
              parameters = parameters.concat(p.data.parameters);
            }
          }

          parameterListSizes.continuousParameters = parameters.length;
          // looping through parameters and setting default checkbox value and parameter type
          for (var i = 0; i < parameters.length; i++) {
            if (parameters[i].unitOfMeasure === null) {
              parameters[i].unitOfMeasure = "Discrete";
            }
            _.assign(parameters[i], { checked: false });
            _.assign(parameters[i], { type: "Continuous Parameter" });
          }
          // setting parameters in state for modifications
          setParameterListSizes({ ...parameterListSizes });
          if (eventParameters) {
            // setParameterList([...parameterList.concat(parameters)]);
            parameters = eventParameters.concat(parameters);
          }

          setParameterList([...parameters]);
          setParametersLoaded(true);
        })
        .catch((error) => {
          if (eventParameters) {
            setParameterList([...eventParameters]);
          }
          console.error("ERROR ", error);
          setParametersLoaded(true);
        });
    });
  };

  function formatTimestamp(timestamp, includeTime = false) {
    if (includeTime) {
      return moment.utc(timestamp).format("MMM DD, YYYY HH:mm:ss");
    }
    return moment.utc(timestamp).format("MMM DD, YYYY");
  }

  // Fetching parameter list
  useEffect(() => {
    const crumb = {
      view: "/ahmsview",
      value: "Full Flight Parameters",
      isFault: false,
      faultCode: "",
      severityColor: "",
      faultMessage: "",
      faultDate: "",
    };
    // If Fault was selected, add event parameters
    if (dataFocus === "FAULT") {
      setParameterSelectionTabStates("Add Parameters");
      const faultFormattedTimestamp = formatTimestamp(faultTimeStamp, true);
      crumb.value = `${faultCode} ${faultMessage} ${faultFormattedTimestamp}`;
      crumb.isFault = true;
      crumb.faultCode = faultCode;
      crumb.severityColor = severityColor;
      crumb.faultMessage = faultMessage;
      crumb.faultDate = faultFormattedTimestamp;
      getParameters(
        tail,
        faultCode,
        startTime,
        endTime,
        aircraftFamily,
        aircraftModel,
        eventUid
      ).then((res) => {
        // Getting Event Parameter list
        let fdeFaultParameters = _.get(res, "data");

        if (fdeFaultParameters) {
          getParameterGroupMetaData(
            tail,
            startTime,
            endTime,
            aircraftModel,
            aircraftFamily,
            fdeFaultParameters,
            eventUid
          )
            .then((res) => {
              let parametersMetaData = _.get(res, "data.parameters");

              if (parametersMetaData) {
                parameterListSizes.fdeFaultParameters =
                  parametersMetaData.length;
                // looping through parameters and setting default checkbox value and parameter type
                for (var i = 0; i < parametersMetaData.length; i++) {
                  if (parametersMetaData[i].unitOfMeasure === null) {
                    parametersMetaData[i].unitOfMeasure = "Discrete";
                  }
                  _.assign(parametersMetaData[i], { checked: false });
                  _.assign(parametersMetaData[i], {
                    type: "FDE Event Parameter",
                  });
                  _.assign(parametersMetaData[i], { loaded: null });
                }

                loadContinouslyRecordedParameters(parametersMetaData);
              }

              if (parametersMetaData.length > 0) {
                getEventDuration(
                  tail,
                  faultCode,
                  faultTimeStamp,
                  aircraftModel,
                  aircraftFamily
                ).then((response) => {
                  const duration = _.get(response, "data.maxDurationPadding");
                  if (duration) {
                    let durationMilliSeconds = duration * 1000;
                    startTime = new Date(faultTimeStamp) - durationMilliSeconds;
                    endTime =
                      new Date(faultTimeStamp).getTime() + durationMilliSeconds;
                    setFdeEventDuration(duration < 5 ? 5 : duration);
                  }
                });
              }
            })
            .catch(() => {
              loadContinouslyRecordedParameters();
            });
        }

        getChartsTemplateList(aircraftModel, aircraftFamily).then((res) => {
          let templatesResponse = _.get(res, "data");
          let templates = templatesResponse.templateList;
          templates.forEach((template) => {
            _.assign(template, { parameters: [] });
          });

          templates = addTemplateNamePlusCreatedByField(templates);
          templates = sortTemplates(templates);
          templates = showRelevantTemplates(templates);

          if (templates) {
            setTemplateList(templates);
          }
        });

        getATAChapterList(aircraftFamily, aircraftModel).then((res) => {
          let ataChapterList = _.get(res, "data");
          if (ataChapterList) {
            setAtaChapterList([...ataChapterList.ataChapters]);
          }
        });
      });
    } else if (dataFocus === "FLIGHT") {
      setParameterSelectionTabStates("Add Parameters");
      loadContinouslyRecordedParameters();

      getChartsTemplateList(aircraftModel, aircraftFamily, true).then((res) => {
        let templatesResponse = _.get(res, "data");
        let templates = templatesResponse.templateList;
        templates.forEach((template) => {
          _.assign(template, { parameters: [] });
        });
        templates = addTemplateNamePlusCreatedByField(templates);
        templates = sortTemplates(templates);
        templates = showRelevantTemplates(templates);
        if (templates) {
          setTemplateList(templates);
        }
      });

      getATAChapterList(aircraftFamily, aircraftModel).then((res) => {
        let ataChapterList = _.get(res, "data");
        if (ataChapterList) {
          setAtaChapterList([...ataChapterList.ataChapters]);
        }
      });
    } else if (dataFocus === "RPD") {
      for (var i = 0; i < rpdRequestParameters.length; i++) {
        if (rpdRequestParameters[i].unitOfMeasure === null) {
          rpdRequestParameters[i].unitOfMeasure = "Discrete";
        }
        _.assign(rpdRequestParameters[i], { checked: false });
        _.assign(rpdRequestParameters[i], { type: "RPD Request Parameter" });
        _.assign(rpdRequestParameters[i], { loaded: null });
      }
      setParametersToAdd([...rpdRequestParameters]);
      setParameterSelectionTabStates("Parameters Overview");
    } else if (dataFocus === "CMS") {
      //If a fault is not selected grab altitude data
      const queryFaultCode = faultCode ? faultCode : null;
      if (queryFaultCode && queryFaultCode !== "N/A") {
        const faultFormattedTimestamp = formatTimestamp(faultTimeStamp, true);
        crumb.value = `${faultMessage} ${faultFormattedTimestamp}`;
        crumb.isFault = true;
        crumb.faultCode = faultCode !== "N/A" ? faultCode : "";
        crumb.severityColor = severityColor;
        crumb.faultMessage = faultMessage;
        crumb.faultDate = faultFormattedTimestamp;
      } else {
        crumb.value = "CMS Full Session";
      }

      getAHMUParameterData(
        tail,
        aircraftFamily,
        aircraftModel,
        moment.utc(startTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        moment.utc(endTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        {
          parameterName: "Pressure Altitude.ADSP_1.8718208.12",
          ATAChapter: "34-11",
          parameterEnglishName: "Pressure Altitude",
          lru: "ADSP_1",
        },
        queryFaultCode,
        getParametersToAdd,
        null,
        400
      ).then((data) => {
        setAltitudeData(data.data[0]);
      });

      setParameterSelectionTabStates("Add Parameters");
      getCmsParameters(
        tail,
        aircraftFamily,
        aircraftModel,
        session,
        faultCode === "N/A" ? "*" : faultCode
      ).then((res) => {
        // Getting Event Parameter list
        let cmsParameters = _.get(res, "data.parameters");
        if (cmsParameters) {
          // The validations on water waste and dfcu systems was added to avoid their use in production
          // since they are still under development by the ahmu team
          /*_.remove(cmsParameters, {
            unitOfMeasure: "IDENTIFIER",
          });*/

          _.remove(cmsParameters, function(parameter) {
            if (parameter.unitOfMeasure === "IDENTIFIER") {
              return true;
            }
            if (
              process.env.REACT_APP_ENVIRONMENT === "PROD" ||
              process.env.REACT_APP_ENVIRONMENT === "PRE-PROD"
            ) {
              const waterWasteAta = [
                "38-00",
                "38-10",
                "38-14",
                "38-20",
                "38-30",
                "38-31",
                "38-40",
              ];

              if (
                waterWasteAta.includes(parameter.ATAChapter) &&
                parameter.systemName === "Waste & Water System"
              ) {
                return true;
              }
            }

            return false;
          });

          parameterListSizes.cmsParameters = cmsParameters.length;
          // looping through parameters and setting default checkbox value and parameter type

          for (var i = 0; i < cmsParameters.length; i++) {
            if (cmsParameters[i].unitOfMeasure === null) {
              cmsParameters[i].unitOfMeasure = "Discrete";
            }

            if (cmsParameters[i].parameterType === "discrete-non-binary") {
              cmsParameters[i].unitOfMeasure = "Discrete Non-binary";
            }
            _.assign(cmsParameters[i], { checked: false });
            _.assign(cmsParameters[i], { type: "CMS Parameter" });
          }

          // Creating unit mapping to quickly grab parameter unit for CMS
          let existingUnitMapping = unitMapping;
          cmsParameters.map((object) => {
            existingUnitMapping.set(object.parameterName, object.unitOfMeasure);
          });
          setUnitMapping(existingUnitMapping);

          loadContinouslyRecordedParameters(cmsParameters);
        }

        getATAChapterList(aircraftFamily, aircraftModel).then((res) => {
          let ataChapterList = _.get(res, "data");
          if (ataChapterList) {
            setAtaChapterList([...ataChapterList.ataChapters]);
          }
        });

        getChartsTemplateList(aircraftModel, aircraftFamily).then((res) => {
          let templatesResponse = _.get(res, "data");
          let templates = templatesResponse.templateList;
          templates.forEach((template) => {
            _.assign(template, { parameters: [] });
          });

          templates = addTemplateNamePlusCreatedByField(templates);
          templates = sortTemplates(templates);
          templates = showRelevantTemplates(templates);

          if (templates) {
            setTemplateList(templates);
          }
        });
      });
    } else if (dataFocus === "IN-FLIGHT") {
      changeTab("PARAMETERS");
      const faultFormattedTimestamp = formatTimestamp(faultTimeStamp, true);
      crumb.value = `${faultCode} ${faultMessage} ${faultFormattedTimestamp}`;
      crumb.isFault = true;
      crumb.faultCode = faultCode;
      crumb.severityColor = severityColor;
      crumb.faultMessage = faultMessage;
      crumb.faultDate = faultFormattedTimestamp;
      getParameters(
        tail,
        faultCode,
        startTime,
        endTime,
        aircraftFamily,
        aircraftModel,
        eventUid
      ).then((res) => {
        // Getting Event Parameter list
        let fdeFaultParameters = _.get(res, "data");

        if (fdeFaultParameters) {
          getParameterGroupMetaData(
            tail,
            startTime,
            endTime,
            aircraftModel,
            aircraftFamily,
            fdeFaultParameters,
            eventUid
          )
            .then((res) => {
              let parametersMetaData = _.get(res, "data.parameters");

              if (parametersMetaData) {
                parameterListSizes.fdeFaultParameters =
                  parametersMetaData.length;
                // looping through parameters and setting default checkbox value and parameter type
                for (var i = 0; i < parametersMetaData.length; i++) {
                  if (parametersMetaData[i].unitOfMeasure === null) {
                    parametersMetaData[i].unitOfMeasure = "Discrete";
                  }
                  _.assign(parametersMetaData[i], { checked: false });
                  _.assign(parametersMetaData[i], {
                    type: "FDE Event Parameter",
                  });
                  _.assign(parametersMetaData[i], { loaded: null });
                }

                if (parametersMetaData) {
                  setParameterList([...parametersMetaData]);
                }
                setParametersLoaded(true);
              }

              if (parametersMetaData.length > 0) {
                getEventDuration(
                  tail,
                  faultCode,
                  faultTimeStamp,
                  aircraftModel,
                  aircraftFamily
                ).then((response) => {
                  const duration = _.get(response, "data.maxDurationPadding");
                  if (duration) {
                    let durationMilliSeconds = duration * 1000;
                    startTime = new Date(faultTimeStamp) - durationMilliSeconds;
                    endTime =
                      new Date(faultTimeStamp).getTime() + durationMilliSeconds;
                    setFdeEventDuration(duration < 5 ? 5 : duration);
                  }
                });
              }
            })
            .catch((error) => {
              tools.consoleLogErrorOutput(
                "Unable to load event parameters.",
                error
              );
              setParametersLoaded(true);
            });
        }

        getChartsTemplateList(aircraftModel, aircraftFamily).then((res) => {
          let templatesResponse = _.get(res, "data");
          let templates = templatesResponse.templateList;
          templates.forEach((template) => {
            _.assign(template, { parameters: [] });
          });

          templates = addTemplateNamePlusCreatedByField(templates);
          templates = sortTemplates(templates);
          templates = showRelevantTemplates(templates);

          if (templates) {
            setTemplateList(templates);
          }
        });

        getATAChapterList(aircraftFamily, aircraftModel).then((res) => {
          let ataChapterList = _.get(res, "data");
          if (ataChapterList) {
            setAtaChapterList([...ataChapterList.ataChapters]);
          }
        });
      });
    }

    setAhmsCrumb(crumb);

    return () => true;
  }, []);

  const resizeLegend = () => {
    setExpandedLegend(!expandedLegend);
  };

  const getNearestXValues = (xVal, dps1) => {
    return [].concat(dps1).sort(function(a, b) {
      var diffA = Math.abs(xVal - a.x);
      var diffB = Math.abs(xVal - b.x);
      return diffA - diffB; // sort a before b when the distance is smaller
    })[0].x;
  };

  /*
    SECTION: Canvas JS Charting
    The following code handles all canvas JS charting.
    TODO: For the time being the code cannot be used in child components because of rendering issues.
          This code should later be reviewd to find a solution and add it to child components
  */
  /* CVSJS */

  // Similar to componentDidMount and componentDidUpdate:
  // Needed because the charts to initially render correctly
  React.useEffect(() => {
    for (let chart of canvasCharts) {
      if (chart) {
        chart.options.rangeChanged = syncHandler;
      }
    }

    var syncedCharts = canvasCharts;
    for (let i = 0; i < syncedCharts.length; i++) {
      var chart = syncedCharts[i];

      //Syncing charts and making sure things are updates correctly on render
      if (chart) {
        chart.options.toolTip = {
          shared: true,
          borderColor: "#808080",
          updated: function(e) {
            for (let j = 0; j < charts.length; j++) {
              if (canvasCharts[j] != e.chart && canvasCharts[j]) {
                canvasCharts[j].toolTip.showAtX(e.entries[0].xValue);
              }
            }
          },
          hidden: function(e) {
            for (let j = 0; j < charts.length; j++) {
              if (canvasCharts[j] != e.chart && canvasCharts[j]) {
                canvasCharts[j].toolTip.hide();
              }
            }
          },
        };

        chart.render();
      }
    }
  });

  //Colors to use in Charts
  const parameterColorArray = [
    "#07B9FC",
    "#FFB300",
    "#EA0034",
    "#9AF000",
    "#ec008c",
    "#0062FF",
    "#33FF00",
    "#FFFE37",
    "#FF6600",
    "#CC00FF",
  ];

  // method to add parameter
  const syncHandler = (e) => {
    var syncedCharts = canvasCharts;
    for (let i = 0; i < syncedCharts.length; i++) {
      let chart = syncedCharts[i];

      if (chart) {
        if (!chart.options.axisX) chart.options.axisX = {};

        if (!chart.options.axisY) chart.options.axisY = {};

        if (e.trigger === "reset") {
          chart.options.axisX.viewportMinimum = chart.options.axisX.viewportMaximum = null;
          chart.options.axisY.viewportMinimum = chart.options.axisY.viewportMaximum = null;

          chart.render();
        } else if (chart !== e.chart) {
          chart.options.axisX.viewportMinimum = e.axisX[0].viewportMinimum;
          chart.options.axisX.viewportMaximum = e.axisX[0].viewportMaximum;

          chart.options.axisY.viewportMinimum = e.axisY[0].viewportMinimum;
          chart.options.axisY.viewportMaximum = e.axisY[0].viewportMaximum;

          chart.render();
        }
      }
    }
  };

  // Get the list style for the drag and drop component
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "rgba(173, 216, 230, 0.7)" : "transparent",
    width: "100%",
    border: "none",
  });

  // Get the item style for the drag and drop component
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "rgba(144, 238, 144, 0.9)" : "transparent",
    ...draggableStyle,
  });

  const onCanvasDragEnd = (result) => {
    const { source, destination } = result;
    const tempCharts = canvasCharts;

    // Dropped outside the a dropable area.
    if (!destination) {
      return;
    }

    // Find the chart that the parameter belongs to.
    let sourceIndex = _.findIndex(charts, function(chart) {
      return chart.id === source.droppableId;
    });

    if (sourceIndex === -1) {
      return;
    }

    // This condition is so that Discrete charts do not drag and drop
    if (
      charts[sourceIndex].unit === "Discrete" ||
      charts[sourceIndex].unit === "Discrete Non-binary" ||
      charts[sourceIndex].unit === undefined
    ) {
      return;
    }

    // Find the destination chart that the parameter will be placed at.
    let destinationIndex = _.findIndex(charts, function(chart) {
      return chart.id === destination.droppableId;
    });

    if (destinationIndex === -1) {
      return;
    }

    // This condition is so that Discrete charts do not drag and drop
    if (
      charts[destinationIndex].unit === "Discrete" ||
      charts[destinationIndex].unit === undefined
    ) {
      return;
    }

    if (charts[destinationIndex].options.data.length >= 10) {
      return;
    }

    let movedItem = tempCharts[sourceIndex].options.data[source.index];
    // tempCharts[sourceIndex].options.data.splice(source.index, 1);
    let paramDestinationIndex = destination.index;
    if (destination.index > source.index && sourceIndex === destinationIndex) {
      paramDestinationIndex++;
    }

    tempCharts[destinationIndex].options.data.splice(
      paramDestinationIndex,
      0,
      movedItem
    );
    tempCharts[destinationIndex].render();
    let paramSourceIndex = source.index;
    if (
      paramSourceIndex > destination.index &&
      sourceIndex === destinationIndex
    ) {
      paramSourceIndex++;
    }

    removeCanvasParameter(sourceIndex, paramSourceIndex);

    /*
        let parameter = charts[sourceIndex].series.splice(source.index, 1);

        charts[destinationIndex].series.splice(
          destination.index,
          0,
          parameter[0]
        );

        setCharts([...charts]);

        setOnDragEndDetails({
          onDragStartIndex: sourceIndex,
          onDragEndIndex: destinationIndex,
        });
    */
  };

  // This method toggles the legend
  const canvasLegendToggle = (chartIndex, seriesIndex) => {
    const selectedChart = canvasCharts[chartIndex];

    charts[chartIndex].options.data[seriesIndex].toggled = !charts[chartIndex]
      .options.data[seriesIndex].toggled;

    if (
      typeof selectedChart.options.data[seriesIndex].visible === "undefined" ||
      selectedChart.options.data[seriesIndex].visible
    ) {
      selectedChart.options.data[seriesIndex].visible = false;
    } else {
      selectedChart.options.data[seriesIndex].visible = true;
    }

    selectedChart.render();
    setCharts([...charts]);
  };

  // Remove a parameter from the legend of the canvas js charts
  const removeCanvasParameter = (chartIndex, seriesIndex) => {
    let tempCanvasCharts = canvasCharts;

    // Find the parameter to be removed from the main charts state variable
    const parameterToRemove = charts[chartIndex].options.data[seriesIndex].name;

    // Remove the parameter from the main charts state variable
    // This will also remove the value in tempCanvasCharts
    charts[chartIndex].options.data.splice(seriesIndex, 1);
    // TODO: tempCanvasCharts[chartIndex].render();

    // Delete the chart if the parameter being removed is the last one.
    if (tempCanvasCharts[chartIndex].options.data.length === 0) {
      removeCanvasChart(chartIndex);
    }

    // Update the parameter list values to match the removal of parameters in Canvas
    let parameterIsPresent = false;
    _.forEach(charts, (chart) => {
      _.forEach(chart.options.data, (parameter) => {
        if (parameter.name === parameterToRemove) {
          parameterIsPresent = true;
        }
      });
    });
    if (!parameterIsPresent) {
      canvasUpdateParameterOverview(parameterToRemove);
    }

    // Set the main charts state variable
    setCharts([...charts]);
  };

  // Remove a canvas chart
  const removeCanvasChart = (chartIndex) => {
    // Save the chart state variable value of the removed chart
    const removedChartOptions = charts[chartIndex];

    // Loop through the canvas chart state variable to move all charts after the rmeoved one an array space up
    for (var cIndex = chartIndex; cIndex < canvasCharts.length - 1; cIndex++) {
      // Save chart options of both chart state variable
      const newChartOptions = charts[cIndex + 1];
      const newCanvasChartOptions = canvasCharts[cIndex + 1];

      // Replace chart options of both chart state variable
      charts[cIndex] = newChartOptions;
      canvasCharts[cIndex] = newCanvasChartOptions;

      // The charts that are changing must be re-rendered by canvas in order to get the changes
      if (canvasCharts[cIndex]) {
        canvasCharts[cIndex].render();
      }
    }

    // Save the values of the removed chart into the last space on each chart state variables (NULL for the canvas)
    charts[canvasCharts.length - 1] = removedChartOptions;
    canvasCharts[canvasCharts.length - 1] = null;

    /*
    TODO: This code might be needed later on
    const chartId = charts[chartIndex].id;
    const el = document.getElementById(legend[chartIndex].id);
    el.style.display = "none";
    */

    setCharts([...charts]);
    setCanvasChartCount(canvasChartCount - 1);
  };

  const handleChartTitleOffFocus = (chartIndex, newChartName) => {
    charts[chartIndex].chartName = newChartName;
    setCharts([...charts]);
  };

  // This method moves the chart up or down
  const moveCanvasChart = (direction, index) => {
    direction = direction.toUpperCase();
    // Initializing indexes of the two charts switching
    const sourceIndex = index;
    let destinationIndex = null;

    let tempChartIndex;
    if (direction === "UP") {
      tempChartIndex = sourceIndex - 1;
      while (destinationIndex === null && tempChartIndex >= 0) {
        if (charts[tempChartIndex].options.data.length > 0) {
          destinationIndex = tempChartIndex;
        }
        tempChartIndex--;
      }
    } else if (direction === "DOWN") {
      tempChartIndex = sourceIndex + 1;
      while (destinationIndex === null && tempChartIndex < charts.length) {
        if (charts[tempChartIndex].options.data.length > 0) {
          destinationIndex = tempChartIndex;
        }
        tempChartIndex++;
      }
    }

    // Determines the index of the chart that the source chart is switching with
    if (destinationIndex !== null) {
      // If the move is valid then move the chart up or down
      const sourceChart = charts[sourceIndex];
      const destinationChart = charts[destinationIndex];

      const sourceOptions = canvasCharts[sourceIndex];
      const destinationOptions = canvasCharts[destinationIndex];

      // The charts that are changing must be re-rendered by canvas in order to get the changes
      canvasCharts[sourceIndex] = destinationOptions;
      canvasCharts[destinationIndex] = sourceOptions;

      canvasCharts[sourceIndex].render();
      canvasCharts[destinationIndex].render();

      charts[sourceIndex] = destinationChart;
      charts[destinationIndex] = sourceChart;

      setCharts([...charts]);
    }
  };

  // Funtion that handles the chart collapse action
  const setChartCollapsed = (chartIndex, newCollapsedValue) => {
    if (chartIndex !== null) {
      charts[chartIndex].isCollapsed = newCollapsedValue;

      setCharts([...charts]);
    }
  };

  const canvasChartsRender = () => {
    let chartCount = 0;
    return (
      <div className="canvasjs__charts-container">
        <div className="canvasjs__charts-title">
          {loadedTemplate && (
            <div style={{ marginRight: "16px" }}>
              {loadedTemplate.templatePrivacyPolicy === "PRIVATE" && (
                <FeatherIcon icon="user" height={24} />
              )}
              {loadedTemplate.templatePrivacyPolicy === "INT_PUBLIC" && (
                <FeatherIcon icon="users" height={24} />
              )}
              {loadedTemplate.templatePrivacyPolicy === "EXT_PUBLIC" && (
                <FeatherIcon icon="star" width={24} height={24} />
              )}
            </div>
          )}
          <span>
            {chartingComponentTools.getChartTabContentHeader(
              dataFocus,
              faultCode,
              loadedTemplate
            )}
          </span>
        </div>
        <DragDropContext onDragEnd={onCanvasDragEnd}>
          {charts.map(
            (chart, cIndex) =>
              chart && (
                <React.Fragment key={chart.id}>
                  <div
                    className={
                      "canvasjs__chart__container" +
                      (chart.options.data.length <= 0 ? "-noDisplay" : "")
                    }
                    id={chart.id}
                  >
                    <div className="canvasjs__chart__container__header">
                      <div className="canvasjs__chart__container__header__collapse">
                        <FeatherIcon
                          icon={
                            chart.isCollapsed ? "chevron-down" : "chevron-up"
                          }
                          height={30}
                          className="canvasjs__chart__container__header__collapse-icon"
                          onClick={(event) => {
                            setChartCollapsed(cIndex, !chart.isCollapsed);
                          }}
                        />
                      </div>
                      <ChartNameController
                        chartId={chart.chartId}
                        chartIndex={cIndex}
                        chartName={chart.chartName}
                        handleChartTitleOffFocus={handleChartTitleOffFocus}
                      />
                      <div className="canvasjs__chart__container__header__displace">
                        <div className="canvasjs__chart__container__header__displace__section">
                          <FeatherIcon
                            icon={"arrow-up-circle"}
                            height={30}
                            className={
                              "canvasjs__chart__container__header__displace-icon" +
                              (chartCount === 0 ? "-disabled" : "")
                            }
                            disabled={chartCount === 0 ? true : false}
                            onClick={(event) => moveCanvasChart("UP", cIndex)}
                          />
                          <FeatherIcon
                            icon={"arrow-down-circle"}
                            height={30}
                            className={
                              "canvasjs__chart__container__header__displace-icon" +
                              (chartCount === canvasChartCount
                                ? "-disabled"
                                : "")
                            }
                            disabled={
                              chartCount === canvasChartCount ? true : false
                            }
                            onClick={(event) => moveCanvasChart("DOWN", cIndex)}
                          />
                        </div>
                        <div className="canvasjs__chart__container__header__displace__section">
                          {chart.options.data.length > 0
                            ? (chartCount = chartCount + 1).toString() +
                              " of " +
                              canvasChartCount
                            : ""}
                        </div>
                      </div>
                      {/*chart.unit !== "Discrete" &&
                        chart.unit !== "Discrete Non-Binary" && (
                          <div className="canvasjs__chart__container__header__fullScreen">
                            <FullChart
                            //modalId={state.chartModalId}
                            //series={state.series}
                            //chartName={chartName}
                            //faultTime={props.faultTimeStamp}
                            />
                          </div>
                        )*/}
                    </div>
                    <div
                      className={
                        "canvasjs__chart__container__body" +
                        (chart.isCollapsed ? "-collapsed-true" : "")
                      }
                    >
                      <div className="canvasjs__chart__legend__container">
                        <div className="canvasjs__chart__legend__header">
                          <div className="canvasjs__chart__legend__header__parameter">
                            Parameter
                          </div>
                          <div className="canvasjs__chart__legend__header__actions">
                            <FeatherIcon icon={"edit-3"} height={20} />
                          </div>
                          <div className="canvasjs__chart__legend__header__toggle">
                            {chart.chartType === "continuous-line" && (
                              <FeatherIcon icon={"eye"} height={20} />
                            )}
                          </div>
                        </div>
                        <Droppable droppableId={chart.id}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              <div className="canvasjs__chart__legend__params__container">
                                {chart.options.data.map(
                                  (series, index) =>
                                    ((chart.chartType ===
                                      "discrete-nonbinary-line" &&
                                      index === 0) ||
                                      chart.chartType !==
                                        "discrete-nonbinary-line") && (
                                      <React.Fragment key={series.name}>
                                        <Draggable
                                          key={
                                            chart.chartType ===
                                            "continuous-line"
                                              ? series.name
                                              : chart.parameterName
                                          }
                                          draggableId={
                                            chart.chartType ===
                                            "continuous-line"
                                              ? series.name
                                              : chart.parameterName
                                          }
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            >
                                              <div className="canvasjs__chart__legend__params">
                                                <div className="canvasjs__chart__legend__params__name">
                                                  <CustomTooltip
                                                    tooltipType={"general"}
                                                    content={
                                                      chart.chartType ===
                                                      "continuous-line"
                                                        ? series.name
                                                        : chart.parameterName
                                                    }
                                                    placement={"top-start"}
                                                  >
                                                    <p>
                                                      {chart.chartType ===
                                                      "continuous-line"
                                                        ? series.name
                                                        : chart.parameterName +
                                                          (series.unit.toUpperCase() !==
                                                            "DISCRETE" &&
                                                          series.unit.toUpperCase() !==
                                                            "DISCRETE-NON-BINARY"
                                                            ? " (" +
                                                              series.unit +
                                                              ")"
                                                            : "")}
                                                    </p>
                                                  </CustomTooltip>
                                                </div>
                                                <div className="canvasjs__chart__legend__params__actions">
                                                  <MenuMoreOptions
                                                    chartId={chart.id}
                                                    parameterName={series.name}
                                                    downloadXLSX={() => {
                                                      exportChartAsExcelForCanvas(
                                                        series.name,
                                                        chart.id
                                                      );
                                                    }}
                                                    removeParam={() => {
                                                      removeCanvasParameter(
                                                        cIndex,
                                                        index
                                                      );
                                                    }}
                                                  />
                                                </div>
                                                <div className="canvasjs__chart__legend__params__toggle">
                                                  {chart.chartType ===
                                                    "continuous-line" && (
                                                    <div
                                                      className="canvasjs__chart__legend__pill"
                                                      style={{
                                                        border:
                                                          "1px solid " +
                                                          (!series.toggled
                                                            ? parameterColorArray[
                                                                index
                                                              ]
                                                            : "#ffffff"),

                                                        cursor: "pointer",
                                                        backgroundColor: !series.toggled
                                                          ? parameterColorArray[
                                                              index
                                                            ]
                                                          : "#000000",
                                                      }}
                                                      onClick={() => {
                                                        canvasLegendToggle(
                                                          cIndex,
                                                          index
                                                        );
                                                      }}
                                                    ></div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      </React.Fragment>
                                    )
                                )}
                              </div>
                            </div>
                          )}
                        </Droppable>
                      </div>
                      <div className="canvasjs__chart__container__inner">
                        <CanvasJSChart
                          options={chart.options}
                          onRef={(ref) => canvasCharts.push(ref)}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )}
        </DragDropContext>
      </div>
    );
  };

  /* SECTION: Component Rendering */
  return noPageData ? (
    <Redirect to={{ pathname: "/fleetview" }} />
  ) : (
    <div className="full__chart__container">
      {parametersLoaded ? (
        <Wizard
          iofgKey="NEW_USER_ONBOARDING"
          pageKey="PARAM_V_PG"
          specialAction={changeTabOnIndex}
        />
      ) : (
        <div></div>
      )}
      <div className="globalHeader__container">
        <GlobalHeader
          serialNumber={serialNumber}
          aircraftFamily={aircraftFamily}
          aircraftModel={aircraftModel}
          registration={tail}
          crumb={ahmsCrumb}
          timeInFlight={timeInFlight}
          latestDataTransferDate={latestDataTransferDate}
          hasLatestHmuTransferDate={hasLatestHmuTransferDate}
          dataFocus={dataFocus}
          faultDescription={faultDescription}
          currentAircraftStatus={currentStatus}
        />
      </div>
      <div className="charting__tab__container">
        {tabs.map((tab) => (
          <React.Fragment key={tab}>
            <div
              className={
                selectedTab === tab
                  ? "charting__tab charting__tab__selected"
                  : "charting__tab"
              }
              style={{
                display:
                  tab === "CHARTS" &&
                  charts.length === 0 &&
                  snapshotParameters.length === 0
                    ? "none"
                    : "block",
              }}
              onClick={() => {
                changeTab(tab);
              }}
              id={
                tab === "TEMPLATES"
                  ? "id_template_tab"
                  : tab === "PARAMETERS"
                  ? "id_parameters_tab"
                  : ""
              }
            >
              {tab}
            </div>
          </React.Fragment>
        ))}
        <div
          style={{
            width: "24%",
            height: "150%",
            position: "absolute",
            right: "0px",
          }}
          id={"id_save_template_export_data"}
        >
          <div
            style={{
              paddingLeft: "20px",
              display: dataFocus === "RPD" ? "none" : "block",
              marginLeft: dataFocus === "IN-FLIGHT" ? "auto" : "",
              position: "absolute",
              right: "0px",
            }}
          >
            <div style={{ display: "flex" }}>
              {dataFocus === "CMS" && (
                <div style={{ paddingRight: "20px" }}>
                  <CustomButton
                    id={"cms-csv-data"}
                    text={
                      loadingCmsCsv ? (
                        <div className="blink_me" id="output-2">
                          {"Loading... 0%"}
                        </div>
                      ) : (
                        "Export CMS Data"
                      )
                    }
                    isDisabled={
                      loadingCmsCsv ||
                      loadingAhmsCsv ||
                      cmsParametersSelected <= 0
                        ? true
                        : false
                    }
                    borderRadiusStyle={"squared"}
                    customWidth={"190px"}
                    customHeight={"50"}
                    colorStyle="secondary"
                    onClickAction={() => {
                      fetchCSVData("CMS");
                    }}
                  />
                </div>
              )}
              <div>
                <CustomButton
                  id={"ahms-csv-data"}
                  text={
                    loadingAhmsCsv ? (
                      <div className="blink_me" id="output-1">
                        {"Loading... 0%"}
                      </div>
                    ) : (
                      "Export AHMS Data"
                    )
                  }
                  isDisabled={
                    loadingAhmsCsv ||
                    loadingCmsCsv ||
                    ahmsParametersSelected <= 0
                      ? true
                      : false
                  }
                  borderRadiusStyle={"squared"}
                  customWidth={"190px"}
                  customHeight={"50"}
                  colorStyle="secondary"
                  onClickAction={() => {
                    fetchCSVData("AHMS");
                  }}
                />
              </div>
            </div>
          </div>
          <CSVLink
            data={csvData}
            filename={csvFileName}
            style={{ display: "none" }}
            ref={csvBtnLink}
            target={"_blank"}
          />
        </div>
        {usingTemplate && (
          <div
            style={{
              display:
                dataFocus === "RPD" || dataFocus === "IN-FLIGHT"
                  ? "none"
                  : "block",
              position: "absolute",
              right: dataFocus === "CMS" ? "585px" : "375px",
            }}
          >
            <CrcModalSave
              aircraftFamily={aircraftFamily}
              templateParameters={parametersToAdd}
              aircraftModel={aircraftModel}
              aircraftUIConfig={aircraftUIConfig}
              id="update-template-btn"
              ataChapterList={ataChapterList}
              openBtnLabel="Update"
              dataFocus={dataFocus}
              faultCode={faultCode}
              omsFaultCodes={omsFaultList}
              notes={faultDescription}
              saveTemplateLocal={saveTemplate}
              reloadTemplates={reloadTemplates}
              usingTemplate={usingTemplate}
              loadedTemplate={loadedTemplate}
            />
          </div>
        )}
        <div
          style={{
            display:
              dataFocus === "RPD" || dataFocus === "IN-FLIGHT"
                ? "none"
                : "block",
            position: "absolute",
            right: dataFocus === "CMS" ? "420px" : "210px",
          }}
        >
          <CrcModalSave
            aircraftFamily={aircraftFamily}
            templateParameters={parametersToAdd}
            aircraftModel={aircraftModel}
            aircraftUIConfig={aircraftUIConfig}
            id="save-template-btn"
            ataChapterList={ataChapterList}
            openBtnLabel="Save As New"
            dataFocus={dataFocus}
            faultCode={faultCode}
            omsFaultCodes={omsFaultList}
            notes={faultDescription}
            saveTemplateLocal={saveTemplate}
            reloadTemplates={reloadTemplates}
            usingTemplate={usingTemplate}
            loadedTemplate={loadedTemplate}
          />
        </div>
      </div>
      <div className="chart__content__container">
        <NotificationPopup
          userName={userName}
          open={templateError}
          closeModal={closeNotificationModal}
          description={""}
          descriptionSituation={true}
          alternativeExistLink={alternativeExistLink}
          color={"#FFFFFF"}
          errorCode={"ER-AH-00005"}
          type={""}
          technical_detail={technical_detail}
        />
        <div
          style={{ display: selectedTab === "PARAMETERS" ? "flex" : "none" }}
        >
          <div className="full__width__flex">
            <ParameterSelection
              numParametersAllowed={numParametersAllowed}
              parameterToggle={handleParameterListToggle}
              startTime={startTime}
              endTime={endTime}
              faultCode={faultCode}
              cmsStartTime={cmsStartTime}
              cmsEndTime={cmsEndTime}
              cmsFlights={
                props.location.state.takeoffReport
                  ? props.location.state.takeoffReport.flights
                  : []
              }
              tabState="all"
              dataFocus={dataFocus}
              parametersLoaded={parametersLoaded}
              parameterListSizes={parameterListSizes}
              parametersToAdd={parametersToAdd}
              allParameters={parameterList}
              getCMSParameterInfo={getCMSParameterInfo}
              updatingCharts={updatingCharts}
              updateCharts={
                dataFocus === "FLIGHT" || dataFocus === "CMS"
                  ? updateCanvasCharts
                  : updateCharts
              }
              createCharts={areChartsDoneLoading}
              actionTab={parameterSelectionTabStates}
              removeParameter={
                dataFocus === "FLIGHT" || dataFocus === "CMS"
                  ? removeCanvasParameterByName
                  : removeParameter
              }
              updateCmsStartTime={updateCmsStartTime}
              updateCmsEndTime={updateCmsEndTime}
              toggleEventParameterCheckBoxes={toggleEventParameterCheckBoxes}
              toggleAllCheckBoxes={toggleAllCheckBoxes}
              changeActionTab={(state) => {
                setParameterSelectionTabStates(state);
              }}
              altitudeData={altitudeData}
              maxSessionChartingRange={maxSessionChartingRange}
              maxSessionChartingRangeMinutes={maxSessionChartingRangeMinutes}
            />
          </div>
        </div>
        <div style={{ display: selectedTab === "TEMPLATES" ? "flex" : "none" }}>
          {templateList && (
            <div className="full__width__flex">
              <TemplateSelection
                dataFocus={dataFocus}
                templates={templateList}
                templateParameters={selectedTemplate}
                loadTemplate={loadTemplate}
                uploadLocalTemplate={uploadLocalTemplate}
                saveLocalTemplate={saveTemplate}
                aircraftFamily={aircraftFamily}
                aircraftModel={aircraftModel}
                setLoadedTemplate={(template) => {
                  setLoadedTemplate(template);
                }}
                isTemplateDetailLoading={isTemplateDetailLoading}
              />
            </div>
          )}
        </div>
        <div style={{ display: selectedTab === "CHARTS" ? "flex" : "none" }}>
          {dataFocus === "FLIGHT" || dataFocus === "CMS" ? (
            /*
            TODO: The CanvasJS library is currently only used when we are charting the following type of parameters:
              - Full Flight AHMS Parameters
              - CMS Parameters
            */
            <div
              className="full__width__flex"
              style={{ marginBottom: "100px" }}
            >
              {charts.length > 0 && canvasChartsRender()}
            </div>
          ) : (
            /*
            TODO: The CanvasJS library is currently only used when we are charting the following type of parameters:
              - RPD
              - FDE/CAS Events
              - FDE/CAS In-Flight Events
            */
            <div style={{ width: "100%", marginBottom: "100px" }}>
              {!updatingCharts && (
                <ChartsController
                  loadedTemplate={loadedTemplate}
                  resizeLegend={resizeLegend}
                  charts={charts}
                  expandedLegend={expandedLegend}
                  onDragEnd={onDragEnd}
                  dataFocus={dataFocus}
                  tail={tail}
                  faultCode={faultCode}
                  faultTimeStamp={faultTimeStamp}
                  toggleParameterFn={toggleParameterFn}
                  downloadXLSX={exportChartAsExcel}
                  deleteChart={deleteChart}
                  removeParameter={removeParameter}
                  moveChart={moveChart}
                  snapshotParameters={snapshotParameters}
                  aircraftFamilyModel={`${aircraftFamily} ${aircraftModel}`}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
