import React, { useEffect, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import FeatherIcon from 'feather-icons-react'
import { Link } from '@mui/material'

import TabItem from '../Global Components/TabItem'
import ReportContext from './Context Components/ReportContext'
import LoadingSpinner from '../Global Components/LoadingSpinner'
import NotificationPopup from '../Global Components/NotificationPopup'
import Summary from './Summary Components/IseSummary'
import Diagnostics from './Diagnostics Components/Diagnostics'
import Visualization from './Visualization Components/IseVisualization'
import Download from './Download'
import Review from './Review Components/Review'
import { PREDICTIVE_MAINTENANCE_URL } from '../../Constants/Constants'
import {
    anomalyTabsForInternalUsers,
    anomalyTabsForExternalUsers,
    dropdownFields,
    CHARTS
} from '../constants'
import { getTakeOffReport } from '../../clients/TakeOffReportClient'
import {
    getFaultsOMS,
    getFaultsFDE,
    getFaultEventCountFDECAS,
    getFaultEventCountOMS
} from '../../clients/FaultsClient'
import { getAllParameters, getParameterGroupMetaData } from '../../clients/ParametersClient'
import {
    getAircraftData,
    getFlightPhase,
    cleanReportLocalStorage,
    processFaultCounts,
    formatDepartureArrivalTime
} from '../usefulFunctions'
import { getPmxFleet, getFleet } from '../../clients/FleetAlertsClient'
import * as tools from '../../utils/CommonTools'
import { useHistory } from 'react-router-dom'

import './PredictiveReports.css'

const PredictiveReports = props => {
    let currUrl = window.location.href
    let urlSplit = currUrl.split('/')
    const sessionKey = urlSplit[urlSplit.length - 2]
    const iseNumber = urlSplit[urlSplit.length - 1]
    const anomalyId = urlSplit[urlSplit.length - 1]

    const [tabsOptions, setTabsOptions] = useState(null)
    const [allAircrafts, setAllAircrafts] = useState(null)
    const [collapseHeader, setCollapseHeader] = useState(false)

    /* FOR RESTORING ARCHIVED ANOMALY */
    const history = useHistory()
    const url = PREDICTIVE_MAINTENANCE_URL()
    const parametersLimit = 10
    const [userInfo, setUserInfo] = useState(null)
    const [userType, setUserType] = useState(null)
    const [loading, setLoading] = useState(true)
    const [flightReport, setFlightReport] = useState(null)
    const [aircraftData, setAircraftData] = useState({})
    const [tab, setTab] = useState(null)
    const [iseReport, setIseReport] = useState(null)
    const [notifModal, setNotifModal] = useState({
        colorCode: '',
        description: '',
        alternativeExistLink: '',
        notifModalState: false,
        technical_detail: '',
        title: '',
        reloadPage: true,
        isError: true,
        containsClose: false
    })

    // All variables for Review
    const [originalAnswers, setOriginalAnswers] = useState(null)
    const [personalSection, setPersonalSection] = useState({
        title: 'Personal Information',
        isCollapse: false,
        isComplete: false,
        questions: []
    })
    const [questionsSection, setQuestionsSection] = useState({
        title: 'Review Questions',
        isCollapse: false,
        isComplete: false,
        questions: []
    })
    const [optionalQuestions, setOptionalQuestions] = useState(null)
    const [reviewQuestions, setReviewQuestions] = useState(null)
    const [reviewFormData, setReviewFormData] = useState(null)
    const [reviewEditMode, setReviewEditMode] = useState(false)
    const [templateForReview, setTemplateForReview] = useState(null)
    const [allReviews, setAllReviews] = useState(null)

    // All variables for Summary
    const [severityFeedback, setSeverityFeedback] = useState('')
    const [diagnosticsRequired, setDiagnosticsRequired] = useState('')
    const [summaryData, setSummaryData] = useState(null)

    // All variables for Visualization
    const [map, setMap] = useState(null)
    const [time, setTime] = useState({
        startTime: null,
        endTime: null
    })
    const [initialZoom, setInitialZoom] = useState({
        min: null,
        max: null
    })
    const [addTimeSeriesData, setAddTimeSeriesData] = useState(null)
    const [templateCharts, setTemplateCharts] = useState(null)
    const [additionalCharts, setAdditionalCharts] = useState(null)
    const [contextualCharts, setContextualCharts] = useState(null)
    const [continuousCharts, setContinuousCharts] = useState(null)
    const [engineeredCharts, setEngineeredCharts] = useState(null)
    const [parametersOverviewChart, setParametersOverviewChart] = useState(null)
    const [parametersToAdd, setParametersToAdd] = useState(null)
    const [contextualParametersToAdd, setContextualParametersToAdd] = useState(null)
    const [continuousParametersToAdd, setContinuousParametersToAdd] = useState(null)
    const [anomalousPoints, setAnomalousPoints] = useState(null)

    const [templateParametersToAdd, setTemplateParametersToAdd] = useState(null)
    // State that holds additional contextual parameter selected from dropdown
    const [dropdownOptions, setDropdownOptions] = useState(null)
    const [dropdownLabel, setDropdownLabel] = useState('')
    const [optionsIsLoading, setOptionsIsLoading] = useState(true)

    // All variables for Diagnostics
    // Keep track of all images from the LRUs
    const [diagnosticsDataISE, setDiagnosticsDataISE] = useState(null)
    const [treeData, setTreeData] = useState(null)
    const [faults, setFaults] = useState(null)

    const loadUserInfo = () => {
        if (localStorage.getItem('userInfo')) {
            let localStorageUserInfo = JSON.parse(localStorage.getItem('userInfo'))
            setUserInfo(localStorageUserInfo)
            setUserType(
                // localStorageUserInfo?.orgId === "10000186" ||
                localStorageUserInfo?.userId.charAt(0).toLowerCase() === 'b' ||
                    localStorageUserInfo?.userId.charAt(0).toLowerCase() === 'k'
                    ? 'internal'
                    : 'external'
            )
        }
    }

    const openNotificationModal = ({
        colorCode,
        description,
        alternativeExistLink,
        title,
        reloadPage,
        isError = true,
        containsClose = false
    }) => {
        setLoading(false)
        setNotifModal({
            colorCode: colorCode,
            description: description,
            alternativeExistLink: alternativeExistLink,
            notifModalState: true,
            title: title,
            reloadPage: reloadPage,
            isError: isError,
            containsClose: containsClose
        })
    }

    const closeNotificationModal = () => {
        setNotifModal({
            notifModalState: false
        })
    }

    const getFleetlist = async () => {
        const errorObj = {
            description: (
                <div>We were not able to access the aircraft data you were looking for.</div>
            ),
            alternativeExistLink: `/predictive`,
            colorCode: '#F2F2F2',
            reloadPage: true
        }

        // need to keep using myMx tails API for predictive instances
        // because we're stilling using b8000535 to login into predictive instances
        if (
            process.env.REACT_APP_ENVIRONMENT === 'PREDICTIVE-DEV' ||
            process.env.REACT_APP_ENVIRONMENT === 'PREDICTIVE-QA' ||
            process.env.REACT_APP_ENVIRONMENT === 'PREDICTIVE' ||
            typeof process.env.REACT_APP_ENVIRONMENT === 'undefined'
        ) {
            await getFleet()
                .then(res => {
                    if (
                        res.data &&
                        Array.isArray(res.data) &&
                        res.data.length > 0 &&
                        // TODO: Delete this error check after myMx backend pushes api changes to PROD
                        typeof res.data[0] === 'object'
                    ) {
                        let fleetList = []
                        res.data.forEach(tail => {
                            tail.tails.registrationNumber = tail.tails.tailNumber
                            fleetList.push(tail.tails)
                        })

                        localStorage.setItem('allAircrafts', JSON.stringify(Array.from(fleetList)))
                        setAllAircrafts(fleetList)
                    } else {
                        openNotificationModal(errorObj)
                    }
                })
                .catch(error => {
                    console.error(`Error : ${error}`)
                    openNotificationModal(errorObj)
                })
        } else {
            await getPmxFleet()
                .then(res => {
                    if (
                        res.data &&
                        Array.isArray(res.data) &&
                        res.data.length > 0 &&
                        // TODO: Delete this error check after myMx backend pushes api changes to PROD
                        typeof res.data[0] === 'object'
                    ) {
                        localStorage.setItem('allAircrafts', JSON.stringify(Array.from(res.data)))
                        setAllAircrafts(res.data)
                    } else {
                        openNotificationModal(errorObj)
                    }
                })
                .catch(error => {
                    console.error(`Error : ${error}`)
                    openNotificationModal(errorObj)
                })
        }
    }

    const updateAnomalyStatus = async () => {
        const response = await axios.put(`${url}/kanban/change_status`, {
            id: iseReport.id,
            old_status: summaryData.status,
            new_status: 'open',
            user_id:
                userInfo?.userId && typeof userInfo?.userId !== 'undefined'
                    ? userInfo.userId
                    : 'N/A'
        })

        return response.data
    }

    const resetPage = async () => {
        await updateAnomalyStatus()
        tools.reloadOrRedirectToPage(`/predictivereports/${iseReport.id}`, history, window)
    }

    // Method to Fetch and load Continioulsy recorded parameters
    const loadContinouslyRecordedParameters = () => {
        let options = []

        getAllParameters(
            aircraftData.tailNumber,
            time.startTime,
            time.endTime,
            aircraftData.aircraftModel,
            aircraftData.aircraftFamily
        )
            .then(res => {
                // Setting Size of Chunks
                const chunkSize = 500
                // Creating Array of Chunk Promises
                const parameterDataPromiseArray = []
                // Figuring out number of Chunks
                const chunkArray = _.chunk(res.data, chunkSize)
                // Adding Promised to Promise Array
                for (let c = 0; c < chunkArray.length; c++) {
                    parameterDataPromiseArray.push(
                        getParameterGroupMetaData(
                            aircraftData.tailNumber,
                            time.startTime,
                            time.endTime,
                            aircraftData.aircraftModel,
                            aircraftData.aircraftFamily,
                            chunkArray[c]
                        )
                    )
                }

                // Resolving Promises
                Promise.all(parameterDataPromiseArray)
                    .then(res => {
                        // Creating storage for joining response from resolved promise array
                        let parameters = []

                        for (let p of res) {
                            parameters = parameters.concat(p.data.parameters)
                        }

                        // parameterListSizes.continuousParameters = parameters.length;
                        // looping through parameters and setting default checkbox value and parameter type
                        for (var i = 0; i < parameters.length; i++) {
                            if (parameters[i].unitOfMeasure === null) {
                                parameters[i].unitOfMeasure = 'Discrete'
                            }
                            _.assign(parameters[i], { type: 'Continuous Parameter' })
                        }

                        setDropdownOptions(parameters)
                        setOptionsIsLoading(false)
                        setDropdownLabel('Search for additional parameters...')
                    })
                    .catch(error => {
                        console.error('Error ', error)
                        setDropdownOptions(options)
                        setOptionsIsLoading(false)
                        setDropdownLabel('No additional parameters available')
                    })
            })
            .catch(error => {
                console.error('Error ', error)
                setDropdownOptions(options)
                setOptionsIsLoading(false)
                setDropdownLabel('No additional parameters available')
            })
    }

    const updateDropdownOptions = options => {
        if (options) {
            setDropdownOptions(options)
        }
    }

    const updateCharts = (charts, type) => {
        if (type === CHARTS.CHART_TYPES.CONTINUOUS) setContinuousCharts(charts ? [...charts] : null)
        if (type === CHARTS.CHART_TYPES.COLLINEAR) setEngineeredCharts(charts ? [...charts] : null)
        else if (type === CHARTS.CHART_TYPES.CONTEXTUAL)
            setContextualCharts(charts ? [...charts] : null)
        else if (type === CHARTS.CHART_TYPES.ADDITIONAL)
            setAdditionalCharts(charts ? [...charts] : null)
        else if (type === CHARTS.CHART_TYPES.OVERVIEW) setParametersOverviewChart(charts)
        else if (type === CHARTS.CHART_TYPES.TEMPLATE)
            setTemplateCharts(charts ? [...charts] : null)
    }

    const updateParamsToAdd = (params, type) => {
        if (type === CHARTS.CHART_TYPES.CONTINUOUS)
            setContinuousParametersToAdd(params ? [...params] : null)
        else if (type === CHARTS.CHART_TYPES.CONTEXTUAL)
            setContextualParametersToAdd(params ? [...params] : null)
        else if (type === CHARTS.CHART_TYPES.ADDITIONAL)
            setParametersToAdd(params ? [...params] : null)
        else if (type === CHARTS.CHART_TYPES.TEMPLATE)
            setTemplateParametersToAdd(params ? [...params] : null)
    }

    const handleQuestionsChange = (title, name, value) => {
        if (title === 'Personal Information') {
            personalSection[name] = value
            setPersonalSection({ ...personalSection })
        } else if (title === 'Review Questions') {
            questionsSection[name] = value
            setQuestionsSection({ ...questionsSection })
        } else if (title === 'Optional') {
            setOptionalQuestions({ ...value })
        }
    }

    const getOmsFault = async faults => {
        const omsCode = faults.oms_code
        const omsDate = faults.oms_date
        let oms = null

        let faultsOms = await getFaultsOMS(
            aircraftData.tailNumber,
            moment.utc(omsDate).valueOf() - 30000,
            moment.utc(omsDate).valueOf() + 30000,
            false,
            aircraftData.aircraftFamily,
            aircraftData.aircraftModel,
            iseReport.session_number,
            undefined
        )

        if (faultsOms && faultsOms.data) {
            oms = _.find(faultsOms.data, ['faultCode', omsCode])
        }

        let faultEventCounts = await getFaultEventCountOMS(
            aircraftData.tailNumber,
            [`${omsCode}`],
            `${omsDate}.000Z`,
            `${omsDate}.000Z`,
            aircraftData.aircraftFamily,
            aircraftData.aircraftModel,
            undefined
        )

        const faultEventCountData = faultEventCounts?.data
            ? processFaultCounts(
                  faultEventCounts.data.faultCountsInFlights,
                  faultEventCounts.data.faultCountsOutsideFlights,
                  'OMS'
              )
            : null

        return {
            type: 'oms',
            faultInfo: oms,
            faultEventCountData: faultEventCountData
        }
    }

    const getCasFault = async faults => {
        const casCode = faults.cas_code
        const casDate = faults.cas_date

        let cas = null

        let faultsCas = await getFaultsFDE(
            aircraftData.tailNumber,
            moment.utc(casDate).valueOf() - 30000,
            moment.utc(casDate).valueOf() + 30000,
            false,
            aircraftData.aircraftFamily,
            aircraftData.aircraftModel,
            iseReport.session_number,
            undefined
        )

        if (faultsCas && faultsCas.data) {
            cas = _.find(faultsCas.data, ['faultCode', casCode])
        }

        let faultEventCounts = await getFaultEventCountFDECAS(
            aircraftData.tailNumber,
            [`${casCode}`],
            `${casDate}.000Z`,
            `${casDate}.000Z`,
            aircraftData.aircraftFamily,
            aircraftData.aircraftModel,
            undefined
        )

        const faultEventCountData = faultEventCounts?.data
            ? processFaultCounts(
                  faultEventCounts.data.faultCountsInFlights,
                  faultEventCounts.data.faultCountsOutsideFlights,
                  'FDE'
              )
            : null

        return {
            type: 'cas',
            faultInfo: cas,
            faultEventCountData: faultEventCountData
        }
    }

    const formatFaults = async faults => {
        let omsFault = {}
        let casFault = {}

        Object.keys(faults).forEach(function (key) {
            let values =
                faults[key] && faults[key].replace(/\{|\}/g, '').replace(/"/g, '').split(',')
            if (values?.length > 0) {
                let value = values[0]
                if (key.includes('date')) {
                    const date = value.split(' ')[0]
                    const time = value.split(' ')[1]

                    value = `${date}T${time}`
                }

                if (key.includes('oms')) {
                    omsFault[key] = value
                } else if (key.includes('cas')) {
                    casFault[key] = value
                }
            }
        })

        const containsOms = faults.oms_code ? true : false
        const containsCas = faults.cas_code ? true : false

        const isCustomer = localStorage.getItem('userType') === 'customer' ? true : false

        let newFaultsObj = {}

        if (containsOms) {
            let oms = await getOmsFault(omsFault)

            newFaultsObj.oms = {
                eventCountData: oms.faultEventCountData,
                faultCode: omsFault.oms_code,
                date: omsFault.oms_date,
                description: omsFault.oms_description,
                faultSeverity: omsFault.oms_severity,
                link: oms.faultInfo
                    ? isCustomer
                        ? oms.faultInfo.sfpExternalUrl
                            ? oms.faultInfo.sfpExternalUrl
                            : null
                        : oms.faultInfo.smartFixPlus
                        ? oms.faultInfo.smartFixPlus
                        : null
                    : null
            }
        }
        if (containsCas) {
            let cas = await getCasFault(casFault)

            newFaultsObj.cas = {
                eventCountData: cas.faultEventCountData,
                faultCode: casFault.cas_code,
                date: casFault.cas_date,
                description: casFault.cas_description,
                faultSeverity: casFault.cas_severity,
                link: cas.faultInfo
                    ? isCustomer
                        ? cas.faultInfo.sfpExternalUrl
                            ? cas.faultInfo.sfpExternalUrl
                            : null
                        : cas.faultInfo.sfp_url
                        ? cas.faultInfo.sfp_url
                        : null
                    : null
            }
        }

        setFaults({ ...newFaultsObj })
    }

    const constructTreeData = lrus_info => {
        const lruWidth = 225

        // sort all Lrus to be able to select the highest 7
        // allLrus = allLrus.sort(
        //   (a, b) =>
        //     b.value - a.value
        // );

        // start constructing tree object
        let newData = {
            name: summaryData.ata_chapter,
            text: summaryData.ata_chapter,
            children: []
        }

        for (let i = 0; i < lrus_info.length; i++) {
            let lru = lrus_info[i]

            let newLRU = {
                ...lru,
                ata: diagnosticsDataISE.ata,
                name: lru.lru_name,
                lruName: lru.lru_name
            }

            if (i === 0) {
                newLRU.first = true
            }

            newData.children.push(newLRU)
        }

        newData.minTreeWidth = newData.children.length * lruWidth

        setTreeData(newData)
    }

    /* Fetching data for all screens */
    const getISETimeSeriesData = async (sessionKey, iseNumber) => {
        const response = await axios
            .get(`${url}/ts_data?session_key=${sessionKey}&ise_num=${iseNumber}`)
            .catch(err => {
                console.error(err)
                return
            })

        if (response?.data) {
            let expected_behaviour = _.get(response, 'data.expected_behaviour')
            let expected_deviation = _.get(response, 'data.expected_deviation')
            // Get all continuous points
            let anomalousTimestamps = _.get(response, 'data.anomalous_timestamps')
            let anomPoints = anomalousTimestamps.sort(function (a, b) {
                return new Date(a) - new Date(b)
            })

            // Group anomalous points by consevutive points
            let groupedAnomalousPoints = []

            for (let i = 0; i < anomPoints.length; i++) {
                let point = anomPoints[i]

                if (groupedAnomalousPoints.length > 0) {
                    let lastRange = groupedAnomalousPoints[groupedAnomalousPoints.length - 1]

                    let lastPointinLastRange = new Date(lastRange[lastRange.length - 1])

                    let nextPoint = lastPointinLastRange.setSeconds(
                        lastPointinLastRange.getSeconds() + 1
                    )

                    if (new Date(nextPoint).getTime() === new Date(point).getTime()) {
                        lastRange.push(point)
                    } else {
                        groupedAnomalousPoints.push([point])
                    }
                } else {
                    groupedAnomalousPoints.push([point])
                }
            }
            setAnomalousPoints(groupedAnomalousPoints)

            for (let i = 0; i < expected_behaviour.length; i++) {
                let time = new Date(summaryData.start_time).setSeconds(
                    new Date(summaryData.start_time).getSeconds() + i
                )
                time = new Date(time).toISOString()
                expected_behaviour[i].time = time
                expected_deviation[i].time = time
            }

            const data = {
                expected_behaviour: expected_behaviour,
                expected_deviation: expected_deviation
            }

            setAddTimeSeriesData({ ...data })
        }
    }

    const getTimeSeriesData = async id => {
        const response = await axios
            .get(`${url}/ts_data?session_key=${sessionKey}&ise_num=${iseNumber}`)
            // .get(`${url}/ts_data?anomaly_id=${id}`)
            .catch(err => {
                console.error(err)
                return
            })

        if (response?.data?.data) {
            let data = {
                data: response.data.data,
                currPage: response.data.current_page,
                totalPages: response.data.total_pages
            }

            let observedData =
                _.get(response, 'data.data.observed_data') || _.get(response, 'data.observed_data')

            // Get all continuous points
            let anomPoints = []
            for (let index = 0; index < observedData.data.length; index++) {
                const item = observedData.data[index]
                const isAnomalous = item.is_anomalous

                if (isAnomalous) {
                    anomPoints.push({
                        time: `${item.date.split('T')[0]}T${item.time}Z`,
                        index: index
                    })
                }
            }

            // Group anomalous points by consevutive points
            const groupedAnomalousPoints = anomPoints.reduce((r, n) => {
                const lastSubArray = r[r.length - 1]

                if (!lastSubArray || lastSubArray[lastSubArray.length - 1].index !== n.index - 1) {
                    r.push([])
                }

                r[r.length - 1].push(n)
                return r
            }, [])

            setAnomalousPoints(groupedAnomalousPoints)

            let dataLength = observedData.data.length
            let date = observedData.data[0]['date']
            let start_time = observedData.data[0]['time']
            let end_time = observedData.data[dataLength - 1]['time']

            let splitDate = date.split('T')

            // Need these values when creation options object
            let newStartDate = moment.utc(`${splitDate[0]}T${start_time}.000Z`).valueOf()
            let newEndDate = moment.utc(`${splitDate[0]}T${end_time}.000Z`).valueOf()

            setInitialZoom({
                min: newStartDate,
                max: newEndDate
            })

            // Retrieve all other time series after top 10 parameters
            const timeSeriesPromises = []
            for (let page = 2; page <= data.totalPages; page++) {
                const timeSeriesPromise = axios
                    .get(
                        `${url}/ts_data?anomaly_id=${id}&feature_types=CONTINUOUS;COLLINEAR&limit=10&page=${page}`
                    )
                    .catch(err => {
                        console.error(err)
                        return
                    })

                timeSeriesPromises.push(timeSeriesPromise)
            }

            let combinedObserved = response.data.data.observed_data.data
            let combinedExpectedBehavior = response.data.data.expected_behaviour.data
            let combinedExpectedDeviation = response.data.data.expected_deviation.data

            Promise.all(timeSeriesPromises).then(res => {
                for (let d = 0; d < res.length; d++) {
                    if (res[d]?.data?.data) {
                        const observed = res[d].data.data.observed_data?.data
                        const expectedBehavior = res[d].data.data.expected_behaviour?.data
                        const expectedDeviation = res[d].data.data.expected_deviation?.data

                        for (let index = 0; index < observed.length; index++) {
                            // Combining observed data
                            let newItem = {
                                ...combinedObserved[index],
                                ...observed[index]
                            }
                            combinedObserved[index] = newItem

                            // Combining expected behavior
                            newItem = {
                                ...combinedExpectedBehavior[index],
                                ...expectedBehavior[index]
                            }
                            combinedExpectedBehavior[index] = newItem

                            // Combining expected deviation
                            newItem = {
                                ...combinedExpectedDeviation[index],
                                ...expectedDeviation[index]
                            }
                            combinedExpectedDeviation[index] = newItem
                        }
                    }
                }

                const data = {
                    observed_data: combinedObserved,
                    expected_behaviour: combinedExpectedBehavior,
                    expected_deviation: combinedExpectedDeviation
                }

                setAddTimeSeriesData({ ...data })
            })
        }
    }

    const getDiagnosticsData = async id => {
        await axios
            .get(`${url}/diagnostics/events?session_key=${sessionKey}&ise_num=${iseNumber}`)
            .then(response => {
                let data = _.get(response, 'data')
                if (data) {
                    setDiagnosticsDataISE(data)
                }
            })
            .catch(err => console.log(err))
    }

    const getSummaryData = async (sessionKey, iseNumber) => {
        const errorObj = {
            description:
                'We were not able to access some of the anomaly data you were looking for.',
            alternativeExistLink: `/predictivereports/${sessionKey}/${iseNumber}`,
            colorCode: '#F2F2F2',
            reloadPage: true
        }

        await axios
            .get(`${url}/summary?session_key=${sessionKey}&ise_num=${iseNumber}`)
            // .get(`${url}/summary?anomaly_id=${id}&feature_types=CONTINUOUS;COLLINEAR`)
            .then(response => {
                if (response && response.data) {
                    let summaryData = response.data
                    let startTime = moment.utc(summaryData.start_time).valueOf()
                    let endTime = moment.utc(summaryData.end_time).valueOf()

                    setSummaryData(response.data)

                    setInitialZoom({
                        min: startTime,
                        max: endTime
                    })

                    // Set top 10 first selected parameters for Continuous Charts
                    // const newList = summaryData.anomalies.slice(0, parametersLimit);
                    // newList.push(summaryData.anomalies[summaryData.anomalies.length - 1])

                    updateParamsToAdd([...summaryData.anomalies], 'continuous')
                } else openNotificationModal(errorObj)
            })
            .catch(error => {
                openNotificationModal(errorObj)
                console.log('error making req for a summary anomaly with id', id)
            })
    }

    const getReviewQuestions = async id => {
        // Retrieve questions for review modal
        await axios
            .get(`${url}/feedback/questions?anomaly_id=${id}`)
            .then(response => {
                let questions = []
                if (response?.data?.length > 0) {
                    response.data.forEach(q => {
                        let dropdownIndex = _.findIndex(dropdownFields, {
                            answerFamily: q.answer_family
                        })

                        if (dropdownIndex !== -1) {
                            let options = q.options
                            let field = dropdownFields[dropdownIndex].field
                            let fieldId = dropdownFields[dropdownIndex].fieldId

                            // Only keep two fields from dropdown list
                            const newOptions = options.map(o => {
                                return {
                                    [field]: o[field],
                                    [fieldId]: o[fieldId]
                                }
                            })
                            // Remove duplicates
                            const removeDups = _.uniqWith(newOptions, function (arrVal, othVal) {
                                if (arrVal[field] === null || othVal[field] === null) return true
                                else
                                    return (
                                        arrVal[field].toLowerCase() === othVal[field].toLowerCase()
                                    )
                            })
                            q.options = removeDups
                            questions.push(q)
                        } else {
                            questions.push(q)
                        }
                    })
                }

                setReviewQuestions(questions)
            })
            .catch(error => {
                setReviewQuestions([])
                console.error(error)
            })
    }

    const getReviewAnswers = async id => {
        // Retrieve answers from previously submitted review (if any)
        const userId =
            userInfo?.userId && typeof userInfo?.userId !== 'undefined' ? userInfo.userId : null

        if (userId) {
            // For now, set all answers to empty form
            let template = []
            reviewQuestions.forEach(q => {
                template.push({
                    question_id: q.question_id,
                    answer_text: '',
                    answer_ids: ''
                })
            })
            setTemplateForReview(template)

            await axios
                .get(
                    `${url}/feedback/answers?anomaly_id=${id}&user_id=${userId}&get_all_answers=false`
                )
                .then(response => {
                    if (response?.data?.length > 0 && response.data[0].answers) {
                        let answers = response.data[0]
                        // Retrieve answers from previously submitted review (if any)
                        const multipleChoiceQuestions = _.filter(reviewQuestions, {
                            question_type: 'multiple'
                        })

                        for (let i = 0; i < answers.answers.length; i++) {
                            let answer = answers.answers[i]

                            if (
                                _.findIndex(multipleChoiceQuestions, {
                                    question_id: answer.question_id
                                }) !== -1
                            ) {
                                // Answers are formatted different for multiple choice questions
                                answer.answer_text =
                                    answer.answer_text !== '' ? answer.answer_text.split(';') : []
                                answer.answer_ids =
                                    answer.answer_ids !== '' ? answer.answer_ids.split(';') : []
                            } else {
                                if (answer.answer_text !== '' && answer.answer_ids !== '')
                                    answer.answer_ids = parseInt(answer.answer_ids)
                            }
                        }

                        // Add all answers not in original answer
                        for (let i = 0; i < reviewQuestions.length; i++) {
                            const q = reviewQuestions[i]
                            let questionFormIndex = _.findIndex(answers.answers, {
                                question_id: q.question_id
                            })

                            if (questionFormIndex === -1) {
                                answers.answers.push({
                                    question_id: q.question_id,
                                    answer_text: '',
                                    answer_ids: ''
                                })
                            }
                        }

                        handleReviewFormData([...answers.answers], true, answers.answered_date)
                        setOriginalAnswers(_.cloneDeep(answers.answers))
                        setReviewEditMode(false)
                    } else {
                        handleReviewFormData([...template], false, null)
                        setReviewEditMode(true)
                    }
                })
                .catch(error => {
                    handleReviewFormData([...template], false, null)
                    setReviewEditMode(true)
                    console.error('No review for this anomaly', id)
                })
        }
    }

    const getAllReviews = async id => {
        await axios
            .get(`${url}/feedback/answers?anomaly_id=${id}`)
            .then(response => {
                if (response?.data) {
                    setAllReviews(
                        response.data
                            .filter(review => {
                                return (
                                    review?.user_id !==
                                    JSON.parse(
                                        localStorage.getItem('userInfo')
                                    )?.userId.toLowerCase()
                                )
                            })
                            .sort((a, b) => {
                                return new Date(b.answered_date) - new Date(a.answered_date)
                            })
                    )
                }
            })
            .catch(error => {
                console.error('No reviews for this anomaly', id)
            })
    }

    const getTakeOffReportData = async () => {
        const takeOffReport = await getTakeOffReport(
            aircraftData.tailNumber,
            null,
            null,
            null,
            aircraftData.aircraftFamily,
            aircraftData.aircraftModel,
            iseReport.session_number,
            undefined
        )
        return takeOffReport.data
    }

    const handleTabPressed = data => {
        // TODO once the designs are done for the Diagnostics and the other tabs,
        // We can remove this and just keep the setTab(data) function!
        const isInternalUser = userInfo.userId.charAt(0).toLowerCase() === 'b'
        const disabledTabs = ['Recommendations']
        // Add Diagnostics and Prognostics to disabledTabs for External users
        if (userType !== 'internal') {
            // disabledTabs.push("Diagnostics");
            disabledTabs.push('Prognostics')
        }

        // save the tab name in the local storage
        localStorage.setItem('selectedTab', JSON.stringify(data))

        if (!disabledTabs.includes(data.title)) {
            setTab(data)
        }
    }

    const handleReviewFormData = (form, reviewed, time) => {
        setReviewFormData({
            containReview: reviewed,
            answers: form,
            answeredDate: typeof time !== 'undefined' ? time : null
        })
    }

    const handleSubmitReview = async answers => {
        const userId =
            userInfo?.userId && typeof userInfo?.userId !== 'undefined' ? userInfo.userId : null

        if (userId) {
            // Format multiple answers array
            let newAnswers = []
            answers.forEach(a => {
                let isVisible = true
                const questionFormIndex = _.findIndex(questionsSection.questions, {
                    question_id: a.question_id
                })
                if (questionFormIndex !== -1) {
                    const questionForm = questionsSection.questions[questionFormIndex]
                    isVisible = questionForm.is_visible
                }

                if (isVisible) {
                    if (Array.isArray(a.answer_text)) {
                        newAnswers.push({
                            question_id: a.question_id.toString(),
                            answer_ids: a.answer_text.join(';'),
                            answer_text: a.answer_text.join(';')
                        })
                    } else {
                        newAnswers.push({
                            question_id: a.question_id.toString(),
                            answer_ids: a.answer_ids.toString(),
                            answer_text: a.answer_text.toString()
                        })
                    }
                }
            })

            const data = {
                user_id: userId,
                anomaly_id: iseReport && iseReport.id,
                answers: newAnswers
            }

            const response = await axios.post(`${url}/feedback/answers`, data)

            return response
        }
    }

    const handleWidthdrawReview = async () => {
        const userId =
            userInfo?.userId && typeof userInfo?.userId !== 'undefined' ? userInfo.userId : null
        const anomalyId = iseReport.id

        if (userId) {
            const response = await axios.delete(
                `${url}/feedback/answers?anomaly_id=${anomalyId}&user_id=${userId}`
            )

            return response
        }
    }

    const updateAircraftData = async () => {
        const aircraft = await getAircraftData(iseReport.serial_number)
        if (aircraft) {
            setAircraftData({
                aircraftFamily: aircraft.aircraftFamily,
                aircraftModel: aircraft.aircraftModel,
                serialNumber: aircraft.serialNumber,
                tailNumber: aircraft.registrationNumber
            })
        }
    }

    useEffect(() => {
        // In case, app starts from Kanban page
        // Need to set the current mode of sideNav to PMx
        props.setCurrentMode('PMx')

        cleanReportLocalStorage()
        loadUserInfo()

        getSummaryData(sessionKey, iseNumber)
        getDiagnosticsData(anomalyId)
        // getReviewQuestions(anomalyId);
        // getAllReviews(anomalyId);

        // Fleet list is necessary to get aircraft data
        if (localStorage.getItem('allAircrafts')) {
            let localStorageAllAircrafts = JSON.parse(localStorage.getItem('allAircrafts'))
            setAllAircrafts(localStorageAllAircrafts)
        } else {
            getFleetlist()
        }
    }, [])

    useEffect(() => {
        if (userType) {
            let tabs = []
            if (userType === 'internal') {
                tabs = anomalyTabsForInternalUsers
            } else if (userType === 'external') {
                tabs = anomalyTabsForExternalUsers
            }

            setTabsOptions(tabs)

            let selectedTab = JSON.parse(localStorage.getItem('selectedTab')) || tabs[0] // default tab is summary;

            // Check that it is in the possible options of tabs
            // Make sure that tab is not deactivated
            if (
                _.findIndex(tabs, function (t) {
                    return !t.deactivated && t.title === selectedTab.title
                }) === -1
            ) {
                selectedTab = tabs[0]
            }

            //Check if new anomaly
            if (localStorage.getItem('selectedAnomaly')) {
                let lastAnomaly = JSON.parse(localStorage.getItem('selectedAnomaly'))
                // If new anomaly, set tab to Summary
                if (lastAnomaly.id !== anomalyId) {
                    selectedTab = tabs[0]
                }
            }

            setTab(selectedTab)
        }
    }, [userType])

    useEffect(() => {
        if (reviewQuestions && userInfo) getReviewAnswers(anomalyId)
    }, [reviewQuestions, userInfo])

    useEffect(() => {
        // Get dropdowns options once we get a Start Time and End Time
        if (time.startTime === '' && time.endTime === '' && !dropdownOptions) {
            updateDropdownOptions([])
            setOptionsIsLoading(false)
            setDropdownLabel('No additional parameters available')
        } else if (time.startTime !== null && time.endTime !== null) {
            loadContinouslyRecordedParameters()
        }
    }, [time])

    useEffect(() => {
        if (iseReport && allAircrafts) {
            updateAircraftData()
        }
    }, [iseReport, allAircrafts])

    useEffect(() => {
        if (summaryData) {
            getISETimeSeriesData(sessionKey, iseNumber)

            // Construct tree data for Diagnostics screen
            if (!_.isEmpty(diagnosticsDataISE) && diagnosticsDataISE?.lrus_info)
                constructTreeData(diagnosticsDataISE.lrus_info)

            if (!iseReport) {
                let report = {
                    id: summaryData.id,
                    event_id: summaryData.event_id,
                    ise_name: summaryData.ise_name,
                    serial_number: summaryData.serial_number,
                    ata_chapter: summaryData.ata_chapter,
                    description: summaryData.ata_description,
                    session_number: summaryData.flight_session,
                    flight_leg: summaryData.flight_leg,
                    end_time: summaryData.end_time,
                    start_time: summaryData.start_time
                }

                const anomalyStringified = JSON.stringify(report)
                localStorage.setItem('selectedAnomaly', anomalyStringified)

                setIseReport(report)
            }
        }
    }, [summaryData, diagnosticsDataISE])

    useEffect(() => {
        if (aircraftData.tailNumber) {
            // Get Takeoff Report using session number for Visualization screen
            if (!flightReport) {
                const errorObj = {
                    description:
                        'We were not able to access some of the aircraft data you were looking for.',
                    alternativeExistLink: `/predictivereports/${iseReport.id}`
                }

                getTakeOffReportData()
                    .then(response => {
                        if (response) {
                            let allFlights = _.get(response, 'flights')
                            if (allFlights.length !== 0) {
                                let flightReport = allFlights[parseInt(iseReport.flight_leg) - 1]
                                setFlightReport(flightReport)
                                setTime({
                                    startTime:
                                        flightReport.departureTime === null
                                            ? ''
                                            : new Date(flightReport.departureTime).getTime() -
                                              100000,
                                    endTime:
                                        flightReport.arrivalTime === null
                                            ? ''
                                            : new Date(flightReport.arrivalTime).getTime() + 100000
                                })
                            } else {
                                setFlightReport({})
                                setTime({
                                    startTime: '',
                                    endTime: ''
                                })
                            }
                        } else {
                            setFlightReport({})
                            setTime({
                                startTime: '',
                                endTime: ''
                            })
                        }
                    })
                    .catch(() => {
                        setFlightReport({})
                        setTime({
                            startTime: '',
                            endTime: ''
                        })
                    })
            }
        }
    }, [aircraftData])

    // useEffect(() => {
    //   if (
    //     aircraftData.tailNumber
    //     && iseReport
    //     && diagnosticsDataISE?.data?.faults
    //     && (!faults?.faults || typeof faults?.faults === "undefined")
    //   ) //Format faults for Diagnostics screen only if it hasn't been done already
    //     formatFaults(diagnosticsData.data.faults[0]);

    // }, [aircraftData, iseReport, diagnosticsData.data]);

    useEffect(() => {
        const containsDiagnostics = diagnosticsDataISE?.lrus
            ? diagnosticsDataISE.lrus.length !== 0
            : null
        if (
            reviewQuestions?.length > 0 &&
            reviewFormData &&
            typeof containsDiagnostics === 'boolean' &&
            personalSection.questions.length === 0 &&
            questionsSection.questions.length === 0
        ) {
            let textType = []
            let singleType = []
            let additionalCommentQuestion = null

            if (reviewFormData.containReview) {
                for (let i = 0; i < reviewQuestions.length; i++) {
                    const q = reviewQuestions[i]

                    let isVisible = false
                    let questionForm = _.find(reviewFormData.answers, {
                        question_id: q.question_id
                    })

                    // Check if the question should be visible
                    // If it contains an answer, make it visible
                    if (typeof questionForm !== 'undefined') {
                        if (q.question_type === 'multiple') {
                            if (questionForm.answer_text.length > 0) isVisible = true
                        } else {
                            if (questionForm.answer_text !== '') isVisible = true
                        }
                    }

                    if (q.question_category === 'General') {
                        if (
                            q.question.toLowerCase().includes('optional') ||
                            q.question.toLowerCase().includes('additional')
                        ) {
                            q.is_visible = true
                            additionalCommentQuestion = { ...q }
                        } else {
                            textType.push(q)
                        }
                    } else {
                        if (q.question_id !== '1') {
                            q.is_visible = isVisible
                        }
                        if (containsDiagnostics) {
                            singleType.push(q)
                        } else {
                            if (q.question_category.toLowerCase().includes('diagnostics')) {
                                // Remove all diagnostics questions if anomaly has no diagnostics
                                if (containsDiagnostics) singleType.push(q)
                            } else {
                                singleType.push(q)
                            }
                        }
                    }
                }
            } else {
                for (let i = 0; i < reviewQuestions.length; i++) {
                    const q = reviewQuestions[i]

                    if (q.question_category === 'General') {
                        if (
                            q.question.toLowerCase().includes('optional') ||
                            q.question.toLowerCase().includes('additional')
                        ) {
                            q.is_visible = false
                            additionalCommentQuestion = { ...q }
                        } else {
                            textType.push(q)
                        }
                    } else {
                        if (q.question_id !== '1') {
                            q.is_visible = false
                        }
                        if (containsDiagnostics) {
                            singleType.push(q)
                        } else {
                            if (q.question_category.toLowerCase().includes('diagnostics')) {
                                // Remove all diagnostics questions if anomaly has no diagnostics
                                if (containsDiagnostics) singleType.push(q)
                            } else {
                                singleType.push(q)
                            }
                        }
                    }
                }
            }

            personalSection.questions = textType
            setPersonalSection({ ...personalSection })

            questionsSection.questions = singleType
            setQuestionsSection({ ...questionsSection })

            setOptionalQuestions({ ...additionalCommentQuestion })

            handleReviewFormData(
                [...reviewFormData.answers],
                reviewFormData.containReview,
                reviewFormData.answeredDate
            )
        }
    }, [reviewFormData, reviewQuestions, diagnosticsDataISE])

    useEffect(() => {
        // Track scoll position
        const onScroll = () => {
            if (!collapseHeader && window.scrollY > 30) {
                setCollapseHeader(true)
            }
            if (collapseHeader && window.scrollY < 2) {
                window.scrollTo(0, 0)
                setCollapseHeader(false)
            }
        }

        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [collapseHeader])

    return (
        <div className='predictiveReports'>
            <ReportContext.Provider
                value={{
                    mapValue: [map, setMap],
                    loadingValue: [loading, setLoading]
                }}
            >
                {/* if loading, load spinner */}
                {loading && <LoadingSpinner />}

                {/* PREDICTIVE REPORT CONTENT */}
                {!notifModal.notifModalState && (
                    <div className='predictiveReports__content'>
                        {summaryData && (
                            <ReportHeader
                                userInfo={userInfo}
                                summaryData={summaryData}
                                aircraftData={aircraftData}
                                iseReport={iseReport}
                                tab={tab}
                                handleTabPressed={handleTabPressed}
                                reviewFormData={reviewFormData}
                                flightReport={flightReport}
                                resetPage={resetPage}
                                userType={userType}
                                tabsOptions={tabsOptions}
                                collapseHeader={collapseHeader}
                            />
                        )}
                        {/* Message to display if anomaly is archived */}
                        {summaryData?.status === 'archived' && (
                            <div className='archive__dialog'>
                                <div style={{ padding: '4px 15px 0px 0px' }}>
                                    <FeatherIcon icon='alert-circle' color='#C89231' />
                                </div>
                                <div style={{ alignSelf: 'center' }}>
                                    This report is READ ONLY. Click on Restore button to move it to
                                    the Open Reports section.
                                </div>
                            </div>
                        )}

                        {/* this is all the stuff below the header */}
                        {/* we also pass information on which fleet we clicked, so we can pass that info to each tab */}
                        {Object.keys(aircraftData).length === 0 ? (
                            <LoadingSpinner />
                        ) : (
                            <div className='predictiveReports__contentBody'>
                                {tab.title === 'Summary' && (
                                    <Summary
                                        summaryData={summaryData}
                                        diagnosticsData={diagnosticsDataISE}
                                        iseReport={iseReport}
                                        flightReport={flightReport}
                                        aircraftData={aircraftData}
                                        sessionKey={sessionKey}
                                        iseNumber={iseNumber}
                                    />
                                )}
                                {tab.title === 'Visualization' && iseReport && (
                                    <Visualization
                                        iseReport={iseReport}
                                        aircraftData={aircraftData}
                                        flightReport={flightReport}
                                        time={time}
                                        summaryData={summaryData}
                                        initialZoom={initialZoom}
                                        updateCharts={updateCharts}
                                        additionalCharts={additionalCharts}
                                        contextualCharts={contextualCharts}
                                        continuousCharts={continuousCharts}
                                        templateCharts={templateCharts}
                                        engineeredCharts={engineeredCharts}
                                        parametersOverviewChart={parametersOverviewChart}
                                        parametersLimit={parametersLimit}
                                        addTimeSeriesData={addTimeSeriesData}
                                        updateParamsToAdd={updateParamsToAdd}
                                        parametersToAdd={parametersToAdd}
                                        contextualParametersToAdd={contextualParametersToAdd}
                                        continuousParametersToAdd={continuousParametersToAdd}
                                        templateParametersToAdd={templateParametersToAdd}
                                        dropdownOptions={dropdownOptions}
                                        dropdownLabel={dropdownLabel}
                                        optionsIsLoading={optionsIsLoading}
                                        updateDropdownOptions={updateDropdownOptions}
                                        anomalousPoints={anomalousPoints}
                                    />
                                )}
                                {tab.title === 'Diagnostics' && (
                                    <Diagnostics
                                        faults={faults}
                                        data={diagnosticsDataISE}
                                        treeData={treeData}
                                        iseReport={iseReport}
                                    />
                                )}
                                {/*{tab.title !== "Recommendations" && <Recommendations />}*/}
                                {tab.title === 'Review' && (
                                    <Review
                                        templateForReview={templateForReview}
                                        reviewQuestions={reviewQuestions}
                                        reviewForm={reviewFormData?.answers}
                                        reviewed={reviewFormData?.containReview}
                                        answeredDate={reviewFormData?.answeredDate}
                                        handleReviewFormData={handleReviewFormData}
                                        handleQuestionsChange={handleQuestionsChange}
                                        personalSection={personalSection}
                                        questionsSection={questionsSection}
                                        optionalQuestions={optionalQuestions}
                                        handleSubmitReview={handleSubmitReview}
                                        originalAnswers={originalAnswers}
                                        handleWidthdrawReview={handleWidthdrawReview}
                                        handleOriginalAnswers={value => setOriginalAnswers(value)}
                                        containsDiagnostics={diagnosticsDataISE?.lrus.length !== 0}
                                        containsPrognostics={false}
                                        editMode={reviewEditMode}
                                        handleReviewEditModeChange={value =>
                                            setReviewEditMode(value)
                                        }
                                        allReviews={allReviews}
                                    />
                                )}
                            </div>
                        )}
                        {/* content for each of the tabs end here */}
                    </div>
                )}

                {/* NOTIF MODAL */}
                {!loading && notifModal.notifModalState && (
                    <NotificationPopup
                        userName={userInfo && userInfo.name}
                        open={notifModal.notifModalState}
                        closeModal={closeNotificationModal}
                        description={notifModal.description}
                        alternativeExistLink={notifModal.alternativeExistLink}
                        color={notifModal.colorCode}
                        technical_detail={notifModal.technical_detail}
                        title={notifModal.title}
                        reloadPage={notifModal.reloadPage}
                        isError={notifModal.isError}
                        containsClose={notifModal.containsClose}
                    />
                )}
            </ReportContext.Provider>
        </div>
    )
}

const ReportHeader = ({
    summaryData,
    aircraftData,
    iseReport,
    tab,
    handleTabPressed,
    reviewFormData,
    flightReport,
    resetPage,
    userType,
    tabsOptions,
    collapseHeader
}) => {
    let prognosticsLink = `/predictive/prognostics/${summaryData?.serial_number}`

    return (
        <div
            className={
                'predictiveReports__header__collapsed-' + (collapseHeader ? 'true' : 'false')
            }
        >
            {/* top part of header */}
            {collapseHeader ? (
                <div className='predictiveReports__globalHeaderContainer'>
                    <div className='predictiveReports__globalHeaderContainer__aircraftInfo'>
                        <Link href={'/predictive'}>
                            <FeatherIcon
                                width={25}
                                height={25}
                                color={'#D2D2D2'}
                                icon={'chevron-left'}
                                strokeWidth={3}
                                style={{ marginTop: 2 }}
                            />
                        </Link>
                        <div>
                            <div className='predictiveReports__serialNumber'>
                                {aircraftData.serialNumber}
                                <Link
                                    href={prognosticsLink}
                                    target='_blank'
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FeatherIcon
                                        width={18}
                                        height={18}
                                        color='#FFFFFF'
                                        icon={'trending-up'}
                                        strokeWidth={3}
                                        style={{
                                            marginLeft: 5
                                        }}
                                    />
                                </Link>
                            </div>
                            <div className='predictiveReports__sessionNumber predictiveReports__textBold'>
                                Session {iseReport?.session_number} - Flight {iseReport?.flight_leg}
                            </div>
                        </div>
                    </div>
                    <div className='predictiveReports__globalHeaderContainer__column'>
                        <div>
                            <div>
                                <span className='predictiveReports__textBold'>
                                    {flightReport && flightReport.departureAirportName
                                        ? `${flightReport.departureAirportIATA}`
                                        : '-'}
                                </span>{' '}
                                {flightReport && flightReport.departureTime
                                    ? formatDepartureArrivalTime(flightReport.departureTime)
                                    : '-'}
                            </div>
                        </div>
                        <div>
                            <div>
                                <span className='predictiveReports__textBold'>
                                    {flightReport && flightReport.arrivalAirportName
                                        ? `${flightReport.arrivalAirportIATA}`
                                        : '-'}
                                </span>{' '}
                                {flightReport && flightReport.arrivalTime
                                    ? formatDepartureArrivalTime(flightReport.arrivalTime)
                                    : '-'}
                            </div>
                        </div>
                    </div>
                    <div className='predictiveReports__globalHeaderContainer__column'>
                        <div>{iseReport && <span>{iseReport.description}</span>}</div>
                        <div>
                            {summaryData && <span>{getFlightPhase(summaryData.flight_phase)}</span>}
                        </div>
                    </div>
                    <div className='predictiveReports__globalHeaderContainer__column'>
                        <div>{iseReport && <span>{iseReport.ise_name}</span>}</div>
                        <div>{iseReport?.event_id}</div>
                    </div>
                </div>
            ) : (
                <div className='predictiveReports__globalHeaderContainer'>
                    <div className='predictiveReports__globalHeaderContainer__aircraftInfo'>
                        <Link href={'/predictive'}>
                            <FeatherIcon
                                width={25}
                                height={25}
                                color={'#D2D2D2'}
                                icon={'chevron-left'}
                                strokeWidth={3}
                                style={{ marginTop: 2 }}
                            />
                        </Link>
                        <div>
                            <div className='predictiveReports__serialNumber'>
                                {aircraftData.serialNumber}
                                <Link
                                    href={prognosticsLink}
                                    target='_blank'
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FeatherIcon
                                        width={22}
                                        height={22}
                                        color='#FFFFFF'
                                        icon={'trending-up'}
                                        strokeWidth={3}
                                        style={{
                                            marginLeft: 5
                                        }}
                                    />
                                </Link>
                            </div>
                            <div className='predictiveReports__textBelowSerialNumber'>
                                {aircraftData.aircraftFamily} {aircraftData.aircraftModel}
                            </div>
                            <div className='predictiveReports__textBelowSerialNumber'>
                                {aircraftData.tailNumber}
                            </div>
                            <div className='predictiveReports__sessionNumber predictiveReports__textBold'>
                                Session {iseReport?.session_number} - Flight {iseReport?.flight_leg}
                            </div>
                        </div>
                    </div>
                    <div className='predictiveReports__globalHeaderContainer__column'>
                        <div>
                            <div>
                                <span className='predictiveReports__textBold'>Departure: </span>
                                {flightReport && flightReport.departureTime
                                    ? moment
                                          .utc(flightReport.departureTime)
                                          .format('MMM DD, YYYY - HH:mm:ss')
                                    : '-'}
                            </div>
                            <div className='predictiveReports__textMidBold'>
                                {flightReport && flightReport.departureAirportName
                                    ? `${flightReport.departureAirportName} (${flightReport.departureAirportIATA})`
                                    : '-'}
                            </div>
                        </div>
                        <div>
                            <div>
                                <span className='predictiveReports__textBold'>Arrival: </span>
                                {flightReport && flightReport.arrivalTime
                                    ? moment
                                          .utc(flightReport.arrivalTime)
                                          .format('MMM DD, YYYY - HH:mm:ss')
                                    : '-'}
                            </div>
                            <div className='predictiveReports__textMidBold'>
                                {flightReport && flightReport.arrivalAirportName
                                    ? `${flightReport.arrivalAirportName} (${flightReport.arrivalAirportIATA})`
                                    : '-'}
                            </div>
                        </div>
                    </div>
                    <div className='predictiveReports__globalHeaderContainer__column'>
                        <div>
                            <div className='predictiveReports__textBold'>ATA Chapter:</div>
                            {iseReport && (
                                <span>
                                    {iseReport.ata_chapter} - {iseReport.description}
                                </span>
                            )}
                        </div>
                        <div>
                            <div className='predictiveReports__textBold'>Flight Phase:</div>
                            {summaryData && <span>{getFlightPhase(summaryData.flight_phase)}</span>}
                        </div>
                    </div>
                    <div className='predictiveReports__globalHeaderContainer__column'>
                        <div>
                            <div className='predictiveReports__textBold'>In-Service Event:</div>
                            {iseReport && <span>{iseReport.ise_name}</span>}
                        </div>
                        <div>
                            {iseReport?.event_id && (
                                <div className='predictiveReports__textBold'>
                                    In-Service Event ID:
                                </div>
                            )}
                            {iseReport?.event_id}
                        </div>
                    </div>
                </div>
            )}

            {/* bottom part of header */}
            {/* map out list of tabs and render each into a Tab Item */}
            <div className='predictiveReports__tabContainer'>
                {tabsOptions?.length > 0 &&
                    tabsOptions.map((item, index) => {
                        const isReviewTab = item.title === 'Review'
                        const containReview = reviewFormData?.containReview
                        const tabText = !isReviewTab
                            ? item.title
                            : containReview
                            ? 'Reviewed'
                            : 'Review'
                        const tabColor = !isReviewTab
                            ? '#D2D2D2'
                            : containReview
                            ? '#3ABB58'
                            : '#D2D2D2'
                        let tooltipText = null

                        if (item.title === 'Diagnostics' && userType !== 'internal') {
                            tooltipText = 'Not available for Beta'
                        }

                        return (
                            <React.Fragment key={item.title}>
                                {summaryData && summaryData.status !== 'archived' ? (
                                    <>
                                        {(item.title === 'Review'
                                            ? userType === 'internal'
                                            : item.title !== 'Restore') && (
                                            <TabItem
                                                data={item}
                                                text={tabText}
                                                icon={item.icon}
                                                color={tabColor}
                                                currentTab={tab}
                                                title={item.title}
                                                onPress={handleTabPressed}
                                                userType={userType}
                                                deactivated={item.deactivated}
                                                tooltipText={tooltipText}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {item.title !== 'Review' && (
                                            <TabItem
                                                data={item}
                                                text={item.title}
                                                icon={item.icon}
                                                color={'#D2D2D2'}
                                                currentTab={tab}
                                                title={item.title}
                                                onPress={
                                                    item.title === 'Restore'
                                                        ? resetPage
                                                        : handleTabPressed
                                                }
                                                userType={userType}
                                                deactivated={item.deactivated}
                                                tooltipText={tooltipText}
                                            />
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        )
                    })}
            </div>
        </div>
    )
}

export default PredictiveReports
