import React, { useEffect, useState } from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import { styled } from "@mui/material/styles";
import { TextField, Autocomplete } from "@mui/material";

const CustomTextField = styled(TextField)({
  width: "100%",
  minWidth: "100%",
  margin: "10px 0 0 0",
  "& label.Mui-focused": {
    color: "#D2D2D2",
  },
  "& label.Mui-disabled": {
    color: "#D2D2D2",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#D2D2D2",
  },
  "& .MuiOutlinedInput-root": {
    "& input": {
      fontWeight: 700,
      paddingTop: "20px !important",
      fontSize: 16,
    },
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "#D2D2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D2D2D2",
    },
  },
});

const CustomSelectAutocomplete = ({
  id,
  handleChange,
  options,
  placeholder,
  field,
  fieldId,
  selectedValues,
  optionsIsLoading,
  noOptionsText = "No options",
}) => {
  const [selected, setSelected] = useState(selectedValues);
  const [showLabel, setShowLabel] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const isVisualizationDropdown = typeof id === "undefined" ? true : false;
  const isDisabled = isVisualizationDropdown
    ? optionsIsLoading || options?.length === 0
      ? true
      : false
    : false;

  useEffect(() => {
    if (Array.isArray(selectedValues)) {
      setSelected([...selectedValues]);
    } else {
      setSelected([]);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (selected?.length === 0) setShowLabel(true);
  }, [selected]);

  return (
    <Autocomplete
      data-testid={id}
      multiple
      disabled={isDisabled}
      open={openDropdown}
      noOptionsText={noOptionsText}
      value={selected}
      onChange={(e, value, reason) => {
        if (value?.length > 0) setShowLabel(false);
        else setShowLabel(true);

        const text = value.map((o) => {
          return o[field];
        });
        const ids = value.map((o) => {
          return o[fieldId];
        });

        setSelected([...value]);

        if (isVisualizationDropdown) handleChange(e, value, reason);
        else handleChange(id, value, text, ids);
      }}
      sx={{
        width: "35%",
        height: selected?.length > 0 ? "fit-content" : "55px",
        backgroundColor: "transparent",
        borderBottomWidth: 0,
        border: "2px solid #505050",
        marginTop: "10px",
        marginLeft: "60px",
        "& .MuiAutocomplete-popupIndicator": isVisualizationDropdown && {
          transform: "none",
        },
        "& .MuiFormControl-root": {
          margin: 0
        },
        "& .MuiSvgIcon-root": {
          color: "white"
        },
        "& .MuiChip-root": {
          color: "white",
          background: "#303030",
          "svg": {
            color: "white"
          }
        }
      }}
      getOptionLabel={(option) => {
        if (isVisualizationDropdown)
          return `${option.ATAChapter ? option.ATAChapter : ""} ${option.parameterName
            }`;
        else return option[field];
      }}
      isOptionEqualToValue={(option, value) => {
        if (isVisualizationDropdown)
          return (
            `${option.ATAChapter ? option.ATAChapter : ""} ${option.parameterName
            }` ===
            `${value.ATAChapter ? value.ATAChapter : ""} ${value.parameterName}`
          );
        else return option[fieldId] === value[fieldId];
      }}
      renderOption={(props, option) => {
        return (
          <React.Fragment key={props.key}>
            {_.findIndex(selected, function (o) {
              if (isVisualizationDropdown)
                return (
                  `${o.ATAChapter ? o.ATAChapter : ""} ${o.parameterName}` ===
                  `${option.ATAChapter ? option.ATAChapter : ""} ${option.parameterName
                  }`
                );
              else return o[fieldId] === option[fieldId];
            }) === -1 && (
                <>
                  {isVisualizationDropdown ? (
                    <div {...props}>
                      {option.ATAChapter ? option.ATAChapter : ""}{" "}
                      {option.parameterName}
                    </div>
                  ) : (
                    <>{option[field] && <div {...props}>{option[field]}</div>}</>
                  )}
                </>
              )}
          </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          label={showLabel ? placeholder : ""}
          fullWidth={true}
          type="text"
          margin="none"
          onBlur={() => {
            if (selected?.length > 0) setShowLabel(false);
            else setShowLabel(true);

            setOpenDropdown(false);
          }}
          onFocus={() => {
            setShowLabel(false);
            setOpenDropdown(true);
          }}
          onKeyDown={() => setShowLabel(false)}
          InputLabelProps={{
            style: {
              fontWeight: isVisualizationDropdown ? 500 : 700,
              fontStyle: isVisualizationDropdown ? "italic" : "normal",
              fontSize: 16,
              color: "#D2D2D2",
            },
            shrink: false,
          }}
        />
      )}
      options={options?.length > 0 ? options : []}
      popupIcon={
        isVisualizationDropdown ? (
          <FeatherIcon
            width={30}
            height={30}
            icon={"search"}
            color={"#D2D2D2"}
          />
        ) : (
          <FeatherIcon
            width={30}
            height={30}
            icon={"chevron-down"}
            color={"#D2D2D2"}
          />
        )
      }
      ListboxProps={{
        style: {
          maxHeight: "350px",
        },
      }}
    />
  );
};

export default CustomSelectAutocomplete;
