import React, { useState, useEffect } from "react";
import "./GlobalHeader.css";
import FeatherIcon from "feather-icons-react";
import AircraftIcon from "../../resources/aircraft-solid.svg";
import moment from "moment";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import * as tools from "../../utils/CommonTools";
import { Link } from "react-router-dom";
import CustomButton from "../CustomButton";
import { sendAirframeDetailsDropdownEvent } from "../../GA4/EventsCatalog";

/**
 * The Global Header Component.
 * @param serialNumber The aircraft serial number.
 * @param registration The aircraft registration.
 * @param aircraftFamily The aircraft family.
 * @param aircraftModel The aircraft model.
 * @param timeInFlight The time in flight for the session.
 * @param latestDataTransferDate The last AHMS data transfer date
 * @param crumb The crumb item to be displayed for navigation.
 * @param hasLatestHmuTransferDate Flag that indicates if the AC has a latest hmu transfer date.
 * @param airframeTime The airframe time.
 * @param airframeCycles The airframe cycles.
 * @param engine1Serial The engine 1 serial number.
 * @param engine1Time The engine 1 time.
 * @param engine1Cycles The engine 1 cycle count.
 * @param engine2Serial The engine 2 serial number.
 * @param engine2Time The engine 2 time.
 * @param engine2Cycles The engine 2 cycle count.
 * @param enableCycleEdit Flag to indicate if the cycles can be edited.
 * @param monitorStatus The monitor status.
 * @param currentAircraftStatus The current aircraft status.
 * @param dataFocus The data focus.
 * @param faultDescription The fault description.
 * @return {JSX.Element}
 * @constructor
 */
export default function GlobalHeader({
  serialNumber,
  registration,
  aircraftFamily,
  aircraftModel,
  timeInFlight,
  latestDataTransferDate,
  crumb,
  hasLatestHmuTransferDate,
  airframeTime,
  airframeCycles,
  engine1Serial,
  engine1Time,
  engine1Cycles,
  engine2Serial,
  engine2Time,
  engine2Cycles,
  enableCycleEdit,
  monitorStatus,
  currentAircraftStatus,
  dataFocus,
  faultDescription,
  sessionNumber,
}) {
  // Variables needed for the collapse capability.
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsible, setIsCollapsible] = useState(false);

  const [engineLeft, setEngineLeft] = useState({});
  const [engineRight, setEngineRight] = useState({});

  const sessionNumberLocalStorage =
    localStorage.getItem("tmpACSession") ?? sessionNumber;
  const urlPath = `/aircraftview/${serialNumber}/${registration}/${sessionNumberLocalStorage}`;

  /**
   * Function to know if a variable is numeric.
   * @param num Variable to review.
   * @return {boolean} True if is numeric.
   */
  const isNumeric = (num) => {
    return !isNaN(num);
  };

  /**
   * Function to know if a number is odd.
   * @param num Number to review.
   * @return {boolean} True if it is odd.
   */
  const isOdd = (num) => {
    if (!isNumeric(num)) return false;
    if (parseInt(num) % 2 === 1) return true;
    else return false;
  };

  useEffect(() => {
    // Set left and right engines.
    if (isOdd(engine1Serial)) {
      setEngineLeft({
        serial: engine1Serial,
        time: engine1Time,
        cycles: engine1Cycles,
      });
      setEngineRight({
        serial: engine2Serial,
        time: engine2Time,
        cycles: engine2Cycles,
      });
    } else {
      setEngineLeft({
        serial: engine2Serial,
        time: engine2Time,
        cycles: engine2Cycles,
      });
      setEngineRight({
        serial: engine1Serial,
        time: engine1Time,
        cycles: engine1Cycles,
      });
    }
    // If we are in the aircraft view or looking at a fault then we enable
    // the collapsed information.
    if (
      dataFocus === "aircraft" ||
      dataFocus === "FAULT" ||
      (dataFocus === "CMS" && faultDescription) ||
      dataFocus === "IN-FLIGHT"
    ) {
      setIsCollapsible(true);
    }
  }, []);

  const onClickDropdown = () => {
    setIsCollapsed(!isCollapsed);
    if (isCollapsed) {
      sendAirframeDetailsDropdownEvent(
        tools.convertMinutesToHoursMinutesText(airframeTime),
        tools.formatCyclesText(airframeCycles),
        tools.convertMinutesToHoursMinutesText(engineLeft.time),
        tools.formatCyclesText(engineLeft.cycles),
        tools.convertMinutesToHoursMinutesText(engineRight.time),
        tools.formatCyclesText(engineRight.cycles)
      )
    }
  }

  /**
   * The panel that is shown when the user clicks the "expand" button.
   * @return {JSX.Element|null}
   */
  const expandPanelContent = () => {
    return dataFocus === "aircraft" ? (
      <div
        className={`globalHeader__additionalContent ${
          isCollapsed ? "globalHeader__additionalContent__collapsed" : ""
        }`}
      >
        <div className="globalHeader__additionalContent__item">
          {wrapInToolTip(
            <p className="globalHeader__additionalContent__item__title">
              <strong>Airframe Hours:</strong>{" "}
              {tools.convertMinutesToHoursMinutesText(airframeTime)}
            </p>
          )}
        </div>
        <div className="globalHeader__additionalContent__item">
          {wrapInToolTip(
            <p className="globalHeader__additionalContent__item__title">
              <strong>Airframe Cycles:</strong>{" "}
              {tools.formatCyclesText(airframeCycles)}
            </p>
          )}
        </div>
        <div className="globalHeader__additionalContent__item">
          {wrapInToolTip(
            <p className="globalHeader__additionalContent__item__title">
              <strong>{"Engine (" + engineLeft.serial + ") Hours:"}</strong>{" "}
              {tools.convertMinutesToHoursMinutesText(engineLeft.time)}
            </p>
          )}
        </div>
        <div className="globalHeader__additionalContent__item">
          {wrapInToolTip(
            <p className="globalHeader__additionalContent__item__title">
              <strong>{"Engine (" + engineRight.serial + ") Hours:"}</strong>{" "}
              {tools.convertMinutesToHoursMinutesText(engineRight.time)}
            </p>
          )}
        </div>
        <div className="globalHeader__additionalContent__item">
          {wrapInToolTip(
            <p className="globalHeader__additionalContent__item__title">
              <strong>{"Engine (" + engineLeft.serial + ") Cycles:"}</strong>{" "}
              {tools.formatCyclesText(engineLeft.cycles)}
            </p>
          )}
        </div>
        <div className="globalHeader__additionalContent__item">
          {wrapInToolTip(
            <p className="globalHeader__additionalContent__item__title">
              <strong>{"Engine (" + engineRight.serial + ") Cycles:"}</strong>{" "}
              {tools.formatCyclesText(engineRight.cycles)}
            </p>
          )}
        </div>
        <div className="globalHeader__additionalContent__buttonContainer">
          {enableCycleEdit && (
            <Link
              to={{
                pathname: "/configview",
                state: {
                  originPage: "AIRCRAFT",
                  selectedConfig: "CONFIG_AIRCRAFT_HOURS",
                  registrationNumber: registration,
                  aircraftFamily: aircraftFamily,
                  aircraftModel: aircraftModel,
                  status: currentAircraftStatus,
                  serial: serialNumber,
                  monitorStatus: monitorStatus,
                },
              }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CustomButton
                id={"btn-edit-cycles-aircraft"}
                text={"EDIT VALUES"}
                buttonStyle={"pill"}
                customButtonColor={"#F0A400"}
                customButtonTextColor={"#000"}
                borderStyle="none"
              />
            </Link>
          )}
        </div>
      </div>
    ) : dataFocus === "FAULT" ||
      (dataFocus === "CMS" && faultDescription) ||
      dataFocus === "IN-FLIGHT" ? (
      <div
        className={`globalHeader__additionalContent globalHeader__additionalContent__fault ${
          isCollapsed ? "globalHeader__additionalContent__collapsed" : ""
        }`}
      >
        <div className="globalHeader__additionalContent__faultDescription">
          <p>{faultDescription}</p>
        </div>
      </div>
    ) : null;
  };

  /**
   * Utility to wrap an item in a tooltip tag.
   * @param htmlContent The content to be wrapped.
   * @return {JSX.Element}
   */
  const wrapInToolTip = (htmlContent) => {
    return (
      <Tooltip
        title={<React.Fragment>{renderDescriptionsTooltip()}</React.Fragment>}
        arrow={true}
        placement={"right"}
        TransitionComponent={Fade}
        enterDelay={250}
        leaveDelay={80}
        classes={{
          popper: "aircraftInfoBannerSession__MuiTooltip-popper",
          tooltip: "aircraftInfoBannerSession__MuiTooltip-tootip",
          arrow: "aircraftInfoBannerSession__MuiTooltip-arrow",
        }}
      >
        {htmlContent}
      </Tooltip>
    );
  };

  /**
   * Renders the tooltip.
   * @return {JSX.Element}
   */
  const renderDescriptionsTooltip = () => {
    return (
      <div className="aircraftInfoBannerSession__toolTip__banner">
        <div className="aircraftInfoBannerSession__toolTip__banner__body">
          <div className="aircraftInfoBannerSession__toolTip__banner__row">
            <span className="aircraftInfoBannerSession__toolTip__banner__titles">
              {"Airframe Hours:"}
            </span>
            <span className="aircraftInfoBannerSession__toolTip__banner__descriptions">
              {
                "Time between Take-off to Landing report rounded to nearest minutes."
              }
            </span>
          </div>
          <div className="aircraftInfoBannerSession__toolTip__banner__row">
            <span className="aircraftInfoBannerSession__toolTip__banner__titles">
              {"Airframe Cycles:"}
            </span>
            <span className="aircraftInfoBannerSession__toolTip__banner__descriptions">
              {"Count at every Take-off report."}
            </span>
          </div>
          <div className="aircraftInfoBannerSession__toolTip__banner__row">
            <span className="aircraftInfoBannerSession__toolTip__banner__titles">
              {"Engine Hours:"}
            </span>
            <span className="aircraftInfoBannerSession__toolTip__banner__descriptions">
              {
                "Time between Take-off to Landing report rounded to nearest minutes."
              }
            </span>
          </div>
          <div className="aircraftInfoBannerSession__toolTip__banner__row">
            <span className="aircraftInfoBannerSession__toolTip__banner__titles">
              {"Engine Cycles:"}
            </span>
            <span className="aircraftInfoBannerSession__toolTip__banner__descriptions">
              {
                "Cycle is defined as a flight consisting of an acceleration to takeoff power, takeoff, and landing."
              }
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="globalHeader__mainContainer">
        <div className="globalHeader__iconContainer">
          <img
            className="header__aircraft__icon"
            src={AircraftIcon}
            alt="aircraft"
          ></img>
        </div>
        <div className="globalHeader__aircraftInfo__container">
          <p className="globalHeader__label">
            <strong>{serialNumber}</strong> {registration}
          </p>
          <p className="globalHeader__value">
            {aircraftFamily} {aircraftModel}
          </p>
        </div>
        <div className="globalHeader__flightTime__container">
          <p className="globalHeader__label">
            <strong>CURRENT STATUS:</strong>
          </p>
          <p className="globalHeader__value">{currentAircraftStatus}</p>
        </div>
        <div className="globalHeader__aircraftInfo__container">
          {hasLatestHmuTransferDate && (
            <>
              <p className="globalHeader__label">
                <strong>LATEST AHMS:</strong>
              </p>
              <p className="globalHeader__value">
                {moment
                  .utc(latestDataTransferDate)
                  .format("MMM DD, YYYY - HH:mm:ss")}
              </p>
            </>
          )}
        </div>
        {isCollapsible ? (
          <div
            className="globalHeader__iconContainer"
            onClick={() => onClickDropdown()}
          >
            <FeatherIcon
              icon={isCollapsed ? "chevron-down" : "chevron-up"}
              height={24}
              width={24}
              color={"#FFF"}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        ) : null}
      </div>
      {expandPanelContent()}
      <div className="globalHeader__breadcrumbContainer">
        <div className="globalHeader__iconContainer">
          <FeatherIcon icon="map" color={"#FFF"} height={15} width={15} />
        </div>
        {crumb && crumb.value ? (
          <Breadcrumb crumbItem={crumb} urlPath={urlPath} />
        ) : null}
      </div>
    </>
  );
}
