import React, { Component } from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import FilterBox from "../Global Components/FilterBox";
import ConfigCommonFaultRow from "./ConfigCommonFaultRow";
import { DropdownListFilterVersion } from "../Global Components/Dropdown.jsx";
import CustomCheckbox from "../Global Components/CustomCheckbox.jsx";
import "./ConfigCommonFaultList.css";

export default class ConfigCommonFaultList extends Component {
  constructor(props) {
    super(props);

    this.createRows = this.createRows.bind(this);
    this.handleAtaFilterChange = this.handleAtaFilterChange.bind(this);
    this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this);
    this.handleFaultCodeFilterChange = this.handleFaultCodeFilterChange.bind(
      this
    );
    this.handleFaultMessageFilterChange = this.handleFaultMessageFilterChange.bind(
      this
    );
    this.handleFdeCategoryFilterChange = this.handleFdeCategoryFilterChange.bind(
      this
    );
    this.loadFaultList = this.loadFaultList.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleListNavigation = this.handleListNavigation.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSingleEditCommonFault = this.handleSingleEditCommonFault.bind(
      this
    );
    this.handleEditCommonFault = this.handleEditCommonFault.bind(this);

    this.state = {
      faultList: [],
      faultListFilteredCount: 0,
      numberOfPages: 1,
      currentTablePage: 1,
      maxRowsInPage: 6,
      maxRowsForScroll: 6,
      commonFaultFilterText: {
        faultCode: "",
        faultMessage: "",
        ata: "",
        reduceStatus: "ALL",
        fdeCategory: "",
      },
      clearFilters: false,
    };
  }

  componentDidMount() {
    this.clearFilters(this.props.clearFilters);
    this.loadFaultList(this.props.faultList);
  }

  render() {
    return this.handleTableRender();
  }

  handleTableRender() {
    const scroll = this.state.faultList.length > this.state.maxRowsForScroll;

    return (
      <div className="configCommonFaultList">
        <div className="configCommonFaultList__header">
          <div className="configCommonFaultList__header__sectionCategory configCommonFaultList__header__font">
            <DropdownListFilterVersion
              options={[
                "FDE Category",
                "Warning",
                "Caution",
                "Advisory",
                "Info",
                "Status",
              ]}
              defaultValue={"FDE Category"}
              onChange={this.handleFdeCategoryFilterChange}
            />
          </div>
          <div className="configCommonFaultList__header__sectionAta configCommonFaultList__header__font">
            <FilterBox
              id={"fde-ata-search"}
              placeholderText={"ATA"}
              handleFilterChange={this.handleAtaFilterChange}
              customHeight={"42px"}
            />
          </div>
          <div
            className={
              "configCommonFaultList__header__sectionCode configCommonFaultList__header__font" +
              (this.props.faultFamilyModelRules
                ? !this.props.faultFamilyModelRules.faultCode
                  ? " configCommonFaultList__header__sectionCode-hideFaultCode"
                  : ""
                : "")
            }
          >
            <FilterBox
              id={"fde-code-search"}
              placeholderText={"Code"}
              handleFilterChange={this.handleFaultCodeFilterChange}
              customHeight={"42px"}
            />
          </div>
          <div
            className={
              "configCommonFaultList__header__sectionName configCommonFaultList__header__font" +
              (this.props.faultFamilyModelRules
                ? !this.props.faultFamilyModelRules.faultCode
                  ? " configCommonFaultList__header__sectionName-hideFaultCode"
                  : ""
                : "")
            }
          >
            <FilterBox
              id={"fde-name-search"}
              placeholderText={"FDE Name"}
              handleFilterChange={this.handleFaultMessageFilterChange}
              customHeight={"42px"}
            />
          </div>
          <div className="configCommonFaultList__header__font configCommonFaultList__header__sectionStatusFilter">
            <DropdownListFilterVersion
              options={["All", "On", "Off"]}
              defaultValue={"All"}
              onChange={this.handleStatusFilterChange}
            />
          </div>
          <div
            className={
              "configCommonFaultList__header__font configCommonFaultList__header__sectionEdit" +
              (scroll ? "-withBar" : "")
            }
          >
            Select
            <CustomCheckbox
              isChecked={
                _.filter(this.state.faultList, ["reduceStatus", false])
                  .length === this.state.maxRowsInPage
              }
              onChangeAction={this.handleSelectAll}
            />
          </div>
        </div>
        {this.createRows(this.state.faultList)}
        <div className="configCommonFaultList__listNavigation">
          <span
            className="configCommonFaultList__listNavigation__icon"
            onClick={() => this.handleListNavigation("FIRST")}
            disabled={this.state.currentTablePage === 1 ? true : false}
          >
            <FeatherIcon icon={"chevrons-left"} height={20} color={"#FFFFFF"} />
          </span>
          <span
            className="configCommonFaultList__listNavigation__icon"
            onClick={() => this.handleListNavigation("PREVIOUS")}
            disabled={this.state.currentTablePage === 1 ? true : false}
          >
            <FeatherIcon icon={"chevron-left"} height={20} color={"#FFFFFF"} />
          </span>
          <span className="configCommonFaultList__listNavigation__pages">
            {this.state.currentTablePage * this.state.maxRowsInPage -
              (this.state.maxRowsInPage - 1) +
              " - " +
              (this.state.currentTablePage * this.state.maxRowsInPage -
                (this.state.maxRowsInPage - this.state.faultList.length)) +
              " of " +
              this.state.faultListFilteredCount}
          </span>
          <span
            className="configCommonFaultList__listNavigation__icon"
            onClick={() => this.handleListNavigation("NEXT")}
            disabled={
              this.state.currentTablePage === this.state.numberOfPages
                ? true
                : false
            }
          >
            <FeatherIcon icon={"chevron-right"} height={20} color={"#FFFFFF"} />
          </span>
          <span
            className="configCommonFaultList__listNavigation__icon"
            onClick={() => this.handleListNavigation("LAST")}
            disabled={
              this.state.currentTablePage === this.state.numberOfPages
                ? true
                : false
            }
          >
            <FeatherIcon
              icon={"chevrons-right"}
              height={20}
              color={"#FFFFFF"}
            />
          </span>
        </div>
      </div>
    );
  }

  // Create Row method
  createRows(visibleFilteredFaultList) {
    let rows = <div />;
    if (visibleFilteredFaultList) {
      rows = (
        <div className={"configCommonFaultList__container"}>
          {visibleFilteredFaultList.map((fault) => (
            <React.Fragment key={fault.faultCode + Math.random() * 90000}>
              <ConfigCommonFaultRow
                id={fault.faultCode + Math.random() * 90000}
                faultCode={fault.faultCode}
                faultMessage={fault.faultMessage}
                ataChapter={fault.ataChapter}
                fdeCategory={fault.fdeCategory}
                reduceStatus={fault.reduceStatus}
                rowIsSubtle={fault.reduceStatus}
                rowIsFiltered={fault.isFilteredRow}
                handleEditCommonFaultClick={this.handleSingleEditCommonFault}
                faultFamilyModelRules={this.props.faultFamilyModelRules}
              />
            </React.Fragment>
          ))}
        </div>
      );
      return rows;
    }
    return (
      <div className="configCommonFaultList__containerNoData">
        NO DATA AVAILABLE
      </div>
    );
  }

  loadFaultList(faultList) {
    const filteredFaultList = [];
    faultList.forEach((fault) => {
      const isFilteredRow = this.isFilteredRow(
        fault.faultCode,
        fault.faultMessage,
        fault.ataChapter,
        fault.reduceStatus,
        fault.fdeCategory
      );
      fault.isFilteredRow = isFilteredRow;
      if (isFilteredRow) {
        filteredFaultList.push(fault);
      }
    });

    const numberOfPages = Math.ceil(
      filteredFaultList.length / this.state.maxRowsInPage
    );

    const newCurrentPage =
      this.state.currentTablePage > numberOfPages
        ? 1
        : this.state.currentTablePage;

    const visibleFilteredFaultList = _.slice(
      filteredFaultList,
      (newCurrentPage - 1) * this.state.maxRowsInPage,
      newCurrentPage * this.state.maxRowsInPage
    );

    this.setState({
      faultList: visibleFilteredFaultList,
      numberOfPages: numberOfPages,
      faultListFilteredCount: filteredFaultList.length,
      currentTablePage: newCurrentPage,
    });
  }

  isFilteredRow(faultCode, faultMessage, ata, reduceStatus, fdeCategory) {
    if (
      faultCode
        .toUpperCase()
        .includes(this.state.commonFaultFilterText.faultCode.toUpperCase()) &&
      faultMessage
        .toUpperCase()
        .includes(
          this.state.commonFaultFilterText.faultMessage.toUpperCase()
        ) &&
      ata
        .toUpperCase()
        .includes(this.state.commonFaultFilterText.ata.toUpperCase()) &&
      reduceStatus ===
        (this.state.commonFaultFilterText.reduceStatus === "ALL"
          ? reduceStatus
          : this.state.commonFaultFilterText.reduceStatus === "ON"
          ? false
          : true) &&
      fdeCategory
        .toUpperCase()
        .includes(this.state.commonFaultFilterText.fdeCategory.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearFilters(clearFilters) {
    if (clearFilters) {
      this.setState(
        (prevState) => ({
          commonFaultFilterText: {
            ...prevState.commonFaultFilterText,
            faultCode: "",
            faultMessage: "",
            category: "",
            ata: "",
          },
        }),
        () => {
          this.loadFaultList(this.props.faultList);
        }
      );
    }
  }

  handleListNavigation(direction) {
    if (direction === "FIRST") {
      this.setState(
        {
          currentTablePage: 1,
        },
        () => {
          this.loadFaultList(this.props.faultList);
        }
      );
    } else if (direction === "LAST") {
      this.setState(
        {
          currentTablePage: this.state.numberOfPages,
        },
        () => {
          this.loadFaultList(this.props.faultList);
        }
      );
    } else if (direction === "PREVIOUS") {
      if (this.state.currentTablePage > 1) {
        this.setState(
          {
            currentTablePage: this.state.currentTablePage - 1,
          },
          () => {
            this.loadFaultList(this.props.faultList);
          }
        );
      }
    } else {
      if (this.state.currentTablePage < this.state.numberOfPages) {
        this.setState(
          {
            currentTablePage: this.state.currentTablePage + 1,
          },
          () => {
            this.loadFaultList(this.props.faultList);
          }
        );
      }
    }
  }

  handleSelectAll() {
    this.handleEditCommonFault(
      _.map(this.state.faultList, "faultCode"),
      _.filter(this.state.faultList, ["reduceStatus", false]).length ===
        this.state.maxRowsInPage
    );
  }

  handleSingleEditCommonFault(faultCode, newStatus) {
    this.handleEditCommonFault([faultCode], newStatus);
  }

  handleEditCommonFault(faultCodes, newStatus) {
    this.props.handleEditCommonFaultClick(faultCodes, newStatus);
    this.loadFaultList(this.props.faultList);
  }

  handleStatusFilterChange(filterValue) {
    const formatValue = filterValue.value.toUpperCase();

    this.setState(
      (prevState) => ({
        commonFaultFilterText: {
          ...prevState.commonFaultFilterText,
          reduceStatus: formatValue,
        },
      }),
      () => {
        this.loadFaultList(this.props.faultList);
      }
    );
  }

  handleFdeCategoryFilterChange(filterValue) {
    let formattedValue = filterValue.value.toUpperCase();
    if (formattedValue === "FDE CATEGORY") {
      formattedValue = "";
    }

    this.setState(
      (prevState) => ({
        commonFaultFilterText: {
          ...prevState.commonFaultFilterText,
          fdeCategory: formattedValue,
        },
      }),
      () => {
        this.loadFaultList(this.props.faultList);
      }
    );
  }

  handleAtaFilterChange(textFieldFilterValue) {
    this.setState(
      (prevState) => ({
        commonFaultFilterText: {
          ...prevState.commonFaultFilterText,
          ata: textFieldFilterValue,
        },
      }),
      () => {
        this.loadFaultList(this.props.faultList);
      }
    );
  }

  handleFaultCodeFilterChange(textFieldFilterValue) {
    this.setState(
      (prevState) => ({
        commonFaultFilterText: {
          ...prevState.commonFaultFilterText,
          faultCode: textFieldFilterValue,
        },
      }),
      () => {
        this.loadFaultList(this.props.faultList);
      }
    );
  }

  handleFaultMessageFilterChange(textFieldFilterValue) {
    this.setState(
      (prevState) => ({
        commonFaultFilterText: {
          ...prevState.commonFaultFilterText,
          faultMessage: textFieldFilterValue,
        },
      }),
      () => {
        this.loadFaultList(this.props.faultList);
      }
    );
  }
}
