import React, { useCallback, useEffect, useState } from "react";
import "./NewGroundLoadingRequest.css";
import FeatherIcon from "feather-icons-react";
import GlobalDropdown from "../../Global Components/Dropdown/GlobalDropdown";
import GlobalDropzone from "../../Global Components/GlobalDropzone/GlobalDropzone";
import _ from "lodash";
import GlobalTextarea from "../../Global Components/GlobalInput/GlobalTextarea";
import CustomButton from "../../Global Components/CustomButton";
import ConfirmationPopup2 from "../../Global Components/ConfirmationPopup2";
import { getFleet } from "../../clients/FleetAlertsClient";
import {
  deleteGroundLoadingFile,
  newGroundLoadingSession,
  submitGroundLoadingSession,
} from "../../clients/GroundLoadingClient";
import Spinner from "../../Global Components/Spinner";
import SingleFileLoader from "./Ground Loading Files/SingleFileLoader";

/**
 * The new ground loading request view.
 * @param handleCancelAction Handle for when the user clicks the "cancel" button.
 * @param userInfo The information of the user that is logged in.
 * @param handleSubmitSuccessful Handle for when the new request is submitted successfully.
 * @return {JSX.Element}
 * @constructor
 */
export default function NewGroundLoadingRequest({
  userInfo,
  handleCancelAction,
  handleSubmitSuccessful,
}) {
  // List of forbidden, required and unique used for validation.
  const forbiddenFormats = ["zip"];
  const requiredFormats = ["LUH"];
  const uniqueFormats = ["LUH"];

  const [instructionsDismissed, setInstructionsDismissed] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadSize, setUploadSize] = useState(0);
  const [displaySummary, setDisplaySummary] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [requestInvalid, setRequestInValid] = useState(true);
  const [fileError, setFileError] = useState(true);
  const [tailOptions, setTailOptions] = useState([]);
  const [tailDropdownDisabled, setTailDropdownDisabled] = useState(false);
  const [dropzoneDisabled, setDropzoneDisabled] = useState(true);
  const [selectedTail, setSelectedTail] = useState(null);
  const [selectedSerial, setSelectedSerial] = useState(null);
  const [selectedTailOption, setSelectedTailOption] = useState(null);
  const [groundLoadingId, setGroundLoadingId] = useState(null);
  const [notes, setNotes] = useState("");
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [instructionsCollapsed, setInstructionsCollapsed] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showWaiting, setShowWaiting] = useState(false);

  const [waitingFileList, setWaitingFileList] = useState([]);
  const [processingFileList, setProcessingFileList] = useState([]);
  const [successfulFileList, setSuccessfulFileList] = useState([]);
  const [failedFileList, setFailedFileList] = useState([]);
  const [filenameList, setFilenameList] = useState([]);

  const [processFile1, setProcessFile1] = useState(null);
  const [processFile2, setProcessFile2] = useState(null);
  const [processFile3, setProcessFile3] = useState(null);
  const [processFile4, setProcessFile4] = useState(null);
  const [removingFilename, setRemovingFilename] = useState([]);

  /**
   * Gets the files that the user uploaded, and it adds the necessary fields
   * for internal processing.
   */
  useEffect(() => {
    const initGLSession = async () => {
      await initializeGroundLoadingSession();
      checkForDuplicatesAndInitFiles(selectedFiles);
    };
    if (!groundLoadingId) {
      initGLSession();
    } else {
      checkForDuplicatesAndInitFiles(selectedFiles);
    }
  }, [selectedFiles]);

  const checkForDuplicatesAndInitFiles = (files) => {
    let filesWithoutDuplicates = [];
    let filenames = [];
    _.forEach(files, (file) => {
      const isDuplicate = _.find(filenameList, (f) => {
        return f === file.name;
      });
      if (!isDuplicate) {
        filesWithoutDuplicates.push(file);
        filenames.push(file.name);
      }
    });
    const newSelectedFiles = filesWithoutDuplicates.map((selectedFile) => ({
      status: "waiting",
      process: false,
      compressedSize: 0,
      id: null,
      fileData: selectedFile,
    }));
    setWaitingFileList((current) => [...current, ...newSelectedFiles]);
    setFilenameList((current) => [...current, ...filenames]);
  };

  useEffect(() => {
    setShowWaiting(waitingFileList.length > 0);
    if (processingFileList.length < 4 && waitingFileList.length > 0) {
      let elementIsAssigned = false;
      const elementToAdd = waitingFileList[0];
      elementToAdd.status = "progress";
      elementToAdd.process = true;
      setProcessingFileList((current) => [...current, elementToAdd]);
      if (processFile1 === null && !elementIsAssigned) {
        setProcessFile1(elementToAdd);
        elementIsAssigned = true;
      }
      if (processFile2 === null && !elementIsAssigned) {
        setProcessFile2(elementToAdd);
        elementIsAssigned = true;
      }
      if (processFile3 === null && !elementIsAssigned) {
        setProcessFile3(elementToAdd);
        elementIsAssigned = true;
      }
      if (processFile4 === null && !elementIsAssigned) {
        setProcessFile4(elementToAdd);
        elementIsAssigned = true;
      }

      setWaitingFileList((current) =>
        current.filter((file) => {
          return file.fileData.name !== elementToAdd.fileData.name;
        })
      );
    }
    let totalRequestSize = 0;
    _.forEach(successfulFileList, (f) => {
      totalRequestSize += f.compressedSize;
    });
    setUploadSize(totalRequestSize);
    setShowSuccess(successfulFileList.length > 0);
    setShowFailed(failedFileList.length > 0);
  }, [waitingFileList, successfulFileList, failedFileList]);

  useEffect(() => {
    setShowProgress(processingFileList.length > 0);
  }, [processingFileList]);

  useEffect(() => {
    setErrors([]);
    const validationErrors = [];
    let disableUpload = false;
    // Checks each file for a forbidden format.
    filenameList.forEach((f) => {
      const err = forbiddenFormatValidator(f);
      if (err !== null) {
        validationErrors.push(err);
        disableUpload = true;
      }
    });
    if (filenameList.length > 0) {
      // Checks that the files include at least one of each required format.
      requiredFormats.forEach((format) => {
        const found = _.find(filenameList, (f) => {
          const extension = f.split(".").pop();
          if (extension === format) {
            return true;
          }
        });
        if (!found) {
          validationErrors.push({
            code: "required",
            message: `Upload must contain one .${format} file.`,
          });
          disableUpload = true;
        }
      });

      // Checks that the files do not contain more than one of each unique formats.
      uniqueFormats.forEach((format) => {
        const uniqueFiltered = _.filter(filenameList, (f) => {
          const extension = f.split(".").pop();
          return extension === format;
        });
        if (uniqueFiltered.length > 1) {
          validationErrors.push({
            code: "unique",
            message: `Upload must not contain more than one .${format} file.`,
          });
          disableUpload = true;
        }
      });
    }
    setFileError(disableUpload);
    setErrors(validationErrors);
  }, [filenameList]);

  /**
   * Triggers when the user selects a tail from the dropdown menu.
   */
  useEffect(() => {
    if (selectedTailOption && selectedTailOption.value) {
      const tailValue = selectedTailOption.value;
      const serialValue = selectedTailOption.label.split("-")[0].trim();
      setSelectedTail(tailValue);
      setSelectedSerial(serialValue);
      setDropzoneDisabled(false);
    }
  }, [selectedTailOption]);

  /**
   * Loads the tails that the user has access to via the API.
   * @type {(function(): void)|*}
   */
  const loadTailsForFilter = useCallback(() => {
    setIsLoading(true);
    getFleet(null)
      .then((response) => {
        if (response.data) {
          const tailFilterContent = _.map(response.data, (tailObj) => {
            const tail = tailObj.tails;
            return {
              value: tail.tailNumber,
              label: `${tail.serialNumber} - ${tail.tailNumber}`,
              familyModel: `${tail.aircraftFamily} ${tail.aircraftModel}`,
            };
          });
          // Filter only Global 7500
          // TODO: Handle this via configuration file.
          const allowedTails = _.filter(tailFilterContent, (t) => {
            return t.familyModel === "Global 7500";
          });
          setTailOptions(allowedTails);
        } else {
          setTailOptions([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  /**
   * When the component is initialized loads the tail list.
   */
  useEffect(() => {
    loadTailsForFilter();
  }, []);

  /**
   * When a ground loading session is created it disables the tail dropdown.
   */
  useEffect(() => {
    if (groundLoadingId && groundLoadingId !== "") {
      setTailDropdownDisabled(true);
    }
  }, [groundLoadingId]);

  /**
   * Initializes a ground loading session.
   * @type {(function(): Promise<void>)|*}
   */
  const initializeGroundLoadingSession = useCallback(async () => {
    if (selectedTail) {
      setIsLoading(true);
      await newGroundLoadingSession(userInfo.userId, selectedTail, null)
        .then((response) => {
          if (response.data) {
            setGroundLoadingId(response.data.id);
          } else {
            // TODO: AN ERROR HAPPENED
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedTail]);

  /**
   * Handles the accepted files after the user selects them in the dropzone.
   * @param files Files that were accepted by the dropzone.
   * @param hasError Flag to prevent the user from submitting if there were errors.
   */
  const handleAcceptedFiles = (files, hasError = false) => {
    setRequestInValid(false);
    setDisplaySummary(true);
    if (hasError) {
      setRequestInValid(true);
    }
    setSelectedFiles(files);
  };

  /**
   * Handles when a file status has changed.
   * @param files
   */
  const handleFileChange = (fileName, status) => {
    const changedFile = processingFileList.find(
      (file) => file.fileData.name === fileName
    );
    if (processFile1 && processFile1.fileData.name === fileName) {
      setProcessFile1(null);
    } else if (processFile2 && processFile2.fileData.name === fileName) {
      setProcessFile2(null);
    } else if (processFile3 && processFile3.fileData.name === fileName) {
      setProcessFile3(null);
    } else if (processFile4 && processFile4.fileData.name === fileName) {
      setProcessFile4(null);
    }
    setProcessingFileList((current) =>
      current.filter((file) => {
        return file.fileData.name !== fileName;
      })
    );
    if (status === "success") {
      changedFile.status = "success";
      changedFile.process = false;
      setSuccessfulFileList((current) => [...current, changedFile]);
    }
    if (status === "failed") {
      changedFile.status = "failed";
      changedFile.process = false;
      setFailedFileList((current) => [...current, changedFile]);
    }
  };

  /**
   * Handles the removal of a file from the file list.
   * @param file file to be removed.
   * @return {Promise<void>}
   */
  const handleRemoveFile = async (file) => {
    if (file.id !== null && file.id !== "") {
      setRemovingFilename((current) => [...current, file.fileData.name]);
      await deleteGroundLoadingFile(
        file.id,
        userInfo.userId,
        selectedTail,
        groundLoadingId,
        null
      )
        .then((response) => {
          if (response.status === 200) {
            removeFileFromLists(file.fileData.name, file.status);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setRemovingFilename((current) =>
            current.filter((filename) => {
              return filename !== file.fileData.name;
            })
          );
        });
    } else {
      removeFileFromLists(file.fileData.name, file.status);
    }
  };

  const removeFileFromLists = (fileName, status) => {
    if (status === "success") {
      setSuccessfulFileList((current) =>
        current.filter((f) => {
          return f.fileData.name !== fileName;
        })
      );
    } else if (status === "failed") {
      setFailedFileList((current) =>
        current.filter((f) => {
          return f.fileData.name !== fileName;
        })
      );
    } else if (status === "waiting") {
      setWaitingFileList((current) =>
        current.filter((f) => {
          return f.fileData.name !== fileName;
        })
      );
    }
    setFilenameList((current) =>
      current.filter((f) => {
        return f !== fileName;
      })
    );
  };

  /**
   * Handles the submit of a ground loading session.
   * @return {Promise<void>}
   */
  const handleSubmitSession = async () => {
    setIsLoading(true);
    await submitGroundLoadingSession(
      userInfo.userId,
      groundLoadingId,
      selectedTail,
      notes,
      null
    )
      .then((response) => {
        if (response.status === 202) {
          setShowConfirmationPopup(false);
          handleSubmitSuccessful();
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Helper to format the estimated request size.
   * TODO: This function may be moved to the reusable tools component.
   * @param bytes Request size in bytes.
   * @return {string}
   */
  const formatRequestSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1000;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  /**
   * Validates the format of a file against the forbidden formats array.
   * @param filename File to be validated.
   * @return {Object} Error object with the details.
   */
  const forbiddenFormatValidator = (filename) => {
    const extension = filename.split(".").pop();
    let error = null;
    if (forbiddenFormats) {
      forbiddenFormats.forEach((format) => {
        if (extension === format) {
          error = {
            code: "format-forbidden",
            message: `The format .${format} is not allowed.`,
          };
        }
      });
    }
    return error;
  };

  // Closes the summary box.
  const closeSummary = () => {
    setDisplaySummary(false);
  };

  const toggleInstructionsCollapsed = () => {
    setInstructionsCollapsed(!instructionsCollapsed);
  };

  const renderFileGroup = (status, iconName, groupName, color) => {
    return (
      <div className="file__upload__group__container">
        <div className="file__upload__group__name">
          <FeatherIcon
            icon={iconName}
            height={"20px"}
            width={"20px"}
            color={color}
          />
          <p
            style={{
              color: color,
            }}
          >
            {groupName}
          </p>
        </div>
        {status === "waiting"
          ? waitingFileList.map((f, index) => (
              <div key={`single-file-loader-${index}-${f.fileData.name}`}>
                <SingleFileLoader
                  file={f}
                  handleFileChange={handleFileChange}
                  handleRemoveFile={handleRemoveFile}
                  userId={userInfo.userId}
                  registrationId={selectedTail}
                  groundLoadingId={groundLoadingId}
                  showRemoveButton={true}
                />
              </div>
            ))
          : null}

        {status === "progress" && processFile1 !== null && (
          <div key={`single-file-loader-${0}-${processFile1.fileData.name}`}>
            <SingleFileLoader
              file={processFile1}
              handleFileChange={handleFileChange}
              handleRemoveFile={handleRemoveFile}
              userId={userInfo.userId}
              registrationId={selectedTail}
              groundLoadingId={groundLoadingId}
              showRemoveButton={false}
            />
          </div>
        )}
        {status === "progress" && processFile2 !== null && (
          <div key={`single-file-loader-${1}-${processFile2.fileData.name}`}>
            <SingleFileLoader
              file={processFile2}
              handleFileChange={handleFileChange}
              handleRemoveFile={handleRemoveFile}
              userId={userInfo.userId}
              registrationId={selectedTail}
              groundLoadingId={groundLoadingId}
              showRemoveButton={false}
            />
          </div>
        )}
        {status === "progress" && processFile3 !== null && (
          <div key={`single-file-loader-${2}-${processFile3.fileData.name}`}>
            <SingleFileLoader
              file={processFile3}
              handleFileChange={handleFileChange}
              handleRemoveFile={handleRemoveFile}
              userId={userInfo.userId}
              registrationId={selectedTail}
              groundLoadingId={groundLoadingId}
              showRemoveButton={false}
            />
          </div>
        )}
        {status === "progress" && processFile4 !== null && (
          <div key={`single-file-loader-${0}-${processFile4.fileData.name}`}>
            <SingleFileLoader
              file={processFile4}
              handleFileChange={handleFileChange}
              handleRemoveFile={handleRemoveFile}
              userId={userInfo.userId}
              registrationId={selectedTail}
              groundLoadingId={groundLoadingId}
              showRemoveButton={false}
            />
          </div>
        )}

        {status === "success"
          ? successfulFileList.map((f, index) => (
              <div key={`single-file-loader-${index}-${f.fileData.name}`}>
                <SingleFileLoader
                  file={f}
                  handleFileChange={handleFileChange}
                  handleRemoveFile={handleRemoveFile}
                  userId={userInfo.userId}
                  registrationId={selectedTail}
                  groundLoadingId={groundLoadingId}
                  showRemoveButton={
                    removingFilename.find(
                      (filename) => filename === f.fileData.name
                    ) === undefined
                  }
                />
              </div>
            ))
          : null}

        {status === "failed"
          ? failedFileList.map((f, index) => (
              <div key={`single-file-loader-${index}-${f.fileData.name}`}>
                <SingleFileLoader
                  file={f}
                  handleFileChange={handleFileChange}
                  handleRemoveFile={handleRemoveFile}
                  userId={userInfo.userId}
                  registrationId={selectedTail}
                  groundLoadingId={groundLoadingId}
                  showRemoveButton={
                    f.id === null ||
                    removingFilename.find(
                      (filename) => filename === f.fileData.name
                    ) === undefined
                  }
                />
              </div>
            ))
          : null}
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            width: "100%",
            marginLeft: "calc(-7% - 50px)",
            marginRight: "-7%",
            marginTop: "-20px",
            height: "100%",
            backgroundColor: "rgb(0,0,0,.5)",
            zIndex: "2000",
          }}
        >
          <div style={{ width: "100px", paddingTop: "20%", margin: "auto" }}>
            <Spinner />
          </div>
        </div>
      ) : (
        <div className="ground__loading__new__request__container">
          <div className="ground__loading__nr__header">
            <p>New Upload</p>
          </div>
          <div
            className={
              instructionsDismissed
                ? "ground__loading__nr__instructions__dismissed"
                : "ground__loading__nr__instructions"
            }
          >
            <div className="ground__loading__nr__instructions__left__container">
              <div
                className="ground__loading__nr__instructions__left__container__label"
                onClick={toggleInstructionsCollapsed}
              >
                <label>Review Instructions</label>
                <FeatherIcon
                  icon={instructionsCollapsed ? "chevron-down" : "chevron-up"}
                  height={"18px"}
                  width={"18px"}
                  color={"#FAFBFC"}
                  className={"instructions__dropdown__icon"}
                />
              </div>
            </div>

            <div className="ground__loading__nr__instructions__right__container">
              <label
                className="ground__loading__nr__instructions__dismissLink"
                onClick={() => {
                  setInstructionsCollapsed(true);
                  setInstructionsDismissed(true);
                }}
              >
                Dismiss
              </label>
            </div>
          </div>
          <div
            className={`ground__loading__nr__instructions__detail ${
              instructionsCollapsed
                ? "ground__loading__nr__instructions__detail__collapsed"
                : ""
            }`}
          >
            <span className="instructions__divider"></span>
            <h2 className="instructions__title">Uploading Files Overview</h2>
            <p className="instructions__detail">
              Use the ground loading feature to remotely transfer database
              files, documents and data tables to the aircraft’s Information
              Management System (IMS) via the AHMS. This feature can be used to
              transfer files to the IMS and store them on the aircraft's local
              drive where they can be accessed by the aircraft’s flight and
              maintenance personnel. For more information, refer to the Flight
              Crew Operating Manual. Uploading files may take several days as
              files will be stored on the aircraft’s server and transferred to
              the IMS the next time the aircraft performs a bulk transfer.
            </p>
            <h2 className="instructions__title">Instructions:</h2>
            <p className="instructions__detail">
              <strong>Step 1:</strong> Select the aircraft’s tail number.{" "}
            </p>
            <p className="instructions__detail">
              <strong>Step 2:</strong> Select the file you wish to upload. You
              can upload multiple files at a time by adding them to the Choose
              Files drop box. The cumulative file size must be smaller than 2GB
              before you can submit your request. Once all files have been
              uploaded, press Submit to begin the transfer process. Please note
              that once files are submitted, they cannot be removed or updated.{" "}
            </p>
            <p className="instructions__detail">
              <strong>Step 3:</strong> Once files have been accepted, they will
              appear in your Transfer Log.{" "}
            </p>
          </div>
          <div className="ground__loading__nr__main__container">
            <div className="ground__loading__nr__input__container">
              <div className="ground__loading__nr__tail__filter">
                <GlobalDropdown
                  options={tailOptions}
                  onChange={(option) => {
                    setSelectedTailOption(option);
                  }}
                  placeholder={"Search..."}
                  label={"Tail Number *"}
                  isSearchable={true}
                  optionsAreObjects={true}
                  defaultValue={selectedTailOption}
                  disabled={tailDropdownDisabled}
                />
              </div>

              <div className="ground__loading__nr__filesDropzone">
                <GlobalDropzone
                  label={"Select Files*"}
                  description={"Click to choose files or drag and drop"}
                  required={true}
                  iconColor={"#FFFFFF"}
                  iconName={"upload-cloud"}
                  handleUploadedFile={handleAcceptedFiles}
                  errors={errors}
                  isDisabled={dropzoneDisabled}
                />
              </div>
            </div>
            <div className="ground__loading__nr__upload__progress">
              <div className="ground__loading__nr__up__header">
                <h2>Upload Progress</h2>
                <p>
                  {formatRequestSize(uploadSize)} queued / <strong>2GB</strong>{" "}
                  limit
                </p>
              </div>
            </div>
            <div className="ground__loading__nr__upload__groups">
              {showFailed &&
                renderFileGroup(
                  "failed",
                  "alert-circle",
                  "Failed to queue",
                  "#FF5050"
                )}

              {showSuccess &&
                renderFileGroup(
                  "success",
                  "check-circle",
                  "Successfully queued",
                  "#5EA128"
                )}

              {showProgress &&
                renderFileGroup(
                  "progress",
                  "upload-cloud",
                  "Files being queued",
                  "#0B69C0"
                )}
              {showWaiting &&
                renderFileGroup("waiting", "clock", "Queuing files", "#606060")}
            </div>
            <div className="ground__loading__nr__input__container">
              <div className="ground__loading__nr__notes__container">
                <GlobalTextarea
                  id={"ground__loading__notes"}
                  handleInputOffFocus={(value) => {
                    setNotes(value);
                  }}
                  placeholder={"Write your notes here..."}
                  label={"Notes"}
                  defaultValue={notes}
                  maximumLength={400}
                  readonly={false}
                />
              </div>
              {displaySummary && (
                <div className="ground__loading__nr__summary__container">
                  <div className="ground__loading__nr__summary">
                    <div className="nr__summary__icon" onClick={closeSummary}>
                      <FeatherIcon
                        icon={"file"}
                        height={"35px"}
                        width={"35px"}
                        color={"#FFC72C"}
                      />
                    </div>
                    <div className="nr__summary__text">
                      <div>
                        <p>
                          {" "}
                          {successfulFileList.length === 1
                            ? `${successfulFileList.length} file`
                            : `${successfulFileList.length} files`}{" "}
                          queued{" "}
                        </p>
                        <p>
                          {" "}
                          <strong>
                            {failedFileList.length === 1
                              ? `${failedFileList.length} error`
                              : `${failedFileList.length} errors`}
                          </strong>{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      className="nr__summary__icon"
                      onClick={() => {
                        setDisplaySummary(false);
                      }}
                    >
                      <FeatherIcon
                        icon={"x"}
                        height={"26px"}
                        width={"26px"}
                        color={"#FFC72C"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="ground__loading__nr__footer">
            <CustomButton
              id={"btn-new-ground-loading-request__cancel"}
              text={"CANCEL"}
              buttonStyle={"pill"}
              customButtonColor={"transparent"}
              customButtonTextColor={"#F0A400"}
              customHeight={"40px"}
              customButtonBorderColor={"#F0A400"}
              onClickAction={handleCancelAction}
            />
            <CustomButton
              id={"btn-new-ground-loading-request__submit"}
              text={"SUBMIT"}
              buttonStyle={"pill"}
              customButtonColor={"#F0A400"}
              customButtonTextColor={"#000"}
              customHeight={"40px"}
              borderStyle="none"
              isDisabled={
                requestInvalid || fileError || uploadSize > 2000000000
              }
              onClickAction={() => {
                setShowConfirmationPopup(true);
              }}
            />
          </div>
          <ConfirmationPopup2
            active={showConfirmationPopup}
            title={"Uploading files"}
            descriptionText={`You are about to upload files for Tail #${selectedSerial}. This action cannot be undone.`}
            confirmationText={"Would you like to proceed?"}
            additionalInfo={""}
            hasIcon={true}
            icon={
              <FeatherIcon
                icon="upload"
                width={43.33}
                height={43.33}
                color={"#FFF"}
              />
            }
            cancelText={"CANCEL"}
            acceptText={"SUBMIT"}
            handleSubmitCancel={() => {
              setShowConfirmationPopup(false);
            }}
            handleSubmitOk={handleSubmitSession}
          />
        </div>
      )}
    </>
  );
}
