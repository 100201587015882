import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import "./ParameterSummaryDialog.css";
import CustomButton from "./CustomButton";
function ParameterSummaryDialog({
  active,
  title,
  parameters,
  handleSubmitClose
}) {
  return (
    <div
      style={{
        display: active ? "block" : "none",
        position: "fixed",
        zIndex: "444",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        overflow: "auto",
        backgroundColor: "rgba(0,0,0, 0.4)"
      }}>
      <div
        style={{
          backgroundColor: "#202020",
          margin: "5% auto",
          width: "60%",
          maxHeight: "700px",
          overflow: "auto",
          zIndex: "445",
          borderRadius: "10px",
          border: "1px solid #888"
        }}>
        <Grid
          container
          spacing={0}
          direction={"row"}
          style={{
            backgroundColor: "#303030",
            height: "80px",
            fontSize: "18px",
            fontWeight: "700",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px"
          }}>
          <Grid
            item xs={8}>
            <p>
              {title}
            </p>
          </Grid>
          <Grid
            item
            xs={4}>
            {parameters.length} parameter(s)
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction={"row"}
          style={{
            maxHeight: "490px",
            paddingLeft: "25px",
            paddingRight: "25px",
            marginTop: "20px",
            marginBottom: "20px",
            overflowY: "auto"
          }}>
          <Grid
            item
            xs={6}
            style={{paddingTop: "0px"}}>
            <p className={"parameterSummaryHeader"}>Parameter Name</p>
          </Grid>
          <Grid
            item
            xs={2}
            pt={0}
            style={{paddingTop: "0px"}}>
            <p className={"parameterSummaryHeader"}>ATA</p>
          </Grid>
          <Grid
            item
            xs={2}
            pt={0}
            style={{paddingTop: "0px"}}>
            <p className={"parameterSummaryHeader"}>LRU</p>
          </Grid>
          <Grid
            item
            xs={2}
            pt={0}
            style={{paddingTop: "0px"}}>
            <p className={"parameterSummaryHeader"}>Type</p>
          </Grid>
          {parameters.length > 0 ? (
            parameters.map((parameter, index) => (
              <React.Fragment key={"rpdParameterSummary_"+ index}>
                <Grid
                  item
                  xs={6}>
                  <p className={"parameterSummaryValue"}>{parameter.parameterName}</p>
                </Grid>
                <Grid
                  item
                  xs={2}>
                  <p className={"parameterSummaryValue"}>{parameter.ATAChapter}</p>
                </Grid>
                <Grid
                  item
                  xs={2}>
                  <p className={"parameterSummaryValue"}>{parameter.LRU}</p>
                </Grid>
                <Grid
                  item
                  xs={2}>
                  <p className={"parameterSummaryValue"}>{parameter.parameterType}</p>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Grid
              item
              xs={12}>
              <p className={"noParametersLabel"}>No parameters available</p>
            </Grid>
            )}
        </Grid>
        <Grid
          container
          spacing={0}
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          style={{
            backgroundColor: "#303030",
            height: "80px",
            bottom: "0"
          }}>
          <Grid item style={{marginRight: "20px"}}>
            <CustomButton
              id={"btn-confirmModalPopup2-cancel"}
              text={"CLOSE"}
              colorStyle={"custom"}
              customButtonColor={"#606060"}
              customButtonTextColor={"#FFFFFF"}
              onClickAction={handleSubmitClose}/>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default ParameterSummaryDialog;

ParameterSummaryDialog.defaultProps = {
  active: false,
  title: "",
  parameters: []
}

ParameterSummaryDialog.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string,
  parameters: PropTypes.array
}