import React, { Component } from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import NoticePopup from "./NoticePopup.js";

import "./NoticeHeader.css";

export default class NoticeHeader extends Component {
    constructor(props) {
        super(props);
        this.displayMaximumCharacterCount = this.displayMaximumCharacterCount.bind(this);
        this.handleMaximumCharacterCount = this.handleMaximumCharacterCount.bind(this);
        this.state = {
            maximumCharacterCount: 0
        };
    }

    displayMaximumCharacterCount(string) {
        if (string.length > this.state.maximumCharacterCount)
            return string.substring(0, this.state.maximumCharacterCount) + "...";
        else return string;
    }

    /**
     * Function that updates the maximum number of characters allowed
     * depdending on the screen size so there is no overflowing the top notice banner
     */
    handleMaximumCharacterCount() {
        if (typeof window !== "undefined") {
            let maxCount = 0;
            if (window.innerWidth >= 1024) {
                maxCount = window.innerWidth / 5;
            } else if (window.innerWidth <= 650) {
                maxCount = window.innerWidth / 10;
            } else {
                maxCount = window.innerWidth / 7;
            }
            this.setState({
                maximumCharacterCount: maxCount
            })
        }
    }

    componentDidMount() {
        this.handleMaximumCharacterCount();

        window.addEventListener('resize', this.handleMaximumCharacterCount);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleMaximumCharacterCount);
    }

    render() {
        // Only display See More button if content text was cut off
        let displayMoreButton = this.props.notice?.message?.content?.length > this.state.maximumCharacterCount;

        return (
            <div className="noticeHeader">
                <div className="noticeHeader__content">
                    {!(_.isEmpty(this.props.notice))
                        && <>
                            {/* Notice Banner */}
                            <div className={"noticeHeader__modal-" + (this.props.showModal ? "open" : "close")}>
                                <NoticePopup
                                    id={this.props.notice.id}
                                    open={this.props.showModal}
                                    handleNoticeModal={this.props.handleNoticeModal}
                                    descriptionSituation={this.props.notice.message.title}
                                    description={this.props.notice.message.content}
                                    errorCode={"NC-NT-00001"}
                                />
                            </div>
                            {!this.props.showModal &&
                                // Top Fixed Banner
                                <div className={"noticeHeader__topBanner"}>
                                    <span className="noticeHeader__topBanner__title">{this.props.notice.message.title} : </span>
                                    <span className="noticeHeader__topBanner__content">
                                        {this.displayMaximumCharacterCount(this.props.notice.message.content)}
                                    </span>
                                    {displayMoreButton
                                        && <span
                                            className="noticeHeader__topBanner__moreButton"
                                            onClick={() => this.props.handleNoticeModal(true)}
                                        >
                                            See More
                                            <FeatherIcon
                                                icon="plus"
                                                height={10}
                                                width={10}
                                                strokeWidth={4}
                                                style={{ marginLeft: 5 }}
                                            />
                                        </span>
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        );
    }
}
