import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for configuration of user selected common fde events
*/

const COMMON_FDE_LIST = Api.COMMON_FDE_LIST;
const COMMON_FDE_UPDATE = Api.COMMON_FDE_UPDATE;

// Get the user configured common FDE events to filter out what a user does not want to see on a first glance on the aircrfat view.
async function getCommonFDEList(aircraftFamily, aircraftModel) {
  let result;
  let data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
    },
  };

  try {
    result = await AxiosService(
      COMMON_FDE_LIST.url,
      COMMON_FDE_LIST.method,
      data
      //axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Update the user configured common FDE events to filter out what a user does not want to see on a first glance on the aircrfat view.
async function postCommonFDEList(
  commonFaultList,
  aircraftFamily,
  aircraftModel
) {
  let result;
  const data = {
    faults: commonFaultList,
    aircraftFamily: aircraftFamily,
    aircraftModel: aircraftModel,
  };

  try {
    result = await AxiosService(
      COMMON_FDE_UPDATE.url,
      COMMON_FDE_UPDATE.method,
      data
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

export { getCommonFDEList, postCommonFDEList };
