import React from "react";
import PropTypes from "prop-types";
import "../styles/Global Components/Button.css";
import "../styles/Global Components/CustomButton.css";

/*
  Custom Button Component Possible Values

  iconOnly = (true, false)
  colorStyle = ("primary", "secondary", "custom")
  buttonStyle = ("default", "pill")
  iconPosition = ("start", "end")
  borderRadiusStyle = ("default", "squared")
  isDisabled = (true, false)
*/

const CustomButton = ({
  id,
  iconOnly,
  text,
  colorStyle,
  customButtonColor,
  customButtonTextColor,
  buttonStyle,
  icon,
  iconPosition,
  customWidth,
  customHeight,
  borderStyle,
  borderRadiusStyle,
  isDisabled,
  onClickAction,
  preventDefault,
  customButtonBorderColor,
}) => {
  const handleOnClickAction = (e) => {
    if (preventDefault) {
      e.preventDefault();
    }
    if (onClickAction) {
      onClickAction();
    }
  };

  return iconOnly ? (
    <button
      id={id}
      className={
        "btn-iconOnly btn-style-color-iconOnly" +
        " btn-style-borderRadius-" +
        (borderRadiusStyle === "squared" ? "squared" : "iconOnly") +
        (borderStyle === "none" ? " btn-style-border-none" : "")
      }
      onClick={handleOnClickAction}
      disabled={isDisabled}
    >
      {icon}
    </button>
  ) : icon ? (
    <button
      id={id}
      className={
        "btn-withIcon" +
        (buttonStyle === "pill" ? "-pill" : "") +
        " btn-style-color-" +
        colorStyle +
        " btn-style-borderRadius-" +
        borderRadiusStyle +
        (borderStyle === "none" ? " btn-style-border-none" : "")
      }
      style={{
        width: customWidth,
        height: customHeight,
        backgroundColor: customButtonColor ? customButtonColor : "",
        color: customButtonTextColor ? customButtonTextColor : "",
      }}
      onClick={handleOnClickAction}
      disabled={isDisabled}
    >
      {iconPosition === "start" ? (
        <div className="customButton-withIcon-container">
          {icon}
          <span className="customButton-text-withIcon-start">{text}</span>
        </div>
      ) : (
        <div className="customButton-withIcon-container">
          <span className="customButton-text-withIcon-end">{text}</span>
          {icon}
        </div>
      )}
    </button>
  ) : (
    <button
      id={id}
      className={
        (buttonStyle === "pill" ? "btn-pill" : "btn-default") +
        " btn-style-color-" +
        colorStyle +
        " btn-style-borderRadius-" +
        borderRadiusStyle +
        (borderStyle === "none" ? " btn-style-border-none" : "")
      }
      style={{
        width: customWidth,
        height: customHeight,
        backgroundColor: customButtonColor && !isDisabled ? customButtonColor
          : customButtonColor && isDisabled ? "#404040" : "",
        color: customButtonTextColor && !isDisabled ? customButtonTextColor : customButtonTextColor && isDisabled ? "#202020" : "",
        cursor: isDisabled ? "not-allowed" : "",
        borderColor: customButtonBorderColor ? customButtonBorderColor : "",
      }}
      onClick={handleOnClickAction}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

export default CustomButton;

CustomButton.defaultProps = {
  id: "custom-button-id",
  iconOnly: false,
  text: "",
  colorStyle: "primary",
  customButtonColor: "",
  customButtonTextColor: "",
  buttonStyle: "default",
  iconPosition: "end",
  customWidth: "initial",
  customHeight: "initial",
  borderRadiusStyle: "default",
  borderStyle: "default",
  isDisabled: false,
};

CustomButton.propTypes = {
  id: PropTypes.string,
  iconOnly: PropTypes.bool,
  text: PropTypes.string,
  colorStyle: PropTypes.string,
  customButtonColor: PropTypes.string,
  customButtonTextColor: PropTypes.string,
  buttonStyle: PropTypes.string,
  icon: PropTypes.object,
  iconPosition: PropTypes.string,
  customWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  customHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  borderRadiusStyle: PropTypes.string,
  borderStyle: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClickAction: PropTypes.func,
  preventDefault: PropTypes.bool,
};
