export const BOMBARDIER_API_URL = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    // Production Instance
    case "PROD":
      return "https://crc.bombardier.cloud";
    // Pre Production / QA Instance (This instance is currently not available for the frontend)
    case "PRE-PROD":
      return "https://crcpreprod.bombardier.cloud";
    // Dev Instance (This instance is currently not available for the frontend)
    // Pre production / QA Instance (This instance is currently used as QA for the front endbut will become an only dev)
    case "DEV":
      return "https://crcpreprod.bombardier.cloud";
    // Demo Instance (Currently uses demo header flag)
    case "DEMO":
      return "https://crcpreprod.bombardier.cloud";
    // Default / Local Instances
    default:
      // return "https://crcpreprod.bombardier.cloud";
      return "https://crc.bombardier.cloud";
  }
};

// Demo instance header flag
export const MYDOM_DEMO_INSTANCE = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
    return false;
  } else if (process.env.REACT_APP_ENVIRONMENT === "DEMO") {
    return true;
  } else {
    return false;
  }
};

/* Notice Viewed Expirations */
export const timeExpirationNoticeSmartLink = 1440 * 60000; //24 Hours -> 1400 Minutes;

export const PREDICTIVE_MAINTENANCE_URL = () => {
  // Prod endpoint
  if (process.env.REACT_APP_ENVIRONMENT === "PREDICTIVE") {
    return "https://ahzmztefj1.execute-api.ca-central-1.amazonaws.com";
  } else if (process.env.REACT_APP_ENVIRONMENT === "PREDICTIVE-QA") {
    // QA endpoint
    return "https://j4zfc3wwb2.execute-api.ca-central-1.amazonaws.com";
  } else if (process.env.REACT_APP_ENVIRONMENT === "PREDICTIVE-DEV") {
    // DEV endpoint
    return "https://w47oysb41l.execute-api.ca-central-1.amazonaws.com";
  } else {
    // QA endpoint for default / Local
    return "https://w47oysb41l.execute-api.ca-central-1.amazonaws.com";
    return "https://j4zfc3wwb2.execute-api.ca-central-1.amazonaws.com";
  }
};
