import React, { useState } from "react";
import Select from "react-select";
import Checkbox from "../../../Checkbox/Checkbox";
import { Form } from "react-bootstrap";
import Spinner from "../../../../../Global Components/Spinner";
import { StyledDropzone } from "../../../../../Global Components/Dropzone";
import CustomButton from "../../../../../Global Components/CustomButton";
import FeatherIcon from "feather-icons-react";
import "./FeedbackForm.scss";
import "../SharedFeedbackForm.scss";
import { uploadFeedBackImage } from "../../../../../clients/S3Client";

const CreateUUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

export const FeedbackForm = ({
  handleChange,
  handleSubmit,
  updateS3ImageDetails,
  handleBack,
  email,
  consent,
  message,
  selected,
  options,
  termsOfUseLink,
  privacyStatementLink,
}) => {
  const userId = JSON.parse(localStorage.getItem("userInfo")).userId;
  const dirName = userId + "/images"; /* optional */

  const [isValidating, setIsValidating] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    email: null,
    subject: null,
    checkbox: null,
  });

  const [imageLoadSuccess, setImageLoadSuccess] = useState(null);

  let display = "feedbackForm";
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "black" : "", //empty since it will depend on the theme (light: color will be white)
      "&:hover": {
        color: "black",
      },
      "&:active": {
        color: "black",
      },
    }),
  };

  const handleUploadLocalFile = (file) => {
    setImageLoadSuccess(null);

    /* This is optional */
    const newFileName = CreateUUID();
    const filePathArray = file[0].path.split(".");
    setIsLoading(true);
    uploadFeedBackImage(
      dirName,
      newFileName,
      file,
      filePathArray[filePathArray.length - 1]
    )
      .then(() => {
        updateS3ImageDetails(
          dirName + "/",
          newFileName + "." + filePathArray[filePathArray.length - 1]
        );
        setImageLoadSuccess(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setImageLoadSuccess(false);
        setIsLoading(false);
      });
  };

  const submitValidation = () => {
    setIsValidating(true);
    let valid = true;
    let emailError = null;
    let subjectError = null;
    let checkboxError = null;

    if (!email || !emailIsValid(email)) {
      emailError = "Please provide a properly formatted email";
      valid = false;
    }
    if (!selected || selected === null || selected.length === 0) {
      subjectError = "Please select an subject";
      valid = false;
    }
    if (!consent) {
      checkboxError = "Please check the checkbox";
      valid = false;
    }

    setError({
      email: emailError,
      subject: subjectError,
      checkbox: checkboxError,
    });
    if (valid) {
      handleSubmit();
    } else {
      setIsValidating(false);
    }
  };

  return (
    <Form className="feedbackForm">
      {!isValidating ?
        <div>
          <Form.Group>
            <Form.Label className="feedbackFormLabel">
              What suggestion would you like to make?
            </Form.Label>
            <p className="feedbackFormErrorMessage">
              <span className="formError">*</span> indicates a required field
            </p>
            <Form.Control
              className="feedbackModalFormInput"
              type="email"
              name="email"
              value={email}
              placeholder="your_email@address.com"
              onChange={handleChange}
            />
            <div className="formError">{error.email}</div>
          </Form.Group>

          <Form.Group>
            <Select
              className="feedbackMultiSelect"
              placeholder="Choose a Subject"
              value={selected}
              options={options}
              styles={customStyles}
              onChange={(value) =>
                handleChange({ target: { name: "selectedValues", value: value } })
              }
              isMulti={true}
            />
          </Form.Group>

          <div className="formError">{error.subject}</div>

          <Form.Group>
            <Form.Control
              className="feedbackModalFormInput"
              as="textarea"
              placeholder="Your Feedback"
              name="message"
              rows="3"
              onChange={handleChange}
              value={message}
            />
          </Form.Group>

          {isLoading ? 
            <div
              style={{
                width: "100%",
                height: "100%",
                zIndex: "2000",
              }}
            >
              <div 
                style={{ 
                  width: "90px", 
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  margin: "auto",
                }}
              >
                <Spinner />
                Uploading...
              </div>
            </div>
            :
            imageLoadSuccess ?
              <div style={{ color: "#00ffa3" }}>Image Loaded Successfully!</div>
              :
              <div className="crc-add-and-drop-fb" style={{cursor: "pointer"}}>
                <StyledDropzone
                  handleUploadLocalFile={handleUploadLocalFile}
                  btnLabel="SCREENSHOT"
                  btnId="feedback-dragdrop"
                />
              </div>
          }
          {imageLoadSuccess === false && (
            <div className="formError">Error loading image!</div>
          )}
          <div className="feedbackCheckboxContainer">
            <Checkbox
              name="consent"
              id={display + "Consent"}
              checked={consent}
              onChange={() =>
                handleChange({ target: { name: "consent", value: !consent } })
              }
              label="By checking this box, you consent to Bombardier's"
            >
              <div className="feedbackFormLinks">
                <a
                  className="checkLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={termsOfUseLink}
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  className="opoCheckLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={privacyStatementLink}
                >
                  Privacy Statement
                </a>
                <span className="opoAsterisk">*</span>
              </div>
            </Checkbox>
            <span className="formError">{error.checkbox}</span>
          </div>
          <div className="feedbackButtonContainer">
            <CustomButton
              isDisabled={isLoading}
              id={"back-data-button"}
              text={"CANCEL"}
              buttonStyle={"pill"}
              iconPosition={"start"}
              onClickAction={() => handleBack()}
            />
            <CustomButton
              isDisabled={isLoading}
              id={"submit-data-button"}
              text={"SUBMIT"}
              buttonStyle={"pill"}
              icon={
                <FeatherIcon
                  icon={"arrow-right"}
                  height={16}
                  width={16}
                />
              }
              iconPosition={"end"}
              onClickAction={submitValidation}
              preventDefault={true}
            />
          </div>
        </div>
      :
        <div
            style={{
              width: "100%",
              height: "100%",
              zIndex: "2000",
              paddingBottom: "15px",
            }}
          >
            <div 
              style={{ 
                width: "90px", 
                paddingTop: "15px",
                paddingBottom: "15px",
                margin: "auto",
              }}
            >
            <Spinner/>
          </div>
          Submitting your feedback...
        </div>
      }
    </Form>
  );
};

const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export default FeedbackForm;
