import React, { useEffect, useState, useMemo, useReducer } from 'react'
import _ from 'lodash'
import { MaterialReactTable } from 'material-react-table'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CustomButton from '../../../Global Components/CustomButton'
import { getMaterialTableIcons } from '../../../Graph Components/Chart Options/MaterialTableConfig'
import { parameterSelectionColumns } from '../../constants'

import './ParameterSelectionTable.css'

const ParameterSelectionTable = props => {
    const { summaryData, onLoadChartsClick, selectedParams, updateParamsToAdd } = props

    const columns = useMemo(() => parameterSelectionColumns)
    const list = _.cloneDeep([...summaryData.anomalies])

    // Keeping track of which additional continuous parameters have been selected to chart
    const [selectedParameters, setSelectedParameters] = useState(selectedParams)
    const [filterStatus, setFilterStatus] = useState({})
    const [rowSelection, setRowSelection] = useState({})

    // Custom theme for the table.
    const tableTheme = useMemo(() =>
        createTheme({
            palette: {
                mode: 'dark',
                background: {
                    default: '#000'
                }
            },
            typography: {
                allVariants: {
                    color: '#FFF',
                    fontFamily: "'DM Sans', sans-serif"
                }
            },
            components: {
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none'
                        }
                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            fontSize: 16
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            fontSize: 16
                        }
                    }
                },
                MuiCollapse: {
                    styleOverrides: {
                        root: {
                            marginTop: -30
                        }
                    }
                },
                MuiBox: {
                    styleOverrides: {
                        root: {
                            color: '#101010'
                        }
                    }
                },
                MuiTableHeadCell: {
                    styleOverrides: {
                        root: {
                            marginTop: 20
                        }
                    }
                }
            }
        })
    )

    // Keep track of filter status
    const handleFilterChange = (column, filterValue) => {
        let newFilterStatusRef = filterStatus
        newFilterStatusRef[column] = filterValue

        setFilterStatus(newFilterStatusRef)
    }

    // Pre-select some rows on the table first load
    useEffect(() => {
        let selectedRows = {}
        selectedParameters.map(selectedParam => {
            let parameterName = selectedParam.parameter_name
            let index = _.findIndex(list, function (o) {
                return _.isEqual(o.parameter_name, parameterName)
            })
            if (index !== -1) {
                selectedRows = { ...selectedRows, [parameterName]: true }
            }
        })
        setRowSelection(selectedRows)
    }, [selectedParams])

    useEffect(() => {
        let selectedParameters = []

        if (Object.keys(rowSelection).length === list.length) {
            // Check if ALL were selected
            selectedParameters = list
        } else if (Object.keys(rowSelection).length === 0) {
            // Check if NONE were selected
            selectedParameters = []
        } else {
            // If row was unchecked
            for (let i = 0; i < selectedParameters.length; i++) {
                const parameterName = selectedParameters[i].parameter_name
                if (!(parameterName in rowSelection)) {
                    selectedParameters.splice(i, 1)
                }
            }

            // If row was selected
            for (let parameterName in rowSelection) {
                const index = _.findIndex(selectedParameters, function (o) {
                    return o.parameter_name === parameterName
                })

                if (index == -1) {
                    const listIndex = _.findIndex(list, function (o) {
                        return o.parameter_name === parameterName
                    })

                    if (listIndex !== -1) {
                        selectedParameters.push(list[listIndex])
                    }
                }
            }
        }

        setSelectedParameters([...selectedParameters])
    }, [rowSelection])

    return (
        <React.Fragment>
            {list.length > 0 && rowSelection && (
                <div className='infoBox parameterSelection__table'>
                    <div
                        className='infoBox__header'
                        style={{ backgroundColor: '#252525', color: 'white' }}
                    >
                        Additional Parameters
                    </div>
                    <div
                        className='infoBox__body'
                        style={{
                            padding: 20,
                            background: 'rgb(12, 12, 12)'
                        }}
                    >
                        <ThemeProvider theme={tableTheme}>
                            <MaterialReactTable
                                muiTableHeadCellProps={{
                                    sx: {
                                        '& .Mui-TableHeadCell-Content-Actions>button': {
                                            display: 'none'
                                        },
                                        '& .Mui-TableHeadCell-Content-Labels': {
                                            color: '#101010'
                                        }
                                    }
                                }}
                                data={list}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        pageIndex: 0,
                                        pageSize: 5 //customize the default page size
                                    },
                                    showColumnFilters: true
                                }}
                                getRowId={row => row.parameter_name}
                                onRowSelectionChange={setRowSelection}
                                state={{ rowSelection }}
                                enableRowSelection={true}
                                enableSorting={false}
                                enableTopToolbar={false}
                                selectAllMode='all'
                                autoResetPageIndex={false} // Prevents the pagination auto reset after updating the data state value
                            />
                        </ThemeProvider>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingTop: '15px'
                            }}
                        >
                            <CustomButton
                                id={'id-update-charts'}
                                text={'UPDATE CHARTS'}
                                isDisabled={false}
                                customWidth={250}
                                onClickAction={() => {
                                    onLoadChartsClick(selectedParameters)
                                    updateParamsToAdd(selectedParameters, 'continuous')
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}
export default ParameterSelectionTable
