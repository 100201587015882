import React, { useState } from "react";
import PropTypes from "prop-types";
import { MessageSquare, AlertTriangle } from "react-feather";
import { Button } from "react-bootstrap";
import FeedbackType from "./FeedbackSupport/FeedbackType/FeedbackType";
import FeedbackForm from "./FeedbackSupport/FeedbackForm/FeedbackForm";
import FeedbackConfirmation from "./FeedbackSupport/FeedbackConfirmation/FeedbackConfirmation";
import FeedbackModal from "./FeedbackSupport/FeedbackModal/FeedbackModal";
import MobileFeedback from "./FeedbackSupport/MobileFeedback/MobileFeedback";
import ErrorModal from "../ErrorModal/ErrorModal";
import Theme from "./FeedbackSupport/Theme.js";
import "./Feedback.scss";

const Feedback = ({
  navOpen,
  isDark,
  icon,
  text,
  contactUsLink,
  pageOptions,
  termsOfUseLink,
  privacyStatementLink,
  submitHandler,
  ratingOptions,
  disableMobile,
}) => {
  const [page, setPage] = useState(FeedbackPages.Closed);
  const [state, setState] = useState(defaultState);
  const [s3ImageDetails, setS3ImageDetails] = useState(s3Details);

  const closeModal = () => {
    setPage(FeedbackPages.Closed);
    setState(defaultState);
    setS3ImageDetails(s3Details);
  };
  const typeModal = () => setPage(FeedbackPages.Type);
  const feedbackModal = () => setPage(FeedbackPages.Feedback);
  const confirmationModal = () => setPage(FeedbackPages.Confirmation);
  const errorModal = () => setPage(FeedbackPages.Error);

  const onStateChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const updateS3ImageDetails = (dirPath, fileName) => {
    setS3ImageDetails({
      dirPath: dirPath,
      fileName: fileName,
    });
  };

  const submissionHandler = () => {
    let stateWithS3Info = state;
    stateWithS3Info.dirPath = s3ImageDetails.dirPath;
    stateWithS3Info.fileName = s3ImageDetails.fileName;
    submitHandler(stateWithS3Info, confirmationModal, errorModal);
    setState(defaultState);
  };

  let feedbackPage = undefined;
  if (page === FeedbackPages.Type)
    feedbackPage = (
      <FeedbackType
        handleNext={feedbackModal}
        feedbackRating={state.feedbackRating}
        ratingOptions={ratingOptions}
        handleChange={onStateChange}
      />
    );
  else if (page === FeedbackPages.Feedback)
    feedbackPage = (
      <FeedbackForm
        handleBack={typeModal}
        email={state.email}
        selected={state.selectedValues}
        options={pageOptions}
        consent={state.consent}
        message={state.message}
        termsOfUseLink={termsOfUseLink}
        privacyStatementLink={privacyStatementLink}
        handleChange={onStateChange}
        handleSubmit={submissionHandler}
        updateS3ImageDetails={updateS3ImageDetails}
      />
    );
  else if (page === FeedbackPages.Confirmation)
    feedbackPage = <FeedbackConfirmation isDark={isDark} onHide={closeModal} />;

  return (
    <Theme isDark={isDark}>
      {navOpen ? (
        <Button
          className={
            "sidebarFloaterModalButton sidebarFloaterModalButton " +
            (disableMobile ? "" : "desktop")
          }
          onClick={typeModal}
        >
          {icon && <span className="sidebarFloaterMessageIcon">{icon}</span>}
          {text}
        </Button>
      ) : (
        <Button
          id="feedback-btn"
          className={
            "sidebarFloaterModalButton sidebarFloaterModalButton " +
            (disableMobile ? "" : "desktop")
          }
          onClick={typeModal}
          style={{ padding: "4px 11px 4px 11px" }}
        >
          {icon && <span className="sidebarFloaterMessageIcon">{icon}</span>}
        </Button>
      )}
      {!disableMobile && (
        <MobileFeedback
          icon={icon}
          isOpen={page !== FeedbackPages.Closed}
          toggleOpen={page === FeedbackPages.Closed ? typeModal : closeModal}
        >
          {feedbackPage}
        </MobileFeedback>
      )}
      {page === FeedbackPages.Error && (
        <ErrorModal
          isDark={isDark}
          title="Error"
          icon={<AlertTriangle size="25" />}
          show={true}
          msg={
            "There was an error submitting your feedback. Please try again later."
          }
          onHide={closeModal}
        />
      )}
      {feedbackPage && (
        <FeedbackModal
          isDark={isDark}
          className={disableMobile ? "" : "desktop"}
          show={true}
          icon={icon}
          onHide={closeModal}
          contactUsLink={contactUsLink}
        >
          {feedbackPage}
        </FeedbackModal>
      )}
    </Theme>
  );
};

const FeedbackPages = {
  Closed: 0,
  Type: 1,
  Feedback: 2,
  Confirmation: 3,
  Error: 4,
};

const defaultState = {
  type: "feedback",
  email: "",
  message: "",
  consent: false,
  fileName: "",
  dirPath: "",
  feedbackRating: undefined,
  selectedValues: [],
};

const s3Details = {
  bucketName: undefined,
  fileName: undefined,
};
export default Feedback;

Feedback.defaultProps = {
  icon: <MessageSquare size="25" />,
  text: "Feedback",
};

Feedback.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string,
};
