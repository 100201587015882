import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import FeatherIcon from "feather-icons-react";
import "../styles/Global Components/Dropzone.css";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 1,
  borderRadius: 2,
  borderColor: "#979797",
  borderStyle: "dashed",
  backgroundColor: "rgba(255,255,255,0.12)",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  height: "135px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export function StyledDropzone(props) {
  const onDrop = useCallback((acceptedFile) => {
    props.handleUploadLocalFile(acceptedFile);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Or drag and drop file here.</p>
        <div style={{ marginTop: "20px" }}>
          <div
            style={{ border: "1px solid white", borderRadius: "20px", padding: "6px 10px 6px 10px" }}
            id={props.btnId ? props.btnId : "btn-template-dragdrop"}
          > <FeatherIcon
              icon="upload"
              height={18}
              wiidth={18}
              color={"#FFFFFF"}
            />{props.btnLabel ? props.btnLabel : "UPLOAD TEMPLATE"}</div>
        </div>
      </div>
    </div>
  );
}
