import React from 'react';
import { Modal } from 'react-bootstrap';
import { useRef } from 'react';

/**
 * Wrapper component that is responsible for taking care of fixing the fixed elements on screen when a modal appears
 * and disappears.
 */
export const ModalWrapper = ({children, onHide, className, show, animation, dialogClassName, backdrop}) => {
    const previousState = useRef();
    if (previousState.current === true && show === false) {
        previousState.current = false;
        fixFixedElementsForModal();
    }

    return (
      <Modal
        className={className}
        show={show}
        onHide={onHide}
        animation={animation}
        dialogClassName={dialogClassName}
        onShow={() => {fixFixedElementsForModal(true); previousState.current = true;}}
        backdrop={backdrop}>
        {children}
      </Modal>
    )
}

//Code that is responsible for fixing the UI glitches and inconsistencies of displaying a modal
const fixFixedElementsForModal = (isModalVisible) => {
    if (document) {
        let body = document.getElementsByTagName("body")[0];

        if (body.scrollHeight > body.offsetHeight) {
            fixNavBarForModal(isModalVisible);
            fixFeedBackForModal(isModalVisible);
        }
    }
}

const fixFeedBackForModal = (isModalVisible) => {
    let feedbackModalButton = document.getElementsByClassName("feedbackModalButton")[0];

    if (feedbackModalButton) {
        //Change the following attributes to keep the feedback element in the proper spot
        feedbackModalButton.style.right = isModalVisible ? getScrollBarWidth() : null;
        feedbackModalButton.style.paddingBottom = isModalVisible ? "15px" : null;
        feedbackModalButton.style.top = isModalVisible ? "calc(30% + " + 10 + "px)" : null;

        //We need to flush the javascript changes before resetting transtion
        // feedbackModalButton.offsetHeight;
        feedbackModalButton.style.transition = isModalVisible ? "none" : null;
    }
}

const fixNavBarForModal = (isModalVisible) => {
    let navBar = document.getElementsByClassName("navBannerContainer")[0];

    if (navBar)
        navBar.style.right = isModalVisible ? getScrollBarWidth() : null;
}

const getScrollBarWidth = () => {
    //Create the containers to get the width
    let outerTempDiv = document.createElement('div');
    outerTempDiv.style.visibility = 'hidden';
    outerTempDiv.style.overflow = 'scroll';

    //Create the temporary inner element
    let innerTempDiv = document.createElement('div');

    //Append them both temporarily to the DOM
    document.body.appendChild(outerTempDiv);
    outerTempDiv.appendChild(innerTempDiv);

    let scrollWidth = (outerTempDiv.offsetWidth - innerTempDiv.offsetWidth) + "px";

    //Remove the temp elements from the DOM
    document.body.removeChild(outerTempDiv);

    return scrollWidth;
}

export default ModalWrapper;