import React, { Fragment } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { styled } from "@mui/material/styles";

const DatePickerRoot = styled(DatePicker)({
  "& .MuiInputBase-root": {
    padding: 6.5,
    border: "1px solid #949494",
    backgroundColor: "#131415",
  },
});

const DatePickerEventCount = ({
  selectedDate,
  handleDateChange,
  minStartDate,
  errorMessage,
}) => {
  const datePickerTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={datePickerTheme}>
          <DatePickerRoot
            format="dd/MM/yyyy"
            disableToolbar
            disableFuture
            inputVariant="outlined"
            value={selectedDate}
            onChange={handleDateChange}
            minDate={minStartDate ? minStartDate : undefined}
          />
          {errorMessage && (
            <span style={{ color: "#FF0000", fontSize: "10px" }}>
              {errorMessage}
            </span>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </Fragment>
  );
};

export default DatePickerEventCount;
