import React, { useEffect, useState, useContext, useRef } from "react";
import AWS from "aws-sdk";
import CircularProgress from "@mui/material/CircularProgress";

import InfoBoxRow from "../../Global Components/InfoBoxRow";
import Zoom from "./Zoom";
import ReportContext from "../Context Components/ReportContext";
import { getAwsCredentials, xmlToJson } from "../../usefulFunctions";

import "./Diagnostics.css";

const Diagnostics = (props) => {
  const {
    data,
    treeData,
    iseReport
  } = props;
  const {
    loadingValue
  } = useContext(ReportContext);

  const [loading, setLoading] = loadingValue;
  const [treePosition, setTreePosition] = useState({
    top: null,
    left: null
  });
  const [treeDims, setTreeDims] = useState({
    height: null,
    width: null
  });
  const [imgDims, setImgDims] = useState({
    height: null,
    width: null
  });
  // Keep track of current LRU selected
  const [currentLRU, setCurrentLRU] = useState(null);
  const [lruImages, setLruImages] = useState({});

  const treeRef = useRef(null);
  const imageRef = useRef(null);

  const getAllImagesISE = async (lrus) => {
    let newLruImages = {};
    for (let i = 0; i < lrus.length; i++) {
      const lru = lrus[i];
      if (lru.image_url) {
        if (newLruImages[lru.image_url] == null) {
          let res = await getImageWidthHeight(lru.image_url)
            .catch((e) => console.error("Image doesn't exist.", e));
          if (typeof res !== "undefined"
            && typeof res.width !== "undefined"
            && typeof res.height !== "undefined") {
            newLruImages[`${lru.lru_name}`] = {
              url: lru.image_url,
              height: res.height,
              width: res.width
            };
          }
        }
      }
    }

    setLruImages(newLruImages);
  }

  // This function checks if image exists
  const getImageWidthHeight = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;

      img.onload = function () {
        document.body.appendChild(this);
        const width = this.width;
        const height = this.height;
        document.body.removeChild(this);
        resolve({
          width: width,
          height: height
        })
      };
      img.onerror = reject;
    })
  };

  const getTreePosition = () => {
    let rect = treeRef.current.getBoundingClientRect();

    return {
      top: rect.top + 50,
      left: rect.left - 40,
      width: rect.width
    }
  };

  // Function gets width and height of container that contains the Tree graph
  const getTreeContainerDims = () => {
    let width = treeRef.current.offsetWidth;
    let height = treeRef.current.offsetHeight - 50;

    return {
      height: height,
      width: width
    };
  };

  // Function gets width and height of container that contains the LRU image
  const getImgContainerDims = () => {
    let width = imageRef.current.offsetWidth;
    let height = imageRef.current.offsetHeight - 50;

    return {
      height: height,
      width: width
    };
  };

  // Function handles the NEXT and PREVIOUS actions of the carousel
  const handleImageCarousel = (action) => {
    if (action === "NEXT") {
      setImageIndex(imageIndex + 1);
    } else if (action === "PREVIOUS") {
      setImageIndex(imageIndex - 1);
    }
  }

  const formatString = (value) => {
    // Split at numbered list
    let stringSplit = value.split(/([0-9]+)/);
    let newString = "";
    for (let i = 0; i < stringSplit.length; i++) {
      const string = stringSplit[i];
      if (!isNaN(string - parseFloat(string)) && parseFloat(string) < 10) {
        if ((i + 1) <= (stringSplit.length - 1) && stringSplit[i + 1][0] === '.') {
          newString += '<br></br>'
        }
      }
      newString += string;
    }

    // Split at link and make link clickable
    stringSplit = newString.split('https');
    if (stringSplit.length > 1) {
      const link = 'https' + stringSplit[1];
      newString = stringSplit[0]
        + `<a href="${link}" target="_blank">`
        + link
        + `</a>`
    }

    return newString;
  }

  useEffect(() => {
    setLoading(true);
    if (!_.isEmpty(data)) {
      const handleResize = () => {
        if (data?.lrus_info?.length !== 0) {
          setTreeDims(getTreeContainerDims());
          setImgDims(getImgContainerDims());
          setTreePosition(getTreePosition());
        }
      }

      const handleScroll = () => {
        if (data?.lrus_info?.length !== 0) {
          setTreePosition(getTreePosition());
        }
      }

      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("scroll", handleScroll);
      }
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      if (
        typeof data !== "undefined"
        && Object.keys(lruImages).length === 0
      ) {
        setLoading(false);
      }

      if (treeRef.current) {
        setTreeDims(getTreeContainerDims());
        setTreePosition(getTreePosition());
      }

      if (imageRef.current) {
        setImgDims(getImgContainerDims());
      }
      getAllImagesISE(data.lrus_info);
    } else {
      setLoading(false)
    }
  }, [data]);

  // Component for FM info box
  const FMInfoBox = () => {
    return (
      <div className="infoBox">
        <div className="infoBox__header">
          Event Description
        </div>
        <div className={"infoBox__body" + (data ? "" : "-no-content")}>
          {data.potential_faults &&
            <InfoBoxRow
              name={"Potential Fault(s)"}
              value={
                data.potential_faults.length === 1
                  ? <span dangerouslySetInnerHTML={{ __html: formatString(data.potential_faults[0]) }} />
                  :
                  <ul className="diagnostics__eventDescription__list">
                    {data.potential_faults.map((fault) =>
                      <li key={`FMInfoBox-${fault}`}>
                        <span dangerouslySetInnerHTML={{ __html: formatString(fault) }} />
                      </li>
                    )}
                  </ul>
              }
            />
          }
          {data.event_descriptions &&
            <InfoBoxRow
              name={"Event Description(s)"}
              value={
                data.event_descriptions.length === 1
                  ? <span dangerouslySetInnerHTML={{ __html: formatString(data.event_descriptions[0]) }} />
                  :
                  <ul className="diagnostics__eventDescription__list">
                    {data.event_descriptions.map((description) =>
                      <li key={`FMInfoBox-${description}`}>
                        <span dangerouslySetInnerHTML={{ __html: formatString(description) }} />
                      </li>
                    )}
                  </ul>
              }
            />
          }
          {data.recommendations &&
            <InfoBoxRow
              name={"Recommendation(s)"}
              value={
                data.recommendations.length === 1
                  ? <span dangerouslySetInnerHTML={{ __html: formatString(data.recommendations[0]) }} />
                  :
                  <ul className="diagnostics__eventDescription__list">
                    {data.recommendations.map((recommendations) =>
                      <li key={`FMInfoBox-${recommendations}`}>
                        <span dangerouslySetInnerHTML={{ __html: formatString(recommendations) }} />
                      </li>
                    )}
                  </ul>
              }
            />
          }
        </div>
      </div>
    )
  };

  // Component for LRU info box
  const LRUInfoBox = () => {
    return (
      <div className="infoBox">
        <div className="infoBox__header">
          LRU Description
        </div>
        <div className={"containsFooter infoBox__body" + (currentLRU ? "" : "-no-content")}>
          {!currentLRU
            ? "Select an LRU  to see the Description"
            :
            <>
              {data.system_name &&
                <InfoBoxRow name={"System Name"} value={data.system_name} />
              }
              {currentLRU.data.lru_name &&
                <InfoBoxRow name={"LRU Name"} value={currentLRU.data.lru_name} />
              }
              {data.ata &&
                <InfoBoxRow name={"ATA"} value={data.ata} />
              }
              {/* <InfoBoxRow name={"ATA (4 Digits)"} value={"---"} />
              <InfoBoxRow name={"ATA (6 Digits)"} value={"---"} /> */}
              {currentLRU.data.part_num &&
                <InfoBoxRow style={{ borderBottom: "0" }} name={"Part Number"} value={currentLRU.data.part_num} />
              }
            </>
          }
        </div>
      </div>
    )
  };

  return (
    <React.Fragment>
      {data &&
        <div className="diagnostics__container">
          {_.isEmpty(data) ?
            <div className="no-diagnostics-content">
              <span className="no-diagnostics-content__title">No Diagnostics!</span>
              {iseReport.event_id ?
                `Predictive Maintenance has not generated any diagnostics for ISE ${iseReport.event_id}`
                :
                "Predictive Maintenance has not generated any diagnostics for this ISE"
              }
            </div>
            :
            <>
              <div className="diagnostics__container__row">
                <div className="infoBox__container diagnostics__container__item large">
                  <div className="infoBox" ref={treeRef} id="zoom-tree-container">
                    <div className="infoBox__header">
                      Impacted Line Replacement Units
                    </div>
                    {treeDims.height && treeDims.width
                      && treePosition.top && treePosition.left
                      && data?.lrus_info?.length > 0
                      && treeData?.children?.length > 0 &&
                      <Zoom
                        currentLRU={currentLRU}
                        updateCurrentLRU={setCurrentLRU}
                        ata={data?.ata}
                        system_name={data?.system_name}
                        width={treeDims.width}
                        height={treeDims.height}
                        position={treePosition}
                        data={treeData}
                        minTreeWidth={treeData.minTreeWidth}
                      />
                    }
                  </div>
                </div>
                <div className="infoBox__container diagnostics__container__item small">
                  <div className="infoBox" ref={imageRef} id="zoom-img-container">
                    <div className="infoBox__header">
                      LRU Image
                    </div>
                    <div className={
                      // this need to be updated to query the ata 6 digits for each part
                      !currentLRU ? "infoBox__body-no-content" : ""}
                      style={currentLRU && {
                        width: "100%",
                        height: "calc(100% - 100px)"
                      }}
                    >
                      {!currentLRU
                        ? "Select an LRU  to see the image"
                        :
                        currentLRU.data?.image_url
                          ?
                          <>
                            {imgDims.height && imgDims.width
                              && lruImages && Object.keys(lruImages).length > 0
                              ?
                              (lruImages[currentLRU.data.lru_name]
                                ? <Zoom
                                  currentLRU={currentLRU}
                                  width={imgDims.width}
                                  height={imgDims.height}
                                  lruImage={lruImages[currentLRU.data.lru_name]}
                                  ata={data.ata}
                                />
                                : <div className="images__circularProgress__container">No images available</div>
                              )
                              :
                              <div className="images__circularProgress__container">
                                <CircularProgress style={{ color: "#979797" }} />
                              </div>
                            }
                          </>
                          : <div className="images__circularProgress__container">No images available</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="diagnostics__container__row">
                <div className="infoBox__container diagnostics__container__item large ">
                  <FMInfoBox />
                </div>
                <div className="infoBox__container diagnostics__container__item small">
                  <LRUInfoBox />
                </div>
              </div>
            </>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default Diagnostics;

