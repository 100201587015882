import React, { useEffect } from "react";
import "./saveTemplate.css";
import FeatherIcon from "feather-icons-react";
import { Dropdown, TemplateDropdown } from "../../Global Components/Dropdown";
import CustomButton from "../../Global Components/CustomButton.jsx";
import { getRolesPermissions } from "../../Auth Components/RbacValidation.js";
import moment from "moment";
import { saveTemplate } from "../../clients/TemplateClient.js";
import { Grid } from "@mui/material";
import {
  NotEmptyValidator,
  GlobalInput,
} from "../../Global Components/CustomInput";
import GlobalDropdown from "../../Global Components/Dropdown/GlobalDropdown";

export default function SaveTemplate(props) {
  // getting user permissions for setting save options
  const userPermissions = getRolesPermissions("ahmsview");

  // Getting date for displaying save date
  const today = new Date();
  const todayFormated = moment.utc(today).format("DD.MM.YY");

  let fault = props.faultCode;
  const data = [];

  if (props.templateType === "SAVE") {
    data.push({
      value: "PRIVATE",
      text: "Personal",
      icon: <FeatherIcon icon={"user"} height={18} />,
    });

    // Adds option to save template as internal public if user has right
    if (userPermissions.templatePublicPolicyInternal) {
      data.push({
        value: "INT_PUBLIC",
        text: "Internal - Shared to your organization only",
        icon: <FeatherIcon icon={"users"} height={18} />,
      });
    }

    // Adds option to save template as internal public if user has right
    if (userPermissions.templatePublicPolicyExternal) {
      data.push({
        value: "EXT_PUBLIC",
        text: "External - Shared to all organizations",
        icon: <FeatherIcon icon={"star"} height={18} />,
      });
    }

    // Setting default option for new template save options.
  } else {
    if (props.loadedTemplate.templatePrivacyPolicy === "PRIVATE") {
      data.push({
        value: "PRIVATE",
        text: "Personal",
        icon: <FeatherIcon icon={"user"} height={18} />,
      });
    } else if (props.loadedTemplate.templatePrivacyPolicy === "INT_PUBLIC") {
      data.push({
        value: "INT_PUBLIC",
        text: "Internal",
        icon: <FeatherIcon icon={"users"} height={18} />,
      });
    } else if (props.loadedTemplate.templatePrivacyPolicy === "EXT_PUBLIC") {
      data.push({
        value: "EXT_PUBLIC",
        text: "External",
        icon: <FeatherIcon icon={"star"} height={18} />,
      });
    }
  }

  // this is the default option for the template type dropdown
  const [selectedOption, setSelectedOption] = React.useState(
    data[0] ? data[0] : []
  );

  if (props.dataFocus === "FAULT") {
    fault = props.faultCode;
  } else if (props.dataFocus === "FLIGHT") {
    fault = "AHMS FULL FLIGHT";
  } else if (props.dataFocus === "CMS") {
    if (props.faultCode !== "N/A") {
      fault = props.faultCode;
    } else {
      fault = "CMS FULL SESSION";
    }
  }

  let newTemplateObject = {
    notes: "",
    templateName: "",
    faultCode: fault,
    omsFaultCodes:
      props.omsFaultCodes && props.omsFaultCodes.length > 0
        ? props.omsFaultCodes
        : null,
    templatePrivacyPolicy: "",
    aircraftFamily: props.aircraftFamily,
    aircraftModel: props.aircraftModel,
    ataChapter: props.ataChapterList[0],
    parameters: props.templateParameters,
    fromTemplateId: null,
    version: "#1 (New Template)",
  };

  let id = props?.loadedTemplate?.templateId;

  if (props?.loadedTemplate?.fromTemplateId) {
    id = props?.loadedTemplate?.fromTemplateId;
  }

  let existingTemplateObject = {
    notes: props?.loadedTemplate?.notes,
    templateName: props?.loadedTemplate?.templateName,
    faultCode: props?.loadedTemplate?.faultCode,
    omsFaultCodes: props?.loadedTemplate?.omsFaultCodes,
    templatePrivacyPolicy: props?.loadedTemplate?.templatePrivacyPolicy,
    aircraftFamily: props?.aircraftFamily,
    aircraftModel: props?.aircraftModel,
    ataChapter: props?.loadedTemplate?.ataChapter,
    parameters: props?.templateParameters,
    fromTemplateId: id,
    version: "New Version",
  };

  const [saveTemplateObject, setSaveTemplateObject] = React.useState(
    props.templateType === "UPDATE"
      ? {
        ...existingTemplateObject,
      }
      : { ...newTemplateObject }
  );

  const [isTemplateValid, setIsTemplateValid] = React.useState(false);

  useEffect(() => {
    if (
      saveTemplateObject.templateName &&
      saveTemplateObject.templateName !== "" &&
      saveTemplateObject.version &&
      saveTemplateObject.version !== "" &&
      saveTemplateObject.notes &&
      saveTemplateObject.notes !== "" &&
      selectedOption.value &&
      selectedOption.value !== "" &&
      saveTemplateObject.ataChapter &&
      saveTemplateObject.ataChapter !== ""
    ) {
      setIsTemplateValid(true);
    } else {
      setIsTemplateValid(false);
    }
  }, [saveTemplateObject]);

  const saveNewTemplate = () => {
    let tempObject = saveTemplateObject;
    tempObject.omsFaultCodes = [tempObject.omsFaultCodes];
    tempObject.templatePrivacyPolicy = selectedOption.value;

    saveTemplate(saveTemplateObject)
      .then((res) => {
        props.reloadTemplates();
        props.closeModal();
      })
      .catch(() => {
        props.closeModal();
      });
  };

  const templateNameOnChange = (event) => {
    saveTemplateObject.templateName = event.target.value;
    setSaveTemplateObject({ ...saveTemplateObject });
  };

  const templateDescriptionOnChange = (event) => {
    saveTemplateObject.notes = event.target.value;
    setSaveTemplateObject({ ...saveTemplateObject });
  };

  return (
    <div className="save__template_modal_content">
      <div className={"modal__save-new-template-header"}>
        {props.templateType === "UPDATE"
          ? "Update an existing template"
          : "Create a new template"}
      </div>
      <div className={"modal__save-new-template-body"}>
        <form autoComplete={"off"} style={{ height: "100%" }}>
          <div
            style={{
              width: "100%",
            }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={8}>
                <GlobalInput
                  id={"template-name"}
                  label={"Template Name*"}
                  placeholder={"Enter name here"}
                  validators={[NotEmptyValidator]}
                  defaultValue={saveTemplateObject.templateName}
                  errorMessage={"Template name is required."}
                  maxLength={80}
                  handleInputOnChange={templateNameOnChange}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalInput
                  id={"version-number"}
                  label={"Version Number*"}
                  placeholder={"Version Number"}
                  defaultValue={saveTemplateObject.version}
                  readonly={true}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  id={"description"}
                  label={"Description*"}
                  placeholder={"Add a description"}
                  validators={[NotEmptyValidator]}
                  errorMessage={"A description is required."}
                  defaultValue={saveTemplateObject.notes}
                  handleInputOnChange={templateDescriptionOnChange}
                />
              </Grid>
              <Grid item xs={4}>
                {props.templateType === "SAVE" ? (
                  <TemplateDropdown
                    options={data}
                    defaultValue={selectedOption}
                    customWidth={"100%"}
                    onChange={(d) => {
                      setSelectedOption(d);
                    }}
                    templateStyle={true}
                    hasIcons={true}
                    label={"Template Type*"}
                  />
                ) : (
                  <GlobalInput
                    id={"template-type"}
                    label={"Template Type*"}
                    placeholder={"Version Number"}
                    defaultValue={selectedOption.text}
                    readonly={true}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <GlobalInput
                  id={"date-created"}
                  label={"Date Created"}
                  placeholder={"01.01.22"}
                  defaultValue={todayFormated}
                  readonly={true}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalInput
                  id={"created-by"}
                  label={"Created By"}
                  placeholder={"Generic User"}
                  defaultValue={userPermissions.user}
                  readonly={true}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={props.ataChapterList}
                  defaultValue={saveTemplateObject.ataChapter}
                  onChange={(d) => {
                    saveTemplateObject.ataChapter = d.value;
                    setSaveTemplateObject({ ...saveTemplateObject });
                  }}
                  label={"ATA Chapter*"}
                  placeholder={"ATA Chapter"}
                  isSearchable={true}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalInput
                  id={"fde"}
                  label={"FDE"}
                  placeholder={"FDE"}
                  defaultValue={fault}
                  readonly={true}
                />
              </Grid>
              <Grid item xs={4}>
                {props.dataFocus !== "CMS" && (
                  <div
                    style={{
                      width: "100%",
                      color: "white",
                    }}
                  >
                    {props.omsFaultCodes && props.omsFaultCodes.length > 0 ? (
                      <Dropdown
                        options={props.omsFaultCodes}
                        defaultValue={
                          saveTemplateObject.omsFaultCodes
                            ? saveTemplateObject.omsFaultCodes[0]
                            : props.omsFaultCodes[0]
                        }
                        onChange={(d) => {
                          saveTemplateObject.omsFaultCodes = d.value;
                          setSaveTemplateObject({ ...saveTemplateObject });
                        }}
                        customWidth={"100%"}
                      />
                    ) : (
                      <GlobalInput
                        id={"related-oms"}
                        label={"Related OMS"}
                        placeholder={"Related OMS"}
                        defaultValue={"NO ASSOCIATED OMS"}
                        readonly={true}
                      />
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          minHeight: "80px",
          backgroundColor: "#303030",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          borderTop: "1px solid #9E9E9E",
          position: "relative",
          paddingTop: "26px",
        }}
      >
        <div style={{ position: "absolute", left: "20px" }}>
          <CustomButton
            id={"download-template"}
            text={"Download"}
            customWidth={"180px"}
            customHeight={"50"}
            colorStyle="primary"
            onClickAction={() => {
              let tempObject = saveTemplateObject;
              tempObject.omsFaultCodes = [tempObject.omsFaultCodes];
              props.saveTemplateLocal(tempObject);
            }}
          />
        </div>
        <div style={{ position: "absolute", right: "220px" }}>
          <CustomButton
            id={"cancel-template-save"}
            text={"Cancel"}
            customWidth={"180px"}
            customHeight={"50"}
            colorStyle="secondary"
            onClickAction={() => {
              props.closeModal();
            }}
          />
        </div>
        <div style={{ position: "absolute", right: "20px" }}>
          <CustomButton
            id={"save-new-template"}
            text={"Save"}
            customWidth={"180px"}
            customHeight={"50"}
            colorStyle="primary"
            onClickAction={() => {
              saveNewTemplate();
            }}
            isDisabled={!isTemplateValid}
          />
        </div>
      </div>
    </div>
  );
}
