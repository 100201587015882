import moment from "moment";
import { getUnit } from "../Helper";

export function getFullSizeLineChartOptions(faultTime, chartID) {
  return {
    tempSeries: [],
    chart: {
      id: chartID,
      redrawOnWindowResize: true,
      group: "full_chart",
      type: "line",
      background: "#101010",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        offsetX: 0,
        offsetY: -3,
        export: {
          csv: {
            dateFormatter(timestamp) {
              let newDate = moment
                .utc(timestamp)
                .format("MMM DD YYYY HH:mm:ss");
              return newDate;
            },
          },
        },
      },
    },
    colors: [
      "#07B9FC",
      "#FFB300",
      "#EA0034",
      "#9AF000",
      "#ec008c",
      "#0062FF",
      "#33FF00",
      "#FFFE37",
      "#FF6600",
      "#CC00FF",
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 3,
      dashArray: [0, 2, 0, 2, 0, 2, 0, 2, 0, 2],
    },
    annotations: !faultTime
      ? {}
      : {
          xaxis: [
            {
              x: new Date(faultTime).getTime(),
              strokeDashArray: 0,
              width: "4px",
              borderColor: "#FF5050",
              label: {
                borderColor: "#FF5050",
                style: {
                  color: "white",
                  background: "#FF5050",
                },
              },
            },
          ],
        },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 0,
    },
    title: {
      text: "",
      align: "left",
    },
    fill: {
      type: "solid",
      gradient: {
        shadeIntensity: 0.5,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        minWidth: 75,
        maxWidth: 75,
        formatter(val) {
          return Math.round((val + Number.EPSILON) * 100) / 100;
        },
        style: {
          colors: "white",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: true,
        color: "white",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        color: "white",
      },
      title: {
        text: "",
      },
    },
    xaxis: {
      type: "datetime",
      tickAmount: 8,
      position: "top",
      labels: {
        offsetX: 0,
        offsetY: -3,
        style: {
          colors: "white",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
        rotateAlways: false,
        formatter(val, timestamp) {
          return moment.utc(timestamp).format("HH:mm:ss.SSS");
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      shared: true,
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        let seriesItemHTML = "";
        let colorIndex = 0;

        series.forEach((parameter) => {
          //setting unit
          let unit = "n/a";
          if (w.config.series[colorIndex].unit) {
            unit = w.config.series[colorIndex].unit;
          } else if (w.config.tempSeries[colorIndex]) {
            unit = w.config.tempSeries[colorIndex].unit;
          }

          let name =
            w.config.series[colorIndex].englishName ||
            w.config.tempSeries[colorIndex].englishName;
          unit = getUnit(unit);
          seriesItemHTML =
            seriesItemHTML +
            ('<div style="width:100%; display:flex; align-items:center; margin: 2px;">' +
              '<div class="legend-pill" style="background-color: ' +
              w.config.colors[colorIndex] +
              '; margin-right: 5px;">' +
              "</div>" +
              '<span style="color:black;">' +
              name +
              ":" +
              "</span>" +
              '<span style="color: black; font-weight: 800; margin-left: 5px;">' +
              parameter[dataPointIndex] +
              " (" +
              unit +
              ")</span></div>");
          colorIndex += 1;
        });
        return (
          '<div style="width:auto; padding: 8px;">' + seriesItemHTML + "</div>"
        );
      },
    },
  };
}
