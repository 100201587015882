import React from "react";
import "./SystemNotificationContent.css";

const SystemNotificationContent = ({
  originalContentString,
  notificationId,
}) => {
  return (
    <span>
      {originalContentString.split("<line_break>").map((paragraph) => (
        <React.Fragment
          key={
            "system-notif-content-" +
            (notificationId ? notificationId : "id") +
            "-" +
            Math.random()
          }
        >
          <span>
            {paragraph.split("<link>").map((text) => (
              <React.Fragment key={"system-notif-text-" + Math.random()}>
                {text.includes("<link_break>") ? (
                  <a
                    className="systemNotificationContent__link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={text.split("<link_break>")[0]}
                  >
                    {text.split("<link_break>")[1]}
                  </a>
                ) : (
                  text
                )}
              </React.Fragment>
            ))}
            <br />
          </span>
        </React.Fragment>
      ))}
    </span>
  );
};

export default SystemNotificationContent;
