import { useEffect, useMemo, useState } from 'react';
import _ from "lodash";
import moment from "moment";
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {
    IconButton,
    Tooltip
} from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQuery } from '@tanstack/react-query';
import {
    getUserNotificationsPaginated,
    updateUserNotifications,
    getAnnouncementsPaginated
} from "../clients/NotificationsClient";
import AircraftIcon from "../resources/aircraft-solid.svg";
import CustomCheckbox from "../Global Components/CustomCheckbox.jsx";
import "./NotificationsTable.css";
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';

const NotificationsTable = (props) => {
    const {
        filters,
        selectedFilterTab,
        updateUnreadNotificationsCount
    } = props;

    const [columnVisibility, setColumnVisibility] = useState({
        notification_type: true,
        id: true,
        notification_status: true
    })

    //manage our own state for stuff we want to pass to the API
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });

    // used to validate whether the selected tab is the announcement tab
    const ANNOUNCEMENT = "ANNOUNCEMENT";

    const {
        data: {
            data = [],
            meta_data
        } = {},
        isError,
        isFetching,
        isRefetching,
        isLoading,
        refetch
    } = useQuery({
        queryKey: [
            'table-data',
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
            selectedFilterTab
        ],
        queryFn: async () => {
            let newFilters = {};
            // Sorting by default by event_timestamp/scheduled_at DESC
            if (selectedFilterTab === ANNOUNCEMENT) {
                newFilters["sort_by"] = "scheduled_at";
            } else {
                newFilters["sort_by"] = "event_timestamp";
            }
            newFilters["sort_order"] = "desc";
            if (selectedFilterTab === ANNOUNCEMENT) {
                const today = new Date();
                let threeMonthsAgo = new Date();
                threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

                const announcementSortingOptions = ["scheduled_at", "title"];
                
                if (sorting.length > 0) {
                    sorting.forEach((sort) => {
                        if (announcementSortingOptions.includes(sort.id)) {
                            newFilters["sort_by"] = sort.id;
                            newFilters["sort_order"] = sort.desc ? "desc" : "asc"
                        }
                    })
                }
                
                let response = await getAnnouncementsPaginated(
                    pagination.pageIndex + 1,
                    pagination.pageSize,
                    {
                        ...newFilters,
                        status: "PUBLISHED",
                        from_datetime: moment.utc(threeMonthsAgo).format("YYYY-MM-DD HH:mm:ss"),
                        to_datetime: moment.utc(today).format("YYYY-MM-DD HH:mm:ss")
                    }
                );
                if (response.data) {
                    return response.data;
                }
            } else {
                let unreadCount = 0;
                const sortingOptions = ["event_timestamp", "tail_number", "notification_type"];

                if (sorting.length > 0) {
                    sorting.forEach((sort) => {
                        if (sortingOptions.includes(sort.id)) {
                            newFilters["sort_by"] = sort.id;
                            newFilters["sort_order"] = sort.desc ? "desc" : "asc"
                        }
                    })
                }

                for (let filterKey in filters) {
                    if (filters[filterKey]) {
                        newFilters[filterKey] = filters[filterKey]
                    }
                }

                let response = await getUserNotificationsPaginated(
                    pagination.pageIndex + 1,
                    pagination.pageSize,
                    newFilters
                )

                if (response.data) {
                    let unreadCountResponse = await getUserNotificationsPaginated(
                        1,
                        1,
                        { notificationStatus: 'DISPATCHED_UNREAD' }
                    )
                    if (unreadCountResponse.data) {
                        unreadCount = unreadCountResponse.data.meta_data.pagination.total_count;
                    }

                    updateUnreadNotificationsCount(unreadCount);
                    return response.data;
                }
            }
            return {}
        }
    });

    // Custom theme for the table.
    const tableTheme = useMemo(() =>
        createTheme({
            palette: {
                mode: 'dark',
            },
            typography: {
                allVariants: {
                    color: "#FFF",
                    fontFamily: "'DM Sans', sans-serif"
                }
            },
        })
    );

    // Handles the read and unread checkbox action
    const handleReadCheckBoxToggle = async (notificationId, newStatus) => {
        await updateUserNotifications(notificationId, newStatus, null)
            .then(() => {
                refetch();
            })
            .catch((error) => {
                console.error("ERROR: ", error);
            });
    };

    // Return the Icon section content acording to the notification type
    const displayIconContent = (eventType) => {
        if (eventType === "SYSTEM_ANNOUNCE") {
            return (
                <div className="notificationRow__iconContent">
                    <FeatherIcon
                        icon={"alert-circle"}
                        className="notificationRow__icon"
                    />
                </div>
            );
        } else {
            return (
                <div className="notificationRow__iconContent">
                    <img
                        className="notificationRow__icon"
                        src={AircraftIcon}
                        alt="aircraft"
                    ></img>
                </div>
            );
        }
    };

    // Returns the tail info section content according to the notification type
    const displayTailInfo = (displayId, tailNumber, aircraftFamily, aircraftModel) => {
        return (
            <div className="notificationRow__tailInfo">
                <div style={{ fontSize: "16px", fontWeight: "700" }}>
                    {displayId}
                </div>
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    {tailNumber}
                </div>
                <div style={{ fontSize: "14px", fontWeight: "400" }}>
                    {`${aircraftFamily} ${aircraftModel}`}
                </div>
            </div>
        );
    };

    // Returns the announcement info section content
    const displayAnnouncementInfo = (title, content) => {
        return (
            <div className="notificationRow__announcementInfo">
                <div style={{ fontSize: "16px", fontWeight: "700" }}>
                    {title}
                </div>
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    {content}
                </div> 
            </div>
        );
    };

    // Returns the color of the severity dot for a notification according to the notification type
    const getDotColor = (eType) => {
        let bgColor = "#000000";
        switch (eType) {
            case "EVENT_WARNING":
                bgColor = "#ff5050";
                break;
            case "EVENT_CAUTION":
                bgColor = "#ffc72c";
                break;
            case "EVENT_ADVISORY":
                bgColor = "#709dff";
                break;
            default:
                bgColor = "#000000";
                break;
        }

        return bgColor;
    };

    // Returns the notification severity section content according to the notification type
    const displayNotificationTypeDot = (eventType) => {
        if (eventType === "SYSTEM_ANNOUNCE") {
            return (
                <div className="notificationRow__notificationTypeSection-noType"></div>
            );
        } else {
            return (
                <div className="notificationRow__notificationTypeSection">
                    <div
                        className="notificationRow__notificationStatusDot"
                        style={{
                            margin: "auto",
                            border: "1px solid white",
                            backgroundColor: getDotColor(eventType),
                        }}
                    ></div>
                </div>
            );
        }
    };

    // Returns the title content according to the notification type
    const displayTitle = (eType, description, eventName) => {
        if (
            eType === "EVENT_ADVISORY" ||
            eType === "EVENT_WARNING" ||
            eType === "EVENT_CAUTION"
        ) {
            return eventName
                ? eventName
                : "FDE/CAS EVENT NOTIFICATION";
        } else if (eType === "EVENT_LANDING") {
            return "LANDING";
        } else if (eType === "EVENT_TAKEOFF") {
            return "TAKE-OFF";
        } else if (eType === "SYSTEM_ANNOUNCE") {
            return description?.noticeTitle
                ? description.noticeTitle
                : "SYSTEM ANNOUNCEMENT";
        }

        return "-";
    };

    // Returns the description content according to the notification type
    const displayDescription = (eType, description, notificationMessage) => {
        if (!notificationMessage) {
            return "";
        }

        if (
            eType === "EVENT_ADVISORY" ||
            eType === "EVENT_WARNING" ||
            eType === "EVENT_CAUTION"
        ) {
            return (
                <div>FDE {notificationMessage.event_code ? notificationMessage.event_code : "-"}</div>
            );
        } else if (eType === "EVENT_LANDING") {
            if (notificationMessage.arrival_airport_name) {
                return (
                    <div>
                        <span>Arrival: </span>
                        <span style={{ fontWeight: "700" }}>{notificationMessage.arrival_airport_name}</span>
                    </div>
                );
            } else {
                return "";
            }
        } else if (eType === "EVENT_TAKEOFF") {
            if (notificationMessage.departure_airport_name) {
                return (
                    <div>
                        <span>Departure: </span>
                        <span style={{ fontWeight: "700" }}>{notificationMessage.departure_airport_name}</span>
                    </div>
                );
            } else {
                return "";
            }
        } else if (eType === "SYSTEM_ANNOUNCE") {
            return (
                <div style={{ display: "flex", flexFlow: "column" }}>
                    {description.noticeSubtitle && (
                        <span>{description.noticeSubtitle}</span>
                    )}
                    {description.noticeContent && (
                        <SystemNotificationContent
                            originalContentString={description.noticeContent}
                        />
                    )}
                </div>
            );
        }

        return "";
    };

    // Returns the formatted date
    const getTimestamp = (timestamp) => {
        return timestamp
            ? moment.utc(timestamp).format("MMM DD, YYYY HH:mm:ss")
            : "-";
    };

    // Returns the additional content according to the notification type
    const displayAdditionalDescription = (eType) => {
        if (
            eType === "EVENT_ADVISORY" ||
            eType === "EVENT_WARNING" ||
            eType === "EVENT_CAUTION"
        ) {
            return (
                <div className="notificationRow__additionalDetails notificationRow__font__medium">
                    FDE/CAS
                </div>
            );
        }

        return <div className="notificationRow__additionalDetails-noDetails"></div>;
    };

    // Columns for Notifications table
    const columns = useMemo(() => [
        {
            header: selectedFilterTab === ANNOUNCEMENT ? "Announcement Message" : "Tail",
            accessorKey: selectedFilterTab === ANNOUNCEMENT ? "title" : "tail_number",
            enableSorting: true,
            size: selectedFilterTab === ANNOUNCEMENT ? 
                document.getElementById('notifications-table').offsetWidth - 300 : "",
            Cell: ({ cell }) => {
                if (selectedFilterTab === ANNOUNCEMENT) {
                    const announcementTitle = cell.row?.original?.message.title;
                    const announcementContent = cell.row?.original?.message.content;
                    return <div style={{ display: 'flex', alignItems: 'center' }}>
                        {displayIconContent("SYSTEM_ANNOUNCE")}
                        {displayAnnouncementInfo(announcementTitle, announcementContent)}
                    </div>
                }
                const eType = cell.row?.original?.notification_type;
                const tailNumber = cell.row?.original?.notification_message.tail_number;
                const displayId = cell.row?.original?.notification_message.display_id;
                const aircraftFamily = cell.row?.original?.notification_message.aircraft_family;
                const aircraftModel = cell.row?.original?.notification_message.aircraft_model;

                return <div style={{ display: 'flex', alignItems: 'center' }}>
                    {displayIconContent(eType)}
                    {displayTailInfo(displayId, tailNumber, aircraftFamily, aircraftModel)}
                </div>
            },
        },
        {
            header: "Event Type",
            accessorKey: "notification_type",
            Cell: ({ cell }) => {
                const eType = cell.row?.original?.notification_type;
                const description = cell.row?.original?.notification_message.fault_description;
                const notificationMessage = cell.row?.original?.notification_message;
                const eventName = cell.row?.original?.notification_message.event_name;

                return <div style={{ display: 'flex', alignItems: 'center' }}>
                    {displayNotificationTypeDot(eType)}
                    <div className="notificationRow_eventDescription">
                        <div className="notificationRow__font__title">
                            {displayTitle(eType, description, eventName)}
                        </div>
                        <div
                            className="notificationRow__font__content"
                            style={{ marginTop: "4px" }}
                        >
                            {displayDescription(eType, description, notificationMessage)}
                        </div>
                    </div>
                </div>
            },
        },
        {
            header: "Data Source",
            accessorKey: "id",
            enableSorting: false,
            Cell: ({ cell }) => {
                const eType = cell.row?.original?.notification_type;

                return <div style={{ display: 'flex', alignItems: 'center' }}>
                    {displayAdditionalDescription(eType)}
                </div>;
            },
        },
        {
            header: "Time stamp",
            accessorKey: selectedFilterTab === ANNOUNCEMENT ? "scheduled_at" : "event_timestamp",
            Cell: ({ cell }) => {
                let timestamp = "";
                if (selectedFilterTab === ANNOUNCEMENT) {
                    timestamp = cell.row?.original?.scheduled_at;
                } else {
                    timestamp = cell.row?.original?.notification_message.event_timestamp;
                }
                return <div style={{ display: 'flex', alignItems: 'center' }}>
                    {getTimestamp(timestamp)}
                </div>
            }
        }, {
            header: "Read / Unread",
            accessorKey: "notification_status",
            enableSorting: false,
            Cell: ({ cell }) => {
                const status = cell.row?.original?.notification_status;
                const notificationId = cell.row?.original?.id;

                return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CustomCheckbox
                        isChecked={status === "DISPATCHED_READ"}
                        onChangeAction={() => {
                            handleReadCheckBoxToggle(
                                notificationId,
                                status === "DISPATCHED_UNREAD" ? "DISPATCHED_READ" : "DISPATCHED_UNREAD"
                            );
                        }}
                    />
                </div>
            }
        }
    ]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: {
            showColumnFilters: true,
        },
        enableHiding: false,
        enableStickyFooter: true,
        enableStickyHeader: true,
        muiTableContainerProps: { 
            sx: { maxHeight: "calc(100vh - 500px)" } 
        },
        localization: {
            noRecordsToDisplay: selectedFilterTab === ANNOUNCEMENT ? "You have no new announcements at this time." : "You have no new notifications at this time."
        },
        manualPagination: true, //turn off built-in client-side pagination
        manualSorting: true, //turn off built-in client-side sorting
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        enableFilters: false,
        enableFullScreenToggle: false,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        renderTopToolbarCustomActions: () => (
            <Tooltip arrow title="Refresh Data">
                <IconButton onClick={() => refetch()}>
                    <RefreshIcon />
                </IconButton>
            </Tooltip>
        ),
        rowCount: meta_data?.pagination?.total_count ?? 0,
        state: {
            columnVisibility,
            isLoading: isLoading || isRefetching || isFetching,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
        onColumnVisibilityChange: setColumnVisibility,
        muiTableProps: {
            sx: {
                borderSpacing: '0 10px',
            }
        },
        muiTablePaperProps: {
            sx: {
                boxShadow: 'none',
                overflowY: 'auto !important'
            }
        },
        muiTableHeadRowProps: {
            sx: {
                '& th': {
                    fontSize: 16,
                    border: 'none',
                    backgroundColor: "#1f1e1e"
                }
            }
        },
        muiTableBodyRowProps: {
            sx: {
                '&:hover': {
                    '& td': {
                        borderTop: '1px solid #ffff',
                        borderBottom: '1px solid #ffff',
                    },
                    '& td:first-of-type': {
                        borderLeft: '1px solid #ffff',
                    },
                    '& td:last-child': {
                        borderRight: '1px solid #ffff',
                    }
                },
                '& td': {
                    backgroundColor: 'rgb(48, 48, 48) !important',
                    fontSize: 16,
                    borderTop: '1px solid transparent',
                    borderBottom: '1px solid transparent',
                    padding: '0.5rem 1rem'
                },
                '& td:hover': {
                    borderTop: '1px solid #ffff',
                    borderBottom: '1px solid #ffff',
                },
                '& td:first-of-type': {
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderLeft: '1px solid transparent',
                },
                '& td:last-child': {
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    borderRight: '1px solid transparent'
                }
            },
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: 'rgba(0,0,0,0)',
        }),
    });

    useEffect(() => {
        refetch();
    }, [filters]);

    useEffect(() => {
        setColumnVisibility({
            notification_type: selectedFilterTab === ANNOUNCEMENT ? false : true,
            id: selectedFilterTab === ANNOUNCEMENT ? false : true,
            notification_status: selectedFilterTab === ANNOUNCEMENT ? false : true,
        });
    }, [selectedFilterTab]);

    return <div id='notifications-table'>
        <ThemeProvider theme={tableTheme}>
            <MaterialReactTable table={table} />
        </ThemeProvider>
    </div>
}

export default NotificationsTable;