import React, { useEffect, useState } from "react";
import "./NotificationsView.css";
import "../styles/Global Components/NotificationPopup.css";
import { Dropdown } from "../Global Components/Dropdown.jsx";
import Banner from "../Global Components/Banner";
import ConfigFleetTailsBox from "../Configuration View Components/ConfigFleetTailsBox";
import * as tools from "../utils/CommonTools";
import { getUserAircraftSubscriptions } from "../clients/NotificationsClient";
import NotificationsTable from "./NotificationsTable.jsx";
import * as featureToggleTools from "../Feature Toggles/FeatureToggleTools";

/**
 * The notifications view component.
 * @returns {Element}
 * @constructor
 */
function NotificationsView() {

    const [familyModelFilter, setFamilyModelFilter] = useState(
        tools.notificationEnabledAircraftFamilyModels()[0]
    );

    /* State Variables */
    const [selectedFilterTab, setSelectedFilterTab] = useState("ALL");
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [selectedTail, setSelectedTail] = useState();
    const [selectedEventType, setSelectedEventType] = useState("")
    /* Filter Values */
    const faultSeverityFilterOptions = ["Unread", "Read", "All"];
    const [eventOptions, setEventOptions] = useState(["Advisory", "Caution", "Warning", "Takeoff", "Landing"]);
    const rpdOptions = ["Request", "Response"];
    const [faultSeverityFilter, setFaultSeverityFilter] = useState("");
    const [tailList, setTailList] = useState([]);
    const [familyModelList, setFamilyModelList] = useState([]);

    const upperTabs = 
        featureToggleTools.isFeatureToggleActive("ft-rpd-notifications")
        ? [
            {
                displayName: "All",
                filterValue: "ALL"
            },
            {
                displayName: "Event",
                filterValue: "EVENT"
            },
            {
                displayName: "RPD",
                filterValue: "RPD"
            },
            {
                displayName: "Announcement",
                filterValue: "ANNOUNCEMENT"
            }
        ]
        : [
            {
                displayName: "All",
                filterValue: "ALL"
            },
            {
                displayName: "Event",
                filterValue: "EVENT"
            },
            {
                displayName: "Announcement",
                filterValue: "ANNOUNCEMENT"
            }
        ];

    useEffect(() => {
        getUserAircraftSubscriptions("False")
            .then((response) => {
                if (response.data) {
                    let allTails = [{
                        aircraftFamily: "",
                        aircraftModel: "",
                        displayId: "All Tails",
                        registrationNumber: ""
                    }];
                    let uniqueTails = [
                        ...new Map(
                        response.data
                            .filter(Boolean)
                            .map((aircraft) => [aircraft.registrationNumber, aircraft]),
                        ).values(),
                    ];
                    uniqueTails = allTails.concat(uniqueTails);
                    setTailList(uniqueTails);
                }
            })
            .catch((err) => {
                console.error("ERROR: ", err);
            });
        
        let newNotificationTypes = [];
        // check if Info notifications are active
        if (featureToggleTools.isFeatureToggleActive("ft-info-notifications")) {
            newNotificationTypes.push("Info");
        }
        // check if Status notifications are active
        if (featureToggleTools.isFeatureToggleActive("ft-status-notifications")) {
            newNotificationTypes.push("Status");
        }
        setEventOptions([...eventOptions, ...newNotificationTypes])
    }, []);

    useEffect(() => {
        const uniqueFamilyModelList = 
            tools.notificationEnabledAircraftFamilyModels().filter(
                (aircraft) =>
                    tailList.find(
                        (tail) =>
                            aircraft.aircraftModel ===
                            tail.aircraftModel
                            ||
                            aircraft.displayId === "All Tails"
                    )
            );
        setFamilyModelList(uniqueFamilyModelList);
    }, [tailList]);

    const evaluateTail = () => {
        if (selectedTail && selectedTail?.registrationNumber !== "") {
            return selectedTail.registrationNumber;
        }
        return null;
    };

    const evaluateAircraftFamilyModel = () => {
        if (familyModelFilter.aircraftFamily.toLocaleLowerCase() !== "all") {
            return `${familyModelFilter.aircraftFamily}${familyModelFilter.aircraftModel}`;
        }
        return null;
    };

    const evaulateNotificationStatus = () => {
        switch (faultSeverityFilter.toLocaleLowerCase()) {
            case 'read':
                return 'DISPATCHED_READ';
            case 'all':
                return null;
            default:
                return 'DISPATCHED_UNREAD';
        }
    };

    const evaulateNotificationType = () => {
        switch (selectedFilterTab.toLocaleLowerCase()) {
            case 'announcement':
                return 'SYSTEM_ANNOUNCE';
            case 'rpd':
                switch (selectedEventType.toLocaleLowerCase()) {
                    case "request":
                        return "RPD_REQUEST";
                    default:
                        return "RPD_RESPONSE";
                }
            case 'event':
                switch (selectedEventType.toLocaleLowerCase()) {
                    case "caution":
                        return "EVENT_CAUTION";
                    case "warning":
                        return "EVENT_WARNING";
                    case "landing":
                        return "EVENT_LANDING";
                    case "takeoff":
                        return "EVENT_TAKEOFF";
                    case "info":
                        return "EVENT_INFO";
                    case "status":
                        return "EVENT_STATUS";
                    default:
                        return "EVENT_ADVISORY";
                }
            default:
                return null;
        }
    };

    /**
     * Handles the tab selection to show different status.
     * @param selectedTab The selected tab item.
     */
    const handleMessageStatusFilterClick = (selectedTab) => {
        setSelectedFilterTab(selectedTab);
    };

    const updateUnreadNotificationsCount = (count) => {
        setUnreadNotifications(count);
    }

    /**
     * Handles the filter by event type.
     * @param eventType The filter value.
     */
    const handleEventFilterChange = (eventFilterValue) => {
        setSelectedEventType(eventFilterValue.value);
    };

    /**
     * Handles the filter by severity.
     * @param faultFilterValue The severity filter value.
     */
    const handleFaultSeverityFilterChange = (faultFilterValue) => {
        setFaultSeverityFilter(faultFilterValue.value);
    };

    const tailSelectEvent = (tail) => {
        let newTail = tailList.find(
            (listedTail) => listedTail.registrationNumber == tail
        );

        if (!tailIsEqual(newTail, selectedTail)) {
            setSelectedTail(newTail);
        }
    }

    const tailIsEqual = (object1, object2) => {
        return (
            object1.aircraftFamily === object2?.aircraftFamily &&
            object1.registrationNumber.toLocaleLowerCase() === object2?.registrationNumber.toLocaleLowerCase()
        );
    };

    const familyModelIsEqual = (object1, object2) => {
        return (
            object1.aircraftFamily === object2.aircraftFamily &&
            object1.aircraftModel === object2.aircraftModel
        );
    };

    const handleFamilyModelSelectEvent = (familyModel) => {
        const tempValues = familyModel.value.split("-");
        const tempAircraftFamily = tempValues[0];
        const tempAircraftModel = tempValues[1];

        let newFamilyModel = familyModelList.find(
            (familyModelItem) => 
                familyModelItem.aircraftFamily === tempAircraftFamily &&
                familyModelItem.aircraftModel === tempAircraftModel
        );

        if (!familyModelIsEqual(newFamilyModel, familyModelFilter)) {
            setFamilyModelFilter(newFamilyModel);
        }
    };


    return <div>
        <div className="notificationsView">
            <div className="notificationsView__content">
                <Banner
                    pageType={"Notifications"}
                    icon={"bell"}
                    logoText={"BOMBARDIER"}
                />
                <div className="notificationsView__contentBody">
                    <div className="notificationsView__topFilters">
                        <div className="notificationsView__filter__tabContainer">
                            {upperTabs.map((tab) => (
                                <React.Fragment key={tab.filterValue}>
                                    <button
                                        className={
                                            selectedFilterTab === tab.filterValue
                                                ? "notificationsView__filter__tabPanel active"
                                                : "notificationsView__filter__tabPanel"
                                        }
                                        onClick={() => {
                                            handleMessageStatusFilterClick(tab.filterValue);
                                        }}
                                    >
                                        <span>
                                            {`
                                                ${tab.displayName}
                                                ${tab.displayName === "All" ? `(${unreadNotifications})` : ""}
                                            `}
                                        </span>
                                    </button>
                                </React.Fragment>
                            ))}
                        </div>
                        {familyModelList.length > 0 &&
                            selectedFilterTab.toLocaleLowerCase() !== "announcement" &&
                            <ConfigFleetTailsBox
                                subjectEnableType={"FAMILY"}
                                familyModelList={familyModelList}
                                selectedFamilyModel={familyModelFilter}
                                familyModelSelectEvent={handleFamilyModelSelectEvent}
                            />
                        }
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            paddingTop: "40px",
                            paddingBottom: "20px",
                            zIndex: 3
                        }}
                    >
                        { selectedFilterTab.toLocaleLowerCase() !== "announcement" &&
                            <React.Fragment>
                                <div>
                                    <ConfigFleetTailsBox
                                        placeholder={"Filter by Tail"}
                                        subjectEnableType={"TAIL"}
                                        tailList={tailList}
                                        tailSelectEvent={tailSelectEvent}
                                        selectedTail={selectedTail}
                                    />
                                </div>
                                <div style={{ width: "5%" }}></div>
                                {selectedFilterTab.toLocaleLowerCase() === "event" &&
                                    <React.Fragment>
                                        <div >
                                            <Dropdown
                                                customWidth={"200px"}
                                                options={eventOptions}
                                                defaultValue={eventOptions[0]}
                                                onChange={handleEventFilterChange}
                                            />
                                        </div>
                                        <div style={{ width: "5%" }}></div>
                                    </React.Fragment>
                                }
                                {selectedFilterTab.toLocaleLowerCase() === "rpd" &&
                                    <React.Fragment>
                                        <div >
                                            <Dropdown
                                                customWidth={"200px"}
                                                options={rpdOptions}
                                                defaultValue={rpdOptions[0]}
                                                onChange={handleEventFilterChange}
                                            />
                                        </div>
                                        <div style={{ width: "5%" }}></div>
                                    </React.Fragment>
                                }
                                <div >
                                    <Dropdown
                                        customWidth={"200px"}
                                        options={faultSeverityFilterOptions}
                                        defaultValue={faultSeverityFilterOptions[0]}
                                        onChange={handleFaultSeverityFilterChange}
                                    />
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <NotificationsTable
                        filters={{
                            aircraftRegistrationNumber: evaluateTail(),
                            aircraftFamilyModel: evaluateAircraftFamilyModel(),
                            notificationStatus: evaulateNotificationStatus(),
                            notificationType: evaulateNotificationType()
                        }}
                        selectedFilterTab={selectedFilterTab}
                        updateUnreadNotificationsCount={updateUnreadNotificationsCount}
                    />
                </div>
            </div>
        </div>
    </div>;
}

export default NotificationsView;
