import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import "./FdeCasRow.css";
import "./FdeCasList.css";
import * as tools from "../utils/CommonTools";
import CustomTooltip from "../Global Components/CustomTooltip";

export default class FdeCasRow extends Component {
  constructor(props) {
    super(props);
    this.calculateIndicator = this.calculateIndicator.bind(this);
    this.calculateFaultSeverity = this.calculateFaultSeverity.bind(this);
    this.handleCorrelationClick = this.handleCorrelationClick.bind(this);
    this.hasClearedSection = this.hasClearedSection.bind(this);
    this.handleFaultCodeColumn = this.handleFaultCodeColumn.bind(this);
    this.hasEventColumn = this.hasEventColumn.bind(this);
  }

  hasClearedSection() {
    if (!this.props.aircraftUIConfig.hideClearedFaults) {
      return (
        <div className="fde-cas-cleared-section">
          {this.props.faultCleared ? (
            <CustomTooltip
              tooltipType={"general"}
              content={
                this.props.clearedTimestamp ?
                  <span>
                    Cleared 
                      <p>
                        {tools.formatDate(this.props.clearedTimestamp)}
                      </p>
                  </span>
                : "Cleared message"
              }
              placement={"top"}
            >
              <div>
                <FeatherIcon
                    icon={"check"}
                    height={20}
                    className={"fde-cas-cleared-section-icon"}
                />
              </div>
            </CustomTooltip>
          ) : (
            <div style={{ width: "10px" }}></div>
          )}
        </div>
      );
    } else {
      return <div style={{ width: "4%", height: "100%" }}></div>;
    }
  }

  hasEventColumn() {
    if (
      this.props.aircraftUIConfig.hasInFlightDataCard ||
      this.props.viewType !== "IFR"
    ) {
      return (
        <div className="fde-cas-event-section">
          {this.props.hasInFlightParameterData &&
          this.props.faultSeverity !== "S" ? (
            <CustomTooltip
              tooltipType={"general"}
              content={"You can chart event parameters for this code."}
              placement={"top"}
            >
              <div>
                <FeatherIcon icon={"activity"} height={20} />
              </div>
            </CustomTooltip>
          ) : (
            <div style={{ width: "10px" }}></div>
          )}
        </div>
      );
    }
  }

  hasEventColumnHeader() {
    if (
      this.props.aircraftUIConfig.hasInFlightDataCard ||
      this.props.viewType !== "IFR"
    ) {
      return <div style={{ width: "10%" }}></div>;
    }
  }

  hasOmsMdcMessages() {
    if (this.props.aircraftUIConfig.hasOmsMdcMessages) {
      return (
        <div
          className={
            "fde-cas-oms-section fde-cas-font" +
            (this.props.omsRelatedCount > 0 ? " highlight" : "")
          }
        >
          {this.props.omsRelatedCount > 0 ? this.props.omsRelatedCount : "-"}
        </div>
      );
    } else {
      return <div style={{ width: "30px", height: "100%" }}></div>;
    }
  }

  handleFaultCodeColumn() {
    if (this.props.aircraftUIConfig.hasFaultTableFaultCode) {
      return (
        <div
          className={
            "fde-cas-code-section fde-cas-bold-link-font fde-cas-bold-link-font-" +
            this.calculateFaultSeverity(this.props.faultSeverity)
          }
        >
          {this.props.faultCode}
        </div>
      );
    }
  }

  render() {
    if (this.props.faultSeverity === "F" || this.props.faultSeverity === "Z") {
      return (
        <div
          id={this.props.id}
          className={
            (this.props.isFlightRow ? "fde-cas-row-black" : "fde-cas-row") +
            (this.props.rowIsSubtle ? " subtle-row" : "") +
            (!this.props.rowIsFiltered ? " invisible-row" : "")
          }
          onClick={() => {
            if (this.props.faultSeverity === "Z") {
              this.props.changeFlightSelection({
                label: this.props.faultCode.replace(/-/g, " "),
                value: null,
              });
            } else {
              this.handleCorrelationClick();
            }
          }}
        >
          {this.calculateIndicator(this.props.faultSeverity)}
          {this.handleFaultCodeColumn()}
          {this.hasClearedSection()}
          {this.hasEventColumnHeader()}
          <div className="fde-cas-message-section fde-cas-font">
            {this.props.faultCode}
          </div>
          <div
            className={
              "fde-cas-oms-section fde-cas-font" +
              (this.props.omsRelatedCount > 0 ? " highlight" : "")
            }
          ></div>
          <div className="fde-cas-timestamp-section fde-cas-font-white">
            {tools.formatDate(this.props.faultTimestamp)}
          </div>
        </div>
      );
    } else if (
      this.props.faultMessage !== "LANDING" &&
      this.props.faultMessage !== "TAKE OFF"
    ) {
      return (
        <div
          ref={this.props.faultMessageBoxRef}
          id={this.props.id}
          className={
            (this.props.isFlightRow ? "fde-cas-row-black" : "fde-cas-row") +
            (this.props.rowIsSubtle ? " subtle-row" : "") +
            (!this.props.rowIsFiltered ? " invisible-row" : "")
          }
          onClick={this.handleCorrelationClick}
        >
          {this.calculateIndicator(this.props.faultSeverity)}
          {this.handleFaultCodeColumn()}
          {this.hasClearedSection()}
          {this.hasEventColumn()}
          <div className="fde-cas-message-section fde-cas-font">
            {this.props.faultMessage}
          </div>
          {this.hasOmsMdcMessages()}
          <div className="fde-cas-timestamp-section fde-cas-font">
            {tools.formatDate(this.props.faultTimestamp)}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  handleCorrelationClick() {
    this.props.faultCorrelationClickEvent(
      this.props.faultCode,
      this.props.faultTimestamp,
      this.props.faultSeverity,
      this.props.id
    );
  }

  calculateIndicator(severity) {
    let color;
    if (severity === "A") {
      color = <div className="indicator blue-rect" />;
    } else if (severity === "C") {
      color = <div className="indicator ambar-rect" />;
    } else if (severity === "W") {
      color = <div className="indicator red-rect" />;
    } else if (severity === "S") {
      color = <div className="indicator white-rect" />;
    } else if (severity === "F") {
      color = <div className="indicator black-rect" />;
    } else if (severity === "Z") {
      color = <div className="indicator full-black-rect" />;
    } else {
      color = <div className="indicator gray-rect" />;
    }
    return color;
  }

  calculateFaultSeverity(severity) {
    let color;
    if (severity === "A") {
      color = "advisory";
    } else if (severity === "C") {
      color = "caution";
    } else if (severity === "W") {
      color = "warning";
    } else if (severity === "S") {
      color = "status";
    } else {
      color = "info";
    }
    return color;
  }
}
