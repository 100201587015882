import AxiosService from "./AxiosService";
import * as Constants from "../Constants/Constants";

const BOMBARDIER_API_URL = Constants.BOMBARDIER_API_URL();
const CHART_TEMPLATE = "/v10/charts-template";
const ChartsTemplateList = "/v10/charts-template-list";

export async function getChartsTemplate(templateId, axiosCancelSource) {
  let result;
  const data = {
    params: {
      templateId: templateId,
    },
  };

  try {
    result = await AxiosService(
      BOMBARDIER_API_URL + CHART_TEMPLATE,
      "GET",
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

export async function getChartsTemplateList(
  aircraftModel,
  aircraftFamily,
  skipParameters = false,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      skipParameters: skipParameters,
    },
  };

  try {
    result = await AxiosService(
      BOMBARDIER_API_URL + ChartsTemplateList,
      "GET",
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}
