import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import FleetRow from "./FleetRow";
import _ from "lodash";
import "./FleetList.css";

/**
 * The Fleet List component.
 */
export default class FleetList extends Component {
  constructor(props) {
    super(props);

    this.createRows = this.createRows.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.getNextSortState = this.getNextSortState.bind(this);

    this.state = {
      maxRowsForScroll: 9,
      fleetTableSortValues: {
        tail: "def",
        registration: "def",
      },
    };
  }

  render() {
    return this.handleTableRender(this.props.tailList);
  }

  /**
   * Handles the render of the table view on the fleet view component.
   * @param tailList The tail list.
   * @returns {Element}
   */
  handleTableRender(tailList) {
    const serialSort = this.state.fleetTableSortValues.tail;
    const registrationSort = this.state.fleetTableSortValues.registration;
    let filteredTailList = tailList;

    if (serialSort !== "def" && registrationSort !== "def") {
      filteredTailList = _.orderBy(
        filteredTailList,
        ["serialNumber", "tailNumber"],
        [serialSort, registrationSort]
      );
    } else if (serialSort !== "def") {
      filteredTailList = _.orderBy(
        filteredTailList,
        ["serialNumber"],
        [serialSort]
      );
    } else if (registrationSort !== "def") {
      filteredTailList = _.orderBy(
        filteredTailList,
        ["tailNumber"],
        [registrationSort]
      );
    }

    const scroll = filteredTailList.length > this.state.maxRowsForScroll;

    return (
      <div className="fleetList">
        <div className="fleetList__header">
          <div className="fleetList__header__sectionTail fleetList__header__font">
            <span>Tail</span>
            <div
              className="fleetList__sort"
              onClick={() => this.handleSortChange("TAIL")}
            >
              <FeatherIcon
                icon={
                  this.state.fleetTableSortValues.tail === "asc"
                    ? "chevron-down"
                    : this.state.fleetTableSortValues.tail === "desc"
                      ? "chevron-up"
                      : "chevron-down"
                }
                height={18}
                width={18}
                color={
                  this.state.fleetTableSortValues.tail === "asc" ||
                    this.state.fleetTableSortValues.tail === "desc"
                    ? "#ffffff"
                    : "#666666"
                }
              />
            </div>
          </div>
          <div className="fleetList__header__sectionRegistration fleetList__header__font">
            <span>Registration</span>
            <div
              className="fleetList__sort"
              onClick={() => this.handleSortChange("REGISTRATION")}
            >
              <FeatherIcon
                icon={
                  this.state.fleetTableSortValues.registration === "asc"
                    ? "chevron-down"
                    : this.state.fleetTableSortValues.registration === "desc"
                      ? "chevron-up"
                      : "chevron-down"
                }
                height={18}
                width={18}
                color={
                  this.state.fleetTableSortValues.registration === "asc" ||
                    this.state.fleetTableSortValues.registration === "desc"
                    ? "#ffffff"
                    : "#666666"
                }
              />
            </div>
          </div>
          <div className="fleetList__header__sectionModel fleetList__header__font">
            Model
          </div>
          <div className="fleetList__header__sectionStatus fleetList__header__font">
            Status
          </div>
          <div className="fleetList__header__sectionWarning fleetList__header__font">
            Warning
          </div>
          <div className="fleetList__header__sectionCaution fleetList__header__font">
            Caution
          </div>
          <div
            className={
              "fleetList__header__font fleetList__header__sectionAdvisory" +
              (scroll ? "-withBar" : "")
            }
          >
            Advisory
          </div>
          {/*<div className="fleetList__header__sectionOms fleetList__header__font">
            OMS
          </div>
          <div
            className={
              "fleetList__header__font fleetList__header__sectionCms" +
              (scroll ? "-withBar" : "")
            }
          >
            CMS
          </div>*/}
        </div>
        {this.createRows(filteredTailList, scroll)}
      </div>
    );
  }

  // Create Row method
  createRows(visibleFilteredTailList, scroll) {
    let rows = <div />;
    if (visibleFilteredTailList) {
      rows = (
        <div
          className={
            scroll ? "fleetList__containerOverflow" : "fleetList__container"
          }
        >
          {visibleFilteredTailList.map((tail) => (
            <React.Fragment key={tail.serialNumber + tail.tailNumber}>
              <FleetRow
                id={tail.serialNumber + tail.tailNumber}
                serialNumber={tail.serialNumber}
                tailNumber={tail.tailNumber}
                aircraftFamily={tail.aircraftFamily}
                aircraftModel={tail.aircraftModel}
                status={tail.status}
                faultsWarning={tail.faultCountWarning}
                faultsCaution={tail.faultCountCaution}
                faultsAdvisory={tail.faultCountAdvisory}
                faultsOMS={tail.omsFaults}
                faultsCMS={tail.cmsFaults}
                monitorStatus={tail.monitorStatus}
              />
            </React.Fragment>
          ))}
        </div>
      );
      return rows;
    }
    return <div className="fleetList__containerNoData">NO DATA AVAILABLE</div>;
  }

  /**
   * Handles the table sorting.
   * @param fieldName The field name.
   */
  handleSortChange(fieldName) {
    if (fieldName === "TAIL") {
      const nextSortState = this.getNextSortState(
        this.state.fleetTableSortValues.tail
      );
      this.setState((prevState) => ({
        fleetTableSortValues: {
          ...prevState.fleetTableSortValues,
          tail: nextSortState,
        },
      }));
    } else {
      const nextSortState = this.getNextSortState(
        this.state.fleetTableSortValues.registration
      );
      this.setState((prevState) => ({
        fleetTableSortValues: {
          ...prevState.fleetTableSortValues,
          registration: nextSortState,
        },
      }));
    }
  }

  /**
   * Gets the next sorting state. Sorting is manual on this component, so we need this logic.
   * @param currentSortState The current sorting state.
   * @returns {string}
   */
  getNextSortState(currentSortState) {
    if (currentSortState === "def") {
      return "asc";
    }
    if (currentSortState === "asc") {
      return "desc";
    }
    if (currentSortState === "desc") {
      return "def";
    }
    return "def";
  }
}
