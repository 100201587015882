import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for the tails data APIs
*/

const TAILS = Api.TAILS;
const TAIL_GENERAL_DATA = Api.TAIL_GENERAL_DATA;
const PMX_USER_AIRCRAFT_SUBSCRIPTIONS = Api.PMX_USER_AIRCRAFT_SUBSCRIPTIONS;

// Tails API that returns a list of all the aircrafts, and its data, that a user has access to (user is subscribed to)
async function getFleet(axiosCancelSource) {
  let result;
  const data = {};

  try {
    result = await AxiosService(
      TAILS.url,
      TAILS.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }
  if(result.isAxiosError) {
    throw result.response.data;
  }
  return result;
}

// Tail General data API that returns the data of an aircraft that a user has access to (user is subscribed to)
async function getTailGeneralData(
  registrationNumber,
  serialNumber,
  queryTimestamp,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      registrationNumber: registrationNumber,
      serialNumber: serialNumber,
      queryTimestamp: queryTimestamp,
    },
  };

  try {
    result = await AxiosService(
      TAIL_GENERAL_DATA.url,
      TAIL_GENERAL_DATA.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }
  if(result.isAxiosError) {
    throw result.response.data;
  }
  return result;
}

// PMx Tails API that returns a list of all aircrafts and its data that user has access to for PMx
async function getPmxFleet(axiosCancelSource) {
  let result;
  const data = {};

  try {
    result = await AxiosService(
      PMX_USER_AIRCRAFT_SUBSCRIPTIONS.url,
      PMX_USER_AIRCRAFT_SUBSCRIPTIONS.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}


export { getFleet, getTailGeneralData, getPmxFleet };
