import React from "react";
import PropTypes from 'prop-types';
import CustomButton from "../../../../../Global Components/CustomButton";
import CheckCircle from "../../../static/CheckCircle.js";
import "./FeedbackConfirmation.scss";


const FeedbackConfirmation = ({onHide, isDark}) => {
  return (
    <div className={`successContainer successContainer`}>
        <span className="successIconContainer">
          <CheckCircle isDark={isDark}/>
        </span>
        <h1 className={`successSection successHeader successHeader`}>THANK YOU</h1>
        <p className={`successSection successCopy successCopy`}>
            We appreciate your feedback!
        </p>
        <div className="successCloseButtonContainer">
          <CustomButton
            id={"close-data-button"}
            text={"CLOSE"}
            buttonStyle={"pill"}
            onClickAction={onHide}
          />
        </div>
    </div>
  );
}

export default FeedbackConfirmation;
  
FeedbackConfirmation.propTypes = {
  onHide: PropTypes.func,
};