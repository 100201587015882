import AxiosService from "./AxiosService";
import moment from "moment";
import * as Constants from "../Constants/Constants";

const BOMBARDIER_API_URL = Constants.BOMBARDIER_API_URL();
const RPD_PARAMETER_LIST = "/v10/rpd_parameter_list";
const RPD_PARAMETER_DATA = "/v10/rpd-parameter-data";

async function getRpdParameterData(
  rpdRequestId,
  parameter,
  padding,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  let result;
  const transform = "line";
  const discreteTranslation = { on: "True", off: "False" };

  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      rpdRequestId: rpdRequestId,
      parameterId: parameter.parameterName,
      parameterName: parameter.parameterName,
      durationPadding: padding,
      englishName: parameter.parameterEnglishName,
      ata: parameter.ATAChapter,
      transform: transform,
      discreteTranslation: discreteTranslation ? discreteTranslation : null,
      lru: parameter.LRU ? parameter.LRU : null,
    },
  };

  try {
    result = await AxiosService(
      BOMBARDIER_API_URL + RPD_PARAMETER_DATA,
      "GET",
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    return error;
  }

  return result;
}

async function getRpdParameterList(
  tail,
  faultCode,
  startTime,
  endTime,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  let result;
  const start = moment.utc(startTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const end = moment.utc(endTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const faultCodes = faultCode ? faultCode : "*";
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tail,
      faultCode: faultCodes,
      startTime: start,
      endTime: end,
    },
  };

  try {
    result = await AxiosService(
      BOMBARDIER_API_URL + RPD_PARAMETER_LIST,
      "GET",
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    return error;
  }

  return result;
}

export { getRpdParameterList, getRpdParameterData };
