import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import {ThreeDots} from "react-loader-spinner";
import "../styles/Global Components/Card.css";
import CustomTooltip from "../Global Components/CustomTooltip";

export default class Card extends Component {
  constructor(props) {
    super(props);

    this.handleCardClick = this.handleCardClick.bind(this);

    this.state = {
      cardId: "BA_CARD_ID_" + Math.floor(Math.random() * 90000),
    };
  }

  render() {
    const featherIconSize = 27;

    if (this.props.isLoading) {
      return (
        <div
          disabled
          id={this.props.id ? this.props.id : this.state.cardId}
          className="card card_size-medium card__disabled"
        >
          <div className="card__content">
            <div className="card__icon">
              <ThreeDots
                color="FFFFFF"
                height={featherIconSize}
                width={featherIconSize}
              />
            </div>
            <div className="card__text">Loading</div>
          </div>
          <div
            className={
              "card__footer" + (this.props.active ? " card__active" : "")
            }
          ></div>
        </div>
      );
    } else {
      return (
        <div
          id={this.props.id ? this.props.id : this.state.cardId}
          className={
            (this.props.id !== "card__smartfix" ? "card" : "card__smartfix") +
            (this.props.smallCard ? " card_size-small" : " card_size-medium") +
            (this.props.disabled
              ? this.props.id === "card__smartfix"
                ? " card__disabled__smartfix"
                : " card__disabled"
              : "")
          }
          disabled={this.props.disabled}
          onClick={this.handleCardClick}
        >
          <div
            className={
              this.props.id !== "card__smartfix"
                ? "card__content"
                : "card__content__smartfix"
            }
          >
            {this.props.informativeMessage && (
              <CustomTooltip
                tooltipType={"contextual"}
                title="Informative"
                content={this.props.informativeMessage}
                placement={"top-end"}
              >
                <div className="card__informativeMessage__dot"></div>
              </CustomTooltip>
            )}

            <div
              className={
                this.props.disabled ? "card__icon__disabled" : "card__icon"
              }
            >
              {/* Render a custom icon if there is one, or a feather icon if there isn't  */}
              {this.props.iconCustom
                ? this.props.iconCustom
                : this.props.featherIconName && (
                    <FeatherIcon
                      icon={
                        this.props.active
                          ? this.props.featherIconNameActive
                            ? this.props.featherIconNameActive
                            : this.props.featherIconName
                          : this.props.featherIconName
                      }
                      height={featherIconSize}
                      width={featherIconSize}
                      color={"#FFFFFF"}
                      className={
                        this.props.disabled
                          ? "external-link-icon__disabled"
                          : "external-link-icon__enabled"
                      }
                    />
                  )}
            </div>
            <div className="card__text">
              <span
                className={
                  "card__span__title-text " +
                  /**
                   * THIS TERNARY IN PLAIN ENGLISH:
                   * If disabled && id is card__disabled__smartfix, set smartfix-disabled property
                   * If not disabled && ID is card__smartfix, set it to smartfix enabled style
                   * Otherwise, don't add a new style class
                   */
                  (this.props.disabled
                    ? this.props.id === "card__disabled__smartfix"
                      ? "card__text_smartfix-disabled"
                      : "card__text_smartfix-disabled"
                    : this.props.id === "card__smartfix"
                    ? "card__text_smartfix-enabled"
                    : "")
                }
              >
                {this.props.active
                  ? this.props.textTitleActive
                    ? this.props.textTitleActive
                    : this.props.textTitle
                  : this.props.textTitle}
              </span>
              <span
                className={"card__span__text " + this.changeCardTextStyle()}
              >
                {this.props.active
                  ? this.props.textActive
                    ? this.props.textActive
                    : this.props.text
                  : this.props.text}
              </span>
            </div>
            {this.props.hasInfo && (
              <CustomTooltip
                tooltipType={"contextual"}
                title="Informative"
                content={this.props.tooltipText}
                placement={"top-end"}
              >
                <div>
                  <FeatherIcon icon="info" width={25} height={25} />
                </div>
              </CustomTooltip>
            )}
          </div>
        </div>
      );
    }
  }

  handleCardClick() {
    if (
      this.props.onCardClickEvent !== null &&
      this.props.onCardClickEvent !== undefined
    ) {
      if (!this.props.disabled) {
        if (this.props.id === "card__smartfix") {
          this.props.onCardClickEvent(this.props.sfpLink);
        } else {
          this.props.onCardClickEvent();
        }
      }
    }
  }

  // Change card styles based on whether card is enabled or disabled
  changeCardTextStyle() {
    let buildStyles = "";
    if (this.props.disabled) {
      if (this.props.id === "card__disabled__smartfix") {
        buildStyles += " card__text_smartfix-disabled";
        // Full Flight Parameters card doesn't use smartfix disabled class
      } else if (this.props.text === "Full Flight Parameters") {
        buildStyles += " card__text-disabled";
      } else {
        buildStyles += " card__text_smartfix-disabled";
      }
      // Else if Card not disabled
    } else {
      if (this.props.id === "card__smartfix") {
        buildStyles += " card__text_smartfix-enabled";
      } else {
        buildStyles += "";
      }
    }
    return buildStyles;
  }
}
