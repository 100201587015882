import React from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import { styled } from "@mui/material/styles";

import { Select, MenuItem } from "@mui/material";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "white !important",
}));

const CustomSelect = ({
  id,
  handleChange,
  options,
  placeholder,
  currValue,
  label,
  value,
  width = "50%",
}) => {
  const containsPlaceholder = typeof placeholder === "undefined" ? false : true;
  const containsFieldId = value && typeof value !== "undefined" ? true : false;

  return (
    <Select
      displayEmpty
      value={currValue ? currValue : ""}
      name={id}
      renderValue={(selected) => {
        if (containsFieldId) {
          let optionsIndex = _.findIndex(options, function (o) {
            return o[value] === selected;
          });

          if (
            (optionsIndex !== -1 &&
              typeof options[optionsIndex] !== "undefined") ||
            !placeholder ||
            typeof placeholder === "undefined"
          ) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {options[optionsIndex].icon && (
                  <FeatherIcon
                    width={25}
                    height={25}
                    icon={options[optionsIndex].icon}
                    style={{ marginRight: 10 }}
                  />
                )}
                <div>{options[optionsIndex][label]}</div>
              </div>
            );
          } else {
            return (
              <span
                style={{
                  fontWeight: 700,
                  fontSize: 16,
                  color: "#D2D2D2",
                }}
              >
                {placeholder}
              </span>
            );
          }
        } else return selected;
      }}
      SelectDisplayProps={{
        style: {
          padding: "25px 20px 20px 20px",
        },
      }}
      IconComponent={(props) => (
        <FeatherIcon
          {...props}
          width={30}
          height={30}
          icon={"chevron-down"}
          style={{ color: "#fff", marginRight: 5, marginTop: -2 }}
        />
      )}
      style={{
        width: width,
        height: 50,
        backgroundColor: "transparent",
        borderBottomWidth: 0,
        border: "2px solid #505050",
        marginTop: 10,
      }}
      MenuProps={{
        PaperProps: {
          style: {
            backgroundColor: "#606060",
            borderRadius: 10,
            padding: 5,
          },
        },
      }}
    >
      {containsPlaceholder && (
        <StyledMenuItem disabled value="">
          <em>{placeholder}</em>
        </StyledMenuItem>
      )}
      {options?.map((option, index) => (
        <StyledMenuItem
          key={index}
          value={containsFieldId ? option[value] : option}
          style={{ marginTop: 10 }}
          onClick={() => {
            if (!containsPlaceholder) {
              if (containsFieldId) handleChange(id, option.value);
              else {
                handleChange(id, option);
              }
            } else handleChange(id, option, label, value);
          }}
        >
          {option.icon && (
            <FeatherIcon
              width={20}
              height={20}
              icon={option.icon}
              style={{ marginRight: 10 }}
            />
          )}
          {containsFieldId ? option[label] : option}
        </StyledMenuItem>
      ))}
    </Select>
  );
};

export default CustomSelect;
