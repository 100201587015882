import React, { Component } from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import AircraftIcon from "../resources/aircraft-solid.svg";
import Spinner from "../Global Components/Spinner.js";
import { ThreeDots } from "react-loader-spinner";
import Banner from "../Global Components/Banner";
import * as tools from "../utils/CommonTools";
import ConfigCyclesHoursBox from "./ConfigCyclesHoursBox";
import ConfigFleetTailsBox from "./ConfigFleetTailsBox";
import ConfigCommonFaultBox from "./ConfigCommonFaultBox";
import ConfigAircraftSettingsBox from "./ConfigAircraftSettingsBox";
import { getFleet } from "../clients/FleetAlertsClient";
import {
  getFlightCycles,
  getFlightCycleLogs,
  postFlightCycles,
  getFlightCyclesByDate,
} from "../clients/AircraftFlightsClient";
import { getCommonFDEList, postCommonFDEList } from "../clients/ConfigFaults";
import {
  getAircraftSettings,
  postAircraftSettings,
} from "../clients/AircraftSettingsConfig";
import "../styles/Global Components/Button.css";
import "../styles/Global Components/LoaderContainer.css";
import "./ConfigView.css";
import ConfirmationPopup from "../Global Components/ConfirmationPopup";
import NotificationPopup from "../Global Components/NotificationPopup.js";
import { getRolesPermissions } from "../Auth Components/RbacValidation.js";
import {
  getNotificationsSettingsV10,
  postNotificationsSettingsV10,
} from "../clients/NotificationsClient";
import AirframeUIConfigs from "../resources/AirframeUIConfigs.json";
import Wizard from "../Application Onboarding Components/Wizard.js";
import ConfigViewOptionsTable from "./ConfigViewOptionsTable";
import ConfigNotifications from "./ConfigNotifications";

export default class ConfigView extends Component {
  fullTailList = [];
  fullAircraftFamilyModelList = [];
  notificationTailList = [];
  notificationFamilyModelList = [];
  constructor(props) {
    super(props);

    this.promisedSetState = this.promisedSetState.bind(this);
    this.renderConfiurationView = this.renderConfiurationView.bind(this);
    this.loadConfigPageInfo = this.loadConfigPageInfo.bind(this);
    this.loadConfigSubjectPageInfo = this.loadConfigSubjectPageInfo.bind(this);
    this.loadConfigCommonFaultPageInfo =
      this.loadConfigCommonFaultPageInfo.bind(this);
    this.loadConfigSettingsPageInfo =
      this.loadConfigSettingsPageInfo.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
    this.handleConfigOptionsBox = this.handleConfigOptionsBox.bind(this);
    this.handleConfigSubjectSelect = this.handleConfigSubjectSelect.bind(this);
    this.handleConfigSettingsBox = this.handleConfigSettingsBox.bind(this);
    this.loadLocationInformation = this.loadLocationInformation.bind(this);
    this.loadUserInfo = this.loadUserInfo.bind(this);
    this.familyModelSelectEvent = this.familyModelSelectEvent.bind(this);
    this.tailSelectEvent = this.tailSelectEvent.bind(this);
    this.handleCyclesHoursSubmit = this.handleCyclesHoursSubmit.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.submitOKConfirmationModal = this.submitOKConfirmationModal.bind(this);
    this.openNotificationModal = this.openNotificationModal.bind(this);
    this.closeNotificationModal = this.closeNotificationModal.bind(this);
    this.getConfigOptionsList = this.getConfigOptionsList.bind(this);
    this.handleCommonFaultOptionClick =
      this.handleCommonFaultOptionClick.bind(this);
    this.handleConfigHoursOptionClick =
      this.handleConfigHoursOptionClick.bind(this);
    this.handleAircraftSettingsOptionClick =
      this.handleAircraftSettingsOptionClick.bind(this);
    this.handleEditCommonFaultClick =
      this.handleEditCommonFaultClick.bind(this);
    this.handleSaveCommonFaultClick =
      this.handleSaveCommonFaultClick.bind(this);
    this.handleUpdateSettingsClick = this.handleUpdateSettingsClick.bind(this);
    this.handleNotificationMgmtOptionClick =
      this.handleNotificationMgmtOptionClick.bind(this);
    this.getFaultFamilyModelRules = this.getFaultFamilyModelRules.bind(this);
    this.getNotificationFamilyModelRules =
      this.getNotificationFamilyModelRules.bind(this);
    this.updateNotificationsConfigV10 =
      this.updateNotificationsConfigV10.bind(this);
    // this.getNextOption = this.getNextOption.bind(this);

    const info = this.loadLocationInformation();
    const userInfo = this.loadUserInfo();
    const rolesPermissions = getRolesPermissions("configview");

    this.state = {
      rolesPermissions: rolesPermissions,
      configOptionsList: this.getConfigOptionsList(),
      subjectEnableType: "TAIL",
      userName: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).name
        : "",
      userInfo: userInfo,
      loadingSubjectInfo: true,
      loadingCommonFaultInfo: true,
      loadingSettingsInfo: true,
      loadingNotificationsConfig: true,
      processingData: false,
      originPage: info[0],
      editCyclesPermissionAircraft: false,
      activeOption:
        info[0] !== "SIDE_NAV"
          ? "CONFIG_AIRCRAFT_HOURS"
          : "CONFIG_AIRCRAFT_SETTINGS",
      tail: info[1],
      aircraftFamily: info[2],
      aircraftModel: info[3],
      selectedTail: null,
      selectedFamilyModel: null,
      faultList: [],
      tailList: [],
      notificationsConfig: null,
      areNotificationsSaved: false,
      familyModelList: [],
      flightCycles: [],
      flightCycleLogs: [],
      confirmModalState: false,
      flightCycleEditData: null,
      aircraftSettings: { externalRpdRequest: null },
      unsavedCommonFaultEdits: false,
      notifModalState: false,
      alternativeExistLink: "",
      errorColorCode: "",
      errorDescription: "",
      errorCode: "ER-CN-00000",
      errorType: "",
      technical_detail: "",
      flightCyclesError: false,
      notificationsConfigV10: [],
    };
  }

  /**
   * Load location state prop information coming from the previous application page.
   * @return {Promise<void>}
   */
  loadLocationInformation() {
    if (this.props.location.state) {
      localStorage.setItem(
        "tmpTail",
        this.props.location.state.registrationNumber
      );
      localStorage.setItem(
        "tmpAircraftFamily",
        this.props.location.state.aircraftFamily
      );
      localStorage.setItem(
        "tmpAircraftModel",
        this.props.location.state.aircraftModel
      );
      localStorage.setItem("tmpSerial", this.props.location.state.serial);
      localStorage.setItem(
        "tmpMonitorStatus",
        this.props.location.state.monitorStatus
      );

      return [
        this.props.location.state.originPage,
        this.props.location.state.registrationNumber,
        this.props.location.state.aircraftFamily,
        this.props.location.state.aircraftModel,
      ];
    } else {
      return ["SIDE_NAV", null, null, null];
    }
  }

  /**
   * Load cached user info.
   * @return {Promise<void>}
   */
  loadUserInfo() {
    if (localStorage.getItem("userInfo")) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      return userInfo;
    } else {
      return null;
    }
  }

  async componentDidMount() {
    // Load all the data on the config view
    this.loadConfigPageInfo(
      this.state.tail,
      this.state.aircraftFamily,
      this.state.aircraftModel,
      this.state.selectedTail?.serialNumber
    );
  }

  /**
   * Treats the setState as a promise to be able to await for the change.
   * @param newState The new state.
   * @return {Promise<void>}
   */
  promisedSetState(newState) {
    return new Promise((resolve) => this.setState(newState, resolve));
  }

  updateNotificationsConfigV10(newNotificationsSetting) {
    this.setState({
      loadingNotificationsConfig: true,
    });

    postNotificationsSettingsV10(newNotificationsSetting)
      .then((res) => {
        this.getNotificationsV10(this.state.selectedTail?.serialNumber);
        // After successfully updating the notification configuration
        // set the notificationsSaved flag to true.
        this.setState({
          areNotificationsSaved: true,
        });
      })
      .catch((err) => {
        this.openNotificationModal({ errorCode: "ER-CN-00006" });
        this.setState({
          loadingNotificationsConfig: false,
        });
      });
  }

  // getNextOption(step) {
  //   if (step === 0) {
  //     this.handleCommonFaultOptionClick();
  //   } else if (step === 2) {
  //     this.handleNotificationMgmtOptionClick();
  //   } else if (step === 3) {
  //     this.handleAircraftSettingsOptionClick();
  //   } else if (step === 4) {
  //     this.handleConfigHoursOptionClick();
  //   }
  // }


  displayNotificationBanner() {
    if (this.fullAircraftFamilyModelList.length > 0) {

      // Function checks is user has access to Global 7500 or Challenger fleets
      const index = _.findIndex(
        this.fullAircraftFamilyModelList,
        function (o) {
          return (
            (o.aircraftFamily === 'Global' && o.aircraftModel === '7500')
            || (o.aircraftFamily === 'Challenger')
          )
        }
      )

      return index !== -1;
    }

    return false;
  }

  getConfigOptionsList() {
    const options = [
      {
        id: "COMMON_FAULT_FILTER",
        order: 0,
        title: "FDE Message Display",
        icon: (
          <FeatherIcon
            className="configView__table_options__icon"
            icon={"filter"}
          />
        ),
        onClickEvent: this.handleCommonFaultOptionClick,
        subjectEnableType: "FAMILY",
        type: "USER",
      },
      {
        id: "CONFIG_AIRCRAFT_HOURS",
        order: 3,
        title: "Airframe Hours/Cycles",
        icon: (
          <FeatherIcon
            className="configView__table_options__icon"
            icon={"clock"}
          />
        ),
        onClickEvent: this.handleConfigHoursOptionClick,
        subjectEnableType: "TAIL",
        type: "AIRCRAFT",
      },
      {
        id: "CONFIG_AIRCRAFT_SETTINGS",
        order: 2,
        title: "Aircraft Settings",
        icon: (
          <img
            className="configView__table_options__icon"
            src={AircraftIcon}
            alt="aircraft"
          ></img>
        ),
        onClickEvent: this.handleAircraftSettingsOptionClick,
        subjectEnableType: "TAIL",
        type: "AIRCRAFT",
      },
      {
        id: "NOTIFICATIONS_MANAGEMENT",
        order: 1,
        title: "Notifications",
        icon: (
          <FeatherIcon
            className="configView__table_options__icon"
            icon={"bell"}
          />
        ),
        onClickEvent: this.handleNotificationMgmtOptionClick,
        subjectEnableType: "TAIL",
        type: "USER",
      },
    ];

    return options;
  }

  setOption;

  setSelectedOption(optionId) {
    const theOption = _.find(this.state.configOptionsList, {
      id: optionId,
    });
    const subjectEnableType = theOption.subjectEnableType;
    let displayTailList = [];
    let currSelectedTail = this.state.selectedTail;
    // If we're trying to open the notifications configuration panel
    // then we only display the list of aircraft that are supported.
    if (theOption.id === "NOTIFICATIONS_MANAGEMENT") {
      displayTailList = this.notificationTailList;
      // If the user previously selected an aircraft that is not
      // supported then we set the selected tail to the first item
      // in the list.
      if (
        !this.getNotificationFamilyModelRules(
          currSelectedTail.aircraftFamily,
          currSelectedTail.aircraftModel
        )
      ) {
        currSelectedTail = displayTailList[0];
      }
    }
    // For any other option we display the full tails list.
    else {
      displayTailList = this.fullTailList;
    }
    this.setState({
      activeOption: optionId,
      subjectEnableType: subjectEnableType,
      tailList: displayTailList,
      selectedTail: currSelectedTail,
    });
  }

  // Renders the components
  render() {
    return (
      <div>
        <ConfirmationPopup
          active={this.state.confirmModalState}
          submitOK={this.submitOKConfirmationModal}
          submitCANCEL={this.closeConfirmationModal}
          descriptionText={
            "Are you sure? Edits will be applied to aircraft records and visible in your organization."
          }
          cancelText={"CANCEL"}
          acceptText={"PROCEED"}
        />
        {this.renderConfiurationView()}
      </div>
    );
  }

  /**
   * Load all the data on the configuration page for a given aircraft or aircraft family/model.
   * @param tail Aircraft registration number.
   * @param aircraftFamily Aircraft family.
   * @param aircraftModel Aircraft model.
   * @param serialNumber Aircraft serial number.
   * @return {Promise<void>}
   */
  async loadConfigPageInfo(tail, aircraftFamily, aircraftModel, serialNumber) {
    await this.loadConfigSubjectPageInfo(tail);

    this.loadConfigCommonFaultPageInfo(
      this.state.selectedFamilyModel?.aircraftFamily,
      this.state.selectedFamilyModel?.aircraftModel
    );

    this.loadConfigSettingsPageInfo(
      tail ? tail : this.state.selectedTail?.registrationNumber,
      aircraftFamily ? aircraftFamily : this.state.aircraftFamily,
      aircraftModel ? aircraftModel : this.state.aircraftModel,
      serialNumber ? serialNumber : this.state.selectedTail?.serialNumber
    );
  }

  // New API for getNotifications settings v10
  async getNotificationsV10(aircraftRegistrationNumber) {
    getNotificationsSettingsV10(aircraftRegistrationNumber)
      .then((res) => {
        if (res.data) {
          this.setState({
            notificationsConfigV10: res.data,
            loadingNotificationsConfig: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loadingNotificationsConfig: false,
        });
        this.openNotificationModal({ errorCode: "ER-CN-00005" });
      });
  }

  /**
   * Load the aircraft that the user is subscribed to.
   * If a registration number is passed as a parameter, that tail is set as selected by default.
   * @param tail Aircraft registration number.
   * @return {Promise<void>}
   */
  async loadConfigSubjectPageInfo(tail) {
    this.setState({
      loadingSubjectInfo: true,
    });

    try {
      const tailList = this.processTailList();
      const promiseArray = [tailList];
      const aircraftPageData = await Promise.all(
        promiseArray.map((req) => req)
      );

      const tempTailList = _.orderBy(
        aircraftPageData[0][0],
        ["aircraftFamily", "aircraftModel", "serialNumber"],
        ["desc", "asc", "asc"]
      );

      const tempCompleteTailList = _.orderBy(
        aircraftPageData[0][1],
        ["aircraftFamily", "aircraftModel", "serialNumber"],
        ["desc", "asc", "asc"]
      );

      // Procesing the family model list based on the aircrafts that the user has acces to.
      const familyTailList = [];
      _.uniqBy(tempCompleteTailList, function (uTail) {
        return uTail.aircraftFamily + uTail.aircraftModel;
      }).forEach((oTail) => {
        familyTailList.push({
          aircraftFamily: oTail.aircraftFamily,
          aircraftModel: oTail.aircraftModel,
          faultRules: this.getFaultFamilyModelRules(
            oTail.aircraftFamily,
            oTail.aircraftModel
          ),
        });
      });

      const tempFamilyTailList = _.orderBy(
        familyTailList,
        ["aircraftFamily", "aircraftModel"],
        ["desc", "asc"]
      );

      let firstFamilyModel = null;
      if (tempFamilyTailList.length > 0) {
        firstFamilyModel = tempFamilyTailList[0];
      }

      let newTail = null;
      if (tail) {
        newTail = _.find(tempTailList, {
          registrationNumber: tail,
        });
      } else if (tempTailList.length > 0) {
        newTail = tempTailList[0];
      }

      this.fullTailList = tempTailList;
      this.fullAircraftFamilyModelList = tempFamilyTailList;

      this.notificationTailList = tempTailList.filter((tail) => {
        return this.getNotificationFamilyModelRules(
          tail.aircraftFamily,
          tail.aircraftModel
        );
      });
      this.notificationFamilyModelList = tempFamilyTailList.filter((tail) => {
        return this.getNotificationFamilyModelRules(
          tail.aircraftFamily,
          tail.aircraftModel
        );
      });

      await this.promisedSetState({
        tailList: tempTailList,
        familyModelList: tempFamilyTailList,
        tail: newTail?.registrationNumber,
        editCyclesPermissionAircraft: newTail?.editCyclesPermission,
        selectedTail: newTail,
        aircraftFamily: newTail?.aircraftFamily,
        aircraftModel: newTail?.aircraftModel,
        selectedFamilyModel: firstFamilyModel,
        loadingSubjectInfo: false,
        processingData:
          this.state.loadingSettingsInfo || this.state.loadingCommonFaultInfo,
      });
    } catch (error) {
      await this.promisedSetState({
        tailList: [],
        notificationTailList: [],
        familyModelList: [],
        selectedTail: null,
        editCyclesPermissionAircraft: false,
        selectedFamilyModel: null,
        loadingSubjectInfo: false,
        processingData:
          this.state.loadingSettingsInfo || this.state.loadingCommonFaultInfo,
      });
    }
  }

  /**
   * Load the page data for the "COMMON FDE EVENT" configuration option by aircraft family/model.
   * @param aircraftFamily Aircraft family.
   * @param aircraftModel Aircraft model.
   * @return {Promise<void>}
   */
  async loadConfigCommonFaultPageInfo(aircraftFamily, aircraftModel) {
    this.setState({
      loadingCommonFaultInfo: true,
    });

    try {
      const commonFaultList = this.processCommonFaultList(
        aircraftFamily,
        aircraftModel
      );
      const promiseArray = [commonFaultList];
      const commonFaultData = await Promise.all(promiseArray.map((req) => req));

      this.setState({
        faultList: _.orderBy(commonFaultData[0], ["faultCode"], ["asc"]),
        loadingCommonFaultInfo: false,
        processingData:
          this.state.loadingSubjectInfo || this.state.loadingSettingsInfo,
      });
    } catch (error) {
      this.setState({
        faultList: [],
        loadingCommonFaultInfo: false,
        processingData:
          this.state.loadingSubjectInfo || this.state.loadingSettingsInfo,
      });
    }
  }

  async loadConfigSettingsPageInfo(
    tail,
    aircraftFamily,
    aircraftModel,
    serialNumber
  ) {
    this.setState({
      loadingSettingsInfo: true,
      processingData: true,
    });

    try {
      if (tail) {
        const flightCycles = this.processFlightCycles(
          tail,
          aircraftFamily,
          aircraftModel
        );
        const flightCycleLogs = this.processFlightCycleLogs(tail);
        const aircraftSettingsConfig = this.processAircraftSettings(tail);
        await this.getNotificationsV10(serialNumber);
        const promiseArray = [
          flightCycles,
          flightCycleLogs,
          aircraftSettingsConfig,
        ];
        const aircraftPageData = await Promise.all(
          promiseArray.map((req) => req)
        );

        this.setState({
          flightCycles: aircraftPageData[0],
          flightCycleLogs: aircraftPageData[1],
          aircraftSettings: aircraftPageData[2],
          loadingSettingsInfo: false,
          processingData:
            this.state.loadingSubjectInfo || this.state.loadingCommonFaultInfo,
        });
      } else {
        this.setState({
          flightCycles: [],
          flightCycleLogs: [],
          loadingSettingsInfo: false,
          processingData:
            this.state.loadingSubjectInfo || this.state.loadingCommonFaultInfo,
        });
      }
    } catch (error) {
      this.setState({
        flightCycles: [],
        flightCycleLogs: [],
        loadingSettingsInfo: false,
        processingData:
          this.state.loadingSubjectInfo || this.state.loadingCommonFaultInfo,
      });
    }
  }

  async processTailList() {
    try {
      const tailList = [];
      const completeTailList = [];
      let tailObject = null;

      if (this.state.userInfo) {
        await getFleet().then((res) => {
          res.data.forEach((tail) => {
            tailObject = {
              serialNumber: tail.tails.serialNumber,
              registrationNumber: tail.tails.tailNumber,
              aircraftType: tail.tails.aircraftType,
              organizationID: tail.tails.organizationID,
              organization: tail.tails.organizationID,
              aircraftFamily: tail.tails.aircraftFamily,
              aircraftModel: tail.tails.aircraftModel,
              editCyclesPermission: tail.tails.editCyclesPermission,
            };

            if (
              _.find(tools.allowedAircraftFamilyModels(), {
                aircraftFamily: tailObject.aircraftFamily,
                aircraftModel: tailObject.aircraftModel,
              })
            ) {
              completeTailList.push(tailObject);
            }

            tailList.push(tailObject);
          });
        });
      }

      return [tailList, completeTailList];
    } catch (error) {
      console.error("ERROR: ", error);
      this.openNotificationModal({ errorCode: "ER-CN-00001" });
      return [];
    }
  }

  /**
   * API call to request and return the catalog/list and user configuration of common FDE events, by aircraft family/model.
   * @param aircraftFamily Aircraft family.
   * @param aircraftModel Aircraft model.
   * @return {Array<object>}
   */
  async processCommonFaultList(aircraftFamily, aircraftModel) {
    try {
      let faultList = [];
      if (this.state.userInfo) {
        await getCommonFDEList(aircraftFamily, aircraftModel).then((res) => {
          faultList = res.data;
        });
      }
      return faultList;
    } catch (error) {
      console.error("ERROR: ", error);
      this.openNotificationModal(
        this.errorColorDefault,
        this.errorDescriptionDefault,
        true,
        "[ER-CN-00000]"
      );
      return [];
    }
  }

  async processFlightCycles(tailNumber) {
    try {
      const flightCycles = await getFlightCycles(
        tailNumber,
        this.state.aircraftFamily,
        this.state.aircraftModel
      );

      if (flightCycles.data) {
        return flightCycles.data;
      } else {
        return {
          totalFlightTime: null,
          totalFlightCycles: null,
          engine1Serial: null,
          totalEngine1Time: null,
          totalEngine1Cycles: null,
          engine2Serial: null,
          totalEngine2Time: null,
          totalEngine2Cycles: null,
        };
      }
    } catch (error) {
      return [
        {
          totalFlightTime: null,
          totalFlightCycles: null,
          engine1Serial: null,
          totalEngine1Time: null,
          totalEngine1Cycles: null,
          engine2Serial: null,
          totalEngine2Time: null,
          totalEngine2Cycles: null,
        },
      ];
    }
  }

  // This async function gets the flight cycles in a specified date,
  // "pointInTime" is the parameter for that value.

  async processFlightCyclesByDate(
    tailNumber,
    aircraftFamily,
    aircraftModel,
    pointInTime
  ) {
    try {
      const flightCycles = await getFlightCyclesByDate(
        tailNumber,
        aircraftFamily,
        aircraftModel,
        pointInTime
      );
      if (flightCycles.data) {
        return flightCycles.data;
      } else {
        return {
          totalFlightTime: null,
          totalFlightCycles: null,
          engine1Serial: null,
          totalEngine1Time: null,
          totalEngine1Cycles: null,
          engine2Serial: null,
          totalEngine2Time: null,
          totalEngine2Cycles: null,
        };
      }
    } catch (error) {
      return [
        {
          totalFlightTime: null,
          totalFlightCycles: null,
          engine1Serial: null,
          totalEngine1Time: null,
          totalEngine1Cycles: null,
          engine2Serial: null,
          totalEngine2Time: null,
          totalEngine2Cycles: null,
        },
      ];
    }
  }

  async processFlightCycleLogs(tailNumber) {
    try {
      const flightCycleLogs = await getFlightCycleLogs(
        tailNumber,
        this.state.aircraftFamily,
        this.state.aircraftModel
      );
      return _.orderBy(flightCycleLogs.data, ["timestamp"], ["desc"]);
    } catch (error) {
      console.error("ERROR: ", error);
      return [];
    }
  }

  async processAircraftSettings(tailNumber) {
    try {
      let settings = await getAircraftSettings(
        tailNumber,
        this.state.aircraftFamily,
        this.state.aircraftModel
      );
      return settings.data;
    } catch (error) {
      console.error("ERROR: ", error);
      this.openNotificationModal({ errorCode: "ER-CN-00003" });
      return { externalRpdRequest: false };
    }
  }

  familyModelSelectEvent(selectedValue) {
    const tempValues = selectedValue.value.split("-");
    const tempAircraftFamily = tempValues[0];
    const tempAircraftModel = tempValues[1];
    let newFamilyModel = _.find(this.state.familyModelList, {
      aircraftFamily: tempAircraftFamily,
      aircraftModel: tempAircraftModel,
    });

    if (newFamilyModel !== this.state.selectedFamilyModel) {
      this.setState(
        {
          selectedFamilyModel: newFamilyModel,
          unsavedCommonFaultEdits: false,
        },
        () => {
          this.loadConfigCommonFaultPageInfo(
            this.state.selectedFamilyModel
              ? this.state.selectedFamilyModel.aircraftFamily
              : null,
            this.state.selectedFamilyModel
              ? this.state.selectedFamilyModel.aircraftModel
              : null
          );
        }
      );
    }
  }

  tailSelectEvent(tail) {
    let newTail = _.find(this.state.tailList, {
      registrationNumber: tail,
    });

    if (newTail !== this.state.selectedTail) {
      this.setState(
        () => ({
          selectedTail: newTail,
          tail: newTail ? newTail.registrationNumber : null,
          aircraftFamily: newTail ? newTail.aircraftFamily : null,
          aircraftModel: newTail ? newTail.aircraftModel : null,
          editCyclesPermissionAircraft: newTail
            ? newTail.editCyclesPermission
            : false,
          areNotificationsSaved: false,
        }),
        () => {
          this.loadConfigSettingsPageInfo(
            this.state.selectedTail
              ? this.state.selectedTail.registrationNumber
              : null,
            this.state.selectedTail
              ? this.state.selectedTail.aircraftFamily
              : null,
            this.state.selectedTail
              ? this.state.selectedTail.aircraftModel
              : null,
            this.state.selectedTail?.serialNumber
              ? this.state.selectedTail?.serialNumber
              : null
          );
        }
      );
    }
  }

  async handleCyclesHoursSubmit(
    tailNumber,
    engine1Serial,
    engine2Serial,
    originalAirframeTime,
    originalAirframeCycles,
    originalEngine1Time,
    originalEngine1Cycles,
    originalEngine2Time,
    originalEngine2Cycles,
    adjustAirframeTime,
    adjustAirframeCycles,
    adjustEngine1Time,
    adjustEngine1Cycles,
    adjustEngine2Time,
    adjustEngine2Cycles
  ) {
    this.setState({
      flightCycleEditData: {
        tailNumber: tailNumber,
        engine1Serial: engine1Serial,
        engine2Serial: engine2Serial,
        originalAirframeTime: originalAirframeTime,
        originalAirframeCycles: originalAirframeCycles,
        originalEngine1Time: originalEngine1Time,
        originalEngine1Cycles: originalEngine1Cycles,
        originalEngine2Time: originalEngine2Time,
        originalEngine2Cycles: originalEngine2Cycles,
        adjustAirframeTime: adjustAirframeTime,
        adjustAirframeCycles: adjustAirframeCycles,
        adjustEngine1Time: adjustEngine1Time,
        adjustEngine1Cycles: adjustEngine1Cycles,
        adjustEngine2Time: adjustEngine2Time,
        adjustEngine2Cycles: adjustEngine2Cycles,
      },
    });

    if (this.state.rolesPermissions.editFlightCycles) {
      this.openConfirmationModal();
    }
  }

  openConfirmationModal() {
    this.setState({
      confirmModalState: true,
    });
  }

  closeConfirmationModal() {
    this.setState({
      confirmModalState: false,
      flightCycleEditData: null,
    });
  }

  async submitOKConfirmationModal() {
    this.closeConfirmationModal();
    this.setState({
      loadingSettingsInfo: true,
      processingData: true,
      flightCyclesError: false,
    });

    postFlightCycles(
      this.state.flightCycleEditData.tailNumber,
      this.state.flightCycleEditData.engine1Serial,
      this.state.flightCycleEditData.engine2Serial,
      this.state.flightCycleEditData.originalAirframeTime,
      this.state.flightCycleEditData.originalAirframeCycles,
      this.state.flightCycleEditData.originalEngine1Time,
      this.state.flightCycleEditData.originalEngine1Cycles,
      this.state.flightCycleEditData.originalEngine2Time,
      this.state.flightCycleEditData.originalEngine2Cycles,
      this.state.flightCycleEditData.adjustAirframeTime,
      this.state.flightCycleEditData.adjustAirframeCycles,
      this.state.flightCycleEditData.adjustEngine1Time,
      this.state.flightCycleEditData.adjustEngine1Cycles,
      this.state.flightCycleEditData.adjustEngine2Time,
      this.state.flightCycleEditData.adjustEngine2Cycles,
      this.state.aircraftFamily,
      this.state.aircraftModel
    )
      .then(async (res) => {
        const flightCycleLogs = await this.processFlightCycleLogs(
          this.state.tail
        );

        if (res.data) {
          this.setState({
            flightCycles: res.data,
            flightCycleLogs: flightCycleLogs,
            loadingSettingsInfo: false,
            processingData:
              this.state.loadingSubjectInfo ||
              this.state.loadingCommonFaultInfo,
          });
        } else {
          this.setState({
            loadingSettingsInfo: false,
            processingData:
              this.state.loadingSubjectInfo ||
              this.state.loadingCommonFaultInfo,
            flightCyclesError: true,
          });
        }
      })
      .catch((error) => {
        console.error(`Error : ${error}`);
        this.setState({
          loadingSettingsInfo: false,
          processingData:
            this.state.loadingSubjectInfo || this.state.loadingCommonFaultInfo,
        });
        this.openNotificationModal({ errorCode: "ER-CN-00002" });
      });
  }

  handleCommonFaultOptionClick() {
    this.setSelectedOption("COMMON_FAULT_FILTER");
  }

  handleConfigHoursOptionClick() {
    this.setSelectedOption("CONFIG_AIRCRAFT_HOURS");
  }

  handleAircraftSettingsOptionClick() {
    this.setSelectedOption("CONFIG_AIRCRAFT_SETTINGS");
  }

  handleNotificationMgmtOptionClick() {
    this.setSelectedOption("NOTIFICATIONS_MANAGEMENT");
  }

  handleEditCommonFaultClick(faultCodes, newStatus) {
    let newArray = this.state.faultList;
    _.forEach(this.state.faultList, function (fault) {
      if (_.includes(faultCodes, fault.faultCode)) {
        fault.reduceStatus = newStatus;
        fault.unsavedEdits = true;
      }
    });

    this.setState({
      unsavedCommonFaultEdits: true,
      faultList: newArray,
    });
  }

  handleSaveCommonFaultClick(faultList) {
    this.setState({
      loadingCommonFaultInfo: true,
      processingData: true,
    });

    const cleanFaultList = [];
    faultList.forEach((fault) => {
      if (fault.unsavedEdits) {
        cleanFaultList.push({
          faultCode: fault.faultCode,
          reduceStatus: fault.reduceStatus,
        });
      }
    });

    postCommonFDEList(
      cleanFaultList,
      this.state.selectedFamilyModel.aircraftFamily,
      this.state.selectedFamilyModel.aircraftModel
    )
      .then(async (res) => {
        this.loadConfigCommonFaultPageInfo(
          this.state.selectedFamilyModel.aircraftFamily,
          this.state.selectedFamilyModel.aircraftModel
        );
      })
      .catch((error) => {
        console.error(`Error : ${error}`);
        this.setState({
          loadingCommonFaultInfo: false,
          processingData:
            this.state.loadingSubjectInfo || this.state.loadingSettingsInfo,
        });
        this.openNotificationModal(
          this.errorColorContinueDefault,
          this.errorDescriptionContinueDefault,
          false,
          "[ER-CN-00000]"
        );
      });

    this.setState({
      unsavedCommonFaultEdits: false,
    });
  }

  handleUpdateSettingsClick(settingType, settingValue) {
    this.setState({
      loadingSettingsInfo: true,
      processingData: true,
    });

    if (this.state.rolesPermissions.editAcSettings) {
      const settingExternalRpdRequest =
        settingType === "EXT_RPD_REQ"
          ? settingValue
          : this.state.aircraftSettings.externalRpdRequest;

      postAircraftSettings(this.state.tail, settingExternalRpdRequest)
        .then(async (res) => {
          this.loadConfigSettingsPageInfo(
            this.state.tail,
            this.state.aircraftFamily,
            this.state.aircraftModel,
            this.state.selectedTail?.serialNumber
          );
        })
        .catch((error) => {
          console.error(`Error : ${error}`);
          this.setState({
            loadingSettingsInfo: false,
            processingData:
              this.state.loadingSubjectInfo ||
              this.state.loadingCommonFaultInfo,
          });
          this.openNotificationModal(
            this.errorColorContinueDefault,
            this.errorDescriptionContinueDefault,
            false,
            "[ER-CN-00004]"
          );
        });
    } else {
      this.setState({
        loadingSettingsInfo: false,
        processingData:
          this.state.loadingSubjectInfo || this.state.loadingCommonFaultInfo,
      });
    }
  }

  openNotificationModal({
    errorColorCode,
    errorDescription,
    alternativeExistLink,
    errorCode,
    errorType,
  }) {
    this.setState({
      errorColorCode: errorColorCode,
      errorDescription: errorDescription,
      alternativeExistLink: alternativeExistLink,
      notifModalState: true,
      errorCode: errorCode,
      errorType: errorType,
    });
  }

  closeNotificationModal = () => {
    this.setState({
      notifModalState: false,
      errorDescription: "",
      alternativeExistLink: null,
      errorColorCode: "",
      errorCode: "ER-AH-00000",
      errorType: "",
      technical_detail: "",
    });
  };

  getFaultFamilyModelRules(aircraftFamily, aircraftModel) {
    return {
      faultCode:
        AirframeUIConfigs[aircraftFamily][aircraftModel].configVIewPage
          .showFaultCode,
    };
  }

  /**
   * Checks the airframe config json file for the notifications rule.
   * @param aircraftFamily Aircraft Family
   * @param aircraftModel Aircraft Model
   * @return {boolean} true if the airframe supports notifications config.
   */
  getNotificationFamilyModelRules(aircraftFamily, aircraftModel) {
    return AirframeUIConfigs[aircraftFamily][aircraftModel]
      ? AirframeUIConfigs[aircraftFamily][aircraftModel].configVIewPage
        .hasNotifications
      : false;
  }

  /**
   * Configuration page main render function.
   * @return {HTML}
   */
  renderConfiurationView() {
    const errorNotification = (
      <NotificationPopup
        userName={this.state.userName}
        open={this.state.notifModalState}
        closeModal={this.closeNotificationModal}
        description={this.state.errorDescription}
        alternativeExistLink={this.state.alternativeExistLink}
        color={this.state.errorColorCode}
        errorCode={this.state.errorCode}
        type={this.state.errorType}
        technical_detail={this.state.technical_detail}
      />
    );

    const configViewContent = (
      <div className="configView">
        <Wizard
          iofgKey="NEW_USER_ONBOARDING"
          pageKey="CONF_PG"
        // specialAction={this.getNextOption}
        />
        <div className="configView__content">
          <Banner
            pageType={"Configuration"}
            icon={"settings"}
            logoText={"BOMBARDIER"}
          />
          <div className="configView__contentBody">
            <div className="configView__optionsContainer">
              <div className="configView__optionsContainer__contentWrapper">
                {this.handleConfigOptionsBox()}
              </div>
            </div>
            <div
              style={{
                width: "40px",
                height: "100%",
              }}
            ></div>
            <div className="configView__settingsContainer">
              <div className="configView__settingsContainer__headerWrapper">
                <div className="configView__settingsContainer__headerWrapper__title">
                  {
                    _.find(this.state.configOptionsList, {
                      id: this.state.activeOption,
                    }).title
                  }
                </div>
                <div className="configView__settingsContainer__headerWrapper__subject">
                  {this.handleConfigSubjectSelect(
                    this.state.loadingSubjectInfo
                  )}
                </div>
                {this.state.activeOption === "NOTIFICATIONS_MANAGEMENT"
                  && this.displayNotificationBanner()
                  && <div className="configView__settingsContainer__headerWrapper__message">
                    To set up or modify in-flight notifications for the{' '}
                    <span style={{ fontWeight: 'bold' }}>Global 7500</span>,
                    <br />
                    please get in touch with{' '}
                    <a
                      href='mailto: smartlink@aero.bombardier.com'
                      style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'inherit' }}
                    >
                      smartlink@aero.bombardier.com
                    </a>
                  </div>
                }
              </div>
              <div className="configView__settingsContainer__contentWrapper">
                {this.handleConfigSettingsBox(
                  this.state.loadingSettingsInfo ||
                  this.state.loadingCommonFaultInfo ||
                  this.state.loadingNotificationsConfig
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return this.state.notifModalState ? errorNotification : configViewContent;
  }

  /**
   * Load and render the configuration options list.
   * This may have user and aircraft config options.
   * @return {HTML}
   */
  handleConfigOptionsBox() {
    return (
      <ConfigViewOptionsTable
        configOptionsList={this.state.configOptionsList}
        activeOptionId={this.state.activeOption}
      />
    );
  }

  /**
   * Load and render the dropdown component that lists the aircraft and aircraft families the user is subscribed to.
   * @param loading Flag that indicates if data is still loading from API calls to enable controls to users.
   * @return {HTML}
   */
  handleConfigSubjectSelect(loading) {
    const loader = (
      <div className="configView__configFleetTails__loader">
        <div className="configView__configFleetTails__loader__text">
          Loading
        </div>
        <ThreeDots color="#909090" height={35} width={35} />
      </div>
    );

    const subjectSelect = (
      <ConfigFleetTailsBox
        subjectEnableType={this.state.subjectEnableType}
        tailList={this.state.tailList}
        familyModelList={this.state.familyModelList}
        familyModelSelectEvent={this.familyModelSelectEvent}
        tailSelectEvent={this.tailSelectEvent}
        selectedTail={this.state.selectedTail}
        selectedFamilyModel={this.state.selectedFamilyModel}
      />
    );

    return loading ? loader : subjectSelect;
  }

  /**
   * Load and render the configuration/settings components that the user can interact with.
   * @param loading Flag that indicates if data is still loading from API calls to enable controls to users.
   * @return {HTML}
   */
  handleConfigSettingsBox(loading) {
    const loader = (
      <div
        style={{
          width: "100%",
          height: "100%",
          zIndex: "2000",
        }}
      >
        <div style={{ width: "100px", paddingTop: "20%", margin: "auto" }}>
          <Spinner />
        </div>
      </div>
    );

    let settingsBox = <div></div>;

    if (this.state.activeOption === "CONFIG_AIRCRAFT_HOURS") {
      if (this.state.selectedTail !== null) {
        settingsBox = (
          <ConfigCyclesHoursBox
            serialNumber={this.state.tail}
            flightCycles={this.state.flightCycles}
            flightCycleLogs={this.state.flightCycleLogs}
            handleCyclesHoursSubmit={this.handleCyclesHoursSubmit}
            permEditFlightCycles={this.state.rolesPermissions.editFlightCycles}
            editCyclesPermissionAircraft={
              this.state.editCyclesPermissionAircraft
            }
            processFlightCyclesByDate={this.processFlightCyclesByDate}
            selectedTail={this.state.selectedTail}
            flightCyclesError={this.state.flightCyclesError}
          />
        );
      } else {
        settingsBox = (
          <div className="configView__noSelectionNotice">
            PLEASE SELECT AN AIRCRAFT
          </div>
        );
      }
    } else if (this.state.activeOption === "CONFIG_AIRCRAFT_SETTINGS") {
      if (this.state.selectedTail !== null) {
        settingsBox = (
          <ConfigAircraftSettingsBox
            aircraftFamily={this.state.selectedTail.aircraftFamily}
            aircraftModel={this.state.selectedTail.aircraftModel}
            aircraftSettings={this.state.aircraftSettings}
            handleUpdateSettingsClick={this.handleUpdateSettingsClick}
            permEditAcSettings={this.state.rolesPermissions.editAcSettings}
          />
        );
      } else {
        settingsBox = (
          <div className="configView__noSelectionNotice">
            PLEASE SELECT AN AIRCRAFT
          </div>
        );
      }
    } else if (this.state.activeOption === "NOTIFICATIONS_MANAGEMENT") {
      settingsBox = (
        <div style={{ width: "100%" }}>
          <ConfigNotifications
            notificationsConfigV10={this.state.notificationsConfigV10}
            savedSuccessfully={this.state.areNotificationsSaved}
            updateNotificationsConfigV10={this.updateNotificationsConfigV10}
            selectedTail={this.state.selectedTail}
          />
        </div>
      );
    } else {
      settingsBox = (
        <ConfigCommonFaultBox
          faultList={this.state.faultList}
          faultFamilyModelRules={
            this.state.selectedFamilyModel
              ? this.state.selectedFamilyModel.faultRules
              : null
          }
          unsavedCommonFaultEdits={this.state.unsavedCommonFaultEdits}
          handleEditCommonFaultClick={this.handleEditCommonFaultClick}
          handleSaveCommonFaultClick={this.handleSaveCommonFaultClick}
        />
      );
    }

    return loading ? loader : settingsBox;
  }
}
