import AxiosService from "./AxiosService";
import * as Constants from "../Constants/Constants";
import axios from "axios";

const BOMBARDIER_API_URL = Constants.BOMBARDIER_API_URL();
const EXCEL_DATA_PLOT_ENDPOINT = "/v10/parameter-data-xlsx";

export async function getExcelReport(data) {
  let result;

  try {
    result = axios({
      method: "post",
      url: BOMBARDIER_API_URL + EXCEL_DATA_PLOT_ENDPOINT,
      data: data,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
  } catch (error) {
    console.error(error);
  }

  return result;
}
