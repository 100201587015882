import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for the notifications API.
*/
const GET_NOTIFICATIONS_SETTINGS_V10 = Api.GET_NOTIFICATIONS_SETTINGS_V10;
const POST_NOTIFICATIONS_SETTINGS_V10 = Api.POST_NOTIFICATIONS_SETTINGS_V10;
const GET_USER_NOTIFICATIONS_PAGINATED = Api.GET_USER_NOTIFICATIONS_PAGINATED;
const UPDATE_USER_NOTIFICATION = Api.UPDATE_USER_NOTIFICATION;
const USER_AIRCRAFT_SUBSCRIPTIONS = Api.USER_AIRCRAFT_SUBSCRIPTIONS;
const GET_ANNOUNCEMENT_LIST_PAGINATED = Api.GET_ANNOUNCEMENT_LIST_PAGINATED;

/**
 * Get a list of aircrafts that a user is subscribed to with optional filtering based on notification
 * @param filterByAircraftNotificationType
 * @param axiosCancelSource The Axios Cancel Source.
 * @return {Promise<AxiosResponse<any>>} Response from the API.
 */
export async function getUserAircraftSubscriptions(
  filterByAircraftNotificationType,
  axiosCancelSource
) {
  let result;

  let data = {
    params: {
      filterByAircraftNotificationType: filterByAircraftNotificationType
    }
  };

  try {
    result = await AxiosService(
      USER_AIRCRAFT_SUBSCRIPTIONS.url,
      USER_AIRCRAFT_SUBSCRIPTIONS.method,
      data,
      axiosCancelSource
    )
  } catch (err) {
    throw err;
  }

  return result;
}

/**
 * Gets the paginated notifications response for the user that is logged in
 * @param page The page number
 * @param pageSize The size of the page
 * @param filters The object containing the filters to be applied on the notifications
 * @param axiosCancelSource The Axios Cancel Source.
 * @return {Promise<AxiosResponse<any>>} Response from the API.
 */
export async function getUserNotificationsPaginated(
  page,
  pageSize,
  filters = {},
  axiosCancelSource
) {
  let result;
  
  let data = {
    params: {
      page: page,
      page_size: pageSize,
      ...filters
    },
  };

  try {
    result = await AxiosService(
      GET_USER_NOTIFICATIONS_PAGINATED.url,
      GET_USER_NOTIFICATIONS_PAGINATED.method,
      data,
      axiosCancelSource
    )
  } catch (err) {
    throw err;
  }

  return result;
}

/**
 * Update the status of an existing notification.
 * @param id The ID of the notification to be updated.
 * @param notificationStatus The new status.
 * @param axiosCancelSource The cancel source.
 * @return {Promise<AxiosResponse<any>>} The API Response.
 */
export async function updateUserNotifications(
  id,
  notificationStatus,
  axiosCancelSource
) {
  let result;
  let data = {
    id: id,
    notification_status: notificationStatus
  };

  try {
    result = await AxiosService(
      UPDATE_USER_NOTIFICATION.url,
      UPDATE_USER_NOTIFICATION.method,
      data,
      axiosCancelSource
    )
  } catch (err) {
    throw err;
  }
  return result;
}

// API client to GET notifications configuration settings v10
export async function getNotificationsSettingsV10(
  aircraftRegistrationNumber,
  axiosCancelSource
) {
  let result;

  let data = {
    params: {
      aircraft_id: aircraftRegistrationNumber,
    },
  };

  try {
    result = await AxiosService(
      GET_NOTIFICATIONS_SETTINGS_V10.url,
      GET_NOTIFICATIONS_SETTINGS_V10.method,
      data,
      axiosCancelSource,
      true
    );
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}

/**
 Save the new user settings config v10:
  *@params
 *@serial_number (string)
 *@user_id (string)
 *@aircraft_id (string)
 *@user_settings (object)
 *@id (setting record uuid string)
 *@user_id (user uuid string)
 *@notification_type (string)
 *@dispatch_type (string)
 *@setting_value (boolean)
 */
export async function postNotificationsSettingsV10(data, axiosCancelSource) {
  let result;
  try {
    result = await AxiosService(
      POST_NOTIFICATIONS_SETTINGS_V10.url,
      POST_NOTIFICATIONS_SETTINGS_V10.method,
      data,
      axiosCancelSource,
      true
    );
  } catch (error) {
    console.error(error);
    return { error: error };
  }
}

/**
 * Gets the paginated announcements
 * @param page The page number
 * @param pageSize The size of the page
 * @param filters The object containing the filters to be applied on the notifications
 * @param axiosCancelSource The Axios Cancel Source.
 * @return {Promise<AxiosResponse<any>>} Response from the API.
 */
export async function getAnnouncementsPaginated(
  page,
  pageSize,
  filters = {},
  axiosCancelSource
) {
  let result;
  
  let data = {
    params: {
      page: page,
      page_size: pageSize,
      ...filters
    },
  };

  try {
    result = await AxiosService(
      GET_ANNOUNCEMENT_LIST_PAGINATED.url,
      GET_ANNOUNCEMENT_LIST_PAGINATED.method,
      data,
      axiosCancelSource
    )
  } catch (err) {
    throw err;
  }

  return result;
}