import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Clients for aircraft permission APIs
*/

const AIRCRAFT_SETTINGS = Api.AIRCRAFT_SETTINGS;
const AIRCRAFT_SETTINGS_UPDATE = Api.AIRCRAFT_SETTINGS_UPDATE;

// Get the settings per aircraft for certain components of the User interface.
async function getAircraftSettings(tail, aircraftFamily, aircraftModel) {
  let result;
  let data = {
    params: {
      tailNumber: tail,
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
    },
  };

  try {
    result = await AxiosService(
      AIRCRAFT_SETTINGS.url,
      AIRCRAFT_SETTINGS.method,
      data
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Update the settings per aircraft for certain components of the User interface.
async function postAircraftSettings(tail, settingExternalRpdRequest) {
  let result;

  const data = {
    tailNumber: tail,
    externalRpdRequest: settingExternalRpdRequest,
  };

  try {
    result = await AxiosService(
      AIRCRAFT_SETTINGS_UPDATE.url,
      AIRCRAFT_SETTINGS_UPDATE.method,
      data
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

export { getAircraftSettings, postAircraftSettings };
