import React, { Component } from "react";
import FilterBox from "../Global Components/FilterBox";
import CmsRow from "./CmsRow";
import "./CmsList.css";

export default class CmsList extends Component {
  constructor(props) {
    super(props);
    this.createRows = this.createRows.bind(this);
    this.handleCodeFilterChange = this.handleCodeFilterChange.bind(this);
    this.handleMessageFilterChange = this.handleMessageFilterChange.bind(this);
  }

  render() {
    return this.handleTableRender(
      this.props.cmsList,
      this.props.selectedCmsFault
    );
  }

  handleTableRender(cmsList, selectedCmsFault) {
    const filteredCmsList = [];
    let faultShowCount = 0;
    cmsList.forEach((fault) => {
      fault.isFilteredRow = this.isFilteredRow(
        fault.faultCode,
        fault.faultMessage
      );
      faultShowCount += fault.isFilteredRow ? 1 : 0;
      filteredCmsList.push(fault);
    });

    const scroll = faultShowCount > 5;

    return (
      <div className="flex-column-simple cms-table">
        <div className="flex-row-simple cms-header">
          <div className="cms-header-code-section cms-header-font">
            <FilterBox
              id={"cms-code-search"}
              placeholderText={"Code"}
              handleFilterChange={this.handleCodeFilterChange}
              customHeight={"42px"}
            />
          </div>
          <div className="cms-header-message-section cms-header-font">
            <FilterBox
              id={"cms-message-search"}
              placeholderText={"Fault Message"}
              handleFilterChange={this.handleMessageFilterChange}
              customHeight={"42px"}
            />
          </div>
          <div
            className={
              "cms-header-font cms-header-timestamp-section" +
              (scroll ? "-with-bar" : "")
            }
          >
            Fault Timestamp
          </div>
        </div>
        {this.createRows(filteredCmsList, selectedCmsFault, scroll)}
      </div>
    );
  }

  // Create Row method
  createRows(filteredCmsList, selectedCmsFault, scroll) {
    let rows = <div />;
    if (filteredCmsList) {
      rows = (
        <div className={scroll ? "cms-container-overflow scrollbar" : null}>
          {filteredCmsList.map((item) => (
            <React.Fragment key={item.id}>
              <CmsRow
                changeFlightSelection={this.props.changeFlightSelection}
                id={item.id}
                color={this.getColor(item.faultSystem)}
                faultCode={item.faultCode}
                faultMessage={item.faultMessage}
                faultDescription={item.faultDescription}
                faultTimestamp={item.faultTimestamp}
                faultSeverity={item.faultSeverity}
                // fdeFaultCode={item.fdeFaultCode}
                // smartfixUrl={item.smartfixUrl}
                rowIsSubtle={this.isSubtleRow(
                  selectedCmsFault,
                  item.faultCode,
                  item.faultTimestamp
                )}
                rowIsFiltered={item.isFilteredRow}
                cmsRowClickEvent={this.props.cmsRowClickEvent}
              />
            </React.Fragment>
          ))}
        </div>
      );
      return rows;
    }
    return <div className="cms-row cms-font cms-wip"> NO DATA AVAILABLE </div>;
  }

  isSubtleRow(selectedCmsFault, cmsMessageFaultCode, cmsMessageFaultTimestamp) {
    let isSubtle = true;

    if (selectedCmsFault) {
      if (
        cmsMessageFaultCode === selectedCmsFault.faultCode &&
        cmsMessageFaultTimestamp === selectedCmsFault.faultTimestamp
      ) {
        isSubtle = false;
      }
    } else {
      isSubtle = false;
    }
    return isSubtle;
  }

  isFilteredRow(faultCode, faultMessage) {
    if (
      faultCode
        .toUpperCase()
        .includes(this.props.cmsFilterText.faultCode.toUpperCase()) &&
      faultMessage
        .toUpperCase()
        .includes(this.props.cmsFilterText.faultMessage.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleCodeFilterChange(textFieldFilterValue) {
    this.props.handleCmsFilterChange("CODE", textFieldFilterValue);
  }

  handleMessageFilterChange(textFieldFilterValue) {
    this.props.handleCmsFilterChange("MESSAGE", textFieldFilterValue);
  }

  getColor(fault) {
    let color = "";
    switch (fault) {
      case "Cabin System":
        color = "light-green";
        break;
      case "Indicating/Recording System":
        color = "light-blue";
        break;
      case "Communications":
        color = "purple";
        break;
      case "Lights":
        color = "yellow";
        break;
      case "Electrical Power":
        color = "green";
        break;
      case "Water/Waste System":
        color = "blue";
        break;
      case "Windows":
        color = "pink";
        break;
      case "Equipment/Furnishing":
        color = "orange";
        break;
      default:
        color = "dark-grey";
        break;
    }
    return color;
  }
}
