import { ListItem } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { SpanIcon, SpanIconCheckedIcon } from '../Global Components/CustomCheckbox'
import { colors } from '../styles/theme'

export const FleetViewDropdown = ({ existingAircraftModel, value, onChange }) => {
    return (
        <Autocomplete
            sx={{
                display: 'inline-block',
                '& input': {
                    color: theme => theme.palette.getContrastText(theme.palette.background.paper)
                },
                "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']": {
                    backgroundColor: 'transparent'
                },
                '& + .MuiAutocomplete-popper .MuiAutocomplete-option.Mui-focused': {
                    backgroundColor: colors.b80
                },
                // change border color
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: colors.b10
                    },
                    '&:hover fieldset': {
                        borderColor: colors.b10
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: colors.b10
                    }
                }
            }}
            disablePortal
            renderTags={tags => <span></span>} // we will show the chips somewhere else
            multiple
            getLimitTagsText={more => `${more} selected`}
            limitTags={0}
            disableCloseOnSelect
            options={existingAircraftModel}
            getOptionLabel={option => option}
            value={value}
            onChange={onChange}
            renderOption={(props, option, state, ownerState) => {
                return (
                    <ListItem
                        {...props}
                        style={{
                            height: 30
                            // if hovered red else transparent
                            // backgroundColor: state.selected ? colors.primary : 'transparent'
                            // backgroundColor: 'transparent'
                        }}
                    >
                        <Checkbox
                            checkedIcon={<SpanIconCheckedIcon />}
                            icon={<SpanIcon />}
                            style={{ marginRight: 8, color: '#D2D2D2' }}
                            checked={state.selected}
                        />
                        {option}
                    </ListItem>
                )
            }}
            style={{
                width: 269
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder='Filter by Aircraft Model'
                    // label={'Aircraft Model'}
                    size={'medium'}
                    variant='outlined'
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            height: '30px',
                            width: '160px'
                        },
                        autoComplete: 'off'
                    }}
                    InputProps={{
                        ...params.InputProps
                    }}
                />
            )}
        />
    )
}
