import React from "react";
import CustomButton from "../Global Components/CustomButton";
import "../styles/Global Components/ConfirmationPopup.css";
import * as tools from "../utils/CommonTools";
import FeatherIcon from "feather-icons-react";

/*
  Global component for request confirmations
  Notes:
    - Currently this confirmation supports the RPD request confirmation which has more specific descriptions 
 */

function ConfirmationPopup({
  active,
  submitOK,
  submitCANCEL,
  descriptionText,
  cancelText,
  acceptText,
  rdpRequestDescription = null

}) {
  return (
    <div style={{ display: active ? "flex" : "none" }}>
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          marginTop: "-20px",
          marginLeft: "-7%",
          marginRight: "-7%",
          zIndex: "400",
          backgroundColor: "rgba(0, 0, 0)",
        }}
      ></div>
      <div className="confirmModalPopup">
        <div className="confirmModalPopup__flag"></div>
        <div className="confirmModalPopup__body">
          <div className="confirmModalPopup__body__description">
            {descriptionText ? descriptionText : "Confirm Operation."}
          </div>
          {rdpRequestDescription ? (
            <div className="confirmModalPopup__rpd__request__body">
              <div
                className="confirmationModalPopup__alertText"
                style={{ marginBottom: "20px" }}
              >
                <FeatherIcon icon={"alert-triangle"} height={20} />
                <span style={{ marginLeft: "10px" }}>
                  {
                    "RPD requests may result in additional charges from your satcom service provider."
                  }
                </span>
              </div>
              <p
                className="confirmModalPopup__rpd__request__message"
                id="rpd_estimated_size"
              >
                <span className="confirmationModalPopup__valueTitle">
                  {"Estimated request size: "}
                </span>
                {tools.convertBytesToReadableBytes(
                  rdpRequestDescription.request["req_response_size"]
                )}
              </p>
              {rdpRequestDescription.type === "CUSTOM" ? (
                <div>
                  <p
                    className="confirmModalPopup__rpd__request__message"
                    id="rpd_custom_time"
                  >
                    <span className="confirmationModalPopup__valueTitle">
                      {"Time of request: "}
                    </span>
                    {rdpRequestDescription.request["req_start_time"] +
                      " ~ " +
                      rdpRequestDescription.request["req_end_time"]}
                  </p>
                  <p
                    className="confirmModalPopup__rpd__request__message"
                    id="rpd_custom_duration"
                  >
                    <span className="confirmationModalPopup__valueTitle">
                      {"Duration of request: "}
                    </span>
                    {rdpRequestDescription.request["req_duration"]}s
                  </p>

                  <p
                    className="confirmModalPopup__rpd__request__message"
                    id="rpd_para"
                  >
                    <span className="confirmationModalPopup__valueTitle">
                      {"Parameters: "}
                    </span>
                  </p>
                  <div className="confirmModalPopup__rpd__request__para__list__border">
                    <ol
                      className="confirmModalPopup__rpd__request__para__list"
                      id="rpd_para_list"
                    >
                      {rdpRequestDescription["parameters"].map(
                        (para, index) => (
                          // using index because this array is static. Unique key will be required if changing to dynamic
                          <li
                            id={"rpd_para_" + index}
                            key={"rpd_para_" + index}
                          >
                            {para["parameterEnglishName"]}
                          </li>
                        )
                      )}
                    </ol>
                  </div>
                </div>
              ) : (
                <div>
                  <p
                    className="confirmModalPopup__rpd__request__message"
                    id="rpd_event_time">
                    <span className="confirmationModalPopup__valueTitle">
                      {"Time of request: "}
                    </span>
                    {rdpRequestDescription.request["req_time"]}
                  </p>
                  <p
                    className="confirmModalPopup__rpd__request__message"
                    id="rpd_event_fault">
                    <span className="confirmationModalPopup__valueTitle">
                      {"Fault code: "}
                    </span>
                    {rdpRequestDescription.request["fault_code"]}
                  </p>
                </div>
              )}
            </div>
          ) : null}

          <div className="confirmModalPopup__body__buttons">
            <CustomButton
              id={"btn-confirmModalPopup-cancel"}
              text={cancelText ? cancelText : "CANCEL"}
              onClickAction={submitCANCEL}
            />
            <CustomButton
              id={"btn-confirmModalPopup-confirm"}
              text={acceptText ? acceptText : "CONFIRM"}
              onClickAction={submitOK}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPopup;
