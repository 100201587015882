import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for CMS session related data.
*/

const CMS_SESSIONS_LIST = Api.CMS_SESSIONS_LIST;
const CMS_IF_MESSAGES = Api.CMS_IF_MESSAGES;

// Returns the list of sessions that ocurred on an aircraft (currently ony Global 7500) during a specific date or session number
async function getCmsSessionList(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  ahmuSessionStartTime,
  ahmuSessionEndTime,
  sessionNumber,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      tailNumber,
      aircraftFamily,
      aircraftModel,
      ahmuSessionStartTime: sessionNumber ? null : ahmuSessionStartTime,
      ahmuSessionEndTime: sessionNumber ? null : ahmuSessionEndTime,
      sessionNumber: sessionNumber,
    },
  };

  try {
    result = await AxiosService(
      CMS_SESSIONS_LIST.url,
      CMS_SESSIONS_LIST.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// CMS In-Flight log messages
async function getCmsIfMessages(
  aircraftFamily,
  aircraftModel,
  tailNumber,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      aircraftFamily,
      aircraftModel,
      tailNumber,
    },
  };

  try {
    result = await AxiosService(
      CMS_IF_MESSAGES.url,
      CMS_IF_MESSAGES.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

export { getCmsSessionList, getCmsIfMessages };
