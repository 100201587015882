import React, { useState } from "react";
import "./Accordion.css";

// Feather icon arrow down
const arrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-down"
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};

// Feather icon arrow up
const arrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-up"
    >
      <polyline points="18 15 12 9 6 15"></polyline>
    </svg>
  );
};

const Accordion = (props) => {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      // If clicked row already active, close it
      return setClicked(null);
    }
    setClicked(index);
  };

  return props.accordionEntries.map((object, index) => (
    <React.Fragment key={"eifrAccordion-" + index}>
      <section className="accordionParent">
        <div className="accordionRowContainer">
          <div
            className="accordionRow"
            onClick={() => toggle(index)}
            key={index}
          >
            <h3 className="accordionText">{object.title}</h3>
            <span className=" accordionSubtitle">{object.subtitle}</span>
            <span
              style={{
                display: "flex",
                order: "3",
                alignSelf: "flex-end",
                width: "10%",
              }}
            >
              {clicked === index ? arrowUp() : arrowDown()}
            </span>
          </div>
        </div>
        {/*Arrow expands */}
        {clicked === index ? (
          <div id="dropDown">
            <pre className="accordionContent">
              <span className="accordionWrap">{object.content}</span>
            </pre>
          </div>
        ) : null}
      </section>
    </React.Fragment>
  ));
};

export default Accordion;
