import * as Api from "../Constants/ApiCatalog";
import AxiosService from "./AxiosService";
import axios, { Axios } from "axios";

const ANNOUNCEMENT_LIST = Api.GET_ANNOUNCEMENT_LIST;

/**
 * Gets the list of announcements
 * @param filters The object containing optional filters (id, fromt_datetime, to_dateframe, status)
 * @param axiosCancelSource The axios cancel source.
 * @return {Promise<AxiosResponse<any>>}
 */
async function getAnnouncementList(
    filters = {},
    axiosCancelSource
) {
    let result;
    const data = {
        params: {
            ...filters
        }
    }

    try {
        result = await AxiosService(
            ANNOUNCEMENT_LIST.url,
            ANNOUNCEMENT_LIST.method,
            data,
            axiosCancelSource
        )
    } catch (error) {
        console.error(error);
    }

    return result;
}

export {
    getAnnouncementList
}