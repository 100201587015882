import moment from "moment";

export function getLollipopChartOptions(
  xMin,
  xMax,
  numberOfTicks,
  lollipopClickEvent,
  colors,
  strokeColors,
  showFaultCode
) {
  const fontSizeDataLabes = "calc(4px + 0.4vw)";
  const fontSizeAxis = "calc(5px + 0.4vw)";
  const fontSizeTooltip = "calc(7px + 0.3vw)";
  const fontSizeNoData = "19px";

  return {
    chart: {
      id: "flight_lollipop",
      group: undefined,
      type: "line",
      background: "#202020",
      fontFamily: "DM Sans",
      height: "100%",
      width: "100%",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          const seriesIndex = config.seriesIndex;
          const dataPointIndex = config.dataPointIndex;
          const seriesName = config.w.globals.seriesNames[seriesIndex];
          const faultTimestamp =
            config.w.globals.seriesX[seriesIndex][dataPointIndex];
          const faultCode =
            config.w.globals.seriesZ[seriesIndex][dataPointIndex].faultCode;
          if (seriesName !== "ENGINE" && seriesName !== "CMS_SESSION") {
            lollipopClickEvent(
              seriesName,
              moment.utc(faultTimestamp).format("YYYY-MM-DDTHH:mm:ss"),
              faultCode
            );
          }
        },
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,
      enabledOnSeries: [],
      offsetX: 0,
      offsetY: -15,
      style: {
        fontSize: fontSizeDataLabes,
        fontFamily: "DM Sans",
        fontWeight: 500,
        colors: ["#202020"],
      },
      background: {
        enabled: true,
        foreColor: "#FFFFFF",
      },
    },
    stroke: {
      show: true,
      curve: "stepline",
      lineCap: "butt",
      colors: undefined,
      width: 0,
      dashArray: 0,
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: [5, 5, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      strokeColors: strokeColors,
      strokeWidth: 1,
      strokeOpacity: 0,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 0,
      offsetX: 0,
      offsetY: 0,
      onDblClick: undefined,
      showNullDataPoints: false,
      hover: {
        sizeOffset: 2,
      },
    },
    fill: {
      type: "solid",
      gradient: {
        shadeIntensity: 0.5,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      show: false,
      tickAmount: 7,
      min: 0,
      max: 7,
      labels: {
        minWidth: 0,
        style: {
          colors: "white",
          fontSize: fontSizeAxis,
          fontFamily: "DM Sans",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    xaxis: {
      type: "datetime",
      tickAmount: numberOfTicks,
      min: moment.utc(xMin).valueOf(),
      max: moment.utc(xMax).valueOf(),
      tickPlacement: "on",
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        offsetX: 6,
        style: {
          colors: "white",
          fontSize: fontSizeAxis,
          fontFamily: "DM Sans",
          cssClass: "apexcharts-xaxis-label",
        },
        formatter(val, timestamp) {
          return moment.utc(timestamp).format("HH:mm:ss");
        },
      },
      axisBorder: {
        show: true,
        color: "#FFFFFF",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#FFFFFF",
        height: 5,
        offsetX: 0,
        offsetY: 0,
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: 0,
          fontFamily: "",
        },
      },
    },
    legend: {
      show: false,
    },
    noData: {
      text: "NO DATA AVAILABLE",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#FFFFFF",
        fontSize: fontSizeNoData,
        fontFamily: "DM Sans",
      },
    },
    annotations: {
      position: "back",
      xaxis: [],
      texts: [],
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: false,
      followCursor: false,
      intersect: true,
      inverseOrder: false,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: fontSizeTooltip,
        fontFamily: "DM Sans",
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true,
        format: "HH:mm:ss",
        formatter(val) {
          return moment.utc(val).format("MMM DD , YYYY HH:mm:ss");
        },
      },
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          if (showFaultCode) {
            return w.globals.seriesZ[seriesIndex][dataPointIndex].faultCode;
          } else {
            const faultCode =
              w.globals.seriesZ[seriesIndex][dataPointIndex].faultCode;
            if (faultCode === "TAKEOFF" || faultCode === "LANDING") {
              return w.globals.seriesZ[seriesIndex][dataPointIndex].faultCode;
            } else {
              return w.globals.seriesZ[seriesIndex][dataPointIndex]
                .faultMessage;
            }
          }
        },
        title: {
          formatter: function (value) {
            // Removing the series name from tooltip if series name is FLIGHT
            return value.toUpperCase() === "FLIGHT" ? null : value.replace("RCOMMON_", "") + "<br>";
          },
        },
      },
      z: {
        formatter: function (value) {
          if (showFaultCode) {
            return value.faultMessage;
          } else {
            return "";
          }
        },
        title: "",
      },
      marker: {
        show: true,
      },
      items: {
        display: "flex",
      },
      fixed: {
        enabled: false,
        position: "topLeft",
        offsetX: 10,
        offsetY: 10,
      },
    },
  };
}
