import { onSubmit } from "../FeedBack Components/src/Feedback/static/feedbackHandler";

const MyCRCProps = {
  isDark: true,
  pageOptions: [
    { label: "Fleet Page", value: "Fleet Page" },
    { label: "Aircraft Page", value: "Aircraft Page" },
    {
      label: "AHMS Page",
      value: "AHMS Page",
    },
    { label: "General Feedback", value: "General Feedback" },
  ],
  ratingOptions: [
    {
      label: "I wish....",
      value: "-1",
      id: "RatingOne",
    },
    {
      label: "I like...",
      value: "1",
      id: "RatingTwo",
    },
    {
      label: "Bug fix...",
      value: "2",
      id: "RatingThree",
    },
  ],
  termsOfUseLink: "https://businessaircraft.bombardier.com/en/terms-use",
  privacyStatementLink: "https://businessaircraft.bombardier.com/en/privacy",
  submitHandler: onSubmit,
};
export default MyCRCProps;
