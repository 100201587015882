import moment from "moment";

export function getLineChartOptions(
  xMin,
  xMax,
  faultTime,
  chartID,
  zoomAllCharts,
  rpdRequestTime,
  type,
  settingZoom,
  discreteTranslation,
  getParameterUnits,
  discreteNonBinaryName
) {
  return {
    tempSeries: [],
    chart: {
      redrawOnWindowResize: true,
      id: chartID,
      group: "social",
      type: "line",
      background: "#101010",
      minHeight: 50,
      maxHeight: 50,
      stacked: false,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        offsetX: -10,
        offsetY: -10,
        export: {
          csv: {
            dateFormatter(timestamp) {
              let newDate = moment
                .utc(timestamp)
                .format("MMM DD YYYY HH:mm:ss");
              return newDate;
            },
          },
        },
      },
      events: {
        zoomed: function (chartContext, { xaxis, yaxis }) {
          if (settingZoom) {
            settingZoom(xaxis.min, xaxis.max);
          }
        },
      },
    },
    colors: [
      "#07B9FC",
      "#FFB300",
      "#EA0034",
      "#9AF000",
      "#ec008c",
      "#0062FF",
      "#33FF00",
      "#FFFE37",
      "#FF6600",
      "#CC00FF",
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 3,
      dashArray: [0, 2, 0, 2, 0, 2, 0, 2, 0, 2],
    },
    annotations: {},
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      borderColor: "#707070",
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    plotOptions: {
      area: {
        fillTo: "origin",
      },
    },
    markers: {
      size: 0,
    },
    title: {
      text: "",
      align: "left",
    },
    fill: {
      type: "fill",
    },
    yaxis: {
      tickAmount: 6,
      labels: {
        minWidth: 75,
        maxWidth: 75,
        offsetX: -13,
        formatter(val) {
          return Math.round((val + Number.EPSILON) * 100) / 100;
        },
        style: {
          colors: type === "Discrete Non-Binary" ? "#101010" : "white",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
      },
      axisBorder: {
        show: true,
        color: "white",
        // offsetX: -12,
      },
      axisTicks: {
        color: "white",
      },
      title: {
        text: "",
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
    },
    xaxis: {
      type: "datetime",
      tickAmount: 9,
      min: moment.utc(xMin).valueOf(),
      max: moment.utc(xMax).valueOf(),
      position: "top",
      tooltip: {
        enabled:
          type === "Discrete Non-Binary" || type === "Discrete" ? false : true,
      },
      labels: {
        offsetX: 0,
        offsetY: -3,
        style: {
          colors: "white",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          cssClass: "apexcharts-xaxis-label",
        },
        rotateAlways: false,
        formatter(val, timestamp) {
          return moment.utc(timestamp).format("HH:mm:ss.SSS");
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      shared: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //Handle tooltip for discrete non-binary
        if (type === "Discrete Non-Binary") {
          let indexOfOnState = 0;

          for (let i = 0; i < series.length; i++) {
            if (series[i][dataPointIndex] === 1) {
              indexOfOnState = i;
              break;
            }
          }

          const stateName = discreteTranslation[
            w.config.series[indexOfOnState].name
          ]
            ? w.config.series[indexOfOnState].discreteTranslation[
            w.config.series[indexOfOnState].name
            ]
            : w.config.series[indexOfOnState].name;

          return (
            '<div style="width:300px; padding: 8px;">' +
            '<div style="width:100%;color:black;font-weight: bolder;">' +
            w.config.series[indexOfOnState].data[dataPointIndex][0] +
            "</div>" +
            '<div style="width:100%; display:flex;; margin: 2px;">' +
            '<div style="">' +
            '<div class="legend-pill" style="background-color: ' +
            w.config.colors[indexOfOnState] +
            '; margin-right: 5px;">' +
            "<span style='color:black;padding-left:20px;'>" +
            stateName +
            "</span>" +
            "</div>" +
            "</div>" +
            "</div>"
          );
        } else if (type === "Discrete") {
          let discreteValueTranslation = w.config.series[0].discreteTranslation;
          if (discreteValueTranslation === null) {
            discreteValueTranslation = {
              0: "false",
              1: "true",
            };
          }
          let seriesItemHTML = "";
          seriesItemHTML =
            seriesItemHTML +
            ('<div style="margin: 2px; height: 50px; width: 450px;">' +
              "<div style='color: black; font-weight: bolder'>" +
              w.config.series[0].data[dataPointIndex][0] +
              "</div>" +
              '<div style="display:flex; flex-direction: column; margin: 2px;">' +
              '<div class="legend-pill" style="background-color: ' +
              w.config.colors[0] +
              '; margin-right: 5px;">' +
              "<span style='color:black;padding-left:20px;'>" +
              w.config.series[0].name +
              "</span>" +
              "<div style='color: black;'>" +
              discreteValueTranslation[
              Math.trunc(w.config.series[0].data[dataPointIndex][1])
              ] +
              "</div>" +
              "</div>" +
              "</div>");
          return '<div style="padding: 8px;">' + seriesItemHTML + "</div>";
        } else {
          let seriesItemHTML = "";
          let unit = "n/a";

          for (let i = 0; i < series.length; i++) {
            let name = w.config.series[i].name;

            if (w.config.series[i].unit) {
              unit = w.config.series[i].unit;
            } else {
              unit = getParameterUnits(name);
            }

            seriesItemHTML =
              seriesItemHTML +
              ('<div style="width:100%; display:flex; align-items:center; margin: 2px;">' +
                '<div class="legend-pill" style="background-color: ' +
                w.config.colors[i] +
                '; margin-right: 5px;">' +
                "</div>" +
                '<span style="color:black;">' +
                name +
                ":" +
                "</span>" +
                '<span style="color: black; font-weight: 800; margin-left: 5px;">' +
                series[i][dataPointIndex] +
                " (" +
                unit +
                ")</span></div>");
          }
          return (
            '<div style="width:auto; padding: 8px;">' +
            seriesItemHTML +
            "</div>"
          );
        }
      },
    },
  };
}
