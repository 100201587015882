import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import {
  getFlightListHistory,
  getAicraftRecentSessionList,
} from "../clients/FlightHistory";
import "../styles/Global Components/Button.css";
import "../styles/Global Components/LoaderContainer.css";
import "./HistoricDataModal.css";
import Spinner from "../Global Components/Spinner.js";
import FeatherIcon from "feather-icons-react";
import CustomButton from "../Global Components/CustomButton";
import HistoricDataTable from "./HistoricDataTable";
import { withRouter } from "react-router";

class HistoricDataModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hdIsLoading: false,
      flightHistory: [],
      recentSessionHistory: [],
      selectedFlight: null,
    };

    this.loadModalHistoricData = this.loadModalHistoricData.bind(this);
    this.handleModalToggle = this.handleModalToggle.bind(this);
    this.hideModalHistoricData = this.hideModalHistoricData.bind(this);
    this.handleHistoricSessionSelection = this.handleHistoricSessionSelection.bind(
      this
    );
    this.handleRecentSessionSelection = this.handleRecentSessionSelection.bind(
      this
    );
    this.handleWindowScrollStatus = this.handleWindowScrollStatus.bind(this);
  }

  componentDidMount() {
    this.loadModalHistoricData();
  }

  async loadModalHistoricData() {
    this.setState({
      hdIsLoading: true,
    });

    let flights = [];
    let recentSessions = [];

    const flightHistoryList = this.processFlightHistoryList(
      this.props.tailNumber,
      this.props.aircraftFamily,
      this.props.aircraftModel,
      this.props.hmuLatestTransfer
    );

    const recentSessionList = this.processRecentSessionList(
      this.props.tailNumber,
      this.props.hmuLatestTransfer,
      this.props.aircraftFamily,
      this.props.aircraftModel
    );

    const historicDataPromiseArray = [flightHistoryList, recentSessionList];
    const historicData = await Promise.all(
      historicDataPromiseArray.map((req) => req)
    );

    flights = historicData[0];
    recentSessions = historicData[1];

    this.setState({
      flightHistory: _.orderBy(flights, ["orderStartTime"], ["desc"]),
      recentSessionHistory: _.orderBy(
        recentSessions,
        ["order", "orderStartTime"],
        ["asc", "desc"]
      ),
      hdIsLoading: false,
    });
  }

  async processFlightHistoryList(
    registrationNumber,
    aircraftFamily,
    aircraftModel,
    hmuLatestTransfer
  ) {
    try {
      const processedFlights = [];
      const processedFlightSessions = [];
      await getFlightListHistory(
        registrationNumber,
        aircraftFamily,
        aircraftModel,
        hmuLatestTransfer
      ).then((history) => {
        // Go over the response flights
        history.data.forEach((flight) => {
          const faultsInfo = _.find(flight.faults, { severity: "N" });
          const faultsAdvisory = _.find(flight.faults, { severity: "A" });
          const faultsCaution = _.find(flight.faults, { severity: "C" });
          const faultsWarning = _.find(flight.faults, { severity: "W" });
          const tempSessionNumber = flight.sessionNumber
            ? flight.sessionNumber
            : "-";
          const tempSessionStartTime = flight.sessionStartTime
            ? moment.utc(flight.sessionStartTime).format("YYYY-MM-DD HH:mm:ss")
            : "-";
          const tempSessionEndTime = flight.sessionEndTime
            ? moment.utc(flight.sessionEndTime).format("YYYY-MM-DD HH:mm:ss")
            : "-";
          const tempDepartureTime = flight.departureTime
            ? moment.utc(flight.departureTime).format("YYYY-MM-DD HH:mm:ss")
            : "-";
          const tempArrivalTime = flight.arrivalTime
            ? moment.utc(flight.arrivalTime).format("YYYY-MM-DD HH:mm:ss")
            : "-";
          const sessionInfo = _.find(flight.sessionFaultCount, {
            severity: "N",
          })
            ? _.find(flight.sessionFaultCount, { severity: "N" }).countTotal
            : 0;
          const sessionAdvisory = _.find(flight.sessionFaultCount, {
            severity: "A",
          })
            ? _.find(flight.sessionFaultCount, { severity: "A" }).countTotal
            : 0;
          const sessionCaution = _.find(flight.sessionFaultCount, {
            severity: "C",
          })
            ? _.find(flight.sessionFaultCount, { severity: "C" }).countTotal
            : 0;
          const sessionWarning = _.find(flight.sessionFaultCount, {
            severity: "W",
          })
            ? _.find(flight.sessionFaultCount, { severity: "W" }).countTotal
            : 0;
          processedFlights.push({
            session: tempSessionNumber,
            sessionStartTime: tempSessionStartTime,
            sessionEndTime: tempSessionEndTime,
            ifrSessionOrder: null,
            departureTime: tempDepartureTime,
            arrivalTime: tempArrivalTime,
            startTime: flight.departureTime
              ? moment.utc(flight.departureTime).format("MMM DD, YYYY - HH:mm")
              : "-",
            endTime: flight.arrivalTime
              ? moment.utc(flight.arrivalTime).format("MMM DD, YYYY - HH:mm")
              : "-",
            airports:
              flight.departureAirportIATA === "SENSITIVE" ||
              flight.arrivalAirportIATA === "SENSITIVE"
                ? "Information Sensitive"
                : (flight.departureAirportIATA
                    ? flight.departureAirportIATA
                    : "-") +
                  " to " +
                  (flight.arrivalAirportIATA ? flight.arrivalAirportIATA : "-"),
            faultsInfoTotal: faultsInfo ? faultsInfo.countTotal : 0,
            faultsAdvisoryTotal: faultsAdvisory ? faultsAdvisory.countTotal : 0,
            faultsCautionTotal: faultsCaution ? faultsCaution.countTotal : 0,
            faultsCautionUncleared: faultsCaution
              ? faultsCaution.countUncleared
              : 0,
            faultsWarningTotal: faultsWarning ? faultsWarning.countTotal : 0,
            faultsWarningUncleared: faultsWarning
              ? faultsWarning.countUncleared
              : 0,
            sessionType: flight.sessionStatus
              ? flight.sessionStatus.toUpperCase()
              : "-",
            missingData: tempSessionNumber === "-",
            rowType: "HISTORY_FLIGHT",
            orderStartTime: tempDepartureTime,
            orderEndTime: tempArrivalTime,
            sessionInfo: sessionInfo,
            sessionAdvisory: sessionAdvisory,
            sessionCaution: sessionCaution,
            sessionWarning: sessionWarning,
          });

          if (
            !_.find(processedFlightSessions, function(flightObject) {
              return (
                flightObject.session === tempSessionNumber &&
                flightObject.sessionStartTime === tempSessionStartTime &&
                flightObject.sessionEndTime === tempSessionEndTime
              );
            })
          ) {
            processedFlightSessions.push({
              session: tempSessionNumber,
              sessionStartTime: tempSessionStartTime,
              sessionEndTime: tempSessionEndTime,
              ifrSessionOrder: null,
              departureTime: null,
              arrivalTime: null,
              startTime: flight.sessionStartTime
                ? moment
                    .utc(flight.sessionStartTime)
                    .format("MMM DD, YYYY - HH:mm")
                : "-",
              endTime: flight.sessionEndTime
                ? moment
                    .utc(flight.sessionEndTime)
                    .format("MMM DD, YYYY - HH:mm")
                : "-",
              airports: "",
              faultsInfoTotal: 0,
              faultsAdvisoryTotal: 0,
              faultsCautionTotal: 0,
              faultsCautionUncleared: 0,
              faultsWarningTotal: 0,
              faultsWarningUncleared: 0,
              sessionType: "SESSION",
              missingData: tempSessionNumber === "-",
              rowType: "HISTORY_SESSION",
              flights: [],
              order: 2,
              orderStartTime: tempSessionStartTime,
              orderEndTime: tempSessionEndTime,
              sessionInfo: sessionInfo,
              sessionAdvisory: sessionAdvisory,
              sessionCaution: sessionCaution,
              sessionWarning: sessionWarning,
            });
          }
        });

        processedFlightSessions.forEach((session) => {
          const foundFlights = _.filter(processedFlights, {
            session: session.session,
            sessionStartTime: session.sessionStartTime,
            sessionEndTime: session.sessionEndTime,
          });

          session.flights = foundFlights;
          session.faultsInfoTotal = foundFlights[0].sessionInfo;
          session.faultsAdvisoryTotal = foundFlights[0].sessionAdvisory;
          session.faultsCautionTotal = foundFlights[0].sessionCaution;
          session.faultsCautionUncleared = _.sumBy(
            foundFlights,
            "faultsCautionUncleared"
          );
          session.faultsWarningTotal = foundFlights[0].sessionWarning;
          session.faultsWarningUncleared = _.sumBy(
            foundFlights,
            "faultsWarningUncleared"
          );

          if (session.orderStartTime === "-" && foundFlights.length > 0) {
            session.orderStartTime = _.orderBy(
              foundFlights,
              ["orderStartTime"],
              ["asc"]
            )[0].orderStartTime;
          }
          if (session.orderEndTime === "-" && foundFlights.length > 0) {
            session.orderEndTime = _.orderBy(
              foundFlights,
              ["orderEndTime"],
              ["asc"]
            )[0].orderEndTime;
          }
        });
      });
      return processedFlightSessions;
    } catch (error) {
      console.error("ERROR: ", error);
      return [];
    }
  }

  async processRecentSessionList(
    tailNumber,
    hmuLatestTransfer,
    aircraftFamily,
    aircraftModel
  ) {
    // Adding a default session when the lates session is an in-flight report
    const processedRecentSessions = [];

    try {
      if (this.props.aircraftUIConfig.aircraftViewPage.hasSitaData) {
        await getAicraftRecentSessionList(
          tailNumber,
          hmuLatestTransfer,
          aircraftFamily,
          aircraftModel,
          null
        ).then((sessions) => {
          let sessionCounter = 0;
          // Go over the response sessions
          sessions.data.forEach((session) => {
            const faultsInfo = _.find(session.faults, { severity: "N" });
            const faultsAdvisory = _.find(session.faults, { severity: "A" });
            const faultsCaution = _.find(session.faults, { severity: "C" });
            const faultsWarning = _.find(session.faults, { severity: "W" });
            const tempSessionStartTime = session.engineStartTime
              ? moment
                  .utc(session.engineStartTime)
                  .format("YYYY-MM-DD HH:mm:ss")
              : "-";
            const tempSessionEndTime = session.engineEndTime
              ? moment.utc(session.engineEndTime).format("YYYY-MM-DD HH:mm:ss")
              : "-";

            const tempArrivalTime = session.arrivalTime
              ? moment.utc(session.arrivalTime).format("MMM DD, YYYY - HH:mm")
              : "-";
            const tempDepartureTime = session.departureTime
              ? moment.utc(session.departureTime).format("MMM DD, YYYY - HH:mm")
              : "-";

            processedRecentSessions.push({
              session:
                "In-Flight Report" +
                (sessionCounter > 0 ? " -" + sessionCounter.toString() : ""),
              sessionStartTime: tempSessionStartTime,
              sessionEndTime: tempSessionEndTime,
              ifrSessionOrder: sessionCounter,
              departureTime: tempArrivalTime,
              arrivalTime: tempDepartureTime,
              startTime: session.engineStartTime
                ? moment
                    .utc(session.engineStartTime)
                    .format("MMM DD, YYYY - HH:mm")
                : "-",
              endTime: session.engineEndTime
                ? moment
                    .utc(session.engineEndTime)
                    .format("MMM DD, YYYY - HH:mm")
                : "-",
              airports:
                session.departureAirportIATA === "SENSITIVE" ||
                session.arrivalAirportIATA === "SENSITIVE"
                  ? "Information Sensitive"
                  : (session.departureAirportIATA
                      ? session.departureAirportIATA
                      : "-") +
                    " to " +
                    (session.arrivalAirportIATA
                      ? session.arrivalAirportIATA
                      : "-"),
              faultsInfoTotal: faultsInfo ? faultsInfo.countTotal : 0,
              faultsAdvisoryTotal: faultsAdvisory
                ? faultsAdvisory.countTotal
                : 0,
              faultsCautionTotal: faultsCaution ? faultsCaution.countTotal : 0,
              faultsCautionUncleared: faultsCaution
                ? faultsCaution.countUncleared
                : 0,
              faultsWarningTotal: faultsWarning ? faultsWarning.countTotal : 0,
              faultsWarningUncleared: faultsWarning
                ? faultsWarning.countUncleared
                : 0,
              sessionType: "SESSION",
              missingData: false,
              rowType:
                session.order === 0 ? "RECENT_SESSION_0" : "RECENT_SESSION",
              order: session.order === 0 ? 0 : 1,
              orderStartTime: tempSessionStartTime,
              orderEndTime: tempSessionEndTime,
            });
            //}

            sessionCounter++;
          });
        });
      }

      return processedRecentSessions;
    } catch (error) {
      console.error("Error: ", error);
      return processedRecentSessions;
    }
  }

  handleModalToggle() {
    if (!this.props.isOpen === true) {
      this.handleWindowScrollStatus(true);
    }
    this.props.handleHistoricDataModalToggle(!this.props.isOpen);
  }

  hideModalHistoricData() {
    this.setState(
      {
        selectedFlight: null,
      },
      () => {
        this.handleWindowScrollStatus(false);
        this.handleModalToggle();
      }
    );
  }

  handleHistoricSessionSelection(
    sessionStartTime,
    sessionEndTime,
    session,
    departureTime,
    arrivalTime,
    sessionType
  ) {
    const isFromUrlNavigation = sessionStorage.getItem("isFromUrlNavigation");
    const flightHistorySessionStartTime =
      moment
        .utc(sessionStartTime)
        .format("YYYY-MM-DDTHH:mm:ss.SSS")
        .toString() + "Z";
    const flightHistorySessionEndTime =
      moment
        .utc(sessionEndTime)
        .format("YYYY-MM-DDTHH:mm:ss.SSS")
        .toString() + "Z";
    const flightSession = session;
    const flightHistoryDepartureTime =
      moment
        .utc(departureTime)
        .format("YYYY-MM-DDTHH:mm:ss.SSS")
        .toString() + "Z";
    const flightHistoryArrivalTime =
      moment
        .utc(arrivalTime)
        .format("YYYY-MM-DDTHH:mm:ss.SSS")
        .toString() + "Z";
    const flightHistorySessionType = sessionType;

    this.props.handleHistoricDataSelection(
      flightHistorySessionStartTime,
      flightHistorySessionEndTime,
      flightSession,
      flightHistoryDepartureTime,
      flightHistoryArrivalTime,
      flightHistorySessionType
    );
    this.hideModalHistoricData();
    if (isFromUrlNavigation) {
      this.props.history.replace({
        pathname: `/aircraftview/${this.props.serialNumber}/${this.props.tailNumber}/${flightSession}`,
      });
    }
  }

  handleRecentSessionSelection(startTime, endTime, ifrSessionOrder) {
    let sessionStartTime = null;
    let sessionEndTime = null;
    const isFromUrlNavigation = sessionStorage.getItem("isFromUrlNavigation");

    if (startTime && endTime) {
      sessionStartTime =
        moment
          .utc(startTime)
          .format("YYYY-MM-DDTHH:mm:ss.SSS")
          .toString() + "Z";

      sessionEndTime =
        moment
          .utc(endTime)
          .format("YYYY-MM-DDTHH:mm:ss.SSS")
          .toString() + "Z";
    }

    if (isFromUrlNavigation) {
      let addExtraParams = true;

      this.props.history.replace({
        pathname: `/aircraftview`,
      });

      sessionStorage.clear();

      this.props.handleRecentSessionSelection(
        sessionStartTime,
        sessionEndTime,
        ifrSessionOrder,
        addExtraParams
      );
    } else {
      this.props.handleRecentSessionSelection(
        sessionStartTime,
        sessionEndTime,
        ifrSessionOrder
      );
    }

    this.hideModalHistoricData();
  }

  handleWindowScrollStatus(isModalOpen) {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }

  render() {
    return (
      <div>
        <div className="historicDataModal__paddings">
          <CustomButton
            id={"historic-data-button"}
            text={"CHOOSE SESSION"}
            buttonStyle={"pill"}
            icon={
              <FeatherIcon
                icon={"arrow-right"}
                height={16}
                width={16}
                color={"#FFFFFF"}
              />
            }
            iconPosition={"end"}
            onClickAction={this.handleModalToggle}
          />
        </div>
        <div
          id="historic_modal"
          className={
            "historicDataModal__popup historicDataModal__popup__isOpen-" +
            (this.props.isOpen ? "true" : "false")
          }
        >
          <div className="historicDataModal historicDataModal__font">
            <div className="historicDataModal__header historicDataModal__font__header">
              <span>
                {"SESSION " +
                  (this.props.selectedSessionNumber
                    ? this.props.selectedSessionNumber
                    : "In-Flight Report")}
              </span>
              <CustomButton
                id={"btn-historic-data-close"}
                buttonStyle={"pill"}
                text={"BACK"}
                colorStyle={"secondary"}
                onClickAction={this.hideModalHistoricData}
              />
            </div>

            <div className="historicDataModal__body">
              {this.hanldeHistoryDataTable()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  hanldeHistoryDataTable() {
    if (this.state.hdIsLoading) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(0,0,0,.5)",
            zIndex: "2000",
          }}
        >
          <div style={{ width: "100px", paddingTop: "20%", margin: "auto" }}>
            <Spinner />
          </div>
        </div>
      );
    } else {
      return (
        <HistoricDataTable
          recentSessionHistory={this.state.recentSessionHistory}
          flightHistory={this.state.flightHistory}
          handleHistoricSessionSelection={this.handleHistoricSessionSelection}
          handleRecentSessionSelection={this.handleRecentSessionSelection}
          selectedSessionStartTime={this.props.selectedSessionStartTime}
          selectedSessionEndTime={this.props.selectedSessionEndTime}
          selectedSessionNumber={this.props.selectedSessionNumber}
        />
      );
    }
  }
}

export default withRouter(HistoricDataModal);
