import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "./RPDRangeSelector.css";
import moment from "moment";
import CustomButton from "../Global Components/CustomButton";

/**
 * RPD Range Selector component.
 * @param startTime
 * @param endTime
 * @param handleTabChange
 * @param isRPDEventRequestAvailable
 * @param faultTimestamp
 * @param defaultRequestDuration
 * @param handleRangeValuesChange
 * @param handleInputChange
 * @return {JSX.Element}
 * @constructor
 */
const RPDRangeSelector = ({
  startTime,
  endTime,
  handleTabChange,
  isRPDEventRequestAvailable,
  faultTimestamp,
  defaultRequestDuration,
  handleRangeValuesChange,
  handleInputChange
}) => {
  const [rangeTabs, setRangeTabs] = useState(["Range", "Snapshot", "Event"])
  const [fromTimestampLabel, setFromTimestampLabel] = useState("");
  const [toTimestampLabel, setToTimestampLabel] = useState("");
  const [requestDuration, setRequestDuration] = useState(110);
  const [requestDate, setRequestDate] = useState("");
  const [requestTime, setRequestTime] = useState("");
  const [selectedTab, setSelectedTab] = useState("Range");
  const [departureDate, setDepartureDate] = useState(new moment());
  const [currentDate, setCurrentDate] = useState(new moment());
  const [durationInputDisabled, setDurationInputDisabled] = useState(false);
  const [timestampInputDisabled, setTimestampInputDisabled] = useState(false);
  const [departureLabel, setDepartureLabel] = useState("");
  const [currentTimeLabel, setCurrentTimeLabel] = useState("");
  const [defaultRequestDate, setDefaultRequestDate] = useState("");
  const [defaultRequestTime, setDefaultRequestTime] = useState("");
  const [minRequestDate, setMinRequestDate] = useState(new moment());
  const [maxRequestDate, setMaxRequestDate] = useState(new moment());

  /**
   * Starts the range selector with the default values.
   */
  useEffect(() => {
    const departure = moment(startTime);
    const minRequestD = moment(startTime).subtract(1, "seconds");
    const current = moment(endTime);
    const maxRequestD = moment(endTime).add(1, "seconds");
    setMinRequestDate(minRequestD);
    setDepartureDate(departure);
    setMaxRequestDate(maxRequestD);
    setCurrentDate(current);
    setRequestDate(current.format("MMM DD, YYYY"));
    setRequestTime(current.format("HH:mm:ss"));
    setDepartureLabel(departure.format("MMM DD, YYYY - HH:mm:ss"));
    setCurrentTimeLabel(current.format("MMM DD, YYYY - HH:mm:ss"));
    setDefaultRequestDate(current.format("MMM DD, YYYY"));
    setDefaultRequestTime(current.format("HH:mm:ss"));
  }, [startTime]);

  useEffect(() => {
    setRequestDuration(defaultRequestDuration);
  }, [defaultRequestDuration])

  /**
   *
   */
  useEffect(() => {
    if (!isRPDEventRequestAvailable) {
      const faultTabs = ["Range", "Snapshot"];
      setRangeTabs(faultTabs);
    }
  }, [isRPDEventRequestAvailable])

  useEffect(() => {

  }, [requestDuration, requestDate, requestTime]);

  /**
   * Updates the selector with the default values depending on the selected tab.
   */
  useEffect(() => {
    switch (selectedTab) {
      case "Range":
        setDurationInputDisabled(false);
        setTimestampInputDisabled(false);
        setRequestDuration(110);
        handleInputChange();
        handleTabChange("Range");
        break;
      case "Snapshot":
        setDurationInputDisabled(true);
        setTimestampInputDisabled(false);
        setRequestDuration(2);
        handleInputChange();
        handleTabChange("Snapshot");
        break;
      case "Event":
        setDurationInputDisabled(true);
        document.getElementById("range__date__input").value = new moment(faultTimestamp).format("MMM DD, YYYY");
        document.getElementById("range__time__input").value = new moment(faultTimestamp).format("HH:mm:ss");
        setTimestampInputDisabled(true);
        handleOnDurationChange(0);
        handleRangeValueChange();
        handleTabChange("Event");
        break;
    }
  }, [selectedTab])


  /**
   * Sets the request duration.
   * @param seconds Request duration in seconds.
   */
  const handleOnDurationChange = (seconds) => {
    setRequestDuration(seconds);
    handleInputChange();
  }

  /**
   * Reads the range value and updates the other properties accordingly.
   */
  const handleRangeValueChange = () => {
    const dateValue = document.getElementById("range__date__input").value;
    const timeValue = document.getElementById("range__time__input").value;
    let durationValue = requestDuration;

    durationValue = durationValue < 1 ? 1 : durationValue > 110 ? 110 : durationValue;
    const newDate = moment(`${dateValue} ${timeValue}`, "MMM DD, YYYY HH:mm:ss");
    if (newDate.isValid() && newDate.isBetween(minRequestDate, maxRequestDate)) {
      const fromDate = moment(`${dateValue} ${timeValue}`, "MMM DD, YYYY HH:mm:ss");
      let toDate = moment(`${dateValue} ${timeValue}`, "MMM DD, YYYY HH:mm:ss").add(durationValue, 'seconds');
      let fromLabel = fromDate.format("MMM DD, YYYY HH:mm:ss");
      let toLabel = "";
      if (fromDate.dayOfYear() !== toDate.dayOfYear()) {
        toLabel = toDate.format("MMM DD, YYYY HH:mm:ss");
      } else {
        toLabel = toDate.format("HH:mm:ss");
      }

      setFromTimestampLabel(fromLabel);
      setToTimestampLabel(toLabel);
      const selectionContainerWidth = document.getElementById("range__view__container").offsetWidth;
      const rangeViewSelectionElement = document.getElementById("range__view__selection");
      const unitWidth = selectionContainerWidth / 130;
      const finalWidth = durationValue * unitWidth;

      rangeViewSelectionElement.style.width = `${finalWidth}px`;
      setRequestTime(timeValue);
      setRequestDate(dateValue);
      setRequestDuration(durationValue);
      handleRangeValuesChange(newDate, durationValue);
    } else {
      const fromDate = moment(`${requestDate} ${requestTime}`, "MMM DD, YYYY HH:mm:ss");
      document.getElementById("range__date__input").value = requestDate;
      document.getElementById("range__time__input").value = requestTime;
      handleRangeValuesChange(fromDate, requestDuration);
    }
  }

  return (
    <div className="rpdView__rangeSelector__container">
      <Grid
        container
        spacing={0}>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={0}
          xs={12}>
          {rangeTabs.map((tab) => (
            <React.Fragment key={tab}>
              <span
                className={
                  selectedTab === tab
                    ? "range__selector__tab range__selector__tab__selected"
                    : "range__selector__tab"
                }
                onClick={() => {
                  setSelectedTab(tab);
                }}>
                {tab}
              </span>
            </React.Fragment>
          ))}
        </Grid>

        <Grid item xs={6}>
          <p className="range__selector__input__label">
            Choose Request Timestamp:
          </p>
          <input
            defaultValue={defaultRequestDate}
            type="text"
            className="range__selector__input"
            placeholder={defaultRequestDate}
            id="range__date__input"
            disabled={timestampInputDisabled}
            onChange={() => handleInputChange()}
          />
          <input
            defaultValue={defaultRequestTime}
            type="text"
            className="range__selector__input"
            placeholder={defaultRequestTime}
            id="range__time__input"
            disabled={timestampInputDisabled}
            onChange={() => handleInputChange()}
          />
          <p className="range__selector__input__hint">Range: {fromTimestampLabel} - {toTimestampLabel}</p>
        </Grid>

        <Grid item xs={3}>
          <p className="range__selector__input__label">
            Duration:
          </p>
          <input type="number"
            className="range__selector__input range__selector__input__seconds"
            placeholder="110"
            id="range__seconds__input"
            disabled={durationInputDisabled}
            value={requestDuration}
            onChange={(event) => handleOnDurationChange(event.target.value)}
          />
          <span className="range__selector__input__suffix">
            seconds
          </span>
          <p className="range__selector__input__hint">110 seconds maximum</p>
        </Grid>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={2}>
          <CustomButton
            id={"btn-applyRangeChanges"}
            text={"APPLY"}
            colorStyle={"custom"}
            customButtonColor={"#ffc72c"}
            customButtonTextColor={"#000000"}
            onClickAction={handleRangeValueChange}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="range__view__container" id="range__view__container">
            <div className="range__view__selection" id="range__view__selection">
            </div>
          </div>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction={"row"}
          justifyContent={"space-evenly"}
          alignItems={"center"}>
          <Grid item container xs={12} spacing={0} direction={"row"} alignItems={"flex-start"}>
            <Grid
              item
              xs={6}
              container
              direction={"column"}
              justifyContent={"space-evenly"}
              alignItems={"flex-start"}
              style={{
                paddingTop: "25px"
              }}>
              <p style={{
                fontSize: "12px",
                color: "#909090"
              }}>DEPARTURE TIME</p>
              <p style={{
                fontSize: "15px"
              }}>{departureLabel}</p>
            </Grid>
            <Grid
              item
              xs={6}
              container
              direction={"column"}
              justifyContent={"space-evenly"}
              alignItems={"flex-start"}
              style={{
                paddingTop: "25px"
              }}>
              <p style={{
                fontSize: "12px",
                color: "#909090"
              }}>CURRENT TIME</p>
              <p style={{
                fontSize: "15px"
              }}>{currentTimeLabel}</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default RPDRangeSelector;