import React from "react";
import FeatherIcon from "feather-icons-react";

import "./PredictiveTooltip.css"

const PredictiveTooltip = (props) => {
    const {
        element,
        text,
        wide,
        fitted,
        centered,
        lifted,
        icon
    } = props;
    return (
        <div
            className={
                `predictive-tooltip 
                ${icon ? " predictive-tooltip--space-left" : ""}`
            }
            data-testid="tooltip-container"
        >
            {element}
            <span
                className={
                    `tooltiptext
                    ${wide ? " tooltiptext--wide" : ""}
                    ${centered ? " tooltiptext--centered" : ""}
                    ${lifted ? " tooltiptext--lifted" : ""}`
                }
                style={fitted && { width: fitted }}
            >
                {text}
            </span>
        </div>
    )
};

PredictiveTooltip.defaultProps = {
    element: "",
    text: "",
    wide: false,
    centered: false,
    lifted: false,
    icon: false
};

export default PredictiveTooltip;
