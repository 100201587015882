import React from "react";
import Select from "react-select";
import "./GlobalDropdown.css";

/**
 * The Global reusable dropdown component.
 * @param options Options to be rendered.
 * @param defaultValue The default value.
 * @param onChange The onchange handler.
 * @param placeholder The input placeholder.
 * @param label The input label.
 * @return {JSX.Element}
 * @constructor
 */
export default function GlobalDropdown({
  options,
  defaultValue,
  onChange,
  placeholder,
  label,
  disabled,
  optionsAreObjects,
  isSearchable,
  height
}) {
  const customStyles = {
    control: (base, { isDisabled, isFocused }) => ({
      ...base,
      backgroundColor: isDisabled ? "#3C3C3C" : "transparent",
      border: isDisabled ? "2px solid #686969" : "2px solid #949494",
      color: isDisabled ? "#9C9C9C" : "#FFFFFF",
      height: height ? `${height}px`: "48px",
      "&:hover": {
        border: "2px solid #AFD4FF",
      },
      "&:focus": {
        border: "2px solid #949494 !important",
      },
      cursor: "pointer",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "inherit",
      fontSize: "16px",
    }),
    indicatorSeparator: (base, { isDisabled }) => ({
      ...base,
      marginBottom: "0px",
      marginTop: "0px",
      width: "2px",
      backgroundColor: isDisabled ? "#686969" : "#949494",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "white",
      "&:hover": {
        color: "white",
      },
      width: height ? `${height-5}px`: "43px",
      height: height ? `${height-5}px`: "43px",
      padding: height ? `${(height-20)/2}px`: "12px",
    }),
    placeholder: (base, state) => ({
      ...base,
      color: "#949494",
    }),
    menu: (base, {}) => ({
      ...base,
      padding: "0",
      margin: "0",
      borderRadius: "5px",
      zIndex: "500",
      border: "1px solid #949494",
    }),
    menuList: (base, {}) => ({
      ...base,
      padding: "0",
      margin: "0",
      borderRadius: "5px",
      zIndex: "500",
    }),
    option: (base, { isDisabled, isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#FFBB29"
        : isFocused
        ? "#D9D9D9"
        : "#2F2F2F",
      color: isSelected || isFocused ? "#000" : "#FFF",
      height: "50px",
      lineHeight: "33px",
      fontWeight: "500",
      cursor: "pointer",
      borderTop: "1px solid #949494",
    }),
    input: (base) => ({
      ...base,
      color: "#FFFFFF",
    }),
  };

  return (
    <>
      {label && label !== "" && (
        <p className={"global__dropdown__label"}>{label}</p>
      )}
      <Select
        classNamePrefix={"global__dropdown"}
        options={
          optionsAreObjects
            ? options
            : options.map((t) => ({ value: t, label: t }))
        }
        value={
          defaultValue && optionsAreObjects
            ? defaultValue
            : defaultValue && !optionsAreObjects
            ? { label: defaultValue, value: defaultValue }
            : { label: placeholder, value: 0 }
        }
        placeholder={placeholder}
        onChange={onChange}
        styles={customStyles}
        isSearchable={isSearchable}
        isDisabled={disabled}
      />
    </>
  );
}
