import React from "react";

import "./InfoBoxRow.css";

const InfoBoxRow = (props) => {
    const {
        name,
        value,
        width = 100
    } = props

    return (
        <div className="infoBox__body__row"
            style={{ width: `${width}%` }}
        >
            <div className="infoBox__body__rowLeft">{name}</div>
            <div className="infoBox__body__rowRight">{value}</div>
        </div>
    )
};

export default InfoBoxRow;