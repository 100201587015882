import React from "react";
import "../styles/Global Components/CrcModal.css";
import FeatherIcon from "feather-icons-react";
import CustomButton from "../Global Components/CustomButton";
import { StyledDropzone } from "../Global Components/Dropzone";
import SaveTemplate from "../Charting Components/Ahms Templates Components/saveTemplate";

export default function CrcModal(props) {
  const [isOpen, setIsOpen] = React.useState(false);

  // method to open modal
  const openModal = async () => {
    if (props.id === "load-template-modal") {
      props.getTemplateList();
    }
    let modal = document.getElementById(props.id);
    setIsOpen(true);
    modal.style.display = "flex";
  };

  // method to close modal
  const closeModal = () => {
    setIsOpen(false);
    let modal = document.getElementById(props.id);
    modal.style.display = "none";
  };

  const submit = () => {
    props.getTemplateCharts();
    let modal = document.getElementById(props.id);
    modal.style.display = "none";
  };

  const createHeader = () => {
    if (props.modalTitle) {
      return (
        <div className="crc-modal-header crc-modal-header-bg">
          <div className="crc-modal-title">{"Update Personal Template"}</div>
          <div
            style={{
              width: "100%",
              height: "40px",
              color: "white",
              paddingLeft: "5%",
              paddingTop: "4px",
              fontWeight: "bold",
              fontSize: "12px",
              lineHeight: "18px",
            }}
          >
            Overwrite existing personal template as a New Version of the same
            template.
          </div>
        </div>
      );
    } else {
      return <div className="crc-modal-header"></div>;
    }
  };

  const createBody = () => {
    if (props.id === "load-template-modal") {
      return (
        <div>
          {props.content}
          <div className="crc-modal-submit">
            <div className="submit-modal">
              <CustomButton
                id={"submit-modal"}
                text="Submit"
                onClickAction={submit}
              />
            </div>
          </div>
          <div className="crc-add-and-drop">
            <StyledDropzone handleUploadLocalFile={handleUploadLocalFile} />
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ height: "100%" }}>
          {isOpen === true ? (
            <SaveTemplate
              templateType={props.openBtnLabel === "Update" ? "UPDATE" : "SAVE"}
              aircraftFamily={props.aircraftFamily}
              aircraftModel={props.aircraftModel}
              aircraftUIConfig={props.aircraftUIConfig}
              ataChapterList={props.ataChapterList}
              omsFaultCodes={props.omsFaultCodes}
              faultCode={props.faultCode}
              saveTemplateLocal={props.saveTemplateLocal}
              closeModal={closeModal}
              templateParameters={props.templateParameters}
              reloadTemplates={props.reloadTemplates}
              loadedTemplate={props.loadedTemplate}
              dataFocus={props.dataFocus}
            />
          ) : (
            <div></div>
          )}
        </div>
      );
    }
  };

  const handleUploadLocalFile = (acceptedFile) => {
    props.uploadLocalTemplateAddAndDrop(acceptedFile[0]);
    closeModal();
  };

  return (
    <div>
      <CustomButton
        id={"open-modal"}
        text={props.openBtnLabel}
        icon={
          props.alert ? (
            props.templateExists ? (
              <div className="crc-modal-btn-alert"></div>
            ) : (
              <FeatherIcon
                icon={"check"}
                height={20}
                className="crc-modal-btn-icon"
              />
            )
          ) : (
            props.icon && (
              <FeatherIcon
                icon={props.icon}
                height={20}
                className="crc-modal-btn-icon"
              />
            )
          )
        }
        iconPosition={"start"}
        borderRadiusStyle={"squared"}
        customWidth={"170"}
        customHeight={"50"}
        colorStyle="secondary"
        onClickAction={openModal}
      />
      <div id={props.id} className="crc-modal">
        <div className="crc-modal-content">
          <div className="crc-modal-body">{createBody()}</div>
        </div>
      </div>
    </div>
  );
}
