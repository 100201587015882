import React, { forwardRef, useMemo } from 'react'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Clear from '@mui/icons-material/Clear'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import FirstPage from '@mui/icons-material/FirstPage'
import LastPage from '@mui/icons-material/LastPage'
import Search from '@mui/icons-material/Search'
import CustomCheckbox from '../../Global Components/CustomCheckbox.jsx'
import { createTheme } from '@mui/material/styles'
import FeatherIcon from 'feather-icons-react'
import { v4 as uuidv4 } from 'uuid'
import Select from 'react-select'
import CustomTooltip from '../../Global Components/CustomTooltip'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

// Function to construct template table based on tab
export function createTemplateColumnDef(
    openPreviewTemplateModal,
    toggleTemplatePreviewModal,
    tab,
    updateTemplateStatus,
    toggleTemplateModal,
    duplicateTemplate,
    templatePermissions,
    loadTemplate,
    groupedTemplates,
    changeTemplateVersion,
    dataFocus
) {
    const customStyles = {
        control: (base, state) => ({
            ...base,
            backgroundColor: '#303030',
            border: 0,
            boxShadow: 0,
            '&:hover': {
                border: 0
            }
        }),
        indicatorSeparator: (base, state) => ({
            ...base,
            marginBottom: '0px',
            marginTop: '0px',
            width: '1.5px'
        }),

        dropdownIndicator: (base, state) => ({
            ...base,
            color: 'white',
            '&:hover': {
                color: 'black'
            },
            '&:active': {
                color: 'black'
            },
            width: '43px',
            height: '43px',
            padding: '12px'
        }),

        container: (base, state) => ({
            ...base,
            width: '100px',
            height: '45px',
            borderColor: 'white',
            //borderRadius: "2px",
            borderStyle: 'solid',
            borderWidth: '1px'
        }),
        menu: (base, state) => ({
            ...base,
            backgroundColor: '#303030',
            color: 'white'
        }),
        placeholder: (base, state) => ({
            ...base,
            color: 'white'
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: '#303030',
            borderBottom: '1px solid white',
            borderLeft: '1px solid white',
            borderRight: '1px solid white',
            color: state.isFocused ? 'white' : '', //empty since it will depend on the theme (light: color will be white)
            '&:hover': {
                color: 'black',
                backgroundColor: 'white'
            },
            '&:active': {
                color: 'black',
                backgroundColor: 'white'
            }
        }),
        singleValue: (base, state) => ({
            ...base,
            color: '#FFFFFF'
        })
    }

    // Setting up default Tabs
    const hasPublicPolicyExternal = templatePermissions?.templatePublicPolicyExternal
    const hasPublicPolicyInternal = templatePermissions?.templatePublicPolicyInternal

    let defaultColumns = [
        {
            title: 'Template Name',
            cellStyle: {
                padding: '8px',
                fontSize: '16px',
                width: '35%',
                fontWeight: 'normal',
                lineHeight: '18px'
            },
            field: 'templateName',
            filterPlaceholder: 'Template name',
            render: rowData => (
                <div>
                    <div>{rowData.templateName}</div>
                </div>
            )
        },
        {
            title: 'ATA',
            cellStyle: {
                padding: '6px',
                fontSize: '14px',
                textAlign: 'center',
                width: '10%'
            },
            field: 'ataChapter',
            filterPlaceholder: 'ATA'
        },
        {
            title: 'Template Type',
            cellStyle: {
                padding: '8px',
                width: '15%'
            },
            field: 'faultCode',
            filterPlaceholder: 'Type',
            render: rowData => <div>{rowData.faultCode}</div>
        },
        {
            title: 'OMS',
            cellStyle: {
                padding: '8px',
                width: '15%'
            },
            filterPlaceholder: 'OMS',
            field: 'faultCode',
            render: rowData => (
                <div style={{ width: '100%' }}>
                    {rowData.omsFaultCodes.map((fault, index) => (
                        <React.Fragment key={uuidv4()}>
                            <div style={{ width: '100%' }}>{fault}</div>
                        </React.Fragment>
                    ))}
                </div>
            )
        },
        {
            title: 'Created by',
            cellStyle: {
                padding: '8px',
                width: '15%'
            },
            field: 'createdBy',
            filterPlaceholder: 'Created by'
        },
        {
            filtering: false,
            maxWidth: '80px',
            minWidth: '80px',
            width: '80px',
            title: 'Policy',
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    {rowData.templatePrivacyPolicy === 'PRIVATE' && (
                        <CustomTooltip
                            content={'Private Template'}
                            tooltipType={'general'}
                            placement={'top'}
                        >
                            <FeatherIcon icon='user' height={24} />
                        </CustomTooltip>
                    )}
                    {rowData.templatePrivacyPolicy === 'INT_PUBLIC' && (
                        <CustomTooltip
                            content={'Internally Shared Template'}
                            tooltipType={'general'}
                            placement={'top'}
                        >
                            <FeatherIcon icon='users' height={24} />
                        </CustomTooltip>
                    )}
                    {rowData.templatePrivacyPolicy === 'EXT_PUBLIC' && (
                        <CustomTooltip
                            content={'Externally Shared Template'}
                            tooltipType={'general'}
                            placement={'top'}
                        >
                            <FeatherIcon icon='star' width={24} height={24} />
                        </CustomTooltip>
                    )}
                </div>
            )
        },
        {
            filtering: false,
            maxWidth: '110px',
            minWidth: '110px',
            width: '110px',
            title: 'Version',
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    {groupedTemplates[rowData.fromTemplateId].length > 1 ? (
                        <Select
                            maxMenuHeight={200}
                            styles={customStyles}
                            options={groupedTemplates[rowData.fromTemplateId]
                                .sort((a, b) => b.templateVersion - a.templateVersion)
                                .map(t => {
                                    return { label: t.templateVersion, value: t.templateId }
                                })}
                            value={{
                                label: rowData.templateVersion,
                                value: rowData.templateId
                            }}
                            onChange={value => {
                                changeTemplateVersion(
                                    tab,
                                    value,
                                    rowData.fromTemplateId,
                                    rowData.templateId
                                )
                            }}
                        />
                    ) : (
                        <div>{'v' + rowData.templateVersion}</div>
                    )}
                </div>
            )
        },
        {
            maxWidth: '60px',
            minWidth: '60px',
            width: '60px',
            title: 'Preview',
            filtering: false,
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    <CustomTooltip
                        content={'Preview Template'}
                        tooltipType={'general'}
                        placement={'top'}
                    >
                        <div
                            style={{
                                height: '40px',
                                width: '40px',
                                borderRadius: '5px',
                                backgroundColor: '#303030',
                                padding: '6px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                openPreviewTemplateModal(rowData)
                                // displayTemplateContents(rowData);
                            }}
                        >
                            <FeatherIcon
                                icon='eye'
                                width={28}
                                height={28}
                                className='template__exist__icon'
                            />
                        </div>
                    </CustomTooltip>
                </div>
            )
        }
    ]

    if (tab !== 'Archive') {
        defaultColumns.push({
            maxWidth: '60px',
            minWidth: '60px',
            width: '60px',
            title: 'Load',
            filtering: false,
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    <CustomTooltip
                        content={'Load Template'}
                        tooltipType={'general'}
                        placement={'top'}
                    >
                        <div
                            style={{
                                height: '40px',
                                width: '40px',
                                borderRadius: '5px',
                                backgroundColor: '#3F51B5',
                                padding: '6px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                loadTemplate(rowData)
                            }}
                        >
                            <FeatherIcon
                                icon='arrow-right'
                                width={28}
                                height={28}
                                className='template__exist__icon'
                            />
                        </div>
                    </CustomTooltip>
                </div>
            )
        })
    }

    if (tab === 'Shared Templates') {
        defaultColumns.push({
            maxWidth: '60px',
            minWidth: '60px',
            width: '60px',
            title: 'Copy',
            filtering: false,
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    <CustomTooltip
                        content={'Duplicate Template'}
                        tooltipType={'general'}
                        placement={'top'}
                    >
                        <div
                            style={{
                                height: '40px',
                                width: '40px',
                                borderRadius: '5px',
                                backgroundColor: '#9C27B0',
                                padding: '6px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                toggleTemplateModal(rowData, 'copied')
                            }}
                        >
                            <FeatherIcon
                                icon='copy'
                                width={28}
                                height={28}
                                className='template__exist__icon'
                            />
                        </div>
                    </CustomTooltip>
                </div>
            )
        })

        if (hasPublicPolicyExternal || hasPublicPolicyInternal) {
            defaultColumns.push({
                maxWidth: '60px',
                minWidth: '60px',
                width: '60px',
                title: 'Archive',
                filtering: false,
                cellStyle: { padding: '8px' },
                field: '',
                render: rowData => (
                    <div>
                        {((rowData.templatePrivacyPolicy === 'INT_PUBLIC' &&
                            hasPublicPolicyInternal) ||
                            (rowData.templatePrivacyPolicy === 'EXT_PUBLIC' &&
                                hasPublicPolicyExternal)) && (
                            <CustomTooltip
                                content={'Archive Template'}
                                tooltipType={'general'}
                                placement={'top'}
                            >
                                <div
                                    style={{
                                        height: '40px',
                                        width: '40px',
                                        borderRadius: '5px',
                                        backgroundColor: '#DD5249',
                                        padding: '6px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        toggleTemplateModal(rowData, 'archived')
                                    }}
                                >
                                    <FeatherIcon
                                        icon='archive'
                                        width={28}
                                        height={28}
                                        className='template__exist__icon'
                                    />
                                </div>
                            </CustomTooltip>
                        )}
                    </div>
                )
            })
        }
    }

    if (tab === 'Archive' && (hasPublicPolicyExternal || hasPublicPolicyInternal)) {
        defaultColumns.push({
            maxWidth: '60px',
            minWidth: '60px',
            width: '60px',
            title: 'Restore',
            filtering: false,
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    <CustomTooltip
                        content={'Restore Template'}
                        tooltipType={'general'}
                        placement={'top'}
                    >
                        <div
                            style={{
                                height: '40px',
                                width: '40px',
                                borderRadius: '5px',
                                backgroundColor: '#4CAF50',
                                padding: '6px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                toggleTemplateModal(rowData, 'upload')
                            }}
                        >
                            <FeatherIcon
                                icon='upload'
                                width={28}
                                height={28}
                                className='template__exist__icon'
                            />
                        </div>
                    </CustomTooltip>
                </div>
            )
        })
    }

    if (tab === 'My Templates' || tab === 'Archive') {
        defaultColumns.push({
            maxWidth: '60px',
            minWidth: '60px',
            width: '60px',
            title: 'Delete',
            filtering: false,
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    {((rowData.templatePrivacyPolicy === 'INT_PUBLIC' && hasPublicPolicyInternal) ||
                        (rowData.templatePrivacyPolicy === 'EXT_PUBLIC' &&
                            hasPublicPolicyExternal) ||
                        tab === 'My Templates') && (
                        <CustomTooltip
                            content={'Delete Template'}
                            tooltipType={'general'}
                            placement={'top'}
                        >
                            <div
                                style={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '5px',
                                    backgroundColor: '#FF5050',
                                    padding: '6px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    toggleTemplateModal(rowData, 'deleted', tab)
                                }}
                            >
                                <FeatherIcon
                                    icon='trash-2'
                                    width={28}
                                    height={28}
                                    className='template__exist__icon'
                                />
                            </div>
                        </CustomTooltip>
                    )}
                </div>
            )
        })
    }

    if (dataFocus === 'CMS') {
        defaultColumns.splice(2, 1)
    }

    return defaultColumns
}

export function getMaterialReactTemplateColumnDef(factory, deps) {
    return useMemo(
        () => [
            {
                accessorKey: 'templateName',
                header: 'Template Name'
            },
            {
                accessorKey: 'ataChapter',
                header: 'ATA',
                size: 100
            },
            {
                accessorKey: 'faultCode',
                header: 'Fault Code'
            },
            {
                accessorKey: 'createdBy',
                header: 'Created by'
            },
            {
                accessorFn: row =>
                    row.templatePrivacyPolicy === 'PRIVATE'
                        ? 'Private'
                        : row.templatePrivacyPolicy === 'INT_PUBLIC'
                        ? 'Public Internal'
                        : 'Public External',
                id: 'privacyPolicy',
                header: 'Privacy Policy',
                size: 100,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            justifyContent: 'center'
                        }}
                    >
                        {row.original.templatePrivacyPolicy === 'PRIVATE' && (
                            <CustomTooltip
                                content={'Private Template'}
                                tooltipType={'general'}
                                placement={'top'}
                            >
                                <div>
                                    <FeatherIcon icon='user' height={24} />
                                </div>
                            </CustomTooltip>
                        )}
                        {row.original.templatePrivacyPolicy === 'INT_PUBLIC' && (
                            <CustomTooltip
                                content={'Internally Shared Template'}
                                tooltipType={'general'}
                                placement={'top'}
                            >
                                <div>
                                    <FeatherIcon icon='users' height={24} />
                                </div>
                            </CustomTooltip>
                        )}
                        {row.original.templatePrivacyPolicy === 'EXT_PUBLIC' && (
                            <CustomTooltip
                                content={'Externally Shared Template'}
                                tooltipType={'general'}
                                placement={'top'}
                            >
                                <div>
                                    <FeatherIcon icon='star' width={24} height={24} />
                                </div>
                            </CustomTooltip>
                        )}
                    </Box>
                )
            },
            {
                accessorFn: row => {
                    ;`v${row.templateVersion}`
                },
                id: 'version',
                header: 'Version',
                size: 100,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            justifyContent: 'center'
                        }}
                    >
                        <span>v{row.original.templateVersion}</span>
                    </Box>
                )
            }
        ],
        deps
    )
}

export function getMaterialReactTableColumnDef(factory, deps) {
    return useMemo(
        () => [
            {
                accessorKey: 'ATAChapter',
                header: 'ATA Chapter',
                size: 60,
                minSize: 60,
                maxSize: 60,
                filterFn: 'customContainsFn'
            },
            {
                accessorKey: 'parameterName',
                header: 'Parameter Name',
                filterFn: 'customContainsFn'
            },
            {
                accessorKey: 'parameterEnglishName',
                header: 'Description',
                filterFn: 'customContainsFn'
            },
            {
                accessorKey: 'unitOfMeasure',
                header: 'Unit',
                filterFn: 'customContainsFn'
            },
            {
                accessorKey: 'type',
                header: 'Parameter Type',
                filterFn: 'customContainsFn'
            }
        ],
        deps
    )
}

export function getMaterialReactTableRpdColumnDef(factory, deps) {
    return useMemo(
        () => [
            {
                accessorKey: 'ATAChapter',
                header: 'ATA Chapter',
                filterFn: 'customContainsFn'
            },
            {
                accessorKey: 'parameterName',
                header: 'Parameter Name',
                filterFn: 'customContainsFn'
            },
            {
                accessorKey: 'parameterEnglishName',
                header: 'Description',
                filterFn: 'customContainsFn'
            },
            {
                accessorKey: 'unitOfMeasure',
                header: 'Unit',
                filterFn: 'customContainsFn'
            }
        ],
        deps
    )
}
export function getMaterialReactTableColumnDefCms(factory, deps) {
    return useMemo(
        () => [
            {
                accessorKey: 'ATAChapter',
                header: 'ATA Chapter',
                size: 50,
                minSize: 50,
                maxSize: 50
            },
            {
                accessorKey: 'parameterName',
                header: 'Parameter Name'
            },
            {
                accessorKey: 'parameterEnglishName',
                header: 'Description'
            },
            {
                accessorKey: 'systemName',
                header: 'System'
            },
            {
                accessorKey: 'unitOfMeasure',
                header: 'Unit'
            },
            {
                accessorKey: 'type',
                header: 'Parameter Type'
            }
        ],
        deps
    )
}

export function getMaterialTableColumnDef(toggleParameter, checkboxDisabled = false) {
    return [
        {
            title: '',
            cellStyle: { width: '5%' },
            filtering: false,
            field: '',
            render: rowData => (
                <div>
                    <CustomCheckbox
                        isChecked={rowData.checked}
                        isDisabled={checkboxDisabled}
                        onChangeAction={e => {
                            toggleParameter(rowData, rowData.checked)
                        }}
                    />
                </div>
            )
        },
        {
            width: '50px',
            maxWidth: '50px',
            title: 'ATA chapter',
            cellStyle: { width: '10%' },
            field: 'ATAChapter',
            filterPlaceholder: 'ATA'
        },
        {
            maxWidth: '100px',
            title: 'Parameter Name(long)',
            cellStyle: {
                wordBreak: 'break-word',
                width: '30%'
            },
            field: 'parameterName',
            filterPlaceholder: 'Parameter Name'
        },
        {
            title: 'Description',
            cellStyle: { width: '25%' },
            field: 'parameterEnglishName',
            filterPlaceholder: 'Description'
        },
        {
            title: 'Unit',
            cellStyle: { width: '15%' },
            field: 'unitOfMeasure',
            filterPlaceholder: 'Unit'
        },
        {
            title: 'Type',
            cellStyle: { width: '15%' },
            field: 'type',
            filterPlaceholder: 'Parameter Type'
        }
    ]
}

// Get the HTML structure for the additional CMS info
export const getCmsAdditionalInfoRender = infoObject => {
    // If the info object is null return a default message
    if (!infoObject) {
        return (
            <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant='h6'>Not Applicable</Typography>
            </Box>
        )
    }

    // Convert the object key + values into an array
    const infoKeyValueArray = Object.entries(infoObject)

    // If there are no values in the object return a default message
    if (infoKeyValueArray.length <= 0) {
        return (
            <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant='h6'>Not Applicable</Typography>
            </Box>
        )
    }

    // Run through the key + values and render both the key and the value as they come in the object.
    return (
        <Box
            sx={{
                display: 'grid',
                margin: 'auto',
                gridTemplateColumns: '1fr 1fr',
                width: '90%',
                marginLeft: '5%'
            }}
        >
            {infoKeyValueArray.map((infoKeyValue, index) => (
                <React.Fragment key={'cmsInfo_' + infoKeyValue[0] + '_' + index}>
                    <div>
                        <span className='cms__info-row-label'>{infoKeyValue[0] + ': '}</span>
                        <span className='cms_info-row-value '>
                            {infoKeyValue[1] !== 'Data not found' && infoKeyValue[1] !== ''
                                ? infoKeyValue[1]
                                : '-'}
                        </span>
                    </div>
                </React.Fragment>
            ))}
        </Box>
    )
}

// Get the material-table columns for the parameter list table that includes CMS parameters.
export function getMaterialTableColumnDefCms(toggleParameter, getCMSParameterInfo) {
    return [
        {
            width: '5%',
            title: '',
            filtering: false,
            field: '',
            render: rowData => (
                <div>
                    <CustomCheckbox
                        isChecked={rowData.checked}
                        onChangeAction={e => {
                            toggleParameter(rowData, rowData.checked)
                        }}
                    />
                </div>
            )
        },
        {
            width: '8%',
            maxWidth: '50px',
            title: 'ATA chapter',
            cellStyle: { width: '8%' },
            field: 'ATAChapter',
            filterPlaceholder: 'ATA'
        },
        {
            width: '20%',
            maxWidth: '90px',
            title: 'Parameter Name(long)',
            cellStyle: {
                width: '20%'
            },
            field: 'parameterName',
            filterPlaceholder: 'Parameter Name',
            render: rowData => (
                <CustomTooltip
                    content={
                        rowData.parameterEnglishName
                            ? rowData.parameterEnglishName
                            : rowData.parameterName
                    }
                    tooltipType={'general'}
                    placement={'top'}
                >
                    <span>{rowData.parameterName}</span>
                </CustomTooltip>
            )
        },
        {
            width: '25%',
            minWidth: '90px',
            title: 'Description',
            cellStyle: { width: '25%' },
            field: 'parameterEnglishName',
            filterPlaceholder: 'Description'
        },
        {
            width: '13%',
            title: 'System',
            cellStyle: { width: '13%' },
            field: 'systemName',
            filterPlaceholder: 'System'
        },
        {
            width: '5%',
            title: 'Load CMS Data',
            render: rowData =>
                rowData.type === 'CMS Parameter' && (
                    <div>
                        {rowData.info ? (
                            <div>
                                {rowData.info.error ? (
                                    <div>Not Applicable</div>
                                ) : (
                                    getCmsAdditionalInfoRender(rowData.info)
                                )}
                            </div>
                        ) : (
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    getCMSParameterInfo(rowData)
                                }}
                            >
                                <FeatherIcon
                                    icon='info'
                                    width={28}
                                    height={28}
                                    className='template__exist__icon'
                                />
                            </div>
                        )}
                    </div>
                )
        },
        {
            width: '8%',
            title: 'Unit',
            cellStyle: { width: '8%' },
            field: 'unitOfMeasure',
            filterPlaceholder: 'Unit'
        },
        {
            width: '10%',
            title: 'Type',
            cellStyle: { width: '10%' },
            field: 'type',
            filterPlaceholder: 'Parameter Type'
        }
    ]
}

export function getMaterialTableIcons() {
    return {
        Filter: forwardRef((props, ref) => (
            <SearchOutlinedIcon {...props} ref={ref} style={{ color: 'white' }} />
        )),
        FirstPage: forwardRef((props, ref) => (
            <FirstPage {...props} ref={ref} style={{ color: 'white' }} />
        )),
        LastPage: forwardRef((props, ref) => (
            <LastPage {...props} ref={ref} style={{ color: 'white' }} />
        )),
        NextPage: forwardRef((props, ref) => (
            <ChevronRight {...props} ref={ref} style={{ color: 'white' }} />
        )),
        PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} style={{ color: 'white' }} />
        )),
        ResetSearch: forwardRef((props, ref) => (
            <Clear {...props} ref={ref} style={{ color: 'white' }} />
        )),
        Search: forwardRef((props, ref) => (
            <Search {...props} ref={ref} style={{ color: 'white' }} />
        )),
        SortArrow: forwardRef((props, ref) => (
            <ArrowDownward {...props} ref={ref} style={{ color: 'white' }} />
        ))
    }
}

export function createRPDLogColumnDef(
    requestEnabled,
    viewRequest,
    duplicateRequest,
    deleteRequest,
    toggleRequest
) {
    let defaultColumns = [
        {
            title: 'Request Name',
            cellStyle: {
                padding: '8px',
                width: '25%'
            },
            field: 'requestName',
            filterPlaceholder: 'Request Name',
            render: rowData => <div>{rowData.requestName}</div>
        },
        {
            title: 'Request Type',
            cellStyle: {
                padding: '8px',
                width: '20%'
            },
            field: 'requestTypeFormatted',
            filterPlaceholder: 'Request Type',
            render: rowData => <div>{rowData.requestTypeFormatted}</div>
        },
        {
            title: 'Date',
            cellStyle: {
                padding: '8px',
                width: '15%'
            },
            field: 'userRequestTimestampFormatted',
            filterPlaceholder: 'Date',
            render: rowData => <div>{rowData.userRequestTimestampFormatted}</div>
        },
        {
            title: 'Status',
            cellStyle: {
                padding: '8px',
                width: 'calc(35% - 120px)'
            },
            field: 'requestStatusFormatted',
            filterPlaceholder: 'Status',
            render: rowData => (
                <div>
                    <span>
                        <FeatherIcon
                            icon={rowData.requestStatusIcon}
                            width={22}
                            height={22}
                            color={rowData.requestStatusIconColor}
                            style={{
                                top: '5px',
                                position: 'relative'
                            }}
                        />
                    </span>
                    <span
                        style={{
                            marginLeft: '10px'
                        }}
                    >
                        {rowData.requestStatusFormatted}
                    </span>
                </div>
            )
        },
        {
            maxWidth: '60px',
            minWidth: '60px',
            width: '60px',
            title: 'Load',
            filtering: false,
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    {rowData.requestStatusFormatted === 'Complete' ? (
                        <Link
                            to={{
                                pathname: '/ahmsview',
                                state: rowData.ahmsRpdInfo
                            }}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            <CustomTooltip
                                content={'Load Request'}
                                tooltipType={'general'}
                                placement={'top'}
                            >
                                <div
                                    style={{
                                        height: '40px',
                                        width: '40px',
                                        borderRadius: '5px',
                                        backgroundColor: '#3F51B5',
                                        padding: '6px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FeatherIcon
                                        icon='arrow-right'
                                        width={28}
                                        height={28}
                                        className='template__exist__icon'
                                    />
                                </div>
                            </CustomTooltip>
                        </Link>
                    ) : (
                        <CustomTooltip
                            content={'Load Request'}
                            tooltipType={'general'}
                            placement={'top'}
                        >
                            <div
                                style={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '5px',
                                    backgroundColor: '#3F51B5',
                                    padding: '6px',
                                    opacity: 0.3,
                                    cursor: 'not-allowed'
                                }}
                            >
                                <FeatherIcon
                                    icon='arrow-right'
                                    width={28}
                                    height={28}
                                    className='template__exist__icon'
                                />
                            </div>
                        </CustomTooltip>
                    )}
                </div>
            )
        },
        {
            maxWidth: '60px',
            minWidth: '60px',
            width: '60px',
            title: 'View',
            filtering: false,
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    <CustomTooltip
                        content={'View Request'}
                        tooltipType={'general'}
                        placement={'top'}
                    >
                        {rowData.requestType !== 'fault' ||
                        rowData.requestStatusFormatted === 'Complete' ? (
                            <div
                                style={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '5px',
                                    backgroundColor: '#303030',
                                    padding: '6px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    viewRequest(rowData)
                                }}
                            >
                                <FeatherIcon
                                    icon='eye'
                                    width={28}
                                    height={28}
                                    className='template__exist__icon'
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '5px',
                                    backgroundColor: '#303030',
                                    padding: '6px',
                                    opacity: 0.3,
                                    cursor: 'not-allowed'
                                }}
                            >
                                <FeatherIcon
                                    icon='eye'
                                    width={28}
                                    height={28}
                                    className='template__exist__icon'
                                />
                            </div>
                        )}
                    </CustomTooltip>
                </div>
            )
        },
        {
            maxWidth: '60px',
            minWidth: '60px',
            width: '60px',
            title: 'Duplicate',
            filtering: false,
            cellStyle: { padding: '8px' },
            field: '',
            render: rowData => (
                <div>
                    <CustomTooltip
                        content={'Duplicate Request'}
                        tooltipType={'general'}
                        placement={'top'}
                    >
                        {requestEnabled && rowData.requestType !== 'fault' ? (
                            <div
                                style={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '5px',
                                    backgroundColor: '#4CAF50',
                                    padding: '6px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    duplicateRequest(rowData)
                                }}
                            >
                                <FeatherIcon
                                    icon='copy'
                                    width={28}
                                    height={28}
                                    className='template__exist__icon'
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '5px',
                                    backgroundColor: '#4CAF50',
                                    opacity: 0.3,
                                    padding: '6px',
                                    cursor: 'not-allowed'
                                }}
                            >
                                <FeatherIcon
                                    icon='copy'
                                    width={28}
                                    height={28}
                                    className='template__exist__icon'
                                />
                            </div>
                        )}
                    </CustomTooltip>
                </div>
            )
        }
    ]

    return defaultColumns
}

export function getMaterialReactRpdLogColumnDef(factory, deps) {
    return useMemo(
        () => [
            {
                accessorKey: 'requestName',
                header: 'Request Name'
            },
            {
                accessorKey: 'requestTypeFormatted',
                header: 'Request Type',
                size: 100
            },
            {
                accessorKey: 'userRequestTimestampFormatted',
                header: 'Date'
            },
            {
                accessorKey: 'requestStatusFormatted',
                header: 'Status'
            }
        ],
        deps
    )
}

export function MaterialTableTheme() {
    return createTheme({
        palette: {
            primary: {
                main: '#ffffff'
            },
            secondary: {
                main: '#ffffff'
            }
        }
    })
}
