import FleetViewOnboarding from "./FleetViewOnboarding";
import AirplaneViewOnboarding from "./AirplaneViewOnboarding";
import AHMSDataOnboarding from "./AHMSDataOnboarding";
import NotificationsViewOnboarding from "./NotificationsViewOnboarding";
import ConfigViewOnboarding from "./ConfigViewOnboarding";

const getSteps = (iofg, page) => {
  let courses;
  switch (true) {
    case iofg === "NEW_USER_ONBOARDING" && page === "FLEET_V_PG":
      courses = FleetViewOnboarding();
      break;
    case iofg === "NEW_USER_ONBOARDING" && page === "AIRCRAFT_V_PG":
      courses = AirplaneViewOnboarding();
      break;
    case iofg === "NEW_USER_ONBOARDING" && page === "PARAM_V_PG":
      courses = AHMSDataOnboarding();
      break;
    case iofg === "NEW_USER_ONBOARDING" && page === "NOTIF_PG":
      courses = NotificationsViewOnboarding();
      break;
    case iofg === "NEW_USER_ONBOARDING" && page === "CONF_PG":
      courses = ConfigViewOnboarding();
      break;
    // case iofg === "TEMPLATE_MGMT_V2_0" && page === "PARAM_V_PG":
      //courses = TemplateMgmt_V2_0();
      // break;

    default:
      courses = FleetViewOnboarding();
      break;
  }

  return courses;
};

export default getSteps;
