import React from "react";
import "./GuidesView.css";

/**
 * PDF guide description and link component.
 * @param id Document/Guide ID.
 * @param title Title of the document/guide.
 * @param date Date the file/guide was modified.
 * @param onClick Event hanlder that will open the selected PDF guide.
 * @return {HTML}
 */
function GuidesPDF({ id, title, date, onClick }) {
  return (
    <div className="guides_documentWrapper" id={id}>
      <div className="guides_documentTitle">
        <span className="guides_documentLink" onClick={onClick}>
          {title}
        </span>
      </div>
      <div className="guides_documentSubtitle">
        <div style={{ paddingRight: "10px" }}>Uploaded {date}</div>
      </div>
    </div>
  );
}

export default GuidesPDF;
