import React from "react";
import { Grid } from "@mui/material";
import CustomButton from "./CustomButton";

function ConfirmationPopup2({
  active,
  handleSubmitOk,
  handleSubmitCancel,
  confirmationText,
  additionalInfo,
  descriptionText,
  title,
  cancelText,
  acceptText,
  hasIcon,
  icon,
}) {
  return (
    <div
      style={{
        display: active ? "block" : "none",
        position: "fixed",
        zIndex: "444",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        overflow: "auto",
        backgroundColor: "rgba(0,0,0, 0.4)"
      }}>
      <div
        style={{
          backgroundColor: "#404040",
          margin: "15% auto",
          width: "550px",
          minHeight: "300px",
          maxHeight: "400px",
          overflow: "auto",
          zIndex: "445"
        }}>
        <Grid
          container
          spacing={0}
          direction={"row"}>
        </Grid>
        <Grid
          container
          direction={"row"}
          item xs={12}>
          {hasIcon ? (
            <Grid item xs={2} style={{
              paddingTop: "50px",
              paddingLeft: "40px",
            }}>
              {icon}
            </Grid>
          ) : null}
          <Grid item xs={hasIcon ? 10 : 12} style={{
            padding: "50px 20px 0 20px"
          }}>
            <p style={{
              fontSize: "24px",
              fontWeight: "700",
              color: "#FFF",
            }}>{title}</p>
            <p style={{
              fontSize: "16px",
              color: "#FFF",
            }}>{descriptionText}</p>
            <p style={{
              fontSize: "14px",
              color: "#ffc72c",
              marginTop: "25px",
              fontStyle: "italic"
            }}>{additionalInfo}</p>
            <p style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginTop: "25px",
              color: "#FFF",
            }}>{confirmationText}</p>
            <div
              style={{
                width: "100%",
                height: "100px",
                display: "flex",
                flexFlow: "row",
                justifyContent: "flex-start",
                gap: "50px",
                alignItems: "center"
              }}>
              <CustomButton
                id={"btn-confirmModalPopup2-cancel"}
                text={cancelText}
                colorStyle={"custom"}
                customButtonColor={"#606060"}
                customButtonTextColor={"#FFFFFF"}
                onClickAction={handleSubmitCancel} />
              <CustomButton
                id={"btn-confirmModalPopup2-ok"}
                text={acceptText}
                colorStyle={"custom"}
                customButtonColor={"#ffc72c"}
                customButtonTextColor={"#000000"}
                onClickAction={handleSubmitOk} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default ConfirmationPopup2;