import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DropdownListTailFamilyModelVersion } from "../Global Components/Dropdown.jsx";
import AircraftIcon from "../resources/aircraft-solid.svg";
import "./ConfigFleetTailsBox.css";

function ConfigFleetTailsBox({
  placeholder,
  subjectEnableType,
  tailList,
  familyModelList,
  tailSelectEvent,
  familyModelSelectEvent,
  selectedTail,
  selectedFamilyModel,
}) {
  const [placeholderText, setPlaceholderText] = useState(placeholder);
  const [familyModelOptions, setFamilyModelOptions] = useState([]);
  const [tailOptions, setTailOptions] = useState([]);
  const [selectedTailOptions, setSelectedTailOptions] = useState({
    lable: "",
    value: "",
  });
  const [selectedFamilyModelOptions, setSelectedFamilyModelOptions] = useState({
    lable: "",
    value: "",
  });

  useEffect(() => {
    setFamilyModelOptions(
      familyModelList?.map((fm) =>
        formatDropdownFamilyValues(fm.aircraftFamily, fm.aircraftModel)
      ).sort(
        (familyModel1, familyModel2) => 
          (familyModel1.value > familyModel2.value) ? 1 
          : (familyModel2.value > familyModel1.value) ? -1 
          : 0
      )
    );

    setSelectedFamilyModelOptions(
      formatDropdownFamilyValues(
        selectedFamilyModel?.aircraftFamily,
        selectedFamilyModel?.aircraftModel
      )
    );

    setTailOptions(
      tailList?.sort((tail1, tail2) => 
        tail1.serialNumber ?
          // sort by serial number if serial number is available
          Math.round(tail1.serialNumber) > Math.round(tail2.serialNumber) ? 1 
            : Math.round(tail2.serialNumber) > Math.round(tail1.serialNumber) ? -1 
            : 0
        :
          // sort by display ID if serial number is unavailable
          Math.round(tail1.displayId) > Math.round(tail2.displayId) ? 1 
            : Math.round(tail2.displayId) > Math.round(tail1.displayId) ? -1 
            : 0
        ).map((tail) =>
          formatDropdownTailValues(
            tail.registrationNumber, 
            tail.serialNumber !== undefined
            ? tail.serialNumber : tail.displayId
          )
        )
    );

    if (selectedTail) {
      setSelectedTailOptions(
        formatDropdownTailValues(
          selectedTail?.registrationNumber,
          selectedTail?.serialNumber !== undefined
          ? selectedTail.serialNumber : selectedTail.displayId
        )
      );
    }
  }, [selectedTail, selectedFamilyModel, tailList]);

  const renderConfigFleetTailsBox = () => {
    return (
      <div
        className={
          "configFleetTailsBox" +
          (subjectEnableType === "FAMILY" || subjectEnableType === "TAIL"
            ? ""
            : " configFleetTailsBox__none")
        }
      >
        <div className="configFleetTailsBox__icon">
          {(subjectEnableType === "FAMILY" || subjectEnableType === "TAIL") && (
            <img
              className="configView__table__options__icon"
              src={AircraftIcon}
              alt="aircraft"
            ></img>
          )}
        </div>
        {placeholderText}
        <div className="configFleetTailsBox__select">
          {subjectEnableType === "FAMILY"
            ? handleConfigFleets()
            : subjectEnableType === "TAIL"
            ? handleConfigTails()
            : ""}
        </div>
      </div>
    );
  };

  const handleConfigFleets = () => {
    return (
      <DropdownListTailFamilyModelVersion
        options={familyModelOptions}
        defaultValue={familyModelOptions[0]}
        onChange={handleFamilyModelSelectEvent}
        selectedValue={selectedFamilyModelOptions}
      />
    );
  };

  const handleConfigTails = () => {
    return (
      <DropdownListTailFamilyModelVersion
        options={tailOptions}
        defaultValue={tailOptions[0]}
        onChange={handleTailSelectEvent}
        selectedValue={selectedTailOptions}
      />
    );
  };

  const handleFamilyModelSelectEvent = (familyModel) => {
    familyModelSelectEvent(familyModel);
    setPlaceholderText(null);
  };

  const handleTailSelectEvent = (tail) => {
    tailSelectEvent(tail.value.split("____")[0]);
    setPlaceholderText(null);
  };

  const formatDropdownTailValues = (registrationNumber, serialNumber) => {
    return {
      value: registrationNumber + "____" + serialNumber,
      //label: "#" + serialNumber + " (reg." + registrationNumber + ")",
      label: (
        <div style={{ display: "flex", width: "100%" }}>
          <span style={{ display: "flex", width: "45%" }}>{serialNumber}</span>
          <span style={{ display: "flex", width: "55%" }}>
            {registrationNumber}
          </span>
        </div>
      ),
    };
  };

  const formatDropdownFamilyValues = (aircraftFamily, aircraftModel) => {
    return {
      value: aircraftFamily + "-" + aircraftModel,
      label: aircraftFamily + " " + aircraftModel,
    };
  };

  return renderConfigFleetTailsBox();
}

export default ConfigFleetTailsBox;

ConfigFleetTailsBox.defaultProps = {
  configOptionsList: [],
  activeOptionId: "",
};

ConfigFleetTailsBox.propTypes = {
  configOptionsList: PropTypes.array,
  activeOptionId: PropTypes.string,
};
