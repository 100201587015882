import ReleaseToggles from './ReleaseToggles.json'
import BetaTestToggles from './BetaTestToggles.json'
import _ from 'lodash'

/**
 * Function to validate a release feature toggle.
 * TODO: Add a valid environments list instead of a direct IF
 * @param name
 */
export function isFeatureToggleActive(name) {
    const featureFlagReleaseToggle = ReleaseToggles[name]
    const currEnvironment = process.env.REACT_APP_ENVIRONMENT
    // If the name exists in the release toggle list:
    if (featureFlagReleaseToggle && typeof featureFlagReleaseToggle !== 'undefined') {
        // Check if the current environment is defined
        // If it is not defined, assume that the user is on a local instance and show the feature
        let showInEnvironment = true
        if (currEnvironment && currEnvironment !== undefined) {
            showInEnvironment = featureFlagReleaseToggle?.active[currEnvironment]
        }
        // Check if the feature is to be shown in the environment
        // true -> show in environment
        // false -> hide in environment
        return showInEnvironment
    }

    const featureFlagBetaTestToggle = BetaTestToggles[name]

    // If the name exists in the beta test toggle list, proceed to validate if the user is part of the testing list
    if (featureFlagBetaTestToggle && typeof featureFlagBetaTestToggle !== 'undefined') {
        // Check if the current environment is defined
        // If it is not defined, assume that the user is on a local instance
        let showInEnvironment = true
        if (currEnvironment && currEnvironment !== undefined) {
            showInEnvironment = featureFlagBetaTestToggle?.active[currEnvironment]
        }
        // Check if the feature is to be shown in the environment
        // true -> show in environment
        // false -> hide in environment
        if (showInEnvironment) {
            // Obtain the user from local storage
            let user = null
            if (localStorage.getItem('userInfo')) {
                const userInfo = JSON.parse(localStorage.getItem('userInfo'))
                user = userInfo.userId
            }
            // Validate if the user is in the beta user pool
            return featureFlagBetaTestToggle.users.includes(user)
        }
    }

    return false
}
