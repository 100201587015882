import React from "react";

import "./ContentMdcReport.css";

const ContentMdcReport = ({ selectedMdcRowData }) => {
  const formatText = (textValue) => {
    if (textValue) return textValue.toString().replaceAll("\r", "\n");
    return "";
  };

  return (
    <div className="content-mdc-report-body">
      <p className="content-mdc-report-text">
        {formatText(selectedMdcRowData.mdcMessageContent)}
      </p>
    </div>
  );
};

export default ContentMdcReport;
