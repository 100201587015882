import AxiosService from "./AxiosService";
import axios from "axios";
import moment from "moment";
import * as Api from "../Constants/ApiCatalog";
import * as Constants from "../Constants/Constants";

/*
  Client for API that hanldes all parameter data retreival.
  All of the following API have a transform parameter that helps retrieve data in a format suitable for 
  charting on the frontend depending on the types of charts being used
*/

const PARAMETER_DATA_ENDPOINT = Api.PARAMETER_DATA_ENDPOINT;
const AHMU_PARAMETER_DATA_ENDPOINT = Api.AHMU_PARAMETER_DATA_ENDPOINT;
const CSV_PARAMETER_DATA_ENDPOINT = Api.CSV_PARAMETER_DATA_ENDPOINT;
const CMS_PARAMETER_DATA_ENDPOINT = Api.CMS_PARAMETER_DATA_ENDPOINT;
const CMS_PARAMETER_INFO_ENDPOINT = Api.CMS_PARAMETER_INFO_ENDPOINT;
const CMS_PARAMETER_CSV_DATA = Api.CMS_PARAMETER_CSV_DATA;

// Return the CMS parameter data for a parameter name and time range
export async function getCmsParameterData(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  serialNumber,
  startTime,
  endTime,
  parameter,
  queryFaultCode,
  getDiscreteTranslation
) {
  const transform =
    parameter.parameterType === "discrete-non-binary"
      ? "state" //? "state"
      : "canvas-line";
  const discreteTranslation = getDiscreteTranslation(parameter.parameterName);

  const utcStartTimeStamp = moment
    .utc(startTime)
    .format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const utcEndTimeStamp = moment
    .utc(endTime)
    .format("YYYY-MM-DDTHH:mm:ss.000[Z]");

  let spliceOffset = 0;
  for (let i = serialNumber.length - 1; i > 0; i--) {
    if (serialNumber[i] === "0") {
      break;
    }
    spliceOffset += 1;
  }

  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tailNumber,
      parameterName: parameter.parameterName,
      faultStart: utcStartTimeStamp,
      faultEnd: utcEndTimeStamp,
      transform: transform,
      sourceId: parameter.sourceId,
      itemId: parameter.itemId,
      ata: parameter.ATAChapter,
      lru: parameter.LRU ? parameter.LRU : null,
      faultCodeInView: queryFaultCode,
    },
  };

  let request = axios.get(CMS_PARAMETER_DATA_ENDPOINT.url, {
    params: data.params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
      demo: Constants.MYDOM_DEMO_INSTANCE(),
    },

    transformResponse: [
      function(data) {
        const response = JSON.parse(data);
        if (response) {
          if (transform === "state") {
          } else {
            response.data.forEach((dp) => {
              dp.x = new Date(dp.x);
            });
          }
        } else {
          return [];
        }

        return response;
      },
    ],
  });

  return request;
}

// Return the CMS ADITIONAL parameter data for a parameter name and time range
export async function getCmsParameterInfo(
  aircraftFamily,
  aircraftModel,
  tail,
  parameterName,
  sessionNumber
) {
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tail,
      cmsParameterName: parameterName,
      sessionNumber: sessionNumber,
    },
  };

  let request = axios.get(CMS_PARAMETER_INFO_ENDPOINT.url, {
    params: data.params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
      demo: Constants.MYDOM_DEMO_INSTANCE(),
    },
  });

  return request;
}

// Return the parameter data for a parameter name and time range
export async function getParameterData(
  tail,
  faultCode,
  aircraftFamily,
  aircraftModel,
  faultTimeStamp,
  parameter,
  padding,
  queryFaultCode,
  getDiscreteTranslation,
  faultName
) {
  const transform = "line";
  const discreteTranslation = getDiscreteTranslation(parameter.parameterName);

  const utcTimeStamp = moment
    .utc(faultTimeStamp)
    .format("YYYY-MM-DDTHH:mm:ss.000[Z]");

  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tail,
      faultCode: faultCode,
      parameterName: parameter.parameterName,
      faultTimestamp: utcTimeStamp,
      durationPadding: padding,
      // englishName: parameter.parameterEnglishName,
      ata: parameter.ATAChapter,
      transform: transform,
      // discreteTranslation: discreteTranslation ? discreteTranslation : null,
      // lru: parameter.LRU ? parameter.LRU : null,
      faultCodeInView: queryFaultCode,
      faultName: faultName,
    },
  };

  let request = axios.get(PARAMETER_DATA_ENDPOINT.url, {
    params: data.params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
      demo: Constants.MYDOM_DEMO_INSTANCE(),
    },
  });

  return request;
}

// Return the flight AHMU parameter data for a parameter name and time range
export async function getAHMUParameterData(
  tail,
  aircraftFamily,
  aircraftModel,
  startTime,
  endTime,
  parameter,
  queryFaultCode,
  getDiscreteTranslation,
  faultName,
  customDataSampleAmount = null
) {
  const transform = "canvas-line";
  const discreteTranslation = getDiscreteTranslation(parameter.parameterName);

  const utcStartTimeStamp = moment
    .utc(startTime)
    .format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const utcEndTimeStamp = moment
    .utc(endTime)
    .format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const uriFormatedParameters = "['" + parameter.parameterName + "']";

  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tail,
      startTime: utcStartTimeStamp,
      endTime: utcEndTimeStamp,
      transform: transform,
      // parameters: uriFormatedParameters,
      parameterName: parameter.parameterName,
      // ata: parameter.ATAChapter,
      // englishName: parameter.parameterEnglishName,
      // discreteTranslation: discreteTranslation ? discreteTranslation : null,
      dataPoints: customDataSampleAmount ? customDataSampleAmount : 100000,
      // lru: parameter.LRU ? parameter.LRU : null,
      faultCodeInView: queryFaultCode,
      faultName: faultName,
    },
  };

  let request = axios.get(AHMU_PARAMETER_DATA_ENDPOINT.url, {
    params: data.params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
      demo: Constants.MYDOM_DEMO_INSTANCE(),
    },
    transformResponse: [
      function (data) {
        const response = JSON.parse(data);
        if (response[0]) {
          response[0].data.forEach((dp) => {
            dp.x = new Date(dp.x);
          });
        } else {
          return [];
        }

        return response;
      },
    ],
  });

  return request;
}

// Return the parameter data for a parameter name and time range transformed for CSV formats
export async function getCSVParameterData(
  parameters,
  tail,
  faultCode,
  startTime,
  endTime,
  aircraftModel,
  aircraftFamily,
  axiosCancelSource
) {
  let result;

  const start = moment.utc(startTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const end = moment.utc(endTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");

  const data = {
    parameters: parameters,
    tailNumber: tail,
    faultCode: faultCode,
    startTime: start,
    endTime: end,
    aircraftModel: aircraftModel,
    aircraftFamily: aircraftFamily,
  };

  var config = {
    onDownloadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      document.getElementById("output-1").innerHTML =
        "Loading... " + percentCompleted + "%";
    },
  };

  try {
    result = await AxiosService(
      CSV_PARAMETER_DATA_ENDPOINT.url,
      CSV_PARAMETER_DATA_ENDPOINT.method,
      data,
      axiosCancelSource,
      null,
      config
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Return the CMS parameter data for a parameter name and time range transformed for CSV formats
export async function cmsParameterDataForCsv(
  parameters,
  tail,
  aircraftFamily,
  aircraftModel,
  faultCode,
  startTime,
  endTime,
  sourceId,
  itemId,
  axiosCancelSource
) {
  const utcStartTimeStamp = moment
    .utc(startTime)
    .format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const utcEndTimeStamp = moment
    .utc(endTime)
    .format("YYYY-MM-DDTHH:mm:ss.000[Z]");

  let result;

  var config = {
    onDownloadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      document.getElementById("output-2").innerHTML =
        "Loading... " + percentCompleted + "%";
    },
  };

  try {
    result = await AxiosService(
      CMS_PARAMETER_CSV_DATA.url +
      `?aircraftFamily=${aircraftFamily}&aircraftModel=${aircraftModel}&tailNumber=${tail}&faultStart=${utcStartTimeStamp}&faultEnd=${utcEndTimeStamp}`,
      CMS_PARAMETER_CSV_DATA.method,
      parameters,
      axiosCancelSource,
      null,
      config
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}
