import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  useMaterialReactTable,
} from "material-react-table";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import CustomTooltip from "./CustomTooltip";
import FeatherIcon from "feather-icons-react";
import "./TemplateTable.css";
import GlobalDropdown from "./Dropdown/GlobalDropdown";

/**
 * The component that renders the template table.
 * @param columns The column definition.
 * @param data The table data.
 * @param groupedTemplates The grouped templates object used for versioning.
 * @param handleOpenPreview Handler for opening a template preview.
 * @param handleLoadTemplate Handler for loading a template.
 * @param toggleTemplateModal Function reference to toggle the template preview modal.
 * @param tab The current tab.
 * @param templatePermissions The user permissions.
 * @param tabDisplayName The tab display name.
 * @param changeTemplateVersion The handler to select a different template version.
 * @returns {Element}
 * @constructor
 */
export const TemplateTable = ({
  columns,
  data,
  groupedTemplates,
  handleOpenPreview,
  handleLoadTemplate,
  toggleTemplateModal,
  tab,
  templatePermissions,
  tabDisplayName,
  changeTemplateVersion,
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const [hasPublicPolicyExternal, setHasPublicPolicyExternal] = useState(false);
  const [hasPublicPolicyInternal, setHasPublicPolicyInternal] = useState(false);

  /**
   * Sets the template permissions to render the table accordingly.
   */
  useEffect(() => {
    if (templatePermissions) {
      setHasPublicPolicyExternal(
        templatePermissions.templatePublicPolicyExternal
      );
      setHasPublicPolicyInternal(
        templatePermissions.templatePublicPolicyInternal
      );
    }
  }, [templatePermissions]);

  // Custom theme for the table.
  const tableTheme = useMemo(() =>
    createTheme({
      palette: {
        background: {
          default: "#101010",
        },
        secondary: {
          main: "#101010",
          contrastText: "#FFFFFF",
        },
        primary: {
          main: "#101010",
          light: "#101010",
          dark: "#101010",
          contrastText: "#FFFFFF",
        },
      },
      typography: {
        allVariants: {
          color: "#FFF",
        },
      },
      components: {
        MuiTableCell: {
          defaultProps: {
            sx: {
              color: "#FFF",
            },
          },
          styleOverrides: {
            root: {
              color: "#FFF",
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              color: "#FFF",
              fill: "#FFF",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              background: "rgb(25,25,25) !important",
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              background: "rgb(25,25,25) !important",
            },
          },
        },

        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#101010 !important",
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              background: "rgb(25,25,25)",
            },
          },
        },
      },
    })
  );

  // The table definition
  const table = useMaterialReactTable({
    columns,
    data,
    autoResetPageIndex: false, // Prevents the pagination auto reset after updating the data state value.
    enableColumnOrdering: false,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    initialState: {
      showColumnFilters: false,
      pagination: { pageSize: 5, pageIndex: 0 },
      showGlobalFilter: true,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10],
    },
    enableHiding: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: "none",
    muiSearchTextFieldProps: {
      sx: {
        border: "1px solid #949494",
        borderRadius: "5px",
        color: "#FFF",
        marginTop: "10px",
        marginBottom: "10px",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        "::placeholder": "color: #949494;",
        ":focus": "border: 2px solid #4A90E2 !important;",
        ":hover": "border: 2px solid #AFD4FF !important;",
      },
      variant: "outlined",
    },
    muiFilterTextFieldProps: {
      sx: {
        border: "1px solid #949494",
        borderRadius: "5px",
        color: "#FFF",
        marginTop: "10px",
        marginBottom: "10px",
        "::placeholder": "color: #949494;",
        ":focus": "border: 2px solid #4A90E2 !important;",
        ":hover": "border: 2px solid #AFD4FF !important;",
      },
      variant: "outlined",
    },
    // Renders the top toolbar with the action buttons.
    renderTopToolbar: ({ table }) => {
      /**
       * Helper function to know if a table row is selected.
       * @returns {boolean} True if a table is selected, false if it is not.
       */
      const isRowSelected = () => {
        return table.getSelectedRowModel().flatRows.length > 0;
      };

      /**
       * Function to check user permissions for enabling or disabling a button.
       * @returns {boolean} True if the user has the permissions, false if it doesn't.
       */
      const isButtonEnabledByPrivacyPolicy = () => {
        if (isRowSelected()) {
          const row = table.getSelectedRowModel().flatRows[0].original;
          if (
            (row.templatePrivacyPolicy === "INT_PUBLIC" &&
              hasPublicPolicyInternal) ||
            (row.templatePrivacyPolicy === "EXT_PUBLIC" &&
              hasPublicPolicyExternal)
          ) {
            return true;
          }
          return false;
        }
        return false;
      };

      /**
       * Calls the handler to open a template preview modal.
       */
      const handlePreview = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          // We must use the row.original to get the actual template object.
          handleOpenPreview(row.original);
        });
      };

      /**
       * Calls the handler to load and chart the template parameters.
       */
      const handleLoad = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          handleLoadTemplate(row.original);
        });
      };

      /**
       * Calls the handler to duplicate a template.
       */
      const handleDuplicate = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          toggleTemplateModal(row.original, "copied");
        });
      };

      /**
       * Calls the handler to archive a template.
       */
      const handleArchive = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          toggleTemplateModal(row.original, "archived");
        });
      };

      /**
       * Calls the handler to restore a template.
       */
      const handleRestore = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          toggleTemplateModal(row.original, "upload");
        });
      };

      const handleDelete = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          toggleTemplateModal(row.original, "deleted", tabDisplayName);
        });
      };

      /**
       * Returns the selected row data.
       * @returns {TData|null} The template data.
       */
      const getRowData = () => {
        if (isRowSelected()) {
          return table.getSelectedRowModel().flatRows[0].original;
        }
        return null;
      };

      /**
       * Helper function to know if a template has multiple versions.
       * @returns {*|boolean} true if the template has more than 1 version, false if it doesn't.
       */
      const templateHasMultipleVersions = () => {
        return (
          getRowData().fromTemplateId &&
          groupedTemplates[getRowData().fromTemplateId].length > 1
        );
      };

      return (
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: "0.5rem",
            justifyContent: "space-between",
            paddingLeft: "10px",
            paddingRight: "10px",
          })}
        >
          {/* import the MRT subcomponents like Global Search and filter toggles. */}
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: "0.5rem", marginTop: "10px" }}>
              {isRowSelected() && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                  }}
                >
                  <p
                    style={{
                      color: "#FFF",
                      fontWeight: 600,
                    }}
                  >
                    {getRowData().templateName}
                  </p>
                </Box>
              )}
              <div
                style={{
                  width: "120px",
                }}
              >
                {table.getSelectedRowModel().flatRows.length > 0 ? (
                  <GlobalDropdown
                    options={
                      templateHasMultipleVersions()
                        ? groupedTemplates[getRowData().fromTemplateId]
                          .sort(
                            (a, b) => b.templateVersion - a.templateVersion
                          )
                          .map((t) => {
                            return {
                              label: `v${t.templateVersion}`,
                              value: t.templateId,
                            };
                          })
                        : [
                          {
                            label: `v${getRowData().templateVersion}`,
                            value: getRowData().templateId,
                          },
                        ]
                    }
                    defaultValue={{
                      label: `v${getRowData().templateVersion}`,
                      value: getRowData().templateId,
                    }}
                    optionsAreObjects={true}
                    height={40}
                    disabled={!templateHasMultipleVersions()}
                    onChange={(value) => {
                      changeTemplateVersion(
                        tabDisplayName,
                        value,
                        getRowData().fromTemplateId,
                        getRowData().templateId
                      );
                    }}
                  />
                ) : (
                  <GlobalDropdown
                    options={["-"]}
                    defaultValue={"-"}
                    height={40}
                    disabled={true}
                  />
                )}
              </div>

              <CustomTooltip
                content={"Preview Template"}
                tooltipType={"general"}
                placement={"top"}
              >
                <div
                  className={!isRowSelected() ? "disabled-button" : ""}
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "5px",
                    backgroundColor: "#303030",
                    padding: "6px",
                    cursor: "pointer",
                  }}
                  onClick={handlePreview}
                >
                  <FeatherIcon
                    icon="eye"
                    width={28}
                    height={28}
                    color={"#FFF"}
                  />
                </div>
              </CustomTooltip>
              {tab !== "archive" && (
                <CustomTooltip
                  content={"Load Template"}
                  tooltipType={"general"}
                  placement={"top"}
                >
                  <div
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "5px",
                      backgroundColor: "#3F51B5",
                      padding: "6px",
                      cursor: "pointer",
                    }}
                    onClick={handleLoad}
                    className={!isRowSelected() ? "disabled-button" : ""}
                  >
                    <FeatherIcon
                      icon="arrow-right"
                      width={28}
                      height={28}
                      color={"#FFF"}
                    />
                  </div>
                </CustomTooltip>
              )}
              {tab === "sharedTemplates" && (
                <>
                  <CustomTooltip
                    content={"Duplicate Template"}
                    tooltipType={"general"}
                    placement={"top"}
                  >
                    <div
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "5px",
                        backgroundColor: "#9C27B0",
                        padding: "6px",
                        cursor: "pointer",
                      }}
                      onClick={handleDuplicate}
                      className={!isRowSelected() ? "disabled-button" : ""}
                    >
                      <FeatherIcon
                        icon="copy"
                        width={28}
                        height={28}
                        color={"#FFF"}
                      />
                    </div>
                  </CustomTooltip>
                  <CustomTooltip
                    content={"Archive Template"}
                    tooltipType={"general"}
                    placement={"top"}
                  >
                    <div
                      style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "5px",
                        backgroundColor: "#DD5249",
                        padding: "6px",
                        cursor: "pointer",
                      }}
                      onClick={handleArchive}
                      className={
                        isButtonEnabledByPrivacyPolicy()
                          ? ""
                          : "disabled-button"
                      }
                    >
                      <FeatherIcon
                        icon="archive"
                        width={28}
                        height={28}
                        color={"#FFF"}
                      />
                    </div>
                  </CustomTooltip>
                </>
              )}

              {tab === "archive" && (
                <CustomTooltip
                  content={"Restore Template"}
                  tooltipType={"general"}
                  placement={"top"}
                >
                  <div
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "5px",
                      backgroundColor: "#4CAF50",
                      padding: "6px",
                      cursor: "pointer",
                    }}
                    onClick={handleRestore}
                    className={
                      isRowSelected() &&
                        (hasPublicPolicyExternal || hasPublicPolicyInternal)
                        ? ""
                        : "disabled-button"
                    }
                  >
                    <FeatherIcon
                      icon="upload"
                      width={28}
                      height={28}
                      color={"#FFF"}
                    />
                  </div>
                </CustomTooltip>
              )}

              {(tab === "myTemplates" || tab === "archive") && (
                <CustomTooltip
                  content={"Delete Template"}
                  tooltipType={"general"}
                  placement={"top"}
                >
                  <div
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "5px",
                      backgroundColor: "#FF5050",
                      padding: "6px",
                      cursor: "pointer",
                    }}
                    onClick={handleDelete}
                    className={
                      isButtonEnabledByPrivacyPolicy() ||
                        (tab === "myTemplates" && isRowSelected())
                        ? ""
                        : "disabled-button"
                    }
                  >
                    <FeatherIcon
                      icon="trash-2"
                      width={28}
                      height={28}
                      color={"#FFF"}
                    />
                  </div>
                </CustomTooltip>
              )}

              <div></div>
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
};

export default TemplateTable;
