import React, { useEffect, useState } from "react";
import CustomInput from "../Global Components/CustomInput";
import { getMaterialReactTableRpdColumnDef } from "../Graph Components/Chart Options/MaterialTableConfig.js";
import RPDSelectedParameters from "./RPDSelectedParameters";
import RPDRangeSelector from "./RPDRangeSelector";
import "./RPDNewRequest.css";
import CustomButton from "../Global Components/CustomButton";
import {
  getRpdResponseSize,
  postRpdEventRequest,
  postRpdRequest,
} from "../clients/RpdRequestClient";
import { Grid } from "@mui/material";
import ToggleSwitch from "../Global Components/ToggleSwitch";
import ConfirmationPopup2 from "../Global Components/ConfirmationPopup2";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import ParameterTable from "../Global Components/ParameterTable";

/**
 * View that is rendered when creating an RPD Request.
 * @param startTime Flight start time.
 * @param endTime Flight current time.
 * @param tail Aircraft's tail#.
 * @param aircraftFamily Aircraft Family.
 * @param aircraftModel Aircraft Model.
 * @param rpdRequestEnabled Flag to enable/disable the RPD Request.
 * @param rpdOverrideEnabled Flag to show the override toggle.
 * @param rpdOverrideValue Flag to know if the user is overriding the permissions.
 * @param status Flight status.
 * @param serial Aircraft Serial Number.
 * @param dataFocus Data Focus (Flight or Fault)
 * @param faultCode Fault Code.
 * @param faultTimestamp Fault Timestamp.
 * @param faultSeverity Fault Severity.
 * @param faultMessage Fault Message.
 * @param rpdEventRequestAvailable Event Request Available flag.
 * @param handleRPDOverride Function to handle the RPD Override on the parent component.
 * @param selectedParameters Selected parameters when duplicating a request.
 * @param isDuplicateRequest Duplicate Request flag.
 * @param defaultRequestName Request name to be set by default.
 * @param defaultRequestDuration Request duration to be set by default.
 * @param allParameters List of parameters available.
 * @param summaryParameters List of selected parameters.
 * @param isLoadingParameterList Loading parameters flag.
 * @param processParameterList Handle to load the parameter list on the parent component.
 * @param toggleParameterSelection Function to handle the parameter selection.
 * @param rpdRequestDisabledMessage The message to be displayed when RPD is disabled.
 * @return {JSX.Element}
 * @constructor
 */
const RPDNewRequest = ({
  startTime,
  endTime,
  tail,
  aircraftFamily,
  aircraftModel,
  rpdRequestEnabled,
  rpdOverrideEnabled,
  rpdOverrideValue,
  serial,
  dataFocus,
  faultCode,
  faultTimestamp,
  faultSeverity,
  faultMessage,
  rpdEventRequestAvailable,
  handleRPDOverride,
  selectedParameters,
  isDuplicateRequest,
  defaultRequestName,
  defaultRequestDuration,
  allParameters,
  summaryParameters,
  isLoadingParameterList,
  processParameterList,
  toggleParameterSelection,
  handleTabChange,
  handleClearParameters,
  rpdRequestDisabledMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rpdRequestName, setRpdRequestName] = useState("");
  const [rpdRequestSize, setRpdRequestSize] = useState("0 bytes");
  const [rpdRequestType, setRpdRequestType] = useState("CUSTOM");
  const [requestDuration, setRequestDuration] = useState(0);
  const [requestTimestamp, setRequestTimestamp] = useState(new moment());
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [validRpdRequest, setValidRpdRequest] = useState(false);
  let fdeColumnDefinition = getMaterialReactTableRpdColumnDef();

  const [rowSelection, setRowSelection] = useState({});
  const [oldRowSelection, setOldRowSelection] = useState({});

  // Catches the latest row selection and triggers the callback.
  useEffect(() => {
    let oldLength = Object.keys(oldRowSelection).length;
    let newLength = Object.keys(rowSelection).length;
    if (newLength > oldLength) {
      for (let key in rowSelection) {
        if (!oldRowSelection[key]) {
          toggleParameterSelection(key, allParameters[key], false);
        }
      }
    } else {
      for (let key in oldRowSelection) {
        if (!rowSelection[key]) {
          toggleParameterSelection(key, allParameters[key], true);
        }
      }
    }
    setOldRowSelection(rowSelection);
  }, [rowSelection]);

  /**
   * Reads the parameters selection and updates the row selection state.
   */
  useEffect(() => {
    const copy = { ...rowSelection };
    let updateSelection = false;
    allParameters.forEach((param, index) => {
      if (param.checked && !copy[index]) {
        copy[index] = true;
        updateSelection = true;
      } else if (!param.checked && copy[index]) {
        delete copy[index];
        updateSelection = true;
      }
    });
    if (updateSelection) {
      setRowSelection(copy);
    }
  }, [allParameters]);

  /**
   * Handles the parameter selection change
   * We use this instead of a useEffect because it
   * prevents double calling.
   * @param updater
   */
  const handleSelectionChange = (updater) => {
    setRowSelection((prevState) => {
      if (updater instanceof Function) {
        return updater(prevState)
      } else {
        return updater
      }
    });
  }

  /**
   * Hook to tell the parent component to load the parameter list.
   */
  useEffect(() => {
    const loadParamList = async () => {
      await processParameterList(tail, startTime, endTime);
    };
    if (tail) {
      let defaultRpdRequestValue = "";
      if (defaultRequestName !== "") {
        defaultRpdRequestValue = `Copy_Of_${defaultRequestName}`;
      } else {
        const currentTime = moment().utc().format("YYYYMMDDhhmmss");
        defaultRpdRequestValue = `${currentTime}_${tail}_NewRPDRequest`;
      }
      setRpdRequestName(defaultRpdRequestValue);
      loadParamList().catch(console.error);
    }
  }, [tail]);

  /**
   * Hook to select the parameters when it is a duplicate.
   */
  useEffect(() => {
    if (
      isDuplicateRequest &&
      allParameters.length > 0 &&
      summaryParameters.length === 0
    ) {
      selectedParameters.forEach((parameter) => {
        toggleParameterSelection(null, parameter, false);
      });
    }
  }, [allParameters]);

  /**
   * Hook to calculate the request size and the request validity each time the duration and / or parameters change.
   */
  useEffect(() => {
    if (
      summaryParameters.length === 0 ||
      requestDuration < 1 ||
      requestDuration > 110 ||
      rpdRequestName === ""
    ) {
      setValidRpdRequest(false);
    } else {
      setValidRpdRequest(true);
    }
  }, [summaryParameters, requestDuration, rpdRequestName, requestTimestamp]);

  useEffect(() => {
    calculateRequestSize().catch((error) => console.error(error));
  }, [summaryParameters]);

  /**
   * Calculate the request size when creating an Event Request.
   */
  useEffect(() => {
    if (rpdRequestType === "EVENT") {
      calculateRequestSize().catch((error) => console.error(error));
    }
  }, [rpdRequestType]);

  /**
   * Sets the request type when the tab in the range selector changes.
   * @param tab The tab name.
   */
  const handleTimeRangeTabChange = (tab) => {
    if (tab === "Event") {
      setRpdRequestType("EVENT");
      // When creating an EVENT request it is automatically valid
      // since the user does not have to select anything else.
      setValidRpdRequest(true);
    } else {
      setRpdRequestType("CUSTOM");
    }
  };

  /**
   * Sets the request name.
   * @param inputValue Name input value.
   */
  const handleRpdNameInputOffFocus = (inputValue) => {
    setRpdRequestName(inputValue);
  };

  /**
   * Sets the request duration.
   * @param seconds Request duration.
   */
  const handleDurationChange = (seconds) => {
    setRequestDuration(seconds);
  };

  useEffect(() => {
    const calcRequestSize = async () => {
      await calculateRequestSize();
    };
    if (
      aircraftModel &&
      aircraftFamily &&
      summaryParameters.length > 0 &&
      requestDuration > 0
    ) {
      calcRequestSize().catch((error) => {
        console.error(`Error: ${error}`);
      });
    }
  }, [requestDuration]);

  const handleRangeValuesChange = (reqTimestamp, seconds) => {
    setRequestTimestamp(reqTimestamp);
    setRequestDuration(seconds);
  };

  /**
   * Sets the request timestamp.
   * @param timestamp Request timestamp.
   */
  const handleTimestampChange = (timestamp) => {
    setRequestTimestamp(timestamp);
  };

  /**
   * Calls the api to calculate the estimated request size.
   * @return {Promise<void>}
   */
  const calculateRequestSize = async () => {
    if (aircraftFamily && aircraftModel) {
      // When the request if of type "Event" we only need to send the fault code.
      if (rpdRequestType === "EVENT") {
        await getRpdResponseSize(
          aircraftFamily,
          aircraftModel,
          null,
          null,
          faultCode,
          null
        )
          .then((res) => {
            const sizeFormatted = formatRequestSize(
              res.data.estimatedSizeInBytes
            );
            setRpdRequestSize(sizeFormatted);
          })
          .catch((error) => {
            console.error(`Error : ${error}`);
            const sizeFormatted = formatRequestSize(0);
            setRpdRequestSize(sizeFormatted);
          });
      } else if (summaryParameters.length > 0) {
        await getRpdResponseSize(
          aircraftFamily,
          aircraftModel,
          requestDuration,
          summaryParameters.map((parameter) => parameter.parameterName),
          null,
          null
        )
          .then((res) => {
            const sizeFormatted = formatRequestSize(
              res.data.estimatedSizeInBytes
            );
            setRpdRequestSize(sizeFormatted);
          })
          .catch((error) => {
            console.error(`Error : ${error}`);
            const sizeFormatted = formatRequestSize(0);
            setRpdRequestSize(sizeFormatted);
          });
      } else {
        const sizeFormatted = formatRequestSize(0);
        setRpdRequestSize(sizeFormatted);
      }
    }
  };

  /**
   * Helper to format the estimated request size.
   * @param bytes Request size in bytes.
   * @return {string}
   */
  const formatRequestSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1000;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  /**
   * Open the confirmation popup.
   */
  const openConfirmationPopup = () => {
    setShowConfirmationPopup(true);
  };

  /**
   * Close the confirmation popup.
   */
  const closeConfirmationPopup = () => {
    setShowConfirmationPopup(false);
  };

  /**
   * Sends the RPD Request.
   * @return {Promise<void>}
   */
  const sendRpdRequest = async () => {
    setShowConfirmationPopup(false);
    setIsLoading(true);
    // When the request is of type "Event" we only send the fault code and
    // fault timestamp.
    if (rpdRequestType === "EVENT") {
      try {
        await postRpdEventRequest(
          true,
          tail,
          faultCode,
          requestTimestamp.format("YYYY-MM-DD HH:mm:ss"),
          aircraftFamily,
          aircraftModel,
          rpdRequestName
        );

        setIsLoading(false);
        handleTabChange("RPD LOG");
      } catch (error) {
        setIsLoading(false);
        console.error("ERROR: ", error);
      }
    }
    // If it is a custom request then we send the parameters, duration and timestamp.
    else {
      try {
        const requestParameters = [];
        summaryParameters.forEach((parameter) => {
          requestParameters.push({
            parameterId: parameter.parameterName,
          });
        });

        await postRpdRequest(
          true,
          tail,
          dataFocus,
          faultCode,
          requestTimestamp.format("YYYY-MM-DD HH:mm:ss"),
          requestDuration,
          requestParameters,
          aircraftFamily,
          aircraftModel,
          rpdRequestName
        );

        setIsLoading(false);
        handleClearParameters();
        handleTabChange("RPD LOG");
      } catch (error) {
        setIsLoading(false);
        console.error("ERROR: ", error);
      }
    }
  };

  const handleInputChange = () => {
    setValidRpdRequest(false);
  };

  const closeRPDView = () => {
    window.history.back();
  };


  /**
   * Renders the UI for a new RPD Request.
   * @return {JSX.Element}
   */
  const loadNewRPDEnabled = () => {
    return (
      <div>
        <Grid container spacing={0}>
          <Grid
            item
            xs={9}
            container
            spacing={0}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <div className="rpdView__headerTitle">
                Choose parameters and time range plus name for a new RPD
                request:
              </div>
            </Grid>
          </Grid>
          <Grid item xs={3} justifyContent="flex-end" alignItems="center">
            <Grid item xs justifyContent="flex-end" alignItems="center">
              {rpdOverrideEnabled ? (
                <div className="rpdOverride__sectionValue rpdOverride__font">
                  <span className="rpdOverride__sectionValue__text">
                    RPD Override {rpdOverrideValue ? "ON" : "OFF"}
                  </span>
                  <ToggleSwitch
                    id="rpd-override-switch"
                    isChecked={rpdOverrideValue}
                    handleSwitchChange={handleRPDOverride}
                    isDisabled={false}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className="rpdView__body">
          <div className="rpdView__rpdListContainer">
            <div className="rpdView__rpdListContainer__body">
              <div className="rpdView__rpdNameContainer">
                <CustomInput
                  id={"rpdName"}
                  defaultValue={rpdRequestName}
                  handleInputOffFocus={handleRpdNameInputOffFocus}
                  placeholder={"New RPD Request Name"}
                />
              </div>
              <div className="rpdView__parameterListContainer">
                {isLoadingParameterList || isLoading ? (
                  <div className="rpdView__parameterTableContainer">
                    <p className="rpdView__center__label__table">
                      Loading Parameter List...
                    </p>
                  </div>
                ) : (
                  <div className="rpdView__fullWidthContainer">
                    <div className="rpdView__parameterTableContainer">
                      <ParameterTable
                        columns={fdeColumnDefinition}
                        data={allParameters}
                        externalSelectionState={rowSelection}
                        handleExternalSelectionChange={handleSelectionChange}
                      /*disableRowSelection={
                        rpdRequestType === "EVENT" ? true : false
                      }*/
                      />
                      {/*<TemplateTable
                        //data={allParameters}
                        type={"load_parameter_table"}
                        columns={getMaterialTableColumnDef(
                          toggleParameterSelection,
                          rpdRequestType === "EVENT"
                        )}
                        tableName={"Continuously Recorded Parameters"}
                        />*/}
                    </div>
                    <div className={"rpdView__parameterSummary"}>
                      <RPDSelectedParameters
                        summaryParameters={summaryParameters}
                        handleRemoveParameterFromList={toggleParameterSelection}
                        rpdRequestType={rpdRequestType}
                      />
                      <RPDRangeSelector
                        startTime={startTime}
                        endTime={endTime}
                        defaultRequestDuration={defaultRequestDuration}
                        dataFocus={dataFocus}
                        faultTimestamp={faultTimestamp}
                        faultSeverity={faultSeverity}
                        faultMessage={faultMessage}
                        isRPDEventRequestAvailable={
                          dataFocus === "FAULT" && rpdEventRequestAvailable
                        }
                        handleTabChange={handleTimeRangeTabChange}
                        handleRangeValuesChange={handleRangeValuesChange}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                    <div className="rpdView__footer">
                      <div className="rpdView__footer__estimatedSize">
                        <p>
                          <span>{rpdRequestSize}</span>&emsp;&emsp;Estimated RPD
                          Size
                        </p>
                      </div>
                      <div className="rpdButtonsContainer">
                        <p>
                          <FeatherIcon
                            icon="alert-triangle"
                            width={15}
                            height={15}
                            color={"#ffc72c"}
                          />
                          &ensp;RPD Requests may result in additional charges
                          from your satcom service provider.
                        </p>
                        <CustomButton
                          customButtonColor={"#606060"}
                          customButtonTextColor={"#FFF"}
                          id={"btn-rpd-cancel-request"}
                          text={"CANCEL"}
                          onClickAction={() => {
                            closeRPDView();
                          }}
                        />
                        <CustomButton
                          id={"btn-rpd-send-request"}
                          text={"SUBMIT RPD REQUEST"}
                          isDisabled={
                            !validRpdRequest && rpdRequestType !== "EVENT"
                          }
                          onClickAction={openConfirmationPopup}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ConfirmationPopup2
          active={showConfirmationPopup}
          title={"Confirm RPD Request"}
          descriptionText={`You are about to send an RPD Request named "${rpdRequestName}" to the aircraft #${serial}.`}
          confirmationText={"Would you like to proceed?"}
          additionalInfo={
            "*Note that additional fees may be charged by your satcom provider."
          }
          hasIcon={true}
          icon={
            <FeatherIcon
              icon="info"
              width={43.33}
              height={43.33}
              color={"#FFF"}
            />
          }
          cancelText={"CANCEL"}
          acceptText={"SUBMIT"}
          handleSubmitCancel={closeConfirmationPopup}
          handleSubmitOk={sendRpdRequest}
        />
      </div>
    );
  };

  /**
   * Renders the UI for when the RPD capability is disabled.
   * @return {JSX.Element}
   */
  const loadNewRPDDisabled = () => {
    return (
      <div>
        <Grid container spacing={0}>
          <Grid
            item
            xs={9}
            container
            spacing={0}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <div className="rpdView__headerTitleBold">
                You cannot perform an RPD Request at this time.
              </div>
            </Grid>
            <Grid item xs={12}>
              {rpdOverrideEnabled ? (
                <div className="rpdView__headerTitle">
                  Turn on RPD override if you wish to perform an RPD request
                  anyway.
                </div>
              ) : (
                <div className="rpdView__headerTitle">
                  {rpdRequestDisabledMessage}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs justifyContent="flex-end" alignItems="center">
              {rpdOverrideEnabled ? (
                <div className="rpdOverride__sectionValue rpdOverride__font">
                  <span className="rpdOverride__sectionValue__text">
                    RPD Override {rpdOverrideValue ? "ON" : "OFF"}
                  </span>
                  <ToggleSwitch
                    id="rpd-override-switch"
                    isChecked={rpdOverrideValue}
                    handleSwitchChange={handleRPDOverride}
                    isDisabled={false}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className="rpdView__body">
          <div className="rpdView__rpdListContainer">
            <div className="rpd__not__available__container">
              <p>RPD Request Not Available</p>
              <div className="rpdView__footer not__available__footer">
                <div className="rpdView__footer__estimatedSize" />
                <div className="rpdButtonsContainer">
                  <CustomButton
                    customButtonColor={"#606060"}
                    customButtonTextColor={"#FFF"}
                    id={"btn-rpd-cancel-request"}
                    onClickAction={() => {
                      closeRPDView();
                    }}
                    text={"CANCEL"}
                  />
                  <CustomButton
                    id={"btn-rpd-send-request"}
                    text={"SUBMIT RPD REQUEST"}
                    isDisabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <>{rpdRequestEnabled ? loadNewRPDEnabled() : loadNewRPDDisabled()}</>;
};

export default RPDNewRequest;
