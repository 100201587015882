import React from "react";
import "./ConfigOptionsTable.css";
import _ from "lodash";
import ConfigOptionsRow from "./ConfigOptionsRow";
export default function ConfigViewOptionsTable({
  configOptionsList,
  activeOptionId,
}) {

  const renderConfigOptionsRows = (type) => {
    return (
      <div className="configOptionsTable__rows" id="configOptionsTable__rows">
        {_.orderBy(configOptionsList, ["order"], ["asc"])
            .filter((opt) => {return opt.type === type}).map((option) => (
          <React.Fragment key={option.id}>
            <ConfigOptionsRow
              title={option.title}
              icon={option.icon}
              isActive={option.id === activeOptionId}
              onClickEvent={option.onClickEvent}
            />
          </React.Fragment>
        ))}
      </div>
    );
  };
  return (
    <div className="configOptionsContainer">
      <h1 className="configOptionsTableTitle">AIRCRAFT CONFIGURATION</h1>
      <div className="configOptionsTable">
        {renderConfigOptionsRows("AIRCRAFT")}
      </div>
      <h1 className="configOptionsTableTitle">USER CONFIGURATION</h1>
      <div className="configOptionsTable">
        {renderConfigOptionsRows("USER")}
      </div>
    </div>
  )


}