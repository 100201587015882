import React, { useEffect, useMemo, useState } from 'react'
import '../styles/Graph Components/TemplateTable.css'
import {
    MaterialReactTable,
    MRT_GlobalFilterTextField,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    useMaterialReactTable
} from 'material-react-table'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { getCmsAdditionalInfoRender } from '../Graph Components/Chart Options/MaterialTableConfig'

/**
 * React Material Table global component for parameters.
 * @param columns The column definition.
 * @param data The table data.
 * @param dataFocus The data focus (i.e. RPD, Event, FDE, etc...)
 * @param getCmsParameterInfo (Optional) The function to get any CMS Parameter additional data.
 * @param disableRowSelection Flag to disable the row selection capability.
 * @param externalSelectionState The table selection state, this has to be externally managed.
 * @param handleExternalSelectionChange The handler for when a selection update happens.
 * @returns {Element}
 * @constructor
 */
export const ParameterTable = ({
    columns,
    data,
    dataFocus,
    getCmsParameterInfo,
    disableRowSelection = false,
    externalSelectionState,
    handleExternalSelectionChange
}) => {
    const [rowSelection, setRowSelection] = useState(externalSelectionState)
    const [expanded, setExpanded] = useState({})

    /**
     * Select some rows by default on first load.
     */
    useEffect(() => {
        setRowSelection(externalSelectionState)
    }, [externalSelectionState])

    /**
     * Handles the table behavior when a row is expanded.
     * It only applies for CMS parameters.
     * @param updater The "expanded" updated value.
     */
    const handleExpandedChange = updater => {
        // First we update the expanded state to reflect changes in the table.
        setExpanded(prevState => {
            if (updater instanceof Function) {
                return updater(prevState)
            } else {
                return updater
            }
        })
        //The updater attribute will be undefined when the user closes an expanded row.
        //If the updater attribute returns a value it means that the user expanded a row,
        //this is when we want to get the additional CMS Parameter Info to be displayed.
        const expandedValue = updater()
        if (expandedValue) {
            const selectedIndex = Object.keys(expandedValue)[0]
            const parameterName = data[selectedIndex].parameterName
            getCmsParameterInfo(parameterName, selectedIndex)
        }
    }

    // Custom theme for the table.
    const tableTheme = useMemo(() =>
        createTheme({
            palette: {
                background: {
                    default: '#101010'
                },
                secondary: {
                    main: '#101010',
                    contrastText: '#FFFFFF'
                },
                primary: {
                    main: '#101010',
                    light: '#101010',
                    dark: '#101010',
                    contrastText: '#FFFFFF'
                }
            },
            typography: {
                allVariants: {
                    color: '#FFF'
                }
            },
            components: {
                MuiTableCell: {
                    defaultProps: {
                        sx: {
                            color: '#FFF'
                        }
                    },
                    styleOverrides: {
                        root: {
                            color: '#FFF'
                        }
                    }
                },
                MuiSvgIcon: {
                    styleOverrides: {
                        root: {
                            color: '#FFF',
                            fill: '#FFF'
                        }
                    }
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            background: 'rgb(25,25,25) !important'
                        }
                    }
                },
                MuiTableHead: {
                    styleOverrides: {
                        root: {
                            background: 'rgb(25,25,25) !important'
                        }
                    }
                },

                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#101010 !important'
                        }
                    }
                },
                MuiList: {
                    styleOverrides: {
                        root: {
                            background: 'rgb(25,25,25)'
                        }
                    }
                }
            }
        })
    )

    /**
     * The table definition.
     */
    const table = useMaterialReactTable({
        columns,
        data,
        autoResetPageIndex: false,
        enableColumnOrdering: false,
        enableRowSelection: !disableRowSelection,
        onRowSelectionChange: handleExternalSelectionChange,
        filterFns: {
            customContainsFn: (row, id, filterValue) => {
                const value = row.getValue(id) ?? ''

                return value?.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
            }
        },
        globalFilterFn: 'customContainsFn',
        initialState: {
            showColumnFilters: true,
            showGlobalFilter: true,
            pagination: {
                pageSize: 5,
                pageIndex: 0
            }
        },
        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 20, 50]
        },
        enableHiding: false,
        enableFullScreenToggle: false,
        enableSelectAll: false,
        enableExpandAll: false,
        muiSearchTextFieldProps: {
            sx: {
                border: '1px solid #949494',
                borderRadius: '5px',
                color: '#FFF',
                marginTop: '10px',
                marginBottom: '10px',
                paddingTop: '5px !important',
                paddingBottom: '5px !important',
                '::placeholder': 'color: #949494;',
                ':focus': 'border: 2px solid #4A90E2 !important;',
                ':hover': 'border: 2px solid #AFD4FF !important;'
            },
            variant: 'outlined'
        },
        localization: {
            filterCustomContainsFn: 'contains'
        },
        muiFilterTextFieldProps: {
            sx: {
                border: '1px solid #949494',
                borderRadius: '5px',
                color: '#FFF',
                marginTop: '10px',
                marginBottom: '10px',
                '::placeholder': 'color: #949494;',
                ':focus': 'border: 2px solid #4A90E2 !important;',
                ':hover': 'border: 2px solid #AFD4FF !important;'
            },
            variant: 'outlined'
        },
        state: { rowSelection, expanded },
        onExpandedChange: handleExpandedChange,
        positionToolbarAlertBanner: 'none',
        renderTopToolbar: ({ table }) => {
            return (
                <Box
                    sx={theme => ({
                        display: 'flex',
                        gap: '0.5rem',
                        justifyContent: 'space-between',
                        paddingLeft: '10px',
                        paddingRight: '10px'
                    })}
                >
                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <MRT_GlobalFilterTextField table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                    </Box>
                </Box>
            )
        },
        // This function will only apply for CMS Parameters.
        renderDetailPanel:
            dataFocus === 'CMS'
                ? ({ row }) => {
                      return (
                          <Box
                              sx={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  alignItems: 'center',
                                  flexFlow: 'row wrap'
                              }}
                          >
                              {row.original.info ? (
                                  <>
                                      {row.original.info.error ? (
                                          <Box sx={{ textAlign: 'center', width: '100%' }}>
                                              <Typography variant='h6'>Not Applicable</Typography>
                                          </Box>
                                      ) : (
                                          getCmsAdditionalInfoRender(row.original.info)
                                      )}
                                  </>
                              ) : (
                                  <>
                                      <Box sx={{ textAlign: 'center', width: '100%' }}>
                                          <Typography variant='h6'>Loading...</Typography>
                                      </Box>
                                  </>
                              )}
                          </Box>
                      )
                  }
                : null
    })

    return (
        <ThemeProvider theme={tableTheme}>
            <MaterialReactTable table={table} />
        </ThemeProvider>
    )
}

export default ParameterTable
