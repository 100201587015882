import React, { Component } from 'react'
import './ContentTOReport.css'
import AirframeUIConfigs from '../resources/AirframeUIConfigs.json'

export default class ContentTOReport extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const takeoffReportFields = this.getTakeoffReportFields(
            this.props.aircraftFamily,
            this.props.aircraftModel
        )

        // https://bbadigital.atlassian.net/browse/MYB-6219
        if (
            [
                'Global 5000 Vision',
                'Global 6000',
                'Challenger 605',
                'Challenger 650',
                'Global Express XRS',
                'Global 5000'
            ].includes(this.props.aircraftFamily + ' ' + this.props.aircraftModel)
        ) {
            return (
                <div style={{ width: '100%' }}>
                    <div
                        style={{
                            backgroundColor: 'black',
                            height: '100%',
                            paddingLeft: '30px',
                            paddingTop: '30px'
                        }}
                    >
                        Takeoff report will be available soon.
                    </div>
                </div>
            )
        }
        if (this.props.flight !== null && this.props.flight !== undefined) {
            return (
                <div className='flex-row-simple takeoff-report-body takeoff-report-body-font'>
                    <div className='flex-column-simple takeoff-report-column-left'>
                        {takeoffReportFields.map((field, i) => {
                            // The first half of the fields have to be displayed
                            // on the left column.
                            if (i < takeoffReportFields.length / 2) {
                                return this.processIndividualField(field)
                            }
                        })}
                    </div>
                    <div className='flex-column-simple takeoff-report-column-right'>
                        {takeoffReportFields.map((field, i) => {
                            // The second half will be displayed on the right column.
                            if (i >= takeoffReportFields.length / 2) {
                                return this.processIndividualField(field)
                            }
                        })}
                    </div>
                </div>
            )
        }
        return null
    }

    /**
     * Process a single field to be displayed in the TO Report.
     * @param field field to be processed (from Airframe config file).
     * @return {*} the data row to be displayed.
     */
    processIndividualField(field) {
        // Gets the value from the API.
        let apiFieldValue = this.props.flight[field.apiFieldName]
        if (!apiFieldValue) {
            apiFieldValue = '-'
        }
        let units = ''
        let value = ''
        // If the units come from the api value then we use that.
        // The API format when units are included is "value : units"
        if (apiFieldValue.includes(':')) {
            value = apiFieldValue.split(':')[0].trim()
            units = apiFieldValue.split(':')[1].trim()
        }
        // If the units are not part of the API response then we use the
        // value in the configuration file.
        else {
            value = apiFieldValue
            units = apiFieldValue === '-' ? '' : field.units
        }
        // Checks if the value needs to be formatted according to the
        // airframe configuration file.
        value = field.formatValue ? this.formatValues(field.formatType, value) : value
        return this.addDataRow(field.displayName, value, units)
    }

    formatValues(type, value) {
        if (value !== null && value !== '') {
            if (type === 'CENTER_GRAVITY') {
                return value.slice(0, -1) + '.' + value.substr(-1)
            }
        }
        return ''
    }

    addDataRow(key, value, unit = '') {
        return (
            <React.Fragment key={key}>
                <div className='flex-row-simple takeoff-report-row'>
                    {key !== '' ? <div className='takeoff-report-row-key'>{key + ':'}</div> : ''}
                    <div>
                        {value && value !== '' ? value + (value !== '' ? ' ' + unit : '') : '-'}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    /**
     * Reads the airframe configuration file for the selected
     * aircraft family and model, and returns the list of fields to be
     * displayed in the TO Report.
     * @param aircraftFamily the aircraft family.
     * @param aircraftModel the aircraft model.
     * @return {*|*[]} List of fields to be displayed.
     */
    getTakeoffReportFields(aircraftFamily, aircraftModel) {
        return AirframeUIConfigs[aircraftFamily][aircraftModel]
            ? AirframeUIConfigs[aircraftFamily][aircraftModel].aircraftViewPage.takeoffReportFields
            : []
    }
}
