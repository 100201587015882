import React from "react";
import "./TitleCard.css";

export default function TitleCard(props) {
  return (
    <div className="titleCardContainer">
      {props.content.body}
      {props.content.footer}
    </div>
  );
}
