import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import Card from "../Global Components/Card";
import "../styles/Global Components/Button.css";
import "./ParameterChartsRouting.css";
import _ from "lodash";
import CustomTooltip from "../Global Components/CustomTooltip";
import * as featureToggleTools from "../Feature Toggles/FeatureToggleTools";
import { sendChartEventParamsEvent, sendChartContParamsEvent, sendChartFaultParamsEvent, sendChartFullFlightParamsEvent } from "../GA4/EventsCatalog";

export default class ParameterChartsRouting extends Component {
  constructor(props) {
    super(props);
    this.hasRDPCardHtml = this.hasRDPCardHtml.bind(this);
  }

  hasInFlightParameterCard() {
    if (this.props.hasParameterDataDictionary) {
      if (this.props.selectedTab === "EIFR") {
        return "";
      }

      if (
        this.props.fdeInfoFault &&
        this.props.fdeInfoFault?.hasInFlightParameterData &&
        this.props.hasInFlightData &&
        this.props.viewType === "IFR"
      ) {
        return (
          <RouterLink
            to={{
              pathname: "/ahmsview",
              state: {
                dataFocus: "IN-FLIGHT",
                tail: this.props.tail,
                aircraftFamily: this.props.aircraftFamily,
                takeoffReport: this.props.takeOffReport,
                aircraftModel: this.props.aircraftModel,
                status: this.props.fdeInfoFault?.status,
                ahmsDate: this.props.fdeInfoFault?.ahmsDate,
                arrivalTime: this.props.arrivalTime,
                departureTime: this.props.departureTime,
                aircraftSession: this.props.aircraftSession,
                faultCode: this.props.fdeInfoFault?.faultCode,
                recentSessionOrder: this.props.recentSessionOrder,
                faultSeverity: this.props.fdeInfoFault?.faultSeverity,
                faultTimestamp: this.props.fdeInfoFault?.faultTimestamp,
                faultMessage: this.props.fdeInfoFault?.faultMessage,
                faultDescription: this.props.fdeInfoFault?.faultDescription,
                serial: this.props.serial,
                monitorStatus: this.props.monitorStatus,
                eventUid: this.props.fdeInfoFault?.eventUid,
                eventStartTime: this.props.fdeInfoFault?.eventStartTime,
                eventEndTime: this.props.fdeInfoFault?.eventEndTime,
                timeInFlight: this.props.timeInFlight,
                latestDataTransferDate: this.props.latestDataTransferDate,
                hasLatestHmuTransferDate: this.props.hasLatestHmuTransferDate,
                currentAircraftStatus: this.props.status,
              },
            }}
            onClick={() => sendChartFaultParamsEvent()}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Card
              id="card_ahms_data"
              text={"Chart Fault Parameters"}
              featherIconName={"activity"}
              tooltipText={"Click to load In-Flight FDE Event Parameters."}
              hasInfo={true}
            ></Card>
          </RouterLink>
        );
      }
    }
  }

  hasParameterCard() {
    if (this.props.hasParameterDataDictionary) {
      if (this.props.selectedTab === "EIFR") {
        return "";
      }

      if (this.props.activeTab === "MDC") {
        return (
          <Card
            id="card_ahms_data"
            text={"Chart Full Flight parameters"}
            featherIconName={"bar-chart"}
            disabled={true}
          ></Card>
        );
      }
      if (
        this.props.viewType === "HISTORIC" &&
        this.props.selectedTab !== "CMS"
      ) {
        if (
          this.props.infoBoxType !== "FLIGHT" &&
          this.props.infoBoxType !== "CMS" &&
          this.props.infoBoxType !== "INFO" &&
          this.props.infoBoxType !== "STATUS"
        ) {
          let omsFaultList = null;

          let faultIndex = _.findIndex(this.props.fdecasList, (f) => {
            return f.faultCode === this.props.fdeInfoFault?.faultCode;
          });

          if (faultIndex !== -1) {
            omsFaultList = this.props.fdecasList[faultIndex]?.allOmsMessages;
          }

          return (
            <CustomTooltip
              tooltipType={"general"}
              content={
                this.props.fdeInfoFault?.hasInFlightParameterData
                  ? "Click to chart continuous and event parameters."
                  : "Click to chart continous parameters"
              }
              placement={"top-end"}
            >
              <RouterLink
                to={{
                  pathname: "/ahmsview",
                  state: {
                    dataFocus: "FAULT",
                    tail: this.props.tail,
                    aircraftFamily: this.props.aircraftFamily,
                    takeoffReport: this.props.takeOffReport,
                    aircraftModel: this.props.aircraftModel,
                    status: this.props.fdeInfoFault?.status,
                    ahmsDate: this.props.fdeInfoFault?.ahmsDate,
                    arrivalTime: this.props.arrivalTime,
                    departureTime: this.props.departureTime,
                    aircraftSession: this.props.aircraftSession,
                    faultCode: this.props.fdeInfoFault?.faultCode,
                    recentSessionOrder: this.props.recentSessionOrder,
                    faultSeverity: this.props.fdeInfoFault?.faultSeverity,
                    faultTimestamp: this.props.fdeInfoFault?.faultTimestamp,
                    faultMessage: this.props.fdeInfoFault?.faultMessage,
                    faultDescription: this.props.fdeInfoFault?.faultDescription,
                    serial: this.props.serial,
                    monitorStatus: this.props.monitorStatus,
                    omsFaultList: omsFaultList,
                    eventUid: this.props.fdeInfoFault?.eventUid,
                    eventStartTime: this.props.fdeInfoFault?.eventStartTime,
                    eventEndTime: this.props.fdeInfoFault?.eventEndTime,
                    timeInFlight: this.props.timeInFlight,
                    latestDataTransferDate: this.props.latestDataTransferDate,
                    hasLatestHmuTransferDate: this.props
                      .hasLatestHmuTransferDate,
                    currentAircraftStatus: this.props.status,
                  },
                }}
                onClick={ () => 
                  this.props.fdeInfoFault?.hasInFlightParameterData
                    ? sendChartEventParamsEvent() 
                    : sendChartContParamsEvent()
                }
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Card
                  id="card_ahms_data"
                  text={
                    this.props.fdeInfoFault?.hasInFlightParameterData
                      ? "Chart Event parameters"
                      : "Chart Cont. parameters"
                  }
                  featherIconName={
                    this.props.fdeInfoFault?.hasInFlightParameterData
                      ? "activity"
                      : "bar-chart-2"
                  }
                />
              </RouterLink>
            </CustomTooltip>
          );
        } else if (
          this.props.infoBoxType === "FLIGHT" &&
          this.props.departureTime != null &&
          this.props.arrivalTime != null
        ) {
          return (
            <CustomTooltip
              tooltipType={"general"}
              content={"Click to chart parameters from the entire flight."}
              placement={"top-end"}
            >
              <RouterLink
                to={{
                  pathname: "/ahmsview",
                  state: {
                    dataFocus: "FLIGHT",
                    status: this.props.status,
                    aircraftSession: this.props.aircraftSession,
                    tail: this.props.tail,
                    aircraftFamily: this.props.aircraftFamily,
                    aircraftModel: this.props.aircraftModel,
                    departureTime: this.props.departureTime,
                    arrivalTime: this.props.arrivalTime,
                    serial: this.props.serial,
                    monitorStatus: this.props.monitorStatus,
                    departureAirportName: this.props.departureAirportName,
                    departureAirportIATA: this.props.departureAirportIATA,
                    arrivalAirportName: this.props.arrivalAirportName,
                    arrivalAirportIATA: this.props.arrivalAirportIATA,
                    timeInFlight: this.props.timeInFlight,
                    latestDataTransferDate: this.props.latestDataTransferDate,
                    hasLatestHmuTransferDate: this.props
                      .hasLatestHmuTransferDate,
                  },
                }}
                onClick={() => sendChartFullFlightParamsEvent()}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Card
                  id="card_ahms_data"
                  text={"Chart Full Flight parameters"}
                  featherIconName={"bar-chart"}
                />
              </RouterLink>
            </CustomTooltip>
          );
        } else {
          return (
            <Card
              id="card_ahms_data"
              text={"Chart Full Flight parameters"}
              featherIconName={"bar-chart"}
              disabled={true}
            ></Card>
          );
        }
      } else {
        if (!this.props.fdeInfoFault && this.props.selectedTab !== "CMS") {
          return (
            <Card
              id="card_ahms_data"
              tooltipText={
                "You may load Full Flight data for Historical flights only."
              }
              hasInfo={true}
              text={"Chart Full Flight parameters"}
              featherIconName={"bar-chart"}
              disabled={true}
            ></Card>
          );
        }
      }
    }
  }

  hasCMSCardHtml() {
    if (
      this.props.selectedTab === "CMS" &&
      this.props.cmsInfoFault &&
      this.props.viewType === "HISTORIC"
    ) {
      return (
        <RouterLink
          to={{
            pathname: "/ahmsview",
            state: {
              dataFocus: "CMS",
              tail: this.props.tail,
              aircraftFamily: this.props.aircraftFamily,
              takeoffReport: this.props.takeOffReport,
              aircraftModel: this.props.aircraftModel,
              status: this.props.status,
              arrivalTime: this.props.arrivalTime,
              departureTime: this.props.departureTime,
              aircraftSession: this.props.aircraftSession,
              faultCode: this.props.cmsInfoFault?.faultCode,
              recentSessionOrder: this.props.recentSessionOrder,
              faultSystem: this.props.cmsInfoFault?.faultSystem,
              faultTimestamp: this.props.cmsInfoFault?.faultTimestamp,
              faultMessage: this.props.cmsInfoFault?.faultMessage,
              faultDescription: this.props.cmsInfoFault?.faultDescription,
              faultDafaultSystemId: this.props.cmsInfoFault?.faultSystemId,
              serial: this.props.serial,
              monitorStatus: this.props.monitorStatus,
              timeInFlight: this.props.timeInFlight,
              latestDataTransferDate: this.props.latestDataTransferDate,
              hasLatestHmuTransferDate: this.props.hasLatestHmuTransferDate,
            },
          }}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Card
            id="card_ahms_data"
            text={"CMS Fault Data"}
            featherIconName={"alert-triangle"}
          ></Card>
        </RouterLink>
      );
    } else if (
      this.props.selectedTab === "CMS" &&
      !this.props.cmsInfoFault &&
      this.props.viewType === "HISTORIC"
    ) {
      if (this.props.cmsSessionsList.length > 0) {
        return (
          <RouterLink
            to={{
              pathname: "/ahmsview",
              state: {
                dataFocus: "CMS",
                tail: this.props.tail,
                aircraftFamily: this.props.aircraftFamily,
                takeoffReport: this.props.takeOffReport,
                cmsSessionsList: this.props.cmsSessionsList,
                aircraftModel: this.props.aircraftModel,
                status: this.props.status,
                arrivalTime: this.props.arrivalTime,
                departureTime: this.props.departureTime,
                aircraftSession: this.props.aircraftSession,
                faultCode: "N/A",
                recentSessionOrder: this.props.recentSessionOrder,
                faultSystem: "All Systems",
                faultTimestamp: null,
                faultMessage: null,
                faultDescription: null,
                faultDafaultSystemId: null,
                serial: this.props.serial,
                monitorStatus: this.props.monitorStatus,
                timeInFlight: this.props.timeInFlight,
                latestDataTransferDate: this.props.latestDataTransferDate,
                hasLatestHmuTransferDate: this.props.hasLatestHmuTransferDate,
              },
            }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Card
              id="card_ahms_data"
              text={"CMS Session Data"}
              featherIconName={"alert-triangle"}
            ></Card>
          </RouterLink>
        );
      } else {
        return (
          <Card
            id="card_ahms_data"
            text={"CMS Session Data"}
            featherIconName={"alert-triangle"}
            disabled={true}
          ></Card>
        );
      }
    }
  }

  hasRDPCardHtml() {
    if (this.props.hasRPDFunctionality) {
      if (
        this.props.infoBoxType !== "FLIGHT" &&
        this.props.infoBoxType !== "CMS" &&
        this.props.infoBoxType !== "INFO" &&
        this.props.infoBoxType !== "STATUS" &&
        this.props.selectedTab !== "CMS"
      ) {
        return (
          <RouterLink
            to={{
              pathname: "/rpdview",
              state: {
                dataFocus: "FAULT",
                tail: this.props.tail,
                aircraftFamily: this.props.aircraftFamily,
                aircraftModel: this.props.aircraftModel,
                status: this.props.status,
                serial: this.props.serial,
                aircraftSession: this.props.aircraftSession,
                monitorStatus: this.props.monitorStatus,
                startTime: this.props.rpdStartTime,
                endTime: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
                faultCode: this.props.fdeInfoFault.faultCode,
                faultSeverity: this.props.fdeInfoFault?.faultSeverity,
                faultTimestamp: this.props.fdeInfoFault?.faultTimestamp,
                faultMessage: this.props.fdeInfoFault?.faultMessage,
                faultDescription: this.props.fdeInfoFault?.faultDescription,
                rpdEventRequestAvailable: this.props.fdeInfoFault
                  ?.rpdEventRequestAvailable,

                externalRpdRequest: this.props.externalRpdRequest,
                rpdSatcomSupport: this.props.rpdSatcomSupport,
                timeInFlight: this.props.timeInFlight,
                latestDataTransferDate: this.props.latestDataTransferDate,
                hasLatestHmuTransferDate: this.props.hasLatestHmuTransferDate,
              },
            }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Card
              id="card_rpd_request"
              textTitle={"RPD"}
              featherIconName={"rss"}
              informativeMessage={
                !this.props.rpdSatcomSupport
                  ? "Some tails might not have RPD capacity. Data will be transmitted after the plane has landed with WiFi capability."
                  : null
              }
            ></Card>
          </RouterLink>
        );
      } else {
        return (
          <RouterLink
            to={{
              pathname: "/rpdview",
              state: {
                dataFocus: "FLIGHT",
                tail: this.props.tail,
                aircraftFamily: this.props.aircraftFamily,
                aircraftModel: this.props.aircraftModel,
                status: this.props.status,
                serial: this.props.serial,
                aircraftSession: this.props.aircraftSession,
                monitorStatus: this.props.monitorStatus,
                startTime: this.props.rpdStartTime,
                endTime: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
                departureAirportName: this.props.departureAirportName,
                departureAirportIATA: this.props.departureAirportIATA,
                arrivalAirportName: this.props.arrivalAirportName,
                arrivalAirportIATA: this.props.arrivalAirportIATA,
                externalRpdRequest: this.props.externalRpdRequest,
                rpdSatcomSupport: this.props.rpdSatcomSupport,
                timeInFlight: this.props.timeInFlight,
                latestDataTransferDate: this.props.latestDataTransferDate,
                hasLatestHmuTransferDate: this.props.hasLatestHmuTransferDate,
              },
            }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Card
              id="card_rpd_request"
              textTitle={"RPD"}
              featherIconName={"rss"}
              informativeMessage={
                !this.props.rpdSatcomSupport
                  ? "Some tails might not have RPD capacity. Data will be transmitted after the plane has landed with WiFi capability."
                  : null
              }
            ></Card>
          </RouterLink>
        );
      }
    }
  }

  hasEventCountCardHtml() {
    const hasSupportedEvents =
      this.props.aircraftUIConfig &&
      Object.keys(this.props.aircraftUIConfig?.globalApp).length !== 0 &&
      this.props.aircraftUIConfig?.globalApp?.supportedEvents?.length !== 0;

    if (featureToggleTools.isFeatureToggleActive("ft-event-history")) {
      if (hasSupportedEvents) {
        return (
          <div onClick={this.props.toggleModal}>
            <Card
              id="card_event_count"
              textTitle="Event History"
              featherIconName={"layers"}
            />
          </div>
        );
      } else {
        return (
          <Card
            id="card_event_count"
            textTitle="Event History"
            featherIconName={"layers"}
            disabled
          />
        );
      }
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="ahms-rpd-button-paddings">
        {this.hasRDPCardHtml()}
        {this.hasParameterCard()}
        {this.hasCMSCardHtml()}
        {this.hasInFlightParameterCard()}
        {this.hasEventCountCardHtml()}
      </div>
    );
  }
}
