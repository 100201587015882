import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";
import _ from "lodash";

/*
  Client for the event list API that include:
  - FDE/CAS
  - OMS/MDC/CAIMS
  - CMS
  - Historic event counts for all the event types menitoned above
*/

const FAULTS_FDE = Api.FAULTS_FDE;
const FAULTS_OMS = Api.FAULTS_OMS;
const FAULTS_CMS = Api.FAULTS_CMS;
const FAULT_EVENT_COUNTS = Api.FAULT_EVENT_COUNTS;
const EVENTS_LIST = Api.EVENTS_LIST;
const SESSION_EVENT_COUNTS = Api.SESSION_EVENT_COUNTS;

// Returns the FDE/CAS events for a particular aircraft and dates/session
async function getFaultsFDE(
  tailNumber,
  startTimestamp,
  endTimestamp,
  sitaInfoSource,
  aircraftFamily,
  aircraftModel,
  sessionNumber,
  axiosCancelSource
) {
  let result;
  let data;

  if (sessionNumber) {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        sessionNumber: sessionNumber,
        infoSource: sitaInfoSource ? "SITA" : null,
      },
    };
  } else if (startTimestamp && endTimestamp) {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        startDate: startTimestamp,
        endDate: endTimestamp,
        infoSource: sitaInfoSource ? "SITA" : null,
      },
    };
  } else {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
      },
    };
  }

  try {
    result = await AxiosService(
      FAULTS_FDE.url,
      FAULTS_FDE.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Returns the OMS/MDC/CAIMS events for a particular aircraft and dates/session
async function getFaultsOMS(
  tailNumber,
  startTimestamp,
  endTimestamp,
  sitaInfoSource,
  aircraftFamily,
  aircraftModel,
  sessionNumber,
  axiosCancelSource
) {
  let result;
  let data;

  if (sessionNumber) {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        sessionNumber: sessionNumber,
        infoSource: sitaInfoSource ? "SITA" : null,
      },
    };
  } else if (startTimestamp && endTimestamp) {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        startDate: startTimestamp,
        endDate: endTimestamp,
        infoSource: sitaInfoSource ? "SITA" : null,
      },
    };
  } else {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
      },
    };
  }

  try {
    result = await AxiosService(
      FAULTS_OMS.url,
      FAULTS_OMS.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Returns the CMS events for a particular aircraft and dates/session
async function getFaultsCMS(
  tailNumber,
  startTimestamp,
  endTimestamp,
  infoSource,
  aircraftFamily,
  aircraftModel,
  sessionNumber,
  axiosCancelSource
) {
  let result;
  let data;

  if (infoSource === "IF") {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        sessionNumber: sessionNumber,
        startDate: startTimestamp,
        endDate: endTimestamp,
        infoSource: startTimestamp && endTimestamp ? infoSource : "IF",
      },
    };
  } else {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        sessionNumber: sessionNumber,
        tailNumber: tailNumber,
        infoSource: infoSource,
      },
    };
  }

  try {
    result = await AxiosService(
      FAULTS_CMS.url,
      FAULTS_CMS.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    return error;
  }

  return result;
}

// Returns the FDE/CAS historic event counts of the past 10 flights for a particular aircraft, starting from a particular date
async function getFaultEventCountFDECAS(
  tailNumber,
  faultCodes,
  departureDate,
  arrivalDate,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  const numberOfFlights = 10;
  const faultType = "FDE";
  let result = [];

  if (faultCodes.length > 0) {
    let formattedFaultCodes = "[";
    let previousFaultCode = "";
    _.orderBy(faultCodes, ["faultCode"], ["asc"]).forEach((faultCode) => {
      if (faultCode !== previousFaultCode) {
        formattedFaultCodes += "'" + faultCode + "',";
        previousFaultCode = faultCode;
      }
    });
    formattedFaultCodes += "]";

    let data;
    if (departureDate && arrivalDate) {
      data = {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        numberOfFlights: numberOfFlights,
        faultType: faultType,
        faultCodes: formattedFaultCodes,
        startDate: departureDate,
        endDate: arrivalDate,
      };
    } else {
      data = {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        numberOfFlights: numberOfFlights,
        faultType: faultType,
        faultCodes: formattedFaultCodes,
      };
    }

    try {
      result = await AxiosService(
        FAULT_EVENT_COUNTS.url,
        FAULT_EVENT_COUNTS.method,
        data,
        axiosCancelSource
      );
    } catch (error) {
      console.error(error);
    }
  } else {
    result = { data: [] };
  }

  return result;
}

// Returns the OMS/MDC/CAIMS historic event counts of the past 10 flights for a particular aircraft, starting from a particular date
async function getFaultEventCountOMS(
  tailNumber,
  faultCodes,
  departureDate,
  arrivalDate,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  const numberOfFlights = 10;
  const faultType = "OMS";
  let result = [];

  if (faultCodes.length > 0) {
    let formattedFaultCodes = "[";
    let previousFaultCode = "";
    _.orderBy(faultCodes, ["faultCode"], ["asc"]).forEach((faultCode) => {
      if (faultCode !== previousFaultCode) {
        formattedFaultCodes += "'" + faultCode + "',";
        previousFaultCode = faultCode;
      }
    });
    formattedFaultCodes += "]";

    let data;
    if (departureDate && arrivalDate) {
      data = {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        numberOfFlights: numberOfFlights,
        faultType: faultType,
        faultCodes: formattedFaultCodes,
        startDate: departureDate,
        endDate: arrivalDate,
      };
    } else {
      data = {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        numberOfFlights: numberOfFlights,
        faultType: faultType,
        faultCodes: formattedFaultCodes,
      };
    }

    try {
      result = await AxiosService(
        FAULT_EVENT_COUNTS.url,
        FAULT_EVENT_COUNTS.method,
        data,
        axiosCancelSource
      );
    } catch (error) {
      console.error(error);
    }
  } else {
    result = { data: [] };
  }

  return result;
}

// Returns the CMS historic event counts of the past 10 flights for a particular aircraft, starting from a particular date
async function getFaultEventCountCMS(
  tailNumber,
  faultCodes,
  departureDate,
  arrivalDate,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  const numberOfFlights = 10;
  const faultType = "CMS";
  let result = [];

  if (faultCodes.length > 0) {
    let formattedFaultCodes = "[";
    let previousFaultCode = "";
    _.orderBy(faultCodes, ["faultCode"], ["asc"]).forEach((faultCode) => {
      if (faultCode !== previousFaultCode) {
        formattedFaultCodes += "'" + faultCode + "',";
        previousFaultCode = faultCode;
      }
    });
    formattedFaultCodes += "]";

    let data;
    if (departureDate && arrivalDate) {
      data = {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        numberOfFlights: numberOfFlights,
        faultType: faultType,
        faultCodes: formattedFaultCodes,
        startDate: departureDate,
        endDate: arrivalDate,
      };
    } else {
      data = {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tailNumber,
        numberOfFlights: numberOfFlights,
        faultType: faultType,
        faultCodes: formattedFaultCodes,
      };
    }

    try {
      result = await AxiosService(
        FAULT_EVENT_COUNTS.url,
        FAULT_EVENT_COUNTS.method,
        data,
        axiosCancelSource
      );
    } catch (error) {
      console.error(error);
    }
  } else {
    result = { data: [] };
  }

  return result;
}

async function getEventList(aircraftFamily, aircraftModel, eventType) {
  let result;
  let data;

  if (aircraftFamily !== "" && aircraftModel !== "" && eventType !== "") {
    const eventTypeFiltered = eventType === "FDE/CAS" ? "FDE" : eventType;

    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        eventType: eventTypeFiltered,
      },
    };

    try {
      result = await AxiosService(EVENTS_LIST.url, EVENTS_LIST.method, data);
    } catch (error) {
      return error;
    }
  } else {
    result = { data: [] };
  }

  return result.data;
}

async function getFaultCountsByAircraft(aircraftData) {
  let result;

  if (Object.keys(aircraftData).length !== 0) {
    aircraftData.eventType =
      aircraftData.eventType === "FDE/CAS" ? "FDE" : aircraftData.eventType;

    try {
      result = await AxiosService(
        SESSION_EVENT_COUNTS.url,
        SESSION_EVENT_COUNTS.method,
        aircraftData
      );
    } catch (error) {
      return error;
    }
  } else {
    result = { data: {} };
  }

  return result.data;
}

export {
  getFaultsFDE,
  getFaultsOMS,
  getFaultsCMS,
  getFaultEventCountFDECAS,
  getFaultEventCountOMS,
  getFaultEventCountCMS,
  getEventList,
  getFaultCountsByAircraft,
};
