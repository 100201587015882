import React, { Component } from "react";
import "./ChartBoxCms.css";
import LollipopChart from "../Graph Components/LollipopChart";

export default class ChartBoxCms extends Component {
  render() {
    return (
      <div className="chartBoxCms">
        <LollipopChart
          selectedSessionFlight={this.props.selectedSessionFlight}
          aircraftUIConfig={this.props.aircraftUIConfig}
          tab="cms"
          engineStartTime={this.props.engineStartTime}
          engineEndTime={this.props.engineEndTime}
          approximateEngineStart={this.props.approximateEngineStart}
          approximateEngineStop={this.props.approximateEngineStop}
          flights={this.props.flights}
          fdeFaults={this.props.fdeFaults}
          cmsFaults={this.props.cmsFaults}
          lollipopClickEvent={this.props.lollipopClickEvent}
          seriesToggleEvent={this.props.seriesToggleEvent}
          cmsSessionsList={this.props.cmsSessionsList}
        />
      </div>
    );
  }
}
