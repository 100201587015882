import React, { useState, useEffect } from "react";
import "./EIFRView.css";
import _ from "lodash";
import Accordion from "../Global Components/Accordion.jsx";
import moment from "moment";

function EIFRView(props) {
  const [allEifrInfoObjects, setAllEifrInfoObjects] = useState([]);

  const formatTimestamp = (timestamp) => {
    return moment.utc(timestamp).format("YYYY-MM-DD HH:mm:ss");
  };
  const convertFromBase64 = (content) => {
    return Buffer.from(content, "base64").toString();
  };

  // Build a 2d array. Each contained array will be for a new flight
  // Transforms queried EIFR entries into objects parseable by the global accordion component
  const createAllEifrInfoObjects = () => {
    let accordionObjects = [];
    let flightObject;
    let eifrObject;
    const eifrTakeoffBuffer = 300000;

    let flightIndex = 0;
    // Go through all flights to create the tables/accordion objects
    props.flightList.forEach((flight) => {
      // Create a flight info object
      flightObject = {
        id: "flight-" + flightIndex.toString(),
        reports: [],
        index: flightIndex,
        number: flightIndex + 1,
        arrivalTime: formatTimestamp(flight.arrivalTime),
        departureTime: formatTimestamp(flight.departureTime),
        auxTimestamp: flight.departureTime,
        type: "FLIGHT",
        typeOrder: 0,
      };

      // Add flight object to accordion object array
      accordionObjects.push(flightObject);
      flightIndex++;
    });

    // Go through all EIFR to create the tables/accordion objects
    props.eifrList.forEach((eifr) => {
      let currentReportDateTime = formatTimestamp(eifr.reportTimestamp);
      let currentReportDateTimeValue = moment
        .utc(eifr.reportTimestamp)
        .valueOf();

      let nearDepartureTime = _.find(props.flightList, (auxFlight) => {
        if (
          currentReportDateTimeValue >=
            moment.utc(auxFlight.departureTime).valueOf() - eifrTakeoffBuffer &&
          currentReportDateTimeValue <=
            moment.utc(auxFlight.departureTime).valueOf() + eifrTakeoffBuffer
        ) {
          return auxFlight;
        }
      })?.departureTime;

      // This will be added to flightInfo.reports array of current flight
      eifrObject = {
        id: "eifr-" + eifr.reportNumber.toString(),
        reportNumber: eifr.reportNumber,
        reportType: eifr.reportType,
        reportTimestamp: currentReportDateTime,
        file: eifr.file,
        auxTimestamp: nearDepartureTime
          ? nearDepartureTime
          : eifr.reportTimestamp,
        type: "EIFR",
        typeOrder: 1,
      };

      accordionObjects.push(eifrObject);
    });

    const sortedAccordionObjects = _.orderBy(
      accordionObjects,
      ["auxTimestamp", "typeOrder"],
      ["asc", "asc"]
    );

    return sortedAccordionObjects;
  };

  useEffect(() => {
    setAllEifrInfoObjects(createAllEifrInfoObjects());
  }, []);

  // Create objects from reports that will be passed to Global Component accordion
  const createAccordionObjects = (reports) => {
    let accordionArray = [];
    reports.map((report) => {
      let accordionObject = {
        title: report.reportType,
        subtitle: formatTimestamp(report.reportTimestamp),
        content: convertFromBase64(report.file),
      };
      accordionArray.push(accordionObject);
    });
    return accordionArray;
  };

  // Construct accordion array out of reports from flightInfo object
  const renderAccordionObject = (eifrReportArray) => {
    let accordionObjects = createAccordionObjects([eifrReportArray]);
    return <Accordion accordionEntries={accordionObjects} />;
  };

  return (
    <section className="allFlights">
      {allEifrInfoObjects.map((eifrInfoObject) => (
        <React.Fragment key={eifrInfoObject.id}>
          <div className="singleFlight">
            {eifrInfoObject.type === "FLIGHT" ? (
              <h1 className="flightNumberHeader">
                {"FLIGHT " + eifrInfoObject.number}
              </h1>
            ) : (
              renderAccordionObject(eifrInfoObject)
            )}
          </div>
        </React.Fragment>
      ))}
    </section>
  );
}

export default EIFRView;
