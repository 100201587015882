import React, { Component } from "react";
import * as tools from "../utils/CommonTools";
import CustomButton from "../Global Components/CustomButton";
import "./ConfigCyclesHours.css";

export default class ConfigCyclesHours extends Component {
  constructor(props) {
    super(props);

    this.onEditValueChange = this.onEditValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateValues = this.updateValues.bind(this);

    this.state = {
      currentFlightTimeHours: null,
      currentFlightTimeMinutes: null,
      currentFlightCycles: null,
      currentEngineOneTimeHours: null,
      currentEngineOneTimeMinutes: null,
      currentEngineOneCycles: null,
      currentEngineTwoTimeHours: null,
      currentEngineTwoTimeMinutes: null,
      currentEngineTwoCycles: null,
      updateFlightTimeHours: "",
      updateFlightTimeMinutes: "",
      updateFlightCycles: "",
      updateEngineOneTimeHours: "",
      updateEngineOneTimeMinutes: "",
      updateEngineOneCycles: "",
      updateEngineTwoTimeHours: "",
      updateEngineTwoTimeMinutes: "",
      updateEngineTwoCycles: "",
      canEditCyclesHours: false,
    };
  }

  componentDidMount() {
    this.updateValues();
  }

  updateValues() {
    const flightTimeValues = tools.convertMinutesToHoursMinutes(
      this.props.flightCycles.totalFlightTime
    );
    const engineOneTimeValues = tools.convertMinutesToHoursMinutes(
      this.props.flightCycles.totalEngine1Time
    );
    const engineTwoTimeValues = tools.convertMinutesToHoursMinutes(
      this.props.flightCycles.totalEngine2Time
    );

    this.setState({
      currentFlightTimeHours: flightTimeValues[0],
      currentFlightTimeMinutes: flightTimeValues[1],
      currentFlightCycles: this.props.flightCycles.totalFlightCycles,
      currentEngineOneTimeHours: engineOneTimeValues[0],
      currentEngineOneTimeMinutes: engineOneTimeValues[1],
      currentEngineOneCycles: this.props.flightCycles.totalEngine1Cycles,
      currentEngineTwoTimeHours: engineTwoTimeValues[0],
      currentEngineTwoTimeMinutes: engineTwoTimeValues[1],
      currentEngineTwoCycles: this.props.flightCycles.totalEngine2Cycles,
      updateFlightTimeHours: flightTimeValues[0],
      updateFlightTimeMinutes: flightTimeValues[1],
      updateFlightCycles: this.props.flightCycles.totalFlightCycles
        ? this.props.flightCycles.totalFlightCycles
        : "0",
      updateEngineOneTimeHours: engineOneTimeValues[0],
      updateEngineOneTimeMinutes: engineOneTimeValues[1],
      updateEngineOneCycles: this.props.flightCycles.totalEngine1Cycles
        ? this.props.flightCycles.totalEngine1Cycles
        : "0",
      updateEngineTwoTimeHours: engineTwoTimeValues[0],
      updateEngineTwoTimeMinutes: engineTwoTimeValues[1],
      updateEngineTwoCycles: this.props.flightCycles.totalEngine2Cycles
        ? this.props.flightCycles.totalEngine2Cycles
        : "0",
    });
  }

  onEditValueChange = (controlID, controlValue) => {
    if (controlID === "id-airframe-hours") {
      this.setState({
        updateFlightTimeHours: controlValue,
      });
    }
    if (controlID === "id-airframe-minutes") {
      this.setState({
        updateFlightTimeMinutes: controlValue,
      });
    }
    if (controlID === "id-airframe-cycles") {
      this.setState({
        updateFlightCycles: controlValue,
      });
    }
    if (controlID === "id-engine-1-hours") {
      this.setState({
        updateEngineOneTimeHours: controlValue,
      });
    }
    if (controlID === "id-engine-1-minutes") {
      this.setState({
        updateEngineOneTimeMinutes: controlValue,
      });
    }
    if (controlID === "id-engine-1-cycles") {
      this.setState({
        updateEngineOneCycles: controlValue,
      });
    }
    if (controlID === "id-engine-2-hours") {
      this.setState({
        updateEngineTwoTimeHours: controlValue,
      });
    }
    if (controlID === "id-engine-2-minutes") {
      this.setState({
        updateEngineTwoTimeMinutes: controlValue,
      });
    }
    if (controlID === "id-engine-2-cycles") {
      this.setState({
        updateEngineTwoCycles: controlValue,
      });
    }
  };

  calculateAdjustValues(type, originalValue, updateValue) {
    if (type === "TIME_HOURS") {
      originalValue =
        originalValue && originalValue !== ""
          ? (Math.round((parseFloat(originalValue) + Number.EPSILON) * 100) /
              100) *
            60
          : 0;

      updateValue =
        updateValue && updateValue !== ""
          ? parseFloat(updateValue) * 60
          : originalValue;
    } else {
      originalValue =
        originalValue && originalValue !== ""
          ? Math.round((parseFloat(originalValue) + Number.EPSILON) * 100) / 100
          : 0;

      updateValue =
        updateValue && updateValue !== ""
          ? parseFloat(updateValue)
          : originalValue;
    }

    const adjustValue =
      Math.round(
        (parseFloat(updateValue - originalValue) + Number.EPSILON) * 100
      ) / 100;

    return adjustValue;
  }

  async handleSubmit(event) {
    event.preventDefault();

    const adjustAirframeTimeHours = this.calculateAdjustValues(
      "TIME_HOURS",
      this.state.currentFlightTimeHours,
      this.state.updateFlightTimeHours
    );
    const adjustAirframeTimeMinutes = this.calculateAdjustValues(
      "TIME_MINUTES",
      this.state.currentFlightTimeMinutes,
      this.state.updateFlightTimeMinutes
    );
    const adjustAirframeCycles = this.calculateAdjustValues(
      "CYCLES",
      this.state.currentFlightCycles,
      this.state.updateFlightCycles
    );

    const adjustEngine1TimeHours = this.calculateAdjustValues(
      "TIME_HOURS",
      this.state.currentEngineOneTimeHours,
      this.state.updateEngineOneTimeHours
    );
    const adjustEngine1TimeMinutes = this.calculateAdjustValues(
      "TIME_MINUTES",
      this.state.currentEngineOneTimeMinutes,
      this.state.updateEngineOneTimeMinutes
    );
    const adjustEngine1Cycles = this.calculateAdjustValues(
      "CYCLES",
      this.state.currentEngineOneCycles,
      this.state.updateEngineOneCycles
    );

    const adjustEngine2TimeHours = this.calculateAdjustValues(
      "TIME_HOURS",
      this.state.currentEngineTwoTimeHours,
      this.state.updateEngineTwoTimeHours
    );
    const adjustEngine2TimeMinutes = this.calculateAdjustValues(
      "TIME_MINUTES",
      this.state.currentEngineTwoTimeMinutes,
      this.state.updateEngineTwoTimeMinutes
    );
    const adjustEngine2Cycles = this.calculateAdjustValues(
      "CYCLES",
      this.state.currentEngineTwoCycles,
      this.state.updateEngineTwoCycles
    );

    if (
      adjustAirframeTimeHours !== 0 ||
      adjustAirframeTimeMinutes !== 0 ||
      adjustAirframeCycles !== 0 ||
      adjustEngine1TimeHours !== 0 ||
      adjustEngine1TimeMinutes !== 0 ||
      adjustEngine1Cycles !== 0 ||
      adjustEngine2TimeHours !== 0 ||
      adjustEngine2TimeMinutes !== 0 ||
      adjustEngine2Cycles !== 0
    ) {
      this.props.handleCyclesHoursSubmit(
        this.props.serialNumber,
        this.props.flightCycles.engine1Serial,
        this.props.flightCycles.engine2Serial,
        this.props.flightCycles.totalFlightTime,
        this.props.flightCycles.totalFlightCycles,
        this.props.flightCycles.totalEngine1Time,
        this.props.flightCycles.totalEngine1Cycles,
        this.props.flightCycles.totalEngine2Time,
        this.props.flightCycles.totalEngine2Cycles,
        adjustAirframeTimeHours + adjustAirframeTimeMinutes,
        adjustAirframeCycles,
        adjustEngine1TimeHours + adjustEngine1TimeMinutes,
        adjustEngine1Cycles,
        adjustEngine2TimeHours + adjustEngine2TimeMinutes,
        adjustEngine2Cycles
      );
    }
  }

  validateEngineSerialNumber(engineSerial) {
    if (engineSerial) {
      return engineSerial;
    }
    return "-";
  }

  render() {
    const engine1Serial = this.validateEngineSerialNumber(
      this.props.flightCycles.engine1Serial
    );
    const engine2Serial = this.validateEngineSerialNumber(
      this.props.flightCycles.engine2Serial
    );

    return (
      <div className="configCyclesHours">
        <form onSubmit={this.handleSubmit}>
          <div className="configCyclesHours__rows__title">
            <div className="configCyclesHours__currentValue">
              <div className="configCyclesHours__currentValue__label">
                System
              </div>
              <div className="configCyclesHours__currentValue__value">
                Count
              </div>
            </div>
            <div className="configCyclesHours__updateValue_cycles">
              Edit total
            </div>
          </div>
          <div className="configCyclesHours__list">
            <div className="configCyclesHours__rows">
              <div className="configCyclesHours__currentValue">
                <div className="configCyclesHours__currentValue__label">
                  Airframe Hours
                </div>
                <div className="configCyclesHours__currentValue__value">
                  {tools.convertMinutesToHoursMinutesText(
                    this.props.flightCycles.totalFlightTime
                  )}
                </div>
              </div>
              <div className="configCyclesHours__updateValue_time">
                <div className="configCyclesHours__updateValue__time__section">
                  <div className="configCyclesHours__updateValue__time__sectionTitle">
                    Hours
                  </div>
                  <input
                    id="id-airframe-hours"
                    name="id-airframe-hours"
                    type="number"
                    step="1"
                    min="0"
                    value={this.state.updateFlightTimeHours}
                    onChange={(e) =>
                      this.onEditValueChange(e.target.id, e.target.value)
                    }
                    disabled={
                      !(
                        this.props.permEditFlightCycles &&
                        this.props.editCyclesPermissionAircraft
                      )
                        ? true
                        : this.props.flightCycles.totalFlightTime
                        ? false
                        : true
                    }
                  />
                </div>
                <div className="configCyclesHours__updateValue__time__section">
                  <div className="configCyclesHours__updateValue__time__sectionTitle">
                    Minutes
                  </div>
                  <input
                    id="id-airframe-minutes"
                    name="id-airframe-minutes"
                    type="number"
                    step="1"
                    min="0"
                    max="59"
                    value={this.state.updateFlightTimeMinutes}
                    onChange={(e) =>
                      this.onEditValueChange(e.target.id, e.target.value)
                    }
                    disabled={
                      !(
                        this.props.permEditFlightCycles &&
                        this.props.editCyclesPermissionAircraft
                      )
                        ? true
                        : this.props.flightCycles.totalFlightTime
                        ? false
                        : true
                    }
                  />
                </div>
              </div>
            </div>
            <div className="configCyclesHours__rows">
              <div className="configCyclesHours__currentValue">
                <div className="configCyclesHours__currentValue__label">
                  Airframe Cycles
                </div>
                <div className="configCyclesHours__currentValue__value">
                  {tools.formatCyclesText(
                    this.props.flightCycles.totalFlightCycles
                  )}
                </div>
              </div>
              <div className="configCyclesHours__updateValue_cycles">
                <div className="configCyclesHours__updateValue__cycles__section">
                  <div className="configCyclesHours__updateValue__cycles__sectionTitle">
                    Cycles
                  </div>
                  <input
                    id="id-airframe-cycles"
                    name="id-airframe-cycles"
                    type="number"
                    step="1"
                    min="0"
                    value={this.state.updateFlightCycles}
                    onChange={(e) =>
                      this.onEditValueChange(e.target.id, e.target.value)
                    }
                    disabled={
                      !(
                        this.props.permEditFlightCycles &&
                        this.props.editCyclesPermissionAircraft
                      )
                        ? true
                        : this.props.flightCycles.totalFlightCycles
                        ? false
                        : true
                    }
                  />
                </div>
              </div>
            </div>
            <div className="configCyclesHours__rows">
              <div className="configCyclesHours__currentValue">
                <div className="configCyclesHours__currentValue__label">
                  {"Engine (" + engine1Serial + ") Hours"}
                </div>
                <div className="configCyclesHours__currentValue__value">
                  {tools.convertMinutesToHoursMinutesText(
                    this.props.flightCycles.totalEngine1Time
                  )}
                </div>
              </div>
              <div className="configCyclesHours__updateValue_time">
                <div className="configCyclesHours__updateValue__time__section">
                  <div className="configCyclesHours__updateValue__time__sectionTitle">
                    Hours
                  </div>
                  <input
                    id="id-engine-1-hours"
                    name="id-engine-1-hours"
                    type="number"
                    step="1"
                    min="0"
                    value={this.state.updateEngineOneTimeHours}
                    onChange={(e) =>
                      this.onEditValueChange(e.target.id, e.target.value)
                    }
                    disabled={
                      !(
                        this.props.permEditFlightCycles &&
                        this.props.editCyclesPermissionAircraft
                      )
                        ? true
                        : engine1Serial === "-"
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="configCyclesHours__updateValue__time__section">
                  <div className="configCyclesHours__updateValue__time__sectionTitle">
                    Minutes
                  </div>
                  <input
                    id="id-engine-1-minutes"
                    name="id-engine-1-minutes"
                    type="number"
                    step="1"
                    min="0"
                    max="59"
                    value={this.state.updateEngineOneTimeMinutes}
                    onChange={(e) =>
                      this.onEditValueChange(e.target.id, e.target.value)
                    }
                    disabled={
                      !(
                        this.props.permEditFlightCycles &&
                        this.props.editCyclesPermissionAircraft
                      )
                        ? true
                        : engine1Serial === "-"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
            <div className="configCyclesHours__rows">
              <div className="configCyclesHours__currentValue">
                <div className="configCyclesHours__currentValue__label">
                  {"Engine (" + engine1Serial + ") Cycles"}
                </div>
                <div className="configCyclesHours__currentValue__value">
                  {tools.formatCyclesText(
                    this.props.flightCycles.totalEngine1Cycles
                  )}
                </div>
              </div>
              <div className="configCyclesHours__updateValue_cycles">
                <div className="configCyclesHours__updateValue__cycles__section">
                  <div className="configCyclesHours__updateValue__cycles__sectionTitle">
                    Cycles
                  </div>
                  <input
                    id="id-engine-1-cycles"
                    name="id-engine-1-cycles"
                    type="number"
                    step="1"
                    min="0"
                    value={this.state.updateEngineOneCycles}
                    onChange={(e) =>
                      this.onEditValueChange(e.target.id, e.target.value)
                    }
                    disabled={
                      !(
                        this.props.permEditFlightCycles &&
                        this.props.editCyclesPermissionAircraft
                      )
                        ? true
                        : engine1Serial === "-"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
            <div className="configCyclesHours__rows">
              <div className="configCyclesHours__currentValue">
                <div className="configCyclesHours__currentValue__label">
                  {"Engine (" + engine2Serial + ") Hours"}
                </div>
                <div className="configCyclesHours__currentValue__value">
                  {tools.convertMinutesToHoursMinutesText(
                    this.props.flightCycles.totalEngine2Time
                  )}
                </div>
              </div>
              <div className="configCyclesHours__updateValue_time">
                <div className="configCyclesHours__updateValue__time__section">
                  <div className="configCyclesHours__updateValue__time__sectionTitle">
                    Hours
                  </div>
                  <input
                    id="id-engine-2-hours"
                    name="id-engine-2-hours"
                    type="number"
                    step="1"
                    min="0"
                    value={this.state.updateEngineTwoTimeHours}
                    onChange={(e) =>
                      this.onEditValueChange(e.target.id, e.target.value)
                    }
                    disabled={
                      !(
                        this.props.permEditFlightCycles &&
                        this.props.editCyclesPermissionAircraft
                      )
                        ? true
                        : engine2Serial === "-"
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="configCyclesHours__updateValue__time__section">
                  <div className="configCyclesHours__updateValue__time__sectionTitle">
                    Minutes
                  </div>
                  <input
                    id="id-engine-2-minutes"
                    name="id-engine-2-minutes"
                    type="number"
                    step="1"
                    min="0"
                    max="59"
                    value={this.state.updateEngineTwoTimeMinutes}
                    onChange={(e) =>
                      this.onEditValueChange(e.target.id, e.target.value)
                    }
                    disabled={
                      !(
                        this.props.permEditFlightCycles &&
                        this.props.editCyclesPermissionAircraft
                      )
                        ? true
                        : engine2Serial === "-"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
            <div className="configCyclesHours__rows">
              <div className="configCyclesHours__currentValue">
                <div className="configCyclesHours__currentValue__label">
                  {"Engine (" + engine2Serial + ") Cycles"}
                </div>
                <div className="configCyclesHours__currentValue__value">
                  {tools.formatCyclesText(
                    this.props.flightCycles.totalEngine2Cycles
                  )}
                </div>
              </div>
              <div className="configCyclesHours__updateValue_cycles">
                <div className="configCyclesHours__updateValue__cycles__section">
                  <div className="configCyclesHours__updateValue__cycles__sectionTitle">
                    Cycles
                  </div>
                  <input
                    id="id-engine-2-cycles"
                    name="id-engine-2-cycles"
                    type="number"
                    step="1"
                    min="0"
                    value={this.state.updateEngineTwoCycles}
                    onChange={(e) =>
                      this.onEditValueChange(e.target.id, e.target.value)
                    }
                    disabled={
                      !(
                        this.props.permEditFlightCycles &&
                        this.props.editCyclesPermissionAircraft
                      )
                        ? true
                        : engine2Serial === "-"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {this.props.flightCyclesError ? (
            <p className="configCyclesHours__errorMessage">There was an error, please try again. If the error persists contact support.</p>
          ): null}
          <div className="configCyclesHours__saveRow">
            {this.props.permEditFlightCycles &&
            this.props.editCyclesPermissionAircraft ? (
              <CustomButton
                id={"id-configCyclesHours-save"}
                text={"SAVE"}
                buttonStyle={"pill"}
                isDisabled={false}
              />
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    );
  }
}
