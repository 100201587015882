import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import CustomButton from "../Global Components/CustomButton";
import ConfigCommonFaultList from "./ConfigCommonFaultList";
import "./ConfigCommonFaultBox.css";

export default class ConfigCommonFaultBox extends Component {
  constructor(props) {
    super(props);

    this.handleConfigCommonFaultEdit = this.handleConfigCommonFaultEdit.bind(
      this
    );
    this.handleSaveEditClick = this.handleSaveEditClick.bind(this);
  }

  render() {
    return (
      <div className="configCommonFaultBox" id="configCommonFaultBox">
        <div
          className={"configCommonFaultBox__content"}
          id="config-common-fault-config"
        >
          {this.handleConfigCommonFaultEdit()}
          <div className="configCommonFaultBox__save">
            <div
              className={
                "configCommonFaultBox__save__unsavedEdits" +
                (this.props.unsavedCommonFaultEdits ? "" : " inactive")
              }
            >
              <FeatherIcon icon={"alert-triangle"} height={20} />
              <span style={{ marginLeft: "5px" }}>
                {"You have unsaved edits."}
              </span>
            </div>

            <CustomButton
              id={"btn-save-common-fault"}
              text={"SAVE"}
              buttonStyle={"pill"}
              isDisabled={!this.props.unsavedCommonFaultEdits}
              onClickAction={this.handleSaveEditClick}
            />
          </div>
        </div>
      </div>
    );
  }

  handleSaveEditClick() {
    this.props.handleSaveCommonFaultClick(this.props.faultList);
  }

  handleConfigCommonFaultEdit() {
    return (
      <ConfigCommonFaultList
        faultList={this.props.faultList}
        faultFamilyModelRules={this.props.faultFamilyModelRules}
        handleEditCommonFaultClick={this.props.handleEditCommonFaultClick}
      />
    );
  }
}
