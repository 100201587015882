import React from "react";
import "./chartNameController.css";

export default function ChartNameController({
    chartId,
    chartIndex,
    chartName,
    handleChartTitleOffFocus,
}) {
    const [localChartName, setLocalChartName] = React.useState(chartName);

    React.useEffect(() => {
        setLocalChartName(chartName);
    }, [chartName]);

    const handleOnChange = (newChartName) => {
        setLocalChartName(newChartName);
    };

    const handleOffFocus = (localChartIndex, newChartName) => {
        handleChartTitleOffFocus(localChartIndex, newChartName);
    };

    return (
        <div className="canvasjs__chart__container__header__title"
            style={{ width: "100%" }}
             data-testid="chart-name-controller"
        >
            <input
                id={"chart-title-" + chartId}
                value={localChartName}
                placeholder="Chart Title"
                type="text"
                onBlur={(event) => handleOffFocus(chartIndex, event.target.value)}
                onChange={(event) => handleOnChange(event.target.value)}
                style={{ width: "100%" }}
            />
        </div>
    );
}
