import React, { useEffect, useState } from "react";
import "./RPDView.css";
import { getRolesPermissions } from "../Auth Components/RbacValidation";
import Spinner from "../Global Components/Spinner.js";
import RPDNewRequest from "./RPDNewRequest";
import RPDLogs from "./RPDLogs";
import { getRpdParameterList } from "../clients/RpdParametersDataClient";
import _ from "lodash";
import GlobalHeader from "../Global Components/GlobalHeader/GlobalHeader";

/**
 * Main view for RPD Requests.
 */
const RPDView = (props) => {
  const rpdCrumb = {
    view: "/rpdview",
    value: "RPD Request",
    faultCode: "",
    faultDate: "",
    faultMessage: "",
    isFault: false,
    severityColor: "",
  };

  // Tabs displayed below the aircraft banner.
  const tabs = ["NEW RPD REQUEST", "RPD LOG"];
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("NEW RPD REQUEST");
  const [rolesPermissions, setRolesPermissions] = useState(null);
  const [aircraftFamily, setAircraftFamily] = useState(null);
  const [aircraftModel, setAircraftModel] = useState(null);
  const [tail, setTail] = useState(null);
  const [dataFocus, setDataFocus] = useState(null);
  const [status, setStatus] = useState(null);
  const [serial, setSerial] = useState(null);
  const [monitorStatus, setMonitorStatus] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [faultCode, setFaultCode] = useState(null);
  const [faultSeverity, setFaultSeverity] = useState(null);
  const [faultTimeStamp, setFaultTimeStamp] = useState(null);
  const [faultMessage, setFaultMessage] = useState(null);
  const [faultDescription, setFaultDescription] = useState(null);
  const [externalRpdRequest, setExternalRpdRequest] = useState(null);
  const [rpdEventRequestAvailable, setRpdEventRequestAvailable] =
    useState(null);
  const [rpdSatComSupport, setRpdSatComSupport] = useState(null);
  const [rpdRequestName, setRpdRequestName] = useState("");
  const [rpdRequestEnabled, setRpdRequestEnabled] = useState(false);
  const [rpdRequestDisabledMessage, setRpdRequestDisabledMessage] =
    useState("");
  const [rpdOverrideEnabled, setRpdOverrideEnabled] = useState(false);
  const [rpdOverrideValue, setRpdOverrideValue] = useState(false);
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [isDuplicateRequest, setIsDuplicateRequest] = useState(false);
  const [defaultRequestDuration, setDefaultRequestDuration] = useState(110);
  const [allParameters, setAllParameters] = useState([]);
  const [summaryParameters, setSummaryParameters] = useState([]);
  const [isLoadingParameterList, setIsLoadingParameterList] = useState(false);
  const [timeInFlight, setTimeInFlight] = useState("");
  const [latestDataTransferDate, setLatestDataTransferDate] = useState("");
  const [hasLatestHmuTransferDate, setHasLatestHmuTransferDate] =
    useState(false);

  // Validatting location data and saving it to local storage, needed to return to the aircraft view.
  localStorage.setItem("tmpTail", props.location.state.tail);
  localStorage.setItem(
    "tmpAircraftFamily",
    props.location.state?.aircraftFamily
  );
  localStorage.setItem("tmpAircraftModel", props.location.state?.aircraftModel);
  localStorage.setItem("tmpSerial", props.location.state.serial);
  localStorage.setItem("tmpMonitorStatus", props.location.state?.monitorStatus);

  useEffect(() => {
    // Load data from the parent component.
    const locationData = loadLocationData();
    // Get local permissions for RPD.
    const localRolesPermissions = getRolesPermissions("rpdview");
    setRolesPermissions(localRolesPermissions);
    setAircraftFamily(locationData[0]);
    setAircraftModel(locationData[1]);
    setTail(locationData[2]);
    setDataFocus(locationData[3]);
    setStatus(locationData[4]);
    setSerial(locationData[5]);
    setMonitorStatus(locationData[6]);
    setStartTime(locationData[7]);
    setEndTime(locationData[8]);
    setFaultCode(locationData[9]);
    setFaultSeverity(locationData[10]);
    setFaultTimeStamp(locationData[11]);
    setFaultMessage(locationData[12]);
    setFaultDescription(locationData[13]);
    setExternalRpdRequest(locationData[18]);
    setRpdEventRequestAvailable(locationData[19]);
    setRpdSatComSupport(locationData[20]);
    setTimeInFlight(locationData[21]);
    setLatestDataTransferDate(locationData[22]);
    setHasLatestHmuTransferDate(locationData[23]);
    /* We check if the variables have been properly initialized before loading
     * any content
     */
    if (rolesPermissions && props.location.state) {
      validateRPDRequestEnabled();
      validateOverrideEnabled();
    }
  }, [props]);

  /**
   * Loads the parameter list for the selected aircraft from the API.
   * @param tail The aircraft tail.
   * @param startTime The flight start time.
   * @param endTime The flight current time.
   * @return {Promise<void>}
   */
  const processParameterList = async (tail, startTime, endTime) => {
    if (allParameters.length === 0) {
      setIsLoadingParameterList(true);
      try {
        const parametersPromiseArray = [];

        const parameterList = await getRpdParameterList(
          tail,
          null,
          startTime,
          endTime,
          aircraftFamily,
          aircraftModel
        );

        let allParameters = [];
        // We only order if there is something to order.
        if (parameterList.data) {
          allParameters = _.orderBy(parameterList.data.parameters, [
            "parameterName",
            "asc",
          ]);
        }
        // Assign the 'checked' attribute for the checkbox functionality in the table.
        allParameters.forEach((parameter) => {
          _.assign(parameter, {
            checked: false,
          });
        });
        setIsLoadingParameterList(false);
        setAllParameters(allParameters);
      } catch (error) {
        setIsLoadingParameterList(false);
        console.error("ERROR: ", error);
      }
    }
  };

  /**
   * Function for when the user checks or unchecks a parameter
   * in the table.
   * @param parameter Parameter selected.
   * @param state Current checked state.
   */
  const toggleParameterSelection = (index, parameter, state) => {
    let tempAllParameters = allParameters;
    let allSummaryParameters = summaryParameters;
    // Find the index of the selected parameter.
    if (!index) {
      index = _.findIndex(tempAllParameters, (param) => {
        return param.parameterName === parameter.parameterName;
      });
    }
    // If it is unchecked then we check it and add it to the summary parameters list.
    // If it is already checked then we uncheck it and remove it from the list.
    if (!state) {
      allSummaryParameters.push(parameter);
      tempAllParameters[index].checked = true;
    } else {
      _.remove(allSummaryParameters, {
        parameterName: parameter.parameterName,
      });
      tempAllParameters[index].checked = false;
    }
    setAllParameters([...tempAllParameters]);
    setSummaryParameters([...allSummaryParameters]);
  };

  /**
   * Loads and validates the data coming from the parent component.
   * @return {*[]}
   */
  const loadLocationData = () => {
    const locationData = [];

    if (props.location.state) {
      locationData.push(props.location.state.aircraftFamily);
      locationData.push(props.location.state.aircraftModel);
      locationData.push(props.location.state.tail);
      locationData.push(props.location.state.dataFocus);
      locationData.push(props.location.state.status);
      locationData.push(props.location.state.serial);
      locationData.push(props.location.state.monitorStatus);
      locationData.push(props.location.state.startTime);
      locationData.push(props.location.state.endTime);
      locationData.push(
        props.location.state.faultCode ? props.location.state.faultCode : null
      );
      locationData.push(
        props.location.state.faultSeverity
          ? props.location.state.faultSeverity
          : null
      );
      locationData.push(
        props.location.state.faultTimestamp
          ? props.location.state.faultTimestamp
          : null
      );
      locationData.push(
        props.location.state.faultMessage
          ? props.location.state.faultMessage
          : null
      );
      locationData.push(
        props.location.state.faultDescription
          ? props.location.state.faultDescription
          : null
      );
      locationData.push(props.location.state.departureAirportName);
      locationData.push(props.location.state.departureAirportIATA);
      locationData.push(props.location.state.arrivalAirportName);
      locationData.push(props.location.state.arrivalAirportIATA);
      locationData.push(props.location.state.externalRpdRequest);
      locationData.push(props.location.state.rpdEventRequestAvailable);
      locationData.push(props.location.state.rpdSatcomSupport);
      locationData.push(props.location.state.timeInFlight);
      locationData.push(props.location.state.latestDataTransferDate);
      locationData.push(props.location.state.hasLatestHmuTransferDate);
      return locationData;
    } else {
      return [null];
    }
  };

  /**
   * Validates if the RPD Request should be enabled for the user and aircraft pair.
   */
  const validateRPDRequestEnabled = () => {
    let enabled = true;
    let message = "";
    if (status !== "IN-FLIGHT") {
      enabled = false;
      message = "The aircraft is currently on ground.";
    } else if (!rolesPermissions.createRpdRequest || !externalRpdRequest) {
      enabled = false;
      message =
        !rolesPermissions.createRpdRequest && !externalRpdRequest
          ? "New RPD requests are currently disabled for this aircraft and your user does not have permissions to create a new RPD Request."
          : !rolesPermissions.createRpdRequest
          ? "Your user does not have permissions to create a new RPD Request."
          : "New RPD requests are currently disabled for this aircraft.";
    }
    setRpdRequestEnabled(enabled);
    setRpdRequestDisabledMessage(message);
  };

  /**
   * Checks if the user can override the RPD permission for the aircraft.
   */
  const validateOverrideEnabled = () => {
    let enabled = rolesPermissions.rpdOverride;
    if (status !== "IN-FLIGHT") {
      enabled = false;
    }
    setRpdOverrideEnabled(enabled);
  };

  /**
   * Enables or disables the RPD Request if the user tries to override it.
   * @param checked Value to be set.
   */
  const handleRPDOverride = (checked) => {
    setRpdRequestEnabled(checked);
    setRpdOverrideValue(checked);
  };

  /**
   * Sets the required values for when a user is duplicating an existing
   * RPD Request.
   * @param request Request object to be duplicated.
   */
  const handleDuplicateRPDRequest = (request) => {
    setSelectedParameters(request.requestParameters);
    setRpdRequestName(request.requestName);
    setDefaultRequestDuration(request.requestDuration);
    setIsDuplicateRequest(true);
    setSelectedTab("NEW RPD REQUEST");
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleClearParameters = () => {
    const copySummaryParameters = [...summaryParameters];
    copySummaryParameters.forEach((param) => {
      toggleParameterSelection(undefined, param, true);
    });
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            width: "100%",
            marginLeft: "calc(-7% - 50px)",
            marginRight: "-7%",
            marginTop: "-20px",
            height: "100%",
            backgroundColor: "rgb(0,0,0,.5)",
            zIndex: "2000",
          }}
        >
          <div style={{ width: "100px", paddingTop: "20%", margin: "auto" }}>
            <Spinner />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="rpdView">
        <div className="rpdView__header">
          <div className="rpdView__header__info">
            <GlobalHeader
              serialNumber={serial}
              aircraftFamily={aircraftFamily}
              aircraftModel={aircraftModel}
              registration={tail}
              crumb={rpdCrumb}
              timeInFlight={timeInFlight}
              latestDataTransferDate={latestDataTransferDate}
              hasLatestHmuTransferDate={hasLatestHmuTransferDate}
              dataFocus={"RPD Request"}
              faultDescription={faultDescription}
              currentAircraftStatus={status}
            />
          </div>
        </div>
        <div className="rpd__tab__container">
          {tabs.map((tab) => (
            <React.Fragment key={tab}>
              <div
                className={
                  selectedTab === tab
                    ? "rpd__tab rpd__tab__selected"
                    : "rpd__tab"
                }
                style={{
                  display: "block",
                }}
                id={
                  tab === "New RPD request"
                    ? "id_new_request_tab"
                    : tab === "Load RPD template"
                    ? "id_template_tab"
                    : tab === "RPD LOG"
                    ? "id_rpd_log_tab"
                    : ""
                }
                onClick={() => {
                  setSelectedTab(tab);
                }}
              >
                {tab}
              </div>
            </React.Fragment>
          ))}
        </div>
        {selectedTab == "NEW RPD REQUEST" ? (
          <RPDNewRequest
            startTime={startTime}
            endTime={endTime}
            tail={tail}
            aircraftFamily={aircraftFamily}
            aircraftModel={aircraftModel}
            rpdRequestEnabled={rpdRequestEnabled}
            rpdOverrideEnabled={rpdOverrideEnabled}
            rpdOverrideValue={rpdOverrideValue}
            serial={serial}
            dataFocus={dataFocus}
            faultCode={faultCode}
            faultTimestamp={faultTimeStamp}
            faultSeverity={faultSeverity}
            faultMessage={faultMessage}
            rpdEventRequestAvailable={rpdEventRequestAvailable}
            handleRPDOverride={handleRPDOverride}
            selectedParameters={selectedParameters}
            isDuplicateRequest={isDuplicateRequest}
            defaultRequestName={rpdRequestName}
            defaultRequestDuration={defaultRequestDuration}
            allParameters={allParameters}
            summaryParameters={summaryParameters}
            processParameterList={processParameterList}
            toggleParameterSelection={toggleParameterSelection}
            isLoadingParameterList={isLoadingParameterList}
            handleTabChange={handleTabChange}
            handleClearParameters={handleClearParameters}
            rpdRequestDisabledMessage={rpdRequestDisabledMessage}
          />
        ) : selectedTab === "RPD LOG" ? (
          <RPDLogs
            tail={tail}
            aircraftFamily={aircraftFamily}
            aircraftModel={aircraftModel}
            rpdRequestEnabled={rpdRequestEnabled}
            status={status}
            serial={serial}
            monitorStatus={monitorStatus}
            handleDuplicateRPDRequest={handleDuplicateRPDRequest}
          />
        ) : null}
      </div>
    </div>
  );
};

export default RPDView;
