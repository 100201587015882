import React, { Component } from "react";
import "./ChartBoxFde.css";
import LollipopChart from "../Graph Components/LollipopChart";

export default class ChartBoxFde extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="chartBoxFde">
        <LollipopChart
          selectedSessionFlight={this.props.selectedSessionFlight}
          aircraftUIConfig={this.props.aircraftUIConfig}
          tab="fde"
          engineStartTime={this.props.engineStartTime}
          engineEndTime={this.props.engineEndTime}
          approximateEngineStart={this.props.approximateEngineStart}
          approximateEngineStop={this.props.approximateEngineStop}
          flights={this.props.flights}
          fdeFaults={this.props.fdeFaults}
          cmsFaults={this.props.cmsFaults}
          lollipopClickEvent={this.props.lollipopClickEvent}
          seriesToggleEvent={this.props.seriesToggleEvent}
        />
      </div>
    );
  }
}
