import React from "react";
import PropTypes from 'prop-types';
import Radio from "../../../RadioButton/Radio";
import { ArrowRight } from 'react-feather';
import { Form } from 'react-bootstrap';
import CustomButton from "../../../../../Global Components/CustomButton";
import FeatherIcon from "feather-icons-react";
import "./FeedbackType.scss";
import "../SharedFeedbackForm.scss";

const FeedbackType = ({feedbackRating, handleChange, handleNext, ratingOptions}) => {
  let display = "FeedbackTypeModal";
  return (
    <Form className="feedbackForm">
      <div className="feedbackFormInner">
        <h3 className="ratingLabel">What kind of feedback do you have?</h3>
        <div className="feedbackFormRatingContainer">
          {ratingOptions.map(type => 
          <React.Fragment key={type.label + "Radio"}>
            <Radio
              id={display + type.id}
              name="feedbackRating"
              checked={feedbackRating === type.value}
              value={type.value}
              onChange={event => handleChange(event, type.value) }
              label={type.label}
            />
          </React.Fragment>
          )}
        </div>
      </div>
      <div className="feedbackButtonContainer">
        <CustomButton
          isDisabled={feedbackRating ? false : true}
          id={"next-data-button"}
          text={"NEXT"}
          buttonStyle={"pill"}
          icon={
            <FeatherIcon
              icon={"arrow-right"}
              height={16}
              width={16}
            />
          }
          iconPosition={"end"}
          onClickAction={() => handleNext()}
        />
      </div>
    </Form>
  );
}

export default FeedbackType;

FeedbackType.defaultProps = {
  ratingOptions: [
    {
      label: "I like something",
      value: "1",
      id: "RatingOne"
    },
    {
      label: "I don't like something",
      value: "-1",
      id: "RatingTwo"
    },
    {
      label: "I have a suggestion",
      value: "0",
      id: "RatingThree"
    }
  ]
}

FeedbackType.propTypes = {
  feedbackRating: PropTypes.string, 
  handleChange: PropTypes.func, 
  handleNext: PropTypes.func,
  ratingOptions: PropTypes.array
};

