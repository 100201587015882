import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";

const SortBox = (props) => {
  const { width, sortOptions, currSort, handleSortOptionChange } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  //ref for detecting clicking outside sort dropdown
  const ref = useRef(null);

  const handleSortChange = (id, sort) => {
    handleSortOptionChange({
      id: id,
      sort: sort,
    });
    setIsCollapsed(true);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsCollapsed(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <List
      sx={{
        width: width,
        position: "relative",
        margin: "10px 0 20px 0",
        padding: 0,
      }}
      ref={ref}
    >
      <ListItemButton
        sx={{
          border: "2px solid #D2D2D2",
        }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <ListItemText
          primaryTypographyProps={{
            style: {
              color: "#D2D2D2",
              fontSize: 16,
              fontFamily: "DM Sans",
              fontWeight: 600,
              cursor: "pointer",
            },
          }}
          primary="Sort By"
        />
        <FeatherIcon
          width={25}
          height={25}
          icon={isCollapsed ? "chevron-down" : "chevron-up"}
          style={{ color: "#D2D2D2" }}
        />
      </ListItemButton>
      <Collapse
        sx={{
          position: "absolute",
          top: 45,
          zIndex: 100,
          width: width,
          backgroundColor: "#202020",
          borderLeft: "2px solid #D2D2D2",
          borderRight: "2px solid #D2D2D2",
          borderBottom: "2px solid #D2D2D2",
        }}
        in={!isCollapsed}
        timeout="auto"
        unmountOnExit
      >
        <List sx={{ padding: 0, width: "100%" }}>
          {/* Making it a list just in case we want to add more type of sort later */}
          <List sx={{ padding: 0, width: "100%" }}>
            {sortOptions.map((option) => {
              return (
                <React.Fragment key={option.id}>
                  <ListItemButton disabled>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          color: "#FFFFFF",
                          fontFamily: "DM Sans",
                          fontWeight: 600,
                          marginLeft: 10,
                          cursor: "pointer",
                        },
                      }}
                      primary={option.title}
                    />
                  </ListItemButton>
                  <Collapse in={true} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      {option.options.map((subOption) => {
                        return (
                          <React.Fragment key={subOption.id}>
                            <ListItemButton
                              onClick={() =>
                                handleSortChange(option.id, subOption.id)
                              }
                            >
                              <ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    color:
                                      subOption.id === currSort.sort
                                        ? "#FFFFFF"
                                        : "#D2D2D2",
                                    fontFamily: "DM Sans",
                                    fontWeight:
                                      subOption.id === currSort.sort
                                        ? 500
                                        : 400,
                                    marginLeft: 25,
                                    cursor: "pointer",
                                  },
                                }}
                                primary={subOption.title}
                              />
                            </ListItemButton>
                          </React.Fragment>
                        );
                      })}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            })}
          </List>
        </List>
      </Collapse>
    </List>
  );
};

export default SortBox;
