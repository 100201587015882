import React, { Component } from "react";
import moment from "moment";
import "./ContentInfoMessage.css";

export default class ContentInfoMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabID: "OVERVIEW",
      overviewTabStyle: "contentFdeFault__tabPanel active",
    };
    this.handleShowTab = this.handleShowTab.bind(this);
  }

  getCmsInfoSection() {
    return (
      <div className="contentInfoMessage__body">
        <div className="contentInfoMessage__body__data">
          {this.addDataRow("LRUSourceid", this.props.LRUSourceId)}
          {this.addDataRow(
            "Description",
            this.props.faultStatusDescription,
            true
          )}
          {this.addDataRow("Action", this.props.faultStatusAction, true)}
        </div>
      </div>
    );
  }

  getFdeOmsInfoSection() {
    return (
      <div className="contentInfoMessage__body">
        <div className="contentInfoMessage__body__data">
          {this.addDataRow(
            "Time",
            moment.utc(this.props.faultTimestamp).format("HH:mm:ss")
          )}
          {this.addDataRow("Code", this.props.faultCode)}
          {this.addDataRow("Message", this.props.faultMessage, true)}
          {this.addDataRow("Description", this.props.faultDescription, true)}
        </div>
      </div>
    );
  }

  handleTabSelection(tab) {
    this.setState({
      overviewTabStyle:
        tab === "OVERVIEW"
          ? "contentFdeFault__tabPanel active"
          : "contentFdeFault__tabPanel",
      activeTabID: tab,
    });
  }

  render() {
    return (
      <div className="contentInfoMessage">
        {/*<div className="contentInfoMessage__tabContainer">
          <button
            className={this.state.overviewTabStyle}
            onClick={() => {
              this.handleTabSelection("OVERVIEW");
            }}
          >
            Overview
          </button>
          </div>*/}

        {this.handleShowTab()}
      </div>
    );
  }

  handleShowTab() {
    let showTab = <div />;

    if (this.state.activeTabID === "OVERVIEW") {
      if (this.props.faultType === "CMS") {
        showTab = this.getCmsInfoSection();
      } else {
        showTab = this.getFdeOmsInfoSection();
      }
    }

    return showTab;
  }

  addDataRow(key, value, long = false) {
    return (
      <div className={"contentInfoMessage__data" + (long ? "-long" : "")}>
        {key !== "" ? (
          <div className="contentInfoMessage__data__key">{key + ":"}</div>
        ) : (
          ""
        )}
        <div className="contentInfoMessage__data__value">
          {value || value === "" ? value : "-"}
        </div>
      </div>
    );
  }
}
