import React, { useState } from "react";
import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import HistoricDataRow from "./HistoricDataRow";
import HistoricDataCollapseRow from "./HistoricDataCollapseRow";
import "./HistoricDataTable.css";
import ToggleSwitch from "../Global Components/ToggleSwitch";

function HistoricDataTable({
  recentSessionHistory,
  flightHistory,
  handleHistoricSessionSelection,
  handleRecentSessionSelection,
  selectedSessionStartTime,
  selectedSessionEndTime,
  selectedSessionNumber,
}) {
  const [sessionNumberSort, setSessionNumberSort] = useState("none");
  const [orderStartTimeSort, setOrderStartTimeSort] = useState("none");
  const [orderEndTimeSort, setOrderEndTimeSort] = useState("none");
  const [expandAllRows, setExpandAllRows] = useState(
    localStorage.getItem("cHistoricalExpandAllRows") === "0" ? false : true
  );

  const handleSessionNumberSort = () => {
    const newSortStatus =
      sessionNumberSort === "none"
        ? "desc"
        : sessionNumberSort === "desc"
        ? "asc"
        : "none";
    setSessionNumberSort(newSortStatus);
  };

  const handleOrderStartTimeSort = () => {
    const newSortStatus =
      orderStartTimeSort === "none"
        ? "desc"
        : orderStartTimeSort === "desc"
        ? "asc"
        : "none";
    setOrderStartTimeSort(newSortStatus);
  };

  const handleOrderEndTimeSort = () => {
    const newSortStatus =
      orderEndTimeSort === "none"
        ? "desc"
        : orderEndTimeSort === "desc"
        ? "asc"
        : "none";
    setOrderEndTimeSort(newSortStatus);
  };

  const handleToggleTableExpand = () => {
    localStorage.setItem(
      "cHistoricalExpandAllRows",
      !expandAllRows ? "1" : "0"
    );
    setExpandAllRows(!expandAllRows);
  };

  const getSortedData = () => {
    const orderKeyArray = ["order"];
    const orderValueArray = ["asc"];

    // No sort option has been selected so we go to default
    if (
      sessionNumberSort === "none" &&
      orderStartTimeSort === "none" &&
      orderEndTimeSort === "none"
    ) {
      orderKeyArray.push("orderStartTime");
      orderValueArray.push("desc");
      return _.concat(
        _.orderBy(recentSessionHistory, orderKeyArray, orderValueArray),
        _.orderBy(flightHistory, orderKeyArray, orderValueArray)
      );
    }

    // A sort option has been selected so we process the sorting
    if (sessionNumberSort !== "none") {
      orderKeyArray.push("session");
      orderValueArray.push(sessionNumberSort);
    }

    if (orderStartTimeSort !== "none") {
      orderKeyArray.push("orderStartTime");
      orderValueArray.push(orderStartTimeSort);
    }

    if (orderEndTimeSort !== "none") {
      orderKeyArray.push("orderEndTime");
      orderValueArray.push(orderEndTimeSort);
    }

    if (orderStartTimeSort === "asc" || orderEndTimeSort === "asc") {
      return _.concat(
        _.orderBy(flightHistory, orderKeyArray, orderValueArray),
        _.orderBy(recentSessionHistory, orderKeyArray, orderValueArray)
      );
    }

    return _.concat(
      _.orderBy(recentSessionHistory, orderKeyArray, orderValueArray),
      _.orderBy(flightHistory, orderKeyArray, orderValueArray)
    );
  };

  const renderHistoricDataTable = () => {
    const historicDataTableContent = (
      <div className="historicDataTable">
        <div className="historicDataTable__controls historicDataTable__font__controls">
          <ToggleSwitch
            id="settings-external-rpd-request-switch"
            isChecked={expandAllRows}
            handleSwitchChange={() => handleToggleTableExpand()}
          />
          <span>{expandAllRows ? "Collapse all rows" : "Expand all rows"}</span>
        </div>
        <div className="historicDataTable__content">
          <div className="historicDataTable__header">
            <div className="historicDataTable__header__sessionInfo">
              Session info
            </div>
            <div className="historicDataTable__header__totalFaultMsg">
              Total Fault messages
            </div>
          </div>
          <div className="historicDataTable__separator_header"></div>
          <div className="historicDataTable__header">
            <div className="historicDataTable__header__sessionNumber">
              <div
                className="historicDataTable__header__sortButton"
                onClick={handleSessionNumberSort}
              >
                <span className="historicDataTable__header__sortButton__text">
                  Session / Flight
                </span>
                <FeatherIcon
                  icon={
                    sessionNumberSort === "none"
                      ? ""
                      : sessionNumberSort === "asc"
                      ? "arrow-down"
                      : "arrow-up"
                  }
                  className={"historicDataTable__header__sortButton__icon"}
                />
              </div>
            </div>
            <div className="historicDataTable__header__startDate">
              <div
                className="historicDataTable__header__sortButton"
                onClick={handleOrderStartTimeSort}
              >
                <span className="historicDataTable__header__sortButton__text">
                  Session Start / Take Off
                </span>
                <FeatherIcon
                  icon={
                    orderStartTimeSort === "none"
                      ? ""
                      : orderStartTimeSort === "asc"
                      ? "arrow-down"
                      : "arrow-up"
                  }
                  className={"historicDataTable__header__sortButton__icon"}
                />
              </div>
            </div>
            <div className="historicDataTable__header__endDate">
              <div
                className="historicDataTable__header__sortButton"
                onClick={handleOrderEndTimeSort}
              >
                <span className="historicDataTable__header__sortButton__text">
                  Session End / Landing
                </span>
                <FeatherIcon
                  icon={
                    orderEndTimeSort === "none"
                      ? ""
                      : orderEndTimeSort === "asc"
                      ? "arrow-down"
                      : "arrow-up"
                  }
                  className={"historicDataTable__header__sortButton__icon"}
                />
              </div>
            </div>
            <div className="historicDataTable__header__airports">Airports</div>
            <div className="historicDataTable__header__faultWarning">
              <FeatherIcon
                icon="circle"
                width={18}
                height={18}
                fill="#FF6666"
                className="warning circle"
              />
              Warning
            </div>
            <div className="historicDataTable__header__faultCaution">
              <FeatherIcon
                icon="circle"
                width={18}
                height={18}
                fill="#FFC72C"
                className="caution circle"
              />
              Caution
            </div>
            <div className="historicDataTable__header__faultAdvisory">
              <FeatherIcon
                icon="circle"
                width={18}
                height={18}
                fill="#6699FF"
                className="advisory circle"
              />
              Advisory
            </div>
            <div className="historicDataTable__header__faultInfo">
              <FeatherIcon
                icon="circle"
                width={18}
                height={18}
                fill="#FFFFFF"
                className="info circle"
              />
              Info
            </div>
          </div>
          {renderHistoricDataTableRows()}
        </div>
      </div>
    );

    return historicDataTableContent;
  };

  const renderHistoricDataTableRows = () => {
    return (
      <div className="historicDataTable__body">
        {getSortedData().map((session) => (
          <React.Fragment
            key={
              session.rowType +
              "-" +
              session.session +
              "-" +
              Math.random() * 90000
            }
          >
            {session.rowType === "HISTORY_SESSION" ? (
              <HistoricDataCollapseRow
                sessionNumber={session.session}
                sessionStartTime={session.sessionStartTime}
                sessionEndTime={session.sessionEndTime}
                ifrSessionOrder={session.ifrSessionOrder}
                sessionType={session.sessionType}
                startTime={session.startTime}
                endTime={session.endTime}
                faultsInfoTotal={session.faultsInfoTotal}
                faultsAdvisoryTotal={session.faultsAdvisoryTotal}
                faultsCautionTotal={session.faultsCautionTotal}
                faultsWarningTotal={session.faultsWarningTotal}
                flights={session.flights}
                missingData={session.missingData}
                handleHistoricSessionSelection={handleHistoricSessionSelection}
                handleRecentSessionSelection={handleRecentSessionSelection}
                selectedSessionStartTime={selectedSessionStartTime}
                selectedSessionEndTime={selectedSessionEndTime}
                selectedSessionNumber={selectedSessionNumber}
                externalExpand={expandAllRows}
              />
            ) : (
              <HistoricDataRow
                sessionNumber={session.session}
                sessionStartTime={session.sessionStartTime}
                sessionEndTime={session.sessionEndTime}
                ifrSessionOrder={session.ifrSessionOrder}
                departureTime={session.departureTime}
                arrivalTime={session.arrivalTime}
                sessionType={session.sessionType}
                startTime={session.startTime}
                endTime={session.endTime}
                airports={session.airports}
                faultsInfoTotal={session.faultsInfoTotal}
                faultsAdvisoryTotal={session.faultsAdvisoryTotal}
                faultsCautionTotal={session.faultsCautionTotal}
                faultsWarningTotal={session.faultsWarningTotal}
                missingData={session.missingData}
                rowType={session.rowType}
                handleHistoricSessionSelection={handleHistoricSessionSelection}
                handleRecentSessionSelection={handleRecentSessionSelection}
                selectedSessionStartTime={selectedSessionStartTime}
                selectedSessionEndTime={selectedSessionEndTime}
                selectedSessionNumber={selectedSessionNumber}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return renderHistoricDataTable();
}

export default HistoricDataTable;
