import React from "react";
//import MaterialTable from "material-table";
//import MTableToolbar from "material-table";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import "./SessionEventCountTable.css";
import moment from "moment";
import { forwardRef } from "react";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import AircraftIcon from "../resources/aircraft-solid.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

const SessionEventCountTable = ({
  faultCountsByAircraft,
  selectedTail,
  currentFaultColor,
}) => {
  const lowResolution = useMediaQuery("(max-width:1368px)");

  const tableColumns = [
    {
      title: "Session",
      field: "sessionNumber",
      cellStyle: {
        borderLeft: "2px solid #686969",
        borderBottom: "2px solid #686969",
        borderTop: "2px solid #686969",
        borderRadius: 1,
      },
    },
    {
      title: "Session Start",
      field: "sessionStartDate",
      render: (rowData) =>
        moment(rowData.sessionStartDate).format("MMM D, YYYY - h:mm"),
      cellStyle: {
        borderBottom: "2px solid #686969",
        borderTop: "2px solid #686969",
        borderRadius: 1,
      },
    },
    {
      title: "Session End",
      field: "sessionEndDate",
      render: (rowData) =>
        moment(rowData.sessionEndDate).format("MMM D, YYYY - h:mm"),
      cellStyle: {
        borderBottom: "2px solid #686969",
        borderTop: "2px solid #686969",
        borderRadius: 1,
      },
    },
    {
      title: "Count",
      field: "count",
      width: "20%",
      render: (rowData) => {
        return (
          <span className="SessionEventCountTable__countCell">
            <span
              className="SessionEventCountTable__countCell__color"
              style={{
                backgroundColor:
                  //rowData.count !== 0 ? currentFaultColor : "#ADA6A6",
                  rowData.event_count !== 0 ? currentFaultColor : "#ADA6A6",
              }}
            ></span>
            {/*rowData.count*/}
            {rowData.event_count}
          </span>
        );
      },
      cellStyle: {
        borderBottom: "2px solid #686969",
        borderTop: "2px solid #686969",
        borderRadius: 1,
      },
    },
  ];

  const darkTheme = createTheme({
    typography: {
      fontFamily: "DM Sans",
    },
    palette: {
      mode: "dark",
    },
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 2,
          paddingRight: 0,
          paddingBottom: 2,
        },
      },
      MuiTable: {
        root: {
          borderSpacing: "0 5px",
          borderCollapse: "separate",
          backgroundColor: "#303030",
        },
      },
    },
  });

  const tableIcons = {
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
  };

  return (
    <>
      <div className="SessionEventCountTable__sessionsInfo">
        <div className="SessionEventCountTable__sessionsInfo__vector" />
        <img
          className="SessionEventCountTable__aircraftImage"
          src={AircraftIcon}
          alt="aircraft"
        />
        <span className="SessionEventCountTable__sessionsInfo__tail">
          <p className="SessionEventCountTable__sessionsInfo__tail__serialNumber">
            #{selectedTail?.serialNumber}
          </p>
          <p>{selectedTail?.registrationNumber}</p>
        </span>
        <div className="SessionEventCountTable__sessionsInfo__occurences__block">
          <p
            className="SessionEventCountTable__sessionsInfo__occurences"
            style={{ color: currentFaultColor }}
          >
            {faultCountsByAircraft?.totalSessionsCount} session occurences
          </p>
        </div>
      </div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={darkTheme}>
          <MaterialTable
            icons={tableIcons}
            columns={tableColumns}
            data={faultCountsByAircraft?.sessions}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    height: "0px",
                  }}
                >
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: false,
              selection: false,
              showTitle: false,
              toolbar: false,
              paging: false,
              draggable: false,
              sorting: true,
              maxBodyHeight: lowResolution ? "280px" : "450px",
              headerStyle: {
                position: "initial",
                backgroundColor: "#303030",
                fontSize: 16,
                fontWeight: 700,
                paddingTop: 15,
                paddingBottom: 15,
              },
              rowStyle: {
                border: "2px solid #686969",
                borderRadius: "2px",
                backgroundColor: "#202020",
              },
            }}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default SessionEventCountTable;
