import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Client for the aircraft flight cycles and hours API
*/

const FLIGHT_CYCLES = Api.FLIGHT_CYCLES;
const FLIGHT_CYCLE_LOGS = Api.FLIGHT_CYCLE_LOGS;
const POST_FLIGHT_CYCLES = Api.POST_FLIGHT_CYCLES;

// Returns the aircraft's most recent count of flight cycles + hours and engine cycles + hours
export async function getFlightCycles(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tailNumber,
    },
  };

  try {
    result = await AxiosService(
      FLIGHT_CYCLES.url,
      FLIGHT_CYCLES.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Returns the logs of users that have updated the aircrafts flight cycles + hours and engine cycles + hours
export async function getFlightCycleLogs(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tailNumber,
      queryCount: 50,
    },
  };

  try {
    result = await AxiosService(
      FLIGHT_CYCLE_LOGS.url,
      FLIGHT_CYCLE_LOGS.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }
  return result;
}

// Updates the aircraft's most recent count of flight cycles + hours and engine cycles + hours
export async function postFlightCycles(
  tailNumber,
  engine1Serial,
  engine2Serial,
  originalAirframeTime,
  originalAirframeCycles,
  originalEngine1Time,
  originalEngine1Cycles,
  originalEngine2Time,
  originalEngine2Cycles,
  adjustAirframeTime,
  adjustAirframeCycles,
  adjustEngine1Time,
  adjustEngine1Cycles,
  adjustEngine2Time,
  adjustEngine2Cycles,
  aircraftFamily,
  aircraftModel
) {
  let result;
  const data = {
    aircraftFamily: aircraftFamily,
    aircraftModel: aircraftModel,
    tailNumber: tailNumber,
    engine1Serial: engine1Serial,
    engine2Serial: engine2Serial,
    totalFlightTime: originalAirframeTime,
    totalFlightCycles: originalAirframeCycles,
    totalEngine1Time: originalEngine1Time,
    totalEngine1Cycles: originalEngine1Cycles,
    totalEngine2Time: originalEngine2Time,
    totalEngine2Cycles: originalEngine2Cycles,
    adjustFlightTime: adjustAirframeTime,
    adjustFlightCycles: adjustAirframeCycles,
    adjustEngine1Time: adjustEngine1Time,
    adjustEngine1Cycles: adjustEngine1Cycles,
    adjustEngine2Time: adjustEngine2Time,
    adjustEngine2Cycles: adjustEngine2Cycles,
  };

  try {
    result = await AxiosService(
      POST_FLIGHT_CYCLES.url,
      POST_FLIGHT_CYCLES.method,
      data
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Returns the aircraft's count of flight cycles by date + hours and engine cycles + hours
export async function getFlightCyclesByDate(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  pointInTime,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tailNumber,
      pointInTime: pointInTime,
    },
  };

  try {
    result = await AxiosService(
      FLIGHT_CYCLES.url,
      FLIGHT_CYCLES.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}
