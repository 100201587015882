import React from "react";
import { Link } from "@mui/material";
import FeatherIcon from "feather-icons-react";
import * as tools from "../utils/CommonTools";
import CustomButton from "../Global Components/CustomButton";
import "./Unauthorized.css";
import "../styles/Global Components/Button.css";

function Unauthorized() {
  const type = localStorage.getItem("credentialsError") ? localStorage.getItem("credentialsError") : "generic";
  let errorDetail = {
    icon : "alert-triangle",
    title : "Unexpected Error",
    message : "Please reload the page and try logging in again. If the error persists, please contact support."
  }
  switch (type) {
    case "network":
      errorDetail.icon = "wifi-off";
      errorDetail.title = "Network Error";
      errorDetail.message = "Your network is blocking the request. To resolve this issue, you can refresh the page, clear your browser cache, or try accessing our site in a different browser. If the issue persists, contact your network administrator.";
      break;
    case "server":
      errorDetail.icon = "alert-triangle";
      errorDetail.title = "Server Error";
      errorDetail.message = "An internal server error has occurred. Please reload the page and try logging in again. If the error persists, please contact support."
      break;
    case "unauthorized":
      errorDetail.icon = "user-x";
      errorDetail.title = "Unauthorized";
      errorDetail.message = "This user is not authorized to access this application. Please contact an authorized administrator to request access."
      break;
    case "generic":
      errorDetail.icon = "alert-triangle";
      errorDetail.title = "Unexpected Error";
      errorDetail.message = "Please reload the page and try logging in again. If the error persists, please contact support."
      break;
    default:
      break;
  }
  const handleLogout = () => {
    tools.cleanAuthLocalStorage();
  };

  return (
    <div className="unauthorized">
      <div className="unauthorized__container">
        <div className="unauthorized__flag"></div>
        <div className="unauthorized__content">
          <div className="unauthorized__body">
            <div className="unauthorized__row">
              <div className="unauthorized__icon">
                <FeatherIcon
                  icon={errorDetail.icon}
                  width={45}
                  height={45}
                  color={"#FFFFFF"}
                />
              </div>
              <div className="unauthorized__message unauthorized__font__title">
                {errorDetail.title}
              </div>
            </div>
            <div className="unauthorized__row">
              <div className="unauthorized__icon"></div>
              <div className="unauthorized__message">
                { errorDetail.message}
              </div>
            </div>
          </div>
          <div className="unauthorized__footer">
            <div className="unauthorized__row">
              <div className="unauthorized__icon"></div>
              <div className="unauthorized__message">
                <Link
                  color="primary"
                  onClick={handleLogout}
                  href={"/applogout"}
                >
                  <CustomButton
                    id={"btn-unauthorized-login"}
                    text={"LOG IN"}
                    icon={
                      <FeatherIcon
                        icon={"arrow-right"}
                        width={18}
                        height={18}
                        color={"#FFFFFF"}
                      />
                    }
                    iconPosition={"end"}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unauthorized;
