import React, { useState } from "react";
import "./GlobalTextarea.css";

/**
 * The reusable textarea element.
 * @param id The identifier.
 * @param defaultValue The default value of the input.
 * @param handleInputOffFocus Handler for when the user clicks out of the input area.
 * @param placeholder The input's placeholder.
 * @param maximumLength
 * @param label The input label.
 * @param readonly Flag to indicate if the input is readonly.
 * @return {JSX.Element}
 * @constructor
 */
export default function GlobalTextarea({
  id,
  defaultValue,
  handleInputOffFocus,
  placeholder,
  maximumLength,
  label,
  readonly
}) {

  const [actualLength, setActualLength] = useState(0);

  /**
   * Handles the "blur" action on the input.
   * @param newTextareaValue The value of the input.
   */
  const handleOffFocus = (newTextareaValue) => {
    handleInputOffFocus(newTextareaValue.target.value);
  }

  const handleKeyUp = (textAreaValue) => {
    setActualLength(textAreaValue.target.value.length);
  }
  return (
    <>
      {label && label !== "" && <p className={"global__textarea__label"}>{label}</p>}
      <textarea
        id={"global-textarea-" + id}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={"global__textarea__input"}
        readOnly={readonly}
        onBlur={handleOffFocus}
        onKeyUp={handleKeyUp}
        maxLength={maximumLength ? maximumLength : 0}
      >
      </textarea>
      <p className={"global__textarea__length"}>{`(${actualLength}/${maximumLength})`}</p>
    </>
  )
}