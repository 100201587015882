import * as tools from "../utils/CommonTools";

// This function controls the user permissions per page that are obtained from the credentials access token.
function getRolesPermissions(pagePath) {
  const token = localStorage.getItem("token");
  const decodedToken = token ? tools.decodeJWTToken(token) : null;

  if (decodedToken) {
    if (pagePath === "rpdview") {
      return {
        createRpdRequest: decodedToken?.identity?.createRpdRequest,
        rpdOverride: decodedToken?.identity?.rpdOverride,
      };
    }
    if (pagePath === "aircraftview") {
      return {
        editFlightCycles: decodedToken?.identity?.editFlightCycles,
      };
    }
    if (pagePath === "configview") {
      return {
        editAcSettings: decodedToken?.identity?.editAcSettings,
        editFlightCycles: decodedToken?.identity?.editFlightCycles,
      };
    }
    if (pagePath === "ahmsview") {
      return {
        user: decodedToken?.identity?.user_full_name,
        templatePublicPolicyInternal:
          decodedToken?.identity?.templatePublicPolicyInternal,
        templatePublicPolicyExternal:
          decodedToken?.identity?.templatePublicPolicyExternal,
      };
    }
    if (pagePath === "uploads") {
      return {
        createGroundLoadingRequest:
          decodedToken?.identity?.createGroundLoadingRequest,
        createManualUploadRequest:
          decodedToken?.identity?.createManualUploadRequest,
      };
    }
    if (pagePath === "global") {
      /* TODO: This validation to production is added because we have not supported PMX permissions in development yet */
      return {
        predictiveMaintenanceAccess:
          process.env.REACT_APP_ENVIRONMENT !== "PROD" &&
          process.env.REACT_APP_ENVIRONMENT !== "PRE-PROD"
            ? true
            : decodedToken?.identity?.pmx_enable,
        predictiveMaintenanceFeedbackAccess:
          process.env.REACT_APP_ENVIRONMENT !== "PROD" &&
          process.env.REACT_APP_ENVIRONMENT !== "PRE-PROD"
            ? true
            : decodedToken?.identity?.pmx_feedback,
      };
    }
  } else {
    return null;
  }
}

export { getRolesPermissions };
