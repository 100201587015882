export const blue = "#3F51B5";
export const grey = "#303030";
export const white = "#FFF";

export const styleReset = {
  primaryColor: white,
  textColor: white,
  backgroundColor: blue,
  arrowColor: blue,
};

export const buttonStyles = {
  borderRadius: "34.5px",
  cursor: "pointer",
  display: "flex",
  flexFlow: "row",
  fontSize: "14px",
  fontWeight: "500",
  justifyContent: "center",
  lineHeight: 1,
  padding: "10px",
  marginBottom: "12px",
  marginRight: "15px",
  // width: "fit-content",
  width: "100px",
  fontFamily: "DM Sans",
};