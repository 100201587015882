/* eslint-disable func-names */
import React, { createRef, useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import "../styles/Graph Components/MenuMoreOptions.css";

export default function MenuMoreOptions({
  chartId,
  parameterName,
  downloadXLSX,
  removeParam,
}) {
  const [menuActive, setMenuActive] = useState(false);
  const wrapperRef = createRef();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMenuActive(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const renderActionsSelection = () => {
    let actionItems;
    if (downloadXLSX !== null) {
      actionItems = (
        <React.Fragment key={"menu-moreOptions"}>
          <div
            className="menu__moreOptions__expanded__item"
            onClick={(event) => downloadXLSX(parameterName, chartId)}
          >
            Download XLSX
          </div>
          <div
            className="menu__moreOptions__expanded__item"
            onClick={(event) => removeParam(parameterName)}
          >
            Remove Parameter
          </div>
        </React.Fragment>
      );
    } else {
      actionItems = (
        <div
          className="menu__moreOptions__expanded__item"
          onClick={(event) => removeParam(parameterName)}
        >
          Remove Parameter
        </div>
      );
    }

    return (
      <div
        className={
          "menu__moreOptions__expanded menu__moreOptions__expanded" +
          (menuActive ? "-visible" : "-hidden")
        }
      >
        {actionItems}
      </div>
    );
  };

  return (
    <div ref={wrapperRef}>
      <div className="menu__moreOptions">
        <div
          className={
            "menu__moreOptions__icon" +
            (menuActive ? " menu__moreOptions__icon-active" : "")
          }
        >
          <FeatherIcon
            onClick={(event) => toggleMenu()}
            icon="more-vertical"
            height={20}
          />
        </div>
        {renderActionsSelection()}
      </div>
    </div>
  );
}
