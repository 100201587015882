import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";
import FormData from "form-data";

/*
  Client that allows to upload images to S3 for the feedback component
*/

const S3_UPLOAD = Api.S3_UPLOAD;

export async function uploadFeedBackImage(dirPath, imageName, file, fileType) {
  let data = new FormData();
  data.append("image", file[0]);
  let result;

  const bucketName = "feedback-dashboard-images";
  const requestString = `${S3_UPLOAD.url}?dirPath=${dirPath}&bucketName=${bucketName}&imageName=${imageName}.${fileType}`;

  try {
    result = AxiosService(requestString, S3_UPLOAD.method, data, null);
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}
