import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { Link } from "@mui/material";

import * as tools from "../../utils/CommonTools";
import { PREDICTIVE_MAINTENANCE_URL } from "../../Constants/Constants";
import LoadingSpinner from "../Global Components/LoadingSpinner";
import TabItem from "../Global Components/TabItem";
import ComponentsHealthBox from "./ComponentsHealthBox";
import GraphExplanationModal from "./GraphExplanationModal";
import InfoBoxRow from "../Global Components/InfoBoxRow";
import { prognosticsTabs } from "../constants";
import { getAircraftData } from "../usefulFunctions";
import { getFlightCycles } from "../../clients/AircraftFlightsClient";
import { convertMinutesToHoursMinutesText } from "../usefulFunctions";
import { getTakeOffReport } from "../../clients/TakeOffReportClient";

import "./Prognostics.css";

const Prognostics = (props) => {
    let currUrl = window.location.href;
    let urlSplit = currUrl.split("/");
    const serialNumber = urlSplit[urlSplit.length - 1];

    const url = PREDICTIVE_MAINTENANCE_URL();

    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(null);
    const [aircraftData, setAircraftData] = useState(null);
    const [componentsList, setComponentsList] = useState(null);
    const [aircraftInfo, setAircraftInfo] = useState(null);
    const [showGraphModal, setShowGraphModal] = useState(false);

    /**
     * Function to redirect to kanban page
     */
    const redirectToReports = (aircraftData) => {
        localStorage.setItem("currentReportsFleet", JSON.stringify(aircraftData));
        window.location.href = '/predictive';
    }

    /**
     * Function for Graph Explanation modal
     */
    const handleGraphIconClick = (value) => {
        setShowGraphModal(value);
    };

    /**
     * Function to get basic aircraft data
     */
    const updateAircraftData = async (serialNumber) => {
        const aircraft = await getAircraftData(serialNumber);
        if (aircraft) {
            setAircraftData({
                aircraftFamily: aircraft.aircraftFamily,
                aircraftModel: aircraft.aircraftModel,
                serialNumber: aircraft.serialNumber,
                tailNumber: aircraft.registrationNumber,
                shortSerialNumber: serialNumber
            });

            // Add aircraft to list of prognostics viewed
            const prognosticsViewed = localStorage.getItem("prognosticsViewed")
                && typeof localStorage.getItem("prognosticsViewed") !== "undefined"
                ? JSON.parse(localStorage.getItem("prognosticsViewed"))
                : [];
            prognosticsViewed.push({
                serialNumber: serialNumber,
                lastClicked: new Date().getTime()
            });
            localStorage.setItem("prognosticsViewed", JSON.stringify(prognosticsViewed))

            setLoading(false);
        }
    };

    /**
     * Function to retrieve prognotics components for a specific aircraft
     */
    const getPrognosticsComponentsList = async (serialNumber) => {
        let components = [];
        let newSerialNumber = serialNumber;
        if (serialNumber < 10) {
            newSerialNumber = `0${serialNumber}`;
        }

        const response = await axios
            .get(`${url}/prognostics/components?aircraft_sn=${newSerialNumber}`)
            .catch((error) => {
                setComponentsList([]);
                console.error("No components list retrieved.")
                return;
            });

        if (response?.data) {
            const componentsTimeSeriesPromises = [];

            for (let i = 0; i < response.data.length; i++) {
                const component = response.data[i];
                const componentsTimeSeriesPromise = axios
                    .get(`${url}/prognostics/component_health_ts?aircraft_sn=${newSerialNumber}&part_number=${component.part_number}`)
                    .catch(() => {
                        console.error(`No time series for part number ${component.part_number}`);
                    });

                componentsTimeSeriesPromises.push(componentsTimeSeriesPromise);
            };

            Promise.all(componentsTimeSeriesPromises)
                .then((res) => {
                    for (let c = 0; c < res.length; c++) {
                        if (typeof res[c] !== "undefined") {
                            const component = res[c].data;

                            let componentIndex = _.findIndex(
                                components,
                                function (c) {
                                    return c.part_number === component.part_number
                                }
                            );

                            if (componentIndex === -1)
                                components.push(component);
                        }
                    }
                    setComponentsList(_.orderBy(components, ["health_score"], ["asc"]));
                });
        } else {
            setComponentsList([]);
        }
    }

    /**
     * Function to retrieve aircraft information
     */
    const handleAircraftInformation = async () => {
        // format id
        const id = `${aircraftData.aircraftFamily} ${aircraftData.aircraftModel} (${aircraftData.serialNumber} / ${aircraftData.tailNumber})`;

        // get the flight hours
        const flightCycles = await processFlightCycles();
        const flightHours = flightCycles?.totalFlightTime ? convertMinutesToHoursMinutesText(flightCycles.totalFlightTime) : "N/A";

        // get flight history
        let lastFlightTime = "N/A";
        const takeOffReport = await processTakeOffReport();
        const latestFlight = takeOffReport.flights[takeOffReport.flights.length - 1]
        lastFlightTime = `${latestFlight.departureAirportIATA ?? "-"} - ${latestFlight.arrivalAirportIATA ?? "-"}`;
        lastFlightTime += ` ${latestFlight.departureTime
            ? moment
                .utc(latestFlight.departureTime)
                .format("MMM DD, YYYY")
            : "-"}`;
        lastFlightTime += ` - ${tools.getFormattedTimeDiff(
            latestFlight.departureTime,
            latestFlight.arrivalTime,
            false)
            }`

        setAircraftInfo({
            id: id,
            flightHours: flightHours,
            lastFlight: lastFlightTime,
            nextFlight: "N/A",
            lastMaintenance: "N/A",
            nextMaintenance: "N/A"
        });
    };

    const processFlightCycles = async () => {
        const flightCycles = await getFlightCycles(
            aircraftData.tailNumber,
            aircraftData.aircraftFamily,
            aircraftData.aircraftModel
        );

        if (flightCycles?.data) return flightCycles.data;
    };

    const processTakeOffReport = async () => {
        const takeOffReport = await getTakeOffReport(
            aircraftData.tailNumber,
            null,
            null,
            null,
            aircraftData.aircraftFamily,
            aircraftData.aircraftModel,
            null,
            undefined
        );
        return takeOffReport.data;
    }

    useEffect(() => {
        setLoading(true);
        // In case, app starts from Kanban page
        // Need to set the current mode of sideNav to PMx
        props.setCurrentMode("PMx");

        // Get basic aircraft data
        updateAircraftData(serialNumber);

        // Get components list
        getPrognosticsComponentsList(serialNumber)

        // Set tab by default to Prognostics tab
        setTab(prognosticsTabs[0]);
    }, []);

    useEffect(() => {
        if (aircraftData?.tailNumber) {
            // Get aircraft information
            handleAircraftInformation();
        }
    }, [aircraftData]);

    const Header = ({
        aircraftData,
        tab,
        tabsOptions
    }) => {
        return (
            <div className={"prognosticsView__header"}>
                {/* top part of header */}
                <div className="prognosticsView__globalHeaderContainer">
                    <div className="prognosticsView__globalHeaderContainer__aircraftInfo">
                        {/* <Link href={"/predictive"}> */}
                        <FeatherIcon
                            width={25}
                            height={25}
                            color={"#D2D2D2"}
                            icon={"chevron-left"}
                            strokeWidth={3}
                            style={{ marginTop: 2 }}
                            onClick={() => redirectToReports(aircraftData)}
                        />
                        {/* </Link> */}
                        <div>
                            <div className="prognosticsView__serialNumber">
                                {aircraftData.serialNumber}
                            </div>
                            <div className="prognosticsView__textBelowSerialNumber">
                                {aircraftData.aircraftFamily} {aircraftData.aircraftModel}
                            </div>
                            <div className="prognosticsView__textBelowSerialNumber">
                                {aircraftData.tailNumber}
                            </div>
                        </div>
                    </div>
                </div>


                {/* bottom part of header */}
                <div className="prognosticsView__tabsContainer">
                    {tabsOptions?.length > 0 && tabsOptions.map((item) => {
                        return (
                            <div
                                key={item.title}
                                style={{
                                    width: 300,
                                    marginRight: 20,
                                }}
                            >
                                {(item.title.toLowerCase()).includes('report')
                                    ? <div onClick={() => redirectToReports(aircraftData)}>
                                        <TabItem
                                            data={item}
                                            text={item.title}
                                            icon={item.icon}
                                            color={"#D2D2D2"}
                                            currentTab={tab}
                                            title={item.title}
                                            deactivated={item.deactivated}
                                            tooltipText={null}
                                        />
                                    </div >
                                    : <TabItem
                                        data={item}
                                        text={item.title}
                                        icon={item.icon}
                                        color={"#D2D2D2"}
                                        currentTab={tab}
                                        title={item.title}
                                        deactivated={item.deactivated}
                                        tooltipText={null}
                                    />
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    }

    const AircraftInformationBox = ({ item }) => {
        return (
            <div className="infoBox aircraft__information__box">
                <div className="infoBox__header">
                    Aircraft Information
                </div>
                <div className={"infoBox__body"}>
                    {item
                        ? <React.Fragment>
                            {item.id && <InfoBoxRow name={"Aircraft ID"} value={item.id} width={48} />}
                            {item.nextFlight && <InfoBoxRow name={"Next Planned Flight"} value={item.nextFlight} width={48} />}
                            {item.flightHours && <InfoBoxRow name={"Total number of flight hours"} value={item.flightHours} width={48} />}
                            {item.lastMaintenance && <InfoBoxRow name={"Last Maintenance"} value={item.lastMaintenance} width={48} />}
                            {item.lastFlight && <InfoBoxRow name={"Last Flight Information"} value={item.lastFlight} width={48} />}
                            {item.nextMaintenance && <InfoBoxRow name={"Next Scheduled Maintenance"} value={item.nextMaintenance} width={48} />}
                        </React.Fragment>
                        : <div className="aircraft__information__circularProgress__container">
                            <CircularProgress style={{ color: "#979797" }} />
                        </div>}
                </div>
            </div>
        )
    };

    return (
        <div className="prognosticsView">
            {/* if loading, load spinner */}
            {loading && <LoadingSpinner />}

            <div className="prognosticsView__content">
                {aircraftData
                    && <Header
                        aircraftData={aircraftData}
                        tab={tab}
                        tabsOptions={prognosticsTabs}
                    />
                }

                <div className="prognosticsView__contentBody">
                    <div className="prognostics__container">
                        {componentsList?.length === 0 ?
                            <div className="container-no-content">
                                No prognostics generated for this anomaly
                            </div>
                            :
                            <>
                                <div className="prognostics__container__row">
                                    <div className="infoBox__container prognostics__container__item">
                                        <AircraftInformationBox
                                            item={aircraftInfo}
                                        />
                                    </div>
                                </div>
                                <div className="prognostics__container__row">
                                    <div className="infoBox__container prognostics__container__item">
                                        <ComponentsHealthBox
                                            components={componentsList}
                                            aircraftInfo={aircraftInfo}
                                            handleGraphIconClick={handleGraphIconClick}
                                        />
                                    </div>
                                </div>
                                <GraphExplanationModal
                                    show={showGraphModal}
                                    handleGraphIconClick={handleGraphIconClick}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prognostics;