import React, { Fragment, useReducer } from "react";
import { Group } from "@visx/group";
import { Tree } from "@visx/hierarchy";
import { hierarchy } from "d3-hierarchy";
import Nodes from "./Tree Components/NodesMove";
import { LinkVerticalStep } from "@visx/shape";

const TreeContainer = (props) => {
    const {
        treePosition,
        data,
        width,
        height,
        currentLRU,
        updateCurrentLRU,
    } = props;
    let LinkComponent = LinkVerticalStep;
    const layout = "cartesian";
    const orientation = "vertical";
    const linkType = "step";
    const stepPercent = 0.5;
    const margin = {
        top: 100,
        left: 0,
        right: 0,
        bottom: 0
    };
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    let origin;
    let sizeWidth;
    let sizeHeight;

    origin = { x: 0, y: 0 };
    sizeWidth = innerWidth;
    sizeHeight = innerHeight;

    const root = hierarchy(data, (d) => (d.isExpanded ? d.children : null));

    const onNodeClick = (node, event) => {
        if (!node.data.isExpanded) {
            node.data.x0 = node.x;
            node.data.y0 = node.y;
        }

        node.data.isExpanded = !node.data.isExpanded;

        if (node.depth === 0) {
            if (!node.data.isExpanded) {
                // When collapsing whole tree, unselect current LRU
                if (currentLRU) {
                    currentLRU.data.isExpanded = false;
                    updateCurrentLRU(null);
                }
            }
        }
        else if (node.depth === 1) {
            if (currentLRU) {
                if (node.data.name === currentLRU.data.name) {
                    currentLRU.data.isExpanded = true;
                } else {
                    // When changing LRU
                    currentLRU.data.isExpanded = false;
                }

                // Can only select one LRU at a time
                updateCurrentLRU(node.data.isExpanded ? node : null);
            }
        }

        // Force Node component to re-render because state of nodes changed
        forceUpdate();
    };

    return (
        <Fragment>
            <Tree
                top={margin.top}
                left={margin.left}
                root={root}
                size={[sizeWidth, sizeHeight]}
                separation={(a, b) => (a.parent == b.parent ? 1 : 0.5) / a.depth}
            >
                {(tree) => (
                    <Group top={origin.y} left={origin.x}>
                        {tree.links().map((link, i) => (
                            <LinkComponent
                                key={i}
                                data={link}
                                percent={stepPercent}
                                stroke="white"
                                strokeWidth="2"
                                fill="none"
                            />
                        ))}

                        <Nodes
                            nodes={tree.descendants()}
                            layout={layout}
                            orientation={orientation}
                            onNodeClick={(node, event) => onNodeClick(node, event)}
                            treePosition={treePosition}
                            updateCurrentLRU={updateCurrentLRU}
                            treeWidth={width}
                        />
                    </Group>
                )}
            </Tree>
        </Fragment>
    );

};

export default TreeContainer;