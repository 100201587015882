import React, { useState, useEffect, useMemo } from "react";
import "./MdcList.css";

import MdcTable from "./MdcTable";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Search from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { getMdcMessages } from "../clients/MdcEventHistoryData";

const CustomTextField = styled(TextField)({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  margin: "0px",
  "& .MuiOutlinedInput-root": {
    height: "36px",
    "& fieldset": {
      borderWidth: "2px",
      borderRadius: "20px",
    },
  },
  "& label.Mui-focused": {
    color: "#D2D2D2",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#D2D2D2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D2D2D2",
      borderWidth: "1px",
      borderRadius: "0px",
    },
    "&:hover fieldset": {
      borderColor: "#D2D2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D2D2D2",
    },
  },
  "& .MuiOutlinedInput-adornedEnd": {
    paddingRight: "10px",
  },
});

const MdcList = ({
  aircraftFamily,
  aircraftModel,
  tailNumber,
  setMdcRowData,
  omsRowClickEvent,
  fdeInfoFault,
  sessionNumber,
}) => {
  const [searchFaultValue, setSearchFaultValue] = useState("");
  const [messageType, setMessageType] = useState({
    checkedFault: true,
    checkedService: true,
  });
  const [mdcData, setMdcData] = useState([]);

  /**
    Filter the initial response from the API with a value from the search bar.
   **/
  const filteredData =
    mdcData.length &&
    mdcData.filter(
      (fault) =>
        fault.mdcCode.toLowerCase().indexOf(searchFaultValue.toLowerCase()) >=
          0 ||
        fault.mdcReportingLru
          .toLowerCase()
          .indexOf(searchFaultValue.toLowerCase()) >= 0 ||
        fault.mdcMessageName
          .toLowerCase()
          .indexOf(searchFaultValue.toLowerCase()) >= 0
    );

  /**
   Filter the MDC messages by type, it can be "fault" or "service" based on the checkboxes.
   **/
  const filteredDataByType =
    filteredData.length &&
    filteredData.filter((record) => {
      if (messageType.checkedFault && messageType.checkedService) {
        return record;
      } else if (messageType.checkedFault && !messageType.checkedService) {
        return record.mdcMessageType === "Fault";
      } else if (messageType.checkedService && !messageType.checkedFault) {
        return record.mdcMessageType === "Service";
      } else if (!messageType.checkedService && !messageType.checkedFault) {
        return;
      }
    });

  /**
   * Function to handle checkboxes states changes.
   * @param {Event} e - Event object.
   **/
  const handleCheckboxChange = (e) => {
    setMessageType({ ...messageType, [e.target.name]: e.target.checked });
  };

  /**
   * Function to handle checkboxes states changes.
   * @param {Array} mdcMsgs - Mdc mesagges response from API.
   * @returns Sorted array by date in asc order which includes an unique id for each item in the list.
   **/
  const sortByDateAscAndAddIds = (mdcMsgs) => {
    let mdcMsgsWithUniqueIds = mdcMsgs.map((msg) => ({ ...msg, id: uuidv4() }));
    return _.sortBy(mdcMsgsWithUniqueIds, function (mdcObj) {
      return new Date(mdcObj.mdcTimestamp);
    });
  };

  // Get the MDC messages for the aircraft selected.
  useEffect(() => {
    getMdcMessages(tailNumber, aircraftFamily, aircraftModel, sessionNumber)
      .then((res) => {
        if (res) {
          let sortedBydate = sortByDateAscAndAddIds(res);
          setMdcData(sortedBydate);
        }
      })
      .catch((err) => {
        console.error("ERROR: ", err);
      });
  }, [aircraftFamily, aircraftModel, tailNumber]);

  return (
    <>
      <div className="mdclist-header">
        <div className="checkedFault-block">
          <input
            id="checkedFault"
            name="checkedFault"
            type="checkbox"
            checked={messageType.checkedFault}
            onChange={handleCheckboxChange}
          />
          <label className="label-block" htmlFor="checkedFault">
            Fault
          </label>
        </div>
        <div className="checkedService-block">
          <input
            id="checkedService"
            name="checkedService"
            type="checkbox"
            checked={messageType.checkedService}
            onChange={handleCheckboxChange}
          />
          <label className="label-block" htmlFor="checkedService">
            Service
          </label>
        </div>

        <div className="searchbar-block">
          <CustomTextField
            onChange={(e) => setSearchFaultValue(e.target.value)}
            value={searchFaultValue}
            type="text"
            variant="outlined"
            margin="none"
            fullWidth={true}
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  classes={{ positionEnd: "padding-right: 0px !important" }}
                >
                  <Search style={{ color: "#FFFFFF" }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div>
        {filteredDataByType && filteredDataByType.length ? (
          <MdcTable
            mdcData={filteredDataByType}
            setMdcRowData={setMdcRowData}
            omsRowClickEvent={omsRowClickEvent}
            fdeInfoFault={fdeInfoFault}
          />
        ) : (
          <p className="error-message">No records to display</p>
        )}
      </div>
    </>
  );
};

export default MdcList;
