import React from "react";
import { X } from "react-feather";
import ModalWrapper from "../../../ModalWrapper/ModalWrapper";
import NeedHelp from "../FeedbackNeedHelp/NeedHelp";
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './FeedbackModal.scss';

//Note that the children variable is auto set to be the children of the component (props.children)
const FeedbackModal = ({isDark, icon, show, onHide, contactUsLink, children, className}) => {
  return (
        <ModalWrapper
        className={`feedbackModal ${className} ${isDark? "theme-dark" : "theme-light"} `}
        show={show}
        onHide={onHide}
        animation={false}
        dialogClassName={`feedbackDialog ${isDark? "feedbackDialog-dark" : ""}`}>
            <Modal.Header className="feedbackModalHeader">
            <Modal.Title className="feedbackModalTitle">
                <span className={`feedbackMessageIcon  ${isDark? "feedbackMessageIcon-dark" : ""}`}>{icon}</span>
                GIVE FEEDBACK
            </Modal.Title>
            <X
                onClick={onHide}
                className={`modalCloseIcon  ${isDark? "modalCloseIcon-dark" : ""}`}
                size="13"
            />
            </Modal.Header>
            <Modal.Body className="feedbackModalBody">
            <NeedHelp contactUsLink={contactUsLink}/>
            {children}
            </Modal.Body>
     </ModalWrapper>  
  );
}

export default FeedbackModal;

FeedbackModal.defaultProps = {
    show: true
}

FeedbackModal.propTypes = {
    icon: PropTypes.element,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    contactUsLink: PropTypes.string
};
  