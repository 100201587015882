import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import * as tools from "../utils/CommonTools";
import moment from "moment";

function PrivateRouteWithMultiplePaths({
  ChildComponent,
  handleBreadcrumb,
  paths,
  ...rest
}) {
  const token = localStorage.getItem("token");
  const tokenExpiration = localStorage.getItem("tokenExpiration");
  const tokenRefresh = localStorage.getItem("tokenRefresh");
  const tokenRefreshExp = localStorage.getItem("tokenRefreshExpiration");
  const userType = localStorage.getItem("userType");
  const userInfo = localStorage.getItem("userInfo");

  const validRefreshTokenExpiration =
    moment.utc(tokenRefreshExp).valueOf() > moment.utc(new Date()).valueOf();

  const hasSession = !!(
    token &&
    tokenExpiration &&
    tokenRefresh &&
    validRefreshTokenExpiration &&
    userType &&
    userInfo
  );
  const auth = hasSession;

  if (!auth) {
    tools.cleanAuthLocalStorage();
    window.location.href = "/";
  }

  useEffect(() => {
    if (handleBreadcrumb) {
      handleBreadcrumb(rest.path);
    }
  }, [rest.path, handleBreadcrumb]);

  return (
    <Route
      path={paths}
      render={() =>
        auth === true ? <ChildComponent {...rest} /> : <div></div>
      }
    />
  );
}

export default PrivateRouteWithMultiplePaths;
