// this file is to show the image on the diagnostics page but enlarged
import _ from "lodash";
import React, { useRef, useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Zoom } from "@visx/zoom";
import { localPoint } from "@visx/event";
import Modal from "@mui/material/Modal";
import { RectClipPath } from "@visx/clip-path";

const ImageModal = (props) => {
  const {
    show,
    image,
    currentLRU,
    handleCloseImageModal,
    ata
  } = props;

  const [dims, setDims] = useState({
    height: null,
    width: null
  });
  const [transformMatrix, setTransformMatrix] = useState({
    scaleX: 1,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0
  });

  const bg = "#FFFFFF";
  const modalHeaderHeight = "35px";
  const modalHeight = "600px";
  const imgModalRef = useRef(null);

  const getImageModalContainerDims = () => {
    let width = imgModalRef.current.offsetWidth;
    let height = imgModalRef.current.offsetHeight;

    // const lruImgWidth = image.width;
    // const lruImgHeight = image.height;

    // let scale = 1

    // transformMatrix.translateY = 0;
    // transformMatrix.scaleX = scale;
    // transformMatrix.scaleY = scale;
    // setTransformMatrix({ ...transformMatrix });

    return {
      height: height,
      width: width
    };
  };

  useEffect(() => {
    setTimeout(() => {
      if (imgModalRef.current) {
        setDims(getImageModalContainerDims());
      }
    }, 300);
  }, [show]);

  return (
    <React.Fragment>
      {show &&
        <Modal
          open={show}
          closeAfterTransition
          onClose={() => {
            handleCloseImageModal();
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="diagnostics__container__item"
            style={{ width: "50%", height: modalHeight }}>
            <div className="infoBox">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: modalHeaderHeight,
                  width: "100%",
                  color: "#909090",
                  backgroundColor: "#303030",
                  fontStyle: "italic"
                }}>
                {currentLRU.data.lruName}
              </div>
              <div
                className="infoBox__body-no-content"
                style={{
                  width: "100%",
                  height: `calc(100% - ${modalHeaderHeight})`
                }}
                ref={imgModalRef}
              >
                {dims.height && dims.width
                  ? (
                    <Zoom
                      width={dims.width}
                      height={dims.height}
                      scaleXMin={0.05}
                      scaleXMax={4}
                      scaleYMin={0.05}
                      scaleYMax={4}
                      initialTransformMatrix={transformMatrix}
                    >
                      {(zoom) => {
                        return (
                          <div>
                            <svg
                              width={dims.width}
                              height={dims.height}
                              style={{
                                cursor: zoom.isDragging ? "grabbing" : "grab",
                              }}
                            >
                              <RectClipPath
                                id="zoom-clip"
                                width={dims.width}
                                height={dims.height}
                              />
                              <rect
                                width={dims.width}
                                height={dims.height}
                                fill={bg}
                              />
                              <g transform={zoom.toString()}>
                                <image height={image.height} width={image.width} href={image.url} />
                              </g>
                              <rect
                                width={dims.width}
                                height={dims.height}
                                fill="transparent"
                                onWheel={zoom.handleWheel}
                                onMouseDown={zoom.dragStart}
                                onMouseMove={zoom.dragMove}
                                onMouseUp={zoom.dragEnd}
                                onMouseLeave={() => {
                                  if (!zoom.isDragging) return;
                                  zoom.dragEnd();
                                }}
                                onDoubleClick={(event) => {
                                  const point = localPoint(event);
                                  zoom.scale({
                                    scaleX: 1.5,
                                    scaleY: 1.5,
                                    point,
                                  });
                                }}
                              />
                            </svg>
                          </div>
                        );
                      }}
                    </Zoom>
                  )
                  : <div className="images__circularProgress__container">
                    <CircularProgress style={{ color: "#979797" }} />
                  </div>}
              </div>
            </div>
          </div>
        </Modal>
      }
    </React.Fragment>
  );
};

export default ImageModal;
