import { Subject } from "rxjs";
import _ from "lodash";

const breadcrumbSubject = new Subject([]);
let breadcrumbContent = [];
/**
 * The service to create and update the breadcrumb items.
 * */
export const breadcrumbService = {
  /**
   * Adds a crumb to the breadcrumb.
   * @param newCrumb The crumb to be added.
   */
  addCrumb: (newCrumb) => {
    // Checks the local storage to see if there is an existing breadcrumb.
    const localBreadcrumb = localStorage.getItem("breadcrumb");
    if (localBreadcrumb) {
      breadcrumbContent = JSON.parse(localBreadcrumb);
    }
    // If the crumb view (where it will redirect when clicked) already
    // exists in the breadcrumb content then the value is replaced.
    const existingIndex = _.findIndex(
      breadcrumbContent,
      (crumb) => crumb.view === newCrumb.view
    );
    if (existingIndex >= 0) {
      breadcrumbContent[existingIndex] = newCrumb;
      breadcrumbContent.length = existingIndex + 1;
    } else {
      breadcrumbContent.push(newCrumb);
    }
    // Sets the local storage item to the new breadcrumb.
    localStorage.setItem("breadcrumb", JSON.stringify(breadcrumbContent));
    breadcrumbSubject.next(breadcrumbContent);
  },
  /**
   * Returns the breadcrumb observable.
   * @return {Observable<T>}
   */
  getBreadcrumb: () => {
    return breadcrumbSubject.asObservable();
  },
};
