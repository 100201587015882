import React, { Component } from "react";

import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import "../styles/Graph Components/LollipopChart.css";
import { getLollipopChartOptions } from "./Chart Options/LollipopChart";
import FeatherIcon from "feather-icons-react";
import _ from "lodash";
import moment from "moment";
import * as tools from "../utils/CommonTools";
import CircleToggle from "./CircleToggle";
import CalculatedValueText from "../Global Components/CalculatedValueText";
import EngineOn from "../resources/engine-on.svg";
import EngineOff from "../resources/engine-off.svg";

export default class LollipopChart extends Component {
  constructor(props) {
    super(props);
    this.setStartEndTimestamp = this.setStartEndTimestamp.bind(this);
    this.getChartOptions = this.getChartOptions.bind(this);
    this.setChartSeries = this.setChartSeries.bind(this);
    this.getCMSSystemResources = this.getCMSSystemResources.bind(this);
    this.createCMSFilterObjects = this.createCMSFilterObjects.bind(this);
    this.toggleMessages = this.toggleMessages.bind(this);
    this.toggleCMSLegendModal = this.toggleCMSLegendModal.bind(this);
    this.getCmsFilters = this.getCmsFilters.bind(this);
    this.getFdeFilters = this.getFdeFilters.bind(this);

    const { startTime, endTime } = this.setStartEndTimestamp();
    const chartOptions = this.getChartOptions(
      startTime,
      endTime,
      10,
      this.props.lollipopClickEvent
    );

    const { chartSeries, chartAnnotations, pointAnnotations, seriesCount } = this.setChartSeries(
      endTime,
      this.props.fdeFaults
    );

    this.state = {
      chartWidth: "100%",
      startTime: startTime,
      endTime: endTime,
      series: chartSeries,
      seriesCount: seriesCount,
      options: chartOptions,
      annotations: chartAnnotations,
      allAnnotations: chartAnnotations,
      pointAnnotations: pointAnnotations,
      toggleStateInfo: "on",
      toggleStateStatus: "on",
      toggleStateReduceCommon: "off",
      cmsSystemFaults: this.createCMSFilterObjects(),
      hideCMSLegendModal: true,
    };

    this.errorColorDefault = "#ff5050";
    this.errorDescriptionDefault =
      "Unable to render flight data in chart due to missing data.";
  }

  componentDidMount() {
    if (this.props.engineStartTime !== null) {
      this.addChartAnnotations();
      this.addChartPointAnnotations();
      if (this.props.tab !== "cms") {
        ApexCharts.exec("flight_lollipop", "hideSeries", "RCOMMON_WARNING");
        ApexCharts.exec("flight_lollipop", "hideSeries", "RCOMMON_CAUTION");
        ApexCharts.exec("flight_lollipop", "hideSeries", "RCOMMON_ADVISORY");
        ApexCharts.exec("flight_lollipop", "hideSeries", "RCOMMON_INFO");
        ApexCharts.exec("flight_lollipop", "hideSeries", "RCOMMON_STATUS");
      }
    }
  }

  setStartEndTimestamp() {
    try {
      const latestTimestamps = [];
      // Add engine start and end times
      if (this.props.engineStartTime) {
        latestTimestamps.push({
          timestamp: moment.utc(this.props.engineStartTime).valueOf(),
        });
        latestTimestamps.push({
          timestamp: moment.utc(this.props.engineEndTime).valueOf(),
        });
      }
      // Add flight times
      if (this.props.flights.length > 0) {
        this.props.flights.forEach((flight) => {
          latestTimestamps.push({
            timestamp: moment.utc(flight.departureTime).valueOf(),
          });
          latestTimestamps.push({
            timestamp: moment.utc(flight.arrivalTime).valueOf(),
          });
        });
      }
      // Add FDE times
      if (this.props.fdeFaults.length > 0) {
        this.props.fdeFaults.forEach((fdeFault) => {
          latestTimestamps.push({
            timestamp: moment.utc(fdeFault.faultTimestamp).valueOf(),
          });
        });
      }
      // Add CMS Times
      if (this.props.cmsFaults.length > 0) {
        this.props.cmsFaults.forEach((cmsFault) => {
          latestTimestamps.push({
            timestamp: moment.utc(cmsFault.faultTimestamp).valueOf(),
          });
        });
      }
      // Add CMS session times
      if (this.props.tab === "cms") {
        if (this.props.cmsSessionsList.length > 0) {
          this.props.cmsSessionsList.forEach((cmsSession) => {
            latestTimestamps.push({
              timestamp: moment.utc(cmsSession.cmsSessionStartTime).valueOf(),
            });
            latestTimestamps.push({
              timestamp: moment.utc(cmsSession.cmsSessionEndTime).valueOf(),
            });
          });
        }
      }

      let startTime, endTime;
      if (latestTimestamps.length > 0) {
        startTime = moment
          .utc(
            _.orderBy(
              _.filter(latestTimestamps, "timestamp"),
              ["timestamp"],
              ["asc"]
            )[0].timestamp
          )
          .valueOf();
        endTime = moment
          .utc(
            _.orderBy(
              _.filter(latestTimestamps, "timestamp"),
              ["timestamp"],
              ["desc"]
            )[0].timestamp
          )
          .valueOf();
        const chartTimeBuffers = 30000;
        startTime -= chartTimeBuffers;
        endTime += chartTimeBuffers;
      }

      return {
        startTime: startTime,
        endTime: endTime,
      };
    } catch (error) {
      console.error(error);
      return {
        startTime: null,
        endTime: null,
      };
    }
  }

  getLolliPopColorArray() {
    let colors;
    if (this.props.tab === "cms") {
      colors = [
        "#303030",
        "#009688",
        "#404040",
        "#00ffa3" /* "Cabin System" - In Flight Entertainment */,
        "#5087ff" /* "Indicating/Recording System" - Camera System */,
        "#8500ed" /* "Communications" - Communications */,
        "#fffb02" /* "Lights" - Cabin Lighting */,
        "#005c79" /* "Electrical Power" - Cabin Power Outlet System */,
        "#1c18d6" /* "Water/Waste System" - Water & Waste */,
        "#b05ebe" /* "Windows" - Window Shades */,
        "#f37619" /* "Equipment/Furnishing" - Galley Appliances */,
      ];
    } else {
      colors = [
        "#303030",
        "#009688",
        "#404040",
        "#FFFFFF", // Status
        "#FFFFFF", // Status
        "#808080", // Info
        "#808080", // Info
        "#709DFF", // Advisory
        "#709DFF", // Advisory
        "#FFC72C", // Caution
        "#FFC72C", // Caution
        "#FF5050", // Warning
        "#FF5050", // Warning
      ];
    }
    return colors;
  }

  getLolliPopStrokeColorArray() {
    let colors;

    if (this.props.tab === "cms") {
      colors = [
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
      ];
    } else {
      colors = [
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#808080", // Status
        "#808080", // Status
        "#FFFFFF", // Info
        "#FFFFFF", // Info
        "#FFFFFF", // Advisory
        "#FFFFFF", // Advisory
        "#FFFFFF", // Caution
        "#FFFFFF", // Caution
        "#FFFFFF", // Warning
        "#FFFFFF", // Warning
      ];
    }

    return colors;
  }

  getChartOptions(startTime, endTime, numberOfTicks, chartClickEvent) {
    const chartOptions = getLollipopChartOptions(
      startTime,
      endTime,
      numberOfTicks,
      chartClickEvent,
      this.getLolliPopColorArray(),
      this.getLolliPopStrokeColorArray(),
      this.props.aircraftUIConfig.aircraftViewPage.showFaultCodeInTooltip
    );
    return chartOptions;
  }

  setChartSeries(chartEndDate, fdeFaults) {
    const seriesObjects = [];
    const annotationValues = [];
    const pointAnnotationValues = [];

    const engineSeries = { name: "ENGINE", data: [] };
    const cmsSessionSeries = { name: "CMS_SESSION", data: [] };
    const flightSeries = { name: "FLIGHT", data: [] };
    const warningSeries = { name: "WARNING", data: [] };
    const cautionSeries = { name: "CAUTION", data: [] };
    const advisorySeries = { name: "ADVISORY", data: [] };
    const infoSeries = { name: "INFO", data: [] };
    const statusSeries = { name: "STATUS", data: [] };
    const reduceCommonWarningSeries = { name: "RCOMMON_WARNING", data: [] };
    const reduceCommonCautionSeries = { name: "RCOMMON_CAUTION", data: [] };
    const reduceCommonAdvisorySeries = { name: "RCOMMON_ADVISORY", data: [] };
    const reduceCommonInfoSeries = { name: "RCOMMON_INFO", data: [] };
    const reduceCommonStatusSeries = { name: "RCOMMON_STATUS", data: [] };

    const inFlightEntertainment = { name: "In Flight Entertainment", data: [] };
    const cameraSystem = { name: "Camera System", data: [] };
    const communications = { name: "Communications", data: [] };
    const lightingSystems = { name: "Cabin Lighting", data: [] };
    const dfcu = { name: "Cabin Power Outlet System", data: [] };
    const waterWaste = { name: "Water & Waste", data: [] };
    const windowShades = { name: "Window Shades", data: [] };
    const galleyAppliances = { name: "Galley Appliances", data: [] };

    const maxL3Height = 6.2;
    const maxL2Height = 4;
    const maxL1Height = 3.6;
    const heightInc = -0.37;
    const maxHeightAnnotation = maxL3Height;

    let previousDateL3 = moment.utc(chartEndDate).valueOf() + 1;
    let previousDateL1 = moment.utc(chartEndDate).valueOf() + 1;
    let heightL3 = maxL3Height;
    let heightL1 = maxL1Height;

    // Push ENGINE series
    if (this.props.engineStartTime) {
      engineSeries.data.push([
        moment.utc(this.props.engineStartTime).valueOf(),
        0,
        { faultCode: "ENGINE ON", faultMessage: "" },
      ]);
      pointAnnotationValues.push({
        id: "ENGINE-ON",
        series: "FLIGHT",
        timestamp: moment.utc(this.props.engineStartTime).valueOf(),
        yaxis: maxL2Height,
        reduceCommonStatus: false,
        annotationObject: this.createAnnotationPoint(
          "ENGINE-ON",
          moment.utc(this.props.engineStartTime).valueOf(),
        ),
      });
    }
    if (this.props.engineEndTime) {
      engineSeries.data.push([
        moment.utc(this.props.engineEndTime).valueOf(),
        0,
        { faultCode: "ENGINE OFF", faultMessage: "" },
      ]);
      pointAnnotationValues.push({
        id: "ENGINE-OFF",
        series: "FLIGHT",
        timestamp: moment.utc(this.props.engineEndTime).valueOf(),
        yaxis: maxL2Height,
        reduceCommonStatus: false,
        annotationObject: this.createAnnotationPoint(
          "ENGINE-OFF",
          moment.utc(this.props.engineEndTime).valueOf(),
        ),
      });
    }

    // Push CMS Session Annotations
    _.orderBy(
      this.props.cmsSessionsList,
      ["cmsSessionStartTime"],
      ["asc"]
    ).forEach((cmsSession) => {
      let label = "";

      //Adding cms session block
      if (cmsSession.cmsSessionStartTime && cmsSession.cmsSessionEndTime) {
        cmsSessionSeries.data.push([
          moment.utc(cmsSession.cmsSessionStartTime).valueOf(),
          0,
          { faultCode: "CMS ON", faultMessage: "" },
        ]);

        cmsSessionSeries.data.push([
          moment.utc(cmsSession.cmsSessionEndTime).valueOf(),
          0,
          { faultCode: "CMS OFF", faultMessage: "" },
        ]);

        annotationValues.push({
          reduceCommonStatus: false,
          annotationObject: this.createAnnotationBlock(
            "cms",
            cmsSession.cmsSessionStartTime,
            cmsSession.cmsSessionEndTime,
            label
          ),
        });
      }
    });

    // Push FLIGHT DEPARTURE/ARRIVAL series
    _.orderBy(this.props.flights, ["departureTime"], ["asc"]).forEach(
      (flight, index) => {
        const annotationRandomNumber = Math.floor(Math.random() * 10000);

        // DEPARTURE FLIGHT
        if (flight.departureTime) {
          flightSeries.data.push([
            moment.utc(flight.departureTime).valueOf(),
            maxL2Height,
            { faultCode: "TAKEOFF", faultMessage: "" },
          ]);
          annotationValues.push({
            id: "FLIGHT-DEPARTURE-" + annotationRandomNumber,
            series: "FLIGHT",
            timestamp: moment.utc(flight.departureTime).valueOf(),
            yaxis: maxL2Height,
            reduceCommonStatus: false,
            annotationObject: this.createAnnotationLollipopStick(
              "FLIGHT-DEPARTURE-" + annotationRandomNumber,
              maxL2Height,
              moment.utc(flight.departureTime).valueOf(),
              maxHeightAnnotation
            ),
          });
        }
        // ARRIVAL FLIGHT
        if (flight.arrivalTime) {
          flightSeries.data.push([
            moment.utc(flight.arrivalTime).valueOf(),
            maxL2Height,
            { faultCode: "LANDING", faultMessage: "" },
          ]);
          annotationValues.push({
            id: "FLIGHT-ARRIVAL-" + annotationRandomNumber,
            series: "FLIGHT",
            timestamp: moment.utc(flight.arrivalTime).valueOf(),
            yaxis: maxL2Height,
            reduceCommonStatus: false,
            annotationObject: this.createAnnotationLollipopStick(
              "FLIGHT-ARRIVAL-" + annotationRandomNumber,
              maxL2Height,
              moment.utc(flight.arrivalTime).valueOf(),
              maxHeightAnnotation
            ),
          });
        }

        let label = "";
        if (
          flight.departureAirportIATA === "SENSITIVE" ||
          flight.arrivalAirportIATA === "SENSITIVE"
        ) {
          label = "FLIGHT";
        } else if (
          flight.departureAirportIATA === null &&
          flight.arrivalAirportIATA === null
        ) {
          label = "FLIGHT";
        } else {
          label =
            (flight.departureAirportIATA !== null
              ? flight.departureAirportIATA
              : "") +
            " - " +
            (flight.arrivalAirportIATA !== null
              ? flight.arrivalAirportIATA
              : "");
        }

        const flightIndex = 1 + index;

        // Adding flight block
        if (flight.arrivalTime && flight.departureTime)
          annotationValues.push({
            reduceCommonStatus: false,
            annotationObject: this.createAnnotationBlock(
              "flight",
              flight.departureTime,
              flight.arrivalTime,
              label,
              flightIndex
            ),
          });
      }
    );

    //adding cms fault lollipops
    _.orderBy(this.props.cmsFaults, ["faultTimestamp"], ["desc"]).forEach(
      (fault) => {
        if (fault.faultSeverity !== "F" && fault.faultSeverity !== "Z") {
          const annotationId =
            "CMS-" + Math.floor(Math.random() * 10000).toString();

          const faultTimestamp = moment.utc(fault.faultTimestamp).valueOf();

          if (faultTimestamp === previousDateL3) {
            heightL3 += heightInc;
          } else {
            heightL3 = maxL3Height;
          }
          previousDateL3 = faultTimestamp;

          let systemTranslation;

          switch (fault.faultSystem) {
            case "Cabin System":
              inFlightEntertainment.data.push([
                faultTimestamp,
                heightL3,
                {
                  faultCode: fault.faultCode,
                  faultMessage: fault.faultMessage,
                },
              ]);
              systemTranslation = "In Flight Entertainment";
              break;
            case "Indicating/Recording System":
              cameraSystem.data.push([
                faultTimestamp,
                heightL3,
                {
                  faultCode: fault.faultCode,
                  faultMessage: fault.faultMessage,
                },
              ]);
              systemTranslation = "Camera System";
              break;
            case "Communications":
              communications.data.push([
                faultTimestamp,
                heightL3,
                {
                  faultCode: fault.faultCode,
                  faultMessage: fault.faultMessage,
                },
              ]);
              systemTranslation = "Communications";
              break;
            case "Lights":
              lightingSystems.data.push([
                faultTimestamp,
                heightL3,
                {
                  faultCode: fault.faultCode,
                  faultMessage: fault.faultMessage,
                },
              ]);
              systemTranslation = "Cabin Lighting";
              break;
            case "Electrical Power":
              dfcu.data.push([
                faultTimestamp,
                heightL3,
                {
                  faultCode: fault.faultCode,
                  faultMessage: fault.faultMessage,
                },
              ]);
              systemTranslation = "Cabin Power Outlet System";
              break;
            case "Water/Waste System":
              waterWaste.data.push([
                faultTimestamp,
                heightL3,
                {
                  faultCode: fault.faultCode,
                  faultMessage: fault.faultMessage,
                },
              ]);
              systemTranslation = "Water & Waste";
              break;
            case "Windows":
              windowShades.data.push([
                faultTimestamp,
                heightL3,
                {
                  faultCode: fault.faultCode,
                  faultMessage: fault.faultMessage,
                },
              ]);
              systemTranslation = "Window Shades";
              break;
            case "Equipment/Furnishing":
              galleyAppliances.data.push([
                faultTimestamp,
                heightL3,
                {
                  faultCode: fault.faultCode,
                  faultMessage: fault.faultMessage,
                },
              ]);
              systemTranslation = "Galley Appliances";
              break;
            default:
              break;
          }

          annotationValues.push({
            id: annotationId,
            series: systemTranslation,
            timestamp: faultTimestamp,
            yaxis: heightL3,
            reduceCommonStatus: false,
            annotationObject: this.createAnnotationLollipopStick(
              annotationId,
              heightL3,
              faultTimestamp,
              maxHeightAnnotation
            ),
          });
        }
      }
    );

    // Push FAULT series
    _.orderBy(
      fdeFaults,
      ["faultTimestamp", "faultSeverity"],
      ["desc", "desc"]
    ).forEach((fault) => {
      const annotationId =
        fault.faultSeverity +
        "-" +
        fault.faultCode.replace(/\s/g, "") +
        "-" +
        Math.floor(Math.random() * 10000);
      const faultTimestamp = moment.utc(fault.faultTimestamp).valueOf();
      let series;

      // If NA fault code, display empty string
      const faultCode = fault.faultCode !== "NA" ? fault.faultCode : "";

      if (
        fault.faultSeverity === "W" ||
        fault.faultSeverity === "C" ||
        fault.faultSeverity === "A"
      ) {
        // Level 3 faults: WARNING, CAUTION, ADVISORY
        if (faultTimestamp === previousDateL3) {
          heightL3 += heightInc;
        } else {
          heightL3 = maxL3Height;
        }
        previousDateL3 = faultTimestamp;

        if (fault.faultSeverity === "W") {
          if (fault.reduceCommonFaultStatus) {
            reduceCommonWarningSeries.data.push([
              faultTimestamp,
              heightL3,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "RCOMMON";
          } else {
            warningSeries.data.push([
              faultTimestamp,
              heightL3,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "WARNING";
          }
        } else if (fault.faultSeverity === "C") {
          if (fault.reduceCommonFaultStatus) {
            reduceCommonCautionSeries.data.push([
              faultTimestamp,
              heightL3,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "RCOMMON";
          } else {
            cautionSeries.data.push([
              faultTimestamp,
              heightL3,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "CAUTION";
          }
        } else if (fault.faultSeverity === "A") {
          if (fault.reduceCommonFaultStatus) {
            reduceCommonAdvisorySeries.data.push([
              faultTimestamp,
              heightL3,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "RCOMMON";
          } else {
            advisorySeries.data.push([
              faultTimestamp,
              heightL3,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "ADVISORY";
          }
        }

        if (typeof series !== 'undefined') {
          annotationValues.push({
            id: annotationId,
            series: series,
            timestamp: faultTimestamp,
            yaxis: heightL3,
            reduceCommonStatus: fault.reduceCommonFaultStatus,
            annotationObject: this.createAnnotationLollipopStick(
              annotationId,
              heightL3,
              faultTimestamp,
              maxHeightAnnotation
            ),
          });
        }
      } else {
        // Level 1 faults: INFORMATION MESSAGES, STATUS MESSAGES
        if (faultTimestamp === previousDateL1) {
          heightL1 += heightInc;
        } else {
          heightL1 = maxL1Height;
        }
        previousDateL1 = faultTimestamp;

        if (fault.faultSeverity === "N") {
          if (fault.reduceCommonFaultStatus) {
            reduceCommonInfoSeries.data.push([
              faultTimestamp,
              heightL1,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "RCOMMON";
          } else {
            infoSeries.data.push([
              faultTimestamp,
              heightL1,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "INFO";
          }
        } else if (fault.faultSeverity === "S") {
          if (fault.reduceCommonFaultStatus) {
            reduceCommonStatusSeries.data.push([
              faultTimestamp,
              heightL1,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "RCOMMON";
          } else {
            statusSeries.data.push([
              faultTimestamp,
              heightL1,
              {
                faultCode: faultCode,
                faultMessage: fault.faultMessage,
              },
            ]);
            series = "STATUS";
          }
        }

        if (typeof series !== 'undefined') {
          annotationValues.push({
            id: annotationId,
            series: series,
            timestamp: faultTimestamp,
            yaxis: heightL1,
            reduceCommonStatus: fault.reduceCommonFaultStatus,
            annotationObject: this.createAnnotationLollipopStick(
              annotationId,
              heightL1,
              faultTimestamp,
              maxHeightAnnotation
            ),
          });
        }
      }
    });

    const tempSeriesCount = [
      { name: "RCOMMON_WARNING", count: reduceCommonWarningSeries.data.length },
      { name: "RCOMMON_CAUTION", count: reduceCommonCautionSeries.data.length },
      {
        name: "RCOMMON_ADVISORY",
        count: reduceCommonAdvisorySeries.data.length,
      },
      { name: "RCOMMON_INFO", count: reduceCommonInfoSeries.data.length },
      { name: "RCOMMON_STATUS", count: reduceCommonStatusSeries.data.length },
    ];

    // TODO: NEED TO FIND A SOLUTION FROM APEXCHART TEAM
    if (engineSeries.data.length === 1) {
      engineSeries.data.push(engineSeries.data[0]);
    }
    /*if (cmsSessionSeries.data.length === 1) {
      cmsSessionSeries.data.push(cmsSessionSeries.data[0]);
    }*/
    if (flightSeries.data.length === 1) {
      flightSeries.data.push(flightSeries.data[0]);
    }
    if (warningSeries.data.length === 1) {
      warningSeries.data.push(warningSeries.data[0]);
    }
    if (cautionSeries.data.length === 1) {
      cautionSeries.data.push(cautionSeries.data[0]);
    }
    if (advisorySeries.data.length === 1) {
      advisorySeries.data.push(advisorySeries.data[0]);
    }
    if (infoSeries.data.length === 1) {
      infoSeries.data.push(infoSeries.data[0]);
    }
    if (statusSeries.data.length === 1) {
      statusSeries.data.push(statusSeries.data[0]);
    }
    if (reduceCommonWarningSeries.data.length === 1) {
      reduceCommonWarningSeries.data.push(reduceCommonWarningSeries.data[0]);
    }
    if (reduceCommonCautionSeries.data.length === 1) {
      reduceCommonCautionSeries.data.push(reduceCommonCautionSeries.data[0]);
    }
    if (reduceCommonAdvisorySeries.data.length === 1) {
      reduceCommonAdvisorySeries.data.push(reduceCommonAdvisorySeries.data[0]);
    }
    if (reduceCommonInfoSeries.data.length === 1) {
      reduceCommonInfoSeries.data.push(reduceCommonInfoSeries.data[0]);
    }
    if (reduceCommonStatusSeries.data.length === 1) {
      reduceCommonStatusSeries.data.push(reduceCommonStatusSeries.data[0]);
    }
    /* CMS */
    if (inFlightEntertainment.data.length === 1) {
      inFlightEntertainment.data.push(inFlightEntertainment.data[0]);
    }
    if (cameraSystem.data.length === 1) {
      cameraSystem.data.push(cameraSystem.data[0]);
    }
    if (communications.data.length === 1) {
      communications.data.push(communications.data[0]);
    }
    if (lightingSystems.data.length === 1) {
      lightingSystems.data.push(lightingSystems.data[0]);
    }
    if (dfcu.data.length === 1) {
      dfcu.data.push(dfcu.data[0]);
    }
    if (waterWaste.data.length === 1) {
      waterWaste.data.push(waterWaste.data[0]);
    }
    if (windowShades.data.length === 1) {
      windowShades.data.push(windowShades.data[0]);
    }
    if (galleyAppliances.data.length === 1) {
      galleyAppliances.data.push(galleyAppliances.data[0]);
    }

    seriesObjects.push(engineSeries);
    seriesObjects.push(cmsSessionSeries);
    seriesObjects.push(flightSeries);
    if (this.props.tab === "cms") {
      seriesObjects.push(inFlightEntertainment);
      seriesObjects.push(cameraSystem);
      seriesObjects.push(communications);
      seriesObjects.push(lightingSystems);
      seriesObjects.push(dfcu);
      seriesObjects.push(waterWaste);
      seriesObjects.push(windowShades);
      seriesObjects.push(galleyAppliances);
    } else {
      seriesObjects.push(statusSeries);
      seriesObjects.push(reduceCommonStatusSeries);
      seriesObjects.push(infoSeries);
      seriesObjects.push(reduceCommonInfoSeries);
      seriesObjects.push(advisorySeries);
      seriesObjects.push(reduceCommonAdvisorySeries);
      seriesObjects.push(cautionSeries);
      seriesObjects.push(reduceCommonCautionSeries);
      seriesObjects.push(warningSeries);
      seriesObjects.push(reduceCommonWarningSeries);
    }

    return {
      chartSeries: seriesObjects,
      chartAnnotations: annotationValues,
      pointAnnotations: pointAnnotationValues,
      seriesCount: tempSeriesCount,
    };
  }

  createAnnotationBlock(type, startTime, endTime, label, index) {
    // Giving Default Background Color
    let backgroundColor = "#303030";
    let offSetY = 275;

    if (type === "cms") {
      backgroundColor = "#009688";
      offSetY = 320;
    }

    if ("Flight " + index === this.props.selectedSessionFlight?.label) {
      backgroundColor = "#808080";
    }

    const annotationObject = {
      id: "Flight" + index,
      x: moment.utc(startTime).valueOf(),
      x2: moment.utc(endTime).valueOf(),
      opacity: 0.3,
      offsetY: offSetY,
      fillColor: backgroundColor,
      label: {
        borderColor: "#ffffff",
        borderWidth: 1,
        position: "bottom",
        text: label,
        offsetY: 0,
        style: {
          background: backgroundColor,
          color: "#FFFFFF",
          height: "200px !important",
          fontSize: "13px",
          fontWeight: "500",
        },
      },
    };

    return annotationObject;
  }

  createAnnotationPoint(
    annotationValuesId,
    annotationTimestamp,
  ) {
    if (annotationValuesId === "ENGINE-ON" || annotationValuesId === "ENGINE-OFF") {
      const annotationObject = {
        id: annotationValuesId,
        x: annotationTimestamp,
        y: 0,
        marker: {
          size: 0
        },
        image: {
          path: annotationValuesId === "ENGINE-ON" ? EngineOn : EngineOff,
          width: 12,
          height: 12,
        }
      };
      return annotationObject;
    }
  }

  createAnnotationLollipopStick(
    annotationValuesId,
    annotationYAxis,
    annotationTimestamp,
    maxYAxis
  ) {
    const annotationObject = {
      id: annotationValuesId,
      borderColor: "#FFFFFF",
      borderWidth: 1,
      fillColor: "#FFFFFF",
      label: {
        borderColor: annotationYAxis >= maxYAxis ? "#808080" : "transparent",
        borderWidth: 1,
        borderRadius: 1,
        text:
          annotationYAxis >= maxYAxis
            ? moment.utc(annotationTimestamp).format("HH:mm:ss")
            : "",
        textAnchor: "middle",
        offsetX: 0,
        offsetY: 10,
        orientation: "horizontal",
        position: "top",
        style: {
          background: "#202020",
          color: "#FFFFFF",
          fontSize: "13px",
          fontWeight: undefined,
          fontFamily: "DM Sans",
          cssClass: "",
          padding: {
            left: 4,
            right: 5,
            top: 2,
            bottom: 3,
          },
        },
      },
      offsetX: 0,
      offsetY:
        Math.floor(
          (maxYAxis + 1 - annotationYAxis) *
          (window.innerHeight <= 800
            ? 44
            : window.innerHeight <= 950
              ? 53
              : 55)
        ) + "px",
      opacity: 0.3,
      strokeDashArray: 0,
      x: annotationTimestamp,
      x2: null,
    };

    return annotationObject;
  }

  addChartAnnotations() {
    this.state.annotations.forEach((annotation) => {
      if (annotation.reduceCommonStatus === false) {
        ApexCharts.exec(
          "flight_lollipop",
          "addXaxisAnnotation",
          annotation.annotationObject
        );
      }
    });
  }

  addChartPointAnnotations() {
    if (this.state.pointAnnotations.length > 0) {
      this.state.pointAnnotations.forEach((pointAnnotation) => {
        if (pointAnnotation.reduceCommonStatus === false) {
          ApexCharts.exec(
            "flight_lollipop",
            "addPointAnnotation",
            pointAnnotation.annotationObject
          );
        }
      });
    }
  }

  toggleMessages(series, state, index) {
    if (state === "cms") {
      if (this.state.cmsSystemFaults[index].active) {
        state = "on";
        this.state.cmsSystemFaults[index].active = false;
      } else {
        state = "off";
        this.state.cmsSystemFaults[index].active = true;
      }

      this.setState({
        cmsSystemFaults: this.state.cmsSystemFaults,
      });
    }

    if (state === "on") {
      state = "off";
      _.filter(this.state.annotations, ["series", series]).forEach(
        (annotation) => {
          ApexCharts.exec("flight_lollipop", "removeAnnotation", annotation.id);
        }
      );
    } else {
      state = "on";
      _.filter(this.state.annotations, ["series", series]).forEach(
        (annotation) => {
          ApexCharts.exec(
            "flight_lollipop",
            "addXaxisAnnotation",
            annotation.annotationObject
          );
        }
      );
    }

    // Update series toggle state
    if (series === "INFO") {
      this.setState(
        {
          toggleStateInfo: state,
        },
        () => {
          ApexCharts.exec("flight_lollipop", "toggleSeries", series);
          this.props.seriesToggleEvent(
            series,
            this.state.toggleStateInfo === "on" ? true : false
          );
        }
      );
    } else if (series === "STATUS") {
      this.setState(
        {
          toggleStateStatus: state,
        },
        () => {
          ApexCharts.exec("flight_lollipop", "toggleSeries", series);
          this.props.seriesToggleEvent(
            series,
            this.state.toggleStateStatus === "on" ? true : false
          );
        }
      );
    } else if (series === "RCOMMON") {
      this.setState(
        {
          toggleStateReduceCommon: state,
        },
        () => {
          if (this.state.toggleStateReduceCommon === "on") {
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_WARNING"])
                .count > 0
            ) {
              ApexCharts.exec(
                "flight_lollipop",
                "showSeries",
                "RCOMMON_WARNING"
              );
            }
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_CAUTION"])
                .count > 0
            ) {
              ApexCharts.exec(
                "flight_lollipop",
                "showSeries",
                "RCOMMON_CAUTION"
              );
            }
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_ADVISORY"])
                .count > 0
            ) {
              ApexCharts.exec(
                "flight_lollipop",
                "showSeries",
                "RCOMMON_ADVISORY"
              );
            }
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_INFO"]).count > 0
            ) {
              ApexCharts.exec("flight_lollipop", "showSeries", "RCOMMON_INFO");
            }
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_STATUS"]).count >
              0
            ) {
              ApexCharts.exec(
                "flight_lollipop",
                "showSeries",
                "RCOMMON_STATUS"
              );
            }
          } else {
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_WARNING"])
                .count > 0
            ) {
              ApexCharts.exec(
                "flight_lollipop",
                "hideSeries",
                "RCOMMON_WARNING"
              );
            }
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_CAUTION"])
                .count > 0
            ) {
              ApexCharts.exec(
                "flight_lollipop",
                "hideSeries",
                "RCOMMON_CAUTION"
              );
            }
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_ADVISORY"])
                .count > 0
            ) {
              ApexCharts.exec(
                "flight_lollipop",
                "hideSeries",
                "RCOMMON_ADVISORY"
              );
            }
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_INFO"]).count > 0
            ) {
              ApexCharts.exec("flight_lollipop", "hideSeries", "RCOMMON_INFO");
            }
            if (
              _.find(this.state.seriesCount, ["name", "RCOMMON_STATUS"]).count >
              0
            ) {
              ApexCharts.exec(
                "flight_lollipop",
                "hideSeries",
                "RCOMMON_STATUS"
              );
            }
          }
          this.props.seriesToggleEvent(
            series,
            this.state.toggleStateReduceCommon === "on" ? true : false
          );
        }
      );
    } else {
      ApexCharts.exec("flight_lollipop", "toggleSeries", series);
    }
  }

  getFdeFilters() {
    let fdeToggles = (
      <div className="lollipopChart__toggle__section">
        <div
          className={
            "lollipopChart__toggle__item-" + this.state.toggleStateInfo
          }
          onClick={() =>
            this.toggleMessages("INFO", this.state.toggleStateInfo)
          }
        >
          <div>Info messages</div>
          <CircleToggle
            circleColor="grey"
            opacityToggler={this.state.toggleStateInfo}
          />
        </div>
        <div
          className={
            "lollipopChart__toggle__item-" + this.state.toggleStateStatus
          }
          onClick={() =>
            this.toggleMessages("STATUS", this.state.toggleStateStatus)
          }
        >
          <div>Status</div>
          <CircleToggle
            circleColor="white"
            opacityToggler={this.state.toggleStateStatus}
          />
        </div>
        <div
          className={
            "lollipopChart__toggle__item-" + this.state.toggleStateReduceCommon
          }
          onClick={() =>
            this.toggleMessages("RCOMMON", this.state.toggleStateReduceCommon)
          }
        >
          <div>
            {"Filtered FDE (" +
              (
                _.find(this.state.seriesCount, ["name", "RCOMMON_WARNING"])
                  .count +
                _.find(this.state.seriesCount, ["name", "RCOMMON_CAUTION"])
                  .count +
                _.find(this.state.seriesCount, ["name", "RCOMMON_ADVISORY"])
                  .count +
                _.find(this.state.seriesCount, ["name", "RCOMMON_INFO"]).count +
                _.find(this.state.seriesCount, ["name", "RCOMMON_STATUS"]).count
              ).toString() +
              ")"}
          </div>
          <CircleToggle
            circleColor="black"
            opacityToggler={this.state.toggleStateReduceCommon}
          />
        </div>
      </div>
    );

    return fdeToggles;
  }

  getCMSSystemResources(system) {
    let systemResources = {
      visibleLabel: "",
      image: "",
      color: "",
    };
    switch (system) {
      case "Cabin System":
        systemResources = {
          visibleLabel: "In Flight Entertainment",
          image: "monitor",
          color: "light-green",
        };
        break;
      case "Indicating/Recording System":
        systemResources = {
          visibleLabel: "Camera System",
          image: "video-camera",
          color: "light-blue",
        };
        break;
      case "Communications":
        systemResources = {
          visibleLabel: "Communications",
          image: "volume-2",
          color: "purple",
        };
        break;
      case "Lights":
        systemResources = {
          visibleLabel: "Cabin Lighting",
          image: "sun",
          color: "yellow",
        };
        break;
      case "Electrical Power":
        systemResources = {
          visibleLabel: "Cabin Power Outlet System",
          image: "power",
          color: "green",
        };
        break;
      case "Water/Waste System":
        systemResources = {
          visibleLabel: "Water & Waste",
          image: "droplet",
          color: "blue",
        };
        break;
      case "Windows":
        systemResources = {
          visibleLabel: "Window Shades",
          image: "window-shade",
          color: "pink",
        };
        break;
      case "Equipment/Furnishing":
        systemResources = {
          visibleLabel: "Galley Appliances",
          image: "meal",
          color: "orange",
        };
        break;
      default:
        systemResources = {
          visibleLabel: "Arrival / Departure",
          image: "plane",
          color: "dark-grey",
        };
        break;
    }
    return systemResources;
  }

  createCMSFilterObjects() {
    let cmsGroups = _.groupBy(this.props.cmsFaults, "faultSystem");
    let cmsGroupKeys = _.keys(cmsGroups);

    let cmsSystemArray = [];
    cmsGroupKeys.forEach((key, index) => {
      let resources = this.getCMSSystemResources(key);
      let cmsObject = {
        systemName: key,
        visibleLabel: resources.visibleLabel,
        image: resources.image,
        count: cmsGroups[key].length,
        active: true,
        color: resources.color,
      };
      cmsSystemArray.push(cmsObject);
    });

    return cmsSystemArray;
  }

  async toggleCMSLegendModal() {
    this.setState((prevState) => ({
      hideCMSLegendModal: !prevState.hideCMSLegendModal,
    }));

    /*if (this.state.hideCMSLegendModal) {
      document.addEventListener("click", this.handleOutsideLegendClick, false);
    } else {
      document.removeEventListener(
        "click",
        this.handleOutsideLegendClick,
        false
      );
    }*/
  }

  handleOutsideLegendClick = (e) => {
    this.toggleCMSLegendModal();
  };

  getCmsLegend() {
    const legendItems = [
      {
        visibleLabel: "Arrival / Departure",
        image: "plane",
        color: "dark-grey",
      },
      {
        visibleLabel: "In Flight Entertainment",
        image: "monitor",
        color: "light-green",
      },
      {
        visibleLabel: "Camera System",
        image: "video-camera",
        color: "light-blue",
      },
      {
        visibleLabel: "Communications",
        image: "volume-2",
        color: "purple",
      },
      {
        visibleLabel: "Cabin Lighting",
        image: "sun",
        color: "yellow",
      },
      {
        visibleLabel: "Cabin Power Outlet System",
        image: "power",
        color: "green",
      },
      {
        visibleLabel: "Water & Waste",
        image: "droplet",
        color: "blue",
      },
      {
        visibleLabel: "Window Shades",
        image: "window-shade",
        color: "pink",
      },
      {
        visibleLabel: "Galley Appliances",
        image: "meal",
        color: "orange",
      },
    ];
    let cmsLegend = (
      <div>
        <div className={"lollipopChart__legend_expanded_cms"}>
          <FeatherIcon icon="book-open" width={15} height={15} />
          <span className="lollipopChart__legend__dropdown_cms">Legend</span>
          <FeatherIcon icon="chevron-up" width={15} height={15} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            fontSize: "16px",
            fontWeight: "700",
            marginBottom: "6px",
            color: "white",
          }}
        // className={".lollipopChart__legend__container__cms"}
        >
          CMS parameters
        </div>
        {legendItems.map((cmsItem, index) => (
          <React.Fragment key={`cms-legend-item-${index}`}>
            {cmsItem.systemName !== "undefined" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "35px",
                  fontSize: "12px",
                  marginBottom: "5px",
                  fontWeight: "500",
                  color: "white",
                }}
                className={".lollipopChart__legend__row_cms"}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "5px",
                  }}
                >
                  <img
                    src={require(`../../public/resources/cms_icons/${cmsItem.image}.svg`)}
                    style={{ height: 20, width: 20 }}
                    className={".lollipopChart__legend__image_icon_cms"}
                  />
                  <CircleToggle
                    circleColor={cmsItem.color}
                    opacityToggler={"on"}
                    className={"lollipopChart__legend__circle_icon_cms"}
                  />
                </div>
                <div
                  className={"lollipopChart__legend__circle_icon_cms"}
                  style={{ flexBasis: "80%" }}
                >
                  {cmsItem.visibleLabel}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
    return cmsLegend;
  }

  getCmsFilters() {
    let cmsToggles = (
      <div className="lollipopChart__toggle__section">
        <div style={{ display: "flex" }}>
          {this.state.cmsSystemFaults.map((cmsItem, index) => (
            <React.Fragment key={cmsItem.systemName}>
              {cmsItem.systemName !== "undefined" && (
                <div
                  className={
                    "lollipopChart__toggle__itemCms-" +
                    cmsItem.active.toString()
                  }
                  onClick={() => {
                    this.toggleMessages(cmsItem.visibleLabel, "cms", index);
                  }}
                >
                  <CircleToggle
                    circleColor={cmsItem.color}
                    opacityToggler={"on"}
                    style={{ marginLeft: 0 }}
                    toggle={"true"}
                  />
                  <span className="lollipopChart__toggle__counterCms">
                    {cmsItem.count}
                  </span>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <React.Fragment>
          <div
            className={"lollipopChart__toggle__legendCms"}
            style={{
              marginLeft: "auto",
              position: "relative",
              justifyContent: "flex-end",
            }}
            onClick={() => {
              this.toggleCMSLegendModal();
            }}
          >
            {this.state.hideCMSLegendModal ? (
              <div className={"lollipopChart__legend_collapsed_cms"}>
                <FeatherIcon icon="book-open" width={15} height={15} />
                <span
                  className="lollipopChart__legend__dropdown_cms"
                  height={15}
                >
                  Legend
                </span>
                <FeatherIcon icon="chevron-down" width={15} height={15} />
              </div>
            ) : (
              <div>
                <div className="lollipopChart__cms__info__modal-content">
                  {this.getCmsLegend()}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      </div>
    );

    return cmsToggles;
  }

  render() {
    return (
      <div className="lollipopChart" id={"session-lollipop-chart"}>
        <div className="lollipopChart__container">
          <div className="lollipopChart__header">
            <span className="lollipopChart__header__font">
              {"Session Data"}
            </span>
            {this.props.tab === "cms"
              ? this.getCmsFilters()
              : this.getFdeFilters()}
          </div>
          <div className="lollipopChart__body__external">
            <div
              className="lollipopChart__chart__section"
              id="lollipopChart__chart__section"
            >
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                annotations={this.state.annotations}
                type="line"
                height={"99%"}
                width={this.state.chartWidth}
              />
            </div>
            <div className="lollipopChart__footer__section">
              <div className="lollipopChart__footer__section__row">
                <div className="lollipopChart__footer__section__row__indicator">
                  <img
                    src={EngineOn}
                    className="lollipopChart__indicator__engineTimestamps"
                    alt="engine on icon"
                  ></img>
                  <span>
                    <b>{"Engine On: "}</b>
                    {this.props.approximateEngineStart ? (
                      this.props.engineStartTime ?
                        tools.formatDate(this.props.engineStartTime) + " UTC"
                        : "-"
                    ) : this.props.engineStartTime ? (
                      tools.formatDate(this.props.engineStartTime) + " UTC"
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
                <div className="lollipopChart__footer__section__row__indicator">
                  <img
                    src={EngineOff}
                    className="lollipopChart__indicator__engineTimestamps"
                    alt="engine off icon"
                  ></img>
                  <span>
                    <b>{"Engine Off: "}</b>
                    {this.props.approximateEngineStop ? (
                      this.props.engineEndTime ?
                        tools.formatDate(this.props.engineEndTime) + " UTC"
                        : "-"
                    ) : this.props.engineEndTime ? (
                      tools.formatDate(this.props.engineEndTime) + " UTC"
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
              </div>
              {this.props.tab === "cms" && (
                <div className="lollipopChart__footer__section__row">
                  <div className="lollipopChart__footer__section__row__indicator">
                    <div className="lollipopChart__indicator__cmsTimestamps"></div>
                    <span>{" CMS Activity"}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
