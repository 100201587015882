import React, { useEffect, useMemo, useState } from "react";
import { Snackbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import usePullData from "../clients/usePullData";
import Toast from "../Global Components/Toast";
import { reloadOrRedirectToPage } from "../utils/CommonTools";

/**
 * The notifications toast component.
 * It appears in the top right corner of the page.
 * @param url The API URL.
 * @param hideTimeout The time it takes to hide the toast.
 * @param pullFrequency The frequency on which it will show.
 * @param redirectPath The redirection path.
 * @param handleNotificationIndicator Handler for the redirection when the user clicks the toast.
 * @returns {Element}
 * @constructor
 */
const NotificationToast = ({
  url = "",
  hideTimeout = 7500, // autohide 5s
  pullFrequency = 300000, //300000, // every 5 mins as default value
  redirectPath,
  handleNotificationIndicator,
}) => {
  const history = useHistory();
  const [data] = usePullData(pullFrequency);
  const [openToast, setOpenToast] = useState(false);

  /**
   * Calls the API to get the notification count and update the text on the toast.
   * @type {{popup: boolean, count: number, message: string}}
   */
  const notificationsMessage = useMemo(() => {
    let count = 0;
    if (data?.meta_data?.pagination) {
      count = data.meta_data.pagination.total_count;
    }

    return {
      popup: count > 0,
      count: count,
      message: `You have ${count} unread notification(s)`,
    };
  }, [data]);

  /**
   * Handles the click action on the toast item.
   */
  const handleClick = () => {
    reloadOrRedirectToPage(redirectPath, history, window);
  };

  /**
   * Handles the close action.
   */
  const handleClose = () => {
    setOpenToast(false);
  };

  useEffect(() => {
    if (notificationsMessage.popup) {
      setOpenToast(true);
    }
    if (handleNotificationIndicator && notificationsMessage.popup) {
      handleNotificationIndicator(notificationsMessage.count);
    }
  }, [
    data,
    handleNotificationIndicator,
    notificationsMessage.popup,
    notificationsMessage.count,
  ]);

  return (
    <div className="notificationToast">
      <Snackbar
        open={openToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={hideTimeout}
        disableWindowBlurListener
        onClose={() => {
          setOpenToast(false);
        }}
      >
        <div>
          <Toast
            message={notificationsMessage.message}
            handleClick={handleClick}
            handleClose={handleClose}
          />
        </div>
      </Snackbar>
    </div>
  );
};

export default NotificationToast;
