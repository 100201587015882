import React from "react";
import PropTypes from "prop-types";
import "./ConfigOptionsRow.css";

function ConfigOptionsRow({ title, icon, isActive, onClickEvent }) {
  const handleRowClick = () => {
    if (!isActive) {
      onClickEvent();
    }
  };

  const renderConfigOptionsRow = () => {
    return (
      <div
        id={title.replace(/\s/g, '')}
        className="configOptionsRow configOptionsRow__font"
        onClick={handleRowClick}
      >
        <div
          className={
            "configOptionsRow__column__activeFlag configOptionsRow__column__active-" +
            (isActive ? "true" : "false")
          }
        ></div>
        <div className="configOptionsRow__column__content">
          <div className="configOptionsRow__column__icon">
            {icon ? icon : ""}
          </div>
          <div className={"configOptionsRow__column__title configOptionsRow__column__title-" +
            (isActive ? "true" : "false")}>{title}</div>
        </div>
      </div>
    );
  };

  return renderConfigOptionsRow();
}

export default ConfigOptionsRow;

ConfigOptionsRow.defaultProps = {
  title: "",
  isActive: true,
};

ConfigOptionsRow.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  isActive: PropTypes.bool,
};
