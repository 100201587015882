import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import '../styles/Global Components/CustomCheckbox.css'
import CheckboxIcon from '../resources//check-square.svg'

const CheckboxRoot = styled(Checkbox)({
    '&:hover': {
        backgroundColor: 'transparent'
    }
})

export const SpanIcon = styled('span')({
    borderRadius: 3,
    width: '18px',
    height: '18px',
    backgroundColor: 'transparent',
    backgroundImage: 'transparent',
    border: '1px solid #ffffff',
    '$root.Mui-focusVisible &': {
        outline: '1px auto rgba(19,124,189,.6)',
        outlineOffset: 1
    },
    'input:hover ~ &': {
        backgroundColor: '#505050'
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)'
    }
})

export const SpanIconCheckedIcon = styled(SpanIcon)({
    backgroundColor: 'transparent',
    border: 'none',
    '&:before': {
        display: 'block',
        width: '21px',
        height: '21px',
        backgroundImage: 'url(' + CheckboxIcon + ')',
        content: '""'
    },
    'input:hover ~ &': {
        backgroundColor: 'transparent'
    }
})

const CustomCheckbox = ({ isChecked, isDisabled, onChangeAction }) => {
    const [checked, setChecked] = useState(isChecked)

    const handleChange = () => {
        if (isChecked === null) {
            setChecked(!checked)
        }
        if (onChangeAction) {
            onChangeAction()
        }
    }

    useEffect(() => {
        if (isChecked !== null) {
            setChecked(isChecked)
        }
    }, [isChecked])

    return (
        <div className='checkbox'>
            <CheckboxRoot
                checked={checked}
                disabled={isDisabled}
                onChange={handleChange}
                color='primary'
                inputProps={{ 'aria-label': 'primary checkbox' }}
                //disableRipple
                icon={<SpanIcon />}
                checkedIcon={<SpanIconCheckedIcon />}
            />
        </div>
    )
}

export default CustomCheckbox

CustomCheckbox.defaultProps = {
    isChecked: null,
    isDisabled: false
}

CustomCheckbox.propTypes = {
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChangeAction: PropTypes.func
}
