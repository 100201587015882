import React, { useEffect, useState } from "react";
import "./SingleFileLoader.css";
import FeatherIcon from "feather-icons-react";
import { LinearProgress, styled, linearProgressClasses } from "@mui/material";
import JSZip from "jszip";
import {
  uploadGroundLoadingFile,
} from "../../../clients/GroundLoadingClient";
import axios from "axios";

/**
 * Displays an individual file with its progress bar.
 * @param file The file to be displayed.
 * @param handleFileChange The handle for when the file status changes.
 * @param handleRemoveFile The handle for when the user removes a file.
 * @param userId The id of the user that is logged in.
 * @param registrationId The tail selected by the user for the ground loading session.
 * @param groundLoadingId The ground loading session id.
 * @return {JSX.Element}
 * @constructor
 */
export default function SingleFileLoader({
  file,
  handleFileChange,
  handleRemoveFile,
  userId,
  registrationId,
  groundLoadingId,
  showRemoveButton,
}) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    marginTop: 2.5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#404040",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:
        file.status === "success"
          ? "#5EA128"
          : file.status === "progress"
            ? "#0B69C0"
            : file.status === "failed"
              ? "#FF5050"
              : "#404040",
    },
  }));

  const [currentFileStatus, setCurrentFileStatus] = useState("");
  const [currentStatusFontColor, setCurrentStatusFontColor] = useState(
    "#FFFFFF"
  );
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [fileZipProgress, setFileZipProgress] = useState(0);
  useEffect(() => {
    if (file.status === "failed") {
      setCurrentFileStatus("Failed!");
      setCurrentStatusFontColor("#FF5050");
    }
    if (
      file.status === "progress" &&
      file.fileData.type === "application/x-zip-compressed"
    ) {
      file.status = "failed";
      handleFileChange(file.fileData.name, "failed");
    }
    // Zips and uploads the file.
    else if (file.status === "progress" && file.process) {
      setCurrentFileStatus("Zipping...");
      const reader = new FileReader();
      const zip = new JSZip();

      reader.onload = async () => {
        const binary = reader.result;
        zip.file(file.fileData.name, binary);
        await zip
          .generateAsync(
            {
              type: "blob",
              compression: "DEFLATE",
              compressionOptions: {
                level: 5, // Compression set to 5 as a middle ground. (Max is 9)
              },
              streamFiles: true,
            },
            function updateCallback(metadata) {
              setFileZipProgress(metadata.percent);
            }
          )
          .then(async (content) => {
            // Content is the binary file to sent to the API
            file.compressedSize = content.size;
            setCurrentFileStatus("Queuing...");
            await uploadFile(file, content).then(() => { });
          });
      };
      reader.readAsArrayBuffer(file.fileData);
    }
  }, [file]);

  /**
   * Function that sends the file information to the API and then submits the
   * zipped file to an S3 bucket.
   * @param file File information.
   * @param compressedBlob Zipped file contents.
   */
  const uploadFile = async (file, compressedBlob) => {
    let status = "failed";
    let fileName = file.fileData.name;
    await uploadGroundLoadingFile(
      userId,
      registrationId,
      `${file.fileData.name}.zip`,
      file.fileData.name,
      file.compressedSize,
      file.fileData.size,
      groundLoadingId,
      null
    )
      .then(async (response) => {
        if (response.data) {
          file.id = response.data.id;
          const s3LinkData = response.data.uploadS3Link;
          await uploadFileToS3WithProgress(
            s3LinkData.url,
            compressedBlob,
            s3LinkData.fields["key"],
            s3LinkData.fields["x-amz-algorithm"],
            s3LinkData.fields["x-amz-credential"],
            s3LinkData.fields["x-amz-date"],
            s3LinkData.fields["x-amz-security-token"],
            s3LinkData.fields["policy"],
            s3LinkData.fields["x-amz-signature"]
          )
            .then((response) => {
              if (response.status === 204) {
                status = "success";
              } else {
                status = "failed";
              }
            })
            .catch((err) => {
              console.error(err);
              status = "failed";
            });
        } else {
          status = "failed";
        }
      })
      .catch((error) => {
        console.error(error);
        status = "failed";
      })
      .finally(() => {
        handleFileChange(fileName, status);
      });
  };

  const removeFile = () => {
    handleRemoveFile(file);
  };

  /**
   * Helper to format the estimated request size.
   * TODO: This function may be moved to the reusable tools component.
   * @param bytes Request size in bytes.
   * @return {string}
   */
  const formatRequestSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1000;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const uploadFileToS3WithProgress = async (
    url,
    file,
    key,
    algorithm,
    credential,
    date,
    securityToken,
    policy,
    signature
  ) => {
    let response;
    const bodyFormData = new FormData();
    bodyFormData.append("key", key);
    bodyFormData.append("x-amz-algorithm", algorithm);
    bodyFormData.append("x-amz-credential", credential);
    bodyFormData.append("x-amz-date", date);
    bodyFormData.append("x-amz-security-token", securityToken);
    bodyFormData.append("policy", policy);
    bodyFormData.append("x-amz-signature", signature);
    bodyFormData.append("file", file);

    await axios
      .post(url, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setFileUploadProgress(progress);
        },
        // In case we need to download something in the future
        onDownloadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          // console.log("Downloaded: " + progress);
        },
      })
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        throw err;
      });
    return response;
  };

  return (
    <div className="single__file__loader__container">
      <div className="single__file__loader__icon">
        <FeatherIcon
          icon={"file"}
          height={"30px"}
          width={"30px"}
          color={
            file.status === "success"
              ? "#5EA128"
              : file.status === "progress"
                ? "#0B69C0"
                : file.status === "failed"
                  ? "#FF5050"
                  : "#404040"
          }
        />
      </div>
      <div className="single__file__loader__progress">
        <div className="single__file__loader__file__data">
          <p>{file.fileData.name}</p>
          <p>
            {file.compressedSize > 0
              ? formatRequestSize(file.compressedSize)
              : formatRequestSize(file.fileData.size)}
          </p>
        </div>
        <div className="single__file__loader__file__status">
          <p
            style={{
              color: currentStatusFontColor,
            }}
          >
            {currentFileStatus === "Zipping..."
              ? `${currentFileStatus} (1/2)`
              : currentFileStatus === "Queuing..."
                ? `${currentFileStatus} (2/2)`
                : currentFileStatus}{" "}
          </p>
        </div>
        <div className="single__file__loader__progress__bar">
          <BorderLinearProgress
            variant={"determinate"}
            value={
              currentFileStatus === "Zipping..."
                ? fileZipProgress
                : currentFileStatus === "Queuing..."
                  ? fileUploadProgress
                  : file.status === "waiting"
                    ? 0
                    : 100
            }
          />
        </div>
      </div>
      {showRemoveButton && (
        <div onClick={removeFile} className="single__file__loader__icon">
          <FeatherIcon
            icon={"x"}
            height={"30px"}
            width={"30px"}
            color={"#FFFFFF"}
          />
        </div>
      )}
    </div>
  );
}
