import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";

/*
  Returns aircraft metadata needed to load the rest of the aircraft view API.
*/

const AIRCRAFT_METADATA = Api.AIRCRAFT_METADATA;

// Returns aircraft metadata, currenlty only latest ahms data transfer date for Global 7500
export async function getAircraftMetadata(
  tailNumber,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  let result;
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber,
    },
  };

  try {
    result = await AxiosService(
      AIRCRAFT_METADATA.url,
      AIRCRAFT_METADATA.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
  }

  return result;
}
