import React, { Component } from "react";
import ToggleSwitch from "../Global Components/ToggleSwitch";
import "./ConfigAircraftSettingsRow.css";

export default class ConfigAircraftSettingsRow extends Component {
  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  render() {
    return (
      <div
        id={this.props.id}
        className={
          "configAircraftSettingsRow" +
          (this.props.rowIsDisabled ? " subtleRow" : "")
        }
      >
        <div className="configAircraftSettingsRow__sectionTitle configAircraftSettingsRow__font ">
          {this.props.settingsTitle}
        </div>
        <div className="configAircraftSettingsRow__sectionValue configAircraftSettingsRow__font">
          {
            this.props.settingUnavailable ? "UNAVAILABLE" :
              <>
              <span className="configAircraftSettingsRow__sectionValue__text">
                {this.props.settingsValue ? "ON" : "OFF"}
              </span>
              {this.props.permEditAcSettings === false ? (
                ""
                ) : (
                <ToggleSwitch
                id="settings-external-rpd-request-switch"
                isChecked={this.props.settingsValue}
                handleSwitchChange={this.handleEditClick}
                isDisabled={this.props.rowIsDisabled}
                />
                )}
              </>
          }
        </div>
      </div>
    );
  }

  handleEditClick() {
    this.props.handleUpdateSettingsClick(
      this.props.settingsType,
      !this.props.settingsValue
    );
  }
}
