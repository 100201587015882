import React, { Component } from "react";
import ConfigAircraftSettingsRow from "./ConfigAircraftSettingsRow";
import FeatherIcon from "feather-icons-react";
import "./ConfigAircraftSettingsBox.css";
import AirframeUIConfigs from "../resources/AirframeUIConfigs.json";

export default class ConfigAircraftSettingsBox extends Component {
  constructor(props) {
    super(props);
    this.createRows = this.createRows.bind(this);

    const notification =
      AirframeUIConfigs[this.props.aircraftFamily][this.props.aircraftModel]
        .configVIewPage.hasRPDFunctionality;

    this.state = {
      aircraftSettingNotifcation:
        "This user does not have permissions to update aircraft settings.",
      hasRpdFunctionality: notification,
    };
  }

  checkForRpdFunctionality() {
    if (this.state.hasRpdFunctionality) {
      return (
        <div>
          {this.props.permEditAcSettings === false ? (
            <div className="configAircraftSettingsBox__alertBanner">
              <FeatherIcon icon={"alert-triangle"} height={20} />
              <span style={{ marginLeft: "5px" }}>
                {this.state.aircraftSettingNotifcation}
              </span>
            </div>
          ) : (
            <div className="configAircraftSettingsBox__alertBanner-inactive"></div>
          )}
        </div>
      );
    } else {
      return (
        <div className="configAircraftSettingsBox__alertBanner">
          <FeatherIcon icon={"alert-triangle"} height={20} />
          <span style={{ marginLeft: "5px" }}>
            {"This Aircraft does not have RPD request capability"}
          </span>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="configAircraftSettingsBox" id="configAircraftSettingsBox">
        {this.checkForRpdFunctionality()}
        <div
          className={"configAircraftSettingsBox__content"}
          id="config-aircraft-settings"
        >
          {this.createRows(this.props.aircraftSettings)}
          <div className="configAircraftSettingsBox__context"></div>
        </div>
      </div>
    );
  }

  createRows(aircraftSettings) {
    let rows = (
      <div className={"configAircraftSettingsBox__container"}>
          <ConfigAircraftSettingsRow
            id={"settings-external-rpd-request"}
            settingsTitle={"RPD Request"}
            settingsType={"EXT_RPD_REQ"}
            settingsValue={aircraftSettings.externalRpdRequest}
            settingsFlagColor={"#4bbc65"}
            rowIsDisabled={false}
            handleUpdateSettingsClick={this.props.handleUpdateSettingsClick}
            permEditAcSettings={this.props.permEditAcSettings}
            settingUnavailable={!this.state.hasRpdFunctionality || !this.props.permEditAcSettings}
          />
      </div>
    );
    return rows;
  }
}
