import React from "react";
import {ChevronUp, ChevronDown} from "react-feather";
import {Card, Collapse} from "react-bootstrap";
import PropTypes from 'prop-types';
import './MobileFeedback.scss';

//Note that the children variable is auto set to be the children of the component (props.children)
const MobileFeedback = ({icon, isOpen, toggleOpen, children}) => {
  return (
    <div className="feedbackMobileContainer nineNineOnemobile">
        <div className="feedbackMobile">
            <Card className="mobileFeedbackInner">
                <Card.Header className="mobileFeedbackHeader" onClick={toggleOpen}>
                    {icon}
                    GIVE FEEDBACK
                    {isOpen ? (<ChevronUp className="feedbackChevronIconMobile" size="25"/>) : ( <ChevronDown className="feedbackChevronIconMobile" size="25"/>)}
                </Card.Header>
                <Collapse in={isOpen}>
                    <Card.Body>
                        {children}
                    </Card.Body>
                </Collapse>
            </Card>
        </div>
    </div>
  );
}

export default MobileFeedback;

MobileFeedback.defaultProps = {
    show: true
}

MobileFeedback.propTypes = {
    icon: PropTypes.element,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    contactUsLink: PropTypes.string
};
  