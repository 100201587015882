import React, { useEffect } from "react";
import TemplateTable from "../../Global Components/TemplateTable";
import "./templateSelection.css";
import {
  createTemplateColumnDef,
  getMaterialReactTemplateColumnDef,
} from "../../Graph Components/Chart Options/MaterialTableConfig.js";
import CustomButton from "../../Global Components/CustomButton.jsx";
import {
  updateTemplateStatus,
  saveTemplate,
} from "../../clients/TemplateClient.js";
import FeatherIcon from "feather-icons-react";
import { getRolesPermissions } from "../../Auth Components/RbacValidation.js";
import {
  getChartsTemplate,
  getChartsTemplateList,
} from "../../clients/ChartsTemplate";
import _ from "lodash";
import Spinner from "../../Global Components/Spinner";

export default function F(props) {
  const resetTableOrderNextRender = React.useRef(false);
  const resetTableFilterNextRender = React.useRef(false);

  const initArchivedTemplates = [];
  const initSharedTemplates = [];
  const initPersonalTemplates = [];

  // Get Template Permissions
  const templatePermissions = getRolesPermissions("ahmsview");
  // Grouping templates by their template of origin
  // Looping through templates to check the fromTemplate Id
  // If it is null, then it gets assigned its id since it is the original template
  // This is done for grouping purposes

  if (props.templates) {
    for (let k = 0; k < props.templates.length; k++) {
      if (!props.templates[k].fromTemplateId) {
        props.templates[k].fromTemplateId = props.templates[k].templateId;
      }

      if (props.templates[k].status === "archived") {
        initArchivedTemplates.push(props.templates[k]);
      }
    }
  }

  _.remove(props.templates, (t) => {
    return t.status === "archived";
  });

  const baseArchivedTemplates = [];

  // grouping templates by fromTemplateId
  const [groupedArchivedTemplates, setGroupedArchivedTemplates] =
    React.useState(_.groupBy(initArchivedTemplates, "fromTemplateId"));

  for (const property in groupedArchivedTemplates) {
    baseArchivedTemplates.push(
      groupedArchivedTemplates[property][
        groupedArchivedTemplates[property].length - 1
      ]
    );
  }

  // grouping templates by fromTemplateId
  const [groupedTemplates, setGroupedTemplates] = React.useState(
    _.groupBy(props.templates, "fromTemplateId")
  );

  const defaultTemplates = [];

  for (const property in groupedTemplates) {
    defaultTemplates.push(groupedTemplates[property][0]);
  }

  // Break up templates for each of the tabs
  for (let i = 0; i < defaultTemplates.length; i++) {
    if (
      defaultTemplates[i].templatePrivacyPolicy === "EXT_PUBLIC" ||
      defaultTemplates[i].templatePrivacyPolicy === "INT_PUBLIC"
    ) {
      initSharedTemplates.push(defaultTemplates[i]);
    } else if (defaultTemplates[i].templatePrivacyPolicy === "PRIVATE") {
      initPersonalTemplates.push(defaultTemplates[i]);
    }
  }

  const [archivedTemplates, setArchivedTemplates] = React.useState(
    baseArchivedTemplates
  );
  const [sharedTemplates, setSharedTemplates] =
    React.useState(initSharedTemplates);
  const [personalTemplates, setPersonalTemplates] = React.useState(
    initPersonalTemplates
  );

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("Shared Templates");
  const [displayTemplateModal, setDisplayTemplateModal] = React.useState(false);
  const [templateModalMessage, setTemplateModalMessage] = React.useState(
    "This Shared Template will be sent to your organization's Archive page."
  );
  const [isTemplateDetailLoading, setIsTemplateDetailLoading] =
    React.useState(false);

  const [selectedTemplate, setSelectedTemplate] = React.useState({
    template: null,
    status: null,
  });

  const [previewTemplateContent, setPreviewTemplateContent] = React.useState(
    <div></div>
  );

  useEffect(() => {
    setIsTemplateDetailLoading(props.isTemplateDetailLoading);
  }, [props.isTemplateDetailLoading]);

  const reloadTemplates = () => {
    getChartsTemplateList(props.aircraftModel, props.aircraftFamily).then(
      (res) => {
        const iArchivedTemplates = [];
        const iSharedTemplates = [];
        const iPersonalTemplates = [];
        let templatesResponse = _.get(res, "data");
        let tTemplates = templatesResponse.templateList;
        tTemplates.forEach((template) => {
          _.assign(template, { parameters: [] });
        });

        if (tTemplates) {
          for (let k = 0; k < tTemplates.length; k++) {
            if (!tTemplates[k].fromTemplateId) {
              tTemplates[k].fromTemplateId = tTemplates[k].templateId;
            }

            if (tTemplates[k].status === "archived") {
              iArchivedTemplates.push(tTemplates[k]);
            }
          }
        }

        _.remove(tTemplates, (t) => {
          return t.status === "archived";
        });

        const bArchivedTemplates = [];

        let groupedArchiveTemplates = _.groupBy(
          iArchivedTemplates,
          "fromTemplateId"
        );

        for (const property in groupedArchiveTemplates) {
          bArchivedTemplates.push(
            groupedArchiveTemplates[property][
              groupedArchiveTemplates[property].length - 1
            ]
          );
        }

        // grouping templates by fromTemplateId

        const templateGroup = _.groupBy(tTemplates, "fromTemplateId");

        const dTemplates = [];

        for (const property in templateGroup) {
          dTemplates.push(
            templateGroup[property][templateGroup[property].length - 1]
          );
        }

        // Break up templates for each of the tabs
        for (let i = 0; i < dTemplates.length; i++) {
          if (
            dTemplates[i].templatePrivacyPolicy === "EXT_PUBLIC" ||
            dTemplates[i].templatePrivacyPolicy === "INT_PUBLIC"
          ) {
            iSharedTemplates.push(dTemplates[i]);
          } else if (dTemplates[i].templatePrivacyPolicy === "PRIVATE") {
            iPersonalTemplates.push(dTemplates[i]);
          }
        }

        setArchivedTemplates([...bArchivedTemplates]);
        setSharedTemplates([...iSharedTemplates]);
        setPersonalTemplates([...iPersonalTemplates]);
        setGroupedTemplates({ ...templateGroup });
        setGroupedArchivedTemplates({ ...groupedArchiveTemplates });
      }
    );
  };

  const changeTemplateVersion = (tab, value, base, rValue) => {
    if (tab === "My Templates") {
      let index = _.findIndex(groupedTemplates[base], function (t) {
        return t.templateId === value.value;
      });

      if (index !== -1) {
        let archiveIndex = _.findIndex(personalTemplates, function (t) {
          return t.templateId === rValue;
        });

        if (archiveIndex !== -1) {
          personalTemplates[archiveIndex] = groupedTemplates[base][index];
        }
      }
      setPersonalTemplates([...personalTemplates]);
      props.setLoadedTemplate(groupedTemplates[base][index]);
    } else if (tab === "Shared Templates") {
      let index = _.findIndex(groupedTemplates[base], function (t) {
        return t.templateId === value.value;
      });

      if (index !== -1) {
        let archiveIndex = _.findIndex(sharedTemplates, function (t) {
          return t.templateId === rValue;
        });

        if (archiveIndex !== -1) {
          sharedTemplates[archiveIndex] = groupedTemplates[base][index];
        }
      }
      setSharedTemplates([...sharedTemplates]);
      props.setLoadedTemplate(groupedTemplates[base][index]);
    } else if (tab === "Archive") {
      let index = _.findIndex(groupedArchivedTemplates[base], function (t) {
        return t.templateId === value.value;
      });

      if (index !== -1) {
        let archiveIndex = _.findIndex(archivedTemplates, function (t) {
          return t.templateId === rValue;
        });

        if (archiveIndex !== -1) {
          archivedTemplates[archiveIndex] =
            groupedArchivedTemplates[base][index];
        }
      }
      setArchivedTemplates([...archivedTemplates]);
      props.setLoadedTemplate(groupedArchivedTemplates[base][index]);
    }
  };

  const openPreviewTemplateModal = async (template) => {
    if (template.parameters.length === 0) {
      setIsTemplateDetailLoading(true);
      await getChartsTemplate(template.templateId)
        .then((res) => {
          template.parameters = res.data.parameters;
          setIsTemplateDetailLoading(false);
        })
        .catch((error) => {
          template.parameters = [];
          setIsTemplateDetailLoading(false);
        });
    }
    let innerHtml = (
      <div className="template__preview_content_ctr">
        <div className="template__preview_content_header">
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div style={{ width: "40%", display: "flex" }}>
                <div style={{ marginTop: "10px" }}>{template.templateName}</div>
              </div>
              <div style={{ width: "20%", marginTop: "10px" }}>
                {template.faultCode}
              </div>
              <div style={{ width: "20%", marginTop: "10px", display: "flex" }}>
                {template.templatePrivacyPolicy === "PRIVATE" && (
                  <FeatherIcon
                    style={{ marginRight: "6px", marginTop: "-6px" }}
                    icon="user"
                    width={24}
                    height={36}
                  />
                )}
                {template.templatePrivacyPolicy === "INT_PUBLIC" && (
                  <FeatherIcon
                    style={{ marginRight: "6px", marginTop: "-6px" }}
                    icon="users"
                    width={24}
                    height={36}
                  />
                )}
                {template.templatePrivacyPolicy === "EXT_PUBLIC" && (
                  <FeatherIcon
                    style={{ marginRight: "6px", marginTop: "-6px" }}
                    icon="star"
                    width={24}
                    height={36}
                  />
                )}{" "}
                {template.templatePrivacyPolicy === "PRIVATE" && (
                  <div style={{ fontWeight: 400 }}>Private Template</div>
                )}
                {template.templatePrivacyPolicy === "INT_PUBLIC" && (
                  <div style={{ fontWeight: 400 }}>
                    Internal Public Template
                  </div>
                )}
                {template.templatePrivacyPolicy === "EXT_PUBLIC" && (
                  <div style={{ fontWeight: 400 }}>
                    External Public Template
                  </div>
                )}
              </div>
              <div style={{ width: "20%", marginTop: "10px" }}>
                {template.parameters
                  ? template.parameters.length + " total parameters"
                  : "0 total parameters"}
              </div>
            </div>
          </div>
        </div>
        <div className="template__preview_description">
          <div style={{ width: "100%", fontWeight: "600" }}>
            {"Description:"}
          </div>
          <div style={{ width: "100%" }}>{template.notes}</div>
        </div>
        <div className="template__preview_content_row_label">
          <div style={{ width: "40%" }}>{"Parameter"}</div>
          <div style={{ width: "20%" }}>{"ATA"}</div>
          <div style={{ width: "20%" }}>{"LRU"}</div>
          <div style={{ width: "20%" }}>{"Type"}</div>
        </div>
        <div
          style={{
            paddingBottom: "20px",
            height: "500px",
            overflowY: "scroll",
          }}
        >
          {template.parameters ? (
            template.parameters.map((row) => (
              <React.Fragment key={row.parameterName}>
                <div className="template__preview_content_row">
                  <div style={{ width: "40%", fontWeight: "400" }}>
                    {row.parameterName}
                  </div>
                  <div style={{ width: "20%" }}>
                    {row.ATAChapter ? row.ATAChapter : "-"}
                  </div>
                  <div style={{ width: "20%" }}>{row.LRU ? row.LRU : "-"}</div>
                  <div style={{ width: "20%", fontWeight: "400" }}>
                    {row.parameterType ? row.parameterType : "-"}
                  </div>
                </div>
              </React.Fragment>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              No Parameters Saved in Template
            </div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            height: "70px",
            backgroundColor: "#303030",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            position: "relative",
            paddingTop: "12px",
          }}
        >
          <div
            style={{ position: "absolute", left: "0px", paddingLeft: "40px" }}
          >
            <CustomButton
              id={"download-chart-template"}
              text={"DOWNLOAD"}
              isDisabled={false}
              customHeight={"40"}
              customWidth={"250"}
              onClickAction={() => {
                props.saveLocalTemplate(template);
                setIsOpen(false);
              }}
            />
          </div>
          <div style={{ position: "absolute", right: "310px" }}>
            <CustomButton
              id={"close-template-modal"}
              text={"CANCEL"}
              isDisabled={false}
              customHeight={"40"}
              customWidth={"250"}
              colorStyle="secondary"
              onClickAction={() => {
                setIsOpen(false);
              }}
            />
          </div>
          <div style={{ position: "absolute", right: "40px" }}>
            <CustomButton
              id={"load-template-from-modal"}
              text={"LOAD TEMPLATE"}
              isDisabled={false}
              customHeight={"40"}
              customWidth={"250"}
              onClickAction={() => {
                setIsOpen(false);
                props.loadTemplate(template);
              }}
            />
          </div>
        </div>
      </div>
    );

    setPreviewTemplateContent(innerHtml);
    setIsOpen(true);
  };

  const toggleTemplatePreviewModal = () => {
    setIsOpen(isOpen ? false : true);
  };

  const toggleTemplateModal = (rowData, status, tab) => {
    if (status === "archived") {
      setTemplateModalMessage(
        "This Shared Template will be sent to your organization's Archive page."
      );
    }
    if (status === "deleted" && tab === "Archive") {
      setTemplateModalMessage(
        "The Archived Template will be permnently deleted. You cannot undo this action."
      );
    }

    if (status === "deleted" && tab === "My Templates") {
      setTemplateModalMessage(
        "This template will be removed from your my templates page."
      );
    }

    if (status === "copied") {
      setTemplateModalMessage(
        "This shared template will be duplicated to your My Templates page."
      );
    }

    if (status === "upload") {
      setTemplateModalMessage(
        "This archived template will be restored to your organization's Shared Templates page."
      );
    }

    if (displayTemplateModal === false) {
      setSelectedTemplate({
        template: rowData,
        status: status,
      });
    }

    setDisplayTemplateModal(displayTemplateModal ? false : true);
  };

  const uploadFile = (event) => {
    let file = event.target.files[0];

    if (file) {
      props.uploadLocalTemplate(file);
    }
  };

  // Updating Status of template with the exception of copy
  const updateStatus = () => {
    if (selectedTemplate.status === "copied") {
      duplicateTemplate();
    } else {
      updateTemplateStatus(
        selectedTemplate.template,
        selectedTemplate.status
      ).then((res) => {
        reloadTemplates();
      });
    }
  };

  // Copying template
  const duplicateTemplate = async () => {
    if (selectedTemplate.template.parameters.length === 0) {
      setIsTemplateDetailLoading(true);
      await getChartsTemplate(selectedTemplate.template.templateId)
        .then((res) => {
          selectedTemplate.template.parameters = res.data.parameters;
          setIsTemplateDetailLoading(false);
        })
        .catch((error) => {
          selectedTemplate.template.parameters = [];
          setIsTemplateDetailLoading(false);
        });
    }
    const tTemplate = {
      ataChapter: selectedTemplate.template.ataChapter,
      cdbVersion: selectedTemplate.template.cdbVersion,
      createdBy: selectedTemplate.template.createdBy,
      faultCode: selectedTemplate.template.faultCode,
      notes: selectedTemplate.template.notes,
      omsFaultCodes: selectedTemplate.template.omsFaultCodes,
      parameters: selectedTemplate.template.parameters,
      status: selectedTemplate.template.status,
      tableData: selectedTemplate.template.tableData,
      templateId: selectedTemplate.template.templateId,
      templateName: selectedTemplate.template.templateName,
      templatePrivacyPolicy: selectedTemplate.template.templatePrivacyPolicy,
      templateVersion: selectedTemplate.template.templateVersion,
      aircraftFamily: props.aircraftFamily,
      aircraftModel: props.aircraftModel,
    };

    tTemplate.templateName = selectedTemplate.template.templateName + "(copy)";
    tTemplate.templatePrivacyPolicy = "PRIVATE";
    tTemplate.status = "PRIVATE";

    saveTemplate(tTemplate).then((res) => {
      reloadTemplates();
    });
  };

  const [templateTabs, setTemplateTabs] = React.useState([
    {
      name: "Archive",
      selected: false,
    },
    {
      name: "Shared Templates",
      selected: true,
    },
    {
      name: "My Templates",
      selected: false,
    },
  ]);

  const handleTemplateTabSelection = (tab) => {
    let tempTabs = [
      {
        name: "Archive",
        selected: false,
      },
      {
        name: "Shared Templates",
        selected: false,
      },
      {
        name: "My Templates",
        selected: false,
      },
    ];

    tempTabs[tab].selected = true;

    setTemplateTabs(tempTabs);
    setSelectedTab(tempTabs[tab].name);
  };

  return (
    <>
      {isTemplateDetailLoading && (
        <div>
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgb(0,0,0,.5)",
              zIndex: "2000",
              top: "0",
              left: "0",
            }}
          >
            <div style={{ width: "100px", paddingTop: "20%", margin: "auto" }}>
              <Spinner />
            </div>
          </div>
        </div>
      )}
      <div id="template-selection" style={{ color: "white" }}>
        <div className="template__selection__instructions">
          Choose which template to load:
        </div>
        <div
          id="myModal"
          className={
            isOpen
              ? "template__preview__modal template__modal__open"
              : "template__preview__modal template__modal__close"
          }
        >
          <div className="template__preview__content">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {previewTemplateContent}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          {templateTabs.map((tab, index) => (
            <React.Fragment key={tab.name}>
              <div
                className={
                  tab.selected
                    ? "default-tab default__tab_selected"
                    : "default-tab"
                }
                onClick={() => {
                  handleTemplateTabSelection(index);
                }}
              >
                {tab.name}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div
          className={
            displayTemplateModal ? "template__modal show" : "template__modal"
          }
        >
          <div className="template__modal_content">
            <div className="template__modal_banner"></div>
            <div className="template__modal__icon_ctnr">
              <FeatherIcon
                icon="info"
                width={60}
                height={60}
                className="template__modal__icon"
              />
            </div>
            <div className="template__modal_description">
              <div className="template__modal_text">{templateModalMessage}</div>
              <div className="template__modal_warning">
                Would you like to proceed?
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  paddingBottom: "40px",
                }}
              >
                <CustomButton
                  id={"close-template-modal-btn"}
                  text={"CANCEL"}
                  isDisabled={false}
                  customWidth={"150"}
                  customHeight={"40"}
                  colorStyle="secondary"
                  onClickAction={() => {
                    toggleTemplateModal();
                  }}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                  paddingBottom: "40px",
                  paddingRight: "40px",
                }}
              >
                <CustomButton
                  id={"confirm-template-modal-btn"}
                  text={"PROCEED"}
                  isDisabled={false}
                  customWidth={"175"}
                  customHeight={"40"}
                  colorStyle="custom"
                  customButtonColor={"#709DFF"}
                  icon={
                    <FeatherIcon icon="arrow-right" height={20} width={20} />
                  }
                  onClickAction={() => {
                    updateStatus();
                    toggleTemplateModal();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {selectedTab === "Archive" && (
          <div id="archive-template-table">
            <TemplateTable
              data={archivedTemplates}
              columns={getMaterialReactTemplateColumnDef()}
              groupedTemplates={groupedArchivedTemplates}
              tab={"archive"}
              tabDisplayName={"Archive"}
              templatePermissions={templatePermissions}
              handleOpenPreview={openPreviewTemplateModal}
              toggleTemplateModal={toggleTemplateModal}
              changeTemplateVersion={changeTemplateVersion}
            />
          </div>
        )}
        {selectedTab === "Shared Templates" && (
          <div id="shared-template-table">
            <TemplateTable
              data={sharedTemplates}
              tab={"sharedTemplates"}
              tabDisplayName={"Shared Templates"}
              columns={getMaterialReactTemplateColumnDef()}
              groupedTemplates={groupedTemplates}
              handleOpenPreview={openPreviewTemplateModal}
              handleLoadTemplate={props.loadTemplate}
              toggleTemplateModal={toggleTemplateModal}
              templatePermissions={templatePermissions}
              changeTemplateVersion={changeTemplateVersion}
            />
          </div>
        )}
        {selectedTab === "My Templates" && (
          <div id="private-templates-table">
            <TemplateTable
              data={personalTemplates}
              columns={getMaterialReactTemplateColumnDef()}
              groupedTemplates={groupedTemplates}
              tab={"myTemplates"}
              tabDisplayName={"My Templates"}
              templatePermissions={templatePermissions}
              handleOpenPreview={openPreviewTemplateModal}
              handleLoadTemplate={props.loadTemplate}
              toggleTemplateModal={toggleTemplateModal}
              changeTemplateVersion={changeTemplateVersion}
            />
          </div>
        )}
        <div
          style={{
            width: "100%",
            fontWeight: "600",
            paddingTop: "20px",
            fontSize: "18px",
            letterSpacing: ".45px",
          }}
        >
          {"Or, upload local template:"}
        </div>
        <div style={{ paddingTop: "40px" }}>
          <label className="custom-file-upload">
            <input
              type="file"
              name="myFile"
              onChange={(f) => {
                uploadFile(f);
              }}
            />
            <FeatherIcon icon="arrow-up" width={24} height={24} /> Upload
            Template
          </label>
        </div>
      </div>
    </>
  );
}
