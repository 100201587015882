import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";

const StepperRoot = styled(Stepper)(() => ({
  "& .MuiStepIcon-root:not(.Mui-completed)": {
    color: "#3F51B5",
  },

  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
    border: "1px solid #A7A7A7",
  },

  "& .MuiStepIcon-text": {
    fill: "#A7A7A7",
    fontWeight: 500,
  },

  "& .Mui-active .MuiStepIcon-text": {
    // fill: "#D19000"
    fill: "#3F51B5",
  },

  "& .Mui-active .MuiSvgIcon-root": {
    // borderColor: "#D19000",
    borderColor: "#FFFFFF",
    color: "#FFFFFF",
  },

  "& .Mui-completed .MuiStepIcon-root": {
    // color: "#D19000",
    // border: "1px solid #D19000",
    fill: "#A7A7A7",
  },

  "& .Mui-completed .MuiStepIcon-text": {
    fill: "#FFFFFF",
  },
}));

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 12px)",
    right: "calc(50% + 12px)",
  },
  // [`&.${stepConnectorClasses.active}`]: {
  //   [`& .${stepConnectorClasses.line}`]: {
  //     borderColor: '#D19000',
  //   },
  // },
  // [`&.${stepConnectorClasses.completed}`]: {
  //   [`& .${stepConnectorClasses.line}`]: {
  //     borderColor: '#D19000',
  //   },
  // },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#A7A7A7",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#A7A7A7",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#A7A7A7",
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const ProgressBarSteps = ["Fleet", "Aircraft", "Templates", "Configuration"];

const Card = (props) => {
  const entries = props.paragraphs.map((p, idx) => (
    <div key={idx} className="cardPara">
      {p}
    </div>
  ));

  return (
    <div>
      {props.index !== 0 ? `${props.index} / ${props.stepCount}` : ""}
      {entries}
      {props.index !== 0 ? (
        <></>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            width: "400px",
            margin: "auto",
          }}
        >
          PROGRESS
          <StepperRoot
            alternativeLabel
            activeStep={ProgressBarSteps.indexOf(props.pageName)}
            connector={<Connector />}
          >
            {ProgressBarSteps.map((label) => (
              <Step key={label}>
                <StepLabel>
                  {props.pageName !== label ? (
                    <></>
                  ) : (
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "DM Sans",
                        fontSize: "16px",
                        fontWeight: "500",
                        marginTop: "0px",
                      }}
                    >
                      {label}
                    </span>
                  )}
                </StepLabel>
              </Step>
            ))}
          </StepperRoot>
        </div>
      )}
    </div>
  );
};

export default Card;
