import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import CircularProgress from '@mui/material/CircularProgress'

import FleetList from '../FleetList'
import AnomalyCard from '../../Global Components/AnomalyCard'
import SearchBox from '../../Global Components/SearchBox'
import FilterBox from './FilterBox'
import SortBox from './SortBox'
import { handleFiltering, handleReportsSearch, handleFleetSearch } from '../../usefulFunctions'
import { getFlightListHistory } from '../../../clients/FlightHistory'
import { columnsFromBackend, statuses } from '../../constants.js'

import './Reports.css'

const Reports = props => {
    const {
        data,
        fleet,
        columns,
        fleetList,
        filteredFleetList,
        filteredReports,
        searchFilteredReports,
        updatePageInfo,

        fleetSearch,
        handleFleetSearchChange,

        currSort,
        handleSortOptionChange,
        fleetListLength,
        searchParams,
        handleSearchParams,
        oldestAnomalyTs,
        userType,
        filterOptions,
        handleFilterOptionsChange,
        handleFlightHistoryChange,
        flightHistories,
        prognosticsViewed
    } = props

    const [currFilters, setCurrFilters] = useState({
        ise_name: [],
        ata_chapter: [],
        severity: [],
        flight_phase: [],
        status: [],
        type: []
    })
    const [dateFilter, setDateFilter] = useState({
        title: 'Date',
        id: 'date',
        isCollapsed: true,
        startDate: null,
        endDate: null
    })
    const [sortOptions, setSortOptions] = useState([
        {
            title: 'Time',
            id: 'first_timestamp',
            options: [
                {
                    title: 'Newest First',
                    id: 'newest'
                },
                {
                    title: 'Oldest First',
                    id: 'oldest'
                }
            ]
        }
    ])

    // when a fleet from the fleet list is pressed,
    // the specific fleet info is sent here
    // TODO, dont put everything into new, separate based on the data
    const handleFleetPress = async (currFleet, list) => {
        let noAnomalies = false

        // Check if aircraft has no anomalies AT ALL
        if (data) {
            const anomalyfromFleet = _.findIndex(data, function (o) {
                return o.serial_number == currFleet.shortSerialNumber
            })
            if (anomalyfromFleet === -1) {
                noAnomalies = true
            }
        }

        if (!noAnomalies) {
            // Get flight history
            if (!(currFleet.shortSerialNumber in flightHistories)) {
                if (typeof currFleet.newestTs === 'undefined') {
                    let fleetIndex = _.findIndex(fleetList, function (f) {
                        return f.shortSerialNumber === currFleet.shortSerialNumber
                    })

                    if (fleetIndex !== -1) {
                        currFleet = {
                            ...currFleet,
                            ...fleetList[fleetIndex]
                        }
                    }
                }

                // Adding a day to newest timestamp
                let date = new Date(currFleet.newestTs).getTime() + 86400000
                const newDate = new Date(date)
                const year = newDate.getUTCFullYear()
                let month = newDate.getMonth() + 1
                if (month < 10) month = `0${month}`

                let day = newDate.getDate()
                if (day < 10) day = `0${day}`

                const utcDate = `${year}-${month}-${day}T00:00:00.000000Z`

                const response = await getFlightListHistory(
                    currFleet.registrationNumber,
                    currFleet.aircraftFamily,
                    currFleet.aircraftModel,
                    utcDate
                )
                if (response.data) {
                    handleFlightHistoryChange(currFleet.shortSerialNumber, response.data)
                }
            }

            let newAnomalies = []
            let openAnomalies = []
            let closedAnomalies = []

            if (list.length > 0) {
                for (let i = 0; i < list.length; i++) {
                    const anomaly = list[i]
                    let column = []
                    if (anomaly.status === 'NEW') column = newAnomalies
                    else if (anomaly.status === 'OPEN') column = openAnomalies
                    else if (anomaly.status === 'CLOSED') column = closedAnomalies

                    if (anomaly.serial_number == currFleet.shortSerialNumber) {
                        // This allows to not add duplicates in the column
                        // column.push(anomaly);
                        if (
                            _.findIndex(column, function (a) {
                                return a.event_id === anomaly.event_id
                            }) === -1
                        ) {
                            column.push(anomaly)
                        }
                    }
                }
            }

            columnsFromBackend['0'].items = newAnomalies
            columnsFromBackend['1'].items = openAnomalies
            columnsFromBackend['2'].items = closedAnomalies

            updatePageInfo('columns', columnsFromBackend)
        } else {
            updatePageInfo('columns', {})
        }

        updatePageInfo('fleet', currFleet)
        // store the fleet we are currently looking at in local storage
        localStorage.setItem('currentReportsFleet', JSON.stringify(currFleet))
    }

    /** Functions related to Filter box */

    // Handles when selecting a date range in Filter Box
    const handleDateChange = (name, value) => {
        dateFilter[name] = value
        setDateFilter({ ...dateFilter })
    }

    // Handles when selecting apply in Filter box
    const handleFilterApplyClick = () => {
        let currList = data

        // Check if contains report search
        if (searchParams.text !== '') {
            currList = searchFilteredReports
        }

        let newReportsList = []

        newReportsList = handleFiltering(currList, currFilters, dateFilter, oldestAnomalyTs)

        updatePageInfo('filteredReports', [...newReportsList], 'Reports')
    }

    // Handles category in Filter box collapse
    const handleFilterCategoryCollapse = (title, name, value) => {
        let optionIndex = _.findIndex(filterOptions, function (o) {
            return o.title === title
        })

        if (optionIndex !== -1) {
            let option = filterOptions[optionIndex]
            option[name] = value

            handleFilterOptionsChange([...filterOptions])
        }
    }

    // Handles when selecting a sub-option from one of the categories in Filter box
    const filterClicked = (option, value) => {
        let index = _.findIndex(currFilters[option], function (f) {
            return f === value
        })

        if (index !== -1) {
            currFilters[option].splice(index, 1)
        } else {
            currFilters[option].push(value)
        }

        setCurrFilters({ ...currFilters })
    }

    const handleFilerResetClick = filters => {
        setCurrFilters({ ...filters })
    }

    /** Functions related to search */
    const handleSearchEnter = searchText => {
        const newSearchParams = {
            oldText: searchParams.text,
            text: searchText,
            load: true
        }

        handleSearchParams(newSearchParams)
    }

    const handleSearch = searchText => {
        let filteredFleets = []
        let newReportsList = []

        if (searchText !== '') {
            if (searchText.includes(searchParams.oldText)) {
                newReportsList = handleReportsSearch(searchText, filteredReports, flightHistories)
            } else {
                newReportsList = handleReportsSearch(searchText, data, flightHistories)
            }
            updatePageInfo('searchFilteredReports', newReportsList, 'Reports')
        } else {
            newReportsList = data
        }

        // Check if contains any report filters (from filter box)
        let containsAFilter = false

        // Check if contains filters
        for (const [key, array] of Object.entries(currFilters)) {
            if (array.length > 0) containsAFilter = true
        }

        // Check if contains date range filter
        if (dateFilter.startDate || dateFilter.endDate) containsAFilter = true

        // Add filters to the new reports list if reports currently contains a filter
        if (containsAFilter) {
            newReportsList = handleFiltering(newReportsList, currFilters, dateFilter)
        }

        if (fleetSearch !== '') {
            const newFleetList = handleFleetSearch(fleetSearch, fleetList)

            newFleetList.forEach(f => {
                let index = _.findIndex(newReportsList, function (fleet) {
                    return fleet.serial_number.toString() === f.shortSerialNumber.toString()
                })

                if (index !== -1) {
                    filteredFleets.push(f)
                }
            })
        } else {
            fleetList.forEach(f => {
                let index = _.findIndex(newReportsList, function (fleet) {
                    return fleet.serial_number.toString() === f.shortSerialNumber.toString()
                })

                if (index !== -1) {
                    filteredFleets.push(f)
                }
            })
        }

        updatePageInfo('filteredReports', [...newReportsList], 'Reports')
        updatePageInfo('filteredFleetList', [...filteredFleets], 'Reports')

        searchParams.load = false
        handleSearchParams(searchParams)
    }

    // Returns filtered fleet list depending on the filtered report list
    const getFleetList = (reportsList, fleetList) => {
        let filteredFleets = []
        fleetList = structuredClone(fleetList)

        reportsList.forEach(report => {
            if (filteredFleets.length <= fleetListLength) {
                const serialNumber = report.serial_number.toString()

                const fleetListIndex = _.findIndex(fleetList, function (fleet) {
                    return fleet.shortSerialNumber === serialNumber
                })

                const currFleetListIndex = _.findIndex(filteredFleets, function (fleet) {
                    return fleet.shortSerialNumber === serialNumber
                })

                if (fleetListIndex !== -1 && currFleetListIndex === -1) {
                    filteredFleets.push(fleetList[fleetListIndex])
                    fleetList.splice(fleetListIndex, 1)
                }
            }
        })

        return filteredFleets.concat([...fleetList])
    }

    useEffect(() => {
        handleFilterApplyClick()
    }, [data])

    useEffect(() => {
        if (searchParams.load) {
            handleSearch(searchParams.text)
        }
    }, [searchParams.load])

    useEffect(() => {
        if (filteredReports) {
            let filteredFleets = fleetList

            if (fleetSearch !== '') {
                const newFleetList = handleFleetSearch(fleetSearch, fleetList)
                filteredFleets = getFleetList(filteredReports, newFleetList)
            } else {
                filteredFleets = getFleetList(filteredReports, [...fleetList])
            }

            updatePageInfo('filteredFleetList', [...filteredFleets], 'Reports')
        }
    }, [filteredReports])

    useEffect(() => {
        let fleetSelected = fleet
        if (filteredFleetList) {
            if (!fleet) {
                fleetSelected = filteredFleetList[0]
            }
            if (fleetSelected && filteredReports) {
                handleFleetPress(fleetSelected, filteredReports)
            }
        }
    }, [filteredReports, filteredFleetList])

    return (
        <div className='predictiveView__tabContent'>
            {/* LEFT SIDE */}
            <FleetList
                data={data}
                selectedFleet={fleet}
                fleetPressed={handleFleetPress}
                fleetList={fleetList}
                currTab={'Reports'}
                updatePageInfo={updatePageInfo}
                filteredReports={filteredReports}
                filteredFleetList={filteredFleetList}
                reportSearch={searchParams.text}
                fleetSearch={fleetSearch}
                handleFleetSearchChange={handleFleetSearchChange}
                currSort={currSort}
                fleetListLength={fleetListLength}
                prognosticsViewed={prognosticsViewed}
            />

            {/* RIGHT SIDE */}
            <div className='reports__rightSide__container'>
                <div className='infoBox'>
                    <div className='infoBox__header'>In-Service Event Reports</div>
                    <div
                        className='infoBox__body'
                        style={{
                            padding: '0 10px 15px',
                            overflowX: 'hidden'
                        }}
                    >
                        <div className='reports__filterBoxes'>
                            <SearchBox handleSearch={handleSearchEnter} width={'270px'} />

                            <FilterBox
                                width={'270px'}
                                filterOptions={filterOptions || []}
                                currFilters={currFilters}
                                filterClicked={filterClicked}
                                handleFilterCategoryCollapse={handleFilterCategoryCollapse}
                                handleFilterApplyClick={handleFilterApplyClick}
                                dateFilter={dateFilter}
                                handleDateChange={handleDateChange}
                                handleFilerResetClick={handleFilerResetClick}
                            />

                            <SortBox
                                width={'200px'}
                                sortOptions={sortOptions}
                                currSort={currSort}
                                handleSortOptionChange={handleSortOptionChange}
                            />
                        </div>
                        <div className='reports__kanbanContainer'>
                            {columns ? (
                                <>
                                    {Object.keys(columns).length === 0 ? (
                                        <div className='reports__empty'>
                                            {fleet && (
                                                <p>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '18px'
                                                        }}
                                                    >
                                                        No In-Service Event Reports found
                                                    </span>
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontWeight: 'normal',
                                                            fontSize: '15px'
                                                        }}
                                                    >
                                                        Predictive Maintenance has not flagged any
                                                        events for {fleet.registrationNumber}
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {Object.entries(columns).map(([id, column]) => {
                                                // TODO: remove when ISE 9 is re-implemented
                                                const columnItemsListFiltered = column.items.filter(
                                                    item => item.ise_num !== 9
                                                )
                                                return (
                                                    <div
                                                        key={id}
                                                        className='reports__droppableContainer'
                                                    >
                                                        <Droppable droppableId={id} key={id}>
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        className='reports__eachDroppable'
                                                                        style={{
                                                                            background:
                                                                                snapshot.isDraggingOver
                                                                                    ? '#4d4d4d'
                                                                                    : '#303030'
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                display: 'none'
                                                                            }}
                                                                        >
                                                                            {provided.placeholder}
                                                                        </span>
                                                                        <div className='reports__eachDroppableTitle'>
                                                                            {column.name}
                                                                            <span
                                                                                style={{
                                                                                    marginLeft: 5,
                                                                                    color:
                                                                                        id === 0
                                                                                            ? '#FFFFFF'
                                                                                            : '#A7A7A7'
                                                                                }}
                                                                            >
                                                                                {`(${columnItemsListFiltered.length})`}
                                                                            </span>
                                                                        </div>
                                                                        {/* this is all the draggable items inside the kanban boards */}
                                                                        {columnItemsListFiltered.map(
                                                                            (item, index) => (
                                                                                <React.Fragment
                                                                                    key={`${item.session_key}-${item.ise_num}`}
                                                                                >
                                                                                    <AnomalyCard
                                                                                        data={item}
                                                                                        index={
                                                                                            index
                                                                                        }
                                                                                        fleet={
                                                                                            fleet
                                                                                        }
                                                                                        flightHistories={
                                                                                            flightHistories
                                                                                        }
                                                                                    />
                                                                                </React.Fragment>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )
                                                            }}
                                                        </Droppable>
                                                    </div>
                                                )
                                            })}
                                        </React.Fragment>
                                    )}
                                </>
                            ) : (
                                <div
                                    style={{
                                        width: '100%',
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CircularProgress style={{ color: '#979797' }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports
