import React from 'react';
import FeatherIcon from "feather-icons-react";
import PredictiveTooltip from '../../Global Components/PredictiveTooltip';

import './ScrollToTopButton.css';
  
const ScrollToTopButton = () =>{
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  return (
    <button
      className={"scrollbutton"}
      onClick={scrollToTop}
    >
      <PredictiveTooltip
        element={
          <FeatherIcon
                width={25}
                height={25}
                icon={"chevron-up"}
            />
        }
        text="Back to top"
        fitted="100px"
        centered={true}
      />
    </button>
    
  );
}
  
export default ScrollToTopButton;