import React, { Component } from "react";
import "../styles/Global Components/Header.css";
import baLogo from "../resources/Bombardier-Logo-White.svg";
import { Link } from "react-router-dom";

export default class Header extends Component {
  render() {
    return (
      <div className="header">
        <Link to="/fleetView">
          <img className="header__logo" src={baLogo} alt="BOMBARDIER"></img>
        </Link>
      </div>
    );
  }
}
