import AxiosService from "./AxiosService";
import * as Constants from "../Constants/Constants";

const BOMBARDIER_API_URL = Constants.BOMBARDIER_API_URL();
const CREATE_TEMPLATE_ENDPOINT = "/v10/charts-template-create";
const TEMPLATE_LIST_ENDPOINT = "/v10/charts-template-list";
const TEMPLATE_STATUS_ENDPOINT = "/v10/charts-template-status";
const UPDATE_TEMPLATE_ENDPOINT = "/v10/charts-template-update";
const TEMPLATE_EXISTS_ENDPOINT = "/v10/template_availability";
const ATA_CHAPTER_LIST = "/v10/ata-chapters";

export async function saveTemplate(templateObject, axiosCancelSource) {
  let result;
  const data = {
    aircraftFamily: templateObject.aircraftFamily,
    aircraftModel: templateObject.aircraftModel,
    ataChapter: templateObject.ataChapter,
    cdbVersion: templateObject.cdbVersion,
    createdBy: templateObject.createdBy,
    faultCode: templateObject.faultCode,
    notes: templateObject.notes,
    omsFaultCodes: templateObject.omsFaultCodes,
    parameters: templateObject.parameters,
    status: templateObject.status,
    templateId: templateObject.templateId,
    templateName: templateObject.templateName,
    templatePrivacyPolicy: templateObject.templatePrivacyPolicy,
    templateVersion: templateObject.templateVersion,
  };

  if (templateObject.fromTemplateId) {
    data.fromTemplateId = templateObject.fromTemplateId;
  }

  try {
    result = await AxiosService(
      BOMBARDIER_API_URL + CREATE_TEMPLATE_ENDPOINT,
      "POST",
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}

export async function updateTemplateStatus(
  template,
  status,
  axiosCancelSource
) {
  let result;
  const data = {};

  let putStatus = status;

  if (status === "upload") {
    putStatus = "restore";
  }

  const requestString = `${BOMBARDIER_API_URL}${TEMPLATE_STATUS_ENDPOINT}?status=${putStatus}&template_id=${template.templateId}`;

  try {
    result = await AxiosService(requestString, "PUT", data, axiosCancelSource);
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}

export async function updateTemplate(
  templateData,
  templateObject,
  aircraftFamily,
  aircraftModel,
  axiosCancelSource
) {
  let result;
  templateObject.notes = templateData.notes;
  const data = {
    templateObject: templateObject,
    faultCode: templateData.faultCode,
    templateId: templateData.templateId,
    templateName: templateData.templateName,
    aircraftFamily,
    aircraftModel,
    notes: templateData.notes,
    ATAChapter: templateData.ataChapter,
  };
  data.templateObject.name = templateData.templateName;

  try {
    result = await AxiosService(
      BOMBARDIER_API_URL + UPDATE_TEMPLATE_ENDPOINT,
      "POST",
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}

export async function getTemplateList(
  aircraftModel,
  aircraftFamily,
  axiosCancelSource
) {
  let result;

  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
    },
  };

  try {
    result = await AxiosService(
      BOMBARDIER_API_URL + TEMPLATE_LIST_ENDPOINT,
      "GET",
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}

export async function getTemplateExists(
  faultCode,
  aircraftModel,
  aircraftFamily,
  axiosCancelSource
) {
  let result;

  const data = {
    params: {
      faultCode: faultCode,
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
    },
  };

  try {
    result = await AxiosService(
      BOMBARDIER_API_URL + TEMPLATE_EXISTS_ENDPOINT,
      "GET",
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}

export async function getATAChapterList(family, model, axiosCancelSource) {
  let result;

  const data = {
    params: {
      aircraftFamily: family,
      aircraftModel: model,
    },
  };

  try {
    result = await AxiosService(
      BOMBARDIER_API_URL + ATA_CHAPTER_LIST,
      "GET",
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}
