import React from "react";
import FeatherIcon from "feather-icons-react";
import PredictiveTooltip from "./PredictiveTooltip";

import "./TabItem.css";

// if edit styles here, also make sure to edit them on the review button
const TabItem = (props) => {
    const {
        data,
        text,
        icon,
        color,
        currentTab,
        onPress = () => { },
        isDraggingOver,
        count,
        deactivated,
        tooltipText
    } = props;
    const active = text.toLowerCase().includes("review")
        ? text.toLowerCase().includes(currentTab.title.toLowerCase())
        : currentTab.title === text;

  const handleTabPress = () => {
    if (!deactivated) {
      onPress(data);
    }
  };

    return (
        tooltipText ?
            <PredictiveTooltip
                element={
                    <div
                        data-testid={"tab-item"}
                        onClick={() => handleTabPress()}
                        className={`report__tab__itemStyles ${deactivated ? " deactivated" : ""}`}
                        style={{
                            backgroundColor: isDraggingOver === true
                                ? "#808080"
                                : (active ? "#606060" : "#404040"),
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <FeatherIcon
                                data-testid={"tab-icon"}
                                width={27}
                                height={27}
                                icon={icon}
                                color={deactivated ? "#707070" : color}
                                style={{ marginRight: 7 }}
                            />
                            <div
                                data-testid={"tab__itemText"}
                                className="tab__itemText"
                                style={{
                                    color: text === "Review"
                                        ? color
                                        : deactivated
                                            ? "#707070"
                                            : (active ? color : "#D2D2D2")
                                }}
                            >
                                {text}
                            </div>
                        </div>
                        {typeof count === "number" &&
                            <div
                                style={{
                                    fontSize: "16px",
                                    fontWeight: 500
                                }}>
                                {count}
                            </div>
                        }
                    </div>
                }
                text={
                    <React.Fragment>
                        {tooltipText}
                    </React.Fragment>
                }
                fitted="180px"
                centered={true}
            />
            :
            <div
                date-testid={"tab-item-with-null-tooltiptext"}
                onClick={() => handleTabPress()}
                className={`report__tab__itemStyles ${deactivated ? " deactivated" : ""}`}
                style={{
                    backgroundColor: isDraggingOver === true
                        ? "#A4A4A4"
                        : (active ? "#606060" : "#404040"),
                    marginLeft: "7px"
                }}
            >
                <div style={{ display: "flex" }}>
                    <FeatherIcon
                        data-testid={"tab-icon"}
                        width={27}
                        height={27}
                        icon={icon}
                        color={deactivated ? "#707070" : color}
                        style={{ marginRight: 7 }}
                    />
                    <div
                        data-testid={"tab__itemText"}
                        className="tab__itemText"
                        style={{
                            color: text === "Review"
                                ? color
                                : deactivated
                                    ? "#707070"
                                    : (active ? color : "#D2D2D2")
                        }}
                    >
                        {text}
                    </div>
                </div>
                {typeof count === "number" &&
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: 500
                        }}>
                        {count}
                    </div>
                }
            </div>
    );
};

export default TabItem;