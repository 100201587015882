import React from "react";

/**
  This is a wrapper component that will wrap a story (Written originally to wrap feedback)
  The dark/light theme will be toggeled by story book
  This theme wrapper will determine the current chosen theme and will alternate the mixins accordingly.
  View theme.scss for more details
 */
const Theme = ({ children, isDark }) => {
  const theme = isDark ? "theme-dark" : "theme-light";
  return <div className={theme}>{children}</div>;
};

export default Theme;
