import moment from "moment";
import AxiosService from "./AxiosService";
import * as Api from "../Constants/ApiCatalog";
import * as Constants from "../Constants/Constants";
import axios from "axios";

/*
  Client for API that hanldes all parameter lists and event duration.
*/

const PARAMETERS_ENDPOINT = Api.PARAMETERS_ENDPOINT;
const CMS_PARAMETERS_ENDPOINT = Api.CMS_PARAMETERS_ENDPOINT;
const PARAMETER_DURATION = Api.PARAMETER_DURATION;

// Return all the CMS parameters that can be charted for a CMS event code on a specific aircraft session
export async function getCmsParameters(
  tail,
  aircraftFamily,
  aircraftModel,
  sessionNumber,
  faultCode,
  axiosCancelSource
) {
  let result;

  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tail,
      sessionNumber: sessionNumber,
      faultCode: faultCode,
    },
  };

  try {
    result = await AxiosService(
      CMS_PARAMETERS_ENDPOINT.url,
      CMS_PARAMETERS_ENDPOINT.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    return { error: error };
  }

  return result;
}

// Returns all the event parameters that can be charted for an FDE/CAS event code on specified dates
export async function getParameters(
  tail,
  faultCode,
  startTime,
  endTime,
  aircraftFamily,
  aircraftModel,
  eventUid,
  axiosCancelSource
) {
  let result;
  let data;

  if (eventUid !== "") {
    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tail,
        faultCode: faultCode,
        hasDiffsInWindow: false,
        eventUid: eventUid,
      },
    };
  } else {
    const start = moment.utc(startTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
    const end = moment.utc(endTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");

    data = {
      params: {
        aircraftFamily: aircraftFamily,
        aircraftModel: aircraftModel,
        tailNumber: tail,
        faultCode: faultCode,
        hasDiffsInWindow: false,
        startTime: start,
        endTime: end,
      },
    };
  }

  try {
    // Send a POST request
    result = axios.post(PARAMETERS_ENDPOINT.url, null, {
      params: data.params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        demo: Constants.MYDOM_DEMO_INSTANCE(),
      },
    });
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Returns all the continuous flight parameters that can be charted for a specified date range
export async function getAllParameters(
  tail,
  startTime,
  endTime,
  aircraftModel,
  aircraftFamily,
  axiosCancelSource
) {
  let result;
  const start = moment.utc(startTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const end = moment.utc(endTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const faultCodes = "*";
  const data = {
    params: {
      aircraftFamily: aircraftFamily,
      aircraftModel: aircraftModel,
      tailNumber: tail,
      faultCode: [faultCodes],
      hasDiffsInWindow: false,
      startTime: start,
      endTime: end,
    },
  };

  try {
    // Send a POST request
    result = axios.post(PARAMETERS_ENDPOINT.url, null, {
      params: data.params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        demo: Constants.MYDOM_DEMO_INSTANCE(),
      },
    });
  } catch (error) {
    console.error(error);
  }

  return result;
}

// Returns all the continuous flight parameters that can be charted for a specified date range.
// This API is used for the new parameter data chunking implemented to support packed parameters.
export async function getParameterGroupMetaData(
  tail,
  startTime,
  endTime,
  aircraftModel,
  aircraftFamily,
  parameters,
  eventUid,
  axiosCancelSource
) {
  let result;
  const start = moment.utc(startTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const end = moment.utc(endTime).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const hasDiffsInWindow = false;

  // If there is no eventUid assigned then use the default start and end times.
  let urlString =
    eventUid !== ""
      ? PARAMETERS_ENDPOINT.url +
        "?aircraftFamily=" +
        aircraftFamily +
        "&aircraftModel=" +
        aircraftModel +
        "&tailNumber=" +
        tail +
        "&hasDiffsInWindow=" +
        hasDiffsInWindow +
        "&eventUid=" +
        eventUid
      : PARAMETERS_ENDPOINT.url +
        "?aircraftFamily=" +
        aircraftFamily +
        "&aircraftModel=" +
        aircraftModel +
        "&tailNumber=" +
        tail +
        "&hasDiffsInWindow=" +
        hasDiffsInWindow +
        "&startTime=" +
        start +
        "&endTime=" +
        end;

  try {
    // Send a POST request

    result = axios({
      method: "post",
      url: urlString,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        demo: Constants.MYDOM_DEMO_INSTANCE(),
      },
      data: parameters,
    });
  } catch (error) {
    console.error(error);
  }

  return result;
}

// API that return the configured event duration time in seconds for an FDE/CAS evet code and event time
export async function getEventDuration(
  tail,
  faultCode,
  faultTime,
  aircraftModel,
  aircraftFamily,
  axiosCancelSource
) {
  let result;
  const faultTimeStamp = moment
    .utc(faultTime)
    .format("YYYY-MM-DDTHH:mm:ss.000[Z]");
  const data = {
    params: {
      tailNumber: tail,
      faultCode: faultCode,
      aircraftModel: aircraftModel,
      aircraftFamily: aircraftFamily,
      faultTimeStamp: faultTimeStamp,
    },
  };

  try {
    result = await AxiosService(
      PARAMETER_DURATION.url,
      PARAMETER_DURATION.method,
      data,
      axiosCancelSource
    );
  } catch (error) {
    console.error(error);
    result = error;
  }

  return result;
}
